import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SearchBoxComponent = (props) => {
  const {
    labelValue, data, populateCall, selectCall,
  } = props;

  return (
    <div className="location-search" style={{ marginBottom: '16px' }}>
      <Autocomplete
        disablePortal
        disableClearable
        id="combo-box"
        options={data ? data.map((result) => ({
          label: result.value,
          value: {
            key: result.key,
            streetNumber: result.streetNumber,
            streetName: result.streetName,
            suburb: result.suburb,
            city: result.city,
            postalCode: result.postalCode,
            center: result.center,
          },
        })) : []}
        onChange={(event, newValue) => {
          selectCall(newValue.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={labelValue}
            onChange={(event) => {
              populateCall(event.target.value);
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchBoxComponent;
