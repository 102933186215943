import React from 'react';

const DocumentTextInput = ({
  value,
  setValue,
  placeholder,
}) => (
  <input
    className="text-input"
    type="text"
    placeholder={placeholder !== undefined ? placeholder : 'example'}
    value={value}
    onChange={(event) => {
      if (setValue) {
        setValue(event.target.value);
      }
    }}
  />
);

export default DocumentTextInput;
