import React from 'react';
import {
  Button, Form, Row, Col, Card, Container,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  getDivision,
  getBranch,
  register,
  updateProfile,
  getProfile,
} from '../../scripts/servest_it/profileScripts';
import { handleLogging, handleValidation, handleOTP } from '../../functions/forms';
import { Context } from '../../functions/servest_it/context';
import { CustomerAddressForm } from '../customerAddress';
import EntriesUpdater from '../../functions/EntriesUpdater';
import { OTPModal } from '../OTPModal';
import { FormRow, FormControlWithLabel } from '../forms';

export class ProfileForm extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const { newProfile } = this.props;
    this.newProfile = newProfile;
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: this.initEntries(),
      requiredMissing: this.initRequiredMissing(),
      divisionTypes: [],
      branchTypes: [],
      registrationContinued: false,
      submitting: false,
      registerDone: false,
      showModal: false,
      loadingProfile: true,
      errors: {},
    };
    const { entries, requiredMissing } = this.state;
    this.EntriesUpdaterer = new EntriesUpdater(
      entries,
      requiredMissing,
      (entris) => this.setState({ entries: entris }),
      (requiredMissin) => this.setState({ requiredMissing: requiredMissin }),
    );
  }

  // INIT VALUES
  initEntries = () => ({
    name: '', // description
    password: '', // user.password
    mobileNumber: '', // mobileNumber
    emailAddress: '', // emailAddress
    // registration continued:
    accountNumber: null, // code
    username: '', // user.username
    passwordHint: '', // user.passwordHint
    officeNumber: '', // officeNumber
    homeNumber: '', /// homeNumber
    division: '', // customLookup3
    branch: '', // customLookup4
    // address:
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
  });

  initRequiredMissing = () => ({
    name: false,
    mobileNumber: false,
    emailAddress: false,
    password: false,
  });
  // << INIT VALUES

  componentDidMount = async () => {
    if (!this.newProfile) {
      const entries = await getProfile();
      console.log('profile:', entries);
      Object.entries(entries).forEach(([key, val]) => {
        if (val == null) entries[key] = this.initEntries()[key];
      });
      this.setState({ entries, loadingProfile: false });
    }
    const divisionTypes = (await getDivision()) || [];
    const branchTypes = (await getBranch()) || [];
    this.setState({ divisionTypes, branchTypes });
  };

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };

  initMultipleRequiredStates = (values) => {
    const { requiredMissing } = this.state;
    const newValues = {};
    values.forEach((name) => {
      if (requiredMissing[name] == null) {
        newValues[name] = false;
      }
    });
    if (Object.keys(newValues).length > 0) {
      const toUpdate = { ...newValues, ...requiredMissing };
      this.setState({ requiredMissing: toUpdate });
    }
  };
  // << UPDATE VALUES

  validStyle = (name) => {
    const {
      requiredMissing,
    } = this.state;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  handleSubmit = async () => {
    const { newProfile } = this;
    const {
      entries,
      entries: {
        name, mobileNumber, emailAddress, password,
      },
      registrationContinued,
    } = this.state;
    const { onLogin } = this.props;
    let handleLoggingResponse;
    const isFormValid = handleValidation(this.state, (s) => this.setState(s));
    if (isFormValid) {
      handleLoggingResponse = handleLogging(this.state, (s) => this.setState(s));
    }

    if (handleLoggingResponse && isFormValid) {
      console.log('handling submit');
      if (newProfile && !registrationContinued) {
        const registerResponse = await register(
          {
            name, mobileNumber, emailAddress, password,
          },
          (s) => this.setState(s),
          () => this.setState({ registrationContinued: true }),
        );
        console.log('register response:', registerResponse);
        const { result } = registerResponse;
        const showModal = result === 'OTP_REQUIRED' || result === 'OTP_ERROR';
        this.setState({ showModal });
      } else {
        console.log('Updating profile...');
        const updateResponse = await updateProfile(entries, (s) => this.setState(s));
        console.log('update response:', updateResponse);
        if (newProfile && updateResponse === true) {
          onLogin(entries, (
            submitting, registerDone,
          ) => this.setState({ submitting, registerDone }));
        }
      }
    }
  };

  continueRegistration = (registerResponse) => {
    const { code, username } = registerResponse;
    this.setEntries('accountNumber', code);
    this.setEntries('username', username);
    this.setState({ registrationContinued: true });
  };

  render() {
    const {
      updateEntries,
      updateReqEntries,
      validStyle,
      newProfile,
      EntriesUpdaterer,
    } = this;
    const {
      entries,
      entries: {
        name,
        mobileNumber,
        emailAddress,
        password,
        // registration continued:
        username,
        passwordHint,
        officeNumber,
        homeNumber,
        division,
        branch,
      },
      requiredMissing,
      divisionTypes,
      branchTypes,
      registrationContinued,
      submitting,
      registerDone,
      showModal,
      loadingProfile,
      errors,
    } = this.state;
    console.log('state:', this.state);
    const {
      // updateEntries, updateReqEntries,
      parentEntries,
      parentRequiredMissing,
      printOut,
    } = EntriesUpdaterer;
    parentEntries(entries);
    parentRequiredMissing(requiredMissing);
    printOut();

    const showFullForm = !newProfile || registrationContinued;

    // When the registration continues to the next screen where more info is required,
    // initialise the states of the new inputs
    if (showFullForm) {
      this.initMultipleRequiredStates([
        'username',
        'password',
        'passwordHint',
        'division',
        'branch',
      ]);
    }

    return (
      <Container fluid id="ProfileForm">
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Card id="formCard" className="text-center">
              <Card.Header>
                <Card.Title>
                  <h3>
                    <b>
                      {newProfile && 'Account Registration'}
                      {!newProfile && 'Edit your Profile'}
                    </b>
                  </h3>
                </Card.Title>
              </Card.Header>
              {!newProfile && (
                <Dimmer inverted active={loadingProfile}>
                  <Loader>Loading account details</Loader>
                </Dimmer>
              )}
              <Card.Body id="formBody">
                <Form>
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Customer Account Details
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* CUSTOMER NAME */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={validStyle('name')}
                          >
                            Customer Name *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="name"
                          type="text"
                          readOnly={showFullForm}
                          required
                          isInvalid={requiredMissing.name === true}
                          value={name}
                          onChange={updateReqEntries}
                        />
                      </Col>
                      {/* FORCELINK USERNAME */}
                      <Col md={6}>
                        {showFullForm && (
                          <>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={validStyle('username')}
                              >
                                Forcelink Username *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="username"
                              type="text"
                              readOnly
                              required
                              isInvalid={requiredMissing.username === true}
                              value={username}
                              onChange={updateReqEntries}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* FORCELINK PASSWORD */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={validStyle('password')}
                          >
                            Forcelink Password *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="password"
                          name="password"
                          required
                          isInvalid={requiredMissing.password === true}
                          value={password}
                          onChange={updateReqEntries}
                          autocomplete="new-password"
                        />
                      </Col>
                      {/* PASSWORD HINT */}
                      <Col md={6}>
                        {showFullForm && (
                          <>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={validStyle('passwordHint')}
                              >
                                Password Hint *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              type="text"
                              name="passwordHint"
                              required
                              isInvalid={requiredMissing.passwordHint === true}
                              value={passwordHint}
                              onChange={updateReqEntries}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Contact Details
                    </Form.Label>
                  </Form.Group>
                  <FormRow>
                    {/* MOBILE NUMBER */}
                    <FormControlWithLabel
                      requiredMissing={requiredMissing}
                      label="Mobile Number"
                      name="mobileNumber"
                      value={mobileNumber}
                      type="tel"
                      onChange={updateReqEntries}
                      error={errors.mobileNumber}
                    />
                    {/* EMAIL ADDRESS */}
                    <FormControlWithLabel
                      requiredMissing={requiredMissing}
                      label="Email Address"
                      name="emailAddress"
                      value={emailAddress}
                      onChange={updateReqEntries}
                    />
                  </FormRow>
                  {showFullForm && (
                    <>
                      <FormRow>
                        {/* OFFICE NUMBER */}
                        <FormControlWithLabel
                          label="Office Number"
                          name="officeNumber"
                          value={officeNumber}
                          type="tel"
                          onChange={updateEntries}
                          error={errors.officeNumber}
                        />
                        {/* LANDLINE EXTENSION */}
                        <FormControlWithLabel
                          label="Landline Extension"
                          name="homeNumber"
                          value={homeNumber}
                          type="text"
                          onChange={updateEntries}
                          error={errors.homeNumber}
                        />
                      </FormRow>
                      <Form.Group className="formLabels">
                        <Form.Label className="largeLabel">
                          Branch and Division
                        </Form.Label>
                      </Form.Group>
                      <Form.Group>
                        <Row className="formInputs">
                          {/* DIVISION */}
                          <Col md={6}>
                            <Material.TextField
                              name="division"
                              select
                              fullWidth
                              margin="normal"
                              label="Division"
                              variant="outlined"
                              required
                              error={requiredMissing.division === true}
                              value={division}
                              onChange={updateReqEntries}
                            >
                              <Material.MenuItem
                                key="blank"
                                value=""
                              />
                              {divisionTypes.map((divisioner) => (
                                <Material.MenuItem
                                  key={divisioner.value}
                                  value={divisioner.value}
                                >
                                  {divisioner.label}
                                </Material.MenuItem>
                              ))}
                            </Material.TextField>
                          </Col>
                          {/* BRANCH */}
                          <Col md={6}>
                            <Material.TextField
                              name="branch"
                              select
                              fullWidth
                              margin="normal"
                              label="Branch"
                              variant="outlined"
                              required
                              error={requiredMissing.branch === true}
                              value={branch}
                              onChange={updateReqEntries}
                            >
                              <Material.MenuItem
                                key="blank"
                                value=""
                              />
                              {branchTypes.map((newBranch) => (
                                <Material.MenuItem
                                  key={newBranch.value}
                                  value={newBranch.value}
                                >
                                  {newBranch.label}
                                </Material.MenuItem>
                              ))}
                            </Material.TextField>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="formLabels">
                        <Form.Label className="largeLabel">
                          Customer Address
                        </Form.Label>
                      </Form.Group>
                      {/* CUSTOMER ADDRESS inputs */}
                      <CustomerAddressForm {...{ entries, updateEntries }} />
                    </>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer>
                <Form.Group id="finalButtons">
                  <Link to="/home">
                    <Button variant="secondary" type="submit">
                      Back
                    </Button>
                  </Link>
                  {!submitting && (
                    <Button
                      variant={registerDone ? 'secondary' : 'primary'}
                      disabled={registerDone}
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                      {newProfile
                        && ((registrationContinued && 'Save and login')
                          || 'Register')}
                      {!newProfile && 'Update profile'}
                    </Button>
                  )}
                  {submitting && <Spinner />}
                  {
                    /* DEV TODO: delete */
                    newProfile
                      // window.location.origin === "http://localhost:3000" && (
                      && window.clientInformation
                      && window.clientInformation.appVersion
                        === '5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.150 Safari/537.36' && (
                        <Button
                          onClick={() => {
                            const toUpdate = {
                              ...entries,
                              name: 'a',
                              password: 'b',
                              mobileNumber: '0821112233',
                              emailAddress: 'cjmeyer96+pqr@gmail.com',
                              // registration continued:
                              username: 'cjmeyer96+pqr@gmail.com',
                              passwordHint: 'd',
                              officeNumber: '0219998877',
                              homeNumber: '32',
                              division: '1',
                              branch: '1',
                              // address:
                              building: 'e',
                              streetNumber: '32',
                              streetName: 'f',
                              suburb: 'g',
                              city: 'h',
                              postalCode: '9876',
                            };
                            this.setState({
                              registrationContinued: true,
                              entries: toUpdate,
                            });
                          }}
                        >
                          reg cont
                        </Button>
                    )
                    /* DEV TODO: delete */
                  }
                </Form.Group>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={2} />
        </Row>
        <OTPModal
          onChangeOTP={(OTP) => this.setEntries('otp', OTP)}
          showModal={showModal}
          setShowModal={(showingModal) => this.setState({ showingModal })}
          setSubmitting={(submit) => this.setState({ submit })}
          mobileNumber={mobileNumber}
          handleOTP={(setOtp, otp) => handleOTP(
            register,
            this.continueRegistration,
            entries,
            (s) => this.setState(s),
            setOtp,
            otp,
          )}
        />
      </Container>
    );
  }
}
