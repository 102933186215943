import React from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from './functions/enpower/context';
import { EnpowerHeader, PortalNav } from './components/enpower/header';
import { ForcelinkFooter } from './components/header';
import { Login } from './pages/enpower/login';
import { HomePage } from './pages/enpower/HomePage';
import {
  LogFaultMyAddress,
  LogFaultOtherAddress,
} from './pages/enpower/LogFault';
import { ProfileForm, RegistrationForm } from './pages/enpower/profile';
import { OpenFaults } from './pages/enpower/openFaults';
import { AllFaults } from './pages/enpower/allFaults';
import { Notifications } from './pages/enpower/notifications';

export class EnpowerApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'Enpower Portal';
    const { openSnackbar } = this.state;
    const {
      authenticated,
      logout,
      cookies: { cookies },
    } = this.context;
    console.log('Authenticated:', authenticated);

    if (authenticated === null) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    const portalNavPaths = [
      '/profile',
      '/notifications',
      '/logFaultMyAddress',
      '/logFaultOtherAddress',
      '/openFaults',
      '/allFaults',
    ];

    const pagesPaths = [
      ['/profile', <ProfileForm />],
      ['/logFaultMyAddress', <LogFaultMyAddress />],
      ['/logFaultOtherAddress', <LogFaultOtherAddress />],
      ['/openFaults', <OpenFaults />],
      ['/allFaults', <AllFaults />],
      ['/notifications', <Notifications />],
    ];

    return (
      <div id="EnpowerApp">
        <Switch>
          <Route path="/">
            <EnpowerHeader />
          </Route>
        </Switch>
        <Switch>
          {portalNavPaths.map((path, i) => (
            <Route path={path} key={i}>
              <PortalNav />
            </Route>
          ))}
        </Switch>
        <div id="App">
          <Container fluid id="AppContainer">
            <Switch>
              <Route
                path="/login"
                render={({ location }) => (!authenticated ? (
                  <Login />
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
              <Route
                path="/register"
                render={({ location }) => (!authenticated ? (
                  <RegistrationForm />
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
              {!authenticated && (
                <Route
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
              <Route
                path="/home"
                render={({ location }) => (authenticated ? (
                  <HomePage />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                ))}
              />
              <Route
                path="/logout"
                render={() => {
                  logout();
                }}
              />
              {pagesPaths.map(([path, page], i) => (
                <Route path={path} key={i}>
                  {page}
                </Route>
              ))}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </div>
        <Route path="/">
          <ForcelinkFooter bg="light" />
        </Route>
        {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
            >
              <Alert
                onClose={this.snackbarClose}
                severity="info"
                elevation={6}
                variant="filled"
              >
                Logged in with username
                {' '}
                <b>{cookies.username}</b>
              </Alert>
            </Snackbar>
        )}
      </div>
    );
  }
}

const NoMatch = () => {
  document.title = '404 Not Found | Enpower Portal';
  const location = useLocation();
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
