import React from 'react';
import {
  Alert,
  IconButton,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AlertBar = (props) => {
  const {
    openSnackbar, setOpenSnackbar, toastMessage, validSubmit,
  } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={() => {
        setOpenSnackbar(false);
      }}
    >
      <Alert
        severity={validSubmit ? 'success' : 'error'}
        elevation={6}
        variant="filled"
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpenSnackbar(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
