import React from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from './functions/servest_it/context';
import { ForcelinkFooter, LogoHeader } from './components/header';
import { ServestITHeader } from './components/servest_it/header';
import { Landing } from './pages/servest_it/landing';
import { Login } from './pages/servest_it/login';
import { Home } from './pages/servest_it/home';
import { MyDetails } from './pages/servest_it/myDetails';
import { Register } from './pages/servest_it/register';
import { ViewTickets } from './pages/servest_it/viewTickets';
import { CreateTicket } from './pages/servest_it/createTicket';
import { EditTicket } from './pages/servest_it/editTicket';
import { EmployeeSchema } from './pages/servest_it/employeeSchema';
import { CustomerSchema } from './pages/servest_it/customerSchema';
import { EmployeeSchemaIS } from './pages/servest_it/employeeSchemaIS';

export class ServestITApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'Servest Portal';
    const { openSnackbar } = this.state;
    const {
      authenticated,
      logout,
      cookies: { cookies },
    } = this.context;
    console.log('Authenticated:', authenticated);

    if (authenticated === null) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    const headerPaths = [
      '/home',
      '/myDetails',
      '/tickets',
      '/ticket',
    ];

    const pagesPaths = [
      ['/myDetails', <MyDetails />],
      ['/tickets', <ViewTickets />],
      ['/ticket/:code', <EditTicket />],
      ['/ticket', <CreateTicket />],
    ];

    return (
      <>
        <ServestITWrapper>
          <Switch>
            {headerPaths.map((path, i) => (
              <Route path={path} key={i}>
                <ServestITHeader paths={headerPaths} />
              </Route>
            ))}
            <Route path="/">
              <LogoHeader
                title=""
                imgSrc="servest_it/servest-logo-white.svg"
                titleStyle={{ color: 'white' }}
              />
            </Route>
          </Switch>

          <div id="App">
            <Container fluid id="AppContainer">
              <Switch>
                {[
                  ['/landing', <Landing />],
                  ['/customer', <CustomerSchema />],
                  ['/employee', <EmployeeSchema />],
                  ['/employee-is', <EmployeeSchemaIS />],
                  ['/login', <Login />],
                  ['/register', <Register />],
                ].map(([path, page], i) => (
                  <Route
                    key={i}
                    path={path}
                    render={({ location }) => (!authenticated ? (
                      page
                    ) : (
                      <Redirect
                        to={{ pathname: '/home', state: { from: location } }}
                      />
                    ))}
                  />
                ))}
                {!authenticated && (
                <Route
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  )}
                />
                )}
                <Route
                  exact
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{ pathname: '/home', state: { from: location } }}
                    />
                  )}
                />
                <Route
                  path="/home"
                  render={({ location }) => (authenticated ? (
                    <Home />
                  ) : (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  ))}
                />
                <Route path="/logout" render={() => ((logout(), null))} />

                {pagesPaths.map(([path, page], i) => (
                  <Route path={path} key={i}>
                    {page}
                  </Route>
                ))}
                <Route
                  path="/exports"
                  component={() => {
                    console.log('Navigating to exports table');
                    window.location.replace(
                      'https://servest.forcelink.net/forcelink/exports.html',
                    );
                    return <h4>Redirecting...</h4>;
                  }}
                />
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </Container>
          </div>
          <Route path="/">
            <ForcelinkFooter />
          </Route>
          {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={this.snackbarClose}
          >
            <Alert
              onClose={this.snackbarClose}
              severity="info"
              elevation={6}
              variant="filled"
            >
              Logged in with username
              {' '}
              <b>{cookies.username}</b>
            </Alert>
          </Snackbar>
          )}
        </ServestITWrapper>
      </>
    );
  }
}

const ServestITWrapper = (props) => {
  const {
    children,
  } = props;
  const loc = useLocation();
  let className;
  if (loc.pathname === '/landing') className = 'withBackgroundImgLanding';
  else if (loc.pathname === '/customer') className = 'withBackgroundColor';
  else if (loc.pathname === '/customer1') className = 'withBackgroundColor';
  else if (loc.pathname === '/employee') className = 'withBackgroundColor';
  else if (loc.pathname === '/employee1') className = 'withBackgroundColor';
  else if (loc.pathname === '/login') className = 'withBackgroundColor';
  else className = 'withBackgroundColor';

  return (
    <div id="ServestITApp" className={className}>
      {children}
    </div>
  );
};

const NoMatch = () => {
  const location = useLocation();
  document.title = '404 Not Found | Servest';
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
