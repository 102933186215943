import styled from 'styled-components';

export const HomeContainer = styled.div`
  background-color: #F2F2F2;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-weight: 650;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #00558A;
  margin-bottom: 40px;
`;

export const SubscriberImage = styled.div`
  background-image: url("/myciti/myciti_logo.png");
  background-blend-mode: overlay;
  background-size: cover;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 75px;
  margin-bottom: 40px;
  margin-top: 20px;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 24px;
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
  background-color: #fff;
  padding: 40px 24px;

  @media only screen and (min-width: 850px) {
    width: 760px;
  }
`;

export const StyleContainer = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  overflow-x: hidden;

  h2 {
    font-style: normal;
    font-weight: 650;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
  }
`;

export const StyleHr = styled.hr`
  color: #ebebeb;
  opacity: 1;
  margin-top: 40px;
  margin-bottom: 24px;
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-weight: 650;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #000000;

  .link {
    :hover {
      text-decoration: underline;
    }
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  
  #composition-menu {
    padding: 0px;
    width: 226px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  }

  .MuiPopper-root {
    bottom: -178px !important;
    height: fit-content !important;
  }

  .MuiPaper-root {
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  .MuiMenuItem-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    padding: 10px !important;
    width: 100%;
    justify-content: left;

    :hover {
      background: #102B7C;
      color: #fff;
    }
  }

  @media only screen and (min-width: 850px) {
    flex-direction: row;
  }
`;

export const Box = styled.div`
  width: 226px;
  height: 120px;
  border: 1px solid #999999;
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: #EBEBEB;
  }

  @media only screen and (min-width: 850px) {
    margin: 0px 8px;
  }
`;

export const ContactInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 850px) {
    flex-direction: row;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a, div {
    padding: 0px 2px;
    font-style: normal;
    font-weight: 650;
    font-size: 14px;
    line-height: 18px;
  }

  a {
    color: #D2232A;
    text-decoration: underline;
  }

  div {
    color: #707070;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`;
