import React from 'react';
import { useParams } from 'react-router-dom';
import { CreateTicketForm } from '../../components/syntell/createTicket';

export const EditTicket = () => {
  const params = useParams();
  const { code } = params;
  console.log('Edit Ticket code:', code);
  document.title = `Edit Ticket ${code} | Syntell Customer Portal`;

  return <CreateTicketForm code={code} />;
};
