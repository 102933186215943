import React, { useContext } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/servest_is/context';

export const ViewOpenWorkOrders = () => {
  const { username } = useContext(Context);

  document.title = 'Open WorkOrders | Servest IS Customer Portal';

  return (
    <Table
      Context={Context}
      title="Open WorkOrders"
      tableModelClass="WorkOrder"
      id="workorder"
      columnView="Customer WorkOrders View"
      additionalFromClauses={['left join modelClass.workOrderRequests as wor']}
      filters={[
        {
          property: 'wor.loggedBy.username',
          value: username,
          options: [],
        },
        {
          property: 'workOrderStatus.open',
          value: true,
          options: [],
        }]}
      sorts={{
        property: 'createdDateTime',
        order: 'DESC',
      }}
    />
  );
};
