import React from 'react';
// import { useHistory } from 'react-router-dom';
import { HomePage } from '../../components/syntell/home';
// import { shouldUserUpdateDetails } from '../../scripts/syntell/globalScripts';

// const shouldUpdate = async (history) => {
//   const shouldUpdate = await shouldUserUpdateDetails();
//   if (shouldUpdate) {
//     alert('Please verify/update your contact details');
//     history.push('/myDetails');
//   }
// };

export const Home = () => {
  // const history = useHistory();
  // DEV: uncomment
  // shouldUpdate(history);
  document.title = 'Home | Syntell Customer Portal';

  return <HomePage />;
};
