import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import '../../styles/joburg_water/logFault.scss';
import { AddressInput } from '../../components/joburg_water/address_input';
import {
  getCallTypesByCode,
  getEscalationOptions,
  getEscalationOptionsWithCode,
  getEscalationReasons,
  getEscalationReasonsWithCode,
} from '../../scripts/joburg_water/logFaultScripts';
import Ripple from '../../components/joburg_water/ripple';
import SlideIn from '../../components/joburg_water/slidein';
import ImageDrop from './ImageDrop';
import { getWorkRequest, getUpperSelectionTree, setProperty } from '../../functions/apiCalls';

const Fault = () => {
  const params = useParams();
  const { code } = params;
  const [stateObj, setStateObj] = useState({
    entries: {
      id: '',
      callType: '',
      subCallType: '',
      subSubCallType: '',
      callNotes: '',
      contactPerson: '',
      mobileNumber: '',
      meterNumber: '',
      accountNumber: '',
      building: '',
      streetNumber: '',
      streetName: '',
      suburb: '',
      city: '',
      postalCode: '',
      description: '',
    },
    requiredMissing: {
      callType: false,
      callNotes: false,
      contactPerson: false,
      mobileNumber: false,
      suburb: false,
    },
    requireNumber: false,
    submitting: false,
    images: [],
    escalationOptions: [],
    escalationCodes: [],
    escalationReasons: [],
    escalationReasonCodes: [],
  });

  const generateCallTypes = async (typeCode) => {
    const ct = (await getCallTypesByCode(typeCode, true)) || [];
    const sct = (await getCallTypesByCode(typeCode, false)) || [];
    const type = ct.find((x) => (x.value === typeCode));
    const typeId = sct.find((x) => (x.label === type.label)).value;
    const tree = await getUpperSelectionTree(typeId).then((res) => res.json());
    return { callType: tree[0], subCallType: tree[1].replaceAll('&nbsp;', ''), subSubCallType: type.label.replaceAll('&nbsp;', '') };
  };

  const fetchData = async () => {
    try {
      const call = await getWorkRequest(code).then((res) => res.json());
      console.log('call', call);
      const callTypes = await generateCallTypes(call.typeCode);
      const escalationOptions = await getEscalationOptions();
      const escalationCodes = await getEscalationOptionsWithCode();
      const escalationReasons = await getEscalationReasons();
      const escalationReasonCodes = await getEscalationReasonsWithCode();

      let escalation = '';
      const escalationCode = escalationCodes.find(
        (x) => (x.value === call.customLookupCode5),
      );
      if (escalationCode !== undefined) {
        escalation = escalationOptions.find(
          (x) => (x.label === escalationCode.label),
        ).value;
      }

      let escalationReason = '';
      const escalationReasonCode = escalationReasonCodes.find(
        (x) => (x.value === call.customLookupCode6),
      );
      if (escalationReasonCode !== undefined) {
        escalationReason = escalationReasons.find(
          (x) => (x.label === escalationReasonCode.label),
        ).value;
      }

      setStateObj((prevState) => ({
        ...prevState,
        entries: {
          ...prevState.entries,
          id: call.id,
          callNotes: call.description,
          meterNumber: call.custom2,
          accountNumber: call.custom4,
          escalation,
          escalationReason,
          escalationCode: call.customLookupCode5,
          escalationReasonCode: call.customLookupCode6,
          contactPerson: call.contactName,
          mobileNumber: call.contactNumber,
          emailAddress: call.contactEmail,
          description: call.address.description,
          building: call.address.address3,
          streetNumber: call.address.address4,
          streetName: call.address.address5,
          suburb: call.address.address6,
          city: call.address.address7,
          postalCode: call.address.address8,
          province: call.address.provinceCode,
          canEscalate: call.custom5 === 'CAN_ESCALATE',
          ...callTypes,
        },
        escalationOptions,
        escalationCodes,
        escalationReasons,
        escalationReasonCodes,
      }));
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const setEntries = (name, val) => {
    const { entries, requiredMissing } = stateObj;
    const toUpdate = { ...entries };
    const toUpdateReq = { ...requiredMissing };
    toUpdate[name] = val;
    if (val !== '') {
      toUpdateReq[name] = false;
    }
    setStateObj({ ...stateObj, entries: { ...toUpdate }, requiredMissing: { ...toUpdateReq } });
  };

  const updateEntries = (name, value) => setEntries(name, value);

  const onResultFound = (result) => {
    console.log('result passed to parent:', result);
    const {
      streetNumber, streetName, city, postalCode, suburb,
    } = result;
    const { entries } = stateObj;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || entries.streetNumber || '';
    toUpdate.streetName = streetName || entries.streetName || '';
    toUpdate.city = city || entries.city || '';
    toUpdate.postalCode = postalCode || entries.postalCode || '';
    toUpdate.suburb = suburb || entries.suburb || '';
    setStateObj({ ...stateObj, entries: { ...toUpdate } });
  };

  const validStyle = (name) => {
    const { requiredMissing } = stateObj;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(stateObj);
  }, [stateObj]);

  return (
    <Container fluid id="formContainer">
      <div className="slide-container">
        <SlideIn settings={{
          marginRight: 0,
          width: '100%',
          from: { marginRight: -700 },
          config: { mass: 1, tension: 300, friction: 30 },
        }}
        >
          <Card id="formCard" className="text-center">
            <Card.Header>
              <img src="joburg_water/logo.jpg" className="login-logo" alt="Joburg Water Logo" />
              <div className="login-title">
                {`Logged Fault ${code}`}
              </div>
            </Card.Header>
            <Card.Body id="formBody">
              <Form>
                <Form.Group className="formLabels">
                  <Form.Label className="largeLabel">
                    Fault Information
                  </Form.Label>
                </Form.Group>
                <Form.Group>
                  <Row className="formInputs">
                    {/* CALL TYPE */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label className="smallLabel" style={validStyle('callType')}>
                          Level One Call Type
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="callType"
                        type="text"
                        isInvalid={stateObj.requiredNumber === true}
                        value={stateObj.entries.callType}
                        onChange={(e) => {
                          updateEntries(e.target.name, e.target.value);
                          if (stateObj.callType === '') {
                            setStateObj({ ...stateObj, requiredNumber: true });
                          } else {
                            setStateObj({ ...stateObj, requiredNumber: false });
                          }
                        }}
                        disabled
                      />
                    </Col>
                    {/* SUB CALL TYPE */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label className="smallLabel" style={validStyle('subCallType')}>
                          Level Two Call Type
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="subCallType"
                        type="text"
                        isInvalid={stateObj.requiredNumber === true}
                        value={stateObj.entries.subCallType}
                        onChange={(e) => {
                          updateEntries(e.target.name, e.target.value);
                          if (stateObj.subCallType === '') {
                            setStateObj({ ...stateObj, requiredNumber: true });
                          } else {
                            setStateObj({ ...stateObj, requiredNumber: false });
                          }
                        }}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="formInputs">
                    {/* SUB SUB CALL TYPE */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label className="smallLabel" style={validStyle('subSubCallType')}>
                          Level Three Call Type
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="subSubCallType"
                        type="text"
                        isInvalid={stateObj.requiredNumber === true}
                        value={stateObj.entries.subSubCallType}
                        onChange={(e) => {
                          updateEntries(e.target.name, e.target.value);
                          if (stateObj.subSubCallType === '') {
                            setStateObj({ ...stateObj, requiredNumber: true });
                          } else {
                            setStateObj({ ...stateObj, requiredNumber: false });
                          }
                        }}
                        disabled
                      />
                    </Col>
                    <Col md={6} />
                  </Row>
                </Form.Group>
                {/* CALL NOTES */}
                <Form.Group className="formLabels">
                  <Form.Label
                    className="smallLabel"
                    style={validStyle('callNotes')}
                  >
                    Call Notes *
                  </Form.Label>
                </Form.Group>
                <Form.Group className="formInputs">
                  <Form.Control
                    name="callNotes"
                    as="textarea"
                    rows={3}
                    required
                    isInvalid={stateObj.requiredMissing.callNotes === true}
                    value={stateObj.entries.callNotes}
                    onChange={
                        (e) => {
                          updateEntries(e.target.name, e.target.value);
                        }
                      }
                    disabled
                  />
                </Form.Group>
                <Form.Group>
                  <Row className="formInputs">
                    {/* ACCOUNT NUMBER */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label className="smallLabel" style={validStyle('accountNumber')}>
                          Account Number *
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="accountNumber"
                        type="text"
                        isInvalid={stateObj.requiredNumber === true}
                        value={stateObj.entries.accountNumber}
                        onChange={(e) => {
                          updateEntries(e.target.name, e.target.value);
                          if (stateObj.accountNumber === '') {
                            setStateObj({ ...stateObj, requiredNumber: true });
                          } else {
                            setStateObj({ ...stateObj, requiredNumber: false });
                          }
                        }}
                        disabled
                      />
                    </Col>
                    {/* METER NUMBER */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label className="smallLabel" style={validStyle('meterNumber')}>
                          Meter Number
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="meterNumber"
                        type="text"
                        value={stateObj.entries.meterNumber}
                        onChange={(e) => {
                          updateEntries(e.target.name, e.target.value);
                          if (stateObj.accountNumber === '') {
                            setStateObj({ ...stateObj, requiredNumber: true });
                          } else {
                            setStateObj({ ...stateObj, requiredNumber: false });
                          }
                        }}
                        disabled
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {stateObj.entries.canEscalate && (
                <Form.Group>
                  <Row className="formInputs">
                    <Col md={6}>
                      <Material.TextField
                        name="escalation"
                        select
                        fullWidth
                        margin="normal"
                        label="Escalation?"
                        variant="outlined"
                        required
                        value={stateObj.entries.escalationCode}
                        onChange={async (e) => {
                          const { label } = stateObj.escalationCodes.find(
                            (x) => (x.value === e.target.value),
                          );
                          const { value } = stateObj.escalationOptions.find(
                            (x) => (x.label === label),
                          );
                          const { entries } = stateObj;
                          const toUpdate = { ...entries };
                          toUpdate.escalation = value;
                          toUpdate.escalationCode = e.target.value;
                          setStateObj({ ...stateObj, entries: { ...toUpdate } });
                        }}
                      >
                        <Material.MenuItem
                          key="blank"
                          value=""
                        />
                        {stateObj.escalationCodes.map((type) => (
                          <Material.MenuItem
                            key={type.value}
                            value={type.value}
                          >
                            {type.label}
                          </Material.MenuItem>
                        ))}
                      </Material.TextField>
                    </Col>
                    <Col md={6}>
                      {stateObj.entries.escalationCode === 'ESCY' && (
                      <Material.TextField
                        name="escalationReason"
                        select
                        fullWidth
                        margin="normal"
                        label="Reason for escalation"
                        variant="outlined"
                        required
                        value={stateObj.entries.escalationReasonCode}
                        onChange={
                              (e) => {
                                let escalationReason = '';
                                const escalationReasonCode = stateObj.escalationReasonCodes.find(
                                  (x) => (x.value === e.target.value),
                                );
                                if (escalationReasonCode !== undefined) {
                                  escalationReason = stateObj.escalationReasons.find(
                                    (x) => (x.label === escalationReasonCode.label),
                                  ).value;
                                }
                                const { entries } = stateObj;
                                const toUpdate = { ...entries };
                                toUpdate.escalationReason = escalationReason;
                                toUpdate.escalationReasonCode = e.target.value;
                                setStateObj({ ...stateObj, entries: { ...toUpdate } });
                              }
                            }
                      >
                        {stateObj.escalationReasonCodes.map((type) => (
                          <Material.MenuItem
                            key={type.value}
                            value={type.value}
                          >
                            {type.label}
                          </Material.MenuItem>
                        ))}
                      </Material.TextField>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                )}
                <Form.Group className="formLabels">
                  <Form.Label className="largeLabel">
                    Contact Details
                  </Form.Label>
                </Form.Group>
                <Form.Group>
                  <Row className="formInputs">
                    {/* CONTACT PERSON */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label
                          className="smallLabel"
                          style={validStyle('contactPerson')}
                        >
                          Contact Person *
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="contactPerson"
                        type="text"
                        required
                        isInvalid={stateObj.requiredMissing.contactPerson === true}
                        value={stateObj.entries.contactPerson}
                        onChange={
                                (e) => {
                                  updateEntries(e.target.name, e.target.value);
                                }
                              }
                        disabled
                      />
                    </Col>
                    {/* CONTACT NUMBER */}
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label
                          className="smallLabel"
                          style={validStyle('mobileNumber')}
                        >
                          Contact Number *
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="mobileNumber"
                        type="number"
                        required
                        isInvalid={stateObj.requiredMissing.mobileNumber === true}
                        value={stateObj.entries.mobileNumber}
                        onChange={
                                (e) => {
                                  updateEntries(e.target.name, e.target.value);
                                }
                              }
                        disabled
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="formInputs">
                    {/* CONTACT EMAIL */}
                    <Col xs={12} md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label className="smallLabel">
                          Contact Email
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        name="emailAddress"
                        type="email"
                        value={stateObj.entries.emailAddress}
                        onChange={(e) => {
                          updateEntries(e.target.name, e.target.value);
                        }}
                        disabled
                      />
                    </Col>
                    <Col xs={0} md={6} />
                  </Row>
                </Form.Group>
                <AddressInput
                  entries={stateObj.entries}
                  onChange={(name, value) => {
                    updateEntries(name, value);
                  }}
                  requiredMissing={stateObj.requiredMissing}
                  updateReqEntries={(name, value) => {
                    updateEntries(name, value);
                  }}
                  onResultFound={onResultFound}
                  page="logFault"
                  readOnly
                />
                <ImageDrop
                  workImages={undefined}
                  images={stateObj.images}
                  setImages={(value) => { setStateObj({ ...stateObj, images: value }); }}
                  workRequestId={undefined}
                  refresh={undefined}
                  readOnly
                />
              </Form>
            </Card.Body>
            <Card.Footer>
              <div className="button-group">
                <Form.Group id="finalButtons">
                  <Link to="/home">
                    <Button className="secondary" type="submit">
                      Cancel
                      <Ripple />
                    </Button>
                  </Link>
                  <div id="submit-spinner">
                    {!stateObj.submitting && (
                    <Button
                      type="submit"
                      className="primary"
                      onClick={async () => {
                        setStateObj({
                          ...stateObj,
                          submitting: true,
                        });
                        await setProperty('WorkOrderRequest', 'customLookup5', stateObj.entries.id, stateObj.entries.escalation);
                        await setProperty('WorkOrderRequest', 'customLookup6', stateObj.entries.id, stateObj.entries.escalationReason);
                        setStateObj({
                          ...stateObj,
                          submitting: false,
                        });
                      }}
                    >
                      Save and continue
                      <Ripple />
                    </Button>
                    )}
                    {stateObj.submitting && <CircularProgress />}
                  </div>
                </Form.Group>
              </div>
            </Card.Footer>
          </Card>
        </SlideIn>
      </div>
    </Container>
  );
};

export default Fault;
