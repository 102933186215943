import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Material from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const OTPModal = ({
  onChangeOTP,
  showModal,
  setShowModal,
  setSubmitting,
  mobileNumber,
  handleOTP,
}) => {
  const [otp, setOtp] = useState('');

  useEffect(() => {
    setOtp('');
  }, []);

  useEffect(() => {
    onChangeOTP(otp);
  }, [otp]);

  const onChange = (value) => { setOtp(value); };

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setOtp('');
        setShowModal(false);
        setSubmitting(false);
      }}
      id="OTPModal"
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '650px',
          borderRadius: '0px',
          padding: '24px',
        },
        '& .MuiDialogTitle-root': {
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '27px',
          color: '#000000',
          fontFamily: '"Poppins", sans-serif',
          padding: '0px 0px 24px 0px',
        },
        '& .MuiDialogContentText-root': {
          fontFamily: '"Poppins", sans-serif',
          fontWeight: '300',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000000',
        },
        '& .MuiDialogContent-root': {
          padding: '0px 0px 24px 0px',
        },
        '& .MuiDialogActions-root': {
          padding: '0px',
        },
        '& .MuiButtonBase-root': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px',
          background: '#102b7c',
          color: '#fff',
          fontFamily: '"Poppins", sans-serif',
        },
        '& .MuiButtonBase-root:hover': {
          backgroundColor: '#102b7c',
        },
      }}
    >
      <DialogTitle>Verify Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the OTP we have sent to
          {' '}
          {mobileNumber}
          <OtpInput value={otp} valueLength={4} onChange={onChange} />
          <Form.Text muted>
            Didn&apos;t get a text?
            {' '}
            <Material.Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOtp('');
                handleOTP(setOtp);
              }}
              underline="always"
              color="primary"
            >
              Send again.
            </Material.Link>
          </Form.Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="primary" onClick={() => handleOTP(setOtp, otp)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OtpInput = (props) => {
  const { value, valueLength, onChange } = props;
  const first = useRef();
  const second = useRef();
  const third = useRef();
  const fourth = useRef();
  const inputRefs = [first, second, third, fourth];
  const RE_DIGIT = new RegExp(/^\d+$/);

  const valueItems = useMemo(() => {
    const oldValue = value;
    const valueArray = oldValue.split('');
    const items = [];
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [value, valueLength]);

  const inputOnChange = (e, idx) => {
    const { target } = e;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }
    targetValue = isTargetValueDigit ? targetValue : ' ';
    const oldValue = value;
    const newValue = oldValue.substring(0, idx) + targetValue + oldValue.substring(idx + 1);
    onChange(newValue);
    if (!isTargetValueDigit) {
      return;
    }
    const { nextElementSibling } = target;
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e) => {
    const { target } = e;
    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }
    const { previousElementSibling } = target;
    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  return (
    <div className="otpContainer">
      {valueItems.map((digit, idx) => (
        <input
          ref={inputRefs[idx]}
          key={idx}
          className="otpInput"
          value={digit}
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={1}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
        />
      ))}
    </div>
  );
};
