import React, { useEffect, useState } from 'react';

import { LeafletMap, LayerPoints } from '../../components/LeafletMap';
import { getWorkMapInfo } from '../../scripts/mapScripts';

export const ViewMap = () => {
  const [zoom, setZoom] = useState(14);
  const [center, setCenter] = useState({
    lat: -26.195607,
    lng: 28.025715,
  });

  useEffect(() => {
    document.title = 'View Map | Siemens Portal';

    async function fetchData() {
      const mapDefaults = await getWorkMapInfo();
      setZoom(mapDefaults.mapZoom);
      setCenter({
        lat: mapDefaults.defaultLat,
        lng: mapDefaults.defaultLong,
      });
    }
    fetchData();
  }, []);

  return (
    <LeafletMap
      style={{ height: '60vh' }}
      zoom={zoom}
      center={center}
      points
    >
      <LayerPoints />
    </LeafletMap>
  );
};
