import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getProfileCustomerTypes, updatePortalCustomerDetails } from '../../functions/apiCalls';
import { addressSchema, AddressForm } from './AddressForm';
import { Input, Select } from './Form';

function CustomerTypeSelect() {
  const [options, setOption] = useState([]);
  useEffect(() => {
    async function getData() {
      const data = await getProfileCustomerTypes();
      setOption(data);
    }
    getData();
  }, []);
  return (
    <Select label="Customer.customerType" name="customerType" options={options} />
  );
}

// yup validation schema
const customerSchema = yup.object().shape({
  code: yup.string().nullable().required(),
  description: yup.string().nullable().required(),
  customerType: yup.string().nullable(),
  orgUnitCode: yup.string().nullable(),
  paymentTermsDays: yup.string().nullable(),
  taxRegNumber: yup.string().nullable(),
  contactPerson: yup.string().nullable(),
  mobileNumber: yup.string().nullable(),
  officeNumber: yup.string().nullable(),
  homeNumber: yup.string().nullable(),
  faxNumber: yup.string().nullable(),
  emailAddress: yup.string().nullable(),
  user: yup.object().shape({
    username: yup.string().nullable(),
    password: yup.string().nullable(),
    passwordHint: yup.string().nullable(),
  }),
  ...addressSchema,
});

export const CustomerAccountDetailsForm = () => (
  <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
    <Typography variant="h5" gutterBottom>
      Customer Account Details
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Input label="Customer.code" name="code" disabled />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.description" name="description" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <CustomerTypeSelect />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.orgUnit" name="orgUnitCode" disabled />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.paymentTermsDays" name="paymentTermsDays" disabled />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.taxRegNumber" name="taxRegNumber" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.user.username" name="user.username" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.user.password" name="user.password" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.user.passwordHint" name="user.passwordHint" />
      </Grid>
    </Grid>
  </div>
);

export const ContactPersonForm = () => (
  <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
    <Typography variant="h5" gutterBottom>
      Contact Person
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Input label="Customer.contactPerson" name="contactPerson" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.mobileNumber" name="mobileNumber" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.officeNumber" name="officeNumber" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.homeNumber" name="homeNumber" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.faxNumber" name="faxNumber" />
      </Grid>
      <Grid item xs={6}>
        <Input label="Customer.emailAddress" name="emailAddress" />
      </Grid>
    </Grid>
  </div>
);

/**
 * Customer Form Component
 */
export const CustomerForm = ({ defaults }) => {
  const methods = useForm({
    resolver: yupResolver(customerSchema),
    defaultValues: defaults,
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = (data) => {
    updatePortalCustomerDetails(data);
    alert('Submitted');
  };

  console.log('defaults: ', defaults);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomerAccountDetailsForm />
        <ContactPersonForm />
        <AddressForm title="Customer Address" />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: '1rem' }}
        >
          <Button variant="contained" component={Link} to="/home">Cancel</Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Submit</Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
