import React from 'react';
import {
  Button, Form, Row, Col, Card,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress as Spinner } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as login from '../scripts/loginScripts';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitting: false,
      loginDone: false,
      passwordDisplay: 'password',
    };
  }

  handleLogin = () => {
    console.log('handling login');
    const { onLogin } = this.props;
    this.setState({ submitting: true });
    onLogin(this.state, (submitting, loginDone) => this.setState({ submitting, loginDone }));
  };

  render() {
    const {
      username, submitting, loginDone, passwordDisplay,
    } = this.state;
    const { subscriber, subscriberId, withRegister = true } = this.props;

    return (
      <Row id="loginFormContainer">
        <Col xs={0} md={3} />
        <Col md={5}>
          <Card id="loginFormCard" className="text-center">
            <Card.Header>
              <Card.Title>
                <b>Log in</b>
              </Card.Title>
              <Card.Subtitle>
                Sign in to your
                {' '}
                {subscriber}
                {' '}
                account
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group>
                  <Material.TextField
                    name="username"
                    fullWidth
                    label="Username"
                    variant="outlined"
                    onChange={(event) => this.setState({ username: event.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Material.TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type={passwordDisplay}
                    onChange={(event) => this.setState({ password: event.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  {!submitting && (
                    <Button
                      type="submit"
                      variant={loginDone ? 'secondary' : 'primary'}
                      disabled={loginDone}
                      onClick={this.handleLogin}
                    >
                      Login
                    </Button>
                  )}
                  {submitting && <Spinner />}
                </Form.Group>
                {withRegister && (
                  <Form.Group>
                    <Link to="/register">
                      <Button variant="outline-info">
                        Register a new profile
                      </Button>
                    </Link>
                  </Form.Group>
                )}
              </Form>
            </Card.Body>
            <Card.Footer>
              Forgot your password?
              <br />
              Have your
              {' '}
              <Material.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  login.passwordHint(username, subscriberId);
                }}
                underline="always"
                color="primary"
              >
                password hint SMS&apos;d to you
              </Material.Link>
              {' '}
              or
              {' '}
              <Material.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  login.passwordReset(username, subscriberId);
                }}
                underline="always"
              >
                reset it
              </Material.Link>
              .
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={0} md={3} />
      </Row>
    );
  }
}
