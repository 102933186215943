import _ from 'lodash';

export const handleLogging = ({ entries, requiredMissing }, setState) => {
  console.log('handling logging');
  let stillRequired = false;
  const toUpdate = { ...requiredMissing };
  Object.keys(requiredMissing).forEach((req) => {
    if (entries[req] == null || entries[req] === '') {
      toUpdate[req] = true;
      stillRequired = true;
    }
  });
  setState({ requiredMissing: toUpdate });
  if (stillRequired) {
    alert('You are missing some required fields');
    return false;
  }
  setState({ submitting: true });
  return true;
};

/**
 * Validates all the fields (entries) in a form, generates and sets error messages,
 * and returns true if all the tests are passed, i.e. form may be submitted.
 * */
export const handleValidation = ({ entries }, setState) => {
  console.log('handling form validation');
  setState({ errors: {} });
  const errors = {};
  let formIsValid = true;

  // phone number validation (10 digits length)
  const numberFields = ((
    { mobileNumber, officeNumber, homeNumber },
  ) => ({ mobileNumber, officeNumber, homeNumber }))(entries);
  const isNumberValid = (number) => /^\d{10}$/.test(number);
  Object.keys(numberFields).forEach((number) => {
    if (numberFields[number] && !isNumberValid(numberFields[number])) {
      formIsValid = false;
      errors[number] = 'Invalid phone number.';
    }
  });

  //  password validation (more than 6 characters,
  //  at least one capital, numeric or special character)
  //  const { password } = entries;
  //  const isPasswordValid = (password) =>
  //  /^.*(?=.{6,})(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\\d)|(?=.*[!#$%&? "]).*$/.test(password);
  //  if (password !== undefined && !isPasswordValid(password)) {
  //  formIsValid = false;
  //  errors[number] = 'Your password must be more than 6 characters,
  //  with at least one capital, numeric or special character.';
  //  }

  // more field validations, e.g. check for requiredMissing fields, etc...

  setState({ errors });
  return formIsValid;
};

// key = SERVER json
// value = CLIENT entries
// json structure flattened
export const reverseMapping = (mapping) => {
  const reversed = {};
  Object.entries(mapping).forEach(([key, val]) => {
    if (reversed[val]) console.error('duplicate key');
    if (_.isPlainObject(val)) {
      reversed[key] = {};
      Object.entries(val).forEach(([inkey, inval]) => {
        reversed[key][inval] = inkey;
      });
    } else reversed[val] = key;
  });
  console.log('reversed mapping:', reversed);
  return reversed;
};

// json -> entries
export const getEntries = (json, entriesToJsonMapping) => {
  console.log('json:', json);
  const mapping = reverseMapping(entriesToJsonMapping);
  console.log('json -> entries:', mapping);
  const entries = {};
  Object.entries(json).forEach(([key, val]) => {
    if (_.isPlainObject(val)) {
      Object.entries(val).forEach(([inkey, inval]) => {
        if (mapping[key] && mapping[key][inkey]) {
          const newKey = mapping[key][inkey];
          // console.log("newKey:", newKey, "inval", inval);
          entries[newKey] = inval || '';
        }
      });
    } else {
      const newKey = mapping[key];
      if (newKey != null) {
        // console.log("newKey:", newKey, val);
        entries[newKey] = val || '';
      }
    }
  });
  console.log('entries:', entries);
  return entries;
};

// entries -> json
export const getData = (entries, mapping) => {
  console.log('entries -> json:', mapping);
  const data = {};
  Object.entries(entries).forEach(([key, val]) => {
    // console.log("entry:", key, "val:", val);
    const newKey = mapping[key];
    if (newKey) {
      data[newKey] = val;
    } else {
      Object.entries(mapping).forEach(([mapkey, mapval]) => {
        if (_.isPlainObject(mapval)) {
          Object.entries(mapval).forEach(([inkey, inval]) => {
            if (inkey === key) {
              if (data[mapkey] == null) data[mapkey] = {};
              data[mapkey][inval] = val;
            }
          });
        }
      });
    }
  });
  console.log('data:', data);
  return data;
};

export const handleOTP = async (
  register,
  doLogin,
  entries,
  setState,
  setOtp,
  OTP = null,
) => {
  const newEntries = { ...entries, OTP };
  console.log('entries with/without OTP: ', newEntries);
  const registerResponse = await register(
    newEntries,
    setState,
    doLogin,
    OTP ? true : null,
  );
  console.log('register response:', registerResponse);
  const { result } = registerResponse;
  if (result === 'OTP_ERROR') setOtp('');
  if (result === 'OTP_REQUIRED' || result === 'OTP_ERROR') { alert('Another OTP was sent to you'); }
  if (result === 'ALREADY_REGISTERED' || result === 'SUCCESS') { setState({ showModal: false }); }
};
