import React from 'react';
import {
  MenuItem, FormControl, Select,
} from '@mui/material';

const SelectDropdown = (props) => {
  const {
    onChange, options, value, thisRef,
  } = props;

  return (
    <FormControl fullWidth>
      <Select
        ref={thisRef}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={onChange}
      >
        <MenuItem
          key="blank"
          value=""
          style={{
            display: 'flex',
            fontFamily: 'Flama',
            justifyContent: 'start',
            padding: '5px 10px',
          }}
        />
        {options && options.map(
          (singleTripOption, i) => (
            <MenuItem
              key={i}
              value={singleTripOption.value}
              style={{
                display: 'flex',
                fontFamily: 'Flama',
                justifyContent: 'start',
                padding: '5px 10px',
              }}
            >
              {singleTripOption.label}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
