import React, { useContext } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/ksl/context';

export const ViewTickets = () => {
  const { username } = useContext(Context);
  document.title = 'Requests | KSL Customer Portal';

  return (
    <Table
      Context={Context}
      title="Requests"
      tableModelClass="WorkOrderRequest"
      id="workorderrequest"
      columnView="Request View"
      filters={[{
        property: 'loggedBy.username',
        value: username,
        options: [],
      }]}
      sorts={{
        property: 'dateCreated',
        order: 'DESC',
      }}
    />
  );
};
