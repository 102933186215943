import React, { useContext } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/syntell/context';

export const ViewTickets = () => {
  const { username } = useContext(Context);
  document.title = 'Work requests | MTM Customer Portal';

  return (
    <Table
      Context={Context}
      title="Work Requests"
      tableModelClass="WorkOrderRequest"
      id="workorderrequest"
      columnView="Quote View"
      filters={[{
        property: 'customer.user.username',
        value: username,
        options: [],
      }, {
        property: 'workRequestStatus.open',
        value: 'true',
        options: [],
      }]}
      sorts={{
        property: 'dateCreated',
        // position: 0,
        order: 'DESC',
      }}
    />
  );
};
