import React, { useCallback } from 'react';
import { MenuItem, Select } from '@mui/material';

const DocumentSelectMenu = ({
  placeholder,
  options,
  onChange,
  selectedItem,
}) => {
  const changeSelectedValue = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <div
      className="select-menu"
    >
      <Select
        placeholder={placeholder}
        value={selectedItem}
        onChange={(event) => {
          changeSelectedValue(event.target.value);
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '50vh',
              overflow: 'auto',
            },
          },
        }}
      >
        {options !== undefined && options.map((option) => (
          <MenuItem key={`menu_item_${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DocumentSelectMenu;
