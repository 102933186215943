import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row, Col, Container, Card, Form, Button, ListGroup,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { Context } from '../../functions/servest_is/context';
import {
  getWorkOrder,
  byClassAndProp,
  getNotesForWO,
} from '../../functions/apiCalls';
import ImageViewerUploader from '../../components/ImageViewerUploader';
import Documents from '../../components/servest_is/documents';

/**
 * Page for viewing a Work Order
 */
const WorkOrderPage = () => {
  const params = useParams();
  const { code } = params;
  const { labels } = useContext(Context);
  const [workOrder, setWorkOrder] = useState();
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: workOrder });

  async function fetchData() {
    setLoading(true);
    const lookup2Res = await byClassAndProp('WorkOrder', 'customLookup2').then((res) => res.json());
    const priorityRes = await byClassAndProp('WorkOrder', 'priority').then((res) => res.json());
    const workTypeRes = await byClassAndProp('WorkOrder', 'workOrderType').then((res) => res.json());
    if (code !== undefined) {
      const wo = await getWorkOrder(code).then((res) => res.json());
      setWorkOrder({
        ...wo,
        customLookupCode2: lookup2Res.find((x) => (x.value === wo.customLookupCode2))?.label,
        priority: priorityRes.find((x) => (x.value === wo.priorityCode))?.label,
        workOrderType: workTypeRes.find((x) => (x.value === wo.typeCode))?.label,
      });
      const notesResp = await getNotesForWO(wo.id).then((res) => res.json());
      setNotes(notesResp);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.title = `View ${code} | Servest IS Portal`;
    reset(workOrder);
    workOrder !== undefined && setImages(workOrder.images !== undefined ? workOrder.images : []);
  }, [workOrder]);

  return (
    <Container fluid>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <Form>
            <Card>
              <Card.Header>
                <Card.Title className="text-center">
                  <h3>
                    <b>
                      View WorkOrder:
                      {' '}
                      {code}
                    </b>
                  </h3>
                </Card.Title>
              </Card.Header>
              <Dimmer inverted active={loading}>
                <Loader>Loading WorkOrder details</Loader>
              </Dimmer>
              <Card.Body className="ml-md-5 mr-md-5">
                <h4>
                  WorkOrder details
                </h4>
                <Form.Group className="mb-2">
                  <Form.Label>{labels['WorkOrder.description']}</Form.Label>
                  <Controller
                    readOnly
                    control={control}
                    name="description"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Control
                        readOnly
                        {...field}
                        isInvalid={errors.description}
                        placeholder="Enter problem description"
                        as="textarea"
                        rows={3}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.createdDateTime']}</Form.Label>
                      <Controller
                        control={control}
                        name="createdDate"
                        render={({
                          field: {
                            onChange, onBlur, value, ref,
                          },
                        }) => (
                          <Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            value={moment(value).format('DD/MM/YYYY HH:mm')}
                            ref={ref}
                            isInvalid={errors.createdDate}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.plannedStart']}</Form.Label>
                      <Controller
                        control={control}
                        name="plannedStartDate"
                        render={({
                          field: {
                            onChange, onBlur, value, ref,
                          },
                        }) => (
                          <Form.Control
                            onBlur={onBlur}
                            onChange={onChange}
                            value={moment(value).format('DD/MM/YYYY HH:mm')}
                            ref={ref}
                            isInvalid={errors.plannedStartDate}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.priority']}</Form.Label>
                      <Controller
                        control={control}
                        name="priority"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.priority}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.newStatus']}</Form.Label>
                      <Controller
                        control={control}
                        name="statusDescription"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.statusDescription}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.customLookup2']}</Form.Label>
                      <Controller
                        control={control}
                        name="customLookupCode2"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.customLookupCode2}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.workOrderType']}</Form.Label>
                      <Controller
                        control={control}
                        name="workOrderType"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.workOrderType}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.contactName']}</Form.Label>
                      <Controller
                        control={control}
                        name="contactName"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.contactName}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.contactNumber']}</Form.Label>
                      <Controller
                        control={control}
                        name="contactNumber"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.contactNumber}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>{labels['WorkOrder.contactEmail']}</Form.Label>
                      <Controller
                        control={control}
                        name="contactEmail"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            isInvalid={errors.contactEmail}
                            readOnly
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <h4>
                  Notes
                </h4>
                {notes !== undefined && notes.length > 0
                  ? (
                    <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                      <ListGroup variant="flush" style={{ border: '1px solid rgba(0,0,0,.125)', padding: '0px 12px', borderRadius: '4px' }}>
                        {notes.map(({ timestamp, noteText }) => (
                          <ListGroup.Item style={{ padding: '12px 0px' }}>
                            <strong>
                              {moment(timestamp).format('DD-MM-yyyy HH:mm')}
                              {' '}
                              |
                              {' '}
                            </strong>
                            {noteText}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )
                  : (<p>No notes</p>)}

                <h4>
                  Photos & Signatures
                </h4>
                {workOrder !== undefined
                && (
                <ImageViewerUploader
                  attachmentImages={workOrder.images}
                  images={images}
                  setImages={setImages}
                  readOnly={!!code}
                />
                )}

                <h4>
                  Documents
                </h4>
                {workOrder !== undefined
                && (
                <Documents
                  recordId={workOrder !== undefined ? `${workOrder.id}` : ''}
                  className="WorkOrder"
                />
                )}

              </Card.Body>
              <Card.Footer>
                <Row id="finalButtons" style={{ flexWrap: 'unset', padding: '0px 8px' }}>
                  <Link to="/home" style={{ width: '100%' }}>
                    <Button className="secondary-button" variant="secondary">
                      Close
                    </Button>
                  </Link>
                </Row>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
        <Col md={2} />
      </Row>
    </Container>
  );
};

export default WorkOrderPage;
