import React from 'react';
import {
  Card,
} from 'react-bootstrap';
import {
  StyledModal,
  ModalBody,
  ModalHeader,
  FormCard,
  CardHeading,
  CloseButton,
  InfoContainer,
} from './surveyPopup.styled';

const SurveyPopup = (props) => {
  const {
    show,
    onEntered,
    onHide,
    title,
    message,
    onClose,
  } = props;

  const handleClick = async () => {
    onClose();
  };

  return (
    <StyledModal
      {...{ show, onEntered, onHide }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader />
      <div>
        <ModalBody>
          <FormCard>
            <div>
              <CardHeading>
                {title}
              </CardHeading>
            </div>
            <InfoContainer>
              <h4>
                {message}
              </h4>

            </InfoContainer>
            <Card.Body />
          </FormCard>
          <CloseButton
            onClick={() => { handleClick(); }}
          >
            Close
          </CloseButton>
        </ModalBody>
      </div>
    </StyledModal>
  );
};

export default SurveyPopup;
