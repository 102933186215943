import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row, Col, Container, Card, Form, Button, Alert,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from '../../functions/ksl/context';
import {
  getPortalCustomerDetails,
  updatePortalCustomerDetails,
} from '../../functions/apiCalls';

/**
 * Page for viewing/changing the customer details
 */
const CustomerDetailsPage = () => {
  const { labels } = useContext(Context);

  const [customerDetails, setCustomerDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: customerDetails });

  useEffect(() => {
    document.title = 'Student Account Details | KSL Portal';

    async function fetchData() {
      setLoading(true);
      const response = await getPortalCustomerDetails().then((res) => res.json());
      setCustomerDetails(response);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => reset(customerDetails), [customerDetails]);

  const onSubmit = (data) => {
    console.log(data);

    const requestBody = {
      ...data,
      billingAddress: data.address,
    };

    async function postData() {
      setPosting(true);
      const response = await updatePortalCustomerDetails(requestBody).then((res) => res.json());
      setSubmitResponse(response);
      setPosting(false);
    }
    postData();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {submitResponse && (
        <Alert
          variant={submitResponse.result === 'SUCCESS' ? 'success' : 'danger'}
          dismissible
          onClose={() => setSubmitResponse(null)}
        >
          {submitResponse.result === 'SUCCESS'
            ? 'Successfully updated your details.'
            : submitResponse.errorMessage || 'Error: Could not update your details.'}
        </Alert>
      )}

      <Container fluid>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <Card.Header>
                  <Card.Title className="text-center">
                    <h3>
                      <b>Edit your details</b>
                    </h3>
                  </Card.Title>
                </Card.Header>

                <Dimmer inverted active={loading}>
                  <Loader>Loading your details</Loader>
                </Dimmer>

                <Card.Body className="ml-md-5 mr-md-5">
                  <h4>
                    Student Account Details
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['User.fullName']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.fullName"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.fullName}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User FullName</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.user?.fullName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.username']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.username"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.username}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User Username</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.createdDate?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.password']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.password"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.password}
                              type="password"
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User password</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.user?.password?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.passwordHint']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.passwordHint"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.passwordHint}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User passwordHint</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.user?.passwordHint?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h4>
                    Contact Details
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['User.mobileNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="mobileNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.mobileNumber}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">mobileNumber</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.mobileNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.phoneNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="officeNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.officeNumber}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">officeNumber</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.officeNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.email']}</Form.Label>
                        <Controller
                          control={control}
                          name="emailAddress"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.emailAddress}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">emailAddress</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.emailAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h4>
                    Student Address
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['address.address3']}</Form.Label>
                        <Controller
                          control={control}
                          name="address.address3"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.address?.address3}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">address.address3</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.address?.address3?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['address.address4']}</Form.Label>
                        <Controller
                          control={control}
                          name="address.address4"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.address?.address4}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">address.address4</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.address?.address4?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['address.address5']}</Form.Label>
                        <Controller
                          control={control}
                          name="address.address5"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.address?.address5}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">address.address5</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.address?.address5?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['address.address6']}</Form.Label>
                        <Controller
                          control={control}
                          name="address.address6"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.address?.address6}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">address.address6</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.address?.address6?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['address.address7']}</Form.Label>
                        <Controller
                          control={control}
                          name="address.address7"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.address?.address7}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">address.address7</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.address?.address7?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['address.address8']}</Form.Label>
                        <Controller
                          control={control}
                          name="address.address8"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.address?.address8}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">address.address8</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.address?.address8?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>

                <Card.Footer>
                  <Row className="justify-content-around">
                    <Link to="/home">
                      <Button variant="secondary">
                        Cancel
                      </Button>
                    </Link>
                    <Button disabled={posting} active={posting} variant="primary" type="submit">
                      Submit
                    </Button>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </>
  );
};

export default CustomerDetailsPage;
