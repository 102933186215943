import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row, Col, Container, Card, Form, Button, Alert, ListGroup,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { Context } from '../../functions/servest_is/context';
import {
  getWorkRequest,
  getWorkOrder,
  postWorkRequest,
  byClassAndProp,
  getPortalCustomerDetails,
  addWorkRequestAttachment,
  genericChangeStatus,
  getWorkRequestNoteTypes,
  addNoteToWorkRequest,
  getCustomLookup1Values,
} from '../../functions/apiCalls';
import {
  getAllStatuses,
} from '../../scripts/servest_is/createTicketScripts';
import { getNotes } from '../../scripts/botswanatourism/scripts';
import ImageViewerUploader from '../../components/ImageViewerUploader';
import Documents from '../../components/servest_is/documents';

/**
 * Page for viewing or creating a Work Order Request
 */
const WorkOrderRequestPage = () => {
  const history = useHistory();
  const params = useParams();
  const { code } = params;
  const { labels } = useContext(Context);

  // Work Request states
  const [workRequest, setWorkRequest] = useState();
  const [statuses, setStatuses] = useState([]);
  const [customLookup1, setCustomLookup1] = useState([]);
  const [customLookup2, setCustomLookup2] = useState([]);
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const [isAddNote, setIsAddNote] = useState(false);
  const [myNoteText, setMyNoteText] = useState('');

  // Form state and methods
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: workRequest });

  async function fetchData() {
    setLoading(true);
    // if an existing workrequest, get the workrequest with the same code
    if (code) {
      let wr = await getWorkRequest(code).then((res) => res.json());
      if (wr.docType === 'Quotation') {
        const wo = await getWorkOrder(wr.parentWorkOrderCode).then((res) => res.json());
        console.log(wo);
        let wrcode = '';
        for (let i = 0; i < wo.workRequestCodes.length; i++) {
          if (!wo.workRequestCodes[i].includes('QU')) {
            wrcode = wo.workRequestCodes[i];
          }
        }
        const wr2 = await getWorkRequest(wrcode).then((res) => res.json());
        console.log(wr2);
        wr = {
          ...wr,
          customLookupCode1: wr2.customLookupCode1,
          customLookupCode2: wr2.customLookupCode2,
        };
      }
      console.log('wr: ', wr);
      const lookup1Res = await getCustomLookup1Values();
      setCustomLookup1(lookup1Res);
      const lookup2Res = await byClassAndProp('WorkOrderRequest', 'customLookup2', 'referencelistmanager').then((res) => res.json());
      setCustomLookup2(lookup2Res);
      setWorkRequest({
        ...wr,
      });

      // Get notes
      setNotes(await getNotes(wr.id));

      // get statuses
      const statusesList = await getAllStatuses(wr.id);
      setStatuses(statusesList);
    } else {
      // if a new workrequest, get customer portal details to prepopulate the form
      const customerDetails = await getPortalCustomerDetails().then((res) => res.json());
      setWorkRequest({
        createdDate: moment().valueOf(),
        customerCode: customerDetails.code,
        customerDescription: customerDetails.description,
        emailAddress: customerDetails.emailAddress,
        address: customerDetails.address,
        childWorkType: '',
        contactEmail: customerDetails.user.email || customerDetails.emailAddress,
        officeNumber: customerDetails.officeNumber,
        //   || customerDetails.user.mobileNumber
        //   || customerDetails.officeNumber
        //   || customerDetails.mobileNumber,
        docType: 'WorkOrderRequest',
        resourceFeedback: '',
        workType: 'CFP',
      });

      const lookup1Res = await getCustomLookup1Values();
      setCustomLookup1(lookup1Res);
      const lookup2Res = await byClassAndProp('WorkOrderRequest', 'customLookup2', 'referencelistmanager').then((res) => res.json());
      setCustomLookup2(lookup2Res);
    }

    // setTypes(await getWorkRequestTypes().then((res) => res.json()));
    // setSubtypes(await getWorkRequestSubtypes()); // WR subtypes // N/A

    setLoading(false);
  }

  // Effect after first render
  useEffect(() => {
    document.title = code
      ? `Edit Ticket ${code} | Servest IS Portal`
      : 'Create Ticket | Servest IS Portal';

    fetchData();
  }, []);

  // Populate form with Work Request data
  useEffect(() => {
    reset(workRequest);
    setImages(workRequest?.images);
  }, [workRequest]);

  // Redirect to home page after WR successfully created
  useEffect(() => {
    if (!code && submitResponse?.result === 'SUCCESS') {
      history.push('home');
      alert('Successfully logged ticket');
    }
  }, [submitResponse, history]);

  // Submit handler, create or edit Work Request
  const onSubmit = (data) => {
    console.log(data);

    async function postData() {
      setPosting(true);
      console.log('customLookup1', data.customLookupCode1, customLookup1);
      console.log('customLookup2', data.customLookupCode2, customLookup2);

      let customLookup1Code = null;
      let customLookup2Code = null;
      if (workRequest.docType !== 'Quotation') {
        if (data.customLookupCode1) {
          customLookup1Code = data.customLookupCode1;
        } else {
          customLookup1Code = customLookup1[0].value;
        }
        if (data.customLookupCode1) {
          customLookup2Code = data.customLookup2Code;
        } else {
          customLookup2Code = customLookup2[0].value;
        }
      }

      const workRequestObject = {
        ...workRequest,
        ...data,
        customLookupCode1: customLookup1Code,
        customLookupCode2: customLookup2Code,
      };

      console.log('wr', workRequestObject);

      const response = await postWorkRequest(workRequestObject).then((res) => res.json());

      // Upload images after a new ticket was successfully logged
      if (!code && response.result === 'SUCCESS' && images?.length > 0) {
        const wr = await getWorkRequest(response.code);
        const wrObj = await wr.json();
        const id = await wrObj.id;

        images?.forEach(async (i) => {
          const formData = new FormData();
          formData.append('workRequestId', id);
          formData.append('file', i.file);
          await addWorkRequestAttachment(formData, 'servest_is');
        });
      }

      setSubmitResponse(response);
      setPosting(false);
    }

    postData();

    // scroll to top after submit
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  async function addingNoteHandler() {
    if (isAddNote) {
      setIsAddNote(false);
    } else {
      setIsAddNote(true);
    }
  }

  async function submitNoteForm(event) {
    event.preventDefault();
    const response = await getWorkRequestNoteTypes();
    const workRequestId = workRequest.id;
    const noteTypeId = response[0].value;
    console.log('note form');
    console.log(`work request id: ${workRequestId}`);
    console.log('note type id: ', noteTypeId);
    if (myNoteText === '') {
      console.log('note: No note text');
    } else {
      console.log(`note: ${myNoteText}`);
      await addNoteToWorkRequest(
        workRequestId,
        noteTypeId,
        myNoteText,
      );
      setNotes(await getNotes(workRequest.id));
      setMyNoteText('');
    }
  }

  const closeAddNoteForm = () => {
    setIsAddNote(false);
  };

  const noteInputChangeHandler = (event) => {
    setMyNoteText(event.target.value);
  };

  return (
    <>
      {submitResponse && (
        <Alert
          variant={submitResponse.result === 'SUCCESS' ? 'success' : 'danger'}
          dismissible
          onClose={() => setSubmitResponse(null)}
        >
          {submitResponse.result === 'SUCCESS'
            ? `Successfully ${code ? 'updated' : 'logged'} ticket ${submitResponse.code}.`
            : submitResponse.errorMessage || `Error: Could not ${code ? 'update' : 'log'} ticket.`}
        </Alert>
      )}

      <Container fluid>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <Card.Header>
                  <Card.Title className="text-center">
                    <h3>
                      <b>{code ? `Edit Ticket: ${code}` : 'Create Ticket'}</b>
                    </h3>
                  </Card.Title>
                </Card.Header>

                <Dimmer inverted active={loading}>
                  <Loader>Loading ticket details</Loader>
                </Dimmer>

                <Card.Body className="ml-md-5 mr-md-5">
                  <h4>
                    Ticket details
                  </h4>

                  {code && (
                    <Row>
                      <Col md="6" sm="12">
                        <Form.Group className="mb-2">
                          <Form.Label>{labels['WorkOrderRequest.dateCreated']}</Form.Label>
                          <Controller
                            control={control}
                            name="createdDate"
                            render={({
                              field: {
                                onChange, onBlur, value, ref,
                              },
                            }) => (
                              <Form.Control
                                onBlur={onBlur}
                                onChange={onChange}
                                value={moment(value).format('DD/MM/YYYY HH:mm')}
                                ref={ref}
                                isInvalid={errors.createdDate}
                                readOnly
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6" sm="12">
                        <Form.Group className="mb-2">
                          <Form.Label>{labels['WorkOrderRequest.workRequestStatus.description']}</Form.Label>
                          {workRequest !== undefined && workRequest.docType === 'Quotation'
                            ? (
                              <Controller
                                control={control}
                                name="statusCode"
                                render={({ field }) => (
                                  <Form.Control
                                    as="select"
                                    {...field}
                                    isInvalid={errors.statusCode}
                                    onChange={async (event) => {
                                      await genericChangeStatus(
                                        event.target.value, workRequest.id,
                                      );
                                      fetchData();
                                    }}
                                  >
                                    {statuses.map(({ value, label }) => (
                                      <option value={value}>{label}</option>
                                    ))}
                                  </Form.Control>
                                )}
                              />
                            ) : (
                              <Controller
                                control={control}
                                name="statusDescription"
                                render={({ field }) => (
                                  <Form.Control
                                    {...field}
                                    isInvalid={errors.statusDescription}
                                    readOnly
                                  />
                                )}
                              />
                            )}
                        </Form.Group>
                      </Col>
                    </Row>

                  )}

                  <Form.Group className="mb-2">
                    <Form.Label>{labels['WorkOrderRequest.description']}</Form.Label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.description}
                          placeholder="Enter problem description"
                          as="textarea"
                          rows={3}
                        />
                      )}
                    />
                    {/* <Form.Text className="text-muted">Description</Form.Text> */}
                    <Form.Control.Feedback type="invalid">
                      {errors.username?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['WorkOrderRequest.customLookup1']}</Form.Label>
                        <Controller
                          control={control}
                          name="customLookupCode1"
                          render={({ field }) => (
                            <Form.Control
                              as="select"
                              {...field}
                              isInvalid={errors.customLookupCode1}
                              disabled={workRequest !== undefined && workRequest.docType === 'Quotation'}
                            >
                              {customLookup1.map(({ value, label }) => (
                                <option value={value}>{label}</option>
                              ))}
                            </Form.Control>
                          )}
                        />
                        {/* <Form.Text className="text-muted">Custom 1</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.customLookupCode1?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['WorkOrderRequest.customLookup2']}</Form.Label>
                        <Controller
                          control={control}
                          name="customLookupCode2"
                          render={({ field }) => (
                            <Form.Control
                              as="select"
                              {...field}
                              isInvalid={errors.customLookupCode2}
                              disabled={workRequest !== undefined && workRequest.docType === 'Quotation'}
                            >
                              {customLookup2.map(({ value, label }) => (
                                <option value={value}>{label}</option>
                              ))}
                            </Form.Control>
                          )}
                        />
                        {/* <Form.Text className="text-muted">Custom 2</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.customLookupCode2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['WorkOrderRequest.contactName']}</Form.Label>
                        <Controller
                          control={control}
                          name="contactName"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactName}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Custom 1</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.contactName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['WorkOrderRequest.contactNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="contactNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactNumber}
                            />
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.contactNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h4>
                    Customer details
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.code']}</Form.Label>
                        <Controller
                          control={control}
                          name="customerCode"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.customerCode}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Customer Code</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.createdDate?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.description']}</Form.Label>
                        <Controller
                          control={control}
                          name="customerDescription"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.customerDescription}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Customer Description</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.customerDescription?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.officeNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="customerOfficeNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.officeNumber}
                            />
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.officeNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.emailAddress']}</Form.Label>
                        <Controller
                          control={control}
                          name="contactEmail"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactEmail}
                            />
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.contactEmail?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row> */}

                  {code && (
                    <>
                      <h4>
                        Notes
                      </h4>
                      {!isAddNote && (
                        <div id="finalButtons" style={{ justifyContent: 'start', marginBottom: '16px' }}>
                          <Button
                            className="primary-button"
                            style={{ width: 'fit-content', height: 'fit-content' }}
                            onClick={addingNoteHandler}
                          >
                            Add Note
                          </Button>
                        </div>
                      )}
                      {isAddNote && (
                        <Card style={{ padding: '1em' }}>
                          <h4>Message:</h4>
                          <div>
                            <div
                              style={{ marginBottom: '16px' }}
                            >
                              <input
                                style={{
                                  display: 'block',
                                  width: '100%',
                                  height: 'calc(1.5em + 0.75rem + 2px)',
                                  padding: '0.375rem 0.75rem',
                                  fontSize: '1rem',
                                  fontWeight: '400',
                                  lineHeight: '1.5',
                                  color: '#495057',
                                  backgroundColor: '#fff',
                                  backgroundClip: 'padding-box',
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                }}
                                id="myNoteText"
                                type="text"
                                onChange={noteInputChangeHandler}
                                value={myNoteText}
                              />
                            </div>
                            <div id="finalButtons">
                              <Button
                                className="secondary-button"
                                onClick={closeAddNoteForm}
                                variant="secondary"
                                style={{ height: 'fit-content' }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="primary-button"
                                onClick={submitNoteForm}
                                style={{ height: 'fit-content' }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </Card>
                      )}
                      {notes.length > 0 && (
                        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                          <ListGroup variant="flush" style={{ border: '1px solid rgba(0,0,0,.125)', padding: '0px 12px', borderRadius: '4px' }}>
                            {notes.map(({ timestamp, noteText }) => (
                              <ListGroup.Item style={{ padding: '12px 0px' }}>
                                <strong>
                                  {moment(timestamp).format('DD-MM-yyyy HH:mm')}
                                  {' '}
                                  |
                                  {' '}
                                </strong>
                                {noteText}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      )}
                      {notes.length === 0 && (
                        <p>No notes</p>
                      )}
                    </>
                  )}

                  <h4>
                    Photos
                  </h4>
                  <ImageViewerUploader
                    attachmentImages={workRequest?.images}
                    images={images}
                    setImages={setImages}
                    readOnly={!!code}
                  />

                  {code
                    && (
                      <>
                        <h4>
                          Documents
                        </h4>
                        {workRequest !== undefined
                          && (
                            <Documents
                              recordId={workRequest !== undefined ? `${workRequest.id}` : ''}
                              className={workRequest.docType}
                              editable
                            />
                          )}
                      </>
                    )}

                </Card.Body>
                <Card.Footer>
                  <Row id="finalButtons" style={{ flexWrap: 'unset', padding: '0px 8px' }}>
                    <Link to="/home" style={{ width: '100%' }}>
                      <Button className="secondary-button" variant="secondary">
                        Cancel
                      </Button>
                    </Link>
                    <Button className="primary-button" disabled={posting} active={posting} variant="primary" type="submit">
                      Submit
                    </Button>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </>
  );
};

export default WorkOrderRequestPage;
