import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/siemens/context';
import {
  getPortalCustomerDetails,
} from '../../functions/apiCalls';

export function ViewTickets() {
  document.title = 'Service Tickets | Siemens Portal';
  const [filters, setFilters] = useState();

  useEffect(() => {
    const setTable = async () => {
      const customerDetails = await getPortalCustomerDetails().then((res) => res.json());
      const newFilter = {
        property: 'customer.id',
        value: customerDetails.id,
        options: [],
      };
      setFilters([newFilter]);
    };
    setTable();
  }, []);

  return (
    <>
      {filters
        ? (
          <Table
            Context={Context}
            title="Service Tickets"
            tableModelClass="WorkOrderRequest"
            id="workorderrequest"
            columnView="Detail View"
            filters={filters}
            sorts={{
              property: 'dateCreated',
              order: 'DESC',
            }}
          />
        ) : (
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        )}
    </>
  );
}
