import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ButtonContainer,
  CancelButton,
  SubmitButton,
} from '../forms.styled';
import Ripple from '../../ripple';

const FormButtons = (props) => {
  const { doSubmit } = props;
  const history = useHistory();

  const handleSubmit = () => {
    doSubmit();
  };

  return (
    <ButtonContainer>
      <CancelButton
        tabIndex={0}
        onClick={() => { history.push('/home'); }}
        className="button"
      >
        Cancel
        <Ripple color="#666666" />
      </CancelButton>
      <SubmitButton
        tabIndex={0}
        onClick={() => { handleSubmit(); }}
        className="button"
      >
        Submit
        <Ripple color="#666666" />
      </SubmitButton>
    </ButtonContainer>
  );
};

export default FormButtons;
