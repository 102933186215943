import React from 'react';
import {
  Box,
  LinearProgress,
} from '@mui/material';
import {
  FormContainer,
  OuterContainer,
  InnerContainer,
  StyleContainer,
  Footer,
} from '../forms.styled';
import FormHeader from './formheader';
import SlideIn from '../../slidein';
import AlertBar from '../../alertBar';

const FormTemplate = (props) => {
  const {
    title,
    children,
    openSnackbar,
    setOpenSnackbar,
    toastMessage,
    validSubmit,
    loading,
  } = props;

  return (
    <FormContainer>
      <AlertBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        toastMessage={toastMessage}
        validSubmit={validSubmit}
      />
      <OuterContainer>
        <div />
        <InnerContainer>
          <Box sx={{
            width: '100%', height: '4px',
          }}
          >
            {loading && <LinearProgress sx={{ color: '#102b7c' }} />}
          </Box>
          <StyleContainer>
            <SlideIn settings={{
              marginRight: 0,
              width: '100%',
              height: '100%',
              from: { marginRight: -450 },
              config: { mass: 1, tension: 300, friction: 30 },
            }}
            >
              <FormHeader title={title} />
              {children}
            </SlideIn>
          </StyleContainer>
          <Box sx={{
            width: '100%', height: '4px',
          }}
          >
            {loading && <LinearProgress sx={{ color: '#102b7c' }} />}
          </Box>
        </InnerContainer>
        <Footer>
          <div
            style={{ paddingRight: '5px' }}
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net');
            }}
          >
            Powered by Forcelink&trade; 2024 |
          </div>
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net/privacy-policy');
            }}
          >
            Privacy Policy
          </div>
        </Footer>
      </OuterContainer>
    </FormContainer>
  );
};

export default FormTemplate;
