import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Container } from 'react-bootstrap';
import { Context } from '../../functions/botswanatourism/context';
import '../../styles/botswanatourism/home.scss';

export class Downloads extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      readyToRedirect: false,
      redirectTo: '',
    };
  }

  render() {
    const {
      readyToRedirect,
      redirectTo,
    } = this.state;

    if (readyToRedirect && redirectTo !== '') {
      return <Redirect to={redirectTo} />;
    }

    // replace za1 with za2
    return (
      <Container>
        <Row>
          <col-12>
            <b>Grading System Reference Docs</b>
            <hr />
            <a href="https://za2.forcelink.net/forcelink/documents/Facilities Portal User Guide.pdf?className=Customer&id=1047&subscriberId=bto">
              Facilities Portal User Guide
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/BTO Grading Process Information.pdf?className=Customer&id=1047&subscriberId=bto">
              Introduction to BTO Grading Process
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/BTO Ecotourism Grading Process Information.pdf?className=Customer&id=1047&subscriberId=bto">
              Introduction to Ecotourism Grading Process
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Frequently Asked Questions.pdf?className=Customer&id=1047&subscriberId=bto">
              Frequently Asked Questions
            </a>
            <br />
            <hr />
            <b>Self-Assessment Forms</b>
            <hr />
            <a href="https://za2.forcelink.net/forcelink/documents/Fully Serviced 1 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Fully Serviced Hotel: 1 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Fully Serviced 2 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Fully Serviced Hotel: 2 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Fully Serviced 3 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Fully Serviced Hotel: 3 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Fully Serviced 4 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Fully Serviced Hotel: 4 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Fully Serviced 5 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Fully Serviced Hotel: 5 Star
            </a>
            <br />
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Selected Serviced 1 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Selected Service Hotel: 1 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Selected Serviced 2 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Selected Service Hotel: 2 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Selected Serviced 3 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Selected Service Hotel: 3 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Selected Serviced 4 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Selected Service Hotel: 4 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Selected Serviced 5 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Selected Service Hotel: 5 Star
            </a>
            <br />
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Guesthouse 1 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Guest House: 1 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Guesthouse 2 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Guest House: 2 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Guesthouse 3 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Guest House: 3 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Guesthouse 4 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Guest House: 4 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Guesthouse 5 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Guest House: 5 Star
            </a>
            <br />
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Bed and Breakfast 1 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Bed and Breakfast: 1 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Bed and Breakfast 2 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Bed and Breakfast: 2 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Bed and Breakfast 3 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Bed and Breakfast: 3 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Bed and Breakfast 4 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Bed and Breakfast: 4 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Bed and Breakfast 5 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Bed and Breakfast: 5 Star
            </a>
            <br />
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Game Lodges and Tented Camps 1 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Game Lodges and Tented Camps: 1 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Game Lodges and Tented Camps 2 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Game Lodges and Tented Camps: 2 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Game Lodges and Tented Camps 3 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Game Lodges and Tented Camps: 3 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Game Lodges and Tented Camps 4 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Game Lodges and Tented Camps: 4 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Game Lodges and Tented Camps 5 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Game Lodges and Tented Camps: 5 Star
            </a>
            <br />
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Self Catering 1 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Self Catering: 1 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Self Catering 2 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Self Catering: 2 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Self Catering 3 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Self Catering: 3 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Self Catering 4 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Self Catering: 4 Star
            </a>
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Self Catering 5 Star.doc?className=Customer&id=1047&subscriberId=bto">
              Self Catering: 5 Star
            </a>
            <br />
            <br />
            <a href="https://za2.forcelink.net/forcelink/documents/Ecotourism.xlsx?className=Customer&id=1047&subscriberId=bto">
              EcoTourism
            </a>
            <br />
          </col-12>
        </Row>
      </Container>
    );
  }
}
