import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  getPriorities, getTechnicians, postWorkRequest,
} from '../../scripts/siemens/createTicketScripts';
import { getWorkRequestTypes, getWorkRequestSubtypes } from '../../functions/apiCalls';
import { addressSchema, AddressForm } from './AddressForm';
import { Input, Datetime, Select } from './Form';

/**
 * Work Request type and subtypes Select
 */
function WorkRequestTypeSelect() {
  const [options, setOptions] = useState([]);
  // const wrTypesWithSubtypes = ['WAR', 'SLA']; //TODO: remove later
  useEffect(() => {
    async function getData() {
      const items = [];
      const typesData = await getWorkRequestTypes().then((res) => res.json());
      typesData.forEach(async (type) => {
        const subtypesData = await getWorkRequestSubtypes(type.value).then((res) => res.json());
        const hasSubtypes = !!subtypesData.length;

        items.push({ label: type.label, value: type.value, disabled: hasSubtypes });
        subtypesData.forEach((subtype) => items.push({ label: `↳ ${subtype.label}`, value: subtype.value }));
      });
      setOptions(items);
    }
    getData();
  }, []);
  return (
    <Select label="WorkOrderRequest.workRequestType" name="typeCode" options={options} />
  );
}

function PrioritySelect() {
  const [options, setOption] = useState([]);
  useEffect(() => {
    async function getData() {
      const data = await getPriorities();
      // [
      //   { label: 'High', value: 'HI', attributes: {}, fieldHandler: null },
      //   { label: 'Normal', value: 'ME', attributes: {}, fieldHandler: null },
      //   { label: 'Low', value: 'LO', attributes: {}, fieldHandler: null },
      // ];
      setOption(data);
    }
    getData();
  }, []);
  return (
    <Select label="WorkOrderRequest.priority" name="priorityCode" options={options} />
  );
}

function TechnicianSelect() {
  const [options, setOption] = useState([]);
  useEffect(() => {
    async function getData() {
      const data = await getTechnicians();
      setOption(data);
    }
    getData();
  }, []);
  return (
    <Select label="WorkOrderRequest.custom2" name="custom2" options={options} />
  );
}

// yup validation schema
const workRequestSchema = yup.object().shape({
  description: yup.string().nullable().required(),
  typeCode: yup.string().nullable().required(),
  childWorkType: yup.string().nullable(),
  statusCode: yup.string().nullable(),
  statusDescription: yup.string().nullable(),
  appointment: yup.string().nullable(),
  priorityCode: yup.string().nullable().required(),
  orgUnitCode: yup.string().nullable(),
  customerCode: yup.string().nullable(),
  customerDescription: yup.string().nullable(),
  custom1: yup.string().nullable(),
  custom2: yup.string().nullable().required(),
  custom3: yup.string().nullable(),
  custom4: yup.string().nullable(),
  custom5: yup.string().nullable(),
  custom6: yup.string().nullable(),
  custom7: yup.string().nullable(),
  custom8: yup.string().nullable(),
  custom9: yup.string().nullable(),
  custom10: yup.string().nullable(),
  custom11: yup.string().nullable(),
  custom12: yup.string().nullable(),
  custom13: yup.string().nullable(),
  custom14: yup.string().nullable(),
  custom15: yup.string().nullable(),
  customNumber1: yup.string().nullable(),
  customNumber2: yup.string().nullable(),
  customNumber3: yup.string().nullable(),
  customNumber4: yup.string().nullable(),
  customNumber5: yup.string().nullable(),
  customLookupCode1: yup.string().nullable(),
  customLookupCode2: yup.string().nullable(),
  customLookupCode3: yup.string().nullable(),
  customLookupCode4: yup.string().nullable(),
  customLookupCode5: yup.string().nullable(),
  ...addressSchema,
  createdDate: yup.string().nullable(),
  customDate1: yup.string().nullable(),
  customDate2: yup.string().nullable(),
  customDate3: yup.string().nullable(),
  customDate4: yup.string().nullable(),
  customDate5: yup.string().nullable(),
  customDate6: yup.string().nullable(),
  customDate7: yup.string().nullable(),
  customDate8: yup.string().nullable(),
  customDate9: yup.string().nullable(),
  customDate10: yup.string().nullable(),
  plannedStartDate: yup.string().nullable(),
  durationHours: yup.string().nullable(),
  systemStatusId: yup.string().nullable(),
  assetCodes: yup.array().nullable(),
  assetCodesToRemove: yup.array().nullable(),
  resolutionCode: yup.string().nullable(),
  contactName: yup.string().nullable(),
  contactNumber: yup.string().nullable(),
  contactEmail: yup.string().nullable(),
  parentWorkOrderCode: yup.string().nullable(),
  resourceFeedback: yup.string().nullable(),
  documents: yup.array().nullable(),
  images: yup.array().nullable(),
  notes: yup.array().nullable(),
  docType: yup.string().nullable(),
  billOfMaterialsGroup: yup.string().nullable(),
  workRequestCodes: yup.array().nullable(),
  allocatedToText: yup.string().nullable(),
  inspectionListGroup: yup.string().nullable(),
  workType: yup.string().nullable(),
  systemStatus: yup.string().nullable(),
});

/**
 * Ticket Form Component
 */
export const TicketForm = ({ defaults }) => {
  const methods = useForm({
    resolver: yupResolver(workRequestSchema),
    defaultValues: defaults,
  });

  const {
    handleSubmit, formState: {
      isSubmitting,
      errors,
    },
  } = methods;

  const onSubmit = async (data) => {
    console.log('data', data);
    await postWorkRequest(data);
    alert('Submitted');
  };

  console.log('defaults: ', defaults);
  console.log('errors: ', errors);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <WorkRequestTypeSelect />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PrioritySelect />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TechnicianSelect />
          </Grid>
          <Grid item xs={6}>
            {/* Convert Region/Contractor to select */}
            <Input label="WorkOrderRequest.orgUnit" name="orgUnitCode" disabled />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkDoc.createdDate" name="createdDate" now disabled />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkOrderRequest.appointment" name="appointment" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactName" name="contactName" disabled />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactNumber" name="contactNumber" disabled />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactEmail" name="contactEmail" disabled />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactEmailCC" name="contactEmailCC" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom1" name="custom1" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom3" name="custom3" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom4" name="custom4" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom5" name="custom5" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom8" name="custom8" />
          </Grid>
          <Grid item xs={12}>
            <Input label="WorkOrderRequest.description" name="description" />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkOrderRequest.customDate1" name="customDate1" disabled />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkOrderRequest.customDate2" name="customDate2" disabled />
          </Grid>
        </Grid>
        <AddressForm title="Client site" />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: '1rem' }}
        >
          <Button variant="contained" component={Link} to="/home">Cancel</Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Submit</Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
