import React from 'react';
import '../style.scss';
import RefundForm from '../../../components/myciti/forms/refundform';

export const Refund = () => {
  document.title = 'Refund | MyCiTi Portal';
  return <RefundForm />;
};

export default Refund;
