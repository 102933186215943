import React from 'react';
import { TextField } from '@mui/material';

const TextArea = (props) => {
  const {
    description, onChange, size, value, thisRef, helperText,
  } = props;

  return (
    <div className="textarea">
      <TextField
        ref={thisRef}
        id="outlined-multiline-flexible"
        label={value === '' && description}
        multiline
        rows={4}
        value={value}
        onChange={(event) => { onChange(event, size); }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p
          style={{ alignItems: 'end', display: 'flex', margin: '3px 0px 0px 0px' }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          {helperText}
        </p>
        <p
          style={{ alignItems: 'end', display: 'flex', margin: '3px 0px 0px 0px' }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          {`${value.length}/${size}`}
        </p>
      </div>
    </div>
  );
};

export default TextArea;
