import React from 'react';
import '../style.scss';
import LostOrStolenForm from '../../../components/myciti/forms/lostorstolenform';

export const LostOrStolen = () => {
  document.title = 'Lost or Stolen | MyCiTi Portal';
  return <LostOrStolenForm />;
};

export default LostOrStolen;
