import { fetchApi } from '../functions/apiCalls';
import { apiUrl, tomtomApiKey } from '../constants/constantsGlobal';

const tomtomApi = 'api.tomtom.com/search/2/';
const tomtomSearchApi = (query, lat, lng, radius) => `${tomtomApi}search/${query}.json?typeahead=true&language=en-GB&countrySet=ZA&radius=${
  radius * 1000
}&view=Unified&minFuzzyLevel=1&maxFuzzyLevel=2&idxSet=PAD%2CStr%2CXstr%2CGeo%2CAddr${lat && lng ? `&lat=${lat}&lon=${lng}` : ''}&key=${tomtomApiKey}`;

// Tom Tom not working
export const getSearchedPlaces = async (...args) => {
  // const response = await fetchApi(tomtomSearchApi(...args), 'GET');
  const response = await fetch(`https://${tomtomSearchApi(...args)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/problem+json',
    },
  });
  const json = await response.json();
  return json;
};

export const getSuburbs = async (...args) => {
  const response = await fetchApi(
    `${tomtomSearchApi(...args)}&entityTypeSet=MunicipalitySubdivision`,
    'GET',
  );
  const json = await response.json();
  return json;
};

export const getReverseGeocodedPlace = async (lat, lng) => {
  const response = await fetchApi(
    `${tomtomApi}reverseGeocode/${lat}%2C${lng}.json?returnSpeedLimit=false&returnRoadUse=false&allowFreeformNewLine=false&returnMatchType=false&view=Unified&key=${tomtomApiKey}`,
    'GET',
  );
  const json = await response.json();
  return json;
};

export const checkOutagesInSuburb = async (suburb) => fetchApi(
  `${apiUrl}custommanager_joburg_water/checkOutagesInSuburb?suburb=${suburb}`,
  'GET',
  { credentials: 'include' },
).then((res) => {
  if (res.status >= 200 && res.status < 300) {
    return true;
  }
  return false;
});
