import { apiUrl, apiBaseUrl } from '../constants/constantsGlobal';
import { fetchApi, standardError } from '../functions/apiCalls';

export const passwordHint = (username, subscriberId) => {
  console.log('Username:', username);
  if (username === '') {
    alert('Username is empty');
    return;
  }
  fetchApi(
    `${apiUrl}usermanager/sendPasswordHint?username=${username}&subscriberId=${subscriberId}`,
    'GET',
  )
    .then((res) => res.text())
    .then((text) => {
      console.log(text);
      alert(text);
    })
    .catch(standardError);
};

export const passwordReset = (username, subscriberId) => {
  console.log('Username:', username);
  if (username === '') {
    alert('Username is empty');
    return;
  }
  fetchApi(
    `${apiUrl}usermanager/resetPassword?username=${username}&subscriberId=${subscriberId}`,
    'GET',
  )
    .then((res) => {
      console.log(res);
      alert(`New temporary password sent to ${username}, if it exists`);
    })
    .catch(standardError);
};

export const doLogin = (
  username,
  password,
  setAuth,
  subscriberId,
  setStates,
) => {
  // setStates(submitting, loginDone)
  // submitting: true = spinner to indicate login is loading
  // loginDone: true = login button but greyed out
  // added false
  console.log('setstates', setStates);
  if (username === '') {
    setStates && setStates(false, false);
    alert('Username is empty');
    return false;
  }
  const user = encodeURIComponent(username);
  const pass = encodeURIComponent(password);
  console.log('Username:', user);
  console.log(
    'temp',
    `${apiBaseUrl}j_spring_security_check?j_subscriberID=${subscriberId}&ajax=true&j_username=${user}&j_password=${pass}`,
  );
  return fetchApi(
    `${apiBaseUrl}j_spring_security_check?j_subscriberID=${subscriberId}&ajax=true&j_username=${user}&j_password=${pass}`,
    'POST',
  )
    .then((res) => res.json())
    .then((json) => {
      const {
        success,
        errorMessage,
        roles,
        sessionId,
      } = json;
      if (success) {
        const isCustomerRole = roles.some((val) => val === 'ROLE_CUSTOMER');
        if (isCustomerRole) {
          setAuth(sessionId, username, subscriberId);
          setStates && setStates(false, true);
          console.log('Navigating to home...');
          // setting authenticated to true should navigate to home automatically
          return true;
        }
        alert(
          'Sorry, this user does not have rights to use the customer portal',
        );
      } else if (errorMessage === 'errors.login.UserDoesNotExistException') {
        alert(`${username} does not exist as a user`);
      } else {
        alert('Could not login.');
      }
      setStates && setStates(false, false);
      return false;
    })
    .catch((e) => {
      setStates && setStates(false, false);
      standardError(e, 'Error logging in');
      return false;
    });
};

export const doLoginNoAlerts = (
  username,
  password,
  setAuth,
  subscriberId,
) => {
  const user = encodeURIComponent(username);
  const pass = encodeURIComponent(password);
  console.log('Username:', user);
  console.log(
    'temp',
    `${apiBaseUrl}j_spring_security_check?j_subscriberID=${subscriberId}&ajax=true&j_username=${user}&j_password=${pass}`,
  );
  return fetchApi(
    `${apiBaseUrl}j_spring_security_check?j_subscriberID=${subscriberId}&ajax=true&j_username=${user}&j_password=${pass}`,
    'POST',
  )
    .then((res) => res.json())
    .then((json) => {
      const {
        success,
        roles,
        sessionId,
      } = json;
      if (success) {
        const isCustomerRole = roles.some((val) => val === 'ROLE_CUSTOMER');
        console.log(isCustomerRole);
        if (isCustomerRole) {
          setAuth(sessionId, username, subscriberId);
          return true;
        }
      }
      return false;
    })
    .catch((e) => { console.log(e); return false; });
};

export const authenticationCheck = (res) => {
  if (res.status === 401) {
    // This should take one to the root of the custom portal
    const firstRoot = window.location.pathname.split('/')[1];
    window.location.href = `${window.location.protocol}//${window.location.host}/${firstRoot}/logout`;
  }
};
