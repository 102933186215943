import React from 'react';
import '../style.scss';
import CustomerSlip from '../../../components/myciti/customerslip';

export const Slip = () => {
  document.title = 'Customer Slip | MyCiTi Portal';
  return <CustomerSlip />;
};

export default Slip;
