import React from 'react';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import { Context } from '../../functions/enpower/context';
import '../../styles/enpower/logFault.scss';
import { AddressInput } from '../../components/AddressInput';
import {
  getCallTypes,
  getCustomerTypes,
  getSubCallTypes,
  logFaultMyAddress,
  logFaultOtherAddress,
} from '../../scripts/enpower/logFaultScripts';
import { handleLogging } from '../../functions/forms';

class LogFault extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const { otherAddress } = this.props;
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: otherAddress ? this.initEntriesWithMap() : this.initEntries(),
      requiredMissing: otherAddress
        ? this.initRequiredMissingWithMap()
        : this.initRequiredMissing(),
      callTypesList: [],
      subCallTypesList: [],
      customerTypesList: [],
      submitting: false,
    };
  }

  initEntries = () => ({
    callType: '',
    subCallType: '',
    customerType: '',
    callNotes: '',
    meterNumber: '',
    accountNumber: '',
  });

  initEntriesWithMap = () => ({
    ...this.initEntries(),
    ...{
      building: '',
      streetNumber: '',
      streetName: '',
      suburb: '',
      city: '',
      postalCode: '',
      description: '',
    },
  });

  initRequiredMissing = () => ({
    callType: false,
    callNotes: false,
  });

  initRequiredMissingWithMap = () => ({
    ...this.initRequiredMissing(),
    ...{ contactPerson: false, contactNumber: false, suburb: false },
  });

  componentDidMount = async () => {
    // DEV (TODO: delete) >>>
    // if (window.location.origin === "http://localhost:3000") {
    //   console.log("You are on localhost and as such, test data will be used");
    //   const callTypesList = testCallTypes;
    //   const customerTypesList = testCustomerTypes;
    //   this.setState({ callTypesList, customerTypesList });
    // } else {
    // <<<
    const callTypesList = await this.generateCallTypes();
    const customerTypesList = await this.generateCustomerTypes();
    this.setState({ callTypesList, customerTypesList });
    // } //DEV (TODO: delete)
  };

  generateCallTypes = async () => {
    const { callTypes, setCallTypes } = this.context;
    console.log('callTypes:', callTypes);
    if (callTypes == null || callTypes.length === 0) {
      console.log('getting call types from API');
      const cT = (await getCallTypes()) || [];
      setCallTypes(cT);
      return cT;
    }
    console.log('getting call types from React context');
    return callTypes;
  };

  generateCustomerTypes = async () => {
    const { customerTypes, setCustomerTypes } = this.context;
    console.log('customerTypes:', customerTypes);
    if (customerTypes == null || customerTypes.length === 0) {
      console.log('getting customer types from API');
      const cT = (await getCustomerTypes()) || [];
      setCustomerTypes(cT);
      return cT;
    }
    console.log('getting customer types from React context');
    return customerTypes;
  };

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };
  // << UPDATE VALUES

  onResultFound = (result) => {
    console.log('result passed to parent:', result);
    const {
      streetNumber, streetName, city, postalCode, suburb,
    } = result;
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || entries.streetNumber || '';
    toUpdate.streetName = streetName || entries.streetName || '';
    toUpdate.city = city || entries.city || '';
    toUpdate.postalCode = postalCode || entries.postalCode || '';
    toUpdate.suburb = suburb || entries.suburb || '';
    this.setState({ entries: toUpdate });
  };

  validStyle = (name) => {
    const {
      requiredMissing,
    } = this.state;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  render() {
    const { title, otherAddress } = this.props;
    const {
      entries,
      entries: {
        callType,
        subCallType,
        customerType,
        callNotes,
        meterNumber,
        accountNumber,
        contactPerson,
        contactNumber,
        contactEmail,
      },
      requiredMissing,
      callTypesList,
      subCallTypesList,
      customerTypesList,
      submitting,
    } = this.state;
    console.log('state:', this.state);

    if (subCallTypesList && subCallTypesList.length !== 0) {
      // Initialise states for sub call input when there is a sub call necessary
      requiredMissing.subCallType == null
        && this.initRequiredState('subCallType');
    } else {
      // When sub call not necessary, reset states for sub call
      if (subCallType !== '') {
        this.setEntries('subCallType', '');
      }
      if (requiredMissing.subCallType != null) {
        this.updateRequiredState('subCallType', null);
      }
    }

    return (
      <Container fluid id="formContainer">
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Card id="formCard" className="text-center">
              <Card.Header>
                <Card.Title>
                  <h3>
                    <b>{title}</b>
                  </h3>
                </Card.Title>
              </Card.Header>
              <Card.Body id="formBody">
                <Form>
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Fault Information
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* CALL TYPE */}
                      <Col md={6}>
                        <Material.TextField
                          name="callType"
                          select
                          fullWidth
                          margin="normal"
                          label="Call Type"
                          variant="outlined"
                          required
                          error={requiredMissing.callType === true}
                          value={callType}
                          onChange={(e) => {
                            this.updateReqEntries(e);
                            const callTypeCode = e.target.value;
                            getSubCallTypes(callTypeCode).then((
                              res,
                            ) => this.setState({ subCallTypesList: res }));
                          }}
                        >
                          <Material.MenuItem
                            key="blank"
                            value=""
                          />
                          {callTypesList.map((type) => (
                            <Material.MenuItem
                              key={type.value}
                              value={type.value}
                            >
                              {type.label}
                            </Material.MenuItem>
                          ))}
                        </Material.TextField>
                      </Col>
                      {/* SUB CALL TYPE */}
                      <Col md={6}>
                        {subCallTypesList && subCallTypesList.length !== 0 && (
                          <Material.TextField
                            name="subCallType"
                            select
                            fullWidth
                            margin="normal"
                            label="Sub Call Type"
                            variant="outlined"
                            required
                            error={requiredMissing.subCallType === true}
                            value={subCallType}
                            onChange={this.updateReqEntries}
                          >
                            {subCallTypesList.map((type) => (
                              <Material.MenuItem
                                key={type.value}
                                value={type.value}
                              >
                                {type.label}
                              </Material.MenuItem>
                            ))}
                          </Material.TextField>
                        )}
                      </Col>
                    </Row>
                    <Row className="formInputs">
                      {/* CUSTOMER TYPE */}
                      <Col md={6}>
                        <Material.TextField
                          name="customerType"
                          select
                          fullWidth
                          margin="normal"
                          label="Customer Type"
                          variant="outlined"
                          value={customerType}
                          onChange={this.updateEntries}
                        >
                          {customerTypesList.map((v) => {
                            const val = v.label ? v.label : v;
                            return (
                              <Material.MenuItem key={val} value={val}>
                                {val}
                              </Material.MenuItem>
                            );
                          })}
                        </Material.TextField>
                      </Col>
                      <Col md={6} />
                    </Row>
                  </Form.Group>
                  {/* CALL NOTES */}
                  <Form.Group className="formLabels">
                    <Form.Label
                      className="smallLabel"
                      style={this.validStyle('callNotes')}
                    >
                      Call Notes *
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="formInputs">
                    <Form.Control
                      name="callNotes"
                      as="textarea"
                      rows={3}
                      required
                      isInvalid={requiredMissing.callNotes === true}
                      value={callNotes}
                      onChange={this.updateReqEntries}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* METER NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Meter Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="meterNumber"
                          type="text"
                          value={meterNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                      {/* ALTERNATE ACCOUNT NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Alternate Account Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="accountNumber"
                          type="text"
                          value={accountNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {otherAddress && (
                    <>
                      <Form.Group className="formLabels">
                        <Form.Label className="largeLabel">
                          Contact Details
                        </Form.Label>
                      </Form.Group>
                      <Form.Group>
                        <Row className="formInputs">
                          {/* CONTACT PERSON */}
                          <Col md={6}>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={this.validStyle('contactPerson')}
                              >
                                Contact Person *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="contactPerson"
                              type="text"
                              required
                              isInvalid={requiredMissing.contactPerson === true}
                              value={contactPerson}
                              onChange={this.updateReqEntries}
                            />
                          </Col>
                          {/* CONTACT NUMBER */}
                          <Col md={6}>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={this.validStyle('contactNumber')}
                              >
                                Contact Number *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="contactNumber"
                              type="number"
                              required
                              isInvalid={requiredMissing.contactNumber === true}
                              value={contactNumber}
                              onChange={this.updateReqEntries}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Row className="formInputs">
                          {/* CONTACT EMAIL */}
                          <Col xs={12} md={6}>
                            <Form.Group className="formLabels">
                              <Form.Label className="smallLabel">
                                Contact Email
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              name="contactEmail"
                              type="email"
                              value={contactEmail}
                              onChange={this.updateEntries}
                            />
                          </Col>
                          <Col xs={0} md={6} />
                        </Row>
                      </Form.Group>

                      {/* to implement component,
                      initalise parent state 'entries' with the initEntriesWithMap function,
                      and similarly with 'requiredMissing' */}
                      <AddressInput
                        entries={entries}
                        updateEntries={this.updateEntries}
                        requiredMissing={requiredMissing}
                        updateReqEntries={this.updateReqEntries}
                        onResultFound={this.onResultFound}
                        page="logFault"
                      />
                    </>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer>
                <Form.Group id="finalButtons">
                  <Link to="/home">
                    <Button variant="secondary" type="submit">
                      Cancel
                    </Button>
                  </Link>
                  {!submitting && (
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={() => {
                        if (
                          handleLogging(this.state, (s) => this.setState(s))
                          === true
                        ) {
                          if (!otherAddress) {
                            logFaultMyAddress(entries, (s) => this.setState(s));
                          }
                          if (otherAddress) {
                            logFaultOtherAddress(entries, (s) => this.setState(s));
                          }
                        }
                      }}
                    >
                      Save and continue
                    </Button>
                  )}
                  {submitting && <Spinner />}
                  {/* DEV TODO: delete */}
                  {/* <Button onClick={() => this.setEntries("suburb", "c")}>
                    set fields
                  </Button> */}
                  {/* DEV TODO: delete */}
                </Form.Group>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    );
  }
}

export const LogFaultMyAddress = () => <LogFault title="Log a fault at My Address" />;

export const LogFaultOtherAddress = () => <LogFault title="Log a fault at Another Address" otherAddress />;
