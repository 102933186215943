import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  FormHeaderContainer,
  FormTitle,
  SubscriberImage,
  BackButton,
  Navigator,
} from '../forms.styled';

const FormHeader = (props) => {
  const history = useHistory();
  const { title } = props;

  return (
    <FormHeaderContainer>
      <Navigator>
        <BackButton onClick={() => { history.push('/home'); }}>
          <ArrowBackIosIcon />
          <div style={{ opacity: '0.5' }}>Back</div>
        </BackButton>
        <SubscriberImage />
      </Navigator>
      <FormTitle>{title}</FormTitle>
    </FormHeaderContainer>
  );
};

export default FormHeader;
