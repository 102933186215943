import { apiUrl } from '../constants/constantsGlobal';
import { fetchApi, standardError } from '../functions/apiCalls';
import { allLabels } from '../constants/siemens/constants';
import { authenticationCheck } from './loginScripts';

export const getWidths = (widths, screenWidth = 1080) => {
  const total = widths.reduce((prev, curr) => prev + curr, 0);
  return widths.map((width) => (Math.round(width) / total) * screenWidth);
};

export const convertRowsForTable = (apiRows, { jsonKeys, headers }) => apiRows.map((entry, i) => {
  let { id } = entry;
  if (id == null) id = i;
  const entryFormatted = { id };
  jsonKeys.forEach((key, j) => {
    const keyFormatted = headers[j];
    let val = entry[key];
    // TODO: fix in original API response >>>
    if (val === 'null') val = null;
    if (val === 'true') val = true;
    if (val === 'false') val = false;
    // <<<
    if (val) {
      if (
        key === 'dateCreated'
          || key === 'createdDate'
          || key === 'appointment'
      ) {
        val = new Date(val).toLocaleString('en-GB', {
          dateStyle: 'short',
          timeStyle: 'short',
        });
      }
    }
    entryFormatted[keyFormatted] = val;
  });
  return entryFormatted;
});

export const getWorkRequestsTable = async (columns) => fetchApi(`${apiUrl}customerportalmanager/getAllCustomerWorkRequests`, 'GET', {
  credentials: 'include',
})
  .then((res) => {
    authenticationCheck(res);
    return res.json();
  })
  .then((json) => convertRowsForTable(json, columns))
  .catch((e) => {
    standardError(e, 'Error occurred loading the table');
  });

export const getQuotationsTable = async (columns) => fetchApi(`${apiUrl}customerportalmanager/getAllCustomerQuotations`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .then((json) => convertRowsForTable(json, columns))
  .catch((e) => {
    standardError(e, 'Error occurred loading the table');
  });

export const getAllCustomerCallsTable = async (columns) => fetchApi(`${apiUrl}customerportalmanager/getAllCustomerCalls`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .then((json) => convertRowsForTable(json, columns))
  .catch((e) => {
    standardError(e, 'Error occurred loading the table');
  });

export const getColumns = (
  headers, headerNames, widths, fieldRenderer,
) => headers.reduce((arr, field, i) => {
  const col = { field, headerName: headerNames[i], width: widths[i] };
  Object.entries(fieldRenderer).forEach(([fieldName, renderer]) => {
    if (field === fieldName) {
      col.renderCell = renderer;
    }
  });
  return [...arr, col];
}, []);

//   {description: 'New Types Test', ...}
// -->
// [
//   {"property": "description", "value": "New Types Test"},
//   {...}
// ]
const convertFilters = (obj) => Object.entries(obj).map(([property, description]) => ({
  property,
  description,
}));

const convertSorts = (obj) => Object.entries(obj).map(([property, order]) => ({
  property,
  order,
}));

export const getTable = (
  columns,
  modelClass,
  id,
  columnView,
  rowStart,
  rowEnd,
  filtersObj = {},
  sortObj = {},
) => {
  console.log(
    columns,
    modelClass,
    id,
    columnView,
    rowStart,
    rowEnd,
    filtersObj,
    sortObj,
  );
  const maxRows = rowEnd - rowStart;
  const filters = convertFilters(filtersObj);
  const sorts = convertSorts(sortObj);
  const tableData = {
    modelClass,
    id,
    columnView,
    rowSelect: {
      rowStart,
      rowEnd,
      maxRows,
    },
    filterSet: {
      filters,
    },
    sortSet: {
      sorts,
    },
  };
  console.log('table data:', tableData);
  const body = JSON.stringify({ className: 'LimitImpl', ...tableData });

  return fetchApi(`${apiUrl}customerportalmanager/getTableData`, 'POST', {
    credentials: 'include',
    body,
  })
    .then((res) => {
      authenticationCheck(res);
      return res.json();
    })
    .catch((e) => {
      standardError(e, 'Error occurred loading the table');
    });
};

/**
 * Creates labels for column headers
 */
const createHeaderNames = (columnProps, model) => {
  // create a human readable header name, if none in allLabels
  const createLabel = (item) => {
    const l = item.replace('.', ' ');
    return l.charAt(0).toUpperCase() + l.slice(1);
  };

  const headerNames = [];
  columnProps.forEach((item) => {
    const key = `${model}.${item}`;
    const label = allLabels[key] || createLabel(item);
    headerNames.push(label);
  });
  return headerNames;
};

/**
 * Creates DataGrid columns, with custom column definitions (i.e. renderCell, type, etc)
 */
const createDataGridColumns = (
  columnProps, headerNames, colDefs,
) => columnProps.map((item, index) => {
  const column = {
    field: item,
    headerName: headerNames[index],
    ...colDefs[item],
  };
    // add time/date types
  if (
    item.includes('time')
      || item.includes('Time')
      || item.includes('appointment')
  ) {
    column.type = 'dateTime';
  } else if (
    item.includes('date')
      || item.includes('Date')
  ) {
    column.type = 'date';
  }
  return column;
});

export const fetchTable = (limitObject) => fetchApi(
  `${apiUrl}customerportalmanager/getTableData`,
  'POST', {
    body: JSON.stringify({
      className: 'LimitImpl',
      ...limitObject,
    }),
  },
)
  .then((res) => {
    authenticationCheck(res);
    return res.json();
  })
  .catch((e) => {
    standardError(e, 'Error occurred loading the table');
  });

export const getTableRowCount = (limitObject) => fetchApi(
  `${apiUrl}customerportalmanager/getTableRowCount`,
  'POST', {
    body: JSON.stringify({
      className: 'LimitImpl',
      ...limitObject,
    }),
  },
)
  .then((res) => res.json())
  .catch((e) => {
    standardError(e, 'Error occurred loading the table');
  });

/**
 * Get Table data, and converts it to DataGrid readable rows & columns
 */
export const getTableData = (
  modelClass,
  id,
  columnView,
  rowStart = 0,
  rowEnd = 10,
  maxRows = 10,
  filters = [],
  sorts = [],
  colDefs = {},
) => {
  console.log('colDefs', colDefs);
  const limitObj = {
    modelClass,
    id,
    columnView,
    rowSelect: {
      rowStart,
      rowEnd,
      maxRows,
    },
    filterSet: {
      filters,
    },
    sortSet: {
      sorts,
    },
  };
  const body = JSON.stringify({ className: 'LimitImpl', ...limitObj });

  return fetchApi(
    `${apiUrl}customerportalmanager/getTableData`,
    'POST', {
      credentials: 'include',
      body,
    },
  )
    .then((res) => {
      authenticationCheck(res);
      return res.json();
    })
    .then((res) => {
      const { limit } = res;
      const rows = res.table;
      const columnProps = limit.columnProperties;
      const model = limit.tableModelClass;
      const headerNames = createHeaderNames(columnProps, model);
      const columns = createDataGridColumns(columnProps, headerNames, colDefs);
      return { columns, rows };
    })
    .catch((e) => {
      standardError(e, 'Error occurred loading the table');
    });
};
