import { apiUrl } from '../../constants/constantsGlobal';
import { fetchApi, standardError } from '../../functions/apiCalls';
import { convertRowsForTable } from '../tablesScripts';

export const getTable = async (columns, pageNo = 0, limit = 100) => fetchApi(
  `${apiUrl}customerportalmanager/findCustomerAssetsFiltered?pageNo=${pageNo}&limit=${limit}`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .then(({ data }) => convertRowsForTable(data, columns))
  .catch((e) => {
    standardError(e, 'Error occurred loading the assets table');
  });

const listToTree = (initialList) => {
  const map = {};
  let node;
  const roots = [];
  let i;
  // initialise
  const list = initialList;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].objectId] = i;
    list[i].children = [];
  }
  // push nodes
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentObjectId !== null) {
      // if dangling branches check that map[node.parentObjectId] exists
      list[map[node.parentObjectId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getAssetTreeNodes = async (assetId) => fetchApi(
  `${apiUrl}assetmanager/getAssetTreeNodes?assetId=${assetId}`,
  'GET',
  {
    credentials: 'include',
  },
)
  .then((res) => res.json())
  .then((res) => listToTree(res))
  .catch((e) => {
    standardError(e, 'Error occured trying to get asset tree nodes');
  });
