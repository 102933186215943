import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import '../../styles/botswanatourism/login.scss';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Container,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress as Spinner } from '@material-ui/core';
import { Context } from '../../functions/botswanatourism/context';
import * as CustomerPortal from '../../scripts/botswanatourism/loginScripts';
import { doLogin } from '../../scripts/loginScripts';

export const LoginForm = (props) => {
  const context = useContext(Context);
  const { setAuth, logout } = context;
  const {
    ack,
  } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [loginDone, setLoginDone] = useState(false);
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    if (ack === 'logout') {
      console.log('Loggingout');
      logout();
      setReadyToRedirect(true);
      setRedirectTo('/login');
    }
  }, []);

  const setStates = (submit, done) => {
    setSubmitting(submit);
    setLoginDone(done);
  };

  const handleLogin = () => {
    setSubmitting(true);
    doLogin(
      username,
      password,
      setAuth,
      'bto',
      setStates,
    );
  };
  let returner;
  if ((readyToRedirect && redirectTo !== '')) {
    returner = <Redirect to={redirectTo} />;
  } else {
    returner = (
      <Container id="loginFormContainer" fluid>
        <img
          id="loginImage"
          src="botswanatourism/LoginBackground.jpg"
          alt="Botswana Tourism"
        />

        <div id="loginGradient" />
        <Row>
          <Col xs={0} sm={1} md={4} />
          <Col xs={12} sm={10} md={4}>
            <Card id="loginFormCard" className="text-center">
              <div>
                <img
                  src="botswanatourism/BotswanaLogo.png"
                  id="loginImageSub"
                  alt="Botswana Tourism"
                />
                <Card.Subtitle id="loginSubHeading">
                  Facilities Self Service Portal
                </Card.Subtitle>
              </div>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Material.TextField
                      fullWidth
                      label="User name"
                      variant="outlined"
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Material.TextField
                      fullWidth
                      label="Password"
                      variant="outlined"
                      type="password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Form.Group>
                  <div id="forgotPass">
                    Forgot your password?
                    <br />
                    Have your
                    {' '}
                    <Material.Link
                      onClick={() => CustomerPortal.passwordHint(username)}
                      underline="always"
                      color="primary"
                    >
                      password hint SMS&apos;d to you
                    </Material.Link>
                    {' '}
                    or
                    {' '}
                    <Material.Link
                      onClick={() => CustomerPortal.passwordReset(username)}
                      underline="always"
                    >
                      reset it
                    </Material.Link>
                    .
                  </div>
                  <br />
                  <Form.Group>
                    {!submitting && (
                    <Button
                      id="loginButton"
                      type="submit"
                      variant={loginDone ? 'secondary' : 'primary'}
                      disabled={loginDone}
                      onClick={() => {
                        handleLogin();
                      }}
                    >
                      Login
                    </Button>
                    )}
                    {submitting && <Spinner />}
                  </Form.Group>
                </Form>
              </Card.Body>

            </Card>
          </Col>
          <Col xs={0} sm={1} md={4} />
        </Row>
        <div id="loginTextFooter">
          If you require any further assistance, please contact Botswana Tourism Organisation on:
          <br />
          Tel: +267 391-3111/310-5601 or
          <br />
          Email: itgrading@botswanatourism.co.bw
        </div>
        <img
          src="botswanatourism/Asset1@50x.png"
          id="loginImageFooter"
          alt="Botswana Tourism"
        />
      </Container>
    );
  }
  return returner;
};
