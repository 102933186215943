import React, { useContext } from 'react';
import { ProfileForm } from '../../components/servest_it/profile';
import { Context } from '../../functions/servest_it/context';
import { doLogin } from '../../scripts/loginScripts';

export const Register = () => {
  document.title = 'Register | Servest IT Portal';
  const subscriberId = 'servest_it';

  const { setAuth, setUsername } = useContext(Context);
  const onLogin = ({ username, password }, setStates) => {
    doLogin(username, password, setAuth, subscriberId, setStates)
      .then((loggedIn) => loggedIn && setUsername(username));
  };

  return <ProfileForm newProfile {...{ onLogin, subscriberId }} />;
};
