import styled from 'styled-components';

export const DocumentDialogFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .default-form-field {
        width: 50%;
    }

    .field-title {
        width: 100%;
        color: #262626;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        min-height: 18px;
    }

    .MuiFormGroup-root {
        display: grid;
        columns: 4;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .button {
        width: fit-content;
        height: fit-content;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        padding: 8px 16px;
        border-radius: 0px;
    }

    .button-plain {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }

    .button-primary {
        border: 1px solid #102b7c;
        background-color: #102b7c;
        color: #ffffff;
        transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;

        :hover {
          background-color: #102b7ce6;
          box-shadow: 0px 0px 0px 1px #102b7c40;
        }
    }

    .button-secondary {
        border: 1px solid #d9d9d9;
        background-color: #ffffff;
        color: #000000;
        transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;

        :hover {
        border: 1px solid #102b7c40;
        box-shadow: 0px 0px 0px 1px #102b7c40;
        }
    }

    .select-menu {
        width: 100%;

        .MuiOutlinedInput-root {
          width: 100%;

          .MuiSelect-select {
            padding: 5px 32px 5px 16px;
            color: #595959;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            background-color: #fff;
          }

          fieldset {
            border: 0.5px #d9d9d9 solid !important;
            border-radius: 2px;
          }
        }
    }

    .text-input {
        padding: 4px 12px;
        width: 100%;
        border-radius: 2px;
        border: 0.5px #d9d9d9 solid;
        color: #595959;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;

        :focus {
          outline: none;
        }
    }

    .checkboxes {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 2px;
        border: 0.5px #d9d9d9 solid;
        background-color: #ffffff;
        padding: 8px;
        max-height: 300px;
        overflow-y: auto;

        .MuiFormGroup-root {
            flex-direction: row;

            .Mui-checked {
              color: #102b7c !important;
            }

            .MuiSvgIcon-root {
              width: 16px;
              height: 16px;
            }

            .MuiFormControlLabel-label {
              color: #262626;
              font-size: 14px;
              font-weight: 300;
              line-height: 22px;
            }
        }
    }
`;

export const DocumentsStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgba(0,0,0,.125);
    padding: 12px; 
    border-radius: 4px;

    .document-footer {
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 8px;
    }

    .button {
        width: fit-content;
        height: fit-content;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        padding: 8px 16px;
        border-radius: 0px;
    }

    .button-plain {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }

    .button-primary {
        border: 1px solid #102b7c;
        background-color: #102b7c;
        color: #ffffff;
        transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;

        :hover {
          background-color: #102b7ce6;
          box-shadow: 0px 0px 0px 1px #102b7c40;
        }
    }

    .button-secondary {
        border: 1px solid #d9d9d9;
        background-color: #ffffff;
        color: #000000;
        transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;

        :hover {
        border: 1px solid #102b7c40;
        box-shadow: 0px 0px 0px 1px #102b7c40;
        }
    }

    .document-table {
        width: 100%;
        height: 100%;
        max-height: 400px;
        overflow: auto;
        border-bottom: 1px solid #f0f0f0;

        .MuiPaper-root {
          box-shadow: none !important;
          border-radius: 0px;

          .MuiTableHead-root {
            .MuiTableCell-root {
              border: none !important;
              font-family: sans-serif;
              font-size: 14px;
              font-weight: 500 !important;
              letter-spacing: -0.518px;
              text-transform: uppercase;
              background-color: #D0D6E8;
              color: #102b7c;
            }
          }

          .MuiTableBody-root {
            tr:last-of-type {
              td {
                border-bottom: none !important;
              }
            }
          }

          .MuiTableRow-root {
            td:last-of-type {
              border-right: none !important;
            }
          }

          .MuiTableCell-root {
            border-right: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            padding: 8px;
            color: #262626;
            font-family: sans-serif;
            font-size: 14px;
            font-weight: 300;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .MuiPaper-root {
            box-shadow: 0px 0px 2px rgba(85, 107, 130, 0.16),
              0px 2px 4px rgba(85, 107, 130, 0.16);
        }

        .MuiList-root {
            width: 200px;
        }

        .MuiMenuItem-root {
            background-color: transparent !important;
            font-family: sans-serif;
            font-size: 14px;
            line-height: 22px;
        }
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;

    .button {
        width: fit-content;
        height: fit-content;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        padding: 8px 16px;
        border-radius: 0px;
    }

    .button-plain {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }

    .button-primary {
        border: 1px solid #102b7c;
        background-color: #102b7c;
        color: #ffffff;
        transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;

        :hover {
        background-color: #102b7ce6;
        box-shadow: 0px 0px 0px 1px #102b7c40;
        }
    }

    .button-secondary {
        border: 1px solid #d9d9d9;
        background-color: #ffffff;
        color: #000000;
        transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;

        :hover {
        border: 1px solid #102b7c40;
        box-shadow: 0px 0px 0px 1px #102b7c40;
    }
}`;
