import React from 'react';
import {
  Button, Form, Card,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress as Spinner } from '@material-ui/core';
import * as login from '../../scripts/loginScripts';
import '../../styles/ssg_facilities/login.scss';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitting: false,
      loginDone: false,
      passwordDisplay: 'password',
    };
  }

  handleLogin = () => {
    console.log('handling login');
    const { onLogin } = this.props;
    const {
      username,
      password,
    } = this.state;
    this.setState({ submitting: true });
    onLogin(
      username,
      password,
      (submitting, loginDone) => this.setState({ submitting, loginDone }),
    );
  };

  render() {
    const {
      username, submitting, loginDone, passwordDisplay,
    } = this.state;
    const { subscriber, subscriberId } = this.props;

    return (
      <>
        <div id="loginFormContainer">
          <div className="new-login-logo-container">
            <img src="ssg_facilities/logo.png" className="new-login-logo-image" alt="SSG Facilities Portal Logo" />
          </div>
          <div className="new-login-form-container">
            <div id="loginFormCard" className="text-center">
              <Card.Header>
                <Card.Title className="new-login-title">
                  <b>Log in</b>
                </Card.Title>
                <Card.Subtitle>
                  Sign in to your
                  {' '}
                  {subscriber}
                  {' '}
                  account
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Material.TextField
                      name="username"
                      fullWidth
                      label="Username"
                      variant="outlined"
                      onChange={(event) => this.setState({ username: event.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Material.TextField
                      fullWidth
                      label="Password"
                      variant="outlined"
                      type={passwordDisplay}
                      onChange={(event) => this.setState({ password: event.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    {!submitting && (
                    <Button
                      type="submit"
                      variant={loginDone ? 'secondary' : 'primary'}
                      disabled={loginDone}
                      onClick={this.handleLogin}
                    >
                      Login
                    </Button>
                    )}
                    {submitting && <Spinner />}
                  </Form.Group>
                </Form>
              </Card.Body>
              <Card.Footer>
                Forgot your password?
                <br />
                Have your
                {' '}
                <Material.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    login.passwordHint(username, subscriberId);
                  }}
                  underline="always"
                  color="primary"
                >
                  password hint SMS&apos;d to you
                </Material.Link>
                {' '}
                or
                {' '}
                <Material.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    login.passwordReset(username, subscriberId);
                  }}
                  underline="always"
                >
                  reset it
                </Material.Link>
                .
              </Card.Footer>
            </div>
          </div>

        </div>
      </>
    );
  }
}
