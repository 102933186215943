import React, { useContext } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/joburg_water/context';

const OpenFaults = () => {
  document.title = 'Open Faults (Logged by me) | Joburg Water Portal';

  const { username } = useContext(Context);

  return (
    <Table
      Context={Context}
      title="Open Faults (Logged by me)"
      tableModelClass="WorkOrderRequest"
      id="workorderrequest"
      columnView="Customer WorkOrderRequest View"
      filters={[
        {
          property: 'loggedBy.username',
          value: username,
          options: [],
        },
        {
          property: 'active',
          value: true,
          options: [],
        },
        {
          property: 'workRequestStatus.open',
          value: true,
          options: [],
        },
      ]}
      sorts={{
        property: 'dateCreated',
        order: 'DESC',
      }}
      isNotCodeLink
      isFault
    />
  );
};

export default OpenFaults;
