import React from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/servest_it/context';

export const ViewTickets = () => {
  document.title = 'Service Tickets | Servest IT Portal';

  return (
    <Table
      Context={Context}
      title="Requests"
      tableModelClass="WorkOrderRequest"
      id="workorderrequest"
      columnView="Customer Tickets View"
      sorts={{
        property: 'dateCreated',
        order: 'DESC',
      }}
    />
  );
};
