import React from 'react';
import {
  SectionContainer,
} from '../forms.styled';
import BlueHeader from './blueheader';

const FormSection = (props) => {
  const {
    title,
    children,
  } = props;

  return (
    <SectionContainer>
      <BlueHeader title={title} />
      {children}
    </SectionContainer>
  );
};

export default FormSection;
