import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
  Alert,
  ListGroup,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { Context } from '../../functions/ssg_facilities/context';
import {
  getWorkRequest,
  postWorkRequest,
  byClassAndProp,
  getPortalCustomerDetails,
  addWorkRequestAttachment,
  getUserRoles,
  getWorkRequestNoteTypes,
  addNoteToWorkRequest,
  getCustomLookup1ValuesSsg,
} from '../../functions/apiCalls';
import {
  getNotes,
  getRootItemByClassAndProp,
} from '../../scripts/botswanatourism/scripts';
import ImageViewerUploader from '../../components/ImageViewerUploader';

/**
 * Page for viewing or creating a Work Order Request
 */
const WorkOrderRequestPage = () => {
  const params = useParams();
  const { code } = params;
  const { labels } = useContext(Context);
  const history = useHistory();
  const [workRequest, setWorkRequest] = useState();
  const [customLookup1, setCustomLookup1] = useState([]);
  // const [customLookupCodes1, setCustomLookupCodes1] = useState([]);
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const [isAddNote, setIsAddNote] = useState(false);
  const [noteText, setNoteText] = useState('');

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: workRequest });

  useEffect(() => {
    document.title = code
      ? `Edit Ticket ${code} | SSG Facilities Portal`
      : 'Create Ticket | SSG Facilities Portal';

    async function fetchData() {
      setLoading(true);

      const lookup1Res = await getRootItemByClassAndProp(
        'WorkOrderRequest',
        'customLookup1',
        '',
      );

      const detailsResponse = await getPortalCustomerDetails().then((res) => res.json());
      const response2 = await getUserRoles();
      detailsResponse.role = [];
      const role = [];
      for (let i = 0; i < response2.length; i++) {
        role.push(response2[i].label);
      }
      detailsResponse.role = role;
      if (code) {
        const wr = await getWorkRequest(code).then((res) => res.json());
        console.log('wr.customLookupCode1', wr.customLookupCode1, lookup1Res.find(
          (x) => x.value === wr.customLookupCode1,
        )?.id);
        setWorkRequest({
          ...wr,
          // customLookupCode1: lookup1Res.find(
          //   (x) => x.value === wr.customLookupCode1,
          // )?.id,
        });

        setNotes(await getNotes(wr?.id));
      } else {
        setWorkRequest({
          createdDate: moment().valueOf(),
          customerCode: detailsResponse.code,
          customerDescription: detailsResponse.description,
          emailAddress: detailsResponse.emailAddress,
          address: detailsResponse.address,
          childWorkType: '',
          contactEmail: detailsResponse.user.email,
          contactName: detailsResponse.user.fullName,
          contactNumber: detailsResponse.user.phoneNumber,
          docType: 'WorkOrderRequest',
          resourceFeedback: '',
        });
      }
      // setCustomerDetails(detailsResponse);

      const lookupValues1ResponseTemp = await getCustomLookup1ValuesSsg();
      // Check lookupValues1Response against lookup1Res and remove
      // everything not in lookup1Res in lookupValues1Response
      const lookupValues1Response = lookup1Res.filter((el) => {
        for (let i = 0; i < lookupValues1ResponseTemp.length; i++) {
          if (lookupValues1ResponseTemp[i].value === `${el.id}`) {
            return true;
          }
        }
        return false;
      });
      const workRequestTypesResponse = await byClassAndProp(
        'WorkOrderRequest',
        'workRequestType',
      ).then((res) => res.json());
      setCustomLookup1(lookupValues1Response);
      setTypes(workRequestTypesResponse);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    console.log('WIO', workRequest);
    reset(workRequest);
    setImages(workRequest?.images);
  }, [workRequest]);

  const onSubmit = (data) => {
    console.log(data);

    async function postData() {
      setPosting(true);
      const workRequestObject = {
        ...workRequest,
        ...data,
        customLookupCode1: data.customLookupCode1
          ? data.customLookupCode1
          : customLookup1[0].value,
        typeCode: data.typeCode || types[0]?.value,
      };

      console.log('wr', workRequestObject);

      const response = await postWorkRequest(workRequestObject).then((res) => res.json());

      // Upload images after a new ticket was successfully logged
      if (!code && response.result === 'SUCCESS' && images?.length > 0) {
        const wr = await getWorkRequest(response.code);
        const wrObj = await wr.json();
        const id = await wrObj.id;

        images?.forEach(async (i) => {
          const formData = new FormData();
          formData.append('workRequestId', id);
          formData.append('file', i.file);
          await addWorkRequestAttachment(formData, 'ssg_fac');
        });
      }

      setSubmitResponse(response);
      setPosting(false);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (response.result === 'SUCCESS') {
        history.push('/tickets');
      }
    }

    postData();

    // scroll to top after submit
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  async function addingNoteHandler() {
    if (isAddNote) {
      setIsAddNote(false);
    } else {
      setIsAddNote(true);
    }
  }

  async function submitNoteForm(event) {
    event.preventDefault();
    const response = await getWorkRequestNoteTypes();
    const workRequestId = workRequest.id;
    const noteTypeId = response[0].value;
    console.log('note form');
    console.log(`work request id: ${workRequestId}`);
    console.log('note type id: ', noteTypeId);
    if (noteText === '') {
      console.log('note: No note text');
    } else {
      console.log(`note: ${noteText}`);
      await addNoteToWorkRequest(
        workRequestId,
        noteTypeId,
        noteText,
      );
      setNotes(await getNotes(workRequest.id));
      setNoteText('');
    }
  }

  const closeAddNoteForm = () => {
    setIsAddNote(false);
  };

  const noteInputChangeHandler = (event) => {
    setNoteText(event.target.value);
  };

  console.log('control', control);

  return (
    <>
      {submitResponse && (
        <Alert
          variant={submitResponse.result === 'SUCCESS' ? 'success' : 'danger'}
          dismissible
          onClose={() => setSubmitResponse(null)}
        >
          {submitResponse.result === 'SUCCESS'
            ? `Successfully ${code ? 'updated' : 'logged'} ticket ${
              submitResponse.code
            }.`
            : submitResponse.errorMessage
              || `Error: Could not ${code ? 'update' : 'log'} ticket.`}
        </Alert>
      )}

      <Container fluid>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <Card.Header>
                  <Card.Title className="text-center">
                    <h3>
                      <b>{code ? 'Edit Ticket' : 'Create Ticket'}</b>
                    </h3>
                  </Card.Title>
                </Card.Header>

                <Dimmer inverted active={loading}>
                  <Loader>Loading ticket details</Loader>
                </Dimmer>

                <Card.Body className="ml-md-5 mr-md-5">
                  <h4>Ticket Details</h4>

                  {code && (
                    <Row>
                      <Col md="6" sm="12">
                        <Form.Group className="mb-2">
                          <Form.Label>
                            {labels['WorkOrderRequest.dateCreated']}
                          </Form.Label>
                          <Controller
                            control={control}
                            name="createdDate"
                            render={({
                              field: {
                                onChange, onBlur, value, ref,
                              },
                            }) => (
                              <Form.Control
                                onBlur={onBlur}
                                onChange={onChange}
                                value={moment(value).format('DD/MM/YYYY HH:mm')}
                                ref={ref}
                                isInvalid={errors.createdDate}
                                readOnly
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6" sm="12">
                        <Form.Group className="mb-2">
                          <Form.Label>
                            {labels['WorkOrderRequest.status']}
                          </Form.Label>
                          <Controller
                            control={control}
                            name="statusDescription"
                            render={({ field }) => (
                              <Form.Control
                                {...field}
                                isInvalid={errors.statusDescription}
                                readOnly
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Form.Group className="mb-2">
                    <Form.Label>
                      {labels['WorkOrderRequest.description']}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.description}
                          placeholder="Enter problem description"
                          as="textarea"
                          rows={3}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          {
                            labels[
                              'WorkOrderRequest.workRequestType.description'
                            ]
                          }
                        </Form.Label>
                        <Controller
                          control={control}
                          name="typeCode"
                          render={({ field }) => (
                            <Form.Control
                              as="select"
                              {...field}
                              isInvalid={errors.typeCode}
                            >
                              {types.map(({ value, label }) => (
                                <option value={value}>{label}</option>
                              ))}
                            </Form.Control>
                          )}
                        />
                        {/* <Form.Text className="text-muted">Custom 2</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.typeCode?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h4>Contact Details</h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          {labels['User.fullName']}
                        </Form.Label>
                        <Controller
                          control={control}
                          name="contactName"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactName}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Customer Code</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.contactName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          {labels['Customer.emailAddress']}
                        </Form.Label>
                        <Controller
                          control={control}
                          name="contactEmail"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactEmail}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Email Number</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.contactEmail?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          {labels['WorkOrderRequest.contactNumber']}
                        </Form.Label>
                        <Controller
                          control={control}
                          name="contactNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactNumber}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Office Number</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.contactNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Work Order Request Location, TODO: Add map */}
                  <h4>Location</h4>
                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          {labels['WorkOrderRequest.customLookup1']}
                        </Form.Label>
                        <Controller
                          control={control}
                          name="customLookupCode1"
                          render={({ field }) => (
                            <Form.Control
                              as="select"
                              {...field}
                              isInvalid={errors.customLookupCode1}
                            >
                              {customLookup1.map(({ value, label }) => (
                                <option value={value}>
                                  {label}
                                </option>
                              ))}
                            </Form.Control>
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.customLookupCode1?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {code && (
                    <>
                      <Row style={{ padding: '2em 0em 2em 1em' }}>
                        <h4>Notes</h4>
                        {!isAddNote && (
                          <Button
                            style={{ height: '2em', marginLeft: '3em', lineHeight: '100%' }}
                            onClick={addingNoteHandler}
                          >
                            Add Note
                          </Button>
                        )}
                      </Row>
                      {isAddNote && (
                        <Card style={{ padding: '1em' }}>
                          <h4>Message:</h4>
                          <div>
                            <div>
                              <input
                                style={{
                                  display: 'block',
                                  width: '100%',
                                  height: 'calc(1.5em + 0.75rem + 2px)',
                                  padding: '0.375rem 0.75rem',
                                  fontSize: '1rem',
                                  fontWeight: '400',
                                  lineHeight: '1.5',
                                  color: '#495057',
                                  backgroundColor: '#fff',
                                  backgroundClip: 'padding-box',
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                }}
                                id="noteText"
                                type="text"
                                onChange={noteInputChangeHandler}
                                value={noteText}
                              />
                            </div>
                            <footer>
                              <Button
                                style={{ height: '2em', marginInline: '1em', lineHeight: '100%' }}
                                onClick={submitNoteForm}
                              >
                                Add
                              </Button>
                              <Button
                                style={{ height: '2em', margin: '1em', lineHeight: '100%' }}
                                onClick={closeAddNoteForm}
                                variant="secondary"
                              >
                                Cancel
                              </Button>
                            </footer>
                          </div>
                        </Card>
                      )}
                      {notes.length > 0 && (
                        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                          <ListGroup variant="flush">
                            {notes.map((e) => (
                              <ListGroup.Item>
                                <strong>
                                  {moment(e.timestamp).format('DD-MM-yyyy HH:mm')}
                                  {' '}
                                  |
                                  {' '}
                                </strong>
                                {e.noteText}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      )}
                      {notes.length === 0 && <p>No notes</p>}
                    </>
                  )}

                  <h4>Photos</h4>

                  <ImageViewerUploader
                    attachmentImages={workRequest?.images}
                    images={images}
                    setImages={setImages}
                    readOnly={!!code}
                  />
                </Card.Body>
                <Card.Footer>
                  <Row
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                    }}
                    className="justify-content-around"
                  >
                    <Link
                      style={{
                        height: 'fit-content',
                      }}
                      to="/home"
                    >
                      <Button
                        style={{
                          height: 'fit-content',
                          width: '80px',
                        }}
                        variant="secondary"
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      style={{
                        height: 'fit-content',
                        width: '80px',
                      }}
                      disabled={posting}
                      active={posting}
                      variant="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </>
  );
};

export default WorkOrderRequestPage;
