import React from 'react';
import { useSpring, animated as a } from 'react-spring';

const SlideIn = (props) => {
  const {
    children,
    settings,
  } = props;
  const slideInFieldProps = useSpring(settings);
  return (
    <a.div
      style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', ...slideInFieldProps,
      }}
    >
      {children}
    </a.div>
  );
};

export default SlideIn;
