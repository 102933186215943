import React from 'react';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useController, useFormContext } from 'react-hook-form';

import { allLabels } from '../../constants/siemens/constants';

/**
 * Input component
 */
export function Input(props) {
  const { control } = useFormContext();
  const { name, label, disabled = false } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: {
      error,
    },
    // formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  return (
    <TextField
      {...inputProps}
      label={allLabels[label] || label}
      inputRef={ref}
      fullWidth
      helperText={error?.message}
      error={!!error}
      disabled={disabled}
      // InputLabelProps={{ shrink: true }}
    />
  );
}

/**
 * Datetime Picker component
 */
export function Datetime(props) {
  const { control } = useFormContext();
  const {
    name, label, disabled = false, now = false,
  } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: {
      error,
    },
    // formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  // get given date or current date as string
  const getDefaultDate = (newNow, milliseconds) => {
    const fstr = (s) => s.substring(0, s.length - 8);
    if (newNow) return fstr(moment().format());
    if (milliseconds) return fstr(moment(milliseconds).format());
    return null;
  };
  return (
    <TextField
      {...inputProps}
      value={getDefaultDate(now, inputProps.value) || ''}
      inputRef={ref}
      type="datetime-local"
      fullWidth
      label={allLabels[label] || label}
      helperText={error?.message}
      error={!!error}
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
    />
  );
}

/**
 * Select component
 */
export function Select(props) {
  const { control } = useFormContext();
  const {
    name, label, options = [], disabled = false,
  } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: {
      error,
    },
    // formState: { touchedFields, dirtyFields, errors }
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  if (options.length) {
    return (
      <TextField
        {...inputProps}
        select
        label={allLabels[label] || label}
        inputRef={ref}
        fullWidth
        helperText={error?.message}
        error={!!error}
        disabled={disabled}
        // InputLabelProps={{ shrink: true }}
      >
        {options
          ? options.map((option) => (
            option.disabled
              ? (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </MenuItem>
              ) : <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))
          : null}
      </TextField>
    );
  }
  return null;
}

//  yup string regex validation for phone numbers,
//  e.g. yup.string(),//.matches(phoneRegex, "Invalid phone number")
export const phoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
