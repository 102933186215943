import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Home,
  SupervisorAccountOutlined,
  Help,
} from '@material-ui/icons';
import { Context } from '../../functions/siemens/context';
import { LogoHeaderWithNav, SupportModal } from '../header';

export const SiemensHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const logoSrc = 'siemens/logo_white.svg';
  const { username, labels } = useContext(Context);
  let version;
  if (labels) version = (labels && labels['js.versionNo']) || '';
  else version = '';
  // TODO create pages used in home dropdown, support dropdown

  const dropdowns = [
    [
      'home',
      Home,
      'Home',
      [['/exports', 'Table exports']],
      () => history.push('/home'),
    ],
    [
      'customer',
      SupervisorAccountOutlined,
      'Customer Self Service',
      [
        ['/myDetails', 'My Details'],
        ['/tickets', 'Service Tickets'],
        ['/ticket', 'Create Ticket'],
        ['/quotations', 'Quotations'],
        ['/assets', 'Assets'],
        ['/viewMap', 'View Map'],
      ],
    ],
    [
      'support',
      Help,
      'Support',
      [
        ['/supportSMSForm', 'Send Support Request'],
        ['/releaseNotes', 'Release Notes'],
        ['/referenceManual', 'Reference Manual'],
        [() => setShowModal(true), 'About Forcelink'],
      ],
      () => setShowModal(true),
    ],
  ];
  return (
    <>
      <LogoHeaderWithNav {...{
        history, logoSrc, username, dropdowns,
      }}
      />
      <SupportModal
        showModal={showModal}
        setShowModal={setShowModal}
        version={version}
      />
    </>
  );
};
