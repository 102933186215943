import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';

import '../../styles/siemens/home.scss';
// import { testKPIs } from "../../constants/siemens/constants"; //dev
import { getKPIs } from '../../scripts/siemens/homeScripts';

function groupedKPI(kpi) {
  const grouped = {};
  kpi.forEach((i) => {
    const group = i.displayGroup.label;
    const item = {
      label: i.label,
      number: i.numericResult,
      limitObj: i.navigationFilterJson,
    };
    if (grouped) {
      grouped[group].push(item);
    } else {
      grouped[group] = [item];
    }
  });
  return grouped;
}

export const KPIDashboard = ({ title = 'KPI Dashboard' }) => {
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      // const d = groupedKPI(testKPIs);
      const res = await getKPIs();
      const groupedData = groupedKPI(res);
      setData(groupedData);
    }
    getData();
  }, []);
  console.log(data);

  const KPICard = ({ label, number, limitObj }) => {
    console.log(limitObj);
    return (
      <Card variant="outlined" style={{ backgroundColor: '#f7f7f7', height: '100%' }}>
        <CardActionArea style={{ height: '100%' }}>
          <Link
            style={{ color: 'black' }}
            to={{ pathname: '/kpi', state: limitObj }}
          >
            <CardContent>
              <Typography align="center" variant="h4" gutterBottom>{number}</Typography>
              <Typography align="center" variant="h6" color="textSecondary">{label}</Typography>
            </CardContent>
          </Link>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <Paper variant="outlined" style={{ marginBottom: '0.5rem', padding: '1.5rem' }}>
      <div style={{ flex: 1, textAlign: 'center' }}>
        <Typography variant="h6">{title}</Typography>
      </div>
      {data
        ? Object.keys(data).map((group) => (
          <div style={{ marginTop: '1.5rem' }}>
            <Typography variant="h6" gutterBottom>{group}</Typography>
            <Grid container direction="row" spacing={2}>
              {data[group].map((item) => (
                <Grid item md={2} sm={4} xs={6}>
                  <KPICard label={item.label} number={item.number} limitObj={item.limitObj} />
                </Grid>
              ))}
            </Grid>
          </div>
        ))
        : null}
    </Paper>
  );
};
