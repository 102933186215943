import React from 'react';
import {
  Navbar,
  Nav,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/botswanatourism/headerandfooter.scss';

export class PortalNav extends React.Component {
  componentDidMount = async () => {
    this.setState({});
  }

  render() {
    const {
      page,
    } = this.props;
    let image = '';
    if (page.pathname === '/home') {
      image = 'PawLine.png';
    } else if (page.pathname === '/facilitiesinformation') {
      image = 'EditFacilityDetialsLine.png';
    } else if (page.pathname.includes('quer')) {
      image = 'LoggingLine.png';
    } else if (page.pathname.includes('appli')) {
      image = 'GradingApplicationsLine.png';
    } else if (page.pathname === '/downloads') {
      image = 'DownloadLine.png';
    }
    return (
      <div>
        <Navbar variant="dark" expand="md" id="portalNav">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <img
                src="botswanatourism/BotswanaLogo.png"
                id="navbarImage"
                alt="Botswana Tourism"
              />

              <Link to="/home" className={page.pathname === '/home' ? 'textBoldUnderLineColour' : ''}>Home</Link>
              <Link to="/facilitiesinformation" className={page.pathname === '/facilitiesinformation' ? 'textBoldUnderLineColour' : ''}>Facility information</Link>
              <Link to="/queries" className={page.pathname === '/queries' ? 'textBoldUnderLineColour' : ''}>Queries</Link>
              <Link to="/applications" className={page.pathname === '/applications' ? 'textBoldUnderLineColour' : ''}>Applications</Link>
              <Link to="/downloads" className={page.pathname === '/downloads' ? 'textBoldUnderLineColour' : ''}>Downloads</Link>
            </Nav>
            <Form inline>
              <Nav className="mr-auto">
                <Link to="/logout">Log out</Link>
              </Nav>
            </Form>
          </Navbar.Collapse>
        </Navbar>

        <div id="navbarBackgroundImageContainer">
          <img
            src="botswanatourism/TheChillingHippo.png"
            id="navbarBackgroundImage"
            alt="Botswana Tourism"
          />
          <div id="headerText">
            {`${page.pathname === '/home' ? 'Facilities Self Service Portal' : ''}`}
            {`${page.pathname === '/facilitiesinformation' ? 'Facility Information' : ''}`}
            {`${page.pathname.includes('quer') ? 'Queries' : ''}`}
            {`${page.pathname.includes('appli') ? 'Applications' : ''}`}
            {`${page.pathname === '/downloads' ? 'Downloads' : ''}`}
            <br />
            <br />
            <img
              src={`botswanatourism/${image}`}
              id="headerTextImage"
              alt="Botswana Tourism"
            />
            <br />
            <br />
            <div id="headerSubText">
              {`${page.pathname === '/home' ? '' : ''}`}
              {`${page.pathname === '/facilitiesinformation' ? 'Validate your facility Information' : ''}`}
              {`${page.pathname === '/queries' ? 'Log your queries' : ''}`}
              {`${page.pathname === '/queryform' ? 'Create your query' : ''}`}
              {`${page.pathname.includes('/queryform/') ? 'Edit your query' : ''}`}
              {`${page.pathname === '/applications' ? 'Log your applications' : ''}`}
              {`${page.pathname === '/applicationform' ? 'Create your application' : ''}`}
              {`${page.pathname.includes('/applicationform/') ? 'Edit your application' : ''}`}
              {`${page.pathname === '/downloads' ? 'Here are some items available for download.' : ''}`}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export const ForcelinkFooter = () => (
  <Row style={{ margin: '0px', paddingLeft: '2vw', paddingRight: '2vw' }}>
    <Col xs={12} style={{ paddingLeft: '5vw', paddingRight: '5vw' }}>
      <hr />
    </Col>
    <Col xs={9} style={{ padding: '0px' }}>
      <div style={{ padding: '15px' }}>
        If you require any further assistance, please contact Botswana Tourism Organisation on:
        <br />
        Tel: +267 391-3111/310-5601 or
        <br />
        Email: itgrading@botswanatourism.co.bw
      </div>
    </Col>
    <Col xs={3} style={{ padding: '10px' }}>
      <img href="https://www.forcelink.net" src="forcelink/powered_by_forcelink.png" style={{ maxWidth: '80%', maxHeight: '80%', float: 'right' }} alt="Powered by Forcelink" />
    </Col>
  </Row>
);
