import React from 'react';
import { Tooltip } from '@mui/material';

/**
 * Generic Forcelink Button
 *
 * @param children what is displayed in the button
 * @param type the type of styling (primary or secondary)
 * @param onClick what happens when you click the button
 * @param tooltip the button tooltip
 * @param disabled optional button disabled: default(false)
 */
const DocumentButton = ({
  children,
  type,
  onClick,
  tooltip,
}) => (
  <Tooltip title={tooltip} enterDelay={1000} arrow>
    <div
      role="button"
      tabIndex={0}
      className={`button button-${type}`}
      onClick={(event) => {
        onClick(event);
      }}
    >
      {children}
    </div>
  </Tooltip>
);

export default DocumentButton;
