import { apiUrl } from '../../constants/constantsGlobal';
import { fetchApi, standardError } from '../../functions/apiCalls';

export const getKPIs = () => fetchApi(
  `${apiUrl}dashboardentriesmanager/getKPIResultsForCurrentUser`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => {
    standardError(e, 'Error occurred loading the KPIs');
  });

export const convertKPIs = (KPIs) => {
  const categories = KPIs.reduce((cats, { displayGroup: { label } }) => {
    if (!cats.includes(label)) {
      return [...cats, label];
    } return cats;
  }, []);
  console.log('categories:', categories);

  let rowsUnordered = categories.map((cat) => {
    const kpis = KPIs.filter(({ displayGroup: { label } }) => label === cat);
    return [cat, kpis];
  });
  rowsUnordered = [...rowsUnordered].map((r) => Object.fromEntries([r]));
  rowsUnordered = [...rowsUnordered].map((cat) => ({
    [Object.keys(cat)[0]]: Object.values(
      cat,
    )[0].map(({
      url, numericResult, label, navigationFilterJson,
    }) => [
      url,
      numericResult,
      label,
      navigationFilterJson,
    ]),
  }));

  const rows = [[]];
  rowsUnordered.forEach((cat) => {
    const catTitle = Object.keys(cat)[0];
    const catCards = Object.values(cat)[0];
    let currRowIndex = rows.length - 1;
    let currRow = rows[currRowIndex];
    let currRowLen = currRow.reduce(
      (total, curr) => total + Object.values(curr)[0].length,
      0,
    ) || 0;
    if (currRowLen === 6) {
      currRowIndex = rows.length;
      currRow = [];
      currRowLen = 0;
    }
    rows[currRowIndex] = [
      ...(currRow || []),
      { [catTitle]: [...catCards].splice(0, 6 - currRowLen) },
    ];
    for (let i = 6 - currRow.length; i < catCards.length; i += 6) {
      rows[rows.length - Math.floor(i / 6)] = [
        { [catTitle]: [...catCards].splice(i, i + 6) },
      ];
    }
  });
  return rows;
};
