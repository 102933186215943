import React, { useRef, useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import {
  FieldsContainer,
  Row,
} from '../forms.styled';
import FormSection from '../helpers/formsection';
import SelectDropdown from '../helpers/selectdropdown';

const PassengerDetails = (props) => {
  const { submit, doSubmit } = props;
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const titleRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const cellphoneRef = useRef();
  const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  useEffect(() => {
    if (submit) {
      let valid = true;
      if (title === '') {
        titleRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (firstName === '') {
        firstNameRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (lastName === '') {
        lastNameRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (!(email !== '' && email.match(validEmail) !== null)) {
        emailRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (cellphone === undefined || (cellphone !== undefined && (cellphone === '' || !isPossiblePhoneNumber(cellphone)))) {
        cellphoneRef.current.parentNode.classList.add('invalid-phone-input');
        valid = false;
      }
      doSubmit(title, firstName, lastName, email, cellphone, valid);
    }
  }, [submit]);

  return (
    <FormSection title="Passenger Details">
      <FieldsContainer>
        <Row>
          <FieldsContainer className="left-side-name">
            <SelectDropdown
              thisRef={titleRef}
              onChange={(event) => {
                setTitle(event.target.value);
                if (event.target.value && event.target.value !== '') {
                  titleRef.current.classList.remove('invalid-input');
                } else {
                  titleRef.current.classList.add('invalid-input');
                }
              }}
              options={[{ label: 'Mr', value: 'Mr' }, { label: 'Mrs', value: 'Mrs' }, { label: 'Ms', value: 'Ms' }, { label: 'Dr', value: 'Dr' }]}
              value={title}
            />
            <p
              style={{
                margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
              }}
              className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
            >
              Title
            </p>
          </FieldsContainer>
          <FieldsContainer className="right-side-name">
            <input
              className="name-title"
              type="text"
              name="firstName"
              value={firstName}
              ref={firstNameRef}
              onChange={(event) => {
                setFirstName(event.target.value);
                if (event.target.value && event.target.value !== '') {
                  firstNameRef.current.classList.remove('invalid-input');
                } else {
                  firstNameRef.current.classList.add('invalid-input');
                }
              }}
            />
            <p
              style={{
                margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
              }}
              className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
            >
              First Name
            </p>
          </FieldsContainer>
        </Row>
        <FieldsContainer>
          <input
            type="text"
            name="lastname"
            value={lastName}
            ref={lastNameRef}
            onChange={(event) => {
              setLastName(event.target.value);
              if (event.target.value && event.target.value !== '') {
                lastNameRef.current.classList.remove('invalid-input');
              } else {
                lastNameRef.current.classList.add('invalid-input');
              }
            }}
          />
          <p
            style={{
              margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
            }}
            className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
          >
            Last Name
          </p>
        </FieldsContainer>
        <FieldsContainer>
          <input
            type="text"
            name="email"
            value={email}
            ref={emailRef}
            onChange={(event) => {
              setEmail(event.target.value);
              if (event.target.value && event.target.value !== '' && event.target.value.match(validEmail) !== null) {
                emailRef.current.classList.remove('invalid-input');
              } else {
                emailRef.current.classList.add('invalid-input');
              }
            }}
          />
          <p
            style={{
              margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
            }}
            className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
          >
            Email
          </p>
        </FieldsContainer>
        <FieldsContainer>
          <PhoneInput
            ref={cellphoneRef}
            international
            countryCallingCodeEditable={false}
            defaultCountry="ZA"
            value={cellphone}
            onChange={(value) => {
              if (value !== undefined) {
                setCellphone(value);
                cellphoneRef.current.parentNode.classList.remove('invalid-phone-input');
              } else {
                cellphoneRef.current.parentNode.classList.add('invalid-phone-input');
                setCellphone('');
              }
            }}
            limitMaxLength={12}
          />
          <p
            style={{
              margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
            }}
            className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
          >
            Cellphone
          </p>
        </FieldsContainer>
      </FieldsContainer>
    </FormSection>
  );
};

export default PassengerDetails;
