import React from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from './functions/bedworth/context';
import { ForcelinkFooter, LogoHeaderWithoutNav } from './components/header';
import { BedworthHeader } from './components/bedworth/header';
import { Login } from './pages/bedworth/login';
import { Home } from './pages/bedworth/home';
import { ViewTickets } from './pages/bedworth/viewTickets';
import WorkOrderRequestPage from './pages/bedworth/workOrderRequest';
import CustomerDetailsPage from './pages/bedworth/customerDetails';

export class BedworthApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'Bedworth Student Portal';
    const { openSnackbar } = this.state;
    const {
      authenticated,
      logout,
      cookies: { cookies },
    } = this.context;
    console.log('Authenticated:', authenticated);

    if (authenticated === null) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    const headerPaths = [
      '/home',
      '/details',
      '/tickets',
      '/ticket',
    ];

    const pagesPaths = [
      ['/details', <CustomerDetailsPage />],
      ['/tickets', <ViewTickets />],
      ['/ticket/:code', <WorkOrderRequestPage />],
      ['/ticket', <WorkOrderRequestPage />],
    ];

    return (
      <BedworthWrapper>
        <Switch>
          {headerPaths.map((path, i) => (
            <Route path={path} key={i}>
              <BedworthHeader paths={headerPaths} />
            </Route>
          ))}
          <Route path="/">
            <LogoHeaderWithoutNav
              logoSrc="bedworth/bedworth_logo.png"
            />
          </Route>
        </Switch>
        <div id="App">
          <Container fluid id="AppContainer">
            <Switch>
              {[
                ['/login', <Login />],
              ].map(([path, page], i) => (
                <Route
                  key={i}
                  path={path}
                  render={({ location }) => (!authenticated ? (
                    page
                  ) : (
                    <Redirect
                      to={{ pathname: '/home', state: { from: location } }}
                    />
                  ))}
                />
              ))}
              {!authenticated && (
                <Route
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
              <Route
                path="/home"
                render={({ location }) => (authenticated ? (
                  <Home />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                ))}
              />
              <Route path="/logout" render={() => ((logout(), null))} />

              {pagesPaths.map(([path, page], i) => (
                <Route path={path} key={i}>
                  {page}
                </Route>
              ))}
              <Route
                path="/exports"
                component={() => {
                  console.log('Navigating to exports table');
                  window.location.replace(
                    'https://servest.forcelink.net/forcelink/exports.html',
                  );
                  return <h4>Redirecting...</h4>;
                }}
              />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </div>
        <Route path="/">
          <ForcelinkFooter />
        </Route>
        {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
            >
              <Alert
                onClose={this.snackbarClose}
                severity="info"
                elevation={6}
                variant="filled"
              >
                Logged in with username
                {' '}
                <b>{cookies.username}</b>
              </Alert>
            </Snackbar>
        )}
      </BedworthWrapper>
    );
  }
}

const BedworthWrapper = (props) => {
  const {
    children,
  } = props;
  const loc = useLocation();
  let className;
  if (loc.pathname === '/login') className = 'withBackgroundImg';
  else className = 'withBackgroundColor';

  return (
    <div id="BedworthApp" className={className}>
      {children}
    </div>
  );
};

const NoMatch = () => {
  const location = useLocation();
  document.title = '404 Not Found | Bedworth';
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
