import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, FormGroup } from '@mui/material';

const DocumentCheckboxes = ({
  values,
  setValues,
  apiCall,
}) => {
  const [options, setOptions] = useState(
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      const apiCallOptions = await apiCall();
      setOptions(apiCallOptions);
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    const temp = [...values];
    const label = event.target.name;
    const optionIndex = options.findIndex((option) => option.label === label);
    if (optionIndex !== -1) {
      const index = temp.findIndex((object) => object === options[optionIndex].value);
      if (index === -1 && event.target.checked) {
        setValues([...temp, options[optionIndex].value]);
      } else if (index !== -1) {
        temp.splice(index, 1);
        setValues([...temp]);
      }
    }
  };

  return (
    <div
      className="checkboxes"
    >
      <div>
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              key={`check_box_${option.label}-${option.value}`}
              control={(
                <Checkbox
                  onChange={handleChange}
                  name={option.label}
                  checked={
                    values !== undefined
                      ? values.findIndex((value) => value === option.value) !== -1
                      : undefined
                  }
                />
              )}
              label={option.label}
            />
          ))}
        </FormGroup>
      </div>
    </div>
  );
};

export default DocumentCheckboxes;
