import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Context } from '../../functions/servest_is/context';
import { getAllActiveUserGroupsDWR, uploadOrEditDocument, findLabelValueByClassAndProperty } from '../../functions/apiCalls';
import { DocumentDialogFields, ButtonWrapper } from './documents.styled';
import DocumentButton from './document_button';
import DocumentSelectMenu from './document_select_menu';
import DocumentTextInput from './document_text_input';
import DocumentCheckboxes from './document_checkboxes';

const DocumentDialog = ({
  open,
  setOpen,
  className,
  recordId,
  refresh,
  document,
  setDocument,
}) => {
  const { labels } = useContext(Context);
  const [file, setFile] = useState(undefined);
  const [id, setId] = useState('');
  const [filename, setFilename] = useState('');
  const [documentTypeId, setDocumentTypeId] = useState('');
  const [authorisedGroups, setAuthorisedGroups] = useState([]);

  useEffect(() => {
    if (document !== undefined) {
      console.log('document', document);
      setFilename(document.filename);
      setId(`${document.id}`);
      setDocumentTypeId(document.documentTypeId);
      setAuthorisedGroups(document.authorisedGroups);
    }
  }, [document]);

  const handleAddDocument = async (event) => {
    const newFile = event.target.files[0];
    setFile(newFile);
    setFilename(newFile.name);
  };

  const handleClose = () => {
    setDocument(undefined);
    setFile(undefined);
    setId('');
    setFilename('');
    setDocumentTypeId('');
    setAuthorisedGroups([]);
    setOpen(false);
  };

  const handleUploadDocument = async () => {
    if (file !== undefined || id !== '') {
      const formData = new FormData();
      if (id !== '') {
        formData.append('id', id);
      }
      if (file !== undefined) {
        formData.append('uploadfile', file);
      }
      formData.append('className', className);
      formData.append('recordId', recordId);
      formData.append('filename', filename);
      formData.append('documentTypeId', documentTypeId);
      formData.append('authorisedGroups', JSON.stringify(authorisedGroups));
      const response = await uploadOrEditDocument(formData).then((res) => res.json());
      if (typeof response !== 'string') {
        refresh();
        handleClose();
      }
    }
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '65vw',
          borderRadius: '0px',
          padding: '24px',
        },
        '& .MuiDialogTitle-root': {
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '27px',
          color: '#000000',
          fontFamily: '"Poppins", sans-serif',
          padding: '0px 0px 24px 0px',
        },
        '& .MuiDialogContentText-root': {
          fontFamily: '"Poppins", sans-serif',
          fontWeight: '300',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000000',
        },
        '& .MuiDialogContent-root': {
          padding: '0px 0px 24px 0px',
        },
        '& .MuiDialogActions-root': {
          padding: '0px',
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {document !== undefined ? 'Edit Document' : 'Upload Documents'}
      </DialogTitle>
      <DocumentDialogFields>
        <div
          style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
        >
          <div className="default-form-field">
            <div className="field-title">{labels['Document.filename']}</div>
            <DocumentTextInput
              value={filename}
              placeholder=""
              setValue={(value) => {
                setFilename(value);
              }}
            />
          </div>
          <div className="default-form-field">
            <div className="field-title">{labels['Document.documentType']}</div>
            <DocumentSelectMenu
              type="select"
              value={documentTypeId}
              setValue={(value) => {
                if (typeof value === 'string') {
                  setDocumentTypeId(value);
                }
              }}
              apiCall={async () => {
                const options = await findLabelValueByClassAndProperty(
                  'Document',
                  'documentType',
                ).then((res) => res.json());
                return options;
              }}
            />
          </div>
        </div>
        <div
          style={{ position: 'relative' }}
        >
          <div style={{ position: 'absolute' }}>
            <DocumentButton
              type="secondary"
              onClick={() => {
                setOpen(true);
              }}
              tooltip="Add Document"
            >
              <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px',
              }}
              >
                <AttachFileIcon
                  style={{ color: '#102b7c' }}
                />
                Attach Files
              </div>
            </DocumentButton>
          </div>
          <input
            style={{ opacity: 0, height: '42px', width: '140px' }}
            type="file"
            onChange={handleAddDocument}
          />
        </div>
        <DocumentCheckboxes
          title={labels.authorisedGroups}
          isEmpty={false}
          values={authorisedGroups}
          setValues={(value) => {
            if (typeof value !== 'string') {
              setAuthorisedGroups(value);
            }
          }}
          apiCall={async () => {
            const options = await getAllActiveUserGroupsDWR().then((res) => res.json());
            return options;
          }}
        />
      </DocumentDialogFields>
      <DialogActions>
        <ButtonWrapper>
          <DocumentButton
            type="primary"
            onClick={handleUploadDocument}
            tooltip={document !== undefined ? 'Confirm Update' : 'Confirm Upload'}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {document !== undefined ? 'Update' : 'Upload'}
            </div>
          </DocumentButton>
          <DocumentButton
            type="secondary"
            onClick={handleClose}
            tooltip="Cancel"
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              Close
            </div>
          </DocumentButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDialog;
