import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Consent,
  Switch,
} from '../forms.styled';
import FormSection from '../helpers/formsection';

const Declaration = (props) => {
  const { submit, doSubmit } = props;

  const [accepted, setAccepted] = useState(false);
  const [date, setDate] = useState(null);
  const consentRef = useRef();

  useEffect(() => {
    if (submit) {
      let valid = true;
      if (!accepted) {
        consentRef.current.classList.add('invalid-input');
        valid = false;
      }
      doSubmit(date, valid);
    }
  }, [submit]);

  const handleAccept = () => {
    if (accepted) {
      setDate(null);
    } else {
      consentRef.current.classList.remove('invalid-input');
      setDate(dayjs(new Date().toISOString().split('T')[0]));
    }
    setAccepted(!accepted);
  };

  return (
    <FormSection title="Declaration">
      <Consent ref={consentRef}>
        <div className="declaration">
          <div className="body">
            I, the undersigned, understand that providing untrue information
            constitutes fraud and certify that the information provided is true in all respects.
          </div>
        </div>
        <Switch
          onClick={() => {
            handleAccept();
          }}
        >
          <div className={`backdrop ${accepted && 'toggled-on'}`}>
            <div className="button" />
          </div>
        </Switch>
      </Consent>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disabled
          value={date}
          format="DD/MM/YYYY"
          readOnly
          slotProps={{
            textField: {
              helperText: 'Date',
            },
          }}
          onChange={(newValue) => { setDate(newValue); }}
        />
      </LocalizationProvider>
    </FormSection>
  );
};

export default Declaration;
