import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row, Col, Container, Card, Form, Button, Alert,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from '../../functions/ssg_facilities/context';
import {
  getPortalCustomerDetails,
  updatePortalUserDetails,
} from '../../functions/apiCalls';

/**
 * Page for viewing/changing the customer details
 */
const CustomerDetailsPage = () => {
  const { labels } = useContext(Context);

  const [customerDetails, setCustomerDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const history = useHistory();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: customerDetails });

  useEffect(() => {
    document.title = 'Customer Account Details | SSG Facilities Portal';

    async function fetchData() {
      setLoading(true);
      const response = await getPortalCustomerDetails().then((res) => res.json());
      console.log('UPDATE:');
      setCustomerDetails(response);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => reset(customerDetails), [customerDetails]);

  const onSubmit = (data) => {
    console.log(data);

    const requestBody = {
      ...data.user,
    };

    async function postData() {
      setPosting(true);
      const response = await updatePortalUserDetails(requestBody).then((res) => res.json());
      setSubmitResponse(response);
      setPosting(false);
      if (response.result === 'SUCCESS') {
        history.push('/home');
      }
    }

    postData();

    // scroll to top after submit
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // console.log(watch("custom1")); // watch input value by passing the name of it

  return (
    <>
      {submitResponse && (
        <Alert
          variant={submitResponse.result === 'SUCCESS' ? 'success' : 'danger'}
          dismissible
          onClose={() => setSubmitResponse(null)}
        >
          {submitResponse.result === 'SUCCESS'
            ? 'Successfully updated your details.'
            : submitResponse.errorMessage || 'Error: Could not update your details.'}
        </Alert>
      )}

      <Container fluid>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <Card.Header>
                  <Card.Title className="text-center">
                    <h3>
                      <b>Edit Your details</b>
                    </h3>
                  </Card.Title>
                </Card.Header>

                <Dimmer inverted active={loading}>
                  <Loader>Loading your details</Loader>
                </Dimmer>

                <Card.Body className="ml-md-5 mr-md-5">
                  <h4>
                    Customer Account Details
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['User.fullName']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.fullName"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.fullName}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User FullName</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.user?.fullName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.username']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.username"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.username}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User Username</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.createdDate?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.password']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.password"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.password}
                              type="password"
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User password</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.user?.password?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.passwordHint']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.passwordHint"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.user?.passwordHint}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">User passwordHint</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.user?.passwordHint?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <h4>
                    Contact Details
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['User.mobileNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.mobileNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.mobileNumber}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">mobileNumber</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.mobileNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.phoneNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.phoneNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.phoneNumber}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">officeNumber</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.officeNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['user.email']}</Form.Label>
                        <Controller
                          control={control}
                          name="user.email"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.email}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">emailAddress</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.emailAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>

                <Card.Footer>
                  <Row
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                    }}
                    className="justify-content-around"
                  >
                    <Link
                      style={{
                        height: 'fit-content',
                      }}
                      to="/home"
                    >
                      <Button
                        style={{
                          height: 'fit-content',
                          width: '80px',
                        }}
                        variant="secondary"
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      style={{
                        height: 'fit-content',
                        width: '80px',
                      }}
                      disabled={posting}
                      active={posting}
                      variant="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </>
  );
};

export default CustomerDetailsPage;
