import React, {
  useRef, useMemo, useState, useEffect,
} from 'react';
import {
  TileLayer,
  MapContainer as Map,
  Marker,
  Popup,
  useMap,
  useMapEvent,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { icon as icn } from 'leaflet';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import '../styles/leafletMap.css';
import { getQueryLayerPoints } from '../scripts/mapScripts';
// import { GestureHandling } from 'leaflet-gesture-handling'; // needs to stay imported

// TODO: move useMap().setView(...) into DraggableMarker component
const ChangeMapView = ({ coords }) => {
  const map = useMap();
  map.setView(coords, map.getZoom());
  return null;
};

export const LayerPoints = ({ layerId = 2002 }) => {
  const [points, setPoints] = useState([]);
  const [bounds, setBounds] = useState(useMap().getBounds());

  const markerIcon = icn({
    iconUrl: 'marker-icon-2x.png',
    iconSize: [25, 41],
  });

  const map = useMapEvent('moveend', () => setBounds(map.getBounds()));

  useEffect(() => {
    async function fetchData() {
      const b = [
        bounds.getSouthWest().lat,
        bounds.getSouthWest().lng,
        bounds.getNorthEast().lat,
        bounds.getNorthEast().lng,
      ];
      const pointsApi = await getQueryLayerPoints(layerId, true, 'CPM', ...b);
      setPoints((oldPoints) => [...oldPoints, ...pointsApi]);
    }
    fetchData();
  }, [bounds]);

  return points === null ? null : (
    points.map((point) => (
      <Marker
        icon={markerIcon}
        position={[point.latitude, point.longitude]}
      >
        <Popup>
          <p>
            <strong>{point.code}</strong>
            <br />
            {point.description}
          </p>
        </Popup>
      </Marker>
    )));
};

export function LeafletMap({
  zoom = 14, center, onCenterChange, style, children,
}) {
  const markerIcon = icn({
    iconUrl: 'marker-icon-2x.png',
    iconSize: [25, 41],
  });
  return (
    <Map
      style={style}
      id="mapid"
      className="map_canvas"
      center={center}
      zoom={zoom}
      scrollWheelZoom
      gestureHandling
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ChangeMapView coords={center} />
      {onCenterChange
        && (
        <DraggableMarker
          center={center}
          icon={markerIcon}
          onCenterChange={onCenterChange}
        />
        )}
      {children}
    </Map>
  );
}

const DraggableMarker = ({ center, icon, onCenterChange }) => {
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onCenterChange(marker.getLatLng());
        }
      },
    }),
    [],
  );

  return (
    <Marker
      icon={icon}
      draggable
      eventHandlers={eventHandlers}
      position={center}
      ref={markerRef}
    >
      <Popup minWidth={90}>You can drag the pin to fine-tune your location</Popup>
    </Marker>
  );
};
