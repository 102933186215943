import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row, Col, Container, Card, Form, Button, Alert, ListGroup,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { Context } from '../../functions/bedworth/context';
import {
  getWorkRequest,
  postWorkRequest,
  getWorkRequestTypes,
  getWorkRequestSubtypes,
  getPortalCustomerDetails,
  addWorkRequestAttachment,
} from '../../functions/apiCalls';
import { getNotes } from '../../scripts/botswanatourism/scripts';
import ImageViewerUploader from '../../components/ImageViewerUploader';

const WorkOrderRequestPage = () => {
  const params = useParams();
  const { code } = params;
  const { labels } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [workRequest, setWorkRequest] = useState();
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [posting, setPosting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const [typeOptions, setTypeOptions] = useState([]);
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: workRequest });

  useEffect(() => {
    document.title = code
      ? `Edit request ${code} | Bedworth Student Portal`
      : 'Create request | Bedworth Student Portal';

    async function fetchData() {
      setLoading(true);
      if (code) {
        const wr = await getWorkRequest(code).then((res) => res.json());
        setWorkRequest({
          ...wr,
        });
        setNotes(await getNotes(wr.id));
      } else {
        const customerDetails = await getPortalCustomerDetails().then((res) => res.json());
        setWorkRequest({
          createdDate: moment().valueOf(),
          customerCode: customerDetails.code,
          customerDescription: customerDetails.description,
          emailAddress: customerDetails.emailAddress,
          address: customerDetails.address,
          childWorkType: '',
          contactEmail: customerDetails.user.email || customerDetails.emailAddress,
          contactNumber: customerDetails.user.phoneNumber
            || customerDetails.user.mobileNumber
            || customerDetails.officeNumber
            || customerDetails.mobileNumber,
          docType: 'WorkOrderRequest',
          resourceFeedback: '',
        });
      }
      const items = [];
      const typesData = await getWorkRequestTypes().then((res) => res.json());
      const results = [];
      for (let i = 0; i < typesData.length; i++) {
        results.push(getWorkRequestSubtypes(
          typesData[i].value,
        ).then((res) => res.json()));
      }
      const subtypesData = await Promise.all(results);
      typesData.forEach((type, i) => {
        const hasSubtypes = !!subtypesData[i].length;
        items.push({ label: type.label, value: type.value, disabled: hasSubtypes });
        subtypesData[i].forEach((subtype) => items.push({ label: `↳ ${subtype.label}`, value: subtype.value }));
      });
      setTypeOptions(items);
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    reset(workRequest);
    setImages(workRequest?.images);
  }, [workRequest]);

  const onSubmit = (data) => {
    console.log(data);

    async function postData() {
      setPosting(true);
      const workRequestObject = {
        ...workRequest,
        ...data,
        workType: data.workRequestType,
      };
      console.log('wr', workRequestObject);

      const response = await postWorkRequest(workRequestObject).then((res) => res.json());

      if (!code && response.result === 'SUCCESS' && images?.length > 0) {
        const wr = await getWorkRequest(response.code);
        const wrObj = await wr.json();
        const id = await wrObj.id;

        images?.forEach(async (i) => {
          const formData = new FormData();
          formData.append('workRequestId', id);
          formData.append('file', i.file);
          await addWorkRequestAttachment(formData, 'bedworth');
        });
      }

      setSubmitResponse(response);
      setPosting(false);
    }

    postData();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {submitResponse && (
        <Alert
          variant={submitResponse.result === 'SUCCESS' ? 'success' : 'danger'}
          dismissible
          onClose={() => setSubmitResponse(null)}
        >
          {submitResponse.result === 'SUCCESS'
            ? `Successfully ${code ? 'updated' : 'logged'} request ${submitResponse.code}.`
            : submitResponse.errorMessage || `Error: Could not ${code ? 'update' : 'log'} request.`}
        </Alert>
      )}

      <Container fluid>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <Card.Header>
                  <Card.Title className="text-center">
                    <h3>
                      <b>{code ? 'Edit request' : 'Create request'}</b>
                    </h3>
                  </Card.Title>
                </Card.Header>

                <Dimmer inverted active={loading}>
                  <Loader>Loading request details</Loader>
                </Dimmer>

                <Card.Body className="ml-md-5 mr-md-5">
                  <h4>
                    Request details
                  </h4>

                  {code && (
                    <Row>
                      <Col md="6" sm="12">
                        <Form.Group className="mb-2">
                          <Form.Label>{labels['WorkOrderRequest.dateCreated']}</Form.Label>
                          <Controller
                            control={control}
                            name="createdDate"
                            render={({
                              field: {
                                onChange, onBlur, value, ref,
                              },
                            }) => (
                              <Form.Control
                                onBlur={onBlur}
                                onChange={onChange}
                                value={moment(value).format('DD/MM/YYYY HH:mm')}
                                ref={ref}
                                isInvalid={errors.createdDate}
                                readOnly
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6" sm="12">
                        <Form.Group className="mb-2">
                          <Form.Label>{labels['WorkOrderRequest.workRequestStatus.description']}</Form.Label>
                          <Controller
                            control={control}
                            name="statusDescription"
                            render={({ field }) => (
                              <Form.Control
                                {...field}
                                isInvalid={errors.statusDescription}
                                readOnly
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                  )}

                  <Form.Group className="mb-2">
                    <Form.Label>{labels['WorkOrderRequest.description']}</Form.Label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          isInvalid={errors.description}
                          placeholder="Enter problem description"
                          as="textarea"
                          rows={3}
                        />
                      )}
                    />
                    {/* <Form.Text className="text-muted">Description</Form.Text> */}
                    <Form.Control.Feedback type="invalid">
                      {errors.username?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['WorkOrderRequest.workRequestType']}</Form.Label>
                        <Controller
                          control={control}
                          name="workRequestType"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Form.Control
                              as="select"
                              {...field}
                              isInvalid={errors.workRequestType}
                            >
                              <option disabled selected value="">Select</option>
                              {typeOptions.length === 0 && <option disabled value="">Loading...</option>}
                              {typeOptions.map(({ value, label, disabled }) => (
                                label.includes('↳')
                                  ? (
                                    <option value={value}>
                                          &nbsp;
                                          &nbsp;
                                      {label}
                                    </option>
                                  ) : (
                                    <option disabled={disabled} value={value}>{label}</option>
                                  )
                              ))}
                            </Form.Control>
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.workRequestType?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" sm="12" />
                  </Row>

                  <h4>
                    Student details
                  </h4>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.code']}</Form.Label>
                        <Controller
                          control={control}
                          name="customerCode"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.customerCode}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Customer Code</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.createdDate?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.description']}</Form.Label>
                        <Controller
                          control={control}
                          name="customerDescription"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.customerDescription}
                              readOnly
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Customer Description</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.customerDescription?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.officeNumber']}</Form.Label>
                        <Controller
                          control={control}
                          name="contactNumber"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactNumber}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Office Number</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.contactNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md="6" sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>{labels['Customer.emailAddress']}</Form.Label>
                        <Controller
                          control={control}
                          name="contactEmail"
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              isInvalid={errors.contactEmail}
                            />
                          )}
                        />
                        {/* <Form.Text className="text-muted">Email Number</Form.Text> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.contactEmail?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {code && (
                    <>
                      <h4>
                        Notes
                      </h4>

                      {notes.length > 0 && (
                        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                          <ListGroup variant="flush">
                            {notes.map(({ timestamp, noteText }) => (
                              <ListGroup.Item>
                                <strong>
                                  {moment(timestamp).format('DD-MM-yyyy HH:mm')}
                                  {' '}
                                  |
                                  {' '}
                                </strong>
                                {noteText}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      )}
                      {notes.length === 0 && (
                        <p>No notes</p>
                      )}
                    </>
                  )}

                  <h4>
                    Photos
                  </h4>

                  <ImageViewerUploader
                    attachmentImages={workRequest?.images}
                    images={images}
                    setImages={setImages}
                    readOnly={!!code}
                  />

                </Card.Body>
                <Card.Footer>
                  <Row className="justify-content-around">
                    <Link to="/home">
                      <Button variant="secondary">
                        Cancel
                      </Button>
                    </Link>
                    <Button disabled={posting} active={posting} variant="primary" type="submit">
                      Submit
                    </Button>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </>
  );
};

export default WorkOrderRequestPage;
