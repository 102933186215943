import styled from 'styled-components';

export const FormContainer = styled.div`
  background-color: #F2F2F2;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 24px;
`;

export const InnerContainer = styled.div`
  height: calc(100% - 48px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s;
  background-color: #fff;

  @media only screen and (min-width: 850px) {
    width: 700px;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 24px;
`;

export const StyleContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  overflow-x: hidden;
  padding: 40px 0px;
`;

export const FormHeaderContainer = styled.div`
  width: 100%;
  padding: 0px 24px 12px 24px;
`;

export const Navigator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const FormTitle = styled.div`
  font-weight: 650;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

export const SubscriberImage = styled.div`
  background-image: url("/myciti/myciti_logo.png");
  background-blend-mode: overlay;
  background-size: cover;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 73px;
  height: 27px;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 650;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #00558A;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  :hover {
    color: #D2232A;
  }
`;

export const BlueSectionTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 650;
  font-size: 12px;
  line-height: 14px;
  color: #00558A;
  padding-bottom: 5px;
  border-bottom: 2px solid #00558A80;
`;

export const SectionContainer = styled.div`
  padding: 0px 5px;
  width: 100%;

  .expirationdate {
    margin-bottom: 17px !important;
  }

  .MuiFormControl-root {
    width: 100%;

    label {
      top: -2px;
      left: 2px;
    }
  }

  .MuiInputLabel-root.Mui-focused {
    display: none;
  }

  .textarea {
    margin-bottom: 16px;

    .MuiInputBase-root {
      height: unset;
    }
  }

  .MuiInputBase-root {
    height: 45px;
    border: 1px solid #999999;
    transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;
    border-radius: 0px;

    input {
      padding: 12px 16px;
    }
  }

  .MuiInputBase-root.Mui-focused {
    border: 1px solid #80bdff;
    box-shadow: 0 0 0 0.2rem #007bff40;
  }

  fieldset {
    border: none !important;
  }
  
  .Mui-focused fieldset {
    border: none !important;
  }

  .invalid-date {
    .MuiInputBase-root {
      border: 1px solid red !important;
      box-shadow: 0 0 0 0.2rem #ff000040 !important;
    }
  }

  .invalid-input {
    border: 1px solid red !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #ff000040 !important;
  }

  .MuiButtonBase-root {
    display: flex !important;
  }

  .MuiFormHelperText-root {
    margin: 3px 0px 0px 0px;
  }

  .location-search {
    
    .MuiInputBase-root {
      padding: 0px 65px 0px 12px !important;
    }

    .MuiAutocomplete-endAdornment {
      top: calc(50% - 12px);
      right: px !important;
    }

    .MuiCircularProgress-root {
      position: absolute;
      right: 45px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  .expirationdate {
    width: 100%;
    margin-right: 0px !important;
  }

  .name-title {
    width: 100%;
    margin-right: 0px !important;
  }

  @media only screen and (min-width: 500px) {
    flex-direction: row;

    .left-side-name {
      width: 20%;
      margin-right: 16px;
    }

    .right-side-name {
      width: 80%;
    }

    .left-side {
      margin-right: 16px !important;
    }
    
    .expirationdate {
      width: 50% !important;
    }
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;
  margin-bottom: 0px !important;

  input {
    color: #000;
    border: 1px solid #999999;
    height: 45px;
    padding: 12px 16px;
    width: 100%;
    transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;

    :focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem #007bff40;
    }
  }

  .invalid-phone-input {
    border: 1px solid red !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #ff000040 !important;
  }

  .PhoneInput {
    width: 100%;
    height: 45px;
    background-color: #fff;
    color: #919191;
    border: 1px solid #999999;
    padding: 0px 20px;
    transition: border-color 0.6s, box-shadow 0.6s,
      outline 0.6s;

    :focus-within {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem #007bff40;
    }

    .PhoneInputCountryIcon {
      box-shadow: none !important;
    }

    .PhoneInputCountrySelectArrow {
      color: #fff !important;
    }

    input {
      border: none !important;
      height: 43px !important;
      width: 100%;
      margin: 0 !important;

      :focus {
        border-color: transparent !important;
        outline: 0;
        box-shadow: none;
      }
    }
  }
`;

export const DisclaimerContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-weight: 650;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #000000;

  .link {
    :hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 4px 24px;

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 41px;
    border-radius: 2px;
    font-weight: 650;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    outline: none;
    transition: box-shadow 0.6s;

    :focus {
      box-shadow: 0 0 0 0.2rem #007bff40;
    }
  }
`;

export const CancelButton = styled.div`
  width: 30%;
  margin-right: 16px;
  color: #333333;
  background: #ffffff;
  border: 1px solid #999999;
`;

export const SubmitButton = styled.div`
  width: 70%;
  color: #ffffff;
  background: #00558A;
  border: 1px solid #00558A;
`;

export const Consent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #999999;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;
  
  .declaration{

    .title {
      font-style: normal;
      font-weight: 650;
      font-size: 14px;
      line-height: 17px;
      color: #707070;
    }

    .body {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #707070;
    }
  }
`;

export const ApplicationRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  .typeSelect {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #999999;
    transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;
  }

  .type {

    ul {
      margin: 0px;
      padding-left: 20px;
    }

    .title {
      font-style: normal;
      font-weight: 650;
      font-size: 14px;
      line-height: 17px;
      color: #707070;
      margin-bottom: 4px;
    }

    .body {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #707070;
    }
  }
`;

export const Switch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .toggled-on {
    background: #00558A;
    border: 2px solid #00558A !important;
    
    .button {
      margin-left: 21px;
    }
  }

  .backdrop {
    width: 51px;
    height: 31px;
    border: 2px solid #AAAAAA;
    border-radius: 32px;
    transition: border 0.3s, background 0.3s;
  }

  .button {
    width: 27px;
    height: 27px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
    border-radius: 73px;
    transition: margin-left 0.3s;
  }
`;

export const DropZoneContainer = styled.div`

  .dropzone{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed #999999;
    height: 120px;
    width: 100%;
    font-weight: 650;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #707070;
  }
`;
