import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FormTemplate from './helpers/formtemplate';
import PassengerDetails from './sections/refundpassengerdetails';
import Declaration from './sections/declaration';
import RefundDetails from './sections/refunddetails';
import FormButtons from './sections/formbuttons';
import IncidentDetails from './sections/incidentdetails';
import { addWorkRequestAttachment, getWorkRequest, postWorkRequest } from '../../../scripts/myciti/scripts';
import {
  ScrollContainer,
} from './forms.styled';

const RefundForm = () => {
  const [form, setForm] = useState({});
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [validSubmit, setValidSubmit] = useState(true);
  const [submitAll, setSubmitAll] = useState(false);
  const [submitPassengerDetails, setSubmitPassengerDetails] = useState(false);
  const [passengerDetails, setPassengerDetails] = useState({});
  const [submitDeclaration, setSubmitDeclaration] = useState(false);
  const [declaration, setDeclaration] = useState({});
  const [submitRefundDetails, setSubmitRefundDetails] = useState(false);
  const [refundDetails, setRefundDetails] = useState({});
  const [submitIncidentDetails, setSubmitIncidentDetails] = useState(false);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const doSubmit = () => {
    setSubmitAll(true);
    setSubmitPassengerDetails(true);
    setSubmitDeclaration(true);
    setSubmitRefundDetails(true);
    setSubmitIncidentDetails(true);
    setLoading(true);
  };

  const getPassengerDetails = (
    title,
    firstName,
    lastName,
    email,
    cellphone,
    cardNumber,
    valid,
  ) => {
    setSubmitPassengerDetails(false);
    if (valid) {
      setPassengerDetails({
        contactName: `${title} ${firstName} ${lastName}`,
        contactEmail: email,
        contactNumber: cellphone,
        custom24: cardNumber,
        valid,
      });
    } else {
      setPassengerDetails({});
    }
  };

  const getRefundDetails = (
    chargedincorrectly,
    refundslip,
    valueDisputed,
    tappedIn,
    tappedOut,
    referenceNumber,
    cardNumber,
    motivation,
    refundType,
    file,
    valid,
  ) => {
    setSubmitRefundDetails(false);
    if (valid) {
      let customLookupCode4 = '';
      if (chargedincorrectly) {
        customLookupCode4 = 'AT03';
      } else if (refundslip) {
        customLookupCode4 = 'AT04';
      }
      setRefundDetails({
        customLookupCode4,
        address: {
          address1: '',
          address2: '',
          address3: '',
          address4: '',
          address5: '',
          address6: '',
          address7: '',
          address8: '',
          countryCode: 'za',
          description: '',
          firmCoordinate: true,
          geomArea: null,
          provinceCode: '',
          requiresGeocoding: false,
          requiresReverseGeocoding: true,
          routePoints: null,
        },
        custom17: valueDisputed,
        custom18: tappedIn,
        custom19: tappedOut,
        custom2: referenceNumber,
        custom10: cardNumber,
        description: `Motivation: ${motivation}`,
        customLookupCode2: refundType === 'mine' ? 'RFE' : 'RFN',
        file,
        valid,
      });
    } else {
      setRefundDetails({});
    }
  };

  const getIncidentDetails = (
    dateIncident,
    timeIncident,
    stationShop,
    busNumber,
    incidentDescription,
    valid,
  ) => {
    setSubmitIncidentDetails(false);
    if (valid) {
      const dateTime = new Date(dayjs(dateIncident.toISOString().split('T')[0]));
      dateTime.setDate(dateIncident.$D);
      dateTime.setMonth(dateIncident.$M);
      dateTime.setFullYear(dateIncident.$y);
      dateTime.setHours(timeIncident.$H);
      dateTime.setMinutes(timeIncident.$m);
      setIncidentDetails({
        customDate3: +dateTime,
        address: {
          address1: '',
          address2: '',
          address3: '',
          address4: '',
          address5: '',
          address6: '',
          address7: '',
          address8: '',
          countryCode: 'za',
          description: '',
          firmCoordinate: true,
          geomArea: null,
          latitude: stationShop.lat,
          longitude: stationShop.lng,
          provinceCode: '',
          requiresGeocoding: false,
          requiresReverseGeocoding: true,
          routePoints: null,
        },
        custom15: busNumber,
        description: `Incident Details: ${incidentDescription}`,
        valid,
      });
    } else {
      setIncidentDetails({});
    }
  };

  const getDeclaration = (date, valid) => {
    if (valid) {
      const dateTime = new Date(dayjs(date.toISOString().split('T')[0]));
      dateTime.setDate(date.$D);
      dateTime.setMonth(date.$M);
      dateTime.setFullYear(date.$y);
      setDeclaration({
        customDate4: +dateTime,
        valid,
      });
    } else {
      setDeclaration({});
    }
    setSubmitDeclaration(false);
  };

  useEffect(() => {
    const valid = passengerDetails.valid && declaration.valid && refundDetails.valid
    && incidentDetails.valid;
    const description = `${incidentDetails.description}\n${refundDetails.description ? refundDetails.description : ''}`;
    delete incidentDetails.description;
    delete refundDetails.description;
    setForm({
      ...passengerDetails,
      ...refundDetails,
      ...incidentDetails,
      ...declaration,
      description,
      valid,
    });
  }, [passengerDetails, declaration, refundDetails, incidentDetails]);

  useEffect(() => {
    if ((form.custom10 !== undefined && form.custom10 !== '' && form.custom10 !== '5576-9700-') && form.custom10 === form.custom24) {
      setSubmitAll(false);
      setValidSubmit(false);
      setOpenSnackbar(true);
      setToastMessage('Please use a different card number when refunding to a different card.');
      setLoading(false);
    } else if (form.valid) {
      const formSubmit = async () => {
        const tempForm = form;
        const documentFiles = tempForm.file;
        delete tempForm.file;
        delete tempForm.valid;
        const workRequest = {
          ...tempForm,
          docType: 'WorkOrderRequest',
          typeCode: 'LCR',
          priorityCode: 'LRP',
          customLookupCode1: 'AFC',
          orgUnitCode: 'AFC',
          assetCodes: ['AFCR'],
        };
        const response = await postWorkRequest(workRequest).then((res) => res.json());
        const wr = await getWorkRequest(response.code).then((res) => res.json());
        documentFiles.forEach(async (documentFile) => {
          const formData = new FormData();
          formData.append('workRequestId', wr.id);
          formData.append('file', documentFile);
          await addWorkRequestAttachment(formData, 'myciti');
        });
        history.push(`/slip?code=${encodeURIComponent(response.code)}`);
        setLoading(false);
      };
      formSubmit();
    } else if (submitAll) {
      setSubmitAll(false);
      setValidSubmit(false);
      setOpenSnackbar(true);
      setToastMessage('Invalid form');
      setLoading(false);
    }
  }, [form]);

  return (
    <FormTemplate
      title="Refund Application Form"
      openSnackbar={openSnackbar}
      setOpenSnackbar={setOpenSnackbar}
      toastMessage={toastMessage}
      validSubmit={validSubmit}
      loading={loading}
    >
      <ScrollContainer>
        <PassengerDetails submit={submitPassengerDetails} doSubmit={getPassengerDetails} />
        <RefundDetails submit={submitRefundDetails} doSubmit={getRefundDetails} />
        <IncidentDetails submit={submitIncidentDetails} doSubmit={getIncidentDetails} />
        <Declaration submit={submitDeclaration} doSubmit={getDeclaration} />
      </ScrollContainer>
      <FormButtons doSubmit={doSubmit} />
    </FormTemplate>
  );
};

export default RefundForm;
