import React, { useState, useEffect } from 'react';
import {
  Row, Container, Button,
} from 'react-bootstrap';
import { DataGrid } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import '../../styles/botswanatourism/home.scss';
import { getTable, getLabels } from '../../scripts/botswanatourism/scripts';
import { isAuthenticated } from '../../functions/apiCalls';

export const Queries = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  // When the components mounts we need to populate the table
  useEffect(() => {
    async function fetchData() {
    // Auth routing
      const auth = await isAuthenticated();
      console.log('Auth', auth);
      let data = [];
      const getColumns = (headers, headerNames, widths) => headers.reduce(
        (arr, val, i) => [
          ...arr,
          { field: val, headerName: headerNames[i], width: widths[i] },
        ],
        [],
      );

      const table = await getTable('CQ');
      const labels = await getLabels();

      data = table;

      if (data.length === 0) {
      // If there is no information table
        console.log('Empty table');
        data = [{
          '.': 'No data available in table',
        }];
      }
      // If there is information in the table
      const tableClass = 'WorkOrderRequest';
      // Get the columns
      const columnNames = [];
      Object.keys(data[0]).forEach((key) => {
        if (labels[`${tableClass}.${key}`]) {
          columnNames.push(labels[`${tableClass}.${key}`]);
        } else {
        // If there is no label it gets the key name.
          columnNames.push(key[0].toUpperCase() + key.substring(1));
        }
      });
      // Col width calc
      const width = {};
      const maxWidth = 300;
      const widthMultiplier = 10;
      Object.keys(data[0]).forEach((key) => {
        width[key] = key.length * widthMultiplier;
      });
      for (let i = 0; i < data.length; i++) {
        Object.keys(data[i]).forEach((key) => {
        // Replace all "null" with ""
          if (data[i][key] === 'null') {
            data[i][key] = '';
          }
          if (data[i][key] && data[i][key].length * widthMultiplier > width[key]) {
            if (data[i][key].length * widthMultiplier > maxWidth) {
              width[key] = maxWidth;
            } else {
              width[key] = data[i][key].length * widthMultiplier;
            }
          }
        });
      }
      const arrWidth = Object.values(width);
      let newColumns = [];
      newColumns = {
        jsonKeys: Object.keys(data[0]),
        headers: Object.keys(data[0]),
        headerNames: columnNames,
        widths: arrWidth,
      };

      const { jsonKeys, headers } = newColumns;
      // Map the table correctly
      const tableFormatted = data.map((entry, i) => {
        const entryFormatted = { id: i };
        jsonKeys.forEach((key, j) => {
          const keyFormatted = headers[j];
          entryFormatted[keyFormatted] = entry[key];
        });
        return entryFormatted;
      });

      const { headerNames, widths } = newColumns;
      const colcol = getColumns(headers, headerNames, widths);
      Object.keys(colcol).forEach((c) => {
        if (colcol[c].field === 'code') {
          colcol[c].renderCell = (val) => <a href={`botswanatourism/queryform/${val.value}`}>{val.value}</a>;
        }
      });
      setRows(tableFormatted);
      setColumns(colcol);
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Row style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '10px' }}>
        {rows.length > 0
              && (
                <div style={{ minHeight: 500, width: '100%' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    hideFooter
                    rowHeight={40}
                    headerHeight={40}
                    loading={loading}
                  />
                </div>
              )}
      </Row>
      <div style={{ height: '20px' }}>
        <Link to="/queryform">
          <Button variant="primary" style={{ float: 'right', width: '150px' }}>
            Add new
          </Button>
          {' '}
        </Link>
      </div>
    </Container>
  );
};
