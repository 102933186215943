import React from 'react';
import {
  Row, Container, Col, Image,
} from 'react-bootstrap';
import { Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../../styles/servest_it/home.scss';

export const LandingCards = () => (
  <Container fluid>
    <Row noGutters>
      <Col lg={6} />
      <Col lg={6} style={{ height: '100%' }}>
        <Row md={1}>
          <Col lg={11} className="servest-responsive-text" style={{ margin: '0 auto', paddingLeft: '7rem' }}>
            <h1 className="text-center servest-title" style={{ color: '#133c98', textAlign: 'center', fontFamily: 'roboto,sans-serif' }}>SERVEST SERVICES PORTAL</h1>
            <p
              className="servest-subtitle"
              style={{
                marginBottom: '10%', marginTop: '5vh', marginLeft: '1rem', marginRight: '1rem', textAlign: 'center',
              }}
            >
              Welcome to the Servest Centralized Services Portal.
              An integrated facilities management solutions platform servicing
              our clients across multiple sectors.
            </p>
          </Col>
        </Row>
        <Row className="servest-responsive-row">
          <Col xl={6} lg={12}>
            <Link to="/employee">
              <Card className="landingCardWhite">
                <Image src="servest_it/landing_icon_employee.png" wrapped ui={false} style={{ width: '50%', margin: 'auto', paddingTop: '2rem' }} />
                <Card.Header style={{ marginTop: '20%', fontSize: '20px', fontFamily: 'roboto,sans-serif' }}>I am a Servest</Card.Header>
                <Card.Header style={{ paddingTop: '20px', fontSize: '30px', fontFamily: 'roboto,sans-serif' }}>EMPLOYEE</Card.Header>
                <Card.Description style={{ paddingTop: '35%', fontSize: '15px', lineHeight: '1.3' }} />
              </Card>
            </Link>
          </Col>
          <Col xl={6} lg={12}>
            <Link to="/customer">
              <Card className="landingCardWhite">
                <Image src="servest_it/landing_icon_customer.png" wrapped ui={false} style={{ width: '50%', margin: 'auto', paddingTop: '2rem' }} />
                <Card.Header style={{ marginTop: '20%', fontSize: '20px', fontFamily: 'roboto,sans-serif' }}>I am a Servest</Card.Header>
                <Card.Header style={{ paddingTop: '20px', fontSize: '30px', fontFamily: 'roboto,sans-serif' }}>CUSTOMER</Card.Header>
                <Card.Description style={{ paddingTop: '35%', fontSize: '15px', lineHeight: '1.3' }} />
              </Card>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);
