import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  LinearProgress,
} from '@mui/material';
import {
  HomeContainer,
  OuterContainer,
  InnerContainer,
  SubscriberImage,
  StyleContainer,
  StyleHr,
  Footer,
  BoxContainer,
  BoxRow,
  Box as MyBox,
  ContactInfoContainer,
  ContactInfo,
  Header,
  Title,
  BackButton,
} from '../../pages/myciti/slip/slip.styled';
import SlideIn from './slidein';
import { getWorkRequest } from '../../scripts/myciti/scripts';

const CustomerSlip = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [fullName, setFullName] = useState('');
  const [dateIssued, setDateIssued] = useState('');
  const [issueNumber, setIssueNumber] = useState('');
  const [slipLink, setSlipLink] = useState('');

  useEffect(() => {
    const code = queryParameters.get('code');
    const fetchDetails = async () => {
      setIssueNumber(code);
      const workRequest = await getWorkRequest(code).then((res) => res.json());
      setFullName(workRequest.contactName);
      function join(t, a, s) {
        function format(m) {
          const f = new Intl.DateTimeFormat('en', m);
          return f.format(t);
        }
        return a.map(format).join(s);
      }
      const a = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
      const s = join(new Date(workRequest.createdDate), a, ' ');
      setDateIssued(s);
      const reportName = 'myciti_customer_slip';
      setSlipLink(`https://${process.env.REACT_APP_API_BASE.replace('-customer', '')}/forcelink/printreport.action?reportName=${reportName}&param=work_request_id&param_value=${workRequest.id}&exportType=browser_pdf`);
    };
    fetchDetails();
  }, []);

  useEffect(() => {
    if (fullName !== '' && dateIssued !== '' && issueNumber !== '' && slipLink !== '') {
      setLoading(false);
    }
  }, [fullName, dateIssued, issueNumber, slipLink]);

  return (
    <HomeContainer>
      <OuterContainer>
        <div />
        <InnerContainer>
          <Box sx={{
            width: 'calc(100% + 48px)', marginTop: '-40px', marginBottom: '16px', marginLeft: '-24px',
          }}
          >
            {loading && <LinearProgress sx={{ color: '#102b7c' }} />}
          </Box>
          <StyleContainer>
            {!loading && (
            <SlideIn settings={{
              marginRight: 0,
              width: '100%',
              from: { marginRight: -450 },
              config: { mass: 1, tension: 300, friction: 30 },
            }}
            >
              <Header>
                <BackButton onClick={() => { history.push('/home'); }}>
                  <ArrowBackIosIcon />
                  <div style={{ opacity: '0.5' }}>Home</div>
                </BackButton>
                <SubscriberImage />
                <div style={{ width: '68px', height: '21px' }} />
              </Header>
              <Title>
                Customer slip
              </Title>
              <h3>
                Thank you! Your request has been submitted.
              </h3>
              <h3>
                A confirmation email and SMS has also been shared with you.
                You will receive feedback via the Transport Information Centre (TIC)
                once your application has been processed.
              </h3>
              <h3>
                You can also follow up on this application by emailing:
                <a href="mail transport.info@capetown.gov.za">transport.info@capetown.gov.za</a>
                .
              </h3>
              <BoxContainer>
                <BoxRow>
                  <MyBox style={{ marginLeft: '0px' }}>
                    <h1>
                      Passenger Fullname
                    </h1>
                    <h2>
                      {fullName}
                    </h2>
                  </MyBox>
                  <MyBox style={{ marginRight: '0px' }}>
                    <h1>
                      Date Issued
                    </h1>
                    <h2>
                      {dateIssued}
                    </h2>
                  </MyBox>
                </BoxRow>
                <MyBox>
                  <h1>
                    Reference Number
                  </h1>
                  <h2>
                    {`#${issueNumber}`}
                  </h2>
                </MyBox>
              </BoxContainer>
            </SlideIn>
            )}
          </StyleContainer>
          <StyleHr />
          <ContactInfoContainer>
            <div />
            <ContactInfo>
              <a href={slipLink} target="_blank" rel="noreferrer">Download</a>
            </ContactInfo>
          </ContactInfoContainer>
          <Box sx={{
            width: 'calc(100% + 48px)', marginTop: '16px', marginBottom: '-40px', marginLeft: '-24px',
          }}
          >
            {loading && <LinearProgress sx={{ color: '#102b7c' }} />}
          </Box>
        </InnerContainer>
        <Footer>
          <div
            style={{ paddingRight: '5px' }}
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net');
            }}
          >
            Powered by Forcelink&trade; 2024 |
          </div>
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net/privacy-policy');
            }}
          >
            Privacy Policy
          </div>
        </Footer>
      </OuterContainer>
    </HomeContainer>
  );
};

export default CustomerSlip;
