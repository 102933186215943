import React from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Container,
  Accordion,
  Table,
} from 'react-bootstrap';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import '../../styles/botswanatourism/home.scss';
import lottie from 'lottie-web';
import {
  getLabels,
  getRootItemByClassAndProp,
  getNotes,
  getDocuments,
  addNote,
  uploadFile,
  postWorkRequest,
  getWorkRequest,
  findChildLabelValuesByClassAndPropertyAndCode,
  byClassAndProp,
  getPortalCustomerDetails,
} from '../../scripts/botswanatourism/scripts';
import { Context } from '../../functions/botswanatourism/context';

export class ApplicationForm extends React.Component {
  static contextType = Context;

  animate = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      newForm: true,
      newNote: false,
      newAlert: false,
      alertMessage: 'Place holder',
      newDocument: false,
      noteArray: [],
      documentArray: [],
      readyToRedirect: false,
      redirectTo: '',
      // Labels for the form
      workRequestObjectLabel: {
        appointment: '',
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        description: '',
        workType: '',
        custom9: '',
        customLookup1: '',
        customLookup2: '',
        customLookup3: '',
        customLookup4: '',
      },
      workRequestObjectData: {
        appointment: '',
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        description: '',
        workType: 'AV',
        custom9: '',
        customLookupCode1: '',
        customLookupCode2: '',
        customLookupCode3: '',
        customLookupCode4: '',
        statusCode: '',
        statusDescription: '',
      },
      customerLabels: {
        // Form -> Facility Information
        code: '',
        description: '',
        customerType: '',
        customNumber1: '',
        customNumber2: '',
        customNumber3: '',
        customNumber4: '',
        custom10: '',
        customNumber6: '',
        custom2: '',
        joinDate: '',
        customLookup3: '',
        custom6: '',
        custom7: '',
        custom3: '',
      },
      customerData: {
        // Form -> Facility Information
        code: '',
        description: '',
        customerType: '',
        customNumber1: '',
        customNumber2: '',
        customNumber3: '',
        customNumber4: '',
        custom10: '',
        customNumber6: '',
        custom2: '',
        joinDate: '',
        customLookup3: '',
        custom6: '',
        custom7: '',
        custom3: '',
      },
      // Whats needed in the form
      workRequestTypes: [],
      customLookup1List: [],
      customLookup2List: [],
      customLookup3List: [],
      customLookup4List: [],
    };

    // Uncontrolled Inputes or References
    this.contactNameRef = React.createRef();
    this.contactNumberRef = React.createRef();
    this.contactEmailRef = React.createRef();
    this.workTypeRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.newFileNameRef = React.createRef();
    this.noteTextFieldRef = React.createRef();
    this.custom9Ref = React.createRef();
    this.customLookup1Ref = React.createRef();
    this.customLookup2Ref = React.createRef();
    this.customLookup3Ref = React.createRef();
    this.customLookup4Ref = React.createRef();
    // Controlled inputes

    this.handleWorkTypeChange = this.handleWorkTypeChange.bind(this);
  }

  handleWorkTypeChange(event) {
    this.setState({ workRequestObjectData: { workType: event.target.value } });
  }

  componentDidMount = async () => {
    const { code } = this.props;
    const { workRequestObjectData } = this.state;

    // Loading
    lottie.loadAnimation({
      container: this.animate,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'botswanatourism/paw.json',
    });
    // Need this to get the field names
    const labels = await getLabels();
    this.setState({
      workRequestObjectLabel: {
        appointment: labels['WorkOrderRequest.appointment'],
        contactName: labels['WorkOrderRequest.contactName'],
        contactNumber: labels['WorkOrderRequest.contactNumber'],
        contactEmail: labels['WorkOrderRequest.contactEmail'],
        description: labels['WorkOrderRequest.description'],
        workRequestType: labels['WorkOrderRequest.workRequestType'],
        custom9: labels['WorkOrderRequest.custom9'],
        customLookup1: labels['WorkOrderRequest.customLookup1'],
        customLookup2: labels['WorkOrderRequest.customLookup2'],
        customLookup3: labels['WorkOrderRequest.customLookup3'],
        customLookup4: labels['WorkOrderRequest.customLookup4'],
      },
      customerLabels: {
        code: labels['Customer.code'],
        description: labels['Customer.description'],
        customerType: labels['Customer.customerType'],
        customNumber1: labels['Customer.customNumber1'],
        customNumber2: labels['Customer.customNumber2'],
        customNumber3: labels['Customer.customNumber3'],
        customNumber4: labels['Customer.customNumber4'],
        custom10: labels['Customer.custom10'],
        customNumber6: labels['Customer.customNumber6'],
        custom2: labels['Customer.custom2'],
        joinDate: labels['Customer.joinDate'],
        customLookup3: labels['Customer.customLookup3'],
        custom6: labels['Customer.custom6'],
        custom7: labels['Customer.custom7'],
        custom3: labels['Customer.custom3'],
      },
    });

    const workRequestObject = await getWorkRequest(code);
    const custdata = await getPortalCustomerDetails();
    const customLookup1List = await getRootItemByClassAndProp('WorkOrderRequest', 'customLookup1', '');
    const customLookup2List = await getRootItemByClassAndProp('WorkOrderRequest', 'customLookup2', '');
    const customLookup3List = await getRootItemByClassAndProp('WorkOrderRequest', 'customLookup3', '');
    const customLookup4List = await getRootItemByClassAndProp('WorkOrderRequest', 'customLookup4', '');

    const selectValuesCustomer = { customerType: '', customLookup3: '' };
    Object.keys(selectValuesCustomer).forEach(async (y) => {
      console.log(y);
      console.log(custdata[y]);
      const value = await byClassAndProp('Customer', y, custdata.code);
      Object.keys(value).forEach(async (x) => {
        if (value[x].value === custdata[y]) {
          console.log('found:', custdata[y], value[x].label);
          selectValuesCustomer[y] = value[x].label;
        }
      });
      console.log('___');
    });

    this.setState({
      customerData: {
        code: custdata.code,
        description: custdata.description,
        customerType: selectValuesCustomer.customerType,
        customNumber1: custdata.customNumber1,
        customNumber2: custdata.customNumber2,
        customNumber3: custdata.customNumber3,
        customNumber4: custdata.customNumber4,
        custom10: custdata.custom10,
        customNumber6: custdata.customNumber6,
        custom2: custdata.custom2,
        joinDate: custdata.joinDate,
        customLookup3: selectValuesCustomer.customLookup3,
        custom6: custdata.custom6,
        custom7: custdata.custom7,
        custom3: custdata.custom3,
        address: custdata.address,
      },
      customLookup1List,
      customLookup2List,
      customLookup3List,
      customLookup4List,
    });

    if (workRequestObject.length !== 0) {
      if (!workRequestObject.appointment) {
        workRequestObject.appointment = 'No appointment has been set.';
      }
      // Set the state var
      this.setState({
        workRequestObjectData: workRequestObject,
      });

      console.log('Work Request Object');
      console.log(workRequestObjectData);
      // You are now editing an application
      this.setState({ newForm: false });

      // Load the correct information for the drop down
      const workRequestTypes = await findChildLabelValuesByClassAndPropertyAndCode('WorkOrderRequest', 'workRequestType', 'AP');
      console.log('Drop down', workRequestTypes);
      this.setState({ workRequestTypes });

      console.log('Notes');
      const notess = await getNotes(workRequestObjectData.id);
      console.log(notess);
      this.setState({ noteArray: notess });

      console.log('Documents');
      const docs = await getDocuments(workRequestObjectData.id);
      console.log(docs);
      this.setState({ documentArray: docs });
      this.setState({ loading: false });
    } else {
      // You are creating a new application

      // Load the correct information for the drop down
      const workRequestTypes = await findChildLabelValuesByClassAndPropertyAndCode('WorkOrderRequest', 'workRequestType', 'AP');
      this.setState({ workRequestTypes });
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      readyToRedirect,
      redirectTo,
      loading,
      customerData,
      workRequestObjectData,
      workRequestObjectLabel,
      customerLabels,
      workRequestTypes,
    } = this.state;
    const {
      newForm, newNote, newDocument, newAlert,
    } = this.state;
    if (readyToRedirect && redirectTo !== '') {
      return <Redirect to={redirectTo} />;
    } if (readyToRedirect) {
      return <Redirect to="/applications" />;
    }

    const renderLoadingOverlay = () => {
      if (loading) {
        return (
          <div id="LoadingOverlayContainer">
            <div id="LoadingOverlay" />
            <div
              ref={(ref) => {
                this.animate = ref;
              }}
              id="LoadingOverlayImage"
            />
          </div>
        );
      }
      return null;
    };

    const saveNewApplication = () => {
      this.setState({ loading: true });
      // Load them into work request object
      const workRequestObject = {};
      workRequestObject.id = '';
      workRequestObject.contactEmail = this.contactEmailRef.current.value;
      workRequestObject.contactName = this.contactNameRef.current.value;
      workRequestObject.contactNumber = this.contactNumberRef.current.value;
      workRequestObject.description = this.descriptionRef.current.value;
      workRequestObject.workType = workRequestObjectData.workType;
      workRequestObject.custom9 = this.custom9Ref.current ? this.custom9Ref.current.value : null;
      workRequestObject.customLookupCode1 = this.customLookup1Ref.current
        ? this.customLookup1Ref.current.value : null;
      workRequestObject.customLookupCode2 = this.customLookup2Ref.current
        ? this.customLookup2Ref.current.value : null;
      workRequestObject.customLookupCode3 = this.customLookup3Ref.current
        ? this.customLookup3Ref.current.value : null;
      workRequestObject.customLookupCode4 = this.customLookup4Ref.current
        ? this.customLookup4Ref.current.value : null;
      workRequestObject.address = customerData.address;
      if (workRequestObject.appointment === 'No appointment has been set.') {
        workRequestObject.appointment = null;
      }
      // Yeet it
      postWorkRequest(JSON.stringify(workRequestObject)).then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          const temp = `/applicationform/${res.code}`;
          this.setState({ readyToRedirect: true, redirectTo: temp });
        } else {
          res.text().then((data) => {
            this.setState({ alertMessage: data });
            this.setState({ newAlert: true });
            this.setState({ loading: false });
          });
        }
      });
    };

    const saveExistingApplication = () => {
      const {
        code,
      } = this.props;
      this.setState({ loading: true });
      // Load them into work request object
      // "DEEP COPY"
      const workRequestObject = JSON.parse(JSON.stringify(workRequestObjectData));
      workRequestObject.contactEmail = this.contactEmailRef.current.value;
      workRequestObject.contactName = this.contactNameRef.current.value;
      workRequestObject.contactNumber = this.contactNumberRef.current.value;
      workRequestObject.description = this.descriptionRef.current.value;
      workRequestObject.workType = workRequestObjectData.workType;
      workRequestObject.custom9 = this.custom9Ref.current ? this.custom9Ref.current.value : null;
      workRequestObject.customLookupCode1 = this.customLookup1Ref.current
        ? this.customLookup1Ref.current.value : null;
      workRequestObject.customLookupCode2 = this.customLookup2Ref.current
        ? this.customLookup2Ref.current.value : null;
      workRequestObject.customLookupCode3 = this.customLookup3Ref.current
        ? this.customLookup3Ref.current.value : null;
      workRequestObject.customLookupCode4 = this.customLookup4Ref.current
        ? this.customLookup4Ref.current.value : null;
      workRequestObject.address = customerData.address;
      if (workRequestObject.appointment === 'No appointment has been set.') {
        workRequestObject.appointment = null;
      }

      postWorkRequest(JSON.stringify(workRequestObject)).then((res) => {
        if (res.status === 200) {
          const temp = '/applications';
          this.setState({ readyToRedirect: true, redirectTo: temp });
        } else {
          res.text().then((data) => {
            this.setState({ alertMessage: data });
            this.setState({ newAlert: true });
            this.setState({ loading: false });
            getWorkRequest(code).then((resp) => {
              this.setState({ workRequestObjectData: resp });
              document.forms.form.reset();
            });
          });
        }
      });
    };

    const toggleNote = () => {
      if (newNote) {
        this.setState({ newNote: false });
      } else {
        this.setState({ newNote: true });
      }
    };

    const saveNote = () => {
      // Saves the note
      addNote(workRequestObjectData.id, 1202, this.noteTextFieldRef.current.value).then(
        () => getNotes(workRequestObjectData.id).then(
          (data) => this.setState({ noteArray: data }),
        ),
      );
      // Close the add note section
      toggleNote();
    };

    const toggleDocument = () => {
      if (newDocument) {
        this.setState({ newDocument: false });
      } else {
        this.setState({ newDocument: true });
      }
    };

    const saveDocument = () => {
      // Create the form
      const formData = new FormData();
      const fileInput = document.querySelector('#fileInput');
      console.log(fileInput);
      const { type } = fileInput.files[0];
      console.log(type);
      let filenamef = this.newFileNameRef.current.value;
      if (filenamef === '') {
        console.log('Defaulting to filename as none given');
        filenamef = fileInput.files[0].name;
      }
      formData.append('className', 'WorkOrderRequest');
      formData.append('dictionaryTable.className', 'WorkOrderRequest');
      formData.append('recordId', workRequestObjectData.id);
      formData.append('id', '');
      formData.append('documentType', '');
      formData.append('filename', filenamef);
      formData.append('uploadfile', fileInput.files[0], filenamef);
      uploadFile(formData).then(
        () => getDocuments(workRequestObjectData.id).then(
          (data) => this.setState({ documentArray: data }),
        ),
      );
      toggleDocument();
    };

    const renderNewFormHeadings = () => {
      const {
        code,
      } = this.props;
      if (newForm) {
        return <h3>Create new application</h3>;
      }
      return (
        <h3>
          You are editing application
          {code}
          {' '}
          with status:
          {workRequestObjectData.statusDescription}
        </h3>
      );
    };
    const renderNewFormButtons = () => {
      if (newForm) {
        return (
          <div>
            <Button variant="primary" style={{ width: '150px', float: 'right' }} onClick={saveNewApplication}>
              Create
            </Button>
            {' '}
            <Link to="/applications">
              <Button variant="secondary" style={{ width: '150px' }}>
                Cancel
              </Button>
              {' '}
            </Link>
          </div>
        );
      }
      return (
        <div>
          <Button variant="primary" style={{ width: '150px', float: 'right' }} onClick={saveExistingApplication}>
            Save
          </Button>
          {' '}
          <Link to="/applications">
            <Button variant="secondary" style={{ width: '150px' }}>
              Cancel
            </Button>
            {' '}
          </Link>
        </div>
      );
    };

    const renderNewNote = () => {
      if (newNote) {
        return (
          <>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Add your new note here:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={this.noteText}
                ref={this.noteTextFieldRef}
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={saveNote}>
              Save your note
            </Button>
            {' '}
            <Button variant="primary" type="submit" onClick={toggleNote}>
              Cancel
            </Button>
            {' '}
          </>
        );
      }
      return null;
    };

    const renderNewDocument = () => {
      if (newDocument) {
        return (
          <>
            <hr />
            <Form id="newDocumentName">
              <Form.Control type="text" ref={this.newFileNameRef} placeholder="New file name (leave blank for current file name)" />
            </Form>
            <br />
            <Form id="newDocument">
              <Form.File
                id="fileInput"
                name="uploadfile"
              />
            </Form>
            <br />
            <Button variant="primary" type="submit" onClick={saveDocument}>
              Upload and save your document
            </Button>
            {' '}
            <Button variant="primary" type="submit" onClick={toggleDocument}>
              Cancel
            </Button>
            {' '}
          </>
        );
      }
      return null;
    };

    const renderNotesAndDocuments = () => {
      const {
        noteArray,
        documentArray,
      } = this.state;
      if (newForm) {
        return (
          <Row>
            <Col md={12}>
              To add notes and documents first create the application...
            </Col>
            <br />
          </Row>
        );
      }
      return (
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Notes
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Note type</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {noteArray.map((note, key) => (
                      <tr key={key}>
                        <td>{note.id}</td>
                        <td>{moment(note.timestamp).format('Do MMMM YYYY')}</td>
                        <td>{note.noteTypeDescription}</td>
                        <td style={{ wordWrap: 'anywhere' }}>{note.noteText}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {renderNewNote()}
                {!newNote
                  ? (
                    <>
                      <Button variant="primary" onClick={toggleNote}>
                        Add a note
                      </Button>
                    </>
                  )
                  : null}

              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Documents
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name and link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentArray.map((document, key) => (
                      <tr key={key}>
                        <td>{document.changeDate}</td>
                        <td>
                          <a href={document.url}>
                            {' '}
                            {document.filename}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {renderNewDocument()}
                {!newDocument
                  ? (
                    <>
                      <Button variant="primary" onClick={toggleDocument}>
                        Add a new document
                      </Button>
                    </>
                  )
                  : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    };

    const renderAlert = () => {
      const {
        alertMessage,
      } = this.state;
      if (newAlert) {
        return (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>Holy guacamole!</strong>
            {' '}
            {alertMessage}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
      }
      return null;
    };

    /* Rules
      1 Custom9
        ${workRequestType}=='Renewal Application'
        || ${workRequestType}=='Grading Amendment Application'
      2
        customLookup1 "${workRequestType}=='First Time Grading Application'
        || ${workRequestType}=='Renewal Application'
        || ${workRequestType}=='Grading Amendment Application'
        || ${workRequestType}=='Advisory Visit Request'  "
      3
        customLookup2 ${workRequestType}=='Ecotourism Application'
      4
        customLookup3 ${workRequestType}=='Ecotourism Application'
      5
        customLookup4 ${workRequestType}!='Advisory Visit Request'
        && (${workOrderRequestStatusLabel}=='Conditions Outstanding'
        || ${statusCode}=='CON'
        || ${workOrderRequestStatusLabel}=='Awaiting Verification of Compliance'
        || ${statusCode}=='AVOC'
        || ${workOrderRequestStatusLabel}=='Compliance Verification In Progress'
        || ${statusCode}=='CVP'
        || ${workOrderRequestStatusLabel}=='Compliance Verification Complete'
        || ${statusCode}=='CVC'
        || ${workOrderRequestStatusLabel}=='Grade Awarded' || ${statusCode}=='GA'
        || ${workOrderRequestStatusLabel}=='Application Completed' || ${statusCode}=='GC')
    */
    const renderBusinessRules1 = () => {
      if (workRequestObjectData.workType === 'RA' || workRequestObjectData.workType === 'GA') {
        return (
          <Col md={6}>
            <Form.Group className="formLabels">
              <Form.Label>{workRequestObjectLabel.custom9}</Form.Label>
            </Form.Group>
            <Form.Control
              type="text"
              ref={this.custom9Ref}
              defaultValue={workRequestObjectData.custom9}
              disabled={workRequestObjectData.statusCode !== 'CR'}
            />
          </Col>
        );
      }
      return null;
    };

    const renderBusinessRules2 = () => {
      const {
        customLookup1List,
      } = this.state;
      if (workRequestObjectData.workType === 'FT' || workRequestObjectData.workType === 'R' || workRequestObjectData.workType === 'GA' || workRequestObjectData.workType === 'AV') {
        return (
          <Col md={6}>
            <Form.Group className="formLabels">
              <Form.Label>{workRequestObjectLabel.customLookup1}</Form.Label>
            </Form.Group>
            <Form.Control
              name="customLookup1"
              type="text"
              as="select"
              ref={this.customLookup1Ref}
              disabled={workRequestObjectData.statusCode !== 'CR'}
            >
              {customLookup1List.map((type) => {
                if (workRequestObjectData.customLookupCode1
                  && workRequestObjectData.customLookupCode1.includes(type.value)) {
                  return (
                    <option value={type.value} key={type.value} selected>
                      {' '}
                      {type.label}
                      {' '}
                    </option>
                  );
                }
                return (
                  <option value={type.value} key={type.value}>
                    {' '}
                    {type.label}
                    {' '}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        );
      }
      return null;
    };

    const renderBusinessRules3 = () => {
      const {
        customLookup2List,
      } = this.state;
      if (workRequestObjectData.workType === 'ET') {
        return (
          <Col md={6}>
            <Form.Group className="formLabels">
              <Form.Label>{workRequestObjectLabel.customLookup2}</Form.Label>
            </Form.Group>
            <Form.Control
              name="customLookup2"
              type="text"
              as="select"
              ref={this.customLookup2Ref}
              disabled={workRequestObjectData.statusCode !== 'CR'}
            >
              {customLookup2List.map((type) => {
                if (workRequestObjectData.customLookupCode2
                  && workRequestObjectData.customLookupCode2.includes(type.value)) {
                  return (
                    <option value={type.value} key={type.value} selected>
                      {' '}
                      {type.label}
                      {' '}
                    </option>
                  );
                }
                return (
                  <option value={type.value} key={type.value}>
                    {' '}
                    {type.label}
                    {' '}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        );
      }
      return null;
    };

    const renderBusinessRules4 = () => {
      const {
        customLookup3List,
      } = this.state;
      if (workRequestObjectData.workType === 'ET') {
        return (
          <Col md={6}>
            <Form.Group className="formLabels">
              <Form.Label>{workRequestObjectLabel.customLookup3}</Form.Label>
            </Form.Group>
            <Form.Control
              name="customLookup3"
              type="text"
              as="select"
              ref={this.customLookup3Ref}
              disabled={workRequestObjectData.statusCode !== 'CR'}
            >
              {customLookup3List.map((type) => {
                if (workRequestObjectData.customLookupCode3
                  && workRequestObjectData.customLookupCode3.includes(type.value)) {
                  return (
                    <option value={type.value} key={type.value} selected>
                      {' '}
                      {type.label}
                      {' '}
                    </option>
                  );
                }
                return (
                  <option value={type.value} key={type.value}>
                    {' '}
                    {type.label}
                    {' '}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        );
      }
      return null;
    };
    const renderBusinessRules5 = () => {
      const {
        customLookup4List,
      } = this.state;
      if (workRequestObjectData.workType !== 'Advisory Visit Request' && (workRequestObjectData.statusCode === 'CON' || workRequestObjectData.statusCode === 'AVOC' || workRequestObjectData.statusCode === 'CVP' || workRequestObjectData.statusCode === 'CVC' || workRequestObjectData.statusCode === 'GA' || workRequestObjectData.statusCode === 'GC')) {
        return (
          <Col md={6}>
            <Form.Group className="formLabels">
              <Form.Label>{workRequestObjectLabel.customLookup4}</Form.Label>
            </Form.Group>
            <Form.Control
              name="customLookup4"
              type="text"
              as="select"
              ref={this.customLookup4Ref}
              disabled
            >
              {customLookup4List.map((type) => {
                if (workRequestObjectData.customLookupCode4
                  && workRequestObjectData.customLookupCode4.includes(type.value)) {
                  return (
                    <option value={type.value} key={type.value} selected>
                      {' '}
                      {type.label}
                      {' '}
                    </option>
                  );
                }
                return (
                  <option value={type.value} key={type.value}>
                    {' '}
                    {type.label}
                    {' '}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        );
      }
      return null;
    };

    return (
      <Container>
        {renderLoadingOverlay()}
        {renderAlert()}
        <form id="form">
          {renderNewFormHeadings()}
          <hr />
          <Row className="formInputs">
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>Current classification</Form.Label>
              </Form.Group>
              <Form.Control type="text" defaultValue={customerData.customerType} disabled />
            </Col>
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.contactName}</Form.Label>
              </Form.Group>
              <Form.Control type="text" ref={this.contactNameRef} defaultValue={workRequestObjectData.contactName} />
            </Col>
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.contactNumber}</Form.Label>
              </Form.Group>
              <Form.Control type="text" ref={this.contactNumberRef} defaultValue={workRequestObjectData.contactNumber} />
            </Col>
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.contactEmail}</Form.Label>
              </Form.Group>
              <Form.Control type="text" ref={this.contactEmailRef} defaultValue={workRequestObjectData.contactEmail} />
            </Col>
            {renderBusinessRules1()}
            {renderBusinessRules2()}
            {renderBusinessRules3()}
            {renderBusinessRules4()}
            {renderBusinessRules5()}

            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.appointment}</Form.Label>
              </Form.Group>
              <Form.Control type="text" defaultValue={workRequestObjectData.appointment} disabled />
            </Col>

            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.workRequestType}</Form.Label>
              </Form.Group>
              <Form.Control
                name="workRequestType"
                type="text"
                as="select"
                onChange={this.handleWorkTypeChange}
                disabled={!newForm}
              >
                {workRequestTypes.map((type) => {
                  if (workRequestObjectData.workType.includes(type.value)) {
                    return (
                      <option value={type.value} key={type.value} selected>
                        {' '}
                        {type.label}
                        {' '}
                      </option>
                    );
                  }
                  return (
                    <option value={type.value} key={type.value}>
                      {' '}
                      {type.label}
                      {' '}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>

            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>{workRequestObjectLabel.description}</Form.Label>
                <Form.Control disabled as="textarea" rows={3} ref={this.descriptionRef} defaultValue={workRequestObjectData.description} />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Facility Information
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="ficardbody">
                    <Row>
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.code}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.code} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.description}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.description} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customerType}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customerType} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customNumber1}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customNumber1} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customNumber2}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customNumber2} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customNumber3}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customNumber3} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customNumber4}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customNumber4} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.custom10}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.custom10} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customNumber6}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customNumber6} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.custom2}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.custom2} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.joinDate}</Form.Label>
                        </Form.Group>
                        <Form.Control type="datetime" defaultValue={customerData.joinDate} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.customLookup3}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.customLookup3} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.custom6}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.custom6} readOnly />
                      </Col>

                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.custom7}</Form.Label>
                        </Form.Group>
                        <Form.Control type="text" defaultValue={customerData.custom7} readOnly />
                      </Col>

                      <Col md={12}>
                        <Form.Group className="formLabels">
                          <Form.Label>{customerLabels.custom3}</Form.Label>
                        </Form.Group>
                        <Form.Control as="textarea" rows={3} defaultValue={customerData.custom3} readOnly />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Form.Group>

          {renderNotesAndDocuments()}
          <br />
          {renderNewFormButtons()}
        </form>
      </Container>
    );
  }
}
