import React, { useRef, useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Radio } from '@mui/material';
import {
  FieldsContainer,
  Row,
  ApplicationRadio,
} from '../forms.styled';
import FormSection from '../helpers/formsection';
import TextArea from '../helpers/textarea';
import {
  getSearchedPlaces,
} from '../../../../scripts/addressScripts';

const Affidavit = (props) => {
  const { submit, doSubmit } = props;
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [location, setLocation] = useState('');
  const [explanation, setExplanation] = useState('');
  const [sapsNumber, setSapsNumber] = useState('');
  const [value, setValue] = useState('');
  const dateRef = useRef();
  const timeRef = useRef();
  const locationRef = useRef();
  const explanationRef = useRef();
  const sapsNumberRef = useRef();
  const radioOne = useRef();
  const radioTwo = useRef();

  useEffect(() => {
    if (submit) {
      let valid = true;
      if (date === null) {
        dateRef.current.classList.add('invalid-date');
        valid = false;
      }
      if (time === null) {
        timeRef.current.classList.add('invalid-date');
        valid = false;
      }
      if (location === '') {
        locationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
        valid = false;
      }
      if (explanation === '') {
        explanationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
        valid = false;
      }
      if (value === '') {
        radioOne.current.classList.add('invalid-input');
        radioTwo.current.classList.add('invalid-input');
        valid = false;
      }
      if (value === 'stolen') {
        if (sapsNumber === '') {
          sapsNumberRef.current.classList.add('invalid-input');
          valid = false;
        }
      }
      doSubmit(
        date,
        time,
        location,
        explanation,
        value,
        sapsNumber,
        valid,
      );
    }
  }, [submit]);

  const doAsyncSearch = async (searchCode) => {
    if (searchCode && searchCode !== '') {
      const loactionSearchResults = await getSearchedPlaces(
        searchCode, 180,
      ).then((res) => res.results);
      const options = [];
      for (let i = 0; i < loactionSearchResults.length; i++) {
        const option = {
          label: loactionSearchResults[i].address.freeformAddress,
          value: {
            lat: loactionSearchResults[i].position.lat,
            lng: loactionSearchResults[i].position.lon,
          },
        };
        options.push(option);
      }
      setSearchResults(options);
    } else {
      setSearchResults([]);
    }
    setLoadingSearch(false);
  };

  const doSearch = (searchCode) => {
    !loadingSearch && setLoadingSearch(true);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        doAsyncSearch(searchCode);
      }, 300),
    );
  };

  return (
    <FormSection title="Affidavit">
      <Row>
        <div className="expirationdate left-side">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              maxDate={dayjs()}
              value={date}
              ref={dateRef}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  helperText: 'Date',
                },
              }}
              onChange={(newValue) => {
                setDate(newValue);
                if (newValue && newValue !== '' && newValue !== null) {
                  dateRef.current.classList.remove('invalid-date');
                } else {
                  dateRef.current.classList.add('invalid-date');
                }
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="expirationdate">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              value={time}
              ref={timeRef}
              slotProps={{
                textField: {
                  helperText: 'Time',
                },
              }}
              onChange={(newValue) => {
                setTime(newValue);
                if (newValue && newValue !== '' && newValue !== null) {
                  timeRef.current.classList.remove('invalid-date');
                } else {
                  timeRef.current.classList.add('invalid-date');
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </Row>
      <div className="location-search" ref={locationRef} style={{ marginBottom: '16px' }}>
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box"
          options={searchResults}
          onChange={(event, newValue) => {
            if (newValue && newValue.value) {
              locationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
              setLocation(newValue.value);
            } else {
              locationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
              setLocation('');
              setSearchResults([]);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={(event) => { doSearch(event.target.value); }}
            />
          )}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Address where incident occurred
        </p>
      </div>
      <ApplicationRadio>
        <div ref={radioOne} className="typeSelect" style={{ marginBottom: '16px' }}>
          <div className="type">
            <div className="title">
              My card was stolen.
            </div>
          </div>
          <Radio
            checked={value === 'stolen'}
            onChange={(event) => {
              setValue(event.target.value);
              radioOne.current.classList.remove('invalid-input');
              radioTwo.current.classList.remove('invalid-input');
            }}
            value="stolen"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          />
        </div>
        <div ref={radioTwo} className="typeSelect" style={{ marginBottom: '16px' }}>
          <div className="type">
            <div className="title">
              I have lost my card.
            </div>
          </div>
          <Radio
            checked={value === 'lost'}
            onChange={(event) => {
              setValue(event.target.value);
              radioOne.current.classList.remove('invalid-input');
              radioTwo.current.classList.remove('invalid-input');
            }}
            value="lost"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          />
        </div>
      </ApplicationRadio>
      <TextArea
        onChange={(event, size) => {
          if (event.target.value.length <= size) {
            setExplanation(event.target.value);
          }
          if (event.target.value && event.target.value !== '') {
            explanationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
          } else {
            explanationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
          }
        }}
        size={600}
        value={explanation}
        thisRef={explanationRef}
        helperText="Explain what happened"
      />
      {value === 'stolen' && (
      <FieldsContainer>
        <input
          className="nomargin"
          type="text"
          name="sapsNumber"
          placeholder="101/5/1976"
          value={sapsNumber}
          ref={sapsNumberRef}
          onChange={(event) => {
            setSapsNumber(event.target.value);
            if (value === 'stolen') {
              if (event.target.value && event.target.value !== '') {
                sapsNumberRef.current.classList.remove('invalid-input');
              } else {
                sapsNumberRef.current.classList.add('invalid-input');
              }
            }
          }}
        />
        <p
          style={{
            margin: '3px 0px 0px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          SAPS Number
        </p>
      </FieldsContainer>
      )}
    </FormSection>
  );
};

export default Affidavit;
