import { apiUrl, apiBaseUrlFunc } from '../../constants/constantsGlobal';

export const passwordHint = (username) => {
  console.log('Username:', username);
  if (username === '') {
    alert('Username is empty, please fill it in.');
    return;
  }
  fetch(
    `${apiUrl}/usermanager/sendPasswordHint?username=${username}&subscriberId=bto`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    },
  )
    .then((res) => {
      console.log(res);
      alert(`Password hint was sent to ${username}, if it exists`);
    })
    .catch((e) => {
      alert('Error sending the password hint:', e.responseText);
      console.error(e);
    });
};

export const passwordReset = (username) => {
  console.log('Username:', username);
  if (username === '') {
    alert('Username is empty, please fill it in.');
    return;
  }
  fetch(
    `${apiUrl}usermanager/resetPassword?username=${username}&subscriberId=bto`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    },
  )
    .then((res) => {
      console.log(res);
      alert(`New temporary password sent to ${username}, if it exists`);
    })
    .catch((e) => {
      alert('Error resetting the password:', e.responseText);
      console.error(e);
    });
};

export const login = (username, password, setAuth, setStates, history) => {
  // setStates(submitting, loginDone)
  // submitting: true = spinner to indicate login is loading
  // loginDone: true = login button but greyed out
  if (username === '') {
    setStates(false, false);
    alert('Username is empty, please fill it in.');
    return;
  }
  console.log('Username:', username);
  const user = encodeURIComponent(username);
  const pass = encodeURIComponent(password);
  console.log('Username:', user);
  fetch(
    `${apiBaseUrlFunc()}j_spring_security_check?j_subscriberID=bto&ajax=true&j_username=${user}&j_password=${pass}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/javascript, q=0.01',
        'Content-Type': 'application/problem+json',
      },
    },
  )
    .then((res) => {
      console.log('response:', res);
      return res.json();
    })
    .then((resJson) => {
      const {
        success,
        errorMessage,
        roles,
        sessionId,
      } = resJson;
      if (success) {
        const isCustomerRole = roles.some((val) => val === 'ROLE_CUSTOMER');
        if (isCustomerRole) {
          setAuth(sessionId, username);
          setStates(false, true);
          console.log('Navigating to home...');
          history.push('/home');
        } else {
          setStates(false, false);
          alert(
            'Sorry, this user does not have rights to use the customer portal',
          );
        }
      } else {
        setStates(false, false);
        if (errorMessage === 'errors.login.UserDoesNotExistException') { alert(`${username} does not exist as a user`); } else alert(errorMessage);
      }
    })
    .catch((e) => {
      setStates(false, false);
      console.error(e);
      // const errMsg =
      //   e.responseText != null
      //     ? e.responseText
      //     : "unknown error, you are possibly not connected.";
      // alert("Error logging in: " + errMsg);
    });
};
