import {
  addressMapping,
  newProfileMapping,
  userMapping,
} from '../constantsGlobal';

export const syntellProfileMapping = {
  ...newProfileMapping,
  user: { ...userMapping.user, username: 'username' },
  address: { ...addressMapping.address, countryCode: 'ZA' },
  billingAddress: { ...addressMapping.address, countryCode: 'ZA' },
  division: 'customLookupCode3',
  branch: 'customLookupCode4',
};

//  key = entries name on client side
//  value = json name on server side
//  export const createTicketMapping = {
//  division: "customLookupCode3",
//  TODO: need to convert somewhere,
//  right now it's just a number but there needs to be some array somewhere
//  branch: "customLookupCode4", //TODO: need to convert somewhere, right now it's just a label
//  contactNumber: "contactNumber",
//  contactEmail: "contactEmail",
//  ccEmail: "contactEmailCC",
//  problemDescription: "description",
//  impact: "customLookupCode1",
//  urgency: "customLookupCode2",
//  category: "workType",
//  subcategory: "workType",
//  ...newProfileMapping,
// };

const ticketMapping = {
  ...addressMapping,
  contactNumber: 'contactNumber',
  contactEmail: 'contactEmail',
  division: 'customLookupCode3',
  branch: 'customLookupCode4',
  impact: 'customLookupCode1',
  urgency: 'customLookupCode2',
  category: 'workType',
  subcategory: 'childWorkType',
  createdDate: 'createdDate',
};

// from getProfile
export const createTicketMapping = {
  ...ticketMapping,
  customerCode: 'code',
  customerName: 'description',
};

// from getWorkRequest
export const editTicketMapping = {
  ...ticketMapping,
  customerCode: 'customerCode',
  customerName: 'customerDescription',
  id: 'id',
  problemDescription: 'description',
  resourceFeedback: 'resourceFeedback',
};

// to postWorkRequest
export const submitEditTicketMapping = {
  ...ticketMapping,
  customerCode: 'code',
  customerName: 'description',
  contactNumber: 'contactNumber',
  contactEmail: 'contactEmail',
  problemDescription: 'description',
  newStatus: 'statusCode',
};

export const ticketsTableColumns = {
  jsonKeys: [
    'code',
    'description',
    'typeCode',
    'statusCode',
    'open',
    'discipline',
    'dateCreated',
    'appointment',
  ],
  headers: [
    'code',
    'description',
    'type',
    'status',
    'open',
    'discipline',
    'dateCreated',
    'appointment',
  ],
  headerNames: [
    'Code',
    'Problem Description',
    'Type',
    'Status',
    'Open?',
    'Discipline',
    'Date Created',
    'Appointment',
  ],
  widths: [100, 260, 130, 130, 130, 150, 157, 157],
};

// TEST DATA

export const testTicketsTable = [
  {
    id: 185,
    code: 'ITS24629',
    description:
      'accpac passwordpasswordpasswordpasswordpasswordpasswordpasswordpasswordpasswordpasswordpasswordpasswordpasswordpassword',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '04/06/2020, 11:12',
    appointment: null,
  },
  {
    id: 184,
    code: 'ITS24628',
    description: 'online User forms',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '04/06/2020, 11:11',
    appointment: null,
  },
  {
    id: 183,
    code: 'ITS24627',
    description: 'add new user ',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '06/04/2020, 11:09',
    appointment: null,
  },
  {
    id: 182,
    code: 'ITS24626',
    description: 'Priority',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '06/03/2020, 12:20',
    appointment: null,
  },
  {
    id: 181,
    code: 'ITS24625',
    description: '&nbsp;Easy Roster',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '05/14/2020, 13:29',
    appointment: null,
  },
  {
    id: 180,
    code: 'ITT24624',
    description: '&nbsp;Audit Microstrategy Change',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '05/14/2020, 13:28',
    appointment: null,
  },
  {
    id: 179,
    code: 'ITS24623',
    description: 'no internet',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '03/13/2020, 13:56',
    appointment: null,
  },
  {
    id: 178,
    code: 'ITT24622',
    description: 'test p3 response-office hours',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '03/09/2020, 16:01',
    appointment: null,
  },
  {
    id: 177,
    code: 'ITT24621',
    description: 'ALL response put it on hold',
    workRequestType: 'some type',
    workRequestStatus: 'On Hold ',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '03/09/2020, 12:43',
    appointment: null,
  },
  {
    id: 176,
    code: 'ITT24620',
    description: 'P3,p4  fix',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '03/09/2020, 12:42',
    appointment: null,
  },
  {
    id: 175,
    code: 'ITT24619',
    description: 'test Response',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '03/06/2020, 12:50',
    appointment: null,
  },
  {
    id: 174,
    code: 'ITS24618',
    description: 'Test P1  and p2 fix',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '03/06/2020, 11:39',
    appointment: null,
  },
  {
    id: 173,
    code: 'ITT24617',
    description: 'TEST Response sla',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '03/06/2020, 11:23',
    appointment: null,
  },
  {
    id: 172,
    code: 'ITS24616',
    description: 'testing',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '03/05/2020, 15:50',
    appointment: null,
  },
  {
    id: 171,
    code: 'ITS24615',
    description: 'test',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Systems Support',
    dateCreated: '02/04/2020, 14:37',
    appointment: null,
  },
  {
    id: 170,
    code: 'ITT24614',
    description: 'tester',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Technical Support',
    dateCreated: '02/04/2020, 14:34',
    appointment: null,
  },
  {
    id: 167,
    code: 'ITS24611',
    description: 'monitor SLA event change of colors',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Systems Support',
    dateCreated: '01/23/2020, 11:57',
    appointment: null,
  },
  {
    id: 166,
    code: 'ITT24610',
    description: 'New SLA colors',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/23/2020, 09:33',
    appointment: null,
  },
  {
    id: 165,
    code: 'ITT24609',
    description: 'tes sla colour',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/22/2020, 15:15',
    appointment: null,
  },
  {
    id: 163,
    code: 'ITS24607',
    description: 'sla test',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Systems Support',
    dateCreated: '01/22/2020, 12:10',
    appointment: null,
  },
  {
    id: 162,
    code: 'ITT24606',
    description: 'test',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/22/2020, 10:47',
    appointment: null,
  },
  {
    id: 161,
    code: 'ITT24605',
    description: '&nbsp;Other',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/21/2020, 15:21',
    appointment: null,
  },
  {
    id: 160,
    code: 'ITS24604',
    description: 'testing sla',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '01/21/2020, 14:45',
    appointment: null,
  },
  {
    id: 159,
    code: 'ITT24603',
    description: 'No internet',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/21/2020, 13:19',
    appointment: null,
  },
  {
    id: 157,
    code: 'ITT24601',
    description: 'New SLA test',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/20/2020, 15:10',
    appointment: null,
  },
  {
    id: 156,
    code: 'ITS24600',
    description: 'hi there.......',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Systems Support',
    dateCreated: '01/20/2020, 08:47',
    appointment: null,
  },
  {
    id: 155,
    code: 'ITS24599',
    description: 'test sla2',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '01/17/2020, 15:07',
    appointment: null,
  },
  {
    id: 154,
    code: 'ITT24598',
    description: 'sla test',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/17/2020, 15:01',
    appointment: null,
  },
  {
    id: 153,
    code: 'ITT24597',
    description: 'test',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/17/2020, 14:56',
    appointment: null,
  },
  {
    id: 152,
    code: 'ITT24596',
    description: 'Hi, printer is not working',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/16/2020, 13:55',
    appointment: null,
  },
  {
    id: 150,
    code: 'ITT24594',
    description: ' No internet',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/16/2020, 11:41',
    appointment: null,
  },
  {
    id: 149,
    code: 'ITT24593',
    description: 'work SLA',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/16/2020, 11:11',
    appointment: null,
  },
  {
    id: 148,
    code: 'ITT24592',
    description: 'test',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/16/2020, 10:59',
    appointment: null,
  },
  {
    id: 147,
    code: 'ITT24591',
    description: 'hi there ',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/16/2020, 10:35',
    appointment: null,
  },
  {
    id: 146,
    code: 'ITT24590',
    description: 'Test SLA notification',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/16/2020, 10:05',
    appointment: null,
  },
  {
    id: 145,
    code: 'ITT24589',
    description: 'Testing SLA',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/15/2020, 15:42',
    appointment: null,
  },
  {
    id: 144,
    code: 'ITT24588',
    description: 'testing',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/15/2020, 12:35',
    appointment: null,
  },
  {
    id: 143,
    code: 'ITT24587',
    description: 'Technical - Hardware',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/15/2020, 12:11',
    appointment: null,
  },
  {
    id: 142,
    code: 'ITT24586',
    description: '&nbsp;Laptop Battery not charging',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '01/15/2020, 11:54',
    appointment: null,
  },
  {
    id: 141,
    code: 'ITM24585',
    description: '&nbsp;Bluehook',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '01/14/2020, 15:26',
    appointment: null,
  },
  {
    id: 140,
    code: 'ITM24584',
    description: 'Testing new notification',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '12/20/2019, 12:54',
    appointment: null,
  },
  {
    id: 139,
    code: 'ITT24583',
    description: 'Ticket dispenser not working. ',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '11/29/2019, 11:09',
    appointment: null,
  },
  {
    id: 138,
    code: 'ITM24582',
    description: 'Microstrategy- audit',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '11/22/2019, 12:11',
    appointment: null,
  },
  {
    id: 135,
    code: 'ITML24579',
    description:
      'Subject:testin\r\n'
      + '\r\n'
      + 'Hi there ,\r\n'
      + '\r\n'
      + 'New forms\r\n'
      + '\r\n'
      + '---\r\n'
      + 'This email has been checked for viruses by AVG.\r\n'
      + 'https://www.avg.com\r\n',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '11/07/2019, 10:23',
    appointment: null,
  },
  {
    id: 134,
    code: 'ITM24578',
    description: '&nbsp;Audit Microstrategy Change',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '11/07/2019, 08:45',
    appointment: null,
  },
  {
    id: 133,
    code: 'ITM24577',
    description: 'aaaaaaaaaaaa',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/31/2019, 14:09',
    appointment: null,
  },
  {
    id: 128,
    code: 'ITS24572',
    description: 'Created From Email',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/29/2019, 13:08',
    appointment: '04/11/2019, 06:07',
  },
  {
    id: 126,
    code: 'ITS24570',
    description: 'Test Save and Reload',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/29/2019, 09:28',
    appointment: null,
  },
  {
    id: 125,
    code: 'ITS24569',
    description: 'testing',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/29/2019, 09:25',
    appointment: null,
  },
  {
    id: 124,
    code: 'ITM24568',
    description: 'test 3',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/25/2019, 13:46',
    appointment: null,
  },
  {
    id: 123,
    code: 'ITT24567',
    description: 'TEST OTHER 2',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/25/2019, 08:15',
    appointment: null,
  },
  {
    id: 122,
    code: 'ITM24566',
    description: 'test OTHER WORK TYPE',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/25/2019, 08:13',
    appointment: null,
  },
  {
    id: 121,
    code: 'ITM24565',
    description: 'No internet',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/23/2019, 08:35',
    appointment: null,
  },
  {
    id: 120,
    code: 'ITM24564',
    description: 'testing',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/22/2019, 14:23',
    appointment: null,
  },
  {
    id: 119,
    code: 'ITT24563',
    description: 'Charger Not working',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/22/2019, 13:51',
    appointment: null,
  },
  {
    id: 118,
    code: 'ITS24562',
    description: 'BSOD',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/11/2019, 10:41',
    appointment: null,
  },
  {
    id: 101830616085260,
    code: 'ITT24561',
    description: 'assign to other',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/09/2019, 12:16',
    appointment: '09/10/2019, 12:14',
  },
  {
    id: 101830615953709,
    code: 'ITS24560',
    description: 'assign to myself 1',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/09/2019, 12:13',
    appointment: '10/09/2019, 12:12',
  },
  {
    id: 101830612540862,
    code: 'ITS24559',
    description: 'no internet',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/09/2019, 11:16',
    appointment: '10/09/2019, 11:15',
  },
  {
    id: 101690612294245,
    code: 'ITT24558',
    description: 'software  expired',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/09/2019, 11:12',
    appointment: '10/09/2019, 11:11',
  },
  {
    id: 101690612192635,
    code: 'ITS24557',
    description: 'System down',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/09/2019, 11:11',
    appointment: '10/09/2019, 11:09',
  },
  {
    id: 117,
    code: 'ITM24556',
    description:
      'Testing Auto allocation if no resources are available in a Discipline',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/09/2019, 09:35',
    appointment: null,
  },
  {
    id: 116,
    code: 'ITS24555',
    description: 'Testing Auto Allocation with Ken',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/09/2019, 09:31',
    appointment: null,
  },
  {
    id: 115,
    code: 'ITT24554',
    description: 'asdf',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 16:32',
    appointment: null,
  },
  {
    id: 101830544909048,
    code: 'ITS24553',
    description: 'test off the phone if allocated to other resource ',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/08/2019, 16:29',
    appointment: '10/08/2019, 16:28',
  },
  {
    id: 114,
    code: 'ITT24552',
    description: 'TT notify',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 16:21',
    appointment: null,
  },
  {
    id: 113,
    code: 'ITM24551',
    description: 'Notify',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/08/2019, 15:51',
    appointment: null,
  },
  {
    id: 101830540943830,
    code: 'ITT24550',
    description: 'test assign to myself',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 15:23',
    appointment: '10/08/2019, 15:22',
  },
  {
    id: 101830540873561,
    code: 'ITT24549',
    description: 'test assign to other resource ',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 15:22',
    appointment: '10/09/2019, 15:21',
  },
  {
    id: 101830540498147,
    code: 'ITT24548',
    description: 'no internet',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 15:15',
    appointment: '10/08/2019, 15:14',
  },
  {
    id: 101830540292143,
    code: 'ITS24547',
    description: 'system error ',
    workRequestType: 'some type',
    workRequestStatus: 'Created From Field',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/08/2019, 15:12',
    appointment: '10/08/2019, 15:11',
  },
  {
    id: 112,
    code: 'ITS24546',
    description: 'Testing notification',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/08/2019, 15:11',
    appointment: null,
  },
  {
    id: 101830537610531,
    code: 'ITT24545',
    description: 'printer',
    workRequestType: 'some type',
    workRequestStatus: 'Created From Field',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 14:27',
    appointment: '10/08/2019, 14:26',
  },
  {
    id: 101830537097375,
    code: 'ITT24544',
    description: 'Internet too slow 1',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 14:18',
    appointment: '10/08/2019, 14:18',
  },
  {
    id: 111,
    code: 'ITM24543',
    description: 'Printer off',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/08/2019, 11:56',
    appointment: null,
  },
  {
    id: 101830519485320,
    code: 'ITT24542',
    description: 'no internet',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/08/2019, 09:27',
    appointment: '10/08/2019, 09:24',
  },
  {
    id: 110,
    code: 'ITM24541',
    description: 'test',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Mobile Support',
    dateCreated: '10/07/2019, 16:08',
    appointment: null,
  },
  {
    id: 109,
    code: 'ITS24540',
    description: 'problem on concept',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '10/07/2019, 15:32',
    appointment: null,
  },
  {
    id: 101830453157961,
    code: 'ITT24539',
    description: 'test2',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/07/2019, 14:59',
    appointment: '10/07/2019, 14:59',
  },
  {
    id: 101690451622741,
    code: 'ITT24538',
    description: 'test1',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/07/2019, 14:34',
    appointment: '10/07/2019, 14:33',
  },
  {
    id: 108,
    code: 'ITT24537',
    description: 'Printer',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/07/2019, 13:50',
    appointment: null,
  },
  {
    id: 107,
    code: 'ITT24536',
    description: 'Printer out of ink',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/07/2019, 11:46',
    appointment: null,
  },
  {
    id: 101790441165513,
    code: 'ITT24535',
    description: 'test_ticket id',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/07/2019, 11:39',
    appointment: '10/07/2019, 11:39',
  },
  {
    id: 101830437201467,
    code: 'ITT24534',
    description: 'no internet',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '10/07/2019, 10:37',
    appointment: '10/07/2019, 10:33',
  },
  {
    id: 106,
    code: 'ITT24533',
    description: 'test email',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/30/2019, 16:33',
    appointment: null,
  },
  {
    id: 105,
    code: 'ITT24532',
    description: 'Printer not working',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Technical Support',
    dateCreated: '09/30/2019, 16:23',
    appointment: null,
  },
  {
    id: 101829828368875,
    code: 'ITT24531',
    description: 'no Internet',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/30/2019, 09:26',
    appointment: '09/30/2019, 09:26',
  },
  {
    id: 104,
    code: 'ITS24530',
    description: 'Problem description\r\n',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Systems Support',
    dateCreated: '09/27/2019, 13:58',
    appointment: null,
  },
  {
    id: 101689582560977,
    code: 'ITT24529',
    description: 'No internet in the office',
    workRequestType: 'some type',
    workRequestStatus: 'Work in Progress',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/27/2019, 13:09',
    appointment: '09/27/2019, 13:09',
  },
  {
    id: 101689582307885,
    code: 'ITT24528',
    description: 'no Internet\n',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Technical Support',
    dateCreated: '09/27/2019, 13:06',
    appointment: '09/27/2019, 13:05',
  },
  {
    id: 103,
    code: 'ITS24527',
    description: 'auto allocation',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '09/27/2019, 12:13',
    appointment: null,
  },
  {
    id: 102,
    code: 'ITT24526',
    description: "Laptop can't reboot",
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 17:39',
    appointment: null,
  },
  {
    id: 101,
    code: 'ITT24525',
    description: 'testing 101',
    workRequestType: 'some type',
    workRequestStatus: 'Cancelled',
    open: false,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 14:35',
    appointment: null,
  },
  {
    id: 100,
    code: 'ITT24524',
    description: "Laptop can't reboot",
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 11:52',
    appointment: null,
  },
  {
    id: 99,
    code: 'ITT24523',
    description: "Laptop can't reboot",
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 11:51',
    appointment: null,
  },
  {
    id: 98,
    code: 'ITT24522',
    description: "Laptop can't reboot",
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 11:51',
    appointment: null,
  },
  {
    id: 97,
    code: 'ITT24521',
    description: ' need new Microsoft office',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 11:50',
    appointment: null,
  },
  {
    id: 96,
    code: 'ITT24520',
    description: "Laptop can't reboot",
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Technical Support',
    dateCreated: '09/26/2019, 11:49',
    appointment: null,
  },
  {
    id: 95,
    code: 'ITT24519',
    description: 'Test logged via mobile',
    workRequestType: 'some type',
    workRequestStatus: 'Completed',
    open: false,
    discipline: 'Technical Support',
    dateCreated: '09/25/2019, 14:18',
    appointment: null,
  },
  {
    id: 94,
    code: 'ITT24518',
    description: 'Code Change',
    workRequestType: 'some type',
    workRequestStatus: 'Assigned',
    open: true,
    discipline: 'Systems Support',
    dateCreated: '09/23/2019, 11:01',
    appointment: null,
  },
];

export const testProfile = {
  id: 2214,
  code: 'TESTCUST',
  description: 'Test Customer',
  contactPerson: 'Test Customer Contact',
  mobileNumber: '0823300894',
  officeNumber: '0114678528',
  homeNumber: '309',
  faxNumber: '',
  emailAddress: 'sarthurjones@gmail.com',
  standardDiscount: 0.0,
  paymentTermsDays: 30,

  taxRegNumber: '',
  joinDate: 1567168740000,

  statusCode: 'AC',
  typeCode: 'IE',
  active: true,
  address: {
    description: 'dfffffffffffffffff',

    address3: '88 Studio Park',
    address4: '5',
    address5: 'Concourse Crescent',
    address6: 'Lonehill',
    address7: 'Sandton',
    address8: '2055',
    countryCode: 'ZA',
    latitude: -26.0383813,
    longitude: 28.0044064,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,

    geomArea: 0.0,
  },

  user: {
    username: 'testcust',
    password: 'fcc299b84f021e264c309dce049ed05f4df65072',
    fullName: 'Test Customer Contact',
    passwordHint: 'User Name in Small letters',
    otp: '',

    employeeNumber: '',

    email: 'sarthurjones@gmail.com',
    phoneNumber: '0114678528',
    mobileNumber: '0823300894',
  },
  alertFlags: [],
  optOutSMSCategories: [],
  custom1: '',
  custom2: '',

  customNumber1: 0.0,
  customNumber2: 0.0,
  customNumber3: 0.0,
  customNumber4: 0.0,
  customNumber5: 0.0,
  customNumber6: 0.0,
  customNumber7: 0.0,
  customNumber8: 0.0,
  customNumber9: 0.0,
  customNumber10: 0.0,

  customLookupCode3: '4',
  customLookupCode4: 'GPNS',

  customMultipleChoice1: [],
  customMultipleChoice2: [],
  customMultipleChoice3: [],
  customMultipleChoice4: [],
  customMultipleChoice5: [],

  assetTypeCode: 'IE',
  customerType: 'IE',
  billingAddress: {
    description: 'dfffffffffffffffff',

    address3: '88 Studio Park',
    address4: '5',
    address5: 'Concourse Crescent',
    address6: 'Lonehill',
    address7: 'Sandton',
    address8: '2055',
    countryCode: 'ZA',
    latitude: -26.0383813,
    longitude: 28.0044064,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,

    geomArea: 0.0,
  },
  customerStatus: 'AC',
};

export const testWorkRequest = {
  id: 1,
  code: 'CLIENT-24436',
  description: 'test',
  typeCode: 'MD',
  childWorkType: null,
  statusCode: 'CMP',
  statusDescription: 'Completed',
  appointment: 1568887412000,
  priorityCode: 'P3',
  orgUnitCode: 'MS',
  customerCode: 'CT0146C',
  custom1: null,
  custom2: '',
  custom3: null,
  custom4: null,
  custom5: null,
  custom6: null,
  custom7: null,
  custom8: null,
  custom9: null,
  custom10: null,
  custom11: null,
  custom12: null,
  custom13: null,
  custom14: null,
  custom15: null,
  customNumber1: 0.0,
  customNumber2: 0.0,
  customNumber3: 0.0,
  customNumber4: 0.0,
  customNumber5: 0.0,
  customLookupCode1: 'AF1',
  customLookupCode2: 'U1',
  customLookupCode3: '11',
  customLookupCode4: 'GPE',
  customLookupCode5: null,
  address: {
    description: 'Acumen Software',
    address1: 'P.O.Box 98495, Sloane Park',
    address2: '',
    address3: '88 Studio Park',
    address4: '5',
    address5: 'Concourse Crescent',
    address6: 'Sandton',
    address7: 'Johannesburg',
    address8: '2152',
    countryCode: 'ZA',
    latitude: -26.018040961903,
    longitude: 28.030320742901,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    routePoints: null,
    geomArea: 0.0,
  },
  createdDate: 1566900356000,
  customDate1: null,
  customDate2: null,
  customDate3: null,
  customDate4: null,
  customDate5: null,
  customDate6: null,
  customDate7: null,
  customDate8: null,
  customDate9: null,
  customDate10: null,
  plannedStartDate: null,
  durationHours: null,
  systemStatusId: 4,
  assetCodes: [],
  assetCodesToRemove: [],
  resolutionCode: null,
  contactName: 'KGADI',
  contactNumber: '',
  contactEmail: '',
  parentWorkOrderCode: 'DEV2721',
  resourceFeedback: null,
  documents: [],
  images: [],
  notes: [
    {
      noteType: 'FFF',
      text: 'Tested and working',
      timeStamp: 1568887598000,
      userName: 'hoosenm@syntell.co.za',
      guid: 'c9153929-5cc1-4939-bdf6-441972e46691',
      active: true,
    },
  ],
  docType: 'WorkOrderRequest',
  billOfMaterialsGroup: null,
  workRequestCodes: [],
  allocatedToText: null,
  inspectionListGroup: null,
  workType: 'MD',
  systemStatus: 'COMPLETED',
};

export const testTicketCategories = [
  {
    label: 'Mobile - Application',
    value: '26112',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Mobile - Forms',
    value: '26116',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Mobile - Hardware',
    value: '26114',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Systems -  BI',
    value: '26108',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Systems -  Business Applications',
    value: '26104',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Systems -  Reports',
    value: '26106',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Systems - Forms',
    value: '26110',
    attributes: { hasChildren: 'false', canSelect: 'true' },
  },
  {
    label: 'Technical - Forms',
    value: '26098',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Technical - Hardware',
    value: '26094',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Technical - Network',
    value: '26102',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: 'Technical - Software',
    value: '26096',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
];

export const testTicketSubcategories = [
  {
    label: 'Mobile - Application',
    value: '26112',
    attributes: { hasChildren: 'true', canSelect: 'true' },
  },
  {
    label: '&nbsp;Bluehook',
    value: '26408',
    attributes: { hasChildren: 'false', canSelect: 'true' },
  },
  {
    label: '&nbsp;Microstrategy Audit',
    value: '26410',
    attributes: { hasChildren: 'false', canSelect: 'true' },
  },
  {
    label: '&nbsp;Microstrategy Survey',
    value: '26412',
    attributes: { hasChildren: 'false', canSelect: 'true' },
  },
  {
    label: '&nbsp;Others',
    value: '26610',
    attributes: { hasChildren: 'false', canSelect: 'true' },
  },
];
