import React from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../functions/enpower/context';
import { LoginAlert } from '../../components/enpower/header';
import * as login from '../../scripts/loginScripts';
import { LoginForm } from '../../components/login';

export class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'enpower_gsdm';
  }

  onLogin = (state, setStates) => {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    const { username, password } = state;
    login.doLogin(username, password, setAuth, subscriberId, setStates);
  };

  render() {
    const { onLogin, subscriberId } = this;
    const subscriber = 'Enpower';
    const withRegister = false;
    return (
      <Container fluid id="LoginForm">
        <LoginAlert />
        <LoginForm {...{
          subscriber,
          onLogin,
          subscriberId,
          withRegister,
        }}
        />
      </Container>
    );
  }
}
