import { apiUrl } from '../../constants/constantsGlobal';

export const getCustomerSurvey = async (id) => fetch(
  `https://${apiUrl}custommanager_stats_sa/getCustomerSurvey?${id}&subscriberId=stats_sa`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
  });

export const getWorkOrderCode = async (id) => fetch(
  `https://${apiUrl}custommanager_stats_sa/getWorkOrderCode?${id}&subscriberId=stats_sa`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
    },
  },
)
  .then((res) => {
    if (res.status === 200) {
      return res.text();
    }
    return null;
  })
  .catch((e) => {
    console.log(e);
  });

export const updateSurveyInspectionList = async (jsonBody, id) => fetch(
  `https://${apiUrl}custommanager_stats_sa/updateSurveyInspectionList?${id}&subscriberId=stats_sa`,
  {
    method: 'POST',
    async: true,
    body: JSON.stringify(jsonBody),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json; q=0.01',
    },
  },
)
  .then((res) => res.json()).catch((e) => {
    console.log(e);
  });
