import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Table } from '../../components/Table';
import { Context } from '../../functions/siemens/context';

/**
 * KPI Table Page displays a table
 */
export const KPITablePage = () => {
  const {
    id, tableModelClass, columnView,
  } = useLocation().state;

  useEffect(() => {
    document.title = 'KPI | Siemens Portal';
  }, []);

  return (
    <Table
      Context={Context}
      title={`${tableModelClass} KPI`}
      tableModelClass={tableModelClass}
      id={id}
      columnView={columnView}
    />
  );
};
