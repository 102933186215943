import React from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Context } from './functions/myciti/context';
import Home from './pages/myciti/home';
import LostOrStolen from './pages/myciti/lostorstolen';
import Refund from './pages/myciti/refund';
import Slip from './pages/myciti/slip';

export class MyCitiApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'MyCiTi Portal';
    document.getElementById('favicon').href = '/favicon/favicon.ico';
    const { openSnackbar } = this.state;
    const {
      cookies: { cookies },
    } = this.context;

    const pagesPaths = [
      ['/home', <Home />],
      ['/lostorstolen', <LostOrStolen />],
      ['/refund', <Refund />],
      ['/slip', <Slip />],
    ];

    return (
      <MyCitiWrapper>
        <div id="App" style={{ margin: '0px' }}>
          <Container fluid id="AppContainer" style={{ padding: '0px' }}>
            <Switch>
              <Route
                exact
                path="/"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
              {pagesPaths.map(([path, page], i) => (
                <Route path={path} key={i}>
                  {page}
                </Route>
              ))}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </div>
        {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
            >
              <Alert
                onClose={this.snackbarClose}
                severity="info"
                elevation={6}
                variant="filled"
              >
                Logged in with username
                {' '}
                <b>{cookies.username}</b>
              </Alert>
            </Snackbar>
        )}
      </MyCitiWrapper>
    );
  }
}

const MyCitiWrapper = (props) => {
  const {
    children,
  } = props;
  const loc = useLocation();
  let className;
  if (loc.pathname === '/login') className = 'withBackgroundImg';
  else className = 'withBackgroundColor';

  return (
    <div id="MyCitiApp" className={className}>
      {children}
    </div>
  );
};

const NoMatch = () => {
  const location = useLocation();
  document.title = '404 Not Found | MyCiTi';
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
