import React from 'react';
import { Container } from 'react-bootstrap';

export const StatsSaHeader = () => {
  const title = '';
  const imgSrc = 'statssa/dipfm_logo.png';
  const titleStyle = { color: 'white' };

  return (
    <Container fluid id="headerContainer">
      <img src={imgSrc} className="App-logo" alt={`${title} Logo`} />
      <p id="headerTitle" style={titleStyle}>
        {title}
      </p>
    </Container>
  );
};
