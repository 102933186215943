import React from 'react';
import {
  Form,
  Row,
  Col,
  Card,
  Container,
  Accordion,
} from 'react-bootstrap';
import {
  Redirect,
} from 'react-router-dom';
import lottie from 'lottie-web';
import { Context } from '../../functions/botswanatourism/context';
import '../../styles/botswanatourism/home.scss';
import {
  getLabels,
  getPortalCustomerDetails,
  byClassAndProp,
} from '../../scripts/botswanatourism/scripts';

export class FacilitiesInformation extends React.Component {
  static contextType = Context;

  animate = null;

  constructor(props) {
    super(props);
    this.state = {
      readyToRedirect: false,
      customMultipleChoice1Array: [],
      customerLabels: {
        // Form -> Facility Information
        code: '',
        description: '',
        customerType: '',
        customNumber1: '',
        customNumber2: '',
        customNumber3: '',
        customNumber4: '',
        custom10: '',
        customNumber6: '',
        custom2: '',
        joinDate: '',
        customLookup3: '',
        custom6: '',
        custom7: '',
        custom3: '',
        // Form -> Contact Information
        contactPerson: '',
        officeNumber: '',
        mobileNumber: '',
        faxNumber: '',
        emailAddress: '',
        custom4: '',
        custom5: '',
        custom8: '',
        custom9: '',

        // Form -> Facility Status
        customerStatus: '',
        customLookup1: '',
        customDate2: '',
        customLookup2: '',
        customDate3: '',

        // Form -> Facility Services
        customMultipleChoice1: '',

        // Form -> Address Info
        billingAddress: {
          id: '',
          address3: '',
          address4: '',
          address5: '',
          address6: '',
          address7: '',
          address8: '',
          province: '',
          formattedLatLong: '',
        },
      },

      customerData: {
        // Form -> Facility Information
        code: '',
        description: '',
        customerType: '',
        customNumber1: '',
        customNumber2: '',
        customNumber3: '',
        customNumber4: '',
        custom10: '',
        customNumber6: '',
        custom2: '',
        joinDate: '',
        customLookup3: '',
        custom6: '',
        custom7: '',
        custom3: '',
        // Form -> Contact Information
        contactPerson: '',
        officeNumber: '',
        mobileNumber: '',
        faxNumber: '',
        emailAddress: '',
        custom4: '',
        custom5: '',
        custom8: '',
        custom9: '',

        // Form -> Facility Status
        customerStatus: '',
        customLookup1: '',
        customDate2: '',
        customLookup2: '',
        customDate3: '',

        // Form -> Facility Services
        customMultipleChoice1: '',

        // Form -> Address Info
        billingAddress: {
          id: '',
          address3: '',
          address4: '',
          address5: '',
          address6: '',
          address7: '',
          address8: '',
          province: '',
          formattedLatLong: '',
        },
      },

      // Whats needed in the form
      redirectTo: '',
      loading: true,
    };
  }

  componentDidMount = async () => {
    const {
      customMultipleChoice1Array,
    } = this.state;

    // Loading
    lottie.loadAnimation({
      container: this.animate,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'botswanatourism/paw.json',
    });

    // Need this to get the field names
    const labels = await getLabels();
    const custdata = await getPortalCustomerDetails();
    console.log(labels);
    console.log(custdata);
    const selectValues = {
      customerType: '', customLookup3: '', 'billingAddress.province': '', customerStatus: '', customLookup1: '',
    };
    Object.keys(selectValues).forEach(async (y) => {
      console.log(y);
      console.log(custdata[y]);
      const value = await byClassAndProp('Customer', y, custdata.code);
      Object.keys(value).forEach(async (x) => {
        if (value[x].value === custdata[y]) {
          console.log('found:', custdata[y], value[x].label);
          selectValues[y] = value[x].label;
        }
      });
      console.log('___');
    });
    console.log(selectValues);

    const test = await byClassAndProp('Customer', 'customMultipleChoice1', custdata.code);
    this.setState({ loading: false });
    this.setState({ customMultipleChoice1Array: test });
    console.log(customMultipleChoice1Array);
    // customerData.customerType
    // customerData.customLookup3
    // customerData.billingAddress.province
    // customerData.customerStatus
    // customerData.customLookup1
    if (labels && custdata) {
      this.setState({
        customerLabels: {
          code: labels['Customer.code'],
          description: labels['Customer.description'],
          customerType: labels['Customer.customerType'],
          customNumber1: labels['Customer.customNumber1'],
          customNumber2: labels['Customer.customNumber2'],
          customNumber3: labels['Customer.customNumber3'],
          customNumber4: labels['Customer.customNumber4'],
          custom10: labels['Customer.custom10'],
          customNumber6: labels['Customer.customNumber6'],
          custom2: labels['Customer.custom2'],
          joinDate: labels['Customer.joinDate'],
          customLookup3: labels['Customer.customLookup3'],
          custom6: labels['Customer.custom6'],
          custom7: labels['Customer.custom7'],
          custom3: labels['Customer.custom3'],
          // Form -> Contact Information
          contactPerson: labels['Customer.contactPerson'],
          officeNumber: labels['Customer.officeNumber'],
          mobileNumber: labels['Customer.mobileNumber'],
          faxNumber: labels['Customer.faxNumber'],
          emailAddress: labels['Customer.emailAddress'],
          custom4: labels['Customer.custom4'],
          custom5: labels['Customer.custom5'],
          custom8: labels['Customer.custom8'],
          custom9: labels['Customer.custom9'],
          // Form -> Facility Status
          customerStatus: labels['Customer.customerStatus'],
          customLookup1: labels['Customer.customLookup1'],
          customDate2: labels['Customer.customDate2'],
          customLookup2: labels['Customer.customLookup2'],
          customDate3: labels['Customer.customDate3'],
          // Form -> Facility Services
          customMultipleChoice1: labels['Customer.customMultipleChoice1'],
          billingAddress: {
            id: labels['billingAddress.id'],
            address3: labels['WorkOrderRequest.customer.billingAddress.address3'],
            address4: labels['WorkOrderRequest.customer.billingAddress.address4'],
            address5: labels['WorkOrderRequest.customer.billingAddress.address5'],
            address6: labels['WorkOrderRequest.customer.billingAddress.address6'],
            address7: labels['WorkOrderRequest.customer.billingAddress.address7'],
            address8: labels['WorkOrderRequest.customer.billingAddress.address8'],
            province: labels['WorkOrderRequest.customer.billingAddress.province'],
            formattedLatLong: labels['WorkOrderRequest.customer.billingAddress.formattedLatLong'],
          },
        },
        customerData: {
          code: custdata.code,
          description: custdata.description,
          customerType: selectValues.customerType,
          customNumber1: custdata.customNumber1,
          customNumber2: custdata.customNumber2,
          customNumber3: custdata.customNumber3,
          customNumber4: custdata.customNumber4,
          custom10: custdata.custom10,
          customNumber6: custdata.customNumber6,
          custom2: custdata.custom2,
          joinDate: custdata.joinDate,
          customLookup3: selectValues.customLookup3,
          custom6: custdata.custom6,
          custom7: custdata.custom7,
          custom3: custdata.custom3,
          // Form -> Contact Information
          contactPerson: custdata.contactPerson,
          officeNumber: custdata.officeNumber,
          mobileNumber: custdata.mobileNumber,
          faxNumber: custdata.faxNumber,
          emailAddress: custdata.emailAddress,
          custom4: custdata.custom4,
          custom5: custdata.custom5,
          custom8: custdata.custom8,
          custom9: custdata.custom9,
          // Form -> Facility Status
          customerStatus: selectValues.customerStatus,
          customLookup1: selectValues.customLookup1,
          customDate2: custdata.customDate2,
          customLookup2: custdata.customLookup2,
          customDate3: custdata.customDate3,
          // Form -> Facility Services
          customMultipleChoice1: custdata.customMultipleChoice1,
          billingAddress: {
            id: custdata.billingAddress.code,
            address3: custdata.billingAddress.address3,
            address4: custdata.billingAddress.address4,
            address5: custdata.billingAddress.address5,
            address6: custdata.billingAddress.address6,
            address7: custdata.billingAddress.address7,
            address8: custdata.billingAddress.address8,
            province: selectValues['billingAddress.province'],
            formattedLatLong: custdata.billingAddress.formattedLatLong,
          },
        },
      });
    }
  }

  render() {
    const {
      readyToRedirect,
      redirectTo,
      loading,
      customerData,
      customerLabels,
      customMultipleChoice1Array,
      workRequestType,
    } = this.state;
    if (readyToRedirect && redirectTo !== '') {
      return <Redirect to={redirectTo} />;
    }

    if (loading) {
      return (
        <div style={{ height: '50vh' }}>
          <div id="LoadingOverlayContainer">
            <div id="LoadingOverlay" />
            <div
              ref={(ref) => {
                this.animate = ref;
              }}
              id="LoadingOverlayImage"
            />
          </div>
        </div>
      );
    }

    return (
      <Container>
        Please confirm that all details below are correct.
        Should you need to change any information,
        please click on the &apos;Queries&apos; tab
        and create a new request for &apos;Update Facility Information&apos;.
        A BTO administrator will respond accordingly to assist.
        <hr />
        <Form.Group>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                Facility Information
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="ficardbody">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.code}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.code} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.description}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.description} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customerType}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customerType} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customNumber1}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customNumber1} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customNumber2}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customNumber2} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customNumber3}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customNumber3} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customNumber4}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customNumber4} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom10}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom10} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customNumber6}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customNumber6} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom2}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom2} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.joinDate}</Form.Label>
                      </Form.Group>
                      <Form.Control type="datetime" defaultValue={customerData.joinDate} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customLookup3}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customLookup3} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom6}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom6} readOnly />
                    </Col>

                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom7}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom7} readOnly />
                    </Col>

                    <Col md={12}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom3}</Form.Label>
                      </Form.Group>
                      <Form.Control as="textarea" rows={3} defaultValue={customerData.custom3} readOnly />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                Contact Information
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="ficardbody">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.contactPerson}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.contactPerson} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.officeNumber}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.officeNumber} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.mobileNumber}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.mobileNumber} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.faxNumber}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.faxNumber} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.emailAddress}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.emailAddress} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom4}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom4} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom5}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom5} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom8}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom8} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.custom9}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.custom9} readOnly />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                Address Info
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="ficardbody">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>
                          {customerLabels.billingAddress.id}
                          {' '}
                          HIDDEN
                        </Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.id} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.address3}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.address3} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.address4}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.address4} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.address5}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.address5} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.address6}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.address6} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.address7}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.address7} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.address8}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.address8} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>
                          {customerLabels.billingAddress.province}
                          {' '}
                          SELECT
                        </Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.province} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.billingAddress.formattedLatLong}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.billingAddress.formattedLatLong} readOnly />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                Facility Status
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body className="ficardbody">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customerStatus}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customerStatus} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customLookup1}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customLookup1} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customDate2}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customDate2} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customLookup2}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customLookup2} readOnly />
                    </Col>
                    <Col md={6}>
                      <Form.Group className="formLabels">
                        <Form.Label>{customerLabels.customDate3}</Form.Label>
                      </Form.Group>
                      <Form.Control type="text" defaultValue={customerData.customDate3} readOnly />
                    </Col>
                  </Row>

                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                Facility Services
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body className="ficardbody">
                  <Row>
                    {customMultipleChoice1Array.map((obj, key) => (
                      <Col md={3}>
                        <Form.Group controlId="formBasicCheckbox" disabled>
                          { customerData.customMultipleChoice1.includes(obj.value)
                            ? <Form.Check key={key} type="checkbox" label={obj.label} disabled checked />
                            : <Form.Check key={key} type="checkbox" label={obj.label} disabled checked={false} />}
                        </Form.Group>
                      </Col>
                    ))}

                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Form.Group>
        {workRequestType}
      </Container>
    );
  }
}
