import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { Card, Image } from 'semantic-ui-react';
import '../../styles/servest_it/home.scss';

export const EmployeeSchemaSelectIS = () => (
  <Container>
    <Row xs={1} md={3} className="iconRow">
      {[
        [
          'https://za2.forcelink.net/forcelink/login.jsp?subscr=servest_is',
          'integrated-solutions.png',
          'Integrated Solutions – Help Desk',
          'servest_is',
          'Personalised integrated facilities management solutions across multiple sectors.',
        ],
        [
          'https://za2.forcelink.net/forcelink/login.jsp?subscr=servest_mh',
          'facilities-management.png',
          '1 Military Hospital',
          'servest_mh',
          "We are a facilities management company, whose sole purpose is to look after our clients' buildings, people and assets.",
        ],
        [
          'https://za2.forcelink.net/forcelink/login.jsp?subscr=servest_nmu',
          'fleet-managment.png',
          'Nelson Mandela University',
          'servest_nmu',
          'We are providing effecient and cost-effective fleet management solutions to our clients such as Nelson Mandela University.',
        ],
      ].map((val) => (
        <Col>
          <div key={val[2]} style={{ padding: '5px', maxWidth: '22rem', minHeight: '340px' }}>
            <Card className="hoverCard" link href={val[0]} style={{ background: '#ffffff', minHeight: '340px' }}>
              <Image src={`servest_it/${val[1]}`} wrapped ui={false} style={{ width: '100%', margin: 'auto' }} />
              <Card.Content>
                <Card.Header style={{ color: 'black' }}>{val[2]}</Card.Header>
                <Card.Description>{val[4]}</Card.Description>
              </Card.Content>
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  </Container>
);
