import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageDropStyle } from './joburgwaterpage.styled';
import { addWorkRequestAttachment } from '../../functions/apiCalls';

const ImageDrop = ({
  workImages,
  images,
  setImages,
  workRequestId,
  refresh,
  readOnly,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
  });

  useEffect(() => {
    const addImage = async () => {
      if (workRequestId !== undefined) {
        if (acceptedFiles.length > 0) {
          const failed = [];
          const uploadImages = async () => {
            const uploadPromises = images.map((image) => {
              const formData = new FormData();
              formData.append('workRequestId', workRequestId);
              formData.append('file', image);
              return addWorkRequestAttachment(formData, 'joburg_water');
            });
            await Promise.all(uploadPromises);
          };
          await uploadImages();
          await refresh();
          if (failed.length === 0) {
            alert('Image(s) added successfully');
          } else {
            alert(`Image upload failed for: ${failed.map((failureName) => failureName).join(' ')}`);
          }
        }
      } else if (images !== undefined && acceptedFiles.length > 0) {
        const temp = [...images];
        acceptedFiles.forEach(async (acceptedFile) => {
          temp.push(acceptedFile);
        });
        setImages([...temp]);
      }
    };
    addImage();
  }, [acceptedFiles]);

  const handleDelete = async (identifier) => {
    if (workRequestId !== undefined) {
      // await deleteImage('WorkOrderRequest', identifier, image.id);
      await refresh();
    } else {
      const temp = [...images];
      temp.splice(identifier, 1);
      setImages([...temp]);
    }
  };

  const getImageUrl = (file) => {
    let url = '';
    if (file) {
      url = URL.createObjectURL(file);
    }
    return url;
  };

  return (
    <ImageDropStyle>
      {workImages !== undefined
        && workImages.map((workImage, i) => (
          <div key={`${workImage.timeStamp}-work-image`} className="work-image">
            <img src={workImage.externalURL} alt="" />
            {!readOnly
            && (
            <div
              aria-label="delete"
              className="control-delete"
              role="button"
              tabIndex={0}
              onClick={() => { handleDelete(i); }}
            >
              <DeleteIcon />
            </div>
            )}
          </div>
        ))}
      {images !== undefined
        && images.map((image, i) => (
          <div key={`${i}-work-image`} className="work-image">
            <img src={getImageUrl(image)} alt="" />
            {!readOnly
            && (
            <div
              aria-label="delete"
              className="control-delete"
              role="button"
              tabIndex={0}
              onClick={() => { handleDelete(i); }}
            >
              <DeleteIcon />
            </div>
            )}
          </div>
        ))}
      {!readOnly
      && (
      <div id="dropzone-wrapper" {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} data-test-id="dropzone" />
        <AddPhotoAlternateOutlinedIcon style={{ marginBottom: '8px' }} />
        Upload
      </div>
      )}
    </ImageDropStyle>
  );
};

export default ImageDrop;
