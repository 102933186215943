import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import FormSection from '../helpers/formsection';
import DropZone from '../helpers/dropzone';

const Documents = (props) => {
  const { submit, doSubmit } = props;
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (submit) {
      let valid = true;
      if (file.length !== 3) {
        document.getElementById('dropzone-wrapper').classList.add('invalid-input');
        valid = false;
      }
      doSubmit(file, valid);
    }
  }, [submit]);

  return (
    <FormSection title="Documentation">
      <div>
        * Attach 3 separate documents
        <ol style={{ paddingLeft: '15px' }}>
          <li>Copy of ID document/ driver&apos;s licence/ passport</li>
          <li>
            Copy of lost/stolen card purchase receipt or any product
            load receipt to prove ownership
          </li>
          <li>Copy of replacement card purchase receipt</li>
        </ol>
      </div>
      <DropZone
        referenceFile={file}
        setReferenceFile={(f) => {
          document.getElementById('dropzone-wrapper').classList.remove('invalid-input');
          setFile(_.cloneDeep(f));
        }}
        size={3}
      />
    </FormSection>
  );
};

export default Documents;
