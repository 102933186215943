import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { Card, Image } from 'semantic-ui-react';
import '../../styles/servest_it/home.scss';

export const EmployeeSchemaSelect = () => (

  <Container>
    <Row xs={1} md={4} className="iconRow2">
      {[
        [
          '/servest/employee-is',
          'integrated-solutions.png',
          'Integrated Solutions',
          'servest_is',
          'Personalised integrated facilities management solutions across multiple sectors.',
        ],
        [
          'https://za2.forcelink.net/forcelink/login.jsp?subscr=servest_cl',
          'cleaning.jpeg',
          'Cleaning',
          'servest_cl',
          'We pride ourselves on providing consistent and professional cleaning services across a variety of sectors.',
        ],
        [
          'https://za2.forcelink.net/forcelink/login.jsp?subscr=servest_it',
          'it.jpg',
          'IT',
          'servest_it',
          'Enables users to log in, create and view IT related calls against the Systems, Technical and Mobile IT Support divisions.',
        ],
        [
          'https://za2.forcelink.net/forcelink/login.jsp?subscr=servest_os',
          'office-plants.png',
          'Office Services',
          'servest_os',
          "We enhance the natural beauty of our clients' office space, by crafting a space filled with beauty and tranquillity.",
        ],
      ].map((val) => (
        <Col>
          <div key={val[2]} style={{ padding: '5px', maxWidth: '22rem', minHeight: '340px' }}>
            <Card className="hoverCard" link href={val[0]} style={{ background: '#ffffff', minHeight: '340px' }}>
              <Image src={`servest_it/${val[1]}`} wrapped ui={false} style={{ width: '100%', margin: 'auto' }} />
              <Card.Content>
                <Card.Header style={{ color: 'black' }}>{val[2]}</Card.Header>
                <Card.Description>{val[4]}</Card.Description>
              </Card.Content>
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  </Container>
);
