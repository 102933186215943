import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { DropZoneContainer } from '../forms.styled';

const DropZone = (props) => {
  const {
    referenceFile,
    setReferenceFile,
    size,
  } = props;
  const [error, setError] = useState(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: size,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
      'file/*': ['.pdf'],
    },
    onDropRejected: () => {
      setError(
        'Incorrect file type. Please upload a .jpg, .jpeg, .png or .pdf file file.',
      );
    },
    onDropAccepted: () => {
      setError(null);
    },
  });

  const deleteDoc = (index) => {
    const temp = referenceFile;
    temp.splice(index, 1);
    setReferenceFile(temp);
  };

  useEffect(() => {
    const temp = referenceFile;
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (temp.length < 3) {
        temp.push(acceptedFiles[i]);
      } else {
        break;
      }
    }
    setReferenceFile(temp);
  }, [acceptedFiles]);

  return (
    <DropZoneContainer>
      <div
        id="dropzone-wrapper"
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} data-test-id="dropzone" />
        <AddPhotoAlternateOutlinedIcon style={{ marginBottom: '8px' }} />
        Upload Documents [.jpg, .jpeg, .png or .pdf]
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p
          style={{ alignItems: 'end', display: 'flex', margin: '3px 0px 0px 0px' }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        />
        <p
          style={{ alignItems: 'end', display: 'flex', margin: '3px 0px 0px 0px' }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          {`${referenceFile.length}/${size}`}
        </p>
      </div>
      <div>
        {error}
      </div>
      <div>
        {referenceFile.map((file, i) => (
          <div
            key={i}
            style={{
              display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'space-between',
            }}
          >
            <div>
              File:
              {' '}
              {file.name}
            </div>
            <div aria-label="Delete Document" role="button" tabIndex={0} style={{ cursor: 'pointer' }} onClick={() => { deleteDoc(i); }}>
              <CloseIcon />
            </div>
          </div>
        ))}
      </div>
    </DropZoneContainer>
  );
};

export default DropZone;
