import { apiUrl } from '../../constants/constantsGlobal';
import {
  createTicketMapping,
} from '../../constants/siemens/constants';
import {
  fetchApi,
  standardError,
  getWorkRequestTypes,
  getWorkRequestSubtypes,
  byClassAndProp,
} from '../../functions/apiCalls';
import { getData, getEntries } from '../../functions/forms';

// const subscriberId = "servest_it";

// DEV (TODO: delete) >>>
// if (window.location.origin === "http://localhost:3000") {
//   console.log("You are on localhost and as such, test data will be used");
//   return new Promise((resolve, reject) =>
//     resolve(getEntries(testWorkRequest, createTicketMapping))
//   );
// } else {
// <<<
// } //DEV (TODO: delete)

export const getTicketDetails = (code) => fetchApi(
  `${apiUrl}customerportalmanager/getWorkRequest?code=${code}`,
  'GET',
  {
    credentials: 'include',
  },
)
  .then((res) => res.json())
  .then((json) => getEntries(json, createTicketMapping))
  .catch(standardError);

// DEV (TODO: delete) >>>
// if (window.location.origin === "http://localhost:3000") {
//   console.log("You are on localhost and as such, test data will be used");
//   return new Promise((resolve, reject) =>
//     resolve(id ? testSubtypesList : testTypesList)
//   );
// } else {
// <<<
// } //DEV (TODO: delete)

export const getTypes = () => getWorkRequestTypes()
  .then((res) => res.json())
  .catch(standardError);

export const getSubtypes = (parentCode) => getWorkRequestSubtypes(parentCode)
  .then((res) => res.json())
  .catch(standardError);

// DEV (TODO: delete) >>>
// if (window.location.origin === "http://localhost:3000") {
//   console.log("You are on localhost and as such, test data will be used");
//   return new Promise((resolve, reject) => resolve(testPrioritiesList));
// } else {
// <<<
// } //DEV (TODO: delete)

export const getPriorities = () => fetchApi(
  `${apiUrl}referencelistmanager/findCodeDescriptionByClassAndProperty?className=WorkOrder&propertyName=priority&currentEntryId=0`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch(standardError);

// DEV (TODO: delete) >>>
// if (window.location.origin === "http://localhost:3000") {
//   console.log("You are on localhost and as such, test data will be used");
//   return new Promise((resolve, reject) => resolve(testTechniciansList));
// } else {
// <<<
// } //DEV (TODO: delete)

export const getTechnicians = () => byClassAndProp(
  'WorkOrderRequest',
  'customLookup2',
  'referencelistmanager',
)
  .then((res) => res.json())
  .catch(standardError);

export const createTicket = (oldEntries) => {
  const entries = { ...oldEntries };
  console.log('entries:', entries);
  if (entries.subcategory !== '') {
    entries.category = entries.subcategory;
    //  subcategory should replace category
    //  which will then have the json key workType in the api call body
  }
  delete entries.subcategory;
  const data = getData(entries, createTicketMapping);
  data.address.country = 1;
  console.log('data:', data);
  // TODO: set states
  return fetchApi(`${apiUrl}customerportalmanager/postWorkRequest`, 'POST', {
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((json) => console.log(json))
    .catch(standardError);
};

export const postWorkRequest = (workRequestObject) => fetchApi(
  `${apiUrl}customerportalmanager/postWorkRequest`,
  'POST',
  {
    credentials: 'include',
    body: JSON.stringify(workRequestObject),
  },
)
  .then((res) => res)
  .catch((e) => e);
