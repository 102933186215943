import React from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../functions/bedworth/context';
import * as login from '../../scripts/loginScripts';
import { LoginForm } from '../../components/bedworth/loginForm';

export class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'bedworth';
    document.title = 'Bedworth Student Portal';
  }

  onLogin = ({ username, password }, setStates) => {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    login.doLogin(username, password, setAuth, subscriberId, setStates);
  };

  render() {
    const { onLogin, subscriberId } = this;
    const subscriber = 'Bedworth';
    return (
      <Container fluid id="LoginForm">
        <LoginForm {...{ subscriber, onLogin, subscriberId }} />
      </Container>
    );
  }
}
