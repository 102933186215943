import React, { useContext } from 'react';
import { ProfileForm } from '../../components/ssg_facilities/profile';
import { Context } from '../../functions/ssg_facilities/context';
import { doLogin } from '../../scripts/loginScripts';

export const Register = () => {
  document.title = 'Register | SSG Facilities Portal';
  const subscriberId = 'ssg_fac';

  const { setAuth, setUsername } = useContext(Context);
  const onLogin = ({ username, password }, setStates) => {
    doLogin(username, password, setAuth, subscriberId, setStates)
      .then((loggedIn) => loggedIn && setUsername(username));
  };

  return <ProfileForm newProfile {...{ onLogin, subscriberId }} />;
};
