import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Material from '@material-ui/core';
import { Context } from '../../functions/joburg_water/context';
import '../../styles/joburg_water/home.scss';
import Ripple from '../../components/joburg_water/ripple';
import SlideIn from '../../components/joburg_water/slidein';

export class HomePage extends React.Component {
  static contextType = Context;

  iconRow = (val, i) => (
    <div key={`icon-${i}`} className="iconGroup">
      <Link to={val[0]}>
        <div id="image-container">
          <img
            src={`joburg_water/homeIcons/${val[1]}.svg`}
            alt="icon"
            className="icons"
          />
          <Ripple />
        </div>
      </Link>
      <p className="iconCaptions">{val[2]()}</p>
    </div>
  );

  render() {
    return (
      <div id="homePageContainer">
        <div className="slide-container">
          <SlideIn settings={{
            marginRight: 0,
            width: '100%',
            from: { marginRight: -700 },
            config: { mass: 1, tension: 300, friction: 30 },
          }}
          >
            <Container>
              <div id="title-container">
                <img src="joburg_water/logo.jpg" className="login-logo" alt="Joburg Water Logo" />
                <div className="login-title">
                  Fault Logging
                </div>
              </div>
              <Row className="iconRow">
                {[
                  ['/profile', 'Profile', () => 'Edit Profile'],
                  [
                    '/logFaultMyAddress',
                    'Log at Home',
                    () => (
                      <span>
                        Log a fault
                        <br />
                        (My address)
                      </span>
                    ),
                  ],
                  [
                    '/logFaultOtherAddress',
                    'Log Away',
                    () => (
                      <span>
                        Log a fault
                        <br />
                        (Other address)
                      </span>
                    ),
                  ],
                ].map(this.iconRow)}
              </Row>
              <Row className="iconRow" style={{ borderBottom: '1px solid #EBEBEB', paddingBottom: '16px' }}>
                {[
                  ['/openFaults', 'Open Faults', () => 'Open Faults (Logged by me)'],
                  ['/allFaults', 'All Faults', () => 'All faults'],
                  ['/notifications', 'Notifications', () => 'Notifications'],
                  ['/logout', 'Log Out', () => 'Log out'],
                ].map(this.iconRow)}
              </Row>
              <br />
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Material.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.forcelink.net/support');
                  }}
                  underline="always"
                >
                  Log Support Issue
                </Material.Link>
              </div>
            </Container>
          </SlideIn>
        </div>
      </div>
    );
  }
}
