import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Modal,
} from 'react-bootstrap';
import { Link, Switch } from 'react-router-dom';
import '../styles/headerandfooter.scss';
import logo from '../img/forcelink/powered_by_forcelink.png';

export const LogoHeader = (props) => {
  const {
    title,
    imgSrc,
    leftNav,
    rightNav,
    titleStyle,
    ...rest
  } = props;

  return (
    <Container fluid id="headerContainer" {...rest}>
      <Row>
        <Col xs={5} md={2} id="headerLogoCol">
          <Link to="/">
            <img src={imgSrc} className="App-logo" alt={`${title} Logo`} />
          </Link>
          {leftNav}
        </Col>
        <Col xs={7} md={8} id="headerTitleCol">
          <p id="headerTitle" style={titleStyle}>
            {title}
          </p>
        </Col>
        <Col xs={2} md={2}>
          {rightNav}
        </Col>
      </Row>
    </Container>
  );
};

export const ForcelinkFooter = (props) => (
  <Navbar fixed="bottom" id="forcelinkFooter" {...props}>
    <Navbar.Brand href="https://www.forcelink.net">
      <img
        src={logo}
        alt="Powered by Forcelink"
      />
    </Navbar.Brand>
  </Navbar>
);

export const LogoHeaderWithNav = ({
  history,
  logoSrc,
  username,
  dropdowns,
}) => (
  <Navbar className="logoHeaderWithNav" collapseOnSelect expand="md">
    <Navbar.Brand id="headerLogoCol">
      <div
        tabIndex={0}
        role="button"
        onClick={() => history.push('/home')}
      >
        <img
          alt="Portal home"
          src={logoSrc}
          className="d-inline-block align-top"
        />
      </div>
    </Navbar.Brand>
    <Switch>
      <HeaderNav {...{ username, dropdowns }} />
    </Switch>
  </Navbar>
);

export const LogoHeaderWithoutNav = () => (
  <Navbar style={{ height: '120px' }} />
);

const headerProps = (item, showDropdown, setShowDropdown) => ({
  show: showDropdown[item],
  onMouseEnter: () => setShowDropdown((state) => ({ ...state, [item]: true })),
  onMouseLeave: () => setShowDropdown((state) => ({ ...state, [item]: false })),
  className: `basic-nav-dropdown outerLinkContainer ${
    showDropdown[item] ? 'showDropdown' : ''
  }`,
});

const HeaderNav = ({ username, dropdowns }) => {
  const items = dropdowns.map(([item]) => item);
  const itemsState = items.reduce((state, item) => {
    const stated = state;
    (stated[item] = false);
    return stated;
  }, {});

  const [showDropdown, setShowDropdown] = useState({
    ...itemsState,
  });
  const props = (item) => headerProps(item, showDropdown, setShowDropdown);

  return (
    <>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" id="headerLinks">
          {dropdowns.map(([stateName, Icon, title, itms, onClick], i) => (
            <HeaderDropdown
              key={i}
              {...props(stateName)}
              {...{ Icon, title, itms }}
              {...(onClick && { onClick })}
            />
          ))}
        </Nav>
        <Nav className="justify-content-end">
          <Navbar.Text>
            {username !== '' && (
              <>
                Signed in as:
                {' '}
                <b>{username}</b>
              </>
            )}
            <Link to="/logout" className="link-item">
              Log out
            </Link>
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </>
  );
};

const HeaderDropdown = ({
  itms, Icon, title, ...props
}) => (
  <NavDropdown
    {...props}
    className={itms.length === 0 ? 'basic-nav-dropdown outerLinkContainer nodropdown' : 'basic-nav-dropdown outerLinkContainer'}
    title={(
      <Nav.Link disabled to="" className="innerLinkContainer">
        <Icon fontSize="large" />
        <p className="headerLinksText">{title}</p>
      </Nav.Link>
    )}
  >
    <HeaderDropdownItems items={itms} />
  </NavDropdown>
);

const HeaderDropdownItems = ({ items }) => items.map(([onClick, name], i, arr) => {
  const isPath = typeof onClick === 'string';
  const props = {
    onClick: (e) => e.stopPropagation(),
    ...(isPath ? { to: onClick } : { onClick }),
  };
  return (
    <React.Fragment key={i}>
      {isPath
        ? (
          <Link {...props}>
            <div className="dropdown-item">{name}</div>
          </Link>
        ) : (
          <div {...props}>
            <div className="dropdown-item">{name}</div>
          </div>
        )}
      {i !== arr.length - 1 && <NavDropdown.Divider />}
    </React.Fragment>
  );
});

export const SupportModal = ({ showModal, setShowModal, version }) => (
  <Modal
    centered
    show={showModal}
    onHide={() => setShowModal(false)}
    id="ServestITSupportModal"
    size="md"
  >
    <Modal.Header closeButton>
      <Modal.Title>About Forcelink</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      If you require any assistance please do not hesitate to contact us on:
      <br />
      <br />
      Tel:
      {' '}
      <a href="tel:+27114678528">+27 (0) 11 467 8528</a>
      {' '}
      (8:00 - 17:00)
      <br />
      Tel:
      {' '}
      <a href="tel:+27726712762">+27 (0)72 671 2762</a>
      {' '}
      (17:00 - 8:00)
      <br />
      Email:
      {' '}
      <a href="mailto:support@forcelink.net">support@forcelink.net</a>
      <br />
      <br />
      Forcelink version
      {' '}
      {version}
      <br />
      Acumen Software
      <br />
      <a href="www.forcelink.net">www.forcelink.net</a>
    </Modal.Body>
  </Modal>
);
