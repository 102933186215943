import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FormTemplate from './helpers/formtemplate';
import PassengerDetails from './sections/passengerdetails';
import Declaration from './sections/declaration';
import FormButtons from './sections/formbuttons';
import CardDetails from './sections/carddetails';
import Affidavit from './sections/affidavit';
import Documents from './sections/documents';
import { addWorkRequestAttachment, getWorkRequest, postWorkRequest } from '../../../scripts/myciti/scripts';
import {
  ScrollContainer,
} from './forms.styled';

const LostOrStolenForm = () => {
  const [form, setForm] = useState({});
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [validSubmit, setValidSubmit] = useState(true);
  const [submitAll, setSubmitAll] = useState(false);
  const [submitPassengerDetails, setSubmitPassengerDetails] = useState(false);
  const [passengerDetails, setPassengerDetails] = useState({});
  const [submitDeclaration, setSubmitDeclaration] = useState(false);
  const [declaration, setDeclaration] = useState({});
  const [submitCardDetails, setSubmitCardDetails] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const [submitAffidavit, setSubmitAffidavit] = useState(false);
  const [affidavit, setAffidavit] = useState({});
  const [submitDocuments, setSubmitDocuments] = useState(false);
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(false);

  const doSubmit = () => {
    setSubmitAll(true);
    setSubmitPassengerDetails(true);
    setSubmitDeclaration(true);
    setSubmitCardDetails(true);
    setSubmitAffidavit(true);
    setSubmitDocuments(true);
    setLoading(true);
  };

  const getPassengerDetails = (title, firstName, lastName, email, cellphone, valid) => {
    setSubmitPassengerDetails(false);
    if (valid) {
      setPassengerDetails({
        contactName: `${title} ${firstName} ${lastName}`,
        contactEmail: email,
        contactNumber: cellphone,
        valid,
      });
    } else {
      setPassengerDetails({});
    }
  };

  const getCardDetails = (cardNumber, expirationDate, cardForTransfer, valid) => {
    setSubmitCardDetails(false);
    if (valid) {
      const dateTime = new Date(dayjs(expirationDate.toISOString().split('T')[0]));
      dateTime.setDate(expirationDate.$D);
      dateTime.setMonth(expirationDate.$M);
      dateTime.setFullYear(expirationDate.$y);
      setCardDetails({
        custom24: cardNumber,
        customDate2: +dateTime,
        custom10: cardForTransfer,
        valid,
      });
    } else {
      setCardDetails({});
    }
  };

  const getAffidavit = (
    date,
    time,
    location,
    explanation,
    value,
    sapsNumber,
    valid,
  ) => {
    setSubmitAffidavit(false);
    if (valid) {
      const dateTime = new Date(dayjs(date.toISOString().split('T')[0]));
      dateTime.setDate(date.$D);
      dateTime.setMonth(date.$M);
      dateTime.setFullYear(date.$y);
      dateTime.setHours(time.$H);
      dateTime.setMinutes(time.$m);
      setAffidavit({
        customDate3: +dateTime,
        address: {
          address1: '',
          address2: '',
          address3: '',
          address4: '',
          address5: '',
          address6: '',
          address7: '',
          address8: '',
          countryCode: 'za',
          description: '',
          firmCoordinate: true,
          geomArea: null,
          latitude: location.lat,
          longitude: location.lng,
          provinceCode: '',
          requiresGeocoding: false,
          requiresReverseGeocoding: true,
          routePoints: null,
        },
        description: `Explanation of what happened: ${explanation}`,
        custom20: sapsNumber,
        customLookupCode2: value === 'stolen' ? 'SC' : 'LC',
        valid,
      });
    } else {
      setAffidavit({});
    }
  };

  const getDocuments = (file, valid) => {
    setSubmitDocuments(false);
    if (valid) {
      setDocuments({
        file,
        valid,
      });
    } else {
      setDocuments({});
    }
  };

  const getDeclaration = (date, valid) => {
    if (valid) {
      const dateTime = new Date(dayjs(date.toISOString().split('T')[0]));
      dateTime.setDate(date.$D);
      dateTime.setMonth(date.$M);
      dateTime.setFullYear(date.$y);
      setDeclaration({
        customDate4: +dateTime,
        valid,
      });
    } else {
      setDeclaration({});
    }
    setSubmitDeclaration(false);
  };

  useEffect(() => {
    let valid = passengerDetails.valid && cardDetails.valid && declaration.valid
    && affidavit.valid && documents.valid;
    if (valid === undefined) {
      valid = false;
    }
    setForm({
      ...passengerDetails, ...cardDetails, ...affidavit, ...documents, ...declaration, valid,
    });
  }, [passengerDetails, declaration, cardDetails, affidavit, documents]);

  useEffect(() => {
    if (form.valid) {
      setValidSubmit(true);
      const formSubmit = async () => {
        const tempForm = form;
        const documentFiles = tempForm.file;
        delete tempForm.valid;
        delete tempForm.file;
        const workRequest = {
          ...tempForm,
          docType: 'WorkOrderRequest',
          typeCode: 'LCR',
          priorityCode: 'LRP',
          customLookupCode1: 'AFC',
          orgUnitCode: 'AFC',
          assetCodes: ['AFCR'],
          customLookupCode4: 'AT00',
        };
        const response = await postWorkRequest(workRequest).then((res) => res.json());
        const wr = await getWorkRequest(response.code).then((res) => res.json());
        documentFiles.forEach(async (documentFile) => {
          const formData = new FormData();
          formData.append('workRequestId', wr.id);
          formData.append('file', documentFile);
          await addWorkRequestAttachment(formData, 'myciti');
        });
        history.push(`/slip?code=${encodeURIComponent(response.code)}`);
        setLoading(false);
      };
      formSubmit();
    } else if (submitAll) {
      setSubmitAll(false);
      setValidSubmit(false);
      setOpenSnackbar(true);
      setToastMessage('Invalid form');
      setLoading(false);
    }
  }, [form]);

  return (
    <FormTemplate
      title="Application for transfer of value from a lost or stolen card"
      openSnackbar={openSnackbar}
      setOpenSnackbar={setOpenSnackbar}
      toastMessage={toastMessage}
      validSubmit={validSubmit}
      loading={loading}
    >
      <ScrollContainer>
        <div style={{ marginTop: '16px' }}>
          Lost/stolen cards will be blocked irreversibly,
          but in some cases contactless payments can still occur
          MyCiTi cannot be held liable for the loss of remaining
          value after the card was lost/stolen.
        </div>
        <PassengerDetails submit={submitPassengerDetails} doSubmit={getPassengerDetails} />
        <CardDetails submit={submitCardDetails} doSubmit={getCardDetails} />
        <Affidavit submit={submitAffidavit} doSubmit={getAffidavit} />
        <Documents submit={submitDocuments} doSubmit={getDocuments} />
        <Declaration submit={submitDeclaration} doSubmit={getDeclaration} />
      </ScrollContainer>
      <FormButtons doSubmit={doSubmit} />
    </FormTemplate>
  );
};

export default LostOrStolenForm;
