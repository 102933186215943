import React, { useRef, useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  FieldsContainer,
  Row,
} from '../forms.styled';
import FormSection from '../helpers/formsection';
import TextArea from '../helpers/textarea';
import {
  getStationsAndBusStops,
} from '../../../../scripts/myciti/scripts';

const IncidentDetails = (props) => {
  const { submit, doSubmit } = props;
  const [dateIncident, setDateIncident] = useState(null);
  const [timeIncident, setTimeIncident] = useState(null);
  const [stationShopId, setStationShopId] = useState(0);
  const [searchedStation, setSearchedStation] = useState('');
  const [stationsOrShops, setStationsOrShops] = useState([]);
  const [busNumber, setBusNumnber] = useState('');
  const [incidentDescription, setIncidentDescription] = useState('');
  const dateIncidentRef = useRef();
  const timeIncidentRef = useRef();
  const stationShopRef = useRef();
  const incidentDescriptionRef = useRef();

  useEffect(() => {
    const fetchdata = async () => {
      const stationsAndShops = await getStationsAndBusStops();
      setStationsOrShops(stationsAndShops);
    };
    fetchdata();
  }, []);

  useEffect(() => {
    if (submit) {
      let valid = true;
      let stationShop = '';
      if (dateIncident === null) {
        dateIncidentRef.current.classList.add('invalid-date');
        valid = false;
      }
      if (timeIncident === null) {
        timeIncidentRef.current.classList.add('invalid-date');
        valid = false;
      }
      if (stationShopId === 0) {
        stationShopRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
        valid = false;
      } else {
        const station = stationsOrShops
          .find((stationAndShop) => stationAndShop.value === stationShopId);
        if (station !== undefined) {
          stationShop = station.label;
        } else {
          stationShopRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
          valid = false;
        }
      }
      if (incidentDescription === '') {
        incidentDescriptionRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
        valid = false;
      }
      doSubmit(
        dateIncident,
        timeIncident,
        stationShop,
        busNumber,
        incidentDescription,
        valid,
      );
    }
  }, [submit]);

  return (
    <FormSection title="Incident Details">
      <Row>
        <div className="expirationdate left-side">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              maxDate={dayjs()}
              value={dateIncident}
              ref={dateIncidentRef}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  helperText: 'Date of incident',
                },
              }}
              onChange={(newValue) => {
                setDateIncident(newValue);
                if (newValue && newValue !== '' && newValue !== null) {
                  dateIncidentRef.current.classList.remove('invalid-date');
                } else {
                  dateIncidentRef.current.classList.add('invalid-date');
                }
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="expirationdate">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              value={timeIncident}
              ref={timeIncidentRef}
              slotProps={{
                textField: {
                  helperText: 'Time of incident',
                },
              }}
              onChange={(newValue) => {
                setTimeIncident(newValue);
                if (newValue && newValue !== '' && newValue !== null) {
                  timeIncidentRef.current.classList.remove('invalid-date');
                } else {
                  timeIncidentRef.current.classList.add('invalid-date');
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </Row>
      <div className="location-search" ref={stationShopRef} style={{ marginBottom: '16px' }}>
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box"
          options={searchedStation !== '' ? [...stationsOrShops.filter(
            (station) => station.label.toLowerCase().includes(searchedStation),
          )] : []}
          onChange={(event, newValue) => {
            if (newValue && newValue.value) {
              stationShopRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
              setStationShopId(newValue.value);
            } else {
              stationShopRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
              setStationShopId(0);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              onChange={(event) => { setSearchedStation(event.target.value); }}
            />
          )}
          renderOption={(renderOptionProps, option) => (
            <li {...renderOptionProps} key={option.value}>{option.label}</li>
          )}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Station or Stop
        </p>
      </div>
      <FieldsContainer>
        <input
          type="text"
          name="busNumber"
          value={busNumber}
          onChange={(event) => {
            setBusNumnber(event.target.value);
          }}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Bus Number
        </p>
      </FieldsContainer>
      <TextArea
        onChange={(event, size) => {
          if (event.target.value.length <= size) {
            setIncidentDescription(event.target.value);
          }
          if (event.target.value && event.target.value !== '') {
            incidentDescriptionRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
          } else {
            incidentDescriptionRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
          }
        }}
        size={600}
        value={incidentDescription}
        thisRef={incidentDescriptionRef}
        helperText="Provide details of the incident"
      />
    </FormSection>
  );
};

export default IncidentDetails;
