import React from 'react';
import {
  Button, Form, Card,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress as Spinner } from '@material-ui/core';
import '../../styles/bedworth/login.scss';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitting: false,
      loginDone: false,
      passwordDisplay: 'password',
    };
  }

  handleLogin = () => {
    console.log('handling login');
    const { onLogin } = this.props;
    this.setState({ submitting: true });
    onLogin(this.state, (submitting, loginDone) => this.setState({ submitting, loginDone }));
  };

  render() {
    const {
      submitting, loginDone, passwordDisplay,
    } = this.state;
    const { subscriber } = this.props;

    return (
      <>
        <div id="loginFormContainer">
          <div className="new-login-logo-container">
            <img src="bedworth/bedworth_logo.png" className="new-login-logo-image" alt="Bedworth Student Portal Logo" />
          </div>
          <div className="new-login-form-container">
            <div id="loginFormCard" className="text-center">
              <Card.Header>
                <Card.Title className="new-login-title">
                  <b>Log in</b>
                </Card.Title>
                <Card.Subtitle>
                  Sign in to your
                  {' '}
                  {subscriber}
                  {' '}
                  account
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Material.TextField
                      name="username"
                      fullWidth
                      label="Username"
                      variant="outlined"
                      onChange={(event) => this.setState({ username: event.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Material.TextField
                      fullWidth
                      label="Password"
                      variant="outlined"
                      type={passwordDisplay}
                      onChange={(event) => this.setState({ password: event.target.value })}
                    />
                  </Form.Group>
                  <Form.Group style={{ margin: '30px 0px 10px 0px' }}>
                    {!submitting && (
                    <Button
                      style={{ padding: '10px', width: '100%', borderRadius: '5px' }}
                      type="submit"
                      variant={loginDone ? 'secondary' : 'primary'}
                      disabled={loginDone}
                      onClick={this.handleLogin}
                    >
                      Login
                    </Button>
                    )}
                    {submitting && <Spinner />}
                  </Form.Group>
                </Form>
              </Card.Body>
            </div>
          </div>

        </div>
      </>
    );
  }
}
