import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';

import { Input } from './Form';

// yup validation schema
export const addressSchema = {
  address: yup.object({
    description: yup.string().nullable(),
    address1: yup.string().nullable(),
    address2: yup.string().nullable(),
    address3: yup.string().nullable(),
    address4: yup.string().nullable(),
    address5: yup.string().nullable(),
    address6: yup.string().nullable(),
    address7: yup.string().nullable(),
    address8: yup.string().nullable(),
    provinceCode: yup.string().nullable(),
    countryCode: yup.string().nullable(),
    latitude: yup.number().nullable(),
    longitude: yup.number().nullable(),
    requiresGeocoding: yup.string().nullable(),
    requiresReverseGeocoding: yup.string().nullable(),
    firmCoordinate: yup.string().nullable(),
    routePoints: yup.number().nullable(),
    geomArea: yup.number().nullable(),
  }),
};

/**
 * Address Form
 * TODO: add map component to this AddressForm
 */
export const AddressForm = ({ title = 'Address' }) => (
  <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Input label="address.description" name="address.description" />
      </Grid>
      <Grid item xs={12}>
        <Input label="address.address1" name="address.address1" />
      </Grid>
      <Grid item xs={12}>
        <Input label="address.address2" name="address.address2" />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Input label="address.address3" name="address.address3" />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Input label="address.address4" name="address.address4" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Input label="address.address5" name="address.address5" />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Input label="address.address6" name="address.address6" />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Input label="address.address7" name="address.address7" />
      </Grid>
      <Grid item sm={4} xs={6}>
        <Input label="address.address8" name="address.address8" />
      </Grid>
    </Grid>
  </div>
);
