import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { Radio } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  FieldsContainer,
  ApplicationRadio,
} from '../forms.styled';
import FormSection from '../helpers/formsection';
import TextArea from '../helpers/textarea';
import DropZone from '../helpers/dropzone';
import {
  getStationsAndBusStops,
} from '../../../../scripts/myciti/scripts';

const PREFIX = '5576-9700-';

const RefundDetails = (props) => {
  const { submit, doSubmit } = props;
  const [radioValue, setRadioValue] = useState('');
  const [refundType, setRefundType] = useState('');
  const [valueDisputed, setValueDisputed] = useState('');
  const [tappedInId, setTappedInId] = useState(0);
  const [tappedOutId, setTappedOutId] = useState(0);
  const [stationsOrShops, setStationsOrShops] = useState([]);
  const [searchedStationIn, setSearchedStationIn] = useState('');
  const [searchedStationOut, setSearchedStationOut] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [cardNumber, setCardNumber] = useState(PREFIX);
  const [motivation, setMotivation] = useState('');
  const [file, setFile] = useState([]);
  const radioOne = useRef();
  const radioTwo = useRef();
  const refundTypeOne = useRef();
  const refundTypeTwo = useRef();
  const valueDisputedRef = useRef();
  const tappedInRef = useRef();
  const tappedOutRef = useRef();
  const cardNumberRef = useRef();
  const motivationRef = useRef();

  useEffect(() => {
    const fetchdata = async () => {
      const stationsAndShops = await getStationsAndBusStops();
      setStationsOrShops(stationsAndShops);
    };
    fetchdata();
  }, []);

  useEffect(() => {
    if (submit) {
      let valid = true;
      let chargedincorrectly = false;
      let refundslip = false;
      let tappedIn = '';
      let tappedOut = '';
      if (refundType === '') {
        refundTypeOne.current.classList.add('invalid-input');
        refundTypeTwo.current.classList.add('invalid-input');
        valid = false;
      }
      if (radioValue === '') {
        radioOne.current.classList.add('invalid-input');
        radioTwo.current.classList.add('invalid-input');
        valid = false;
      } else if (radioValue === 'chargedincorrectly') {
        chargedincorrectly = true;
      } else if (radioValue === 'refundslip') {
        refundslip = true;
        if (file.length < 2) {
          document.getElementById('dropzone-wrapper').classList.add('invalid-input');
          valid = false;
        }
      }
      if (valueDisputed === '') {
        valueDisputedRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (tappedInId === 0) {
        tappedInRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
        valid = false;
      } else {
        const station = stationsOrShops
          .find((stationAndShop) => stationAndShop.value === tappedInId);
        if (station !== undefined) {
          tappedIn = station.label;
        } else {
          tappedInRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
          valid = false;
        }
      }
      if (tappedOutId === 0) {
        tappedOutRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
        valid = false;
      } else {
        const station = stationsOrShops
          .find((stationAndShop) => stationAndShop.value === tappedOutId);
        if (station !== undefined) {
          tappedOut = station.label;
        } else {
          tappedOutRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
          valid = false;
        }
      }
      if (refundType === 'another') {
        if (cardNumber === '' || cardNumber.length < 19) {
          cardNumberRef.current.classList.add('invalid-input');
          valid = false;
        }
        if (motivation === '') {
          motivationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
          valid = false;
        }
      }
      doSubmit(
        chargedincorrectly,
        refundslip,
        valueDisputed,
        tappedIn,
        tappedOut,
        referenceNumber,
        cardNumber,
        motivation,
        refundType,
        file,
        valid,
      );
    }
  }, [submit]);

  const handleCardNumberChange = (event) => {
    let inputValue = event.target.value;
    if (!inputValue.startsWith(PREFIX)) {
      inputValue = PREFIX;
    } else {
      let cardNumberPart = inputValue.slice(PREFIX.length).replace(/\D/g, '');
      cardNumberPart = cardNumberPart.substring(0, 8).replace(/(\d{4})(?=\d)/g, '$1-');
      inputValue = PREFIX + cardNumberPart;
    }
    if (inputValue !== PREFIX || inputValue === PREFIX) {
      setCardNumber(inputValue);
      cardNumberRef.current.classList.remove('invalid-input');
    } else {
      cardNumberRef.current.classList.add('invalid-input');
    }
  };

  return (
    <FormSection title="Refund Application Details">
      <ApplicationRadio>
        <div ref={radioOne} className="typeSelect" style={{ marginBottom: '16px' }}>
          <div className="type">
            <div className="title">
              I think I have been charged incorrectly
            </div>
            <div className="body">
              You may submit supporting documents below
            </div>
          </div>
          <Radio
            checked={radioValue === 'chargedincorrectly'}
            onChange={(event) => {
              setRadioValue(event.target.value);
              radioOne.current.classList.remove('invalid-input');
              radioTwo.current.classList.remove('invalid-input');
            }}
            value="chargedincorrectly"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          />
        </div>
        <div ref={radioTwo} className="typeSelect" style={{ marginBottom: '16px' }}>
          <div className="type">
            <div className="title">
              My transaction failed at the card vending machine (CVM)
            </div>
            <div className="body">
              You need to submit a copy of your bank statement with this application
            </div>
          </div>
          <Radio
            checked={radioValue === 'refundslip'}
            onChange={(event) => {
              setRadioValue(event.target.value);
              radioOne.current.classList.remove('invalid-input');
              radioTwo.current.classList.remove('invalid-input');
            }}
            value="refundslip"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          />
        </div>
      </ApplicationRadio>
      {radioValue && (
        <DropZone
          referenceFile={file}
          setReferenceFile={(f) => {
            document.getElementById('dropzone-wrapper').classList.remove('invalid-input');
            setFile(_.cloneDeep(f));
          }}
          size={radioValue === 'refundslip' ? 2 : 1}
        />
      )}
      <FieldsContainer>
        <input
          className="nomargin"
          type="text"
          step="any"
          name="valueDisputed"
          value={valueDisputed}
          ref={valueDisputedRef}
          onChange={(event) => {
            setValueDisputed(event.target.value);
            if (event.target.value && event.target.value !== '') {
              valueDisputedRef.current.classList.remove('invalid-input');
            } else {
              valueDisputedRef.current.classList.add('invalid-input');
            }
          }}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Value disputed, including any penalties
        </p>
      </FieldsContainer>
      <div className="location-search" ref={tappedInRef} style={{ marginBottom: '16px' }}>
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box"
          options={searchedStationIn !== '' ? stationsOrShops : []}
          onChange={(event, newValue) => {
            if (newValue && newValue.value) {
              tappedInRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
              setTappedInId(newValue.value);
            } else {
              tappedInRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
              setTappedInId(0);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              onChange={(event) => { setSearchedStationIn(event.target.value); }}
            />
          )}
          renderOption={(renderOptionProps, option) => (
            <li {...renderOptionProps} key={option.value}>{option.label}</li>
          )}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Station or Stop tapped IN
        </p>
      </div>
      <div className="location-search" ref={tappedOutRef} style={{ marginBottom: '16px' }}>
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box"
          options={searchedStationOut !== '' ? [...stationsOrShops.filter(
            (station) => station.label.toLowerCase().includes(searchedStationOut),
          )] : []}
          onChange={(event, newValue) => {
            if (newValue && newValue.value) {
              tappedOutRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
              setTappedOutId(newValue.value);
            } else {
              tappedOutRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
              setTappedOutId(0);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              onChange={(event) => { setSearchedStationOut(event.target.value); }}
            />
          )}
          renderOption={(renderOptionProps, option) => (
            <li {...renderOptionProps} key={option.value}>{option.label}</li>
          )}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Station or Stop tapped OUT
        </p>
      </div>
      <FieldsContainer>
        <input
          className="nomargin"
          type="text"
          name="referenceNumber"
          value={referenceNumber}
          onChange={(event) => {
            setReferenceNumber(event.target.value);
          }}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Transport Information Centre (TIC) reference, if available
        </p>
      </FieldsContainer>
      <ApplicationRadio>
        <div ref={refundTypeOne} className="typeSelect" style={{ marginBottom: '16px' }}>
          <div className="type">
            <div className="title">
              Refund to my existing
              <b style={{ marginLeft: '4px' }}>my</b>
              connect card.
            </div>
          </div>
          <Radio
            checked={refundType === 'mine'}
            onChange={(event) => {
              setRefundType(event.target.value);
              refundTypeOne.current.classList.remove('invalid-input');
              refundTypeTwo.current.classList.remove('invalid-input');
            }}
            value="mine"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          />
        </div>
        <div ref={refundTypeTwo} className="typeSelect" style={{ marginBottom: '16px' }}>
          <div className="type">
            <div className="title">
              Refund a different
              <b style={{ marginLeft: '4px' }}>my</b>
              connect card.
            </div>
          </div>
          <Radio
            checked={refundType === 'another'}
            onChange={(event) => {
              setRefundType(event.target.value);
              refundTypeOne.current.classList.remove('invalid-input');
              refundTypeTwo.current.classList.remove('invalid-input');
            }}
            value="another"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          />
        </div>
      </ApplicationRadio>
      {refundType === 'another' && (
      <>
        <FieldsContainer>
          <input
            className="nomargin"
            type="text"
            name="cardNumber"
            value={cardNumber}
            ref={cardNumberRef}
            onChange={handleCardNumberChange}
          />
          <p
            style={{
              margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
            }}
            className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
          >
            Card Number eg. (5576-9700-XXXX-XXXX) -
            For the
            <b style={{ marginLeft: '4px' }}>my</b>
            connect card to be refunded
          </p>
        </FieldsContainer>
        <TextArea
          onChange={(event, size) => {
            if (event.target.value.length <= size) {
              setMotivation(event.target.value);
            }
            if (event.target.value && event.target.value !== '') {
              motivationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.remove('invalid-input');
            } else {
              motivationRef.current.getElementsByClassName('MuiInputBase-root')[0].classList.add('invalid-input');
            }
          }}
          size={600}
          value={motivation}
          thisRef={motivationRef}
          helperText="Motivation for refund to a different card - Incorrect or illegible applications will not be processed."
        />
      </>
      )}
    </FormSection>
  );
};

export default RefundDetails;
