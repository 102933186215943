import React, { useEffect } from 'react';

import { KPIDashboard } from '../../components/siemens/KPIDashboard';

export const HomePage = () => {
  useEffect(() => {
    document.title = 'Home | Siemens Portal';
  }, []);
  return <KPIDashboard title="Welcome to Customer Services!" />;
};
