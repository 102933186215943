import styled from 'styled-components';

export const LoginContainer = styled.div`
  background-color: #F2F2F2;
  height: calc(100vh - 64px);
  width: calc(100vw - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
`;

export const SubscriberImage = styled.div`
  background-image: url("/servest_is/logo.png");
  background-blend-mode: overlay;
  background-size: cover;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 75px;
`;

export const PortalName = styled.div`
  width: 100%;
  margin-bottom: 24px;
  font-family: "Zen Dots", sans-serif !important;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #aaaaae;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 48px);
  width: calc(100% - 48px);
  max-height: 100%;
  overflow-y: auto;
  padding: 24px;
`;

export const InnerContainer = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;

  @media only screen and (min-width: 700px) {
    width: 600px;
  }
`;

export const StyleContainer = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0px 24px 40px 24px;
  overflow-x: hidden;

  .invalid-input {
    border: 1px solid red !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #ff000040 !important;
  }

  .select-container {
    background-color: #fff;
    border: 1px solid #999999;
    padding-right: 20px;
    width: 100%;
    height: 45px;
    margin-bottom: 16px;
    transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;

    :focus-within {
      border-color: #80bdff !important;
      outline: 0;
      box-shadow: 0 0 0 0.2rem #007bff40;
    }
  }

  input {
    color: #000;
    border: 1px solid #999999;
    height: 45px;
    margin-bottom: 40px;
    padding: 12px 16px;
    width: 100%;
    transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;

    :focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem #007bff40;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 40px;
    width: 100%;
  }
`;

export const PasswordVisibility = styled.div`
  border: none;
  background-color: transparent;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #999999;
  margin-bottom: 40px;
  transition: border-color 0.6s, box-shadow 0.6s, outline 0.6s;

  :focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff40;
  }

  input {
    border: none;
    margin: 0px;

    :focus {
      outline: none;
      border-color: transparent;
      box-shadow: none;
    }
  }
`;

export const LoginButton = styled.div`
  padding: 10px;
  background: #131e98;
  border: 1px solid #131e98;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export const RegisterButton = styled.div`
  padding: 10px;
  background: #ffffff;
  border: 1px solid #aaaaae;
  color: #aaaaae;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export const StyleHr = styled.hr`
  color: #ebebeb;
  opacity: 1;
  margin-top: 40px;
  margin-bottom: 24px;
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-weight: 650;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #000000;

  .link {
    :hover {
      text-decoration: underline;
    }
  }
`;
