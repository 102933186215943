import React from 'react';
import { Context } from '../../../functions/joshco/context';
import LoginForm from '../../../components/joshco/loginForm';

class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'joshco';
    document.title = 'JOSHCO Portal';
  }

  render() {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    return (
      <LoginForm setAuth={setAuth} subscriberId={subscriberId} />
    );
  }
}

export default Login;
