import { addressMapping } from '../constantsGlobal';

export const logFaultMapping = {
  callType: 'workType',
  subCallType: 'childWorkType',
  customerType: 'customLookupCode2',
  callNotes: 'description',
  meterNumber: 'custom2',
  accountNumber: 'custom4',
  contactPerson: 'contactName',
  contactNumber: 'contactNumber',
  contactEmail: 'contactEmail',
  ...addressMapping,
};

export const notificationsTableColumns = {
  jsonKeys: ['value', 'label'],
  headers: ['datetime', 'message'],
  headerNames: ['Date/Time', 'Message'],
  widths: [234, 800],
};

export const faultsTableColumns = {
  jsonKeys: [
    'code',
    'description',
    'workType',
    'status',
    'dateCreated',
    'contactName',
    'address',
  ],
  headers: [
    'code',
    'callNotes',
    'callType',
    'status',
    'dateCreated',
    'contactPerson',
    'address',
  ],
  headerNames: [
    'Code',
    'Call Notes',
    'Call Type',
    'Status',
    'Date Created',
    'Contact Person',
    'Address',
  ],
  widths: [120, 180, 140, 140, 170, 180, 214],
};

// TEST DATA

export const testCallTypes = [
  {
    label: 'No Supply',
    value: 'NS',
    flag: true,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'Dangerous situation',
    value: 'DSN',
    flag: false,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'Street Light',
    value: 'SL',
    flag: false,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'Smart Meter (Not billing related)',
    value: 'SM',
    flag: true,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'Meter Fault (Not billing related)',
    value: 'MNW',
    flag: true,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'Power Quality issue',
    value: 'PQ',
    flag: false,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'No hot water (where geyser load control unit installed)',
    value: 'NHW',
    flag: false,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
  {
    label: 'Substation Grass Cutting/Vegetation Clearing',
    value: 'ENGC',
    flag: false,
    flag2: false,
    value2: null,
    fieldHandler: null,
  },
];

export const testCustomerTypes = [
  {
    label: 'Prepaid', value: '27026', flag: false, flag2: false, value2: null,
  },
  {
    label: 'Domestic',
    value: '27028',
    flag: false,
    flag2: false,
    value2: null,
  },
  {
    label: 'Large Power User',
    value: '27029',
    flag: false,
    flag2: false,
    value2: null,
  },
];

export const testFaultsTable = [
  {
    id: 1908262,
    code: 'CPWEB25489769647',
    description: "No supply 8 duke's avenue windsor",
    workType: 'No Supply (Area)',
    childWorkType: null,
    status: 'Closed',
    dateCreated: 1572593312000,
    contactName: 'Patrick',
    address:
      '8, Dukes Avenue, Windsorororororr West, City of Johanneshanneshannesburg, 2194, South Africa',
    latitude: -26.12216,
    longitude: 27.96292,
    documentCount: 0,
  },
  {
    id: 1702555,
    code: 'CPWEB2452083',
    description: 'No power',
    workType: 'No Supply (Area)',
    childWorkType: null,
    status: 'Closed',
    dateCreated: 1555911357000,
    contactName: 'Peter',
    address:
      '8 The Governors , 423, West Avenue, Ferndale, Randburg, 2194, South Africa',
    latitude: -26.0811,
    longitude: 27.982650000000035,
    documentCount: 0,
  },
  {
    id: 1081644,
    code: 'CPWEB2267407',
    description: 'No Supplyyyyyyyyyyyyyyyyyyyyyy at The Governors complex',
    workType: 'No Supply (Area)',
    childWorkType: null,
    status: 'Closed longwordherefortestingoutthewrapping',
    dateCreated: 1523802770000,
    contactName: 'Peter',
    address:
      '8 The Governors , 423, West Avenue, Ferndale, Randburg, 2194, South Africa',
    latitude: -26.0811,
    longitude: 27.982650000000035,
    documentCount: 0,
  },
  {
    id: 270062,
    code: 'CPWEB2040134',
    description:
      'Street light section out on Rocky street, near intersection with Malibongwe',
    workType: 'Street Light',
    childWorkType: null,
    status: 'Closed',
    dateCreated: 1471238808000,
    contactName: 'Peter',
    address: 'Rocky Street, Ferndale, Randburg, 2194, South Africa',
    latitude: -26.07968399091986,
    longitude: 27.977233574838237,
    documentCount: 0,
  },
];

export const testNotificationsTable = [
  {
    label:
      'Dear Peter, your Joburg Water Web Fault Logging password reminder is: bull',
    value: '14-12-2020 13:23',
    attributes: {},
  },
  {
    label:
      "Peter, your password for username '0829051749' has been changed to '0829051749'",
    value: '01-12-2020 13:35',
    attributes: {},
  },
  {
    label:
      "Peter, your password for username '0829051749' has been reset to '1dtzfh5t'",
    value: '30-11-2020 14:44',
    attributes: {},
  },
  {
    label:
      'Dear Peter, your Joburg Water Web Fault Logging password reminder is: bull',
    value: '30-11-2020 14:44',
    attributes: {},
  },
  {
    label:
      "Peter, your password for username '0829051749' has been changed to '0829051749'",
    value: '24-11-2020 10:09',
    attributes: {},
  },
];

export const testProfile = {
  id: 187583,
  code: 'CT0175C',
  description: 'hj',
  contactPerson: 'Peter',
  mobileNumber: '0829051749',
  officeNumber: null,
  homeNumber: null,
  faxNumber: null,
  emailAddress: 'phellberg@gmail.com',
  standardDiscount: 0.0,
  paymentTermsDays: 30,
  username: null,
  taxRegNumber: null,
  joinDate: 1463113143000,
  notes: null,
  parentCode: null,
  parentId: null,
  statusCode: 'AC',
  typeCode: 'DOM',
  active: true,
  address: {
    description: 'Peter Hellberg',
    address1: null,
    address2: null,
    address3: null,
    address4: '3',
    address5: 'Church Street',
    address6: 'Johannesburg',
    address7: 'City of Johannesburg',
    address8: '2001',
    countryCode: 'ZA',
    latitude: 0.0,
    longitude: 0.0,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    routePoints: null,
    geomArea: 0.0,
  },
  orgUnitCode: null,
  priorityCode: null,
  user: {
    username: '0829051749',
    password: '89a04261d26bd1bb4830ed4544cc99428978c0f3',
    fullName: 'Peter',
    passwordHint: 'bulln',
    otp: '',
    custom1: '',
    custom2: null,
    custom3: null,
    custom4: null,
    custom5: null,
    employeeNumber: '',
    customDate1: null,
    customDate2: null,
    customDate3: null,
    customDate4: null,
    customDate5: null,
    termsAcceptedDate: null,
    email: 'phellberg@gmail.com',
    phoneNumber: null,
    mobileNumber: '0829051749',
    customerCode: null,
  },
  alertFlags: [],
  optOutSMSCategories: ['ON'],
  custom1: null,
  custom2: null,
  custom3: null,
  custom4: null,
  custom5: null,
  custom6: null,
  custom7: null,
  custom8: null,
  custom9: null,
  custom10: null,
  customNumber1: 0.0,
  customNumber2: 0.0,
  customNumber3: 0.0,
  customNumber4: 0.0,
  customNumber5: 0.0,
  customNumber6: 0.0,
  customNumber7: 0.0,
  customNumber8: 0.0,
  customNumber9: 0.0,
  customNumber10: 0.0,
  customLookupCode1: null,
  customLookupCode2: null,
  customLookupCode3: null,
  customLookupCode4: null,
  customLookupCode5: null,
  customLookupCode6: null,
  customLookupCode7: null,
  customLookupCode8: null,
  customLookupCode9: null,
  customLookupCode10: null,
  customDate1: null,
  customDate2: null,
  customDate3: null,
  customDate4: null,
  customDate5: null,
  customDate6: null,
  customDate7: null,
  customDate8: null,
  customDate9: null,
  customDate10: null,
  custom11: null,
  custom12: null,
  custom13: null,
  custom14: null,
  custom15: null,
  custom16: null,
  custom17: null,
  custom18: null,
  custom19: null,
  custom20: null,
  custom21: null,
  custom22: null,
  custom23: null,
  custom24: null,
  custom25: null,
  custom26: null,
  custom27: null,
  custom28: null,
  custom29: null,
  custom30: null,
  customTransient1: null,
  customTransient2: null,
  customTransient3: null,
  customTransient4: null,
  customTransient5: null,
  customTransient6: null,
  customTransient7: null,
  customTransient8: null,
  customTransient9: null,
  customTransient10: null,
  customMultipleChoice1: [],
  customMultipleChoice2: [],
  customMultipleChoice3: [],
  customMultipleChoice4: [],
  customMultipleChoice5: [],
  accountManager: null,
  customerType: 'DOM',
  customerStatus: 'AC',
  billingAddress: {
    description: 'Peter Hellberg',
    address1: null,
    address2: null,
    address3: null,
    address4: '3',
    address5: 'Church Street',
    address6: 'Johannesburg',
    address7: 'City of Johannesburg',
    address8: '2001',
    countryCode: 'ZA',
    latitude: 0.0,
    longitude: 0.0,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    routePoints: null,
    geomArea: 0.0,
  },
  assetTypeCode: 'DOM',
};

// Geographical centre of Mbombela, this is used to bias search results
export const geoBias = {
  lat: -26.521416,
  lng: 29.985428,
};
