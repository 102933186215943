import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

// import { customerData, workRequestData } from "../../constants/siemens/constants"; //dev
import { getPortalCustomerDetails, getWorkRequest } from '../../functions/apiCalls';
import { TicketForm } from '../../components/siemens/TicketForm';

/**
 * recursively delete all null keys in obj
 */
const deleteNull = (obj) => {
  const objecter = obj;
  Object.keys(obj).forEach((k) => {
    if (objecter[k] === null) { delete objecter[k]; } else if (typeof (objecter[k]) === 'object') { deleteNull(objecter[k]); }
  });
  return objecter;
};

export const TicketFormPage = ({ assetId }) => {
  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [defaults, setDefaults] = useState();

  useEffect(() => {
    document.title = 'Create Ticket | Siemens Portal';
    async function getData() {
      setIsLoading(true);

      // fetch customer data
      // TODO: unnecessary request if you get profile data from context
      const customerData = await getPortalCustomerDetails().then((res) => res.json());
      // const customerSubset = (({ address, code }) => ({ address, code }))(customerData);
      // pick relevant keys
      const { typeCode, ...customerSubset } = customerData;

      // fetch workrequest data, if a code is provided
      const wrData = code ? await getWorkRequest(code).then((res) => res.json()) : {};

      // delete keys that are null, because react-hook-form doesn't like null inputs
      const data = deleteNull({ ...customerSubset, ...wrData, assetId });

      setDefaults(data); // setDefaults(deleteNull(workRequestData)); //dev
      setIsLoading(false);
    }
    getData();
  }, []);

  return (
    <Container maxWidth="md" disableGutters>
      <Paper
        style={{ padding: '1rem' }}
        elevation={2}
      >
        <Typography variant="h4" style={{ textAlign: 'center', paddingBottom: '1rem' }}>
          {code ? `Edit Ticket (${code})` : 'Create Ticket'}
        </Typography>
        {isLoading
          && (
          <Backdrop style={{ zIndex: 10, color: '#fff' }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
          )}
        {defaults !== undefined
          ? <TicketForm defaults={defaults} />
          : null}
      </Paper>
    </Container>
  );
};
