import { apiUrlWithHttps } from '../../constants/constantsGlobal';

export const getIdByCode = (code) => fetch(`${apiUrlWithHttps}workorderrequestmanager/searchByCode?code=${code}`, {
  method: 'GET',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
  },
  credentials: 'include',
}).then((res) => {
  res.json();
}).catch((e) => {
  console.error(e);
  const errMsg = e.responseText != null && e.responseText !== ''
    ? e.responseText
    : 'unknown error, you are possibly not connected.';
  alert(`Error occurred loading the table: ${errMsg}`);
});

export const getTable = (code) => fetch(`${apiUrlWithHttps}custommanager_bto/getOpenWorkRequestsByParentType?parentTypeCode=${code}`, {
  method: 'GET',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
  },
  credentials: 'include',
}).then(
  (res) => res.json(),
).catch((e) => {
  console.error(e);
  return [];
});

export const getLabels = () => fetch(`${apiUrlWithHttps}subscribercustomlabelsmanager/getAllLabels`, {
  method: 'GET',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
  },
  credentials: 'include',
}).then(
  (res) => res.json(),
).catch((e) => {
  console.error(e);
  return [];
});

export const getRootItemByClassAndProp = (className, propertyName, id) => fetch(`${apiUrlWithHttps}customerportalmanager/findRootItemsByClassAndProperty?className=${className}&propertyName=${propertyName}&subscriberId=${'bto'}&currentItemId=${id}&queryString=`, {
  method: 'GET',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
  },
  credentials: 'include',
}).then((res) => res.json())
  .catch((e) => {
    console.error(e);
  });

export const getItemByClassAndProp = (className, propertyName) => fetch(
  `${apiUrlWithHttps}customerportalmanager/findCustomerPortalLabelValuesByClassAndPropertyDWR?className=${className}&propertyName=${propertyName}`,
  {
    method: 'GET',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch((e) => {
  console.error('Comms error:', e);
  return [];
});

export const getNotes = (id) => fetch(`${apiUrlWithHttps}customerportalmanager/getNotes?id=${id}`,
  {
    method: 'GET',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  }).then(
  (res) => res.json(),
).catch((e) => {
  console.error('Comms error:', e);
  return [];
});

export const getDocuments = (id) => fetch(
  `${apiUrlWithHttps}customerportalmanager/getWorkRequestAttachments?workRequestId=${id}`,
  {
    method: 'GET',
    async: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch(
  (e) => {
    console.error('Comms error:', e);
    return [];
  },
);

export const addNote = (id, noteTypeId, text) => fetch(`${apiUrlWithHttps}workorderrequestmanager/addNote?id=${id}&noteTypeId=${noteTypeId}&text=${text}`,
  {
    method: 'GET',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  }).then(
  (res) => res.json(),
).catch((e) => {
  console.error('Comms error:', e);
  return [];
});

export const uploadFile = (formData) => fetch(`${apiUrlWithHttps}documentsmanager/uploadDocument`,
  {
    method: 'POST',
    async: false,
    credentials: 'include',
    body: formData,
    processData: false,
    contentType: false,
  }).then(
  (res) => res.json(),
).catch((e) => {
  console.error('Comms error:', e);
  return [];
});

export const getPortalCustomerDetails = () => fetch(
  `${apiUrlWithHttps}customerportalmanager/getPortalCustomerDetails`,
  {
    method: 'GET',
    async: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch(
  (e) => {
    console.error('Comms error:', e);
    return [];
  },
);

export const byClassAndProp = async (className, propertyName) => fetch(
  `${apiUrlWithHttps}customerportalmanager/findRootItemsByClassAndProperty?className=${className}&propertyName=${propertyName}&subscriberId=${'bto'}&currentItemId=0&queryString=`,
  {
    method: 'GET',
    async: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch(
  (e) => {
    console.error('Comms error:', e);
    return [];
  },
);

export const getWorkRequest = async (code) => fetch(
  `${apiUrlWithHttps}customerportalmanager/getWorkRequest?code=${code}`,
  {
    method: 'GET',
    async: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch(
  (e) => {
    console.error('Comms error:', e);
    return [];
  },
);

export const postWorkRequest = async (workRequestObject) => fetch(
  `${apiUrlWithHttps}customerportalmanager/postWorkRequest`,
  {
    method: 'POST',
    async: true,
    body: workRequestObject,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res,
).catch(
  (e) => e,
);

export const findChildLabelValuesByClassAndPropertyAndCode = async (
  className, propertyName, code,
) => fetch(
  `${apiUrlWithHttps}customerportalmanager/findChildLabelValuesByClassAndPropertyAndCode?className=${className}&propertyName=${propertyName}&code=${code}`,
  {
    method: 'GET',
    async: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch(
  (e) => {
    console.error('Comms error:', e);
    return [];
  },
);

export const postWorkRequestMyAddress = async (workRequestObject) => fetch(
  `${apiUrlWithHttps}customerportalmanager/logCallMyAddress`,
  {
    method: 'POST',
    async: true,
    body: workRequestObject,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then(
  (res) => res.json(),
).catch(
  (e) => e,
);
