import { apiUrlWithHttps } from '../../constants/constantsGlobal';

export const getWorkRequest = (code) => fetch(
  `${apiUrlWithHttps}custommanager_myciti/getWorkRequest?code=${code}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      subscriberId: 'myciti',
    },
    credentials: 'include',
  },
).then((res) => res)
  .catch((e) => e);

export const postWorkRequest = (workRequestObject) => fetch(
  `${apiUrlWithHttps}custommanager_myciti/postWorkRequest`, {
    method: 'POST',
    async: true,
    body: JSON.stringify(workRequestObject),
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      subscriberId: 'myciti',
    },
  },
)
  .then((res) => res)
  .catch((e) => e);

export const getRootItemByClassAndProp = (className, propertyName, id) => fetch(
  `${apiUrlWithHttps}custommanager_myciti/findRootItemsByClassAndProperty?className=${className}&propertyName=${propertyName}&subscriberId=${'myciti'}&currentItemId=${id}&queryString=`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      subscriberId: 'myciti',
    },
    credentials: 'include',
  },
).then((res) => res.json())
  .catch((e) => {
    console.error(e);
  });

export const addWorkRequestAttachment = (formData, subscriberId) => fetch(`${apiUrlWithHttps}custommanager_myciti/addWorkRequestAttachment`, {
  method: 'POST',
  async: false,
  credentials: 'include',
  body: formData,
  processData: false,
  contentType: false,
  headers: {
    credentials: 'include',
    subscriberId,
  },
})
  .then((res) => res.json())
  .catch((e) => {
    console.error('Comms error:', e);
    return [];
  });

export const getStationsAndBusStops = () => fetch(`${apiUrlWithHttps}custommanager_myciti/getStationsAndBusStops`, {
  method: 'GET',
  credentials: 'include',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
    subscriberId: 'myciti',
  },
})
  .then((res) => res.json())
  .catch((e) => {
    console.error('Comms error:', e);
    return [];
  });
