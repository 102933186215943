import { apiUrl } from '../../constants/constantsGlobal';
import { fetchApi, standardError } from '../../functions/apiCalls';

export const convertRowsForTable = (apiRows, { jsonKeys, headers }) => apiRows.map((entry, id) => {
  const entryFormatted = { id };
  jsonKeys.forEach((key, j) => {
    const keyFormatted = headers[j];
    let val = entry[key];
    if (key === 'dateCreated') {
      val = new Date(val).toLocaleString('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
      });
    }
    entryFormatted[keyFormatted] = val;
  });
  return entryFormatted;
});

const getTable = async (endpoint, columns) => fetchApi(`${apiUrl}calltakemanager/${endpoint}`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .then((json) => convertRowsForTable(json, columns))
  .catch((e) => {
    standardError(e, 'Error occurred loading the table');
  });

export const getAllFaultsTable = async (columns) => getTable('getAllCustomerCalls', columns);

export const getOpenFaultsTable = async (columns) => getTable('getOpenCustomerCalls', columns);

export const getNotificationsTable = async (columns) => getTable('getCustomerNotifications', columns);

export const getColumns = (headers, headerNames, widths) => headers.reduce(
  (arr, val, i) => [
    ...arr,
    { field: val, headerName: headerNames[i], width: widths[i] },
  ],
  [],
);
