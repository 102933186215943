import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AssetForm } from '../../components/siemens/AssetForm';

export const ViewAssetPage = () => {
  const params = useParams();
  const { id } = params;
  console.log('id through params:', id);

  useEffect(() => {
    document.title = 'View Asset | Siemens Portal';
  }, []);

  return <AssetForm id={id} />;
};
