import React from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../functions/servest_it/context';
import * as login from '../../scripts/loginScripts';
import { LoginForm } from '../../components/servest_it/loginForm';

export class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'servest_it';
    document.title = 'Servest IT Portal';
  }

  onLogin = ({ username, password }, setStates) => {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    login.doLogin(username, password, setAuth, subscriberId, setStates);
  };

  render() {
    const { onLogin, subscriberId } = this;
    const subscriber = 'Servest IT';
    return (
      <Container fluid id="LoginForm">
        <LoginForm {...{ subscriber, onLogin, subscriberId }} />
      </Container>
    );
  }
}

// DID NOT WORK!

// /* Modules */
// import React, { useContext } from "react";
// import { useLocation } from 'react-router-dom';
// import { Container } from "react-bootstrap";
// /* Local */
// import { Context } from "../../functions/servest_it/context";
// import * as login from "../../scripts/loginScripts";
// import { LoginForm } from "../../components/servest_it/loginForm";

// //const useAuthContext = () => useContext(Context);

// export const Login = () => {

//   const { state } = useLocation();
//   const subscriberId = state.subscriberId;
//   const subscriberName = state.subscriberName || 'Servest';
//   const { setAuth } = useAuthContext;
//   console.log('sub id', subscriberId);
//   document.title = `${subscriberName} Portal`;

//   const onLogin = ({ username, password }, setStates) => {
//     login.doLogin(username, password, setAuth, subscriberId, setStates);
//   };

//   return(
//     <Container fluid id='LoginForm' style={{maxWidth: "85%"}}>
//       <LoginForm
//         subscriber={subscriberName}
//         onLogin={onLogin}
//         subscriberId={subscriberId}
//       />
//     </Container>
//   );
// };
