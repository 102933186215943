export default class EntriesUpdater {
  constructor(entries, requiredMissing, setStateEntries, setRequiredMissing) {
    this.entries = entries;
    this.requiredMissing = requiredMissing;
    this.setStateEntries = setStateEntries;
    this.setRequiredMissing = setRequiredMissing;
  }

  parentEntries = (entries) => {
    this.entries = entries;
  };

  parentRequiredMissing = (requiredMissing) => {
    this.requiredMissing = requiredMissing;
  };

  printOut = () => console.log(
    'entries:',
    this.entries,
    'requiredMissing:',
    this.requiredMissing,
  );

  setEntries = (name, val) => {
    const toUpdate = { ...this.entries };
    toUpdate[name] = val;
    this.setStateEntries(toUpdate);
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const toUpdate = { ...this.requiredMissing };
    toUpdate[name] = bool;
    this.setRequiredMissing(toUpdate);
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };

  initMultipleRequiredStates = (values) => {
    const newValues = {};
    values.forEach((name) => {
      if (this.requiredMissing[name] == null) {
        newValues[name] = false;
      }
    });
    if (Object.keys(newValues).length > 0) {
      const toUpdate = { ...newValues, ...this.requiredMissing };
      this.setRequiredMissing(toUpdate);
    }
  };
}
