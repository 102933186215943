import React, { useState, useEffect } from 'react';
import { Table } from '../../components/tables';
import {
  notificationsTableColumns,
} from '../../constants/enpower/constants';
import {
  getNotificationsTable,
  getColumns,
} from '../../scripts/enpower/tablesScripts';

export const Notifications = () => {
  const [rows, setRows] = useState(null);
  document.title = 'Notifications | Enpower Portal';
  const { headers, headerNames, widths } = notificationsTableColumns;
  const columns = getColumns(headers, headerNames, widths);
  const [loading, setLoading] = useState(true);

  const setRowsAsync = async () => {
    setRows(await getNotificationsTable(notificationsTableColumns));
    setLoading(false);
  };

  useEffect(() => {
    setRowsAsync();
  }, []);
  console.log('rows:', rows);

  return <Table title="Notifications" {...{ columns, rows, loading }} />;
};
