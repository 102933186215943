import React, { useState } from 'react';
import '../styles/ImageUploader.scss';

// Upload and view work request attachment images
const ImageViewerUploader = (props) => {
  const {
    attachmentImages = [],
    readOnly = false,
    images = [],
    setImages,
  } = props;

  const handleRemoveImage = (index) => {
    const tempImages = images.filter((img, i) => (i !== index) && img);
    setImages(tempImages);
  };

  return (
    <>
      {/* No attachment images on work request */}
      {(attachmentImages.length === 0 && readOnly) && <p>No photos found</p>}

      {/* Attachment images on work request */}
      {(attachmentImages.length > 0 && readOnly) && (
        <div className="imagesviewer-container">
          {images.map((src, index) => (
            <div
              tabIndex={0}
              role="button"
              className="imagesviewer-previewimage"
              style={{ background: `url("${src.externalURL}")`, flexBasis: '50%', flexShrink: '0' }}
              onClick={() => window.open(src.externalURL, '_blank')}
            >
              {!readOnly && (
              <div
                tabIndex={0}
                role="button"
                onClick={() => handleRemoveImage(index)}
              >
                <img
                  alt="Cancel"
                  src="/icons/clear-icon.svg"
                  className="imagesviewer-cancel-icon"
                />
              </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Selected images for upload */}
      {(images.length > 0 && !readOnly) && (
        <div className="imagesviewer-container">
          {images.map((src, index) => (
            <div style={{
              position: 'relative', width: '305px',
            }}
            >
              <div
                tabIndex={0}
                role="button"
                onClick={() => window.open(src.url, '_blank')}
                style={{ height: '100%', width: 'auto' }}
              >
                <img
                  alt="Preview"
                  src={src.url}
                  className="imagesviewer-previewimage"
                />
              </div>
              {!readOnly && (
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => handleRemoveImage(index)}
                >
                  <img
                    alt="Cancel"
                    src="/icons/clear-icon.svg"
                    className="imagesviewer-cancel-icon"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Empty state upload button */}
      {(images.length === 0 && !readOnly) && (
        <div className="imageupload-container">
          <img src="icons/photo-icon.svg" alt="camera" className="imageupload-camera-icon" />
          <div className="imageupload-text">Add photos relevant to your ticket</div>
          <Upload images={images} setImages={setImages} />
        </div>
      )}

      {/* Full state upload button */}
      {(images?.length > 0 && images.length < 3 && !readOnly) && (
        <Upload images={images} setImages={setImages} />
      )}
    </>
  );
};

const Upload = ({ images, setImages }) => {
  const [error, setError] = useState();

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setError('Only PNG, JPEG and GIF');
      return;
    }

    if (file.size > 10000000) {
      setError('Max 10MB');
      return;
    }

    setImages([
      ...images,
      {
        file,
        url: URL.createObjectURL(file),
      },
    ]);
  };

  if (images.length < 3) {
    return (
      <>
        <div className="imageupload-input-text">
          <input
            className="imageupload-input"
            type="file"
            accept="image/*"
            onChange={(e) => handleChange(e)}
          />
          {images.length === 0 ? 'Click to browse' : 'Add more photos'}
        </div>
        {error && <div className="imageupload-error">{error}</div>}
      </>
    );
  }
  return null;
};

export default ImageViewerUploader;
