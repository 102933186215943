import styled from 'styled-components';
import {
  Card,
  Modal,
} from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  text-align: center;
  z-index: 1000000001;
  width: 100vw;

  @media only screen and (min-width: 800px) {
    width: 100%;
  }

  .fadeIn{
    opacity:1;
  }
  
  .fadeOut{
    opacity:0;
  }

  .card-body{
    padding: 0px
  }

  .modal-content {
    border-radius: 10px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  h4 {
    font: normal normal 500 14px/19px Roboto;
  }
`;

export const ModalHeader = styled(Modal.Header)`
  border: none;
`;

export const CloseButton = styled.div`
  font: normal normal 500 14px/21px Roboto;
  color: #FFFFFF;
  background: #03427d;
  border-radius: 4px;
  margin: 10px 30px;
  margin-bottom: 30px;
  padding: 15px;
  cursor: pointer;
`;

export const ModalBody = styled(Modal.Body)`
  max-height: 80vh;
  overflow: auto;
  padding: 0px 30px;
  @media (max-width: 768px) { 
    padding: 0px 0px;
  }
`;

export const FormCard = styled(Card)`
  text-align: center;
  border-radius: 1rem;
  padding: 0px 30px;
  border: 0px solid rgba(0,0,0,.125);
`;

export const CardHeading = styled(Card.Subtitle)`
  padding: 10px;
  padding-top: 0px;
  font: normal normal 500 25px/33px Roboto;
  color: #333333;

  @media only screen and (min-width: 600px) {
    font: normal normal 500 30px/39px Roboto;
  }
`;
