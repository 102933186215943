import React from 'react';
import '../style.scss';
import HomePage from '../../../components/myciti/homePage';

export const Home = () => {
  document.title = 'Home | MyCiTi Portal';
  return <HomePage />;
};

export default Home;
