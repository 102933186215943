import React from 'react';
import {
  BlueSectionTitle,
} from '../forms.styled';

const BlueHeader = (props) => {
  const { title } = props;

  return (
    <BlueSectionTitle>
      {title}
    </BlueSectionTitle>
  );
};

export default BlueHeader;
