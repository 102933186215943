import { newProfileMapping } from '../../constants/constantsGlobal';
import * as profile from '../profileScripts';
import { doLogin } from '../loginScripts';

const subscriberId = 'enpower_gsdm';

export const getProfile = () => profile.getProfile(newProfileMapping);

export const updateProfile = (
  entries, setState,
) => profile.updateProfile(entries, setState, newProfileMapping);

export const register = (entries, setState, setAuth, withOTP = false) => {
  const { mobileNumber, password } = entries;
  return profile.register(
    entries,
    setState,
    subscriberId,
    newProfileMapping,
    () => doLogin(mobileNumber, password, setAuth, subscriberId),
    withOTP,
  );
};
