import React from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../functions/joburg_water/context';
import { LoginAlert } from '../../components/joburg_water/header';
import * as login from '../../scripts/loginScripts';
import { LoginForm } from '../../components/joburg_water/login';

export class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'joburg_water';
  }

  onLogin = (state, setStates) => {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    const { username, password } = state;
    login.doLogin(username, password, setAuth, subscriberId, setStates);
  };

  render() {
    const { onLogin, subscriberId } = this;
    const subscriber = 'Joburg Water';
    return (
      <Container fluid id="LoginForm">
        <div id="loginAlertContainer">
          <LoginAlert />
        </div>
        <LoginForm {...{ subscriber, onLogin, subscriberId }} />
        <div />
      </Container>
    );
  }
}
