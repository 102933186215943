import React from 'react';
import { Cookies } from 'react-cookie';

export const Context = React.createContext();

export class ContextManager extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
  }

  render() {
    this.cookies.set('subscriberId', 'stats_sa', { path: '/stats_sa' });

    const {
      props: { children },
      cookies,
      setAuth,
      logout,
      setCallTypes,
      setCustomerTypes,
    } = this;

    return (
      <Context.Provider
        value={{
          setAuth,
          logout,
          setCallTypes,
          setCustomerTypes,
          cookies,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
