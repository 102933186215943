import React from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Container,
  Accordion,
  Table,
} from 'react-bootstrap';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import '../../styles/botswanatourism/home.scss';
import lottie from 'lottie-web';
import {
  getLabels,
  getNotes,
  getDocuments,
  addNote,
  uploadFile,
  postWorkRequest,
  getWorkRequest,
  findChildLabelValuesByClassAndPropertyAndCode,
  postWorkRequestMyAddress,
} from '../../scripts/botswanatourism/scripts';
import { Context } from '../../functions/botswanatourism/context';

export class QueryForm extends React.Component {
  static contextType = Context;

  animate = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      newForm: true,
      newNote: false,
      newAlert: false,
      alertMessage: 'Place holder',
      newDocument: false,
      noteArray: [],
      documentArray: [],
      readyToRedirect: false,
      redirectTo: '',
      // Labels for the form
      workRequestObjectLabel: {
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        description: '',
        workType: '',
      },
      workRequestObjectData: {
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        description: '',
        workType: '',
      },
    };

    // Uncontrolled Inputes or References
    this.contactNameRef = React.createRef();
    this.contactNumberRef = React.createRef();
    this.contactEmailRef = React.createRef();
    this.workTypeRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.newFileNameRef = React.createRef();
    this.noteTextFieldRef = React.createRef();
  }

  componentDidMount = async () => {
    const {
      code,
    } = this.props;
    const {
      workRequestObjectData,
    } = this.state;

    // Loading
    lottie.loadAnimation({
      container: this.animate,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'botswanatourism/paw.json',
    });

    // Need this to get the field names
    const labels = await getLabels();
    this.setState({
      workRequestObjectLabel: {
        contactName: labels['WorkOrderRequest.contactName'],
        contactNumber: labels['WorkOrderRequest.contactNumber'],
        contactEmail: labels['WorkOrderRequest.contactEmail'],
        description: labels['WorkOrderRequest.description'],
        workRequestType: labels['WorkOrderRequest.workRequestType'],
      },
    });

    const workRequestObject = await getWorkRequest(code);
    if (workRequestObject.length !== 0) {
      // Set the state var
      this.setState({ workRequestObjectData: workRequestObject });
      // You are now editing an query
      this.setState({ newForm: false });

      // Load the correct information for the drop down
      const workRequestTypes = await findChildLabelValuesByClassAndPropertyAndCode('WorkOrderRequest', 'workRequestType', 'CQ');
      console.log('Drop down', workRequestTypes);
      this.setState({ workRequestTypes });

      console.log('Notes');
      const notess = await getNotes(workRequestObject.id);
      console.log(notess);
      this.setState({ noteArray: notess });

      console.log('Documents');
      const docs = await getDocuments(workRequestObject.id);
      console.log(docs);
      this.setState({ documentArray: docs });
      this.setState({ loading: false });
      console.log('workRequestObjectData', workRequestObjectData);
    } else {
      // You are creating a new query
      // Load the correct information for the drop down
      const workRequestTypes = await findChildLabelValuesByClassAndPropertyAndCode('WorkOrderRequest', 'workRequestType', 'CQ');
      this.setState({ workRequestTypes });
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      newForm,
      newNote,
      newDocument,
      newAlert,
      redirectTo,
      readyToRedirect,
      loading,
      workRequestObjectData,
      workRequestObjectLabel,
      workRequestTypes,
      alertMessage,
      noteArray,
      noteTextFieldRef,
      noteText,
      documentArray,
    } = this.state;
    const {
      code,
    } = this.props;
    if (readyToRedirect && redirectTo !== '') {
      return <Redirect to={redirectTo} />;
    } if (readyToRedirect) {
      return <Redirect to="/queries" />;
    }

    const renderLoadingOverlay = () => {
      if (loading) {
        return (
          <div id="LoadingOverlayContainer">
            <div id="LoadingOverlay" />
            <div
              ref={(ref) => {
                this.animate = ref;
              }}
              id="LoadingOverlayImage"
            />
          </div>
        );
      }
      return null;
    };

    const saveNewQuery = () => {
      this.setState({ loading: true });
      // Load them into work request object
      const workRequestObject = {};
      workRequestObject.id = '';
      // workRequestObject.addressid = '';
      workRequestObject.contactEmail = this.contactEmailRef.current.value;
      workRequestObject.contactName = this.contactNameRef.current.value;
      workRequestObject.contactNumber = this.contactNumberRef.current.value;
      workRequestObject.description = this.descriptionRef.current.value;
      workRequestObject.workType = this.workTypeRef.current.value;
      // Yeet it
      postWorkRequestMyAddress(JSON.stringify(workRequestObject)).then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS') {
          const temp = `/queryform/${res.code}`;
          this.setState({ readyToRedirect: true, redirectTo: temp });
        } else {
          this.setState({ alertMessage: res.errorMessage });
          this.setState({ newAlert: true });
          this.setState({ loading: false });
        }
      });
    };

    const saveExistingQuery = () => {
      this.setState({ loading: true });
      // Load them into work request object
      // "DEEP COPY"
      const workRequestObject = JSON.parse(JSON.stringify(workRequestObjectData));
      workRequestObject.contactEmail = this.contactEmailRef.current.value;
      workRequestObject.contactName = this.contactNameRef.current.value;
      workRequestObject.contactNumber = this.contactNumberRef.current.value;
      workRequestObject.description = this.descriptionRef.current.value;
      workRequestObject.workType = this.workTypeRef.current.value;

      // Yeet it
      postWorkRequest(JSON.stringify(workRequestObject)).then((res) => res.json()).then((data) => {
        console.log(data);
        if (data.result === 'SUCCESS') {
          const temp = '/queries';
          this.setState({ readyToRedirect: true, redirectTo: temp });
        } else {
          this.setState({ alertMessage: JSON.stringify(data) });
          this.setState({ newAlert: true });
          this.setState({ loading: false });
          getWorkRequest(code).then((resp) => {
            this.setState({ workRequestObjectData: resp });
            document.forms.form.reset();
          });
        }
      });
    };

    const toggleNote = () => {
      if (newNote) {
        this.setState({ noteText: '' });
        this.setState({ newNote: false });
      } else {
        this.setState({ newNote: true });
      }
    };

    const saveNote = () => {
      // Saves the note
      addNote(workRequestObjectData.id, 1202, this.noteTextFieldRef.current.value).then(
        () => getNotes(workRequestObjectData.id).then(
          (data) => this.setState({ noteArray: data }),
        ),
      );
      // Close the add note section
      toggleNote();
    };

    const toggleDocument = () => {
      if (newDocument) {
        this.setState({ newDocument: false });
      } else {
        this.setState({ newDocument: true });
      }
    };
    const saveDocument = () => {
      // Create the form
      const formData = new FormData();
      const fileInput = document.querySelector('#fileInput');
      console.log(fileInput);
      const { type } = fileInput.files[0];
      console.log(type);
      let filenamef = this.newFileNameRef.current.value;
      if (filenamef === '') {
        console.log('Defaulting to filename as none given');
        filenamef = fileInput.files[0].name;
      }
      formData.append('className', 'WorkOrderRequest');
      formData.append('dictionaryTable.className', 'WorkOrderRequest');
      formData.append('recordId', workRequestObjectData.id);
      formData.append('id', '');
      formData.append('documentType', '');
      formData.append('filename', filenamef);
      formData.append('uploadfile', fileInput.files[0], filenamef);
      uploadFile(formData).then(
        () => getDocuments(workRequestObjectData.id).then(
          (data) => this.setState({ documentArray: data }),
        ),
      );
      toggleDocument();
    };
    const renderNewFormHeadings = () => {
      if (newForm) {
        return <h3>Create new query</h3>;
      }
      return (
        <h3>
          Editing query:
          {code}
        </h3>
      );
    };
    const renderNewFormButtons = () => {
      if (newForm) {
        return (
          <div>
            <Button variant="primary" style={{ width: '150px', float: 'right' }} onClick={saveNewQuery}>
              Create
            </Button>
            {' '}
            <Link to="/queries">
              <Button variant="secondary" style={{ width: '150px' }}>
                Cancel
              </Button>
              {' '}
            </Link>
          </div>
        );
      }
      return (
        <div>
          <Button variant="primary" style={{ width: '150px', float: 'right' }} onClick={saveExistingQuery}>
            Save
          </Button>
          {' '}
          <Link to="/queries">
            <Button variant="secondary" style={{ width: '150px' }}>
              Cancel
            </Button>
            {' '}
          </Link>
        </div>
      );
    };

    const renderNewNote = () => {
      if (newNote) {
        return (
          <>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Add your new note here:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={noteText}
                ref={noteTextFieldRef}
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={saveNote}>
              Save your note
            </Button>
            {' '}
            <Button variant="primary" type="submit" onClick={toggleNote}>
              Cancel
            </Button>
            {' '}
          </>
        );
      }
      return null;
    };

    const renderNewDocument = () => {
      if (newDocument) {
        return (
          <>
            <hr />
            <Form id="newDocumentName">
              <Form.Control type="text" ref={this.newFileNameRef} placeholder="New file name (leave blank for current file name)" />
            </Form>
            <br />
            <Form id="newDocument">
              <Form.File
                id="fileInput"
                name="uploadfile"
              />
            </Form>
            <br />
            <Button variant="primary" type="submit" onClick={saveDocument}>
              Upload and save your document
            </Button>
            {' '}
            <Button variant="primary" type="submit" onClick={toggleDocument}>
              Cancel
            </Button>
            {' '}
          </>
        );
      }
      return null;
    };

    const renderNotesAndDocuments = () => {
      if (newForm) {
        return (
          <Row>
            <Col md={12}>
              To add notes and documents first create the query...
            </Col>
            <br />
          </Row>
        );
      }
      return (
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Notes
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Note type</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {noteArray.map((note, key) => (
                      <tr key={key}>
                        <td>{note.id}</td>
                        <td>{moment(note.timestamp).format('Do MMMM YYYY')}</td>
                        <td>{note.noteTypeDescription}</td>
                        <td style={{ wordWrap: 'anywhere' }}>{note.noteText}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {renderNewNote()}
                {!newNote
                  ? (
                    <>
                      <Button variant="primary" onClick={toggleNote}>
                        Add a note
                      </Button>
                    </>
                  )
                  : null}

              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Documents
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name and link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentArray.map((document, key) => (
                      <tr key={key}>
                        <td>{document.changeDate}</td>
                        <td>
                          <a href={document.url}>
                            {' '}
                            {document.filename}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {renderNewDocument()}
                {!newDocument
                  ? (
                    <>
                      <Button variant="primary" onClick={toggleDocument}>
                        Add a new document
                      </Button>
                    </>
                  )
                  : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    };

    const renderAlert = () => {
      if (newAlert) {
        return (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>Holy guacamole!</strong>
            {' '}
            {alertMessage}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
      }
      return null;
    };

    return (
      <Container>
        {renderLoadingOverlay()}
        {renderAlert()}
        <form id="form">
          {renderNewFormHeadings()}
          <hr />
          <Row className="formInputs">
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.contactName}</Form.Label>
              </Form.Group>
              <Form.Control type="text" ref={this.contactNameRef} defaultValue={workRequestObjectData.contactName} />
            </Col>
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.contactNumber}</Form.Label>
              </Form.Group>
              <Form.Control type="text" ref={this.contactNumberRef} defaultValue={workRequestObjectData.contactNumber} />
            </Col>
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.contactEmail}</Form.Label>
              </Form.Group>
              <Form.Control type="text" ref={this.contactEmailRef} defaultValue={workRequestObjectData.contactEmail} />
            </Col>

            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label>{workRequestObjectLabel.workRequestType}</Form.Label>
              </Form.Group>
              {workRequestTypes && (
              <Form.Control
                name="workRequestType"
                type="text"
                as="select"
                ref={this.workTypeRef}
                disabled={!newForm}
              >
                {workRequestTypes.map((type) => {
                  if (workRequestObjectData.workType.includes(type.value)) {
                    return (
                      <option value={type.value} key={type.value} selected>
                        {' '}
                        {type.label}
                        {' '}
                      </option>
                    );
                  }
                  return (
                    <option value={type.value} key={type.value}>
                      {' '}
                      {type.label}
                      {' '}
                    </option>
                  );
                })}
              </Form.Control>
              )}
            </Col>
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>{workRequestObjectLabel.description}</Form.Label>
                <Form.Control as="textarea" rows={3} ref={this.descriptionRef} defaultValue={workRequestObjectData.description} />
              </Form.Group>
            </Col>
          </Row>

          {renderNotesAndDocuments()}
          <br />
          {renderNewFormButtons()}
        </form>
      </Container>
    );
  }
}
