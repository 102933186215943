import React from 'react';
import {
  Row, Container, Button,
} from 'react-bootstrap';
import { DataGrid } from '@material-ui/data-grid';
import { Link, Redirect } from 'react-router-dom';
import { Context } from '../../functions/botswanatourism/context';
import '../../styles/botswanatourism/home.scss';
import { getTable, getLabels } from '../../scripts/botswanatourism/scripts';

export class Applications extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [],
      loading: true,
      data: [],
      readyToRedirect: false,
      redirectTo: '',
    };
  }

  // When the components mounts we need to populate the table
  componentDidMount = async () => {
    let {
      columns, data,
    } = this.state;
    const getColumns = (headers, headerNames, widths) => headers.reduce(
      (arr, val, i) => [
        ...arr,
        { field: val, headerName: headerNames[i], width: widths[i] },
      ],
      [],
    );

    const table = await getTable('AP');
    const labels = await getLabels();

    data = table;

    if (data.length === 0) {
      // If there is no information table
      console.log('Empty table');
      data = [{
        '.': 'No data available in table',
      }];
    }
    // If there is information in the table
    const tableClass = 'WorkOrderRequest';
    // Get the columns
    const columnNames = [];
    Object.keys(data[0]).forEach((key) => {
      if (labels[`${tableClass}.${key}`]) {
        columnNames.push(labels[`${tableClass}.${key}`]);
      } else {
        // If there is no label it gets the key name.
        columnNames.push(key[0].toUpperCase() + key.substring(1));
      }
    });
    // Col width calc
    const width = {};
    const maxWidth = 300;
    const widthMultiplier = 10;
    Object.keys(data[0]).forEach((key) => {
      width[key] = key.length * widthMultiplier;
    });
    for (let i = 0; i < data.length; i++) {
      Object.keys(data[i]).forEach((key) => {
        // Replace all "null" with ""
        if (data[i][key] === 'null') {
          data[i][key] = '';
        }
        if (data[i][key] && data[i][key].length * widthMultiplier > width[key]) {
          if (data[i][key].length * widthMultiplier > maxWidth) {
            width[key] = maxWidth;
          } else {
            width[key] = data[i][key].length * widthMultiplier;
          }
        }
      });
    }
    const arrWidth = Object.values(width);
    columns = {
      jsonKeys: Object.keys(data[0]),
      headers: Object.keys(data[0]),
      headerNames: columnNames,
      widths: arrWidth,
    };

    const { jsonKeys, headers } = columns;
    // Map the table correctly
    const tableFormatted = data.map((entry, i) => {
      const entryFormatted = { id: i };
      jsonKeys.forEach((key, j) => {
        const keyFormatted = headers[j];
        entryFormatted[keyFormatted] = entry[key];
      });
      return entryFormatted;
    });

    const { headerNames, widths } = columns;
    const colcol = getColumns(headers, headerNames, widths);
    Object.keys(colcol).forEach((c) => {
      if (colcol[c].field === 'code') {
        colcol[c].renderCell = (val) => <a href={`botswanatourism/applicationform/${val.value}`}>{val.value}</a>;
      }
    });
    this.setState({ rows: tableFormatted, columns: colcol, loading: false });
  }

  render() {
    const {
      rows,
      columns,
      loading,
      readyToRedirect,
      redirectTo,
    } = this.state;
    if (readyToRedirect && redirectTo !== '') {
      return <Redirect to={redirectTo} />;
    }
    return (
      <Container>
        <Row style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '10px' }}>
          <div style={{ minHeight: 500, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooter
              rowHeight={40}
              headerHeight={40}
              loading={loading}
              // onRowClick={rowData => Example('/sample'+ rowData.getValue("code"))}
            />
          </div>
        </Row>
        <div style={{ height: '20px' }}>
          <Link to="/applicationform">
            <Button variant="primary" style={{ float: 'right', width: '150px' }}>
              Add new
            </Button>
            {' '}
          </Link>
        </div>
      </Container>
    );
  }
}
