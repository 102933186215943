import { apiUrl, apiUrlFunc } from '../constants/constantsGlobal';
import {
  fetchApi,
  standardError,
  getPortalCustomerDetails,
} from '../functions/apiCalls';
import { getData, getEntries } from '../functions/forms';

export const register = (
  entries,
  setState,
  subscriberId,
  mapping,
  doLogin,
) => {
  const data = getData(entries, mapping);
  const body = JSON.stringify(data);
  const msgEnums = {
    ALREADY_REGISTERED: 'This username is already registered. If you need to reset your password, click on \'reset password\' on the login screen.',
    OTP_ERROR: 'Your OTP was incorrect. Another one has been sent.',
    SUCCESS: 'Your registration was successful.',
  };
  return fetchApi(
    `${apiUrlFunc()}customermanager/registerPortalCustomer?subscriberId=${subscriberId}`,
    'POST',
    { body },
  )
    .then(async (res) => {
      console.log('status:', res.status);
      if (res.status === 500) {
        const text = await res.text().then((txt) => txt);
        throw new Error(text);
      } else {
        return res.json().then((json) => {
          if (!res.ok) {
            throw new Error(json.error);
          }
          const { result } = json;
          console.log(json);
          if (msgEnums[result]) {
            console.log(msgEnums[result]);
            alert(msgEnums[result]);
          }
          if (result === 'ALREADY_REGISTERED') {
            setState({
              submitting: false,
            });
            //  alert(
            //  `This username is already registered. If you need to reset your password,
            //  click on 'reset password' on the login screen.`
            //  );
            //  return;
          }
          if (result === 'OTP_REQUIRED') {
            //  console.log("OTP is required");
            //  if (withOTP) {
            //  alert("Your OTP was incorrect. Another one has been sent.");
            //  }
            //  return true; //show OTP modal
          }
          if (result === 'OTP_ERROR') {
            //  console.log("OTP was incorrect");
            //  alert("Your OTP was incorrect. Another one has been sent.");
            //  return true; //show OTP modal
          }
          if (result === 'SUCCESS') {
            //  console.log("Successful login");
            //  alert("Your registration was successful.");
            setState({ submitting: false });
            doLogin(json);
            // return;
          }
          return json;
        });
      }
    })
    .catch((e) => {
      setState({
        submitting: false,
      });
      standardError(e, 'Error completing your registration');
    });
};

export const updateProfile = (entries, setState, mapping) => {
  const data = getData(entries, mapping);
  const body = JSON.stringify(data);
  return fetchApi(
    `${apiUrl}customermanager/updatePortalCustomerDetails`,
    'POST',
    { body },
  )
    .then((res) => res.json())
    .then((json) => {
      setState({
        submitting: false,
      });
      if (json.result === 'SUCCESS') {
        alert('Successfully updated your profile');
        return true;
      }
      throw new Error(json.errorMessage);
    })
    .catch((e) => {
      standardError(e, 'Error updating your profile');
      setState({
        submitting: false,
      });
    });
};

export const getProfile = (mapping) => getPortalCustomerDetails()
  .then((res) => res.json())
  .then((json) => {
    console.log('get profile:', json);
    return getEntries(json, mapping);
  })
  .catch((e) => standardError(e, 'Error fetching your profile'));
