import { apiUrl } from '../../constants/constantsGlobal';
import {
  createTicketMapping,
  editTicketMapping,
  submitEditTicketMapping,
} from '../../constants/syntell/constants';
import {
  fetchApi,
  standardError,
  getPortalCustomerDetails,
  getWorkRequestTypes,
  getWorkRequestSubtypes,
  getWorkRequestStatus,
  getWorkRequestNextStatuses,
  byClassAndProp,
} from '../../functions/apiCalls';
import { getData, getEntries } from '../../functions/forms';

// const subscriberId = "syn_mtm";

// DEV (TODO: delete) >>>
// if (window.location.origin === "http://localhost:3000") {
//   console.log("You are on localhost and as such, test data will be used");
//   return new Promise((resolve, reject) =>
//     resolve(getEntries(testWorkRequest, createTicketMapping))
//   );
// } else {
// <<<
// } //DEV (TODO: delete)

export const getWorkRequest = (code) => fetchApi(
  `${apiUrl}customerportalmanager/getWorkRequest?code=${code}`,
  'GET',
  {
    credentials: 'include',
  },
)
  .then((res) => res.json())
  .then((json) => getEntries(json, editTicketMapping))
  .catch(standardError);

// DEV (TODO: delete) >>>
// if (window.location.origin === "http://localhost:3000") {
//   console.log("You are on localhost and as such, test data will be used");
//   return new Promise((resolve, reject) =>
//     resolve(getEntries(testProfile, createTicketMapping))
//   );
// } else {
// <<<
// } //DEV (TODO: delete)

export const getTicketDetails = () => getPortalCustomerDetails()
  .then((res) => res.json())
  .then((json) => getEntries(json, createTicketMapping))
  .catch(standardError);

export const getTicketCategories = () => getWorkRequestTypes()
  .then((res) => res.json())
  .catch(standardError);

export const getTicketSubcategories = (parentCode) => getWorkRequestSubtypes(parentCode)
  .then((res) => res.json())
  .catch(standardError);

export const getImpactTypes = () => byClassAndProp('WorkOrderRequest', 'customLookup1')
  .then((res) => res.json())
  .catch(standardError);

export const getUrgencyTypes = () => byClassAndProp('WorkOrderRequest', 'customLookup2')
  .then((res) => res.json())
  .catch(standardError);

export const createTicket = (oldEntries, code, history, setState) => {
  const entries = { ...oldEntries };
  console.log('entries:', entries);
  //  if (entries.subcategory !== "") {
  //    entries.category = entries.subcategory;
  //  subcategory should replace category
  //  which will then have the json key workType in the api call body
  //  }
  //  delete entries.subcategory;
  delete entries.division;
  delete entries.branch;

  let mapping;
  if (code) mapping = submitEditTicketMapping;
  else mapping = editTicketMapping;
  const data = getData(entries, mapping);
  if (code) data.address.country = 1;
  data.code = code;
  data.docType = 'WorkOrderRequest';
  console.log('data:', data);
  return fetchApi(`${apiUrl}customerportalmanager/postWorkRequest`, 'POST', {
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((json) => {
      const { cde } = json;
      setState({ submitDone: true });
      history.push('/home');
      alert(`Successfully submitted.${cde ? ` Code: ${cde}` : ''}`);
    })
    .catch(standardError);
};

export const getAllStatuses = async (id) => {
  const currentStatus = await getWorkRequestStatus(id);
  const nextStatuses = await getWorkRequestNextStatuses(
    currentStatus.value,
    id,
  );
  return [currentStatus, ...nextStatuses];
};
