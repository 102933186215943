import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Paper,
  Typography,
  Container,
} from '@material-ui/core';

import { getAssetTreeNodes } from '../../scripts/siemens/assetsScripts';

export const AssetTreeView = ({ id }) => {
  const [assets, setAssets] = useState([]);

  const renderTree = (nodes) => {
    console.log('nodes', nodes);
    console.log(nodes.objectId);
    console.log(nodes.text);
    console.log(nodes.children);

    return (
      <TreeItem
        key={nodes.objectId}
        nodeId={nodes.objectId}
        label={nodes.text}
      >
        {(Array.isArray(nodes.children) && nodes.children.length)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    (async () => {
      const data = await getAssetTreeNodes(id);
      console.log('assets', data);
      setAssets(data);
    })();
  }, []);

  if (assets.length) {
    return (
      <TreeView
        style={{
          flexGrow: 1,
          height: '100%',
          width: '100vw',
        }}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={[id]}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {assets.map((node) => renderTree(node))}
      </TreeView>
    );
  }
  return <Typography>No data</Typography>;
};

export const AssetTreePage = () => {
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    document.title = 'Asset Tree | Siemens Portal';
  }, []);

  return (
    <Container>
      <Paper
        style={{
          height: '50vh',
          padding: '1rem',
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginBottom: '1rem',
          }}
          variant="h5"
        >
          Asset Tree:
          {' '}
          {id}
        </Typography>
        {id
          ? <AssetTreeView id={id} />
          : null}
      </Paper>
    </Container>
  );
};
