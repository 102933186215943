import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Card, Image } from 'semantic-ui-react';
import '../../styles/servest_it/home.scss';

export const CustomerSchemaSelect = () => (
  <Container>
    <Row xs={1} className="iconRow">
      {[
        [
          'https://customer.forcelink.net/servest_is',
          'integrated-solutions.png',
          'Integrated Solutions – Help Desk',
          'servest_is',
          'Personalised integrated facilities management solutions across multiple sectors.',
        ],
        [
          'https://customer.forcelink.net/servest_it',
          'it.jpg',
          'IT',
          'servest_it',
          'Enables users to log in, create and view IT related calls against the Systems, Technical and Mobile IT Support divisions.',
        ],
      ].map((val) => (
        <div key={val[2]} style={{ padding: '5px', maxWidth: '22rem', minHeight: '340px' }}>
          <Card className="hoverCard" link href={val[0]} style={{ background: '#ffffff', minHeight: '340px' }}>
            <Image src={`servest_it/${val[1]}`} wrapped ui={false} style={{ width: '100%', margin: 'auto' }} />
            <Card.Content>
              <Card.Header style={{ color: 'black' }}>{val[2]}</Card.Header>
              <Card.Description>{val[4]}</Card.Description>
            </Card.Content>
          </Card>
        </div>
      ))}
    </Row>
  </Container>
);
