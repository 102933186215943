import {
  apiBaseUrl,
  apiUrl,
  newProfileMapping,
} from '../../constants/constantsGlobal';
import { servestProfileMapping } from '../../constants/servest_it/constants';
import { fetchApi, byClassAndProp } from '../../functions/apiCalls';
import * as profile from '../profileScripts';

const subscriberId = 'servest_it';

export const getDivision = async () => byClassAndProp('Customer', 'customLookup3')
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getBranch = async () => byClassAndProp('Customer', 'customLookup4')
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const lookupDivisionDesc = async (code) => {
  const divisionTypes = (await getDivision()) || [];
  const division = divisionTypes.find((x) => x.value === code);
  if (division) {
    return division.label;
  }
  return code;
};

export const lookupBranchDesc = async (code) => {
  const branchTypes = (await getBranch()) || [];
  const branch = branchTypes.find((x) => x.value === code);
  if (branch) {
    return branch.label;
  }
  return code;
};

export const checkUsernameAvailability = (username) => fetchApi(
  `${apiUrl}usermanager/userExists?username=${username}`,
  'GET',
).then((res) => res.json());

export const postCustomerDetails = ({ name, mobileNumber, emailAddress }) => {
  const body = JSON.stringify({
    register: true,
    save: true,
    subscriberId,
    description: name,
    mobileNumber,
    emailAddress,
  });
  fetchApi(
    `${apiBaseUrl}customportal/servest_it/customerdetails.html`,
    'POST',
    { body },
  ).then((res) => res.json());
};

export const updateProfile = (entries, setState) => {
  const newEntries = entries;
  newEntries.contactPerson = entries.name;
  return profile.updateProfile(newEntries, setState, servestProfileMapping);
};

export const getProfile = () => profile.getProfile(servestProfileMapping);

export const register = (
  entries,
  setState,
  continueRegistration,
  withOTP = false,
) => profile.register(
  entries,
  setState,
  subscriberId,
  newProfileMapping,
  continueRegistration,
  withOTP,
);
