import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Modal,
} from 'react-bootstrap';
import { Link, Switch } from 'react-router-dom';
import '../../styles/headerandfooter.scss';
import logo from '../../img/forcelink/powered_by_forcelink.png';

export const LogoHeader = (props) => {
  const {
    title,
    imgSrc,
    leftNav,
    rightNav,
    titleStyle,
    ...rest
  } = props;
  return (
    <Container fluid id="headerContainer" {...rest}>
      <Row>
        <Col xs={5} md={2} id="headerLogoCol">
          <Link to="/">
            <img src={imgSrc} className="App-logo" alt={`${title} Logo`} />
          </Link>
          {leftNav}
        </Col>
        <Col xs={7} md={8} id="headerTitleCol">
          <p id="headerTitle" style={titleStyle}>
            {title}
          </p>
        </Col>
        <Col xs={2} md={2}>
          {rightNav}
        </Col>
      </Row>
    </Container>
  );
};

export const ForcelinkFooter = (props) => (
  <Navbar id="forcelinkFooter" {...props} style={{ marginTop: 'auto', marginLeft: '10%', marginRight: '10%' }}>
    <Col xs={12} style={{ paddingLeft: '5vw', paddingRight: '5vw' }} />
    <Col xs={9} style={{ padding: '0px' }}>
      <div style={{ padding: '15px', color: 'white' }}>
        If you require any further assistance,
        <br />
        please contact Mikros Traffic Management (Pty) Ltd on:
        <br />
        Email: mtmsupport@syntell.co.za
      </div>
    </Col>
    <Col xs={3} style={{ padding: '10px' }}>
      <img href="https://www.forcelink.net" src={logo} style={{ maxWidth: '80%', maxHeight: '80%', float: 'right' }} alt="Powered by Forcelink" />
    </Col>
  </Navbar>
);

export const LogoHeaderWithNav = ({
  history,
  logoSrc,
  username,
  dropdowns,
}) => (
  <Navbar className="logoHeaderWithNav" collapseOnSelect expand="md">
    <Navbar.Brand id="headerLogoCol">
      <div
        tabIndex={0}
        role="button"
        onClick={() => history.push('/home')}
      >
        <img
          alt="Portal home"
          style={{ width: '10rem' }}
          src={logoSrc}
          className="d-inline-block align-top"
        />
      </div>
    </Navbar.Brand>
    <Switch>
      <HeaderNav {...{ username, dropdowns }} />
    </Switch>
  </Navbar>
);

const headerProps = (item, showDropdown, setShowDropdown) => ({
  show: showDropdown[item],
  onMouseEnter: () => setShowDropdown((state) => ({ ...state, [item]: true })),
  onMouseLeave: () => setShowDropdown((state) => ({ ...state, [item]: false })),
  className: `basic-nav-dropdown outerLinkContainer ${
    showDropdown[item] ? 'showDropdown' : ''
  }`,
});

const HeaderNav = ({ username, dropdowns }) => {
  const items = dropdowns.map(([item]) => item);
  const itemsState = items.reduce(
    (state, item) => {
      const newState = state;
      (newState[item] = false);
      return newState;
    },
    {},
  );
  const [showDropdown, setShowDropdown] = useState({
    ...itemsState,
  });
  const props = (item) => headerProps(item, showDropdown, setShowDropdown);

  return (
    <>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" id="headerLinks">
          {dropdowns.map(([stateName, Icon, title, newItems, onClick], i) => (
            <HeaderDropdown
              key={i}
              {...props(stateName)}
              {...{ Icon, title, newItems }}
              {...(onClick && { onClick })}
            />
          ))}
        </Nav>
        <Nav className="justify-content-end">
          <Navbar.Text>
            {username !== '' && (
            <>
              Signed in as:
              {' '}
              <b>{username}</b>
            </>
            )}
            <Link to="/logout" className="link-item">
              Log out
            </Link>
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </>
  );
};

const HeaderDropdown = ({
  items, Icon, title, ...props
}) => (
  <NavDropdown
    {...props}
    title={(
      <Nav.Link disabled className="innerLinkContainer">
        <Icon fontSize="large" />
        <p className="headerLinksText">{title}</p>
      </Nav.Link>
          )}
  >
    <HeaderDropdownItems items={items} />
  </NavDropdown>
);

const HeaderDropdownItems = ({ items }) => items.map(([onClick, name], i, arr) => {
  const props = {
    onClick: (e) => e.stopPropagation(),
    ...(typeof onClick === 'string' ? { to: onClick } : { onClick }),
  };
  return (
    <React.Fragment key={i}>
      <Link {...props}>
        <div className="dropdown-item">{name}</div>
      </Link>
      {i !== arr.length - 1 && <NavDropdown.Divider />}
    </React.Fragment>
  );
});

export const SupportModal = ({ showModal, setShowModal, version }) => (
  <Modal
    centered
    show={showModal}
    onHide={() => setShowModal(false)}
    id="ServestITSupportModal"
    size="sm"
  >
    <Modal.Header closeButton>
      <Modal.Title>About Forcelink</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Forcelink version
      {' '}
      {version}
      <br />
      Acumen Software
      <br />
      <a href="www.forcelink.net">www.forcelink.net</a>
    </Modal.Body>
  </Modal>
);
