import React from 'react';
import { Cookies } from 'react-cookie';
import { getAllLabels } from '../apiCalls';
import { checkCookies } from '../context';
import * as context from '../context';

export const Context = React.createContext();

export class ContextManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.zeroState();
    this.cookies = new Cookies();
  }

  zeroState = () => ({
    authenticated: null,
    username: '',
    callTypes: [],
    customerTypes: [],
    labels: {},
  });

  componentDidMount = async () => {
    checkCookies(this.cookies, (s) => this.setState(s));
  };

  setAuth = async (...args) => context.setAuth(...args, this.cookies, (s) => this.setState(s));

  logout = () => context.logout((s) => this.setState(s), this.cookies, this.zeroState);

  setLabels = async () => {
    const labels = await getAllLabels();
    if (labels) this.setState({ labels });
  };

  // SETTERS
  setCallTypes = (callTypes) => this.setState({ callTypes });

  setCustomerTypes = (customerTypes) => this.setState({ customerTypes });

  // RENDER CONTEXT
  render() {
    const {
      props: { children },
      state: {
        authenticated, username, callTypes, customerTypes, labels,
      },
      cookies,
      setAuth,
      logout,
      setCallTypes,
      setCustomerTypes,
      setLabels,
    } = this;

    // if (authenticated === null) clearCookies(this.cookies);
    if (authenticated === true) {
      cookies.set('subscriberId', 'servest_it', { path: '/servest_it' });
      if (Object.keys(labels).length === 0) {
        setLabels();
      }
    }
    console.log('context state:', this.state, 'cookies:', cookies.cookies);

    return (
      <Context.Provider
        value={{
          setAuth,
          logout,
          setCallTypes,
          setCustomerTypes,
          cookies,
          authenticated,
          username,
          callTypes,
          customerTypes,
          labels,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
