import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

export const CustomerAddressForm = ({
  entries: {
    building, streetNumber, streetName, suburb, city, postalCode,
  },
  updateEntries,
  readOnly = false,
}) => (
  <>
    <Form.Group>
      <Row className="formInputs">
        {/* BUILDING */}
        <Col md={6}>
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">Building</Form.Label>
          </Form.Group>
          <Form.Control
            readOnly={readOnly}
            name="building"
            type="text"
            value={building}
            onChange={updateEntries}
          />
        </Col>
        {/* STREET NUMBER */}
        <Col md={6}>
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">Street Number</Form.Label>
          </Form.Group>
          <Form.Control
            readOnly={readOnly}
            name="streetNumber"
            type="text"
            value={streetNumber}
            onChange={updateEntries}
          />
        </Col>
      </Row>
    </Form.Group>
    <Form.Group>
      <Row className="formInputs">
        {/* STREET NAME */}
        <Col md={6}>
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">Street Name</Form.Label>
          </Form.Group>
          <Form.Control
            readOnly={readOnly}
            name="streetName"
            type="text"
            value={streetName}
            onChange={updateEntries}
          />
        </Col>
        {/* SUBURB */}
        <Col md={6}>
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">Suburb</Form.Label>
          </Form.Group>
          <Form.Control
            readOnly={readOnly}
            name="suburb"
            type="text"
            value={suburb}
            onChange={updateEntries}
          />
        </Col>
      </Row>
    </Form.Group>
    <Form.Group>
      <Row className="formInputs">
        {/* CITY */}
        <Col md={6}>
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">City</Form.Label>
          </Form.Group>
          <Form.Control
            readOnly={readOnly}
            name="city"
            type="text"
            value={city}
            onChange={updateEntries}
          />
        </Col>
        {/* POSTAL CODE */}
        <Col md={6}>
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">Postal Code</Form.Label>
          </Form.Group>
          <Form.Control
            readOnly={readOnly}
            name="postalCode"
            type="number"
            value={postalCode}
            onChange={updateEntries}
          />
        </Col>
      </Row>
    </Form.Group>
  </>
);
