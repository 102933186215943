import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const FormTable = ({
  columns,
  rows,
  contextMenuOptions,
}) => {
  const [currentRows, setCurrentRows] = useState([]);
  const [currentColumns, setCurrentColumns] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [elementId, setElementId] = useState();

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuPosition({ top: event.clientY, left: event.clientX });
    setElementId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCurrentRows(rows);
    setCurrentColumns(columns);
  }, [rows, columns]);

  return (
    <div
      className="document-table"
    >
      <TableContainer
        component={Paper}
        style={{ maxHeight: '100%', maxWidth: '100%', overflow: 'auto' }}
      >
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={menuPosition}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {contextMenuOptions !== undefined
            && contextMenuOptions.map((contextMenuOption) => (
              <MenuItem
                key={`menu-item-${contextMenuOption.label}`}
                style={{ color: '#000000' }}
                onClick={() => {
                  handleClose();
                  contextMenuOption.action(elementId);
                }}
              >
                {contextMenuOption.label}
              </MenuItem>
            ))}
        </Menu>
        {currentRows !== undefined && currentColumns !== undefined && (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {currentColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(currentRows.length > 0 ? currentRows : currentRows).map(
                (row, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      contextMenuOptions !== undefined
                          && row.id !== undefined
                          && handleClick(e, row.id);
                    }}
                  >
                    {currentColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          style={{ maxWidth: '200px' }}
                        >
                          {column.link === true ? (
                            <a href={value.value}>{value.label}</a>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default FormTable;
