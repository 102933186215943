import React from 'react';
import {
  Alert, Button, Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import SearchIcon from '@material-ui/icons/Search';
import { LeafletMap } from './LeafletMap';
import { getUnauthenticatedWorkMapInfo } from '../scripts/mapScripts';
import {
  getSearchedPlaces,
  getReverseGeocodedPlace,
  checkOutagesInSuburb,
  getSuburbs,
} from '../scripts/addressScripts';
import '../styles/AddressInput.scss';
import SearchBoxComponent from './SearchBoxComponent';

export class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    const {
      onResultFound,
      updateEntries,
      updateReqEntries,
      requiredMissing,
      subscriberId,
    } = this.props;
    this.onResultFound = onResultFound;
    this.updateEntries = updateEntries;
    this.updateReqEntries = updateReqEntries;
    this.requiredMissing = requiredMissing;
    this.state = {
      searchResults: [],
      suburbResults: [],
      showSuburbAlert: false,
      showSuburbSearch: false,
      center: {
        lat: 0,
        lng: 0,
      },
      subscriberId,
    };
  }

  handleMarkerChange(center) {
    getReverseGeocodedPlace(center.lat, center.lng).then((res) => {
      const result = res.addresses[0];
      this.setState({ center });
      this.onResultFound({
        streetNumber: result.address.streetNumber,
        streetName: result.address.streetName,
        suburb: result.address.municipalitySubdivision,
        city: result.address.countrySecondarySubdivision,
        postalCode: result.address.postalCode,
      });
    });
  }

  updateEntries = (e) => this.updateEntries(e);

  updateReqEntries = (e) => this.updateReqEntries(e);

  validStyle = (name) => (this.requiredMissing[name] === true ? { color: 'red' } : null);

  componentDidMount = async () => {
    const { subscriberId } = this.state;
    const res = await getUnauthenticatedWorkMapInfo(subscriberId);
    this.setState({
      center: {
        lat: res.defaultLat,
        lng: res.defaultLong,
      },
    });
  }

  render() {
    const {
      entries: {
        building,
        streetNumber,
        streetName,
        suburb,
        city,
        postalCode,
        description,
      },
      requiredMissing,
      page,
    } = this.props;
    const {
      showSuburbAlert,
      showSuburbSearch,
      center,
      searchResults,
      suburbResults,
    } = this.state;

    const handleSuburbSearch = async (query) => {
      if (query.length > 0) {
        const response = await getSuburbs(query, center.lat, center.lng, 180);
        console.log(response);
        const newresults = response.results.map((result) => ({
          key: result.id,
          suburb: result.address.municipalitySubdivision,
          center: { lat: result.position.lat, lng: result.position.lon },
          value: result.address.municipalitySubdivision,
        }));
        this.setState({
          suburbResults: newresults,
        });
      }
    };

    const handleSearchChange = async (query) => {
      if (query.length > 0) {
        const response = await getSearchedPlaces(query, center.lat, center.lng, 180);
        const newresults = response.results.map((result) => ({
          key: result.id,
          streetNumber: result.address.streetNumber,
          streetName: result.address.streetName,
          suburb: result.address.municipalitySubdivision,
          city: result.address.countrySecondarySubdivision,
          postalCode: result.address.postalCode,
          center: { lat: result.position.lat, lng: result.position.lon },
          value: result.address.freeformAddress,
        }));
        this.setState({
          searchResults: newresults,
        });
      }
    };

    return (
      <div id="AddressInput">
        <Form.Group className="formLabels">
          <Form.Label className="largeLabel">Customer Address</Form.Label>
        </Form.Group>
        {showSuburbAlert && suburb && (
          <Alert
            variant="warning"
            onClose={() => this.setState({ showSuburbAlert: false })}
            dismissible
          >
            <p>
              <strong>Warning!</strong>
              {' '}
              An outage is already logged in this
              suburb.
            </p>
          </Alert>
        )}
        <Form.Group>
          <SearchBoxComponent
            labelValue="Search for an address"
            data={searchResults}
            selectCall={(result) => {
              this.onResultFound(result);
              this.setState({ center: result.center });
            }}
            populateCall={handleSearchChange}
          />
        </Form.Group>
        <Form.Group>
          <Row className="formInputs">
            {/* BUILDING */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">Building</Form.Label>
              </Form.Group>
              <Form.Control
                name="building"
                type="text"
                value={building}
                onChange={this.updateEntries}
              />
            </Col>
            {/* STREET NUMBER */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label
                  className="smallLabel"
                  style={
                    page === 'profile' ? this.validStyle('streetNumber') : null
                  }
                >
                  Street Number
                  {page === 'profile' ? ' *' : ''}
                </Form.Label>
              </Form.Group>
              <Form.Control
                name="streetNumber"
                type="text"
                required={page === 'profile'}
                isInvalid={
                  page === 'profile' && requiredMissing.streetNumber === true
                }
                value={streetNumber}
                onChange={
                  page === 'profile'
                    ? this.updateReqEntries
                    : this.updateEntries
                }
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row className="formInputs">
            {/* STREET NAME */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label
                  className="smallLabel"
                  style={
                    page === 'profile' ? this.validStyle('streetName') : null
                  }
                >
                  Street Name
                  {page === 'profile' ? ' *' : ''}
                </Form.Label>
              </Form.Group>
              <Form.Control
                name="streetName"
                type="text"
                required={page === 'profile'}
                isInvalid={
                  page === 'profile' && requiredMissing.streetName === true
                }
                value={streetName}
                onChange={
                  page === 'profile'
                    ? this.updateReqEntries
                    : this.updateEntries
                }
              />
            </Col>
            {/* SUBURB */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label
                  className="smallLabel"
                  style={page === 'logFault' ? this.validStyle('suburb') : null}
                >
                  Suburb *
                </Form.Label>
              </Form.Group>
              <InputGroup>
                <Form.Control
                  name="suburb"
                  type="text"
                  required
                  isInvalid={
                    page === 'logFault' && requiredMissing.suburb === true
                  }
                  value={suburb}
                  onChange={
                    page === 'logFault'
                      ? this.updateReqEntries
                      : this.updateEntries
                  }
                />
                <InputGroup.Append>
                  <Button
                    onClick={() => {
                      this.setState({ showSuburbSearch: !showSuburbSearch });
                    }}
                    variant="outline-primary"
                  >
                    <SearchIcon />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
        </Form.Group>
        {showSuburbSearch && (
          <Form.Group>
            <SearchBoxComponent
              labelValue="Search for a suburb"
              data={suburbResults}
              selectCall={(result) => {
                this.onResultFound({ suburb: result.suburb });
                const outageStatus = checkOutagesInSuburb(result.suburb);
                this.setState({
                  showSuburbAlert: !outageStatus,
                  showSuburbSearch: false,
                  center: result.center,
                });
              }}
              populateCall={handleSuburbSearch}
            />
            <SearchBoxComponent />
          </Form.Group>
        )}
        <Form.Group>
          <Row className="formInputs">
            {/* CITY */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">City</Form.Label>
              </Form.Group>
              <Form.Control
                name="city"
                type="text"
                value={city}
                onChange={this.updateEntries}
              />
            </Col>
            {/* POSTAL CODE */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">Postal Code</Form.Label>
              </Form.Group>
              <Form.Control
                name="postalCode"
                type="number"
                value={postalCode}
                onChange={this.updateEntries}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          {/* DESCRIPTION/ADDITIONAL INFO */}
          <Form.Group className="formLabels">
            <Form.Label className="smallLabel">
              {page === 'profile' && 'Additional Address information'}
              {page === 'logFault' && 'Description'}
            </Form.Label>
          </Form.Group>
          <Form.Control
            name="description"
            type="text"
            value={description}
            onChange={this.updateEntries}
          />
        </Form.Group>
        <Form.Group className="mapCanvasWrapper">
          <LeafletMap
            center={center}
            onCenterChange={(centered) => this.handleMarkerChange(centered)}
          />
        </Form.Group>
      </div>
    );
  }
}
