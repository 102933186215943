import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Card, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../../styles/ksl/home.scss';

export const HomePage = () => (
  <Container>
    <Row className="iconRow">
      {[
        [
          '/ticket',
          'new_ticket.jpg',
          'Create a new request',
          'This enables all the users to create new request.',
        ],
        [
          '/tickets',
          'view_tickets.jpg',
          'View requests',
          'This enables all the users to view request that they have logged.',
        ],
        [
          '/details',
          'my_details.jpg',
          'My Details',
          'This enables users to update their personal IT Portal details N.B. please provide your correct details to enable IT to smoothly and efficiently support you.',
        ],
      ].map((val) => (
        <Link to={val[0]} key={val[2]}>
          <Card link href="">
            <Image src={`ksl/${val[1]}`} wrapped ui={false} />
            <Card.Content>
              <Card.Header>{val[2]}</Card.Header>
              <Card.Description>{val[3]}</Card.Description>
            </Card.Content>
          </Card>
        </Link>
      ))}
    </Row>
  </Container>
);
