import React, { createElement, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Route, Switch, useLocation,
} from 'react-router-dom';
import { Cookies } from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './styles/App.scss';
import './styles/login.scss';
import './styles/global.scss';
import './styles/LandingPage.scss';
import {
  Context as RootContext,
  ContextManager as RootContextManager,
} from './functions/context';
import { ContextManager as BotswanaContextManager } from './functions/botswanatourism/context';
import { ContextManager as ServestITContextManager } from './functions/servest_it/context';
import { ContextManager as ServestISContextManager } from './functions/servest_is/context';
import { ContextManager as SiemensContextManager } from './functions/siemens/context';
import { ContextManager as SyntellContextManager } from './functions/syntell/context';
import { ContextManager as EnpowerContextManager } from './functions/enpower/context';
import { ContextManager as SSGFacilitiesContextManager } from './functions/ssg_facilities/context';
import { ContextManager as StatsSaAppContextManager } from './functions/statssa/context';
import { ContextManager as BedworthContextManager } from './functions/bedworth/context';
import { ContextManager as MyCitiContextManager } from './functions/myciti/context';
import { ContextManager as KslContextManager } from './functions/ksl/context';
import { ContextManager as JoshcoContextManager } from './functions/joshco/context';
import { ContextManager as JoburgWaterContextManager } from './functions/joburg_water/context';
import { BotswanaTourismApp } from './BotswanaTourismApp';
import { ServestITApp } from './ServestITApp';
import { ServestISApp } from './ServestISApp';
import { SiemensApp } from './SiemensApp';
import { SyntellApp } from './SyntellApp';
import { EnpowerApp } from './EnpowerApp';
import { SSGFacilitiesApp } from './SSGFacilitiesApp';
import { StatsSaApp } from './StatsSaApp';
import { BedworthApp } from './BedworthApp';
import { MyCitiApp } from './MyCitiApp';
import { KslApp } from './KslApp';
import { JoshcoApp } from './JoshcoApp';
import { JoburgWaterApp } from './JoburgWaterApp';

let location;
const cookies = new Cookies();

export const endpointsPortalAndApi = [
  ['/bedworth', 'za2', 'Bedworth', 'Tourism'],
  ['/botswanatourism', 'za2', 'Botswana Tourism', 'Tourism'],
  ['/enpower_gsdm', 'za2', 'Enpower Web Fault Logging', 'power'],
  ['/myciti', 'za2', 'MyCiti', 'Tourism'],
  ['/servest_is', 'za2', 'Servest IS ', 'tech'],
  ['/servest_it', 'za2', 'Servest IT ', 'tech'],
  ['/siemens', 'za2', 'Siemens', 'meeting'],
  ['/ssg_fac', 'za2', 'SSG Facilities', 'facilities'],
  ['/syn_mtm', 'za2', 'Syntell ', 'tech'],
  ['/ksl', 'za2', 'KSL ', 'tech'],
  ['/joshco', 'za2', 'JOSHCO ', 'tech'],
  ['/joburg_water', 'za2', 'Joburg Water ', 'power'],
];
const paths = endpointsPortalAndApi.map((portal) => portal[0]);
const allPaths = [];
for (let i = 0; i < paths.length; i++) {
  allPaths.push(paths[i]);
}
allPaths.push('/servest');
allPaths.push('/statssa');
const apps = [
  [BedworthContextManager, BedworthApp],
  [BotswanaContextManager, BotswanaTourismApp],
  [EnpowerContextManager, EnpowerApp],
  [MyCitiContextManager, MyCitiApp],
  [ServestISContextManager, ServestISApp],
  [ServestITContextManager, ServestITApp],
  [SiemensContextManager, SiemensApp],
  [SSGFacilitiesContextManager, SSGFacilitiesApp],
  [SyntellContextManager, SyntellApp],
  [KslContextManager, KslApp],
  [JoshcoContextManager, JoshcoApp],
  [JoburgWaterContextManager, JoburgWaterApp],
  // do not appaer as cards at root
  [ServestITContextManager, ServestITApp],
  [StatsSaAppContextManager, StatsSaApp],
];

const checkCookies = () => {
  let currentSubscriber = location.pathname.split('/')[1];
  if (currentSubscriber === 'botswanatourism') {
    currentSubscriber = 'bto';
  }
  console.log(
    `current location: [${location.pathname}] currentSubscriber: [${currentSubscriber}]`,
    location,
  );
  console.log('cookies in index:', cookies.getAll());
  const { subscriberId } = cookies.getAll();
  if (
    subscriberId != null
    && currentSubscriber != null
    && subscriberId !== currentSubscriber
  ) {
    ['JSESSIONID', 'SESSION', 'subscriberId', 'username'].forEach((cookie) => {
      console.log('removing', cookie, cookies.get(cookie));
      cookies.remove(cookie);
    });
  }
  if (currentSubscriber) cookies.set('subscriberId', currentSubscriber, { path: `/${currentSubscriber === 'bto' ? 'botswanatourism' : currentSubscriber}` });
  console.log('cookies now:', cookies.getAll());
};

const RootAppRouter = () => {
  const rootContext = useContext(RootContext);
  console.log('rootContext:', rootContext);
  const { setPortalEndpoint, setApiEndpoint } = rootContext;

  location = useLocation();

  useEffect(() => checkCookies(setPortalEndpoint, setApiEndpoint));

  return (
    <>
      <Route exact path="/">
        <div id="LandingPage">
          <img className="image" src="background1.jpg" alt="Botswana Tourism" />
          <section className="wrapper">
            <div className="container">
              <div className="row">
                {paths.map((portal, i) => (
                  <div key={`portalDiv_${i}`} className="col-md-4">
                    <div role="button" tabIndex={0} onClick={() => { window.location.href = portal; }}>
                      <div
                        className="card text-white card-has-bg click-col"
                        style={{ backgroundImage: `url(https://source.unsplash.com/600x900/?${endpointsPortalAndApi[i][3]}})` }}
                      >
                        <img
                          className="card-img d-none"
                          src={`https://source.unsplash.com/600x900/?${endpointsPortalAndApi[i][3]}}`}
                          alt="Splash"
                        />
                        <div className="card-img-overlay d-flex flex-column">
                          <div className="card-body">
                            <small className="card-meta mb-2">{portal}</small>
                            <h2 className="card-title mt-0 ">
                              {endpointsPortalAndApi[i][2]}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </Route>

      {apps.map(([context, app], i) => (
        <Route path={allPaths[i]} key={allPaths[i]}>
          <BrowserRouter basename={allPaths[i]}>
            <Switch>
              <Route
                path="*"
                render={() => checkCookies(setPortalEndpoint, setApiEndpoint)}
              />
            </Switch>
            <Switch>
              <Route path="/">
                {createElement(context, null, createElement(app))}
              </Route>
            </Switch>
          </BrowserRouter>
        </Route>
      ))}
    </>
  );
};

const RootApp = () => (
  <React.StrictMode>
    <RootContextManager>
      <BrowserRouter>
        <Switch>
          <RootAppRouter />
        </Switch>
      </BrowserRouter>
    </RootContextManager>
  </React.StrictMode>
);

ReactDOM.render(<RootApp />, document.getElementById('root'));
