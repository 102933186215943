import React, { useEffect, useCallback, useState } from 'react';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridOverlay,
} from '@material-ui/data-grid';
import {
  LinearProgress,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import _ from 'lodash';
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import '../styles/tables.scss';

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      rows: [],
      showModal: false,
    };
  }

  componentDidMount() {
    this.keepTrackOfSize();
    window.addEventListener('resize', this.keepTrackOfSize);
  }

  CustomToolbar = ({ title }) => {
    const { filters } = this.props;
    const hasFilters = filters && Object.keys(filters).length !== 0;
    return (
      <GridToolbarContainer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={{ flex: 1, display: 'flex' }}>
          <GridToolbar />
          {hasFilters && (
            <div className="MuiDataGrid-toolbar">
              <Button
                size="small"
                onClick={() => this.setState({ showModal: true })}
              >
                <CloudDownload style={{ marginRight: '5px' }} />
                Export Filters
              </Button>
            </div>
          )}
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="h6">{title}</Typography>
        </div>
        <div style={{ flex: 1 }} />
      </GridToolbarContainer>
    );
  };

  CustomLoadingOverlay = () => (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );

  keepTrackOfSize = () => this.setState({ width: window.innerWidth });

  componentWillUnmount = () => window.removeEventListener('resize', this.keepTrackOfSize);

  render() {
    const {
      props: {
        title, rows: propRows, columns: propCols, getRows, filters,
      },
      state: {
        width, rows, showModal,
      },
      CustomLoadingOverlay,
      CustomToolbar,
    } = this;
    console.log('page width:', width);
    const pageSize = width > 768 ? 30 : 15;
    const height = width > 768 ? 1000 : 500;
    const columns = propCols != null ? propCols : [];
    const loading = propRows == null;
    if (propRows != null) {
      const rowsEqual = !_.isEqual(rows, propRows);
      console.log(
        'state rows:',
        rows,
        'prop rows:',
        propRows,
        'equal:',
        rowsEqual,
      );
      rowsEqual && this.setState({ rows: propRows });
    }

    return (
      <TableWrapper
        style={{ height, width: '100%' }}
        getRows={getRows}
        filters={filters}
      >
        {(onFilterModelChange, loadingFilter) => {
          console.log(
            'state loading:',
            loading,
            'wrapper loading:',
            loadingFilter,
          );
          return (
            <>
              <DataGrid
                {...{
                  rows,
                  columns,
                  pageSize,
                  loading,
                  onFilterModelChange,
                }}
                filterMode={getRows != null ? 'server' : null}
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{
                  toolbar: { title },
                }}
                disableSelectionOnClick
                autoPageSize
                pagination
                rowHeight={40}
                headerHeight={40}
              />
              <FiltersModal
                showModal={showModal}
                setShowModal={(showModals) => this.setState({ showModal: showModals })}
                filters={filters}
              />
            </>
          );
        }}
      </TableWrapper>
    );
  }
}

const FiltersModal = ({ setShowModal, showModal, filters }) => (
  <Modal
    centered
    onHide={() => setShowModal(false)}
    show={showModal}
    id="FiltersModal"
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header>
      <Modal.Title>Exports filters JSON</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TextField
        label="Filters JSON"
        multiline
        rows={20}
        value={filters ? JSON.stringify(filters) : ''}
        variant="filled"
        fullWidth
      />
    </Modal.Body>
    <Modal.Footer>
      <BootstrapButton variant="primary" onClick={() => setShowModal(false)}>
        Done
      </BootstrapButton>
    </Modal.Footer>
  </Modal>
);

function TableWrapper({
  style, getRows, children, filters,
}) {
  const [filterValue, setFilterValue] = useState(filters || {});
  const [loading, setLoading] = useState(false);

  const onFilterModelChange = useCallback((params) => {
    if (params.filterModel) {
      const {
        filterModel: { items },
      } = params;
      console.log('filter params:', params);
      setFilterValue(items);
    } else {
      setFilterValue([]);
    }
  }, []);

  useEffect(() => {
    let active = true;
    const updateFilterValue = async () => {
      console.log('update filter value:', filterValue);
      setLoading(true);
      getRows(filterValue);
      if (!active) return;
      setLoading(false);
    };
    getRows != null && updateFilterValue();

    return () => {
      active = false;
    };
  }, [filterValue, getRows, setLoading]);

  console.log(
    'rendering table, filterValue:',
    filterValue,
    'loading:',
    loading,
  );

  return <div style={style}>{children(onFilterModelChange, loading)}</div>;
}
