import React from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../functions/syntell/context';
import * as login from '../../scripts/loginScripts';
import { LoginForm } from '../../components/syntell/login';

export class Login extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.subscriberId = 'syn_mtm';
    // document.title = "Syntell Customer Portal";
  }

  onLogin = ({ username, password }, setStates) => {
    const { subscriberId } = this;
    const { setAuth } = this.context;
    login.doLogin(username, password, setAuth, subscriberId, setStates);
  };

  render() {
    const { onLogin, subscriberId } = this;
    const subscriber = 'syn_mtm';
    return (
      <Container fluid id="LoginForm">
        <LoginForm {...{ subscriber, onLogin, subscriberId }} />
      </Container>
    );
  }
}
