import React from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { AssetsPage } from './pages/siemens/AssetsPage';
import { AssetTreePage } from './pages/siemens/AssetTreePage';
import { Context } from './functions/siemens/context';
import { ForcelinkFooter, LogoHeader } from './components/header';
import { HomePage } from './pages/siemens/HomePage';
import { Login } from './pages/siemens/login';
import { SiemensHeader } from './components/siemens/header';
import { ViewAssetPage } from './pages/siemens/ViewAssetPage';
import { ViewTickets } from './pages/siemens/viewTickets';
import { ViewMap } from './pages/siemens/ViewMap';
import { Quotations } from './pages/siemens/quotations';
import { KPITablePage } from './pages/siemens/KPITablePage';
import { TicketFormPage } from './pages/siemens/TicketFormPage';
import { CustomerFormPage } from './pages/siemens/CustomerFormPage';

export class SiemensApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'Siemens Portal';
    const { openSnackbar } = this.state;
    const {
      authenticated,
      logout,
      cookies: { cookies },
    } = this.context;
    console.log('Authenticated:', authenticated);

    if (authenticated === null) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    const headerPaths = [
      '/home',
      '/myDetails',
      '/tickets',
      '/ticket',
      '/quotations',
      '/assets',
      '/asset',
      '/viewMap',
      '/workRequests',
      '/kpi',
    ];

    const pagesPaths = [
      ['/myDetails', <CustomerFormPage />],
      ['/tickets', <ViewTickets />],
      ['/quotations/:filters?', <Quotations />],
      ['/assets', <AssetsPage />],
      ['/asset/view/:id', <ViewAssetPage />],
      ['/asset/tree/:id', <AssetTreePage />],
      ['/asset/workmap/:id', <AssetTreePage />],
      ['/viewMap', <ViewMap />],
      ['/ticket/:code', <TicketFormPage />],
      ['/ticket', <TicketFormPage />],
      ['/kpi', <KPITablePage />],
    ];

    return (
      <div id="SiemensApp">
        <Switch>
          {headerPaths.map((path, i) => (
            <Route path={path} key={i}>
              <SiemensHeader />
            </Route>
          ))}
          <Route path="/">
            <LogoHeader
              title="Siemens Portal"
              imgSrc="siemens/logo_white.svg"
            />
          </Route>
        </Switch>
        <div id="App">
          <Container fluid id="AppContainer">
            <Switch>
              <Route
                path="/login"
                render={({ location }) => (!authenticated ? (
                  <Login />
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
              {!authenticated && (
                <Route
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
              <Route
                path="/home"
                render={({ location }) => (authenticated ? (
                  <HomePage />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                ))}
              />
              <Route path="/logout" render={() => ((logout(), null))} />
              {pagesPaths.map(([path, page], i) => (
                <Route path={path} key={i}>
                  {page}
                </Route>
              ))}
              {[
                ['/exports', 'exports.html'],
                ['/supportSMSForm', 'supportsmsform.html'],
                ['/releaseNotes', 'versioninfo.html'],
                ['/referenceManual', 'Forcelink_Reference_Manual.pdf'],
              ].map(([path, page], i) => (
                <Route
                  key={i}
                  path={path}
                  component={() => {
                    window.location.replace(
                      `https://za.forcelink.net/forcelink/${page}`,
                    );
                    return <h4>Redirecting...</h4>;
                  }}
                />
              ))}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </div>
        <Route path="/">
          <ForcelinkFooter />
        </Route>
        {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
            >
              <Alert
                onClose={this.snackbarClose}
                severity="info"
                elevation={6}
                variant="filled"
              >
                Logged in with username
                {' '}
                <b>{cookies.username}</b>
              </Alert>
            </Snackbar>
        )}
      </div>
    );
  }
}

const NoMatch = () => {
  document.title = '404 Not Found | Servest IT';
  const location = useLocation();
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
