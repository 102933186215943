import React, { useRef, useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  FieldsContainer,
} from '../forms.styled';
import FormSection from '../helpers/formsection';

const PREFIX = '5576-9700-';

const CardDetails = (props) => {
  const { submit, doSubmit } = props;
  const [cardNumber, setCardNumber] = useState(PREFIX);
  const [expirationDate, setExpirationDate] = useState(null);
  const [cardForTransfer, setCardForTransfer] = useState(PREFIX);
  const cardNumberRef = useRef();
  const expirationDateRef = useRef();
  const cardForTransferRef = useRef();

  useEffect(() => {
    if (submit) {
      let valid = true;
      if (cardNumber === '' || cardNumber.length < 19) {
        cardNumberRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (expirationDate === null) {
        expirationDateRef.current.classList.add('invalid-date');
        valid = false;
      }
      if (cardForTransfer === '' || cardForTransfer.length < 19) {
        cardForTransferRef.current.classList.add('invalid-input');
        valid = false;
      }
      if (cardForTransfer === cardNumber) {
        cardForTransferRef.current.classList.add('invalid-input');
        cardNumberRef.current.classList.add('invalid-input');
        valid = false;
      }
      doSubmit(cardNumber, expirationDate, cardForTransfer, valid);
    }
  }, [submit]);

  const handleCardNumberChange = (event) => {
    let inputValue = event.target.value;
    if (!inputValue.startsWith(PREFIX)) {
      inputValue = PREFIX;
    } else {
      let cardNumberPart = inputValue.slice(PREFIX.length).replace(/\D/g, '');
      cardNumberPart = cardNumberPart.substring(0, 8).replace(/(\d{4})(?=\d)/g, '$1-');
      inputValue = PREFIX + cardNumberPart;
    }
    if (inputValue !== PREFIX || inputValue === PREFIX) {
      setCardNumber(inputValue);
      cardNumberRef.current.classList.remove('invalid-input');
    } else {
      cardNumberRef.current.classList.add('invalid-input');
    }
  };

  const handleCardForTransferChange = (event) => {
    let inputValue = event.target.value;
    if (!inputValue.startsWith(PREFIX)) {
      inputValue = PREFIX;
    } else {
      let cardNumberPart = inputValue.slice(PREFIX.length).replace(/\D/g, '');
      cardNumberPart = cardNumberPart.substring(0, 8).replace(/(\d{4})(?=\d)/g, '$1-');
      inputValue = PREFIX + cardNumberPart;
    }
    if (inputValue !== PREFIX || inputValue === PREFIX) {
      setCardForTransfer(inputValue);
      cardForTransferRef.current.classList.remove('invalid-input');
    } else {
      cardForTransferRef.current.classList.add('invalid-input');
    }
  };

  return (
    <FormSection title="Card Details">
      <FieldsContainer>
        <input
          className="nomargin"
          type="text"
          name="cardNumber"
          value={cardNumber}
          ref={cardNumberRef}
          onChange={handleCardNumberChange}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Card Number eg. (5576-9700-XXXX-XXXX) -
          For the existing
          <b style={{ marginLeft: '4px' }}>my</b>
          connect card
        </p>
      </FieldsContainer>
      <div className="expirationdate">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            value={expirationDate}
            ref={expirationDateRef}
            format="MM/YYYY"
            views={['month', 'year']}
            slotProps={{
              textField: {
                helperText: 'Card Expiration Date',
              },
            }}
            onChange={(newValue) => {
              setExpirationDate(newValue);
              if (newValue && newValue !== '' && newValue !== null) {
                expirationDateRef.current.classList.remove('invalid-date');
              } else {
                expirationDateRef.current.classList.add('invalid-date');
              }
            }}
          />
        </LocalizationProvider>
      </div>
      <FieldsContainer>
        <input
          className="nomargin"
          type="text"
          name="cardForTransfer"
          value={cardForTransfer}
          ref={cardForTransferRef}
          onChange={handleCardForTransferChange}
        />
        <p
          style={{
            margin: '3px 0px 16px 0px', alignItems: 'end', display: 'flex',
          }}
          className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
        >
          Card Number To Transfer To eg. (5576-9700-XXXX-XXXX) -
          For the new
          <b style={{ marginLeft: '4px' }}>my</b>
          connect card
        </p>
      </FieldsContainer>
    </FormSection>
  );
};

export default CardDetails;
