import React, { useState, useEffect } from 'react';
import './survey.scss';
import { Container } from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  getCustomerSurvey,
  getWorkOrderCode,
  updateSurveyInspectionList,
} from '../../scripts/statssa/surveyApis';
import {
  SurveyPopup,
} from './surveyPopup';

export const Survey = () => {
  const [survey, setSurvey] = useState(null);
  const [surveyFilled, setSurveyFilled] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [workorderCode, setWorkorderCode] = useState(null);
  const workorderIdParam = new URLSearchParams(window.location.search);

  useEffect(() => {
    async function fetchData() {
      const code = await getWorkOrderCode(workorderIdParam);
      const data = await getCustomerSurvey(workorderIdParam);
      if (data) {
        setSurvey(data);
      }
      setWorkorderCode(code);
    }
    fetchData();
  }, []);

  const SurveyInner = () => survey.map((surveyItem, i) => (
    <div id={`inpectionrow_${i}`} key={`inpectionrow_${i}`} className="InspectionRow">
      <h3 id={`inspectiondescription_${i}`} key={`inspectiondescription_${i}`}>
        {surveyItem.description}
      </h3>
      {(surveyItem.customField.fieldTypeId === 4)
          && (
            <SelectInner surveyItem={surveyItem} i={i} />
          )}
      {(surveyItem.customField.fieldTypeId < 4)
          && (
            <InputInner />
          )}
    </div>
  ));

  const SelectInner = (props) => {
    const { surveyItem } = props;
    const { i } = props;
    const customReferenceList = surveyItem.customField.customReferenceList.sort((
      { sortOrder: a }, { sortOrder: b },
    ) => a - b);

    return (
      <select className="inspectionselect" id={surveyItem.id} key={`inspectionselect_${i}`}>
        <option id="option__" key="option__" value="" defaultValue>-- Select --</option>
        {customReferenceList.map((inspectionOption, j) => (
          <option id={inspectionOption.id} key={`option_${i}_${j}`} value={inspectionOption.description}>{inspectionOption.description}</option>
        ))}
      </select>
    );
  };

  const InputInner = () => (<input className="inspectiontext" type="text" value="" />);

  const onSubmit = async () => {
    const itemList = [];
    const inspectionColumn = document.getElementsByClassName('InspectionColumn')[0];
    const inspectionRows = inspectionColumn.getElementsByClassName('InspectionRow');
    const inspectionNumber = inspectionRows.length;
    for (let i = 0; i < inspectionNumber; i++) {
      let input = inspectionRows[i].getElementsByClassName('inspectiontext')[0];
      let value;
      let valueId = null;
      let id;
      if (input) {
        value = input.value;
        id = input.getAttribute('id');
      } else {
        const indexedItem = inspectionRows[i].getElementsByClassName('inspectionselect')[0];
        input = indexedItem;
        value = input.options[input.selectedIndex].getAttribute('value');
        valueId = input.options[input.selectedIndex].getAttribute('id');
        id = input.getAttribute('id');
      }
      const item = {
        itemValue: value,
        itemId: id,
        itemValueId: valueId,
      };
      if (item.itemValue && item.itemValue !== null && item.itemValue.length > 0) {
        itemList.push(item);
      }
    }
    if (inspectionNumber === itemList.length) {
      setLoadingPage(true);
      const response = await updateSurveyInspectionList(itemList, workorderIdParam);
      setLoadingPage(false);
      if (response) {
        setSurveySubmitted(true);
      }
    } else {
      setSurveyFilled(true);
    }
  };

  const onSubmitClose = () => {
    setSurveySubmitted(false);
    window.location.reload();
  };

  const onFillenClose = () => {
    setSurveyFilled(false);
  };

  return (
    <Container>
      <Dimmer inverted active={loadingPage}>
        <Loader>Submitting survey</Loader>
      </Dimmer>
      <SurveyPopup
        show={surveyFilled}
        onClose={() => onFillenClose()}
        title="Incomplete Survey"
        message="Please fill in the entire survey before submitting."
      />
      <SurveyPopup
        show={surveySubmitted}
        onClose={() => onSubmitClose()}
        title="Successfully submitted"
        message="Thank you for submitting your feedback."
      />
      <div id="headerDiv">
        <h2>
          Customer Satisfaction Survey
        </h2>
        {workorderCode && (
          <h1>
            {workorderCode}
          </h1>
        )}
      </div>
      {((survey !== null) && (survey.length > 0)) && (
        <div id="subheading">
          The task:
          {' '}
          {workorderCode}
          {' '}
          was completed. Please answer the following questions.
        </div>
      )}
      <div className="InspectionColumn">
        {((survey !== null) && (survey.length > 0)) && (
          <SurveyInner />
        )}
        {((survey === null) || ((survey !== null) && (survey.length === 0))) && (
          <div id="subheading">
            This task cannot be rated.
            {' '}
            {workorderCode ? ('Either the task has already been rated or the task has not been completed') : ('It appears this task does not exist')}
            .
          </div>
        )}
      </div>
      {((survey !== null) && (survey.length > 0)) && (
        <div id="buttonContainer">
          <div
            tabIndex={0}
            role="button"
            id="submitButton"
            onClick={() => { onSubmit(); }}
          >
            Submit
          </div>
        </div>
      )}
    </Container>
  );
};
