import React from 'react';
import './styles/statssa/App.scss';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Survey } from './pages/statssa/survey';
import { StatsSaHeader } from './components/statssa/header';

export const StatsSaApp = () => {
  document.title = 'Stats SA Portal';
  return (
    <div id="StatsSaApp">
      <Switch>
        <Route path="/">
          <StatsSaHeader />
        </Route>
      </Switch>
      <div id="App">
        <Container fluid id="AppContainer">
          <Switch>
            <Route
              path="/survey"
              render={() => <Survey />}
            />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Container>
      </div>
    </div>
  );
};

const NoMatch = () => {
  document.title = '404 Not Found | Stats SA';
  const location = useLocation();
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
