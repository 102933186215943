import React, { useState, useEffect } from 'react';
import { Table } from '../../components/tables';
import {
  notificationsTableColumns,
} from '../../constants/joburg_water/constants';
import {
  getNotificationsTable,
  getColumns,
} from '../../scripts/joburg_water/tablesScripts';

export const Notifications = () => {
  const [rows, setRows] = useState(null);
  document.title = 'Notifications | Joburg Water Portal';
  const { headers, headerNames, widths } = notificationsTableColumns;

  function renderLinkCell(params) {
    const text = params.value;
    const linkRegex = /https?:\/\/[^\s]+/g;
    const innerHtml = text.replace(linkRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    return <span dangerouslySetInnerHTML={{ __html: innerHtml }} />;
  }
  const columns = [...getColumns(headers, headerNames, widths).filter((column) => column.field !== 'message'), {
    field: 'message', headerName: 'Message', width: 800, renderCell: renderLinkCell,
  }];
  const [loading, setLoading] = useState(true);

  const setRowsAsync = async () => {
    setRows(await getNotificationsTable(notificationsTableColumns));
    setLoading(false);
  };

  useEffect(() => {
    setRowsAsync();
  }, []);
  console.log('rows:', rows);

  return <Table title="Notifications" {...{ columns, rows, loading }} />;
};
