import React from 'react';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import moment from 'moment';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from '../../functions/syntell/context';
import '../../styles/syntell/createTicket.scss';
import {
  getWorkRequest,
  getTicketDetails,
  createTicket,
  getAllStatuses,
} from '../../scripts/syntell/createTicketScripts';
import { handleValidation, handleLogging } from '../../functions/forms';
import { FormRow, FormControlWithLabel } from '../forms';
import { provinces } from '../../constants/constantsGlobal';
import { CustomerAddressForm } from '../customerAddress';
import { getWorkRequestSubtypes } from '../../functions/apiCalls';

export class CreateTicketForm extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: this.initEntries(),
      requiredMissing: this.initRequiredMissing(),
      loadingPage: true,
      submitting: false,
      submitDone: false,
      ticketCategoryList: [],
      statusesList: [],
      errors: {},
    };
  }

  // INIT VALUES >>
  initEntries = () => ({
    customerCode: '',
    customerName: '',
    addressDescription: '',
    // CustomerAddressForm:
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    province: '',
    contactNumber: '',
    contactEmail: '',
    ccEmail: '',
    problemDescription: '',
    category: '',
    subcategory: '',
    newStatus: '',
    resourceFeedback: '',
    createdDate: moment().valueOf(),
  });

  initRequiredMissing = () => ({
    problemDescription: false,
    category: false,
  });
  // << INIT VALUES

  componentDidMount = async () => {
    const { code } = this.props;
    const entries = this.initEntries();
    let incomingEntries;
    let statusesList;
    const items = [];
    const typesData = [{ value: 'RFAULT' }];
    const results = [];
    for (let i = 0; i < typesData.length; i++) {
      results.push(getWorkRequestSubtypes(
        typesData[i].value,
      ).then((res) => res.json()));
    }
    const subtypesData = await Promise.all(results);
    typesData.forEach((type, i) => {
      const hasSubtypes = !!subtypesData[i].length;
      items.push({ label: type.label, value: type.value, disabled: hasSubtypes });
      subtypesData[i].forEach((subtype) => items.push({ label: `↳ ${subtype.label}`, value: subtype.value }));
    });
    const ticketCategoryList = items;
    this.setState({ ticketCategoryList: items });

    if (code) {
      console.log('Editing Ticket');
      // TODO replace over getTicketDetails
      incomingEntries = await getWorkRequest(code);
      console.log('incoming entries (Edit Ticket):', incomingEntries);

      statusesList = await getAllStatuses(incomingEntries.id);
      console.log('statuses list:', statusesList);
      entries.newStatus = statusesList[0].value;
      console.log('newStatus entry:', entries.newStatus);
      this.setState({ statusesList });
    } else {
      console.log('Creating Ticket');
      incomingEntries = await getTicketDetails();
    }

    Object.keys(incomingEntries).forEach(async (entry) => {
      if (entry === 'createdDate') {
        entries[entry] = new Date(entries[entry]);
      } else if (entry === 'category' && incomingEntries[entry] !== '') {
        entries[entry] = ticketCategoryList.find(
          (x) => x.value === incomingEntries[entry],
        ).label;
      } else if (incomingEntries[entry] != null) {
        entries[entry] = incomingEntries[entry];
      }
    });
    console.log('entries will be set:', entries);
    this.setState({ entries, loadingPage: false });
  };

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };

  render() {
    const { updateEntries, updateReqEntries } = this;
    const { code } = this.props;
    const {
      entries,
      entries: {
        customerCode,
        customerName,
        addressDescription,
        province,
        contactNumber,
        contactEmail,
        problemDescription,
        subcategory,
        category,
        createdDate,
        resourceFeedback,
      },
      requiredMissing,
      loadingPage,
      submitting,
      submitDone,
      ticketSubcategoryList,
      ticketCategoryList,
      errors,
    } = this.state;
    console.log('createTicket state:', this.state);

    if (ticketSubcategoryList && ticketSubcategoryList.length !== 0) {
      // Initialise states for ticket subcategory input when there is a subcategory necessary
      requiredMissing.subcategory == null
        && this.initRequiredState('subcategory');
    } else {
      // When subcategory not necessary, reset states for subcategory
      if (subcategory !== '') {
        this.setEntries('subcategory', '');
      }
      if (requiredMissing.subcategory != null) {
        this.updateRequiredState('subcategory', null);
      }
    }

    if (code != null) {
      requiredMissing.newStatus == null && this.initRequiredState('newStatus');
    }

    return (
      <Container fluid id="createTicketForm">
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Card id="formCard" className="text-center">
              <Card.Header>
                <Card.Title>
                  <h3>
                    <b>{code != null ? 'Edit work request' : 'Create work request'}</b>
                  </h3>
                </Card.Title>
              </Card.Header>
              <Dimmer inverted active={loadingPage}>
                <Loader>Loading ticket details</Loader>
              </Dimmer>
              <Card.Body id="formBody">
                <Form>
                  <Card className="formInnerCard">
                    <Form.Group className="formLabels">
                      <Form.Label className="largeLabel">
                        Customer and Address
                      </Form.Label>
                    </Form.Group>
                    <FormRow>
                      {/* CUSTOMER CODE */}
                      <FormControlWithLabel
                        readOnly
                        label="Customer Code"
                        name="customerCode"
                        value={customerCode}
                      />
                      {/* CUSTOMER NAME */}
                      <FormControlWithLabel
                        readOnly
                        label="Customer Name"
                        name="customerName"
                        value={customerName}
                      />
                    </FormRow>
                    <FormRow>
                      {/* ADDRESS DESCRIPTION */}
                      <FormControlWithLabel
                        readOnly={code != null}
                        type="text"
                        label="Address Description"
                        name="addressDescription"
                        value={addressDescription}
                        onChange={updateEntries}
                      />
                    </FormRow>
                    {/* CUSTOMER ADDRESS inputs */}
                    <CustomerAddressForm
                      {...{ entries, updateEntries }}
                      readOnly={code != null}
                    />
                    <Form.Group>
                      <Row className="formInputs">
                        {/* PROVINCE */}
                        <Col md={6}>
                          <Material.TextField
                            readOnly={code != null}
                            name="province"
                            select
                            fullWidth
                            margin="normal"
                            label="Province"
                            variant="outlined"
                            value={province}
                            onChange={updateEntries}
                          >
                            <Material.MenuItem
                              key="blank"
                              value=""
                            />
                            {Object.entries(provinces).map(
                              ([newCode, name], i) => (
                                <Material.MenuItem key={i} value={newCode}>
                                  {name}
                                </Material.MenuItem>
                              ),
                            )}
                          </Material.TextField>
                        </Col>
                        <Col md={6} />
                      </Row>
                    </Form.Group>
                  </Card>

                  <FormRow>
                    {/* CONTACT NUMBER  */}
                    <FormControlWithLabel
                      label="Contact Number"
                      type="tel"
                      name="contactNumber"
                      value={contactNumber}
                      onChange={updateEntries}
                      error={errors.contactNumber}
                    />
                    {/* CONTACT EMAIL */}
                    <FormControlWithLabel
                      type="email"
                      label="Contact e-mail"
                      name="contactEmail"
                      value={contactEmail}
                      onChange={updateEntries}
                    />
                  </FormRow>
                  {
                    // <FormRow>
                    //   {/* CC EMAIL */}
                    //   <FormControlWithLabel
                    //     label="CC e-mail"
                    //     type="email"
                    //     name="ccEmail"
                    //     value={ccEmail}
                    //     onChange={updateEntries}
                    //     error={errors.contactNumber}
                    //   />
                    // </FormRow>
                  }
                  <Form.Group>
                    <Row className="formInputs">
                      <Col md="6" sm="12">
                        <Material.TextField
                          name="category"
                          select
                          fullWidth
                          margin="normal"
                          label="Ticket Category"
                          variant="outlined"
                          required
                          error={requiredMissing.category === true}
                          value={category}
                          onChange={(e) => {
                            updateReqEntries(e);
                          }}
                        >
                          <Material.MenuItem
                            key="blank"
                            value=""
                          />
                          {ticketCategoryList && ticketCategoryList.map((newCategory) => (
                            <Material.MenuItem
                              key={newCategory.value}
                              value={newCategory.value}
                            >
                              {newCategory.label}
                            </Material.MenuItem>
                          ))}
                        </Material.TextField>
                      </Col>
                      <Col md="6" sm="12" />
                    </Row>
                  </Form.Group>
                  <FormRow md={9}>
                    {/* PROBLEM DESCRIPTION */}
                    <FormControlWithLabel
                      type="text"
                      as="textarea"
                      label="Problem Description"
                      name="problemDescription"
                      value={problemDescription}
                      requiredMissing={requiredMissing}
                      onChange={updateReqEntries}
                    />
                  </FormRow>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* DATE CREATED */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Date created
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="createdDate"
                          readOnly
                          value={moment(createdDate).format('D MMM YYYY hh:mm')}
                        />
                      </Col>
                      <Col md={6} />
                    </Row>
                  </Form.Group>
                  {code != null && (
                    <FormRow>
                      <FormControlWithLabel
                        readOnly
                        label="Resource Feedback"
                        name="resourceFeedback"
                        value={resourceFeedback}
                      />
                    </FormRow>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer>
                <Form.Group id="finalButtons">
                  <Link to="/home">
                    <Button variant="secondary" type="submit">
                      Cancel
                    </Button>
                  </Link>
                  {!submitting && (
                    <ButtonWrapper>
                      {(history) => (
                        <Button
                          variant={submitDone ? 'secondary' : 'primary'}
                          disabled={submitDone}
                          type="submit"
                          onClick={() => {
                            let handleLoggingResponse;
                            const isFormValid = handleValidation(
                              this.state,
                              (s) => this.setState(s),
                            );
                            if (isFormValid) {
                              handleLoggingResponse = handleLogging(
                                this.state,
                                (s) => this.setState(s),
                              );
                            }
                            if (handleLoggingResponse && isFormValid) {
                              createTicket(entries, code, history, (s) => this.setState(s));
                            }
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </ButtonWrapper>
                  )}
                  {submitting && <Spinner />}
                </Form.Group>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    );
  }
}

const ButtonWrapper = ({ children }) => {
  const history = useHistory();
  return children(history);
};
