import React from 'react';
import {
  Button, Form, Row, Col, Card, Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import { AddressInput } from '../../components/AddressInput';
import {
  register,
  getProfile,
  updateProfile,
} from '../../scripts/enpower/profileScripts';
import { handleLogging, handleOTP } from '../../functions/forms';
import { Context } from '../../functions/enpower/context';
import { getProfileCustomerTypes } from '../../functions/apiCalls';
import { OTPModal } from '../../components/OTPModal';

export class ProfileForm extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const {
      newProfile,
    } = this.props;
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: this.initEntriesWithMap(),
      requiredMissing: this.initRequiredMissingWithMap(),
      customerTypesList: [],
      isEmployee: false,
      showModal: false,
      submitting: false,
    };
    this.newProfile = newProfile;
  }

  // INIT VALUES >>
  initEntries = () => ({
    name: '',
    profileCustomerType: '',
    accountNumber: '',
    meterNumber: '',
    password: '',
    passwordHint: '',
    employeeSAPNumber: '',
    contactPerson: '',
    mobileNumber: '',
    homeNumber: '',
    emailAddress: '',
    optOutPreferences: [],
  });

  initEntriesWithMap = () => ({
    ...this.initEntries(),
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    description: '',
  });

  initRequiredMissing = () => ({
    name: false,
    profileCustomerType: false,
    password: false,
    passwordHint: false,
    contactPerson: false,
    mobileNumber: false,
    emailAddress: false,
  });

  initRequiredMissingWithMap = () => ({
    ...this.initRequiredMissing(),
    streetNumber: false,
    streetName: false,
    suburb: false,
  });
  // << INIT VALUES

  componentDidMount = async () => {
    if (!this.newProfile) {
      // DEV (TODO: delete) >>>
      // if (window.location.origin === "http://localhost:3000") {
      //   console.log("You are on localhost and as such, test data will be used");
      //   entries = getEntries(testProfile, newProfileMapping);
      // } else {
      // <<<
      const entries = await getProfile();
      // } //DEV (TODO: delete)
      Object.keys(entries).forEach((entry) => {
        if (entries[entry] == null) {
          entries[entry] = this.initEntries()[entry];
        }
      });
      this.setState({ entries });
    }
    // DEV (TODO: delete) >>>
    // if (window.location.origin === "http://localhost:3000") {
    //   this.setState({ customerTypesList: testCustomerTypes });
    // } else {
    // <<<
    const customerTypesList = (await getProfileCustomerTypes()) || [];
    this.setState({ customerTypesList });
    // } //DEV (TODO: delete)
  };

  handleSubmit = async () => {
    const { setAuth } = this.context;
    const { entries } = this.state;
    const handleLoggingResponse = handleLogging(this.state, (s) => this.setState(s));
    if (handleLoggingResponse) {
      console.log('handling submit');
      if (this.newProfile) {
        const registerResponse = await register(
          entries,
          (s) => this.setState(s),
          setAuth,
        );
        console.log('register response:', registerResponse);
        const { result } = registerResponse;
        if (result === 'OTP_REQUIRED' || result === 'OTP_ERROR') { this.setState({ showModal: true }); }
        if (result === 'ALREADY_REGISTERED' || result === 'SUCCESS') { this.setState({ showModal: false }); }
      } else {
        updateProfile(entries, (s) => this.setState(s));
      }
    }
  };

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };
  // << UPDATE VALUES

  onResultFound = (result) => {
    console.log('result passed to parent:', result);
    const {
      streetNumber, streetName, city, postalCode, suburb,
    } = result;
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || entries.streetNumber || '';
    toUpdate.streetName = streetName || entries.streetName || '';
    toUpdate.city = city || entries.city || '';
    toUpdate.postalCode = postalCode || entries.postalCode || '';
    toUpdate.suburb = suburb || entries.suburb || '';
    this.setState({ entries: toUpdate });
  };

  validStyle = (name) => {
    const {
      requiredMissing,
    } = this.state;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  render() {
    const { newProfile } = this;
    const { setAuth } = this.context;
    const {
      entries,
      entries: {
        name,
        profileCustomerType,
        accountNumber,
        meterNumber,
        password,
        passwordHint,
        employeeSAPNumber,
        contactPerson,
        mobileNumber,
        homeNumber,
        emailAddress,
        optOutPreferences,
      },
      requiredMissing,
      customerTypesList,
      isEmployee,
      showModal,
      submitting,
    } = this.state;
    if (isEmployee) {
      // Initialise states for SAP number input when Employee? is checked
      requiredMissing.employeeSAPNumber == null
        && this.initRequiredState('employeeSAPNumber');
    } else {
      // When not employee, reset states for SAP number
      if (entries.employeeSAPNumber !== '') {
        this.setEntries('employeeSAPNumber', '');
      }
      if (requiredMissing.employeeSAPNumber != null) {
        this.updateRequiredState('employeeSAPNumber', null);
      }
    }

    return (
      <Container fluid id="ProfileForm">
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Card id="formCard" className="text-center">
              <Card.Header>
                <Card.Title>
                  <h3>
                    <b>
                      {newProfile
                        ? 'Account Registration'
                        : 'Edit your Profile'}
                    </b>
                  </h3>
                </Card.Title>
              </Card.Header>
              <Card.Body id="formBody">
                <Form>
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Customer Account Details
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* NAME */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('name')}
                          >
                            Name *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="name"
                          type="text"
                          required
                          isInvalid={requiredMissing.name === true}
                          value={name}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                      {/* CUSTOMER TYPE */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('profileCustomerType')}
                          >
                            Customer Type *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="profileCustomerType"
                          type="text"
                          as="select"
                          required
                          isInvalid={
                            requiredMissing.profileCustomerType === true
                          }
                          value={profileCustomerType}
                          onChange={this.updateReqEntries}
                        >
                          <option value="" key="blank" aria-label="blank"> </option>
                          {customerTypesList.map((type) => (
                            <option value={type.value} key={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* ACCOUNT NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Account Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="accountNumber"
                          value={accountNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                      {/* METER NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Meter Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="meterNumber"
                          value={meterNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* PASSWORD */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('password')}
                          >
                            Password *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="password"
                          name="password"
                          required
                          isInvalid={requiredMissing.password === true}
                          value={password}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                      {/* PASSWORD HINT */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('passwordHint')}
                          >
                            Password Hint *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="passwordHint"
                          required
                          isInvalid={requiredMissing.passwordHint === true}
                          value={passwordHint}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      {/* ENPOWER EMPLOYEE? */}
                      <Col xs={12} md={6} id="employeeCol">
                        <Form.Check
                          type="checkbox"
                          name="isEmployee"
                          id="isEmployee"
                          label="Enpower Employee?"
                          checked={isEmployee}
                          onChange={(e) => this.setState({ isEmployee: e.target.checked })}
                        />
                      </Col>
                      {/* EMPLOYEE SAP NUMBER */}
                      {isEmployee === true && (
                        <Col xs={12} md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle('employeeSAPNumber')}
                            >
                              Employee SAP Number *
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="text"
                            name="employeeSAPNumber"
                            required
                            isInvalid={
                              requiredMissing.employeeSAPNumber === true
                            }
                            value={employeeSAPNumber}
                            onChange={this.updateReqEntries}
                          />
                        </Col>
                      )}
                    </Row>
                  </Form.Group>

                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Contact Details
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* CONTACT PERSON */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('contactPerson')}
                          >
                            Contact Person *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="text"
                          name="contactPerson"
                          required
                          isInvalid={requiredMissing.contactPerson === true}
                          value={contactPerson}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                      {/* MOBILE NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('mobileNumber')}
                          >
                            Mobile Number *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="tel"
                          name="mobileNumber"
                          aria-describedby="helperText"
                          required
                          isInvalid={requiredMissing.mobileNumber === true}
                          value={mobileNumber}
                          onChange={this.updateReqEntries}
                        />
                        <Form.Text
                          muted
                          id="helperText"
                          style={{ textAlign: 'left' }}
                        >
                          This will be your username
                        </Form.Text>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* HOME NUMBER */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label className="smallLabel">
                            Home Number
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="tel"
                          name="homeNumber"
                          value={homeNumber}
                          onChange={this.updateEntries}
                        />
                      </Col>
                      {/* EMAIL ADDRESS */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle('emailAddress')}
                          >
                            Email Address *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="email"
                          name="emailAddress"
                          isInvalid={requiredMissing.emailAddress === true}
                          required
                          value={emailAddress}
                          onChange={this.updateReqEntries}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* SMS OPT-OUT PREFERENCES */}
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      SMS opt-out Preferences (tick to opt out)
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    {[
                      ['Outage Notifications', 'ON'],
                      ['Other Notifications', 'OTN'],
                      ['Company Announcements', 'CA'],
                      ['Community Empowerment', 'CE'],
                    ].map((value) => (
                      <Form.Row key={value[1]}>
                        <Form.Check
                          type="checkbox"
                          id={`optOut-${value[1]}`}
                          label={value[0]}
                          checked={(optOutPreferences || []).includes(value[1])}
                          onChange={(e) => {
                            const code = value[1];
                            console.log(
                              'code:',
                              code,
                              'checked',
                              e.target.checked,
                            );
                            if (e.target.checked) {
                              this.setEntries(
                                'optOutPreferences',
                                optOutPreferences.concat(code),
                              );
                            } else {
                              this.setEntries(
                                'optOutPreferences',
                                optOutPreferences.filter((p) => p !== code),
                              );
                            }
                          }}
                        />
                      </Form.Row>
                    ))}
                  </Form.Group>

                  {/* to implement component,
                  initalise parent state 'entries' with the initEntriesWithMap function,
                  and similarly with 'requiredMissing' */}
                  <AddressInput
                    entries={entries}
                    updateEntries={this.updateEntries}
                    requiredMissing={requiredMissing}
                    updateReqEntries={this.updateReqEntries}
                    onResultFound={this.onResultFound}
                    page="profile"
                  />
                </Form>
              </Card.Body>
              <Card.Footer>
                <Form.Group id="finalButtons">
                  <Link to="/home">
                    <Button variant="secondary" type="submit">
                      Back
                    </Button>
                  </Link>
                  {!submitting && (
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                      {newProfile && 'Register'}
                      {!newProfile && 'Save and continue'}
                    </Button>
                  )}
                  {submitting && <Spinner />}
                  {/* DEV TODO: delete */}
                  {/* <Button onClick={() => this.setState({ showModal: true })}>
                    show
                  </Button> */}
                  {/* <Button
                    onClick={() => {
                      const { entries } = this.state;
                      const toUpdate = { ...entries };
                      toUpdate["streetNumber"] = "a";
                      toUpdate["streetName"] = "b";
                      toUpdate["suburb"] = "c";
                      this.setState({ entries: toUpdate });
                    }}
                  >
                    set fields
                  </Button> */}
                  {/* DEV TODO: delete */}
                </Form.Group>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={2} />
        </Row>
        <OTPModal
          onChangeOTP={(OTP) => this.setEntries('otp', OTP)}
          showModal={showModal}
          setShowModal={(s) => this.setState({ showModal: s })}
          setSubmitting={(submit) => this.setState({ submit })}
          mobileNumber={mobileNumber}
          handleOTP={(setOtp, otp) => handleOTP(
            register,
            setAuth,
            entries,
            (s) => this.setState(s),
            setOtp,
            otp,
          )}
        />
      </Container>
    );
  }
}

export const RegistrationForm = () => <ProfileForm newProfile />;
