/* eslint-disable no-template-curly-in-string */
import {
  addressMapping,
  newProfileMapping,
  userMapping,
} from '../constantsGlobal';

export const siemensProfileMapping = {
  ...newProfileMapping,
  user: { ...userMapping.user, username: 'username' },
  address: { ...addressMapping.address, countryCode: 'ZA' },
  billingAddress: { ...addressMapping.address, countryCode: 'ZA' },
  orgUnit: 'orgUnitCode',
  paymentTerms: 'paymentTermsDays',
  VATNumber: 'taxRegNumber',
  faxNumber: 'faxNumber',
};

//  key = entries name on client side
//  value = json name on server side
export const createTicketMapping = {
  ...siemensProfileMapping,
  type: 'typeCode',
  priority: 'priorityCode',
  code: 'code',
  createdDate: 'createdDate',
  appointment: 'appointment',
  technicianName: 'contactName',
  technicianNumber: 'contactNumber',
  technicianEmail: 'contactEmail',
  ccEmail: 'contactEmailCC',
  PONumber: 'custom1',
  authorityName: 'custom2',
  authorityNumber: 'custom3',
  quoteNumber: 'custom4',
  CIReference: 'custom5',
  callLoggerDetails: 'custom8',
  problemDescription: 'description',
  customerCode: 'customerCode',
};

export const ticketsTableColumns = {
  jsonKeys: [
    'code',
    'description',
    'typeCode',
    'statusDescription',
    // "open",
    'orgUnitCode',
    'createdDate',
    'appointment',
  ],
  headers: [
    'code',
    'description',
    'type',
    'status',
    // "open",
    'orgUnit',
    'dateCreated',
    'appointment',
  ],
  headerNames: [
    'Code',
    'Description',
    'Request Type',
    'Status',
    // "Open?",
    'Org Unit',
    'Date Created',
    'Appointment',
  ],
  widths: Object.values({
    code: 130,
    description: 320,
    type: 100,
    status: 180,
    // open: 80,
    orgUnit: 100,
    dateCreated: 140,
    appointment: 140,
  }),
};

export const quotationsTableColumns = {
  jsonKeys: [
    'code',
    'description',
    'typeCode',
    'statusDescription',
    'createdDate',
    'priorityCode',
  ],
  headers: [
    'code',
    'description',
    'type',
    'status',
    'dateCreated',
    'priority',
  ],
  headerNames: [
    'Code',
    'Description',
    'Request Type',
    'Status',
    'Date Created',
    'Priority',
  ],
  widths: Object.values({
    code: 260,
    description: 260,
    type: 260,
    status: 260,
    dateCreated: 260,
    priority: 260,
  }),
};

// export const assetsTableColumns = {
//   jsonKeys: [
//     "id",
//     "code",
//     "description",
//     "parentAsset.description",
//     "assetType.description",
//     "status.description",
//     "structureCode",
//   ],
//   headers: [
//     "id",
//     "code",
//     "description",
//     "parentAsset.description",
//     "assetType.description",
//     "status.description",
//     "structureCode",
//   ],
//   headerNames: [
//     "id",
//     "code",
//     "description",
//     "parentAsset.description",
//     "assetType.description",
//     "status.description",
//     "structureCode",
//   ],
//   widths: [
//     100,
//     260,
//     130,
//     130,
//     80,
//     150,
//     157,
//     157
//   ],
// };

// TEST DATA
export const testProfile = {
  id: 38195,
  code: 'PRASA',
  description: 'PRASA',
  contactPerson: '',
  mobileNumber: '',
  officeNumber: '',
  homeNumber: '',
  faxNumber: '',
  emailAddress: '',
  standardDiscount: 0.0,
  paymentTermsDays: 30,
  username: null,
  taxRegNumber: '',
  joinDate: 1486458000000,
  notes: null,
  parentCode: null,
  parentId: null,
  statusCode: null,
  typeCode: 'DOM',
  active: true,
  address: {
    description: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '56',
    address5: 'Doctor Ribeiro Drive',
    address6: 'Naledi',
    address7: 'Pretoria',
    address8: '0122',
    countryCode: 'ZA',
    latitude: -25.71833587821103,
    longitude: 28.344981015524255,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    routePoints: null,
    geomArea: 0.0,
  },
  orgUnitCode: 'OU1019',
  priorityCode: null,
  user: {
    username: 'rmanager',
    password: 'e1be31b7d05b2b2794ba4b688de7b9824a24184d',
    fullName: 'Regional Manager PRASA',
    passwordHint: 'rmanager',
    otp: '',
    custom1: null,
    custom2: null,
    custom3: null,
    custom4: null,
    custom5: null,
    employeeNumber: '',
    customDate1: null,
    customDate2: null,
    customDate3: null,
    customDate4: null,
    customDate5: null,
    termsAcceptedDate: null,
    email: '@',
    phoneNumber: '',
    mobileNumber: '',
    customerCode: null,
  },
  alertFlags: [],
  optOutSMSCategories: [],
  custom1: null,
  custom2: null,
  custom3: null,
  custom4: null,
  custom5: null,
  custom6: null,
  custom7: null,
  custom8: null,
  custom9: null,
  custom10: null,
  customNumber1: 0.0,
  customNumber2: 0.0,
  customNumber3: 0.0,
  customNumber4: 0.0,
  customNumber5: 0.0,
  customNumber6: 0.0,
  customNumber7: 0.0,
  customNumber8: 0.0,
  customNumber9: 0.0,
  customNumber10: 0.0,
  customLookupCode1: null,
  customLookupCode2: null,
  customLookupCode3: null,
  customLookupCode4: null,
  customLookupCode5: null,
  customLookupCode6: null,
  customLookupCode7: null,
  customLookupCode8: null,
  customLookupCode9: null,
  customLookupCode10: null,
  customDate1: null,
  customDate2: null,
  customDate3: null,
  customDate4: null,
  customDate5: null,
  customDate6: null,
  customDate7: null,
  customDate8: null,
  customDate9: null,
  customDate10: null,
  custom11: null,
  custom12: null,
  custom13: null,
  custom14: null,
  custom15: null,
  custom16: null,
  custom17: null,
  custom18: null,
  custom19: null,
  custom20: null,
  custom21: null,
  custom22: null,
  custom23: null,
  custom24: null,
  custom25: null,
  custom26: null,
  custom27: null,
  custom28: null,
  custom29: null,
  custom30: null,
  customTransient1: null,
  customTransient2: null,
  customTransient3: null,
  customTransient4: null,
  customTransient5: null,
  customTransient6: null,
  customTransient7: null,
  customTransient8: null,
  customTransient9: null,
  customTransient10: null,
  customMultipleChoice1: [],
  customMultipleChoice2: [],
  customMultipleChoice3: [],
  customMultipleChoice4: [],
  customMultipleChoice5: [],
  accountManager: null,
  billingAddress: {
    description: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '56',
    address5: 'Doctor Ribeiro Drive',
    address6: 'Naledi',
    address7: 'Pretoria',
    address8: '0122',
    countryCode: 'ZA',
    latitude: -25.71833587821103,
    longitude: 28.344981015524255,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    routePoints: null,
    geomArea: 0.0,
  },
  customerType: 'DOM',
  customerStatus: null,
  assetTypeCode: 'DOM',
};

export const workRequestData = {
  id: 112,
  code: 'WR5786Y',
  description: 'af',
  typeCode: 'SLAREPA',
  childWorkType: null,
  statusCode: '01',
  statusDescription: 'Created',
  appointment: null,
  priorityCode: 'ME',
  orgUnitCode: 'OU1019',
  customerCode: 'PRASA',
  customerDescription: 'PRASA',
  custom1: '',
  custom2: '',
  custom3: '',
  custom4: '',
  custom5: '',
  custom6: null,
  custom7: null,
  custom8: '',
  custom9: null,
  custom10: null,
  custom11: null,
  custom12: null,
  custom13: null,
  custom14: null,
  custom15: null,
  custom16: null,
  custom17: null,
  custom18: null,
  custom19: null,
  custom20: null,
  customNumber1: 0.0,
  customNumber2: 0.0,
  customNumber3: 0.0,
  customNumber4: 0.0,
  customNumber5: 0.0,
  customLookupCode1: null,
  customLookupCode2: 'prasa',
  customLookupCode3: null,
  customLookupCode4: null,
  customLookupCode5: null,
  address: {
    description: '',
    address1: null,
    address2: null,
    address3: '',
    address4: '56',
    address5: 'Doctor Ribeiro Drive',
    address6: 'Naledi',
    address7: 'Pretoria',
    address8: '0122',
    provinceCode: '',
    countryCode: 'ZA',
    latitude: 0.0,
    longitude: 0.0,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    routePoints: null,
    geomArea: 0.0,
  },
  createdDate: 1615538448000,
  customDate1: null,
  customDate2: null,
  customDate3: null,
  customDate4: null,
  customDate5: null,
  customDate6: null,
  customDate7: null,
  customDate8: null,
  customDate9: null,
  customDate10: null,
  plannedStartDate: null,
  durationHours: null,
  systemStatusId: 5,
  assetCodes: [],
  assetCodesToRemove: [],
  resolutionCode: null,
  contactName: 'PRASA',
  contactNumber: '1234',
  contactEmail: '',
  parentWorkOrderCode: 'WO58637',
  resourceFeedback: null,
  documents: [],
  images: [],
  notes: [],
  docType: 'WorkOrderRequest',
  billOfMaterialsGroup: null,
  workRequestCodes: [],
  allocatedToText: null,
  inspectionListGroup: null,
  systemStatus: 'CREATED',
  workType: 'SLAREPA',
};

export const testWorkRequest = {
  id: 7,
  code: 'WR5628Y-WAR',
  description: 'Faulty ECC Rack ',
  typeCode: 'WR',
  statusCode: '02',
  statusDescription: 'Ready for Dispatching',
  appointment: 1589279040000,
  priorityCode: 'ME',
  customerCode: 'PRASA',
  custom1: '',
  custom2: '',
  address: {
    description: 'Sender Schienenkontakt M43, 5m Kabel',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    address5: '',
    address6: '',
    address7: '',
    address8: '',
    countryCode: 'ZA',
    latitude: 0.0,
    longitude: 0.0,
    requiresGeocoding: false,
    requiresReverseGeocoding: false,
    firmCoordinate: false,
    geomArea: 0.0,
  },
  createdDate: 1589192672000,
  systemStatusId: 6,
  assetCodes: [],
  assetCodesToRemove: [],
  contactName: 'Jonty',
  contactNumber: '',
  contactEmail: '',
  parentWorkOrderCode: 'WO58576',
  documents: [],
  images: [],
  notes: [],
  docType: 'WorkOrderRequest',
  workRequestCodes: [],
  workType: 'WR',
  systemStatus: 'IN_PROGRESS',
};

export const testCustomerTypes = [
  {
    label: 'Agricultural',
    value: 'AGR',
    flag: false,
    flag2: false,
    value2: null,
    id: 1356,
    fieldHandler: null,
  },
  {
    label: 'Commercial',
    value: 'COM',
    flag: false,
    flag2: false,
    value2: null,
    id: 1354,
    fieldHandler: null,
  },
  {
    label: 'DOT Site',
    value: 'DOTS',
    flag: false,
    flag2: false,
    value2: null,
    id: 24675,
    fieldHandler: null,
  },
  {
    label: 'Industrial',
    value: 'Ind',
    flag: false,
    flag2: false,
    value2: null,
    id: 1355,
    fieldHandler: null,
  },
  {
    label: 'null',
    value: '0',
    flag: false,
    flag2: false,
    value2: null,
    id: 2474,
    fieldHandler: null,
  },
  {
    label: 'Domestic',
    value: 'DOM',
    flag: false,
    flag2: false,
    value2: null,
    id: 1353,
    fieldHandler: null,
  },
];

export const testQuotationsTable = [
  {
    'workRequestStatus.description': 'Quotation Issued',
    dateCreated: 1590581834000,
    code: 'Q0006',
    description: 'Fishaways Quotation',
    custom2: null,
    id: 33,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
  {
    'workRequestStatus.description': 'Quotation Accepted',
    dateCreated: 1595206917000,
    code: 'Q0009',
    description: 'Maintenance',
    custom2: null,
    id: 40,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
  {
    'workRequestStatus.description': 'Created',
    dateCreated: 1615366919000,
    code: 'Q0012',
    description: 'abc',
    custom2: null,
    id: 102,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
  {
    'workRequestStatus.description': 'Accepted',
    dateCreated: 1590582548000,
    code: 'Q0007',
    description: 'Adhoc quote ',
    custom2: null,
    id: 122640582490072,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
  {
    'workRequestStatus.description': 'Accepted',
    dateCreated: 1590587970000,
    code: 'Q0008',
    description: 'Maintenance',
    custom2: null,
    id: 122640587849553,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
  {
    'workRequestStatus.description': 'Completed',
    dateCreated: 1604925934000,
    code: 'Q0010',
    description: 'was vandalism ',
    custom2: null,
    id: 122654925904919,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
  {
    'workRequestStatus.description': 'Accepted',
    dateCreated: 1613058591000,
    code: 'Q0011',
    description: 'test',
    custom2: null,
    id: 122663058570632,
    'workRequestType.description': 'Maintenance',
    'priority.description': 'Normal',
  },
];

export const testTicketsTable = [
  {
    id: 7,
    code: 'WR5628Y-WAR',
    description: 'Faulty ECC Rack ',
    typeCode: 'WR',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    appointment: 1589279040000,
    priorityCode: 'ME',
    customerCode: 'PRASA',
    custom1: '',
    custom2: '',
    address: {
      description: 'Sender Schienenkontakt M43, 5m Kabel',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589192672000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: 'Jonty',
    contactNumber: '',
    contactEmail: '',
    parentWorkOrderCode: 'WO58576',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'WR',
  },
  {
    id: 8,
    code: 'WR5629Y',
    description: 'Work on ACM200 Module Axle Counter Modul 200',
    typeCode: 'WR',
    statusCode: '01',
    statusDescription: 'Created',
    priorityCode: 'ME',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589280884000,
    systemStatusId: 5,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'CREATED',
    workType: 'WR',
  },
  {
    id: 9,
    code: 'WR5630Y-WAR',
    description: 'Faulty points Machine',
    typeCode: 'WR',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'ME',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589286762000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'WR',
  },
  {
    id: 10,
    code: 'WR5630Y-WAR-WAR',
    description: 'Faulty points Machine',
    typeCode: 'SLA',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'ME',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589288458000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    parentWorkOrderCode: 'WO58583',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'SLA',
  },
  {
    id: 11,
    code: 'WR5628Y-WAR-WAR',
    description: 'Faulty ECC Rack ',
    typeCode: 'SLA',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'ME',
    customerCode: 'PRASA',
    address: {
      description: 'Sender Schienenkontakt M43, 5m Kabel',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589288743000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: 'Jonty',
    contactNumber: '',
    contactEmail: '',
    parentWorkOrderCode: 'WO58582',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'SLA',
  },
  {
    id: 12,
    code: 'WR5631Y-WAR',
    description: 'T',
    typeCode: 'WR',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'UR',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589968732000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: 'Jay',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'WR',
  },
  {
    id: 13,
    code: 'WR5631Y-WAR-WAR',
    description: 'T',
    typeCode: 'SLA',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'UR',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1589968772000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: 'Jay',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'SLA',
  },
  {
    id: 14,
    code: 'WR5632Y-WAR',
    description: '&nbsp;Warranty Replacement',
    typeCode: 'WARE',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'ME',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1590059766000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    parentWorkOrderCode: 'WO58581',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'WARE',
  },
  {
    id: 15,
    code: 'WR5632Y-SLA',
    description: '&nbsp;Warranty Replacement',
    typeCode: 'WAR',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'ME',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    address: {
      description: 'Axle Counter Modul 200',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1590059798000,
    systemStatusId: 6,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'WAR',
  },
  {
    id: 18,
    code: 'WR5634Y',
    description: 'Work on Amber Amber',
    typeCode: 'WR',
    statusCode: '01',
    statusDescription: 'Created',
    priorityCode: 'ME',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: 'Amber',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1590398767000,
    systemStatusId: 5,
    assetCodes: ['2182'],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'CREATED',
    workType: 'WR',
  },
  {
    id: 21,
    code: 'WR5637Y-SLA',
    description: 'Work on ACM200 Holder 02AZ',
    typeCode: 'WR',
    statusCode: '02',
    statusDescription: 'Ready for Dispatching',
    priorityCode: 'ME',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: '02AZ',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      countryCode: 'ZA',
      latitude: 0.0,
      longitude: 0.0,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1590406573000,
    systemStatusId: 6,
    assetCodes: ['02AZ'],
    assetCodesToRemove: [],
    contactName: 'Wynand',
    contactNumber: '01132415',
    contactEmail: '',
    parentWorkOrderCode: 'WO58580',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'IN_PROGRESS',
    workType: 'WR',
  },
  {
    id: 22,
    code: 'WR5638Y',
    description: '&nbsp;Warranty Repair',
    typeCode: 'WR',
    statusCode: '01',
    statusDescription: 'Created',
    priorityCode: 'ME',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '56',
      address5: 'Doctor Ribeiro Drive',
      address6: 'Naledi',
      address7: 'Pretoria',
      address8: '0122',
      countryCode: 'ZA',
      latitude: -25.71833587821103,
      longitude: 28.344981015524255,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1590418222000,
    systemStatusId: 5,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'CREATED',
    workType: 'WR',
  },
  {
    id: 23,
    code: 'WR5639Y',
    description: '&nbsp;Warranty Repair',
    typeCode: 'WR',
    statusCode: '01',
    statusDescription: 'Created',
    priorityCode: 'ME',
    orgUnitCode: 'OU1019',
    customerCode: 'PRASA',
    custom1: '',
    address: {
      description: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '56',
      address5: 'Doctor Ribeiro Drive',
      address6: 'Naledi',
      address7: 'Pretoria',
      address8: '0122',
      countryCode: 'ZA',
      latitude: -25.71833587821103,
      longitude: 28.344981015524255,
      requiresGeocoding: false,
      requiresReverseGeocoding: false,
      firmCoordinate: false,
      geomArea: 0.0,
    },
    createdDate: 1590479139000,
    systemStatusId: 5,
    assetCodes: [],
    assetCodesToRemove: [],
    contactName: 'Jonty',
    contactNumber: '',
    contactEmail: '',
    documents: [],
    images: [],
    notes: [],
    docType: 'WorkOrderRequest',
    workRequestCodes: [],
    systemStatus: 'CREATED',
    workType: 'WR',
  },
];

export const testTypesList = [
  {
    label: 'Warranty', value: 'WAR', attributes: {}, fieldHandler: null,
  },
  {
    label: 'SLA', value: 'SLA', attributes: {}, fieldHandler: null,
  },
  {
    label: 'Quotation', value: 'QA', attributes: {}, fieldHandler: null,
  },
  {
    label: 'Assessment', value: 'Assess', attributes: {}, fieldHandler: null,
  },
  {
    label: 'General Assistance',
    value: 'GA',
    attributes: {},
    fieldHandler: null,
  },
  {
    label: 'Enquiry', value: 'EQ', attributes: {}, fieldHandler: null,
  },
];

export const testSubtypesList = [];

export const testPrioritiesList = [
  {
    label: 'High', value: 'HI', attributes: {}, fieldHandler: null,
  },
  {
    label: 'Normal', value: 'ME', attributes: {}, fieldHandler: null,
  },
  {
    label: 'Low', value: 'LO', attributes: {}, fieldHandler: null,
  },
];

export const testTechniciansList = [
  {
    label: 'Abel Mosala ',
    value: 'amosala',
    flag: false,
    flag2: false,
    value2: null,
    id: 29877,
    fieldHandler: null,
  },
  {
    label: 'Abraham Myeni',
    value: 'amyeni',
    flag: false,
    flag2: false,
    value2: null,
    id: 29840,
    fieldHandler: null,
  },
  {
    label: 'Ayanda Buthelezi',
    value: 'abuthelezi',
    flag: false,
    flag2: false,
    value2: null,
    id: 29828,
    fieldHandler: null,
  },
  {
    label: 'Branch Manager PRASA',
    value: 'bmanager',
    flag: false,
    flag2: false,
    value2: null,
    id: 29854,
    fieldHandler: null,
  },
  {
    label: 'Brent Tehini',
    value: 'btehini',
    flag: false,
    flag2: false,
    value2: null,
    id: 29858,
    fieldHandler: null,
  },
  {
    label: 'Deon Naidoo',
    value: 'dnaidoo',
    flag: false,
    flag2: false,
    value2: null,
    id: 29806,
    fieldHandler: null,
  },
  {
    label: 'Dimpho Lesholu ',
    value: 'dlesholu',
    flag: false,
    flag2: false,
    value2: null,
    id: 29824,
    fieldHandler: null,
  },
  {
    label: 'Forcelink Support',
    value: 'superadmin',
    flag: false,
    flag2: false,
    value2: null,
    id: 29798,
    fieldHandler: null,
  },
  {
    label: 'Hope Phiri ',
    value: 'hphiri',
    flag: false,
    flag2: false,
    value2: null,
    id: 29832,
    fieldHandler: null,
  },
  {
    label: 'Kamlan Gounden ',
    value: 'kgounden',
    flag: false,
    flag2: false,
    value2: null,
    id: 29818,
    fieldHandler: null,
  },
  {
    label: 'Keshav Misra',
    value: 'kmisra',
    flag: false,
    flag2: false,
    value2: null,
    id: 29842,
    fieldHandler: null,
  },
  {
    label: 'Lebeau Nel',
    value: 'lebeaun',
    flag: false,
    flag2: false,
    value2: null,
    id: 29820,
    fieldHandler: null,
  },
  {
    label: 'Mandla Mahlobo',
    value: 'mmahlobo',
    flag: false,
    flag2: false,
    value2: null,
    id: 29856,
    fieldHandler: null,
  },
  {
    label: 'Mfanafuthi  Myeni',
    value: 'mmyeni',
    flag: false,
    flag2: false,
    value2: null,
    id: 29816,
    fieldHandler: null,
  },
  {
    label: 'National Manager',
    value: 'nmanger',
    flag: false,
    flag2: false,
    value2: null,
    id: 29848,
    fieldHandler: null,
  },
  {
    label: 'National Manager',
    value: 'nmanager',
    flag: false,
    flag2: false,
    value2: null,
    id: 29850,
    fieldHandler: null,
  },
  {
    label: 'Patrick',
    value: 'patrick',
    flag: false,
    flag2: false,
    value2: null,
    id: 29808,
    fieldHandler: null,
  },
  {
    label: 'Paul Beyard',
    value: 'pbeyard',
    flag: false,
    flag2: false,
    value2: null,
    id: 29834,
    fieldHandler: null,
  },
  {
    label: 'PRASA',
    value: 'prasa',
    flag: false,
    flag2: false,
    value2: null,
    id: 29812,
    fieldHandler: null,
  },
  {
    label: 'PRASA Jonathan',
    value: 'prasajon',
    flag: false,
    flag2: false,
    value2: null,
    id: 29804,
    fieldHandler: null,
  },
  {
    label: 'PRASA Tech 2',
    value: 'prasatech2',
    flag: false,
    flag2: false,
    value2: null,
    id: 29860,
    fieldHandler: null,
  },
  {
    label: 'PRASA Tech 3',
    value: 'prasatech3',
    flag: false,
    flag2: false,
    value2: null,
    id: 29862,
    fieldHandler: null,
  },
  {
    label: 'PRASA Tech 4',
    value: 'prasatech4',
    flag: false,
    flag2: false,
    value2: null,
    id: 29864,
    fieldHandler: null,
  },
  {
    label: 'PRASA Technician',
    value: 'prasatech',
    flag: false,
    flag2: false,
    value2: null,
    id: 29800,
    fieldHandler: null,
  },
  {
    label: 'Regional Manager PRASA',
    value: 'rmanager',
    flag: false,
    flag2: false,
    value2: null,
    id: 29846,
    fieldHandler: null,
  },
  {
    label: 'Sbusiso Mthethwa ',
    value: 'smthethwa',
    flag: false,
    flag2: false,
    value2: null,
    id: 29826,
    fieldHandler: null,
  },
  {
    label: 'Siemens Front End Users',
    value: 'siemens',
    flag: false,
    flag2: false,
    value2: null,
    id: 29814,
    fieldHandler: null,
  },
  {
    label: 'Sifiso Dlamini',
    value: 'sdlamini',
    flag: false,
    flag2: false,
    value2: null,
    id: 29822,
    fieldHandler: null,
  },
  {
    label: 'Storeman',
    value: 'storeman',
    flag: false,
    flag2: false,
    value2: null,
    id: 29844,
    fieldHandler: null,
  },
  {
    label: 'Tanya Moodley',
    value: 'moodleyt',
    flag: false,
    flag2: false,
    value2: null,
    id: 29802,
    fieldHandler: null,
  },
  {
    label: 'Themba',
    value: 'RS6173',
    flag: false,
    flag2: false,
    value2: null,
    id: 29874,
    fieldHandler: null,
  },
  {
    label: 'Themba',
    value: 'ThembaN',
    flag: false,
    flag2: false,
    value2: null,
    id: 29875,
    fieldHandler: null,
  },
  {
    label: 'Tian Kunneke',
    value: 'tkunneke',
    flag: false,
    flag2: false,
    value2: null,
    id: 29810,
    fieldHandler: null,
  },
  {
    label: 'timothy@2020',
    value: 'tim',
    flag: false,
    flag2: false,
    value2: null,
    id: 29876,
    fieldHandler: null,
  },
  {
    label: 'Tshenge Mukwevho',
    value: 'tmukwevho',
    flag: false,
    flag2: false,
    value2: null,
    id: 29830,
    fieldHandler: null,
  },
  {
    label: 'Victor Mochela',
    value: 'vmochela',
    flag: false,
    flag2: false,
    value2: null,
    id: 29838,
    fieldHandler: null,
  },
  {
    label: 'View Only',
    value: 'viewonly',
    flag: false,
    flag2: false,
    value2: null,
    id: 29872,
    fieldHandler: null,
  },
  {
    label: 'Vitumbiko Nundwe ',
    value: 'vnundwe',
    flag: false,
    flag2: false,
    value2: null,
    id: 29836,
    fieldHandler: null,
  },
];

/*
 * All labels for Siemens
 * TODO: use {apiUrl}/rest/subscribercustomlabelsmanager/getAllLabels
 */
export const allLabels = {
  'WorkOrder.priority': 'Urgency',
  'serializedassetmovements.heading': 'Serialised Asset Movements',
  'statusList.heading': 'Edit Statuses',
  'js.viewWorkLog': 'View Work Log',
  'Building.locationAddress': 'Building Address',
  'assetStatuses.title': 'Asset Statuses',
  'js.printSchematicLegend': 'Print Legend',
  'smscampaigncategories.title': 'SMS Campaign Categories',
  'js.workOrderRequest': 'Work Request',
  'Device.deleted': 'Device {0} Deleted',
  'user.accountExpired': 'Expired',
  'call.callType': 'Call Type',
  'StockTake.status.open': 'Open?',
  'js.editInstructionsTitle': 'Edit Instructions',
  'mobile.materialSearchMode': 'Search Mode',
  'PlannedOutage.customNumber2': '',
  'PlannedOutage.customNumber1': '',
  'workOrderRequestMobile.back': 'Back to Work Request',
  'ForcelinkSubcontractor.subscriberId': 'Subscriber ID',
  'availabilityCalendar.id': 'Id',
  'dashboardEntry.inactivated': 'Dashboard Entry {0} inactivated',
  '*.material.supplier': 'Supplier',
  'js.calendarMatrixStartDate': 'Start Date:',
  'loadsheddingtemplates.title': 'Load Shedding Templates',
  'AssetType.parentAssetTypes': 'Allowed Parent Asset Types',
  'menu.viewCrews': 'Crews',
  'FaultReport.durationMins': 'Duration (Minutes)',
  'resource.description': 'Name',
  'menu.activeUserSessions': 'Active User Sessions',
  'assettemplates.heading': 'Asset Templates',
  'js.addStockBatch': 'Batch Add Stock',
  'js.editCronExpression': 'Edit Cron Expression',
  'PlannedOutage.customNumber4': '',
  'PlannedOutage.customNumber3': '',
  'Contract.supplier.description': 'Supplier',
  'PlannedOutage.customNumber6': '',
  'Resource.resourceType.description': 'Type',
  'PlannedOutage.customNumber5': '',
  'WorkOrder.images': 'Images',
  'PlannedOutage.customNumber8': '',
  'PlannedOutage.customNumber7': '',
  'Device.deviceType.code': 'Device Type Code',
  'PlannedOutage.customNumber9': '',
  'Lease.contactEmail': 'Contact Email',
  'AbnormalPlant.deviceDescription': 'Device/Location',
  '*.material.supplier.description': 'Supplier',
  'js.ungroupFromCrew': 'Ungroup from Crew',
  'IncomingSMSMessage.workRequest.code': 'Work request Code',
  'User.signatureImage': 'Signature Image',
  'Asset.ownerCustomer.custom30': '',
  'suppliers.heading': 'Suppliers',
  'js.createWorkOrderFromInspectionGroupClone':
    'Create Work Order (Copy inspection items)',
  'material.assetTypes': 'Associated Asset/Work Types',
  '*.customer.custom29': '',
  '*.customer.custom26': '',
  '*.customer.custom25': '',
  '*.customer.custom28': '',
  'PlannedOutage.depot.description': 'Depot',
  '*.customer.custom27': '',
  'js.copyDevice': 'Copy Device',
  'UserGroup.code': 'Group Code',
  'js.saveChanges': 'Save Changes',
  'WorkOrderRequest.customer.billingAddress.address2': '',
  'WorkOrderRequest.customer.billingAddress.address1': '',
  'QueryMapLineLayer.heading': 'Query Line Layer',
  'ReferenceListItems.title': 'Edit Reference List Items',
  '*.customer.emailAddress': 'E-mail Address',
  '*.customer.custom19': '',
  '*.customer.custom18': '',
  '*.customer.custom15': '',
  '*.customer.custom14': '',
  '*.customer.custom17': '',
  'js.selectAssetsWithScannerInstructions':
    'Please start scanning asset barcodes',
  '*.customer.custom16': '',
  'menu.viewAssets': 'Assets',
  'WorkOrderRequest.customer.billingAddress.address6': '',
  'errors.maxlength': '{0} can not be greater than {1} characters.',
  'WorkOrderRequest.customer.billingAddress.address5': '',
  'errors.supportSmsFail': 'Unable to send support sms to {0}',
  'WorkOrderRequest.customer.billingAddress.address4': '',
  'WorkOrderRequest.customer.billingAddress.address3': '',
  'Customer.customMultipleChoice5': '',
  'Customer.customMultipleChoice4': '',
  'errors.password.concurrentLogin':
    'This user is already logged in on another session. Concurrent logins are not allowed.',
  'Customer.customMultipleChoice3': '',
  'WorkOrderRequest.customer.billingAddress.address7': '',
  'Customer.customMultipleChoice2': '',
  '*.customer.custom22': '',
  '*.customer.custom21': '',
  '*.customer.custom24': '',
  'WorkOrderRequest.customMultipleChoice1': 'Functions to be Supplied',
  '*.customer.custom23': '',
  'js.dynamicDescriptionRule': 'Dynamic Description rule',
  'WorkOrderRequest.customMultipleChoice3': '',
  'WorkOrderRequest.customMultipleChoice2': '',
  '*.customer.custom20': '',
  'WorkOrderRequest.customMultipleChoice5': '',
  'customerportal.workOrderRequest.assets': 'Assets',
  'WorkOrderRequest.customMultipleChoice4': '',
  'WorkOrder.alert.etaExceeded':
    'Estimated Time of Arrival for Work Order was exceeded ',
  'WorkRequestSubcontractorAssignmentRule.workRequestSort.hint':
    'Please supply a comma-separated list of sort criteria (e.g. customer.description,dateCreated)',
  'address.addressType': 'Address Type',
  'assetstock.button.addBatch': 'Batch create assets in stock',
  'Customer.customMultipleChoice1': '',
  'orgStructure.custom1': '',
  'orgStructure.custom2': '',
  'orgStructure.custom3': '',
  'orgStructure.custom4': '',
  'orgStructure.custom5': '',
  '*.customer.custom11': '',
  '*.customer.custom10': '',
  '*.customer.custom13': '',
  '*.customer.custom12': '',
  'billOfMaterials.totalDue': 'Total Due:',
  'SLA.deleted': 'SLA {0} deleted',
  'PlannedOutage.voltage.code': 'Voltage',
  'Customer.validation.selfReferencingParent':
    'Customer cannot be its own parent',
  'workOrderLog.heading': 'Work Order Log',
  'js.warnSaveResourceBeforeCreateUser':
    'Please save the resource before creating a user',
  'SLA.duration': 'Duration (Minutes)',
  'AssetTypes.heading': 'Asset types',
  '*.images': 'Images',
  'availabilityCalendar.endDateTime': 'End Time',
  'Device.assetType': 'Device Type',
  'js.requiresPhotoIfRuleHint':
    "The item will require a photo if the condition is true either in this field or in another field in the list. Text must be formatted as follows: ${value}=='<a value to check for in this field>' && ${<field id value to look up>}=='<value to check for>' && ${<another field id value to look up>}=='<value to check for>' || (<more criteria>), etc",
  'user.id': 'Id',
  'menu.viewMyCompanyWorkOrders': 'My Company Work Orders',
  'StockTake.error.cannotAdjustSerialisedByQuantity':
    "You cannot adjust serialised stock by quantity, please adjust under 'Expected Serialised Items'",
  'billOfMaterials.totalVat': 'Total VAT:',
  'Contract.contractbomgroupsDefaultColumnView': '',
  'menu.viewCustomLabels': 'Custom Labels',
  'quotationMobile.back': 'Back to Quotation',
  'js.pasteAsset': 'Paste Asset',
  'material.pricing': 'Pricing',
  'WorkOrder.contactEmail': 'Technician Responsible e-mail',
  'errors.float': '{0} must be a float (decimal number).',
  'WorkOrderRequest.workOrder.code': 'Work Order',
  'button.refresh': 'Refresh',
  'Supplier.custom10': '',
  'BillOfMaterialsItem.store': 'Store',
  'DashboardEntry.colour': 'Colour',
  'user.email': 'Email',
  'customerportal.quotations.title': 'Quotations',
  'js.moveToOtherPO': 'Move Item to other PO',
  'Resource.labourServices': 'Labour / Services',
  'js.clearParentCustomer': 'Clear Parent Customer',
  'js.changeShiftEndTimeForName': 'Change Shift End Time for {0}?',
  'SchematicDiagram.pageSize.description': 'Page Size',
  'resource.pauseRefreshMode': 'Pause Refresh Mode',
  'SwitchingEvent.notes': 'Notes',
  'PurchaseRequisition.customerAndAddress': 'Customer and Address',
  'menu.viewSerialisedAssetMovements': 'Serialised Asset Movements',
  'WorkOrder.groupSelectedWorkRequestsToWorkOrderFeedback':
    '{0} work requests grouped. The remainder will continue in the background. You will be notified of progress.',
  'AssetCategory.depreciationType.description': 'Depreciation Type',
  'Crew.customDate10': '',
  'AssetCategory.depreciationRates.title':
    'Comma-separated lists of depreciation percentage for each year',
  'Resource.javaFilesLocation': 'Forcelink files path     ',
  'BillOfMaterialsItem.transactionType': 'Transaction Type',
  'js.createNewParentForSelectedAssets':
    'Create new parent for selected assets',
  'StockTake.expectedSerialisedItems': 'Expected Serialised Items',
  'InspectionListItem.largeFormat.dateCaptured': '',
  'customerMobile.quotationRequest': 'Quotation',
  'smartCityPortal.homePage.private': 'Private Services',
  'ResourceForm.image.title': 'Double-click to change',
  'Asset.customNumber8': '',
  'js.createSMSCampaignForSelectedCustomers':
    'Create SMS Campaign for selected customers',
  'Asset.customNumber9': '',
  'subscriberDatabase.keepMasterAndTransactionData':
    'Keep Master and Transactional Data?',
  'orgStructure.address': 'Org Unit Address ',
  'Asset.added': 'Asset {0} Added',
  'WorkOrderRequestResources.assignmentCancelledMessage':
    '{0} {1} has been cancelled.',
  'Invoice.billOfMaterialsGroup.formattedTotalPriceIncl': 'Price Incl',
  'Lease.building.floorArea': 'Floor Area ',
  'assetStatus.title': 'Asset Status',
  'Customer.treeDescription': 'Customer Hierarchy',
  'customerMobile.quotationRequestNumber': 'Quotation Number',
  'WorkOrder.createdDateTime': 'Date Created',
  'StockTake.status.systemStatus.open': 'Open?',
  'Invoice.add': 'Add',
  'ScheduledWorkTemplate.inspectionFeedbackTriggerExpr':
    'Inspection trigger Expression',
  'testREST.option.workOrder': 'Work Order (/rest/workorder)',
  'mobile.listScheduledWorkOrders': 'Allocated Call Outs',
  'workOrderMobile.noWorkOrderRequests':
    'There are no Work Requests to display.',
  'js.viewAssetDetail': 'View Asset Detail',
  'js.deSelect': 'De-select',
  'user.accountcreated.emailsubject': 'Forcelink Account Created',
  'BillOfMaterialsItem.transactionType.description': 'Transaction Type',
  'FaultReport.station.description': 'Substation',
  'js.changeCurrentSwitchStates': 'Change Current Switch States',
  'AuditTrail.oldId': 'Old ID',
  'WorkOrder.customLookup15': '',
  'SMSCampaign.status.description': 'Status',
  'WorkOrder.timeSlot': '',
  'WorkOrder.customLookup10': '',
  'CallTake.customer.code.placeholder': 'Click to search',
  'billOfMaterials.labourChargeType': 'Labour Charge Type',
  'WorkOrder.customLookup13': '',
  'button.addSignature': 'Add Signature',
  'WorkOrder.customLookup14': '',
  'js.mapContextHideAddresses': 'Hide Addresses',
  'WorkOrder.customLookup11': '',
  'WorkOrder.customLookup12': '',
  'Asset.customNumber4': '',
  'Asset.expectedEndOfLife': 'Expected End Of Life',
  'Asset.customNumber5': '',
  'Asset.customNumber6': '',
  'Asset.customNumber7': '',
  'Material.materialType.treeDescription': 'Type Hierarchy',
  'Asset.customNumber1': 'Work Count',
  'webapp.name': 'forcelink',
  'Asset.customNumber2': '',
  'Asset.customNumber3': '',
  'QueryMapLayer.minZoom': 'Minimum Zoom',
  'Resource.custom9': '',
  'js.linkChildAssetInspectionRule': 'Link Child Asset Inspection rule',
  'workorderscheduler.heading': 'Scheduler',
  'Resource.custom4': 'Telephone number (Additional landline)',
  'Resource.custom3': 'Telephone number (Work landline)',
  'Resource.custom2': 'Next of kin Contact number ',
  'Resource.custom1': 'Next of kin',
  'Resource.custom8': '',
  'Lease.pricePerSquareMeter': 'Price per m2',
  'Resource.custom7': '',
  'Resource.custom6': '',
  'Resource.custom5': 'Company Position',
  'Crew.resourceType': 'Resource Type',
  'Device.deviceSubType': 'Sub Type',
  'resource.user.username': 'Username',
  'js.pleaseSelectSubcontractor': 'Please select a sub-contractor',
  'assetstocksummary.title': 'Asset Stock Summary',
  'js.projectAddTaskAbove': 'Add task above',
  'AbnormalPlant.title': 'Abnormal Plant ',
  'ResourceType.emailOffDutyFlag': 'Email when off duty?',
  'FaultReport.customNumber6': '',
  'FaultReport.customNumber7': '',
  'js.issueBOMItem': 'Issue Item',
  'FaultReport.customNumber8': '',
  'Asset.customTransient1.format': '###,##0.00',
  'FaultReport.customNumber9': '',
  'FaultReport.customNumber2': '',
  'FaultReport.customNumber3': '',
  'js.viewCustomerInvoices': 'View Customer Invoices',
  'FaultReport.customNumber4': '',
  'FaultReport.customNumber5': '',
  'Asset.constructionDate': 'Construction Date',
  'errors.twofields':
    'The {0} field has to have the same value as the {1} field.',
  'allocateselected.heading': 'Allocate Resources to Selected',
  'menu.requestDatabaseWritePermission': 'Request database write permission',
  'LabelValueFlag.label': 'Label',
  'SupportPerson.firstName': 'First Name',
  'InboxEventUser.acknowledgedDate': 'Acknowledged Date',
  'CustomMatrixView.groupByProperty': 'Group By Property',
  'DeviceType.customNumber5': '',
  'DeviceType.customNumber4': '',
  'DeviceType.customNumber1': '',
  'DeviceType.customNumber3': '',
  'DeviceType.customNumber2': '',
  'StockTake.billOfMaterials': 'Adjustments',
  'Asset.viewInTree': 'View in Tree',
  'resource.employer': 'Employer',
  'ResourceShiftRecord.shiftType.description': 'Shift Type',
  'button.manualAddress': 'Manual Address',
  'ForcelinkBilling.title': 'Forcelink Billing',
  'FaultReport.customNumber1': '',
  'TableValidationRule.additionalPrintReportFiles':
    'Additional Print Report Files',
  'js.confirmCreateNewWorkOrderForWorkRequest':
    'There is already a Work Order linked to this Work Request. If you create a new one, it will be replaced. Are you sure you want to do this?',
  'SwitchingInstruction.permitNo': 'Permit No',
  'js.autoRefreshTitle': 'Auto Refresh',
  'SwitchingInstruction.operationType': 'Operation Type',
  'menu.allocateOrgStructure': 'Calc. Org Units',
  'FaultReport.department': 'Department to Investigate',
  'BillOfMaterialsItem.createdDate': 'Created Date',
  'failteirelandfeeschedule.title': 'Failte Ireland Fee Schedule',
  'stocktakes.title': 'Stock Takes',
  'ForcelinkSubcontractor.custom2': '',
  'ForcelinkSubcontractor.custom1': '',
  'ForcelinkSubcontractor.custom5': '',
  'js.partiallyRestoreDevicesPlanned': 'Partially Restore Device(s) (planned)',
  'ForcelinkSubcontractor.custom4': '',
  'ForcelinkSubcontractor.custom3': '',
  'FlashReport.faultReport.station.description': 'Substation',
  'Contract.startDate': 'Start Date',
  'CustomManagerImpl_cp_mdt.twitterPlannedOutageRestored':
    'Planned Outage at ${station.description} restored at {timeStamp}. {shortURL}',
  'mobile.stockOnHand': 'Stock on Hand',
  'Project.task.backgroundColor':
    '${workOrderStatus.systemStatus.projectTaskColour}',
  'menu.billingadmin.pricing': 'Forcelink Pricing',
  'Resource.distanceFromCentre': 'Distance',
  'Device.circuit': 'Circuit',
  'DashboardEntry.columnSize': 'Column Size',
  'SupplierQuotation.statusDescription': 'Status',
  'call.custom7': '',
  'call.custom8': '',
  'call.custom5': '',
  'call.custom6': '',
  'call.custom3': '',
  'call.custom4': '',
  'call.custom1': '',
  'call.custom2': '',
  'js.viewPeriodDay': 'Day',
  'mobile.inspectionListItem.notes': 'Notes',
  'Scheduler.viewType': 'View Type',
  'testREST.option.workRequest': 'Work Request (/rest/workrequest)',
  'call.custom9': '',
  'mobile.notAvailable': 'Not Available',
  'AbnormalPlant.faultReport.depot.description': 'Depot/Area',
  'faultreportworkrequests.heading2':
    'Trouble Calls linked to Outage {0} from {1}',
  'FlashReport.faultReport.code': 'Code',
  'mobile.pleaseCallMeText':
    "Enter (optional) message for 'Please Call Me' request",
  'Project.endDate': 'End Date',
  'js.confirmReloadExpectedItems':
    'Reloading expected items will re-load the expected stock counts as of now. Are you sure you want to continue? ',
  'PurchaseRequisition.workType': 'Work Type',
  'AbnormalPlant.depot.description': 'Depot/Area',
  'Call.resolvedDuringCall': 'Resolved During Call?',
  '*.customer.customDate5': '',
  '*.customer.customDate4': '',
  '*.customer.customDate3': '',
  'Lease.building.description': 'Building',
  'js.imageRotateLeft': 'Rotate Left',
  'js.userFiltersSaved': 'User filters saved to Database',
  'Asset.expectedLifeCycles': 'Expected Life Cycles',
  '*.customer.customDate2': '',
  '*.customer.customDate1': '',
  'Invoice.customNumber4': '',
  'WorkOrderRequest.allocationHistory': 'Allocation History',
  'Invoice.customNumber3': '',
  'LoadShedding.workOrderCode': 'Work Order',
  'Invoice.customNumber5': '',
  'js.batchAddAsset': 'Batch add Assets',
  'StockTakeFoundItem.material': 'Material',
  'PlannedOutage.plannedStart': 'Planned Start',
  'Invoice.customNumber2': '',
  'Invoice.customNumber1': '',
  'Quotation.customerOrderNumber': 'Order No',
  'js.requiresNoteIfRule': "'Requires Note If' rule",
  'fieldAccess.assignGroups': 'Assign Group Rights',
  'Building.code': 'Code',
  'mobile.noMatchingMaterialsFound':
    'There are no materials matching this code',
  'Contract.description': 'Description',
  Invoice: 'Invoice',
  'tax.taxName': 'VAT',
  'WorkOrderRequest.customDate10': '',
  'SupportPerson.heading': 'Support Person',
  'WorkOrderRequest.allocatedResources': 'Allocated To',
  'export.completed':
    'Your file export has completed. Please go to Home > Table Exports to download. ',
  'ruleSelections.heading': 'Rules',
  'js.moveSelectedToParent': 'Move Selected Assets to this Parent',
  'PersonalDataExpiryRule.durationMonths': 'Months to expiry',
  'menu.runUnitTest': 'Run unit test',
  'AuditTrail.newId': 'New Id',
  'js.kickOutUserSession': 'Kick out user session',
  'SwitchingEvent.normalState': 'Normal State',
  'LoadShedding.customDate10': '',
  'js.allocateResources': 'Allocate Technicians',
  'AssetType.description': 'Asset Type Description',
  'js.addUser': 'Add User',
  'Assettemplate.newStatus': '',
  'js.zoomMapToSelection': 'Zoom Map to Selection',
  'js.hideConnection': 'Hide Connection',
  'Asset.warrantyEnd': 'Warranty End Date',
  'menu.reports': 'Reports',
  'js.createCustomer': 'Create Customer',
  'js.portalOTPSent':
    'A One-time-PIN has been sent to your mobile number - please enter it here to complete your registration',
  'webapp.tagline': 'mobile@work',
  'ScheduledWorkTemplate.customNumber5': '',
  'ScheduledWorkTemplate.customNumber4': '',
  'ScheduledWorkTemplate.customNumber1': '',
  'ScheduledWorkTemplate.customNumber3': '',
  'ScheduledWorkTemplate.customNumber2': '',
  'Asset.customMultipleChoice1': '',
  'Asset.customMultipleChoice2': '',
  'Asset.customMultipleChoice3': '',
  'passwordReset.invalidUsername':
    "Sorry, this username is invalid. Please contact your administrator or mail 'support@forcelink.net' for assistance.",
  'Lease.customer.description': 'Tenant',
  'Asset.customMultipleChoice4': '',
  'Asset.customMultipleChoice5': '',
  'SupplierQuotation.quotationDate': 'Quotation Date',
  'BillOfMaterialsItem.quantity': 'Quantity',
  'js.outsource': 'Outsource',
  'button.accept': 'Accept',
  'Asset.validation.activeChildren':
    'Cannot inactivate asset with active children',
  'workflowSelectTypes.heading': 'Select Types linked to Workflow',
  'resourceType.title': 'Resource Type',
  'menu.moreOptions': 'More options',
  'Device.projectCode': 'Project Code',
  'WorkOrderRequest.allocate.title': 'Work Order Request Allocation',
  'schematicDiagram.title': 'Schematic Diagram',
  'Asset.scrapValue': 'Scrap Value',
  'inbox.inbox': 'Inbox',
  'mobile.addWorkDocNote': 'Add Work Notes',
  'referenceList.code': 'Code',
  'referenceList.fieldName.table.className': 'Table Name',
  'ShiftType.shiftTypeTextColour': 'Text Colour',
  'User.billingType': 'Billing Type',
  'js.rightClickForOptions': 'Right-click for options',
  'locationAddress.description': 'Address Description',
  'Device.voltageLevel': 'Voltage Level',
  'js.autoGenerateCodes': 'Auto-generate codes',
  'fieldAccess.table': 'Table',
  'js.markSelectedAsDuplicate': 'Mark Selected as Duplicate',
  'PlannedOutage.station.description': 'Substation',
  'FaultReport.startDate': 'Start Date',
  'workorders.standardView': 'Standard View',
  'button.register': 'Signup',
  'customer.title': 'Customer Detail',
  'SMSCampaignCategory.heading': 'SMS Campaign Category',
  'PlannedOutage.schematicDiagramToActivate.diagramName.description':
    'Schematic Diagram to Activate ',
  'menu.viewWorkOrderRequests': 'Work Requests',
  'StatusReference.systemStatus.description': 'System Status',
  'Customer.customerPortalUsers': 'Customer Portal Users',
  'mobile.storeName': 'Store',
  'QueryMapLineLayer.mapTypes': 'Maps to display in',
  'SLAs.title': "SLA's",
  'js.switchingEventMenu.linkAbnormalPlant': 'Link Abnormal Plant',
  'ResourceType.skills': 'Skills',
  'js.viewOutageTroubleCalls': 'View Outage Trouble Calls',
  'SMSCampaign.campaignCategory': 'Campaign Category',
  'resource.rates': 'Rates',
  'Quotation.customLookup9': '',
  'call.customNumber1': '',
  'Quotation.customLookup5': '',
  'Quotation.customLookup6': '',
  'Quotation.customLookup7': '',
  'Quotation.customLookup8': '',
  'call.customNumber5': '',
  'Quotation.customLookup1': '',
  'call.customNumber4': '',
  'Quotation.customLookup2': '',
  'call.customNumber3': '',
  'Quotation.customLookup3': '',
  'call.customNumber2': '',
  'Quotation.customLookup4': '',
  'call.customNumber9': '',
  'call.customNumber8': '',
  'call.customNumber7': '',
  'AssetType.updated': 'Asset Type {0} Updated',
  'call.customNumber6': '',
  'js.createSuspectOutageWithSelectedWorkOrder':
    'Create Suspect outage with selected work order',
  'MaterialStore.custom1': '',
  'MaterialStore.custom2': '',
  'MaterialStore.custom3': '',
  'MaterialStore.custom4': '',
  'mobile.shiftComments': 'Shift Comments',
  Customer: 'Customer',
  'mobile.editBillOfMaterials': 'Edit Bill Of Materials',
  'referenceList.updated': 'Reference has been updated successfully.',
  'ForcelinkPricing.unitPrice': 'Unit Price',
  'inspectionListGroup.largeFormat.heading': 'Inspection List',
  'testREST.dataType': 'Data Type',
  'upload.title': 'File Upload',
  'passwordHint.sentTo': 'Your password reminder has been sent to: {0}',
  'js.editWorkflowRules': 'Edit Workflow Rules',
  'WorkOrder.billOfMaterialsGroup.formattedTotalCostExcl': 'Cost Excl',
  'Call.customLookupCode4': '',
  'Call.customLookupCode5': '',
  'MaterialStore.custom5': '',
  'Call.customLookupCode1': '',
  'Call.customLookupCode2': 'Customer Type ',
  'Call.customLookupCode3': '',
  'Building.assetStatus': 'Status',
  '*.contactPerson.mobileNumber': 'Mobile Number',
  'faultreportworkrequests.heading':
    'Trouble Calls linked to Outage {0} from {1} to {2}',
  'flushCache.heading': 'Flush Successful!',
  'call.customLookup12': '',
  'call.customLookup13': '',
  'call.customLookup14': '',
  'call.customLookup15': '',
  'FieldAccess.table.className': 'Table',
  'customTableColumnSelection.rowFilters': 'Row Filters',
  'call.customLookup10': '',
  'WorkOrderRequest.customer.parentCustomer.homeNumber': '',
  'availabilityCalendar.updated': 'Shift Roster has been updated successfully.',
  'call.customLookup11': '',
  'js.undo': 'Undo',
  'WorkOrder.percentageCompletion': 'Percentage Completion',
  'EquipmentType.operationTypes': 'Operation Types',
  'SLA.priority': 'Priority',
  wednesday: 'Wednesday',
  'Building.custom15': '',
  'Building.custom14': '',
  'Building.custom13': '',
  'Building.custom12': '',
  'Building.custom11': '',
  'Building.custom10': '',
  'menu.devices': 'Devices',
  'User.contactPreferences': 'Contact Preferences',
  'Device.deviceType.deviceCategory.description': 'Device Category',
  'js.countWorkOrdersUpdated': '{0} Work Orders updated',
  'SLAAuditTrail.sla.priority.description': 'Priority',
  'WorkOrderRequest.workRequestType': 'Type',
  'SMSMessage.timeStamp': 'Date/Time',
  '*.customFieldView': 'Custom Field View',
  'workrequestgroupingpreview.title': 'Optionally group to existing Work Order',
  'js.hardcodedDynamicTextAttr123':
    'This is hardcoded, yet dynamic text: attr1=[attr1], attr2=[attr2], attr3=[attr3]',
  'js.selectPOStore': 'Select PO Store',
  'menu.viewWorkCalendarMatrixViews': 'Work Calendar Matrix Views',
  'notificationRule.triggerClassName': 'Trigger Table',
  'notificationRules.title': 'Notification Rules',
  'SwitchingEvent.currentState': 'Current State',
  'js.quickLookupPlaceholderHint':
    'Type in a document code to search for work orders or work requests and hit <Enter>. You can use * as a wild-card.',
  'flushCache.message':
    'All caches successfully flushed, returning you to your previous page in 2 seconds.',
  'TableValidationRule.heading': 'Table Validation Rule',
  'FlashReport.protRemarks': 'Protection Remarks',
  'js.pleasePasteList':
    'Please paste the list of values below. Each line will be treated as a value.',
  'js.setOwnerCustomer': 'Set Owner Customer',
  'stocktakes.heading': 'Stock Takes',
  'Crew.custom1': '',
  'Crew.custom2': '',
  'Crew.custom3': '',
  'Crew.custom4': '',
  'AssetType.locked': 'Locked',
  'Crew.custom5': '',
  'js.transactionType': 'Transaction Type',
  'Crew.custom6': '',
  'js.damagedDeviceNotes': 'Notes',
  'Crew.custom7': '',
  'LoadShedding.customNumber10': '',
  'workOrderMobile.reject.title': 'Reject Workorder',
  'Crew.custom8': '',
  'Crew.custom9': '',
  'login.title': 'Login',
  'FlashReport.faultReport.station': 'Substation',
  '*.attachments': 'Attachments',
  'FaultReport.responsibleProtUser': 'Responsible Technician',
  'js.keepExistingButton': 'Keep Existing',
  'Device.validation.moveToStore':
    'Only Device types which are Maintenance Significant Items may be moved to a store',
  'QueryMapLayer.sortOrder': 'Sort Order',
  'Crew.emailAddress': 'Email Address',
  'js.allocateToResourceButton': 'Allocate to Resource',
  'js.viewOnWorkMap': 'View on Work Map',
  'orgStructure.parentOrgUnit': 'Parent Org Unit',
  'PurchaseRequisition.customNumber3': '',
  'PurchaseRequisition.customNumber4': '',
  'PurchaseRequisition.customNumber1': '',
  'PurchaseRequisition.customNumber2': '',
  'PurchaseRequisition.customNumber7': '',
  'PurchaseRequisition.customNumber8': '',
  'PurchaseRequisition.customNumber5': '',
  'PurchaseRequisition.customNumber6': '',
  'DeviceTypes.title': 'Device types',
  'SupportPerson.lastName': 'Last Name',
  'PurchaseRequisition.customNumber9': '',
  'IncomingSMSMessage.workRequest': 'Work request',
  'Asset.locationAddress.address1': 'PO Addr1',
  'Asset.locationAddress.address2': 'PO Addr2',
  'map.allocatedTo': 'Allocated To ',
  'js.workOrdersOnDescendantAsset':
    'Include Work Orders linked to descendant assets',
  'Asset.locationAddress.address5': 'Street Name',
  'Asset.locationAddress.address6': 'Suburb',
  'quotationDetail.code': 'Quotation',
  'billOfMaterials.updated': 'Bill Of Materials has been updated successfully.',
  'Asset.locationAddress.address3': 'Address Line 3',
  'ForcelinkSubcontractor.servicesDescription.hint':
    'A description of the type of work the subcontractor does, which will be used to guide the agent/dispatcher when selecting a subcontractor to outsource to.',
  'Asset.locationAddress.address4': 'Str. #',
  'Asset.locationAddress.address7': 'City',
  'Asset.locationAddress.address8': 'Postal Code',
  'Asset.locationAddress.inheritedFromParent': 'Address inherited from parent',
  'Quotation.contactNumber': 'Contact Number',
  'AssetTemplate.notes': 'Notes',
  pleaseSelect: '--Please Select',
  'damagedDevice.notes': 'Damage Notes',
  'purchaserequisitions.title': 'Purchase Requisitions',
  'WorkOrder.parent': 'Parent ',
  'LoadShedding.depot.description': 'Depot',
  'button.invoice': 'Invoice',
  'SLA.customStart': 'Start Status Code(s)',
  '*.contactPerson.faxNumber': 'Fax Number',
  'AbnormalPlant.customNumber10': '',
  'label.oldPassword': 'Old Password',
  'js.removeAssetFromList': 'Remove Asset from List',
  WorkOrder: 'Work Order',
  'js.editPurchaseInvoice': 'Edit Purchase Invoice',
  '*.orgUnit': 'Org Unit',
  'smsUsage.heading': 'SMS Usage',
  '*.status': 'Status',
  'js.supplierQuotationStatus': 'New Status',
  'WorkCalendarMatrixView.groupByProperties': 'Group By Properties',
  'js.scheduleCustomMethod': 'Schedule Custom Method',
  'js.changeRSEnd': 'Change End Date/Time',
  '*.customer.customNumber1': '',
  '*.customer.customNumber3': '',
  'SubscriberDatabase.smartCity': 'Smart City Schema?',
  '*.customer.customNumber2': '',
  '*.customer.customNumber5': '',
  '*.customer.customNumber4': '',
  'js.createAbnormalCable': 'Create Abnormal Line/Cable record',
  'InspectionListItem.dateCaptured': 'Date Captured',
  'SmsUsage.smsCount': 'SMS Count',
  'PlannedOutage.requirements': 'Requirements',
  '*.customer.customNumber7': '',
  'Quotation.noteAddedNotifyMonitorsEmailSubject':
    'Note added to Quotation {0}:{1} (by {2})',
  '*.customer.customNumber6': '',
  '*.customer.customNumber9': '',
  '*.customer.customNumber8': '',
  'CustomReferenceList.active': 'Active? ',
  'SLA.title': 'SLA',
  'js.changeRSStart': 'Change Start Date/Time',
  'Contract.inspectionList': 'Checklist',
  'Customer.description': 'Description',
  'documentsFilter.hasInvoice': 'Has Invoice',
  'FaultReport.protectionSummary': 'Summary of Incident',
  'menu.scheduler': 'Scheduler',
  'Customer.optOuts': '',
  'SwitchingEvent.timeStamp': 'Date/Time',
  'Supplier.customNumber2': '',
  'Supplier.customNumber3': '',
  'WorkOrder.auditTrail': '',
  'mobile.assignToSelf': 'Assign to yourself?',
  'Supplier.customNumber1': '',
  'crews.heading': 'Crews',
  'invoice.heading': 'Tax Invoice',
  'Supplier.customNumber8': '',
  'menu.viewResourceTypes': 'Resource Types',
  'Supplier.customNumber9': '',
  'Supplier.customNumber6': '',
  'Supplier.customNumber7': '',
  'SwitchingInstruction.totalEarthsApplied':
    '. Grand total of {0} earths applied',
  'AssetAssetXref.custom1': '',
  'Supplier.customNumber4': '',
  'AssetAssetXref.custom2': '',
  'Supplier.customNumber5': '',
  'AssetAssetXref.custom3': '',
  'purchaseOrder.supplier': 'Supplier',
  'AssetAssetXref.custom4': '',
  'menu.viewSLAEvents': 'SLA Events',
  'AssetAssetXref.custom5': '',
  'ForcelinkBillingDetail.billingMaster.subscriberId': 'Billing Master schema',
  'smartCityPortal.homePage.municipal': 'Municipal Services',
  'crew.id': 'Id',
  'FaultReport.custom9': '',
  'FaultReport.custom4': '',
  'Asset.ownerCustomer.customLookup10': '',
  'FaultReport.custom3': '',
  'FaultReport.custom2': '',
  'FaultReport.custom1': '',
  'BillOfMaterialsGroup.hierarchy': 'Hierarchy',
  'FaultReport.custom8': '',
  'FaultReport.custom7': '',
  'FaultReport.custom6': '',
  'FaultReport.custom5': '',
  'referenceList.description': 'Description',
  'user.firstName': 'First Name',
  'WorkOrder.callAddresses': 'Call Addresses',
  'Customer.customDate2': '',
  'Customer.customDate1': '',
  'SupplierQuotation.supplierDescription': 'Supplier',
  'Customer.openWorkCount': 'Open Work Orders',
  'Customer.customDate6': '',
  'Asset.inWarranty': 'In Warranty?',
  'Customer.customDate5': '',
  'Customer.customDate4': '',
  'Customer.customDate3': '',
  'WorkOrderRequest.province': 'Province',
  'Customer.customDate9': '',
  'Customer.customDate8': '',
  'Customer.customDate7': '',
  'company.url': '',
  'StockTakeExpectedItem.barcode': 'Barcode',
  'js.editCustomFlags': 'Edit Custom Flags',
  'SubscriberDatabase.templateSchema': 'Template Schema',
  'js.createSuspectOutageForDevice': 'Create Suspect Outage for Device',
  'Store.code': 'Store Code',
  'menu.viewQueryMapLayers': 'Query Map Layers',
  'projects.title': 'Projects',
  'InboxEventUser.pk.user.username': 'Username',
  'availabilityCalendarList.title': 'Shift Roster List',
  'Supplier.explicitSkills': 'Skills',
  'mobile.taxCode': 'Select Tax Type',
  'WorkOrderAutoAssignmentRule.matchOrgUnits': 'Match Org Units?',
  'SwitchingInstruction.custom1': '',
  'SwitchingInstruction.custom2': '',
  'SwitchingInstruction.custom5': '',
  'smsLog.title': 'SMS Log',
  'SwitchingInstruction.custom3': '',
  'SwitchingInstruction.custom4': '',
  'SupportSMS.notDelivered':
    'Delivery of support SMS to {0} failed. Please try again later',
  'WorkOrder.imageNo': 'Image',
  'mobile.selectFault': 'Select Fault',
  'js.changePropertyOnSelected': 'Change Property on Selected',
  'js.firmCoordinate': 'Firm Coordinate',
  'PurchaseOrder.customDate4': '',
  'PurchaseOrder.customDate5': '',
  'js.cancelOutsource': 'Cancel outsourcing',
  'js.requiresReverseGeocoding': 'Requires Reverse Geocoding',
  'menu.viewResourceChats': 'Resource Chats',
  'Customer.standardDiscount': 'Discount',
  'PurchaseOrder.customDate1': 'Arrival Date',
  'Crew.manager': 'Manager',
  'PurchaseOrder.customDate2': 'Reminder Date',
  'PurchaseOrder.customDate3': '',
  'Asset.manufSerialNumber': 'Serial number',
  'subscriberCustomFieldLabels.pk': 'Id',
  'SupplierOrgUnit.orgUnit.description': 'Org unit',
  'versioninfo.heading': 'Version Info',
  'SMSCampaign.recipientsSummary.smsMessagesByStatus': 'SMS Messages by Status',
  'js.switchingEventMenu.setFaultLocation': 'Set as Fault Location',
  'Document.customLookup2.description': '',
  'UserGroup.billingType': 'Billing Type',
  'customerMobile.workOrderAllocatedTo': 'Allocated To',
  'resources.title': 'Technician ',
  'js.reverseDeviceConnection': 'Reverse Connection',
  'globalSettingsDetail.title': 'Global Settings Detail',
  'Document.customLookup5.description': '',
  'CallTake.customer.code.hint': 'Click to search by Code/Account number',
  'FlashReport.responsibleProtUser': 'Responsible Tech',
  'material.taxCode': 'Tax Code',
  'MaterialStore.customNumber6': '',
  'SchematicDiagram.devicesToDecommission': 'Devices to Decommission',
  'MaterialStore.customNumber7': '',
  'MaterialStore.customNumber8': '',
  'Asset.manufacturerFrame': 'Manufacturer info',
  'MaterialStore.customNumber9': '',
  'MaterialStore.customNumber2': '',
  'DeviceType.custom2': '',
  'MaterialStore.customNumber3': '',
  'DeviceType.custom1': '',
  'MaterialStore.customNumber4': '',
  'MaterialStore.customNumber5': '',
  'workOrderRequest.inspectionList': 'Inspection List',
  'DeviceType.custom5': '',
  'js.createInvoicePackageForSelectedInvoices':
    'Create Invoice Package for Selected Invoices',
  'DeviceType.custom4': '',
  'MaterialStore.customNumber1': '',
  'DeviceType.custom3': '',
  'DeviceType.MSI': 'Maintenance Significant Item?',
  'Asset.aquisitionDate': 'Acquisition Date',
  'billOfMaterials.id': 'ID',
  'js.commissionSelectedDevices': 'Commission selected devices',
  'Project.contract.code': 'Contract',
  'Asset.financialFrame': 'Financial Information',
  'FaultReport.voltageLevel.code': 'Voltage Level',
  'Contract.responsibleUser': 'Responsible User',
  'Resource.user.accountLocked': 'User Account Locked',
  'ForcelinkPricing.rangeStart': 'Range Start',
  'Customer.active': 'Active? ',
  'Device.validation.circularReference':
    'There is a circular reference in the device inheritance',
  'workflowSelectTypes.selectTypes': 'Select Types',
  'material.stockOnHand': 'Stock On Hand',
  'ShiftType.heading': 'Shift Type',
  'js.createSuspectOutageForNearbyLoadCentre':
    'Create Suspect Outage for nearby load centre',
  'Lease.serviceDeposit': 'Service Deposit',
  'mobile.currentAvailability': 'Current Availability',
  'customerquotations.heading': 'Quotations',
  'mainMenu.message': 'Current status:',
  'SuperadminUser.fullName': 'Full name',
  'WorkOrder.assets': 'Assets',
  'TrainingEvent.customDate4': '',
  'Customer.faxNumber': 'Fax Number',
  'TrainingEvent.customDate3': '',
  'TrainingEvent.customDate2': '',
  'errors.approvalLimit':
    'Sorry, this price of {0} is above your approval limit of {1}',
  'TrainingEvent.customDate1': '',
  'purchaseOrder.purchaseInvoices': 'Supplier Invoices',
  'Building.leases': 'Leases',
  'TrainingEvent.customDate5': '',
  'users.nousers': '<span>No users found.</span>',
  'Building.description': 'Description',
  'js.addStandardCharge': '',
  'menu.viewCustomerDetails': 'Customer Details',
  'purchaseOrder.store.description': 'Target Store',
  'ResourceType.hourlyRate': 'Hourly Cost',
  'FaultReport.customNumber10': '',
  'workorderrequests.heading': 'Work Requests',
  'AbnormalPlant.status.open': 'Open?',
  'portalMainMenu.heading': 'Welcome to the Forcelink Customer Portal!',
  'Asset.warranty': 'Warranty',
  'Device.validation.ownchild': "Device cannot be it's own child",
  'js.pleaseSelectShiftType': 'Please select the Shift Type for the shift',
  'AssetCategory.description': 'Asset Category Description',
  'Asset.validation.noUniqueStockFoundForAssetCode':
    "No unique stock item found for Asset Code '{0}'",
  'userProfile.message': 'Please update your information using the form below.',
  'user.updated.byAdmin':
    'User information for {0} has been successfully updated.',
  'SuperadminUser.mobileNo': 'Mobile Number',
  'FaultReport.protTeamLeader': 'Prot. Team Leader',
  'button.uploadFile': 'Upload File',
  'Asset.customTransient14': '',
  'Asset.customTransient15': '',
  'Asset.customTransient12': '',
  'WorkOrder.contactNumber': 'Technician Responsible Number',
  'Asset.customTransient13': '',
  'Asset.customTransient10': '',
  'Asset.customTransient11': '',
  'js.renameGroup': 'Rename Group',
  'ResourceShiftRecord.customLookup5': '',
  'ResourceShiftRecord.customLookup4': '',
  'RelayFailure.notes': 'Details of Failure/Investigation',
  'ResourceType.assetType': 'Associated Vehicle/Machinery Asset Type',
  'testREST.heading': 'Test RESTful web services',
  'button.upload': 'Upload',
  'MaterialStore.customNumber10': '',
  'js.showLinkedOutagesAbnormalPlant': 'Show Linked Outages/Abnormal Plant',
  '*.material.code': 'Material Code',
  'Crew.customNumber5': '',
  'button.cancel': 'Cancel',
  'AssetScheduledWorkTemplateXref.pk.asset.code': 'Asset',
  'Crew.customNumber4': '',
  'Crew.customNumber3': '',
  'uploadForm.customLookup3': '',
  'Crew.customNumber2': '',
  'uploadForm.customLookup2': '',
  'Crew.customNumber1': '',
  'uploadForm.customLookup1': '',
  'BatchAddAsset.codeInputHint':
    'Type in codes here, scan in with a barcode scanner, or paste a list of codes from the clipboard.',
  'js.editLease': 'Edit Lease',
  'deviceType.secondaryVoltage': 'Secondary Voltage?',
  'InspectionListItem.inspectionListGroup.treeDescription': 'Group Tree',
  'Asset.ownerCustomer.custom15': '',
  'Asset.ownerCustomer.custom16': '',
  'Asset.ownerCustomer.custom17': '',
  'PurchaseRequisition.purchaseOrders': 'Purchase Orders',
  'Asset.ownerCustomer.custom18': '',
  'Asset.ownerCustomer.custom19': '',
  'WorkOrder.workRequestCount': 'Work Request Count',
  'Device.modelNumber': 'Model Number',
  'WorkOrderRequest.images': 'Images',
  'Asset.ownerCustomer.custom10': '',
  'Asset.ownerCustomer.custom11': '',
  'inbox.invoices': 'Invoices',
  'Asset.ownerCustomer.custom12': '',
  'Asset.ownerCustomer.custom13': '',
  'Asset.ownerCustomer.custom14': '',
  'Lease.status.description': 'Status',
  'activeUsers.title': 'Active Users',
  'Asset.parentBillOfMaterialsGroup.parentDType': 'Parent BOM ',
  'WorkOrder.deleted': 'Work Order {0} has been deleted.',
  'Asset.ownerCustomer.custom26': '',
  'LoadShedding.custom3': '',
  'Asset.ownerCustomer.custom27': '',
  'LoadShedding.custom4': '',
  'Asset.ownerCustomer.custom28': '',
  'WorkOrderRequest.sentToSubcontractor':
    'Work Request sent to Sub-contractor {0}',
  'LoadShedding.custom5': '',
  'Asset.ownerCustomer.custom29': '',
  'LoadShedding.custom6': '',
  'LoadShedding.custom1': '',
  'userProfile.admin.message':
    "You can update this user's information using the form below.",
  'LoadShedding.custom2': '',
  'WorkOrderRequest.customLookup20': '',
  'supplier.title': 'Supplier',
  'js.countWorkRequestsChangedTo': "{0} Work Requests changed to '{1}'",
  'Asset.ownerCustomer.custom20': '',
  'Asset.ownerCustomer.custom21': '',
  'label.newPassword': 'New Password',
  'Asset.ownerCustomer.custom22': '',
  'LoadShedding.custom7': '',
  'Asset.ownerCustomer.custom23': '',
  'LoadShedding.custom8': '',
  'menu.getExchangeRates': 'Get Exchange Rates',
  'Asset.ownerCustomer.custom24': '',
  'LoadShedding.custom9': '',
  'WorkOrderRequest.status': 'Status\t',
  'Asset.ownerCustomer.custom25': '',
  'errors.password.invalidSubscriber': "The subscriber '{0}' does not exist.",
  'menu.customerQuotations': 'Quotations',
  'BillOfMaterialsItem.parentDoc': 'Document',
  '*.officeNumber': '',
  'ResourceShiftRecord.shiftMileage': 'Shift Mileage',
  'Customer.country': 'Country',
  'Asset.customLookup10': '',
  'WorkRequestAutoAllocationRule.userFilter': 'User Filters',
  'FaultReport.initialCauses.description': 'Apparent Cause',
  'InspectionListWizard.button.addGroup': 'Add Group',
  'Project.dateCreated': 'Date Created',
  'TaxType.country': 'country',
  'user.resourceAccount': 'Resource Account',
  'FaultReport.workorderid': 'Work Order ID',
  'WorkCalendarMatrixView.description': 'Description',
  'js.createOutageForDevice': 'Create Outage for Device',
  'MobileManagerView.dictionaryTable.className': 'Doc Type',
  'js.contractPeriodStartDate': 'Period Start Date',
  'menu.genericAuditLog': 'Generic Audit Log',
  StockTake: 'Stock Take',
  'customerworkrequests.defaultColumnView': '',
  'js.switchingEventMenu.createActualSwitchingInstruction':
    'Create Actual Switching Instruction',
  'errors.invalid': '{0} is invalid.',
  'js.pasteScheduledWorkOrders': 'Paste Selected Work Orders',
  'WorkOrderAutoAssignmentRule.workOrderSort': 'Work Order Sorts',
  Resource: 'Resource',
  'button.addStandardCharge': '',
  'LoadShedding.voltage.description': 'Voltage',
  'Call.typeCode': 'Fault Type',
  'SchematicDiagram.diagramName.code': 'Diagram Code',
  'billOfMaterials.vat': 'VAT',
  'js.moveLeft': 'Move Left',
  'WorkOrder.customNumber2': '',
  'WorkOrder.customNumber1': '',
  'AssetTypes.title': 'Asset types',
  'ResourceAttribute.timeStamp': 'Timestamp',
  'WorkOrder.customNumber4': '',
  'WorkOrder.customNumber3': '',
  'WorkOrder.customNumber6': '',
  'uploadForm.customLookup5': '',
  'WorkOrder.customNumber5': '',
  'uploadForm.customLookup4': '',
  'WorkOrder.customNumber8': '',
  'WorkOrder.customNumber7': '',
  'WorkOrder.customNumber9': '',
  'workrequestgroupingpreview..dontGroup': "Don't Group",
  'customerPortal.passwordReset.email.subject':
    'Customer Portal password reset',
  'js.viewDeviceAttributes': 'View Device Attributes',
  'js.zoomOut': 'Zoom Out',
  'errors.duplicateName': "'{0}' is a duplicate name.",
  'ResourceShiftRecord.customLookup1': '',
  'js.viewDeviceDetail': 'View Device Details',
  'ResourceShiftRecord.customLookup3': '',
  'ResourceShiftRecord.customLookup2': '',
  'menu.viewUserGroups': 'User Groups',
  'PlannedOutage.custom1': '',
  'store.heading': 'Store',
  'PlannedOutage.custom9': '',
  'PlannedOutage.custom8': '',
  'PlannedOutage.custom7': '',
  'PlannedOutage.custom6': '',
  'PlannedOutage.custom5': '',
  'FlashReport.faultReport.weather': 'Weather',
  'PlannedOutage.custom4': '',
  'AssetType.materials': 'Materials',
  'resource.javalinksent':
    "Forcelink Mobile Java link has been SMS'd to Technician ",
  'PlannedOutage.custom3': '',
  'Quotation.billOfMaterialsDefaultColumnView': 'Order View',
  'PlannedOutage.custom2': '',
  'mobile.noBillOfMaterials': 'There are no materials for this workorder.',
  'Asset.ownerCustomer.custom8': '',
  'Asset.ownerCustomer.custom9': '',
  'js.contractassetsMoreOptionsMenu.linkSelectedAssets': 'Link selected Assets',
  'Asset.ownerCustomer.custom4': '',
  'Asset.ownerCustomer.custom5': '',
  'Asset.ownerCustomer.custom6': '',
  'Asset.ownerCustomer.custom7': '',
  'FlashReport.faultReport.trippedDevices': 'Tripped Devices',
  'Asset.ownerCustomer.custom1': '',
  'Asset.ownerCustomer.custom2': '',
  'Asset.ownerCustomer.custom3': '',
  'BillOfMaterialsItemAsset.pk.bomItem.quantity': 'Quantity',
  'menu.personalDataExpiryRules': 'Personal Data Expiry Rules',
  'Customer.deleted': 'Customer {0} has been deleted successfully.',
  '*.priority': 'Priority',
  'material.id': 'Id',
  'purchaseOrder.supplier.description': 'Supplier',
  'InspectionListWizard.new': 'New',
  'Lease.parkingFee': 'Parking Fee',
  'RelayFailure.type': 'Function',
  'mobile.addMaterial': 'Add Material',
  'errors.subscriberId.invalid': 'Subscriber ID is invalid.',
  'js.reloadDeviceInfo': 'Reload Device Info',
  addImage: 'Add Image',
  'StockTake.error.unknownBarcode': 'Unknown Barcode',
  'Contract.heading': 'Contracts',
  'SMSCampaignCategory.parent.description': 'SMS Category',
  'myinboxevents.title': 'My Inbox Events',
  'js.workOrdersDirectlyOnAsset':
    'Include only Work Orders directly linked to asset',
  'QueryMapLayer.heading': 'Query Layer Details',
  'js.pasteDevice': 'Paste Device',
  'js.confirmOriginalClosed':
    'The original ticket is already closed - are you sure you want to continue?',
  'CustomerPortal.registerHint':
    'Not yet registered? Click below to create a profile',
  'AbnormalPlant.device.description': 'Tripped device',
  'BillOfMaterialsItem.totalCost': 'Total Cost',
  'js.PurchaseRequisitionFormControllerTitle': 'Purchase Requisition',
  '*.dateCreated': 'Date Created',
  'button.moreOptions': 'More Options',
  'js.shiftEndTime': 'Shift End Time',
  'PlannedOutage.customMultipleChoice2': '',
  'WorkOrderRequest.customLookup14': '',
  'PlannedOutage.customMultipleChoice1': '',
  'WorkOrderRequest.customLookup15': '',
  'WorkOrderRequest.customLookup12': '',
  'WorkOrderRequest.customLookup13': '',
  'addprbomitems.heading': 'Add Materials',
  'WorkOrderRequest.customLookup18': '',
  'WorkOrderRequest.customLookup19': '',
  'WorkOrderRequest.customLookup16': '',
  'WorkRequestSubcontractorAssignmentRule.subcontractorFilter':
    'Subcontractor Filters',
  'WorkOrderRequest.customLookup17': '',
  'StatusReference.title': 'Edit Status Definition',
  'workorderautoassignmentrules.heading':
    'Work Order Automatic Resource Assignment Rules',
  'dashboardEntry.hql': 'HQL/SQL',
  'mobilemanagerviews.title': 'Mobile Manager Views',
  'WorkOrderRequest.customLookup10': '',
  'PlannedOutage.customMultipleChoice5': '',
  'WorkOrderRequest.customLookup11': '',
  'PlannedOutage.customMultipleChoice4': '',
  'PlannedOutage.customMultipleChoice3': '',
  'StockTake.error.serializedAssetBinCodeNotExpected':
    'Serializable asset bin code recognised ({0}), but should be scanned by individual asset codes',
  'FaultReport.smsMessage.FaultReport':
    'Fault ${code} on {0} ${status.description} {1} {2}',
  'allocateresource.heading': 'Allocate Resources',
  'Invoice.childInvoices': 'Child Invoices',
  'DeviceCategory.title': 'Device Category',
  'DashboardEntry.displayGroup.description': 'Display Group',
  'Asset.validation.allowedParentTypes':
    "Asset of Type '{0}' cannot be a child of type '{1}'",
  'AssetStockSummary.assetType.description': 'Asset Type',
  'MobileManagerView.groupByProperties': 'Group By Properties',
  'Contract.bomGroups': 'BOM Periods',
  'WorkOrder.plannedStart': 'Planned Start Date/Time',
  'js.emptyProjectConfirmAdd':
    'There are currently no tasks in this project. Would you like to add one?',
  'js.groupSelectedToCrew': 'Group Selected to Crew',
  'scheduler.title': 'Scheduler',
  'SearchCustomer.title': 'Search for Customer',
  'UserGroup.active': 'Active? ',
  'resourceType.heading': 'Resource Type',
  'OrgStructure.customIcon': 'Custom Icon Name',
  'spatialLayers.title': 'Edit Map Custom Layers',
  'PlannedOutage.blockMVA': 'Block MVA',
  'Project.code': 'Code',
  'store.title': 'Store',
  'Asset.updated': 'Asset {0} Updated',
  'notificationRule.heading': 'Notification Rule Details',
  'js.countResourcesMadeNotAvailable': '{0} resources made NOT available',
  'workflowSelectTypes.title': 'Select Types linked to Workflow',
  'SMSCampaign.smsCategory': 'SMS Category',
  'ForcelinkBilling.subscriber.subscriberId': 'Subscriber',
  'editCustomFields.title': 'Edit Custom Fields',
  'mobile.selectMiscItem': 'Select Misc Item',
  'purchaserequisitions.heading': 'Purchase Requisitions',
  'smartCityPortal.homePage.logOut': 'Logout',
  'address.address': 'Address',
  'Asset.commissioningDate': 'Commissioning Date',
  'button.javaLink': 'send Java link by SMS',
  'passwordRule.enforceSpecialCharacters':
    'The password must contain at least one special character that is not a letter or number.',
  'LoadShedding.durationHHMM': 'Actual Duration (HH:MM)',
  'dashboardEntry.sqlFlag': 'SQL Mode?',
  'DeviceCategory.added': 'Device Category {0} Added',
  'querymaplayers.heading': 'Query Layers',
  'customers.heading': 'Customers',
  'InspectionListItem.value': 'Value',
  'FailteIrelandFeeSchedule.heading': 'Failte Ireland Fee Schedule',
  'POBillOfMaterialsItem.quantityOrdered': 'Quantity Ordered',
  'notificationRule.update': 'Update',
  'orgStructure.canSelect': 'Can Select?',
  'WorkOrderRequest.customNumber1': '',
  'js.changeRSPlannedEnd': 'Change Planned End Date/Time',
  'Assettemplate.description': 'Description',
  'WorkOrderRequest.customNumber3': '',
  'WorkOrderRequest.customNumber2': '',
  'WorkOrderRequest.customNumber5': '',
  'fieldAccess.regex.title':
    'A validation pattern based on a Regular Expression',
  'WorkOrderRequest.customNumber4': '',
  'WorkDoc.createdDate': 'Date Created',
  'WorkRequestAutoAllocationRule.matchOrgUnits': 'Match Org Units?',
  'WorkOrderRequest.customNumber7': '',
  'WorkOrderRequest.customNumber6': '',
  'WorkOrderRequest.customNumber9': '',
  'FaultReport.remarks.title':
    "This block is for historical remarks. Please see the 'notes' block above for adding new notes.",
  'WorkOrderRequest.customNumber8': '',
  'js.adjustStockLevel': 'Adjust Stock Level',
  'ForcelinkBilling.billingType': 'Billing Type',
  'Workflow.title': 'Work Flow',
  'js.confirmDeletePurchaseInvoice':
    'Are you sure you want to delete the Purchase Invoice?',
  'menu.ViewAbnormalPlant': 'Abnormal Plant',
  'js.invalidTimeEntered': 'invalid time entered',
  'RuleParameters.ruleParameterValues.parameterValue': 'Parameter Value',
  'resource.hourlyRate': 'Hourly Cost to Company',
  'StatusReference.custom2': 'Custom 2',
  'Assettemplate.customNumber12': '',
  'button.email': 'Email',
  'StatusReference.custom3': 'Custom 3',
  'Assettemplate.customNumber11': '',
  'StatusReference.custom4': 'Custom 4',
  'address.updated': 'Address has been updated successfully.',
  'Assettemplate.customNumber10': '',
  'StatusReference.custom5': 'Custom 5',
  'StatusReference.custom1': 'Custom 1',
  'Resource.workOrderEmailNotificationMessage':
    '{0},\n\nWork Order {1} has been allocated to you. Details are:\n\n{2}',
  'Contract.contractassetsDefaultColumnView': '',
  'SMSMessage.toNumber': 'To Number',
  'SupplierOrgUnit.customDate1': '',
  'SupplierOrgUnit.customDate2': '',
  'SupplierOrgUnit.customDate3': '',
  'button.shiftHistory': 'Shift History',
  'SupplierOrgUnit.customDate4': '',
  'Assettemplate.customNumber20': '',
  'userGroup.title': 'Edit User Group',
  'SupplierOrgUnit.customDate5': '',
  'Asset.linkedCustomer': 'Linked Customer',
  'materialmovements.title': 'Material Movements',
  'resource.workCount': 'All Work Count',
  'DeviceType.schematicSymbol': 'Schematic Symbol',
  'js.linkWorkOrderToTrippedDevice': 'Link Work Order',
  'Quotation.customNumber10': '',
  'StockTake.foundItems': 'Found Items',
  'Assettemplate.customNumber19': '',
  'Assettemplate.customNumber18': '',
  'Assettemplate.customNumber17': '',
  'Assettemplate.customNumber16': '',
  'Assettemplate.customNumber15': '',
  'Assettemplate.customNumber14': '',
  'Assettemplate.customNumber13': '',
  'uploadForm.newFileName': 'New File name',
  'button.renew': 'Renew',
  'PurchaseRequisition.orgUnit.costCentre': 'Cost Centre',
  '*.inspectionList': 'Inspection List',
  'ForcelinkBilling.unitPrice': 'Unit Price',
  'menu.viewInvoices': 'Invoices',
  'mobile.updateStatusTitle': 'Workorder Status',
  'js.satelliteLayer': 'Satellite View',
  'notificationRule.sqlFlag': 'SQL Mode?',
  'Quotation.workRequestStatus.open': 'Open?',
  'Device.deviceStatus.description': 'Device Status',
  'PlannedOutage.customNumber10': '',
  'SMSCampaignCategory.description': 'SMS Campaign Category',
  'CustomManagerImpl_cp_mdt.FaultReport': 'Outage',
  '*.addressView': 'Address View',
  'resource.mobileNumber': 'Mobile Number',
  'menu.billingadmin': 'Billing Admin',
  'js.receiveSelectedLineItemsDialogTitle': 'Duplicate Selected BOM Items',
  'SupportPerson.onDuty': 'On Duty?',
  'Building.customDate1': '',
  'Building.customDate2': '',
  'Building.customDate3': '',
  'Building.customDate4': '',
  'Building.customDate5': '',
  'Building.customDate6': '',
  'Building.customDate7': '',
  'Building.customDate8': '',
  'subscriberCustomLabels.added': 'Custom Label has been added successfully.',
  'customerMobile.quotationRequestViewAll': 'View All Quotations',
  'Document.title': 'Document',
  'js.portalLoginFailure':
    "Invalid username/password combination. To re-send your password reminder, click <a href='javascript:CustomerPortal.passwordHint();'>here</a>. To reset your password, click <a href='javascript:CustomerPortal.passwordReset();'>here</a>.",
  'WorkOrder.customTransient6': '',
  'Building.customDate9': '',
  'WorkOrder.customTransient7': '',
  'WorkOrder.customTransient4': '',
  'js.versionNo': '2.26.0',
  'WorkOrder.customTransient5': '',
  'WorkOrder.customTransient2': '',
  'WorkOrder.customTransient3': '',
  'ActiveSessionInfo.uid': 'Device UID',
  'js.createWorkOrderForAsset': '',
  'WorkOrder.customTransient1': '',
  'Building.custom6': '',
  'Building.custom5': '',
  'SubscriberAutoNumberingSettings.tableName.tableName': 'Table Name',
  'Building.custom4': '',
  'Building.custom3': '',
  'FlashReport.newStatus': 'New Status',
  'errors.date': '{0} is not a date.',
  'Building.custom9': '',
  'WorkOrder.customTransient8': '',
  'Building.custom8': '',
  'WorkOrder.customTransient9': '',
  'Building.custom7': '',
  'Asset.assetStatus.code': 'Asset Status Code',
  'SLA.penaltyCost': 'Penalty Cost',
  'Building.custom2': '',
  'Building.custom1': '',
  'AbnormalPlant.customDate3': '',
  'AbnormalPlant.customDate2': '',
  'AbnormalPlant.customDate5': '',
  'AbnormalPlant.customDate4': '',
  'AbnormalPlant.customDate7': '',
  'AbnormalPlant.customDate6': '',
  'AbnormalPlant.customDate9': '',
  'AbnormalPlant.customDate8': '',
  'customer.childCustomers': 'Child Customers:',
  'AbnormalPlant.customDate1': '',
  'RuleSelection.enabled': 'Enabled?',
  'UserDWR.fullName': 'Full Name',
  'AssetCategory.updated': 'Asset Category {0} Updated',
  'Crew.mobileNumber': 'Mobile Number',
  'crew.updated': 'Crew has been updated successfully.',
  'selectmaterial.heading': 'Select Material',
  'QuickLaunch.mainMenu': 'Home',
  'js.linkSubstationToSelectedDiagram': 'Link Substation To Selected Diagram',
  'passwordReset.heading': 'Reset your Forcelink password',
  'Scheduler.viewPeriod.Month': 'Month',
  'menu.viewWorkMap': 'Work Map',
  'incomingsmsmessages.title': 'Incoming SMS messages',
  'display.heading': 'File Information',
  'js.viewProject': 'View Project',
  'menu.allocateOrgUnits': 'Select Org Units',
  ValidationError: 'Validation Error:',
  'js.pleaseSelectPOStore':
    'Please select the store which will receive the Purchase Order',
  'subscriberCustomFieldLabels.updated':
    'Custom Field has been updated successfully.',
  'js.setPermissions': 'Set Permissions',
  'PurchaseOrders.title': 'Purchase Orders',
  'customerpurchaserequisition.title': 'Purchase Requisition',
  'Resource.availableVehicles': 'Available Vehicles',
  'Lease.heading': 'Lease',
  'Asset.manufacturer': 'Manufacturer',
  'button.wapLink': 'send WAP link by SMS',
  'menu.myQuotations': 'My Quotations',
  'js.resourceUngroupedFromCrew': 'Resource ungrouped from crew',
  'js.print': 'Print',
  'loadsheddingtemplates.heading': 'Load Shedding Templates',
  'Device.validation.serialNumber':
    'Maintenance Significant Items must have a Unique Serial Number',
  'ScheduledWorkTemplate.intervalHours': 'Interval (Hours)',
  'QueryMapLayer.infoTextHtml': 'Info Text HTML',
  'menu.saveUserFilters': 'Save Filters',
  'Quotation.selectedMonitorGroups': 'Selected Monitor Groups',
  'SMSCampaignCategory.smsCategory': 'SMS Category',
  'resourceTypes.title': 'Resource Types',
  'js.incorrectPassword': 'Password incorrect, please try again',
  'QuickLaunch.customers': 'Customers',
  maxLengthExceeded:
    'The file you are trying to upload is too big.  The maximum allowed size is 10 MB.',
  'menu.viewMainMenu': 'Main Menu',
  'FaultReport.currentDevicesOffSupplyCount': 'No. of Devices currently off',
  'SLA.description': 'Description',
  'resource.photoPath': 'Photo file path',
  'IncomingSMSMessage.fromNumber': 'From Number',
  'ForcelinkSubcontractor.password': 'Password',
  'mobile.deleteBillOfMaterials': 'Delete Line Item',
  'WorkOrderAutoAssignmentRule.resourceFilter.hint':
    "Please supply a comma-separated list of filter criteria (e.g. description like '%Plumbing%')",
  'WorkRequestSubcontractorAssignmentRule.workRequestFilter.hint':
    "Please supply a comma-separated list of filter criteria (e.g. workRequestType.code='XX',customer.code='YY', etc)",
  'CustomerPortal.homePage.viewAllFaults': 'View All Faults',
  'AuditTrail.table.className': 'Table',
  'workOrderRequest.title': 'Work Request Detail',
  'warranties.heading': 'Warranties',
  'WorkOrderRequest.subcontractor.description': 'Subcontractor',
  'js.moveDown': 'Move Down',
  'SubscriberDatabase.billOnlyActiveUsers': 'Bill only active users?',
  'button.add': 'Add',
  'FaultReport.customDate7': '',
  'FaultReport.customDate8': '',
  'orgStructure.active': 'Active? ',
  'FaultReport.customDate9': '',
  'schematicdiagrams.allVersionsView': 'All Versions',
  'PurchaseOrder.error.noPurchaseInvoices':
    'No Purchase Invoices have been added',
  'js.moveUp': 'Move Up',
  'Material.unitType.description': 'Unit Type',
  'js.captureSerialisedAssets': 'Capture new Serialised Items',
  'WorkRequestSubcontractorAssignmentRule.subcontractorSort.hint':
    'Please supply a comma-separated list of sort criteria (e.g. description)',
  'MobileManagerView.userGroups': 'User Groups',
  'Crew.customDate8': '',
  'FaultReport.customDate1': '',
  'js.selectStandardInspectionList': 'Select Standard Inspection List',
  'Crew.customDate9': '',
  'FaultReport.customDate2': '',
  'Crew.customDate6': '',
  'FaultReport.customDate3': '',
  'Crew.customDate7': '',
  'FaultReport.customDate4': '',
  'Crew.customDate4': '',
  'FaultReport.customDate5': '',
  'Crew.customDate5': '',
  'FaultReport.customDate6': '',
  'Crew.customDate2': '',
  'Crew.customDate3': '',
  'Crew.customDate1': '',
  'WorkOrder.workOrderType.description': 'Type',
  'DashboardEntry.subColumnSize': 'Sub Column Size',
  'js.allocateResourcesToSelected': 'Allocate Technicians to Selected',
  'StockTakeExpectedItem.nonSerialisedQuantityFound': 'Quantity Found',
  'workOrderMobile.noActiveQuotations': 'There are no active Quotations',
  'SLAAuditTrail.missedByHours': 'Missed By (hours)',
  'customerportal.workOrderRequest.workLog': 'Work Log',
  'Contract.customDate1': '',
  'workflows.heading': 'Edit Workflows',
  'Contract.customDate2': '',
  'Contract.customDate5': '',
  'Contract.customDate3': '',
  'Contract.customDate4': '',
  'Device.childDevices': 'Child Devices',
  'BillOfMaterialsItem.stockOnHand': 'Stock on Hand',
  '*.material.materialType.treeDescription': 'Material Type',
  'FailteIrelandFeeSchedule.rangeEnd': 'Range End',
  'SupplierOrgUnit.customLookup3': '',
  'WorkOrderRequest.customerOrderNumber': '',
  'SupplierOrgUnit.customLookup4': '',
  'SupplierOrgUnit.customLookup1': '',
  'SupplierOrgUnit.customLookup2': '',
  'smsUsage.title': 'SMS Usage',
  'AbnormalPlant.abnormalPlantType': 'Abnormal Plant Type',
  'user.address.province': 'Province',
  'js.createUnplannedOutage': 'Create Unplanned Outage',
  'SupplierOrgUnit.customLookup5': '',
  'PurchaseRequisition.workRequestStatus.open': 'Open?',
  'WorkOrderRequest.eta': 'Expected Time of Arrival',
  'SwitchingInstruction.equipmentType': 'Equipment',
  'WorkOrderRequest.etc': 'Expected Time of Completion',
  'AssetAssetXref.customDate1': '',
  'js.viewAssetDetails': 'View Asset Details?',
  'ForcelinkSubcontractor.workTypes': 'Work Types',
  'orgStructure.deleted': 'Org Structure has been deleted successfully.',
  'StockTakeFoundItemDWR.quantity': 'Quantity Found',
  'TableValidationRule.ELValidationExpr': 'Validation Expression (EL format)',
  'js.workOrderEmailSent': 'Work Order E-mail sent',
  'SLAAuditTrail.paused': 'Paused?',
  'material.unitCost': 'Unit Cost',
  '403.message':
    'Your current role does not allow you to view this page.  Please contact your system administrator if you believe you should have access.  In the meantime, how about a pretty picture to cheer you up?',
  'Note.text': 'Note',
  'subscriberCustomFieldLabelsList.title': 'Custom Field List',
  'AssetAssetXref.customDate4': '',
  'AssetAssetXref.customDate5': '',
  'AssetAssetXref.customDate2': '',
  'AssetAssetXref.customDate3': '',
  'js.loginToCustomerPortal': 'Login to Customer Portal',
  'AbnormalPlant.faultReport.custom10': '',
  'invoice.invoiceDueDate': 'Due Date',
  'Store.customNumber10': '',
  'userProfile.heading': 'User Profile',
  'Store.customNumber11': '',
  'CustomerPortal.button.register': 'Register a new profile',
  'Store.customNumber18': '',
  'errors.password.newHintCannotBeBlank':
    'The new password hint cannot be blank.',
  'Store.customNumber19': '',
  'Store.customNumber16': '',
  'Store.customNumber17': '',
  'Store.customNumber14': '',
  '*.idString': 'ID',
  'Store.customNumber15': '',
  'Store.customNumber12': '',
  'Store.customNumber13': '',
  'Store.customDate10': '',
  'WorkOrderRequest.description': 'Description',
  'js.addMaterial': 'Add Material',
  'js.rotateLabel': 'Rotate Label',
  'Asset.dimensions': 'Dimensions/size',
  'Store.customNumber20': '',
  'ForcelinkSubcontractor.replicateAssetTypesToSub.hint':
    "A comma-delimited list of asset type codes to replicate to the sub. If 'ALL', all asset types will replicate.",
  'login.submitSupportRequest':
    'Having trouble?<br><a href="mailto:support@forcelink.net?subject=Support%20request%20via%20Forcelink%20Application&body=Good%20day%20Forcelink%20Support%0A%0AI%20am%20experiencing%20the%20following%20issue%20with%20Forcelink%3A%0A%0AMy%20details%20are%20as%20follows%3A%0AName%20and%20Surname%3A%0Ausername%3A%0Asubscriber%20id%20(company)%3A%0Ae-mail%3A%0Acellphone%3A%0A%0AOther%20notes%20regarding%20my%20request%20for%20support%3A%0A%0A">Email Forcelink Support</a>',
  'IncomingSMSMessage.status': 'Status',
  'Building.externalId': 'External ID',
  'Asset.ownerCustomer.customNumber9': '',
  'Asset.ownerCustomer.customNumber8': '',
  'Asset.ownerCustomer.customNumber7': '',
  'Asset.ownerCustomer.customNumber6': '',
  'Asset.ownerCustomer.customNumber5': '',
  '*.address4': 'Street Number',
  '*.address5': 'Street Name',
  '*.address6': 'Suburb',
  '*.address7': 'City',
  '*.address1': 'Postal Address1',
  '*.address2': 'Postal Address2',
  '*.address3': 'Building',
  'Quotation.noteAddedNotifyMonitorsEmailBody':
    'Dear {0},\n\nNote added by {4} at {1}:\n\n{2}\n\nLink to Quotation:{3}\n\nRegards, Forcelink',
  '*.address8': 'Postal Code',
  'SchematicDiagram.versionNumber': 'Diagram Version',
  'FlashReport.faultReport.damagedDevices': 'Damaged Devices',
  'js.detachFromParent': 'Detach from Parent',
  'Quotation.billOfMaterials': 'Bill Of Materials',
  'allocateresources.title': 'Allocate Resources',
  'js.clearOwnerCustomer': 'Clear Owner Customer',
  'WorkOrderRequest.invoice': 'Invoice',
  'Asset.ownerCustomer.customNumber4': '',
  'Asset.ownerCustomer.customNumber3': '',
  'Asset.ownerCustomer.customNumber2': '',
  'Asset.ownerCustomer.customNumber1': '',
  'WorkOrderRequest.SLAAuditTrail': 'SLA Events',
  'mobile.searchMode.byAssetWorkType': 'By Asset/Work Type',
  'customerportal.workrequestform.address': 'Work Location',
  'js.createChildAsset': 'Create Child Asset',
  'uploadForm.name': 'Entity Name',
  'buildings.heading': 'Buildings',
  'js.purchaseOrderEmailSent': 'Purchase Order E-mail sent',
  'js.changeStatusForSelected': 'Change Status for Selected',
  'js.newCustomerStatus': 'New Customer Status',
  'js.deletePurchaseInvoice': 'Delete Purchase Invoice',
  'js.customReferenceListDescription': 'Description',
  'Customer.custom9': '',
  'Customer.custom8': '',
  'CustomMatrixView.customColumnView': 'Custom Column View',
  'Customer.custom7': '',
  'Customer.custom6': '',
  'Invoice.workOrder.billOfMaterialsGroup.formattedTotalCostExcl': 'Cost Excl',
  'Customer.custom5': '',
  'Customer.custom4': '',
  'Customer.custom3': '',
  'Customer.custom2': '',
  'DashboardEntry.subColumnNumber': 'Sub Column Number',
  'Customer.custom1': '',
  'Contract.type': 'Contract Type',
  'Scheduler.viewPeriod.Week': 'Week',
  'WorkRequestSubcontractorAssignmentRule.title':
    'Work Request Automatic Subcontractor Assignment Rule',
  'standardBillOfMaterials.title': 'Edit Standard Bills Of Materials',
  'globalsettings.title': 'Global Settings',
  'MaterialStore.stockValue': 'Stock Value',
  'SLA.heading': 'SLA',
  'BillOfMaterialsGroup.formattedTotalPriceExcl': 'Total Price Excl',
  'mobile.resolutionCode': 'Resolution Code',
  'Quotation.timeSlot': '',
  'menu.viewArcGISLayers': 'ArcGIS Layers',
  'passwordReset.resendOTP': 'Re-send OTP',
  'Rule.description': 'Description',
  'js.store': 'Store',
  'menu.viewAssetStockSummary': 'Asset Stock Summary',
  'resource.groupingFilter.all': 'All',
  'Contract.active': 'Active? ',
  'button.editSchematic': 'Edit Schematic',
  'PlannedOutage.plantToIsolateFrom': 'Plant to isolate from',
  'resource.code': 'Employee Number',
  'WorkOrderResources.assignmentCancelledMessage':
    'Work Order {0} has been cancelled.',
  'WorkOrder.message.multipleWithdrawn':
    '{0} Work Orders have been withdrawn from you',
  'IncomingSMSMessage.message': 'Message',
  'FaultReport.switchingInstructions': 'Switching Instructions',
  'mobile.noSelection': 'Selection Error!! Please try again.',
  'menu.outageMatrix': 'Outage Matrix',
  'button.editStatuses': 'Edit Statuses',
  'js.resultsLimitedTo200':
    'Results were limited to 200 objects. Please zoom in closer to ensure that all objects are displayed.',
  '*.selected': 'Select',
  'Call.address': 'Address',
  'Rule.parameters': 'Parameters',
  'SLAAuditTrail.workOrderRequest.code': 'Work Request',
  'js.sendMobileAppLink': 'Send Mobile App link',
  'js.quotationNumber': 'Quotation Number',
  'Device.ownerCustomer.code': 'Owner cust. code',
  'customerPortal.passwordupdated.emailsubject':
    'Customer Portal password updated',
  'userProfile.orgUnitsInfo':
    'Users will be restricted to these Organisational Units and their children. An empty list here means that the user has full access.',
  'DictionaryField.table.className': 'Table',
  'menu.SuspectOutages': 'Suspect Outages',
  'AssetAssetXref.customNumber1': '',
  'FaultReport.flashReport.id': 'Flash Report',
  'AssetAssetXref.customNumber2': '',
  'AssetAssetXref.customNumber3': '',
  'AssetAssetXref.customNumber4': '',
  'mobile.comments': 'Comments:',
  'jmesa.add': 'Add',
  'AssetAssetXref.customNumber5': '',
  'WorkOrder.contract': '',
  'Device.ODMSFlag': 'Maintained in ODMS?',
  'icon.information.img': '/images/iconInformation.gif',
  'userProfile.assignGroups': 'Assign Groups',
  'WorkOrder.orgUnit.description': 'Organisational Unit',
  'button.createDuplicate': 'Create Duplicate',
  'js.schedulerOptions': 'Scheduler Options',
  'SMSCampaign.description': 'Description',
  'Assettemplate.customLookup8': '',
  'Resource.code': 'Employee Number',
  'Assettemplate.customLookup7': '',
  'DeviceType.assetCategory': 'Asset Category',
  'Assettemplate.customLookup9': '',
  'Assettemplate.customLookup4': '',
  'Assettemplate.customLookup3': '',
  'js.cancel': 'Cancel',
  'Assettemplate.customLookup6': '',
  'Assettemplate.customLookup5': '',
  'FieldAccess.enableAudit': 'Enable Auditing?',
  'AssetCalendar.WorkOrderRequest.backgroundColor': '#66ff66',
  'Assettemplate.customLookup2': '',
  'Assettemplate.customLookup1': '',
  'Material.supplier.code': 'Supplier Code',
  'SMSCampaign.scheduledDeliveryDate': 'Scheduled Send Date/Time',
  'SMSCampaignCategory.descriptiveNotes': 'Message Template',
  'WorkOrder.error.workTypeRequiresContract':
    "A Contract must be selected for work type '{0}'",
  'SwitchingInstruction.customDate5': '',
  'FailteIrelandFeeSchedule.perPitchFee': 'Per Pitch',
  'SwitchingInstruction.customDate3': '',
  'Asset.locationAddress.geomArea': '',
  'SwitchingInstruction.customDate4': '',
  'SwitchingInstruction.customDate1': '',
  'SwitchingInstruction.customDate2': '',
  'user.visitWebsite': 'visit',
  'js.decommissionDevice': 'Decommission Device',
  'WorkOrder.city': 'City',
  'SubscriberDatabase.defaultHost': 'Default Host',
  id: 'ID',
  'FaultReport.customersOffSupplyCount': 'No. of Customers affected',
  '*.alert.lateFinish': 'Late Finish Alert ',
  'Assettemplate.customDate9': '',
  'DeviceType.schematicSymbol.title': 'SVG markup for schematic diagram symbol',
  'LoadSheddingTemplate.title': 'Load Shedding Template',
  'Assettemplate.customDate7': '',
  'Assettemplate.customDate8': '',
  'Assettemplate.customDate5': '',
  'Assettemplate.customDate6': '',
  'Assettemplate.customDate3': '',
  'Assettemplate.customDate4': '',
  'AssetAttribute.attributeValue': 'Attribute Value',
  'js.orgUnit': 'Org Unit',
  'Assettemplate.customDate1': '',
  'Assettemplate.customDate2': '',
  'FaultReport.error.noAbnormalPlantForNonNormalisedSwitchingEvents':
    "There is a non-normalised switching event on '{0}' not linked to an Abnormal Plant record. You can only restore the outage when all switching events that have not been normalised are linked to an Abnormal Plant record",
  'button.workOrder.create': 'Create Work Order',
  'js.editBillOfMaterials': 'Edit Bill Of Materials',
  'js.changeResolutionForSelected': 'Change Resolution Code for Selected',
  'SuperadminUser.email': 'Email',
  'inbox.quotations': 'Quotations',
  'editInspectionListItems.addItem': 'Add Inspection Item',
  'fieldAccess.requiredIfRule': "'Required If' rule",
  'publicholidays.heading': 'Public Holidays',
  'Device.connectivityToSourceText': 'Connectivity to Source',
  'SLAAuditTrail.sla.duration': 'Target Duration(min)',
  'userProfile.cookieLogin':
    'You cannot change passwords when logging in with the Remember Me feature.  Please logout and log back in to change passwords.',
  'mobile.inspectionList': 'Site List',
  'passwordReset.sms.message': 'Dear {0},\n\nYour new password is: {1}',
  'TrainingEvent.trainingDate': 'Training Date',
  'ScheduledWorkTemplate.sla': 'SLA',
  'Device.normalOpenLinks': 'Open Point links',
  'js.assignSelected': 'Assign Selected Work Requests',
  'Building.customDate10': '',
  'PurchaseRequisition.workRequestType.description': 'Work Type',
  'MaintenancePlan.button.createMaintenancePlan': 'Create Maintenance Plan',
  'WorkCalendarMatrixView.averageProperty.description': 'Average Property',
  'purchaseOrder.supplier.contactNumber': 'Contact Number',
  'js.schedulerViewType': 'View Type',
  'js.hideColumn': 'Hide Column',
  'clickstreams.heading': 'All Clickstreams',
  'mobile.inspectionList.editAll': 'Edit all items',
  '*.alert.lateStart':
    'Late Start AlertPurchase Order Delivered total differs from Invoiced total',
  'DeviceCategory.virtual': 'Virtual',
  'StockTakeFoundItem.quantityFound': 'Quantity Found',
  'Store.externalId': 'External ID',
  'js.addScheduledJob': 'Add Scheduled Job',
  'Contract.contactEmail': 'Contact Email',
  'PurchaseRequisition.notes': 'Notes',
  'Store.authorisedUsers': 'Authorised Users',
  'fieldAccess.fieldLabel': 'Field Label',
  'menu.viewOrgStructure': 'Org Structures',
  'Customer.added': 'Customer {0} has been added successfully.',
  'js.imageRotateRight': 'Rotate Right',
  'FailteIrelandFeeSchedule.perDoubleRoomFee': 'Per Double Room',
  'BillOfMaterialsItem.purchaseOrder': 'Purchase Order',
  'menu.viewQueryMapLineLayers': 'Query Map Line Layers',
  'js.addSupplierQuotationError':
    'The Quotation Number, Supplier and Total Excl. VAT are required',
  'Asset.associatedWorkOrders': 'Associated Work Orders',
  'Device.locationAddress': 'Device Address',
  'Material.labour': 'Labour?',
  'js.viewLinkedWorkRequest': 'View Linked Work Request',
  'ReferenceList.systemReferenceList': 'System Reference List',
  'js.noResultsFound': 'No Results Found',
  'ScheduledScript.lastRunTime': 'Last run date',
  'Customer.lastWorkDate': 'Last Work Date ',
  'js.singleSelectInstructions':
    'left-click on objects to select/de-select them.',
  'invoices.groupView': 'Group View',
  'workorders.planningView': 'Planning View',
  'notificationRule.deleted': 'Notification Rule Deleted',
  'StatusReference.ruleFlags': 'Rule Flags',
  'button.createInvoice': 'Create Invoice',
  '*.adminView': 'Admin View',
  'Resource.cacheSize': 'Cache Size',
  'PurchaseOrder.button.receiveSerialized':
    'Receive/Return Serialized Equipment',
  'WorkOrderAsset.pk.workOrder.workOrderStatus.description': 'Status ',
  'customerworkrequests.heading': 'Service Tickets',
  'FailteIrelandFeeSchedule.category': 'Category',
  'FaultReport.initialCauses': 'Apparent Cause',
  'js.PurchaseRequisitionsControllerTitle': 'Purchase Requisitions',
  'Workflow.heading': 'Work Flow',
  'errors.workType.chooseLowerLevel': 'Please select a lower level work type.',
  'workflow.saved': "Work Flow '{0}' saved",
  'Asset.serialisedassetmovements': 'Serialised Asset Movements',
  'ReferenceList.customIcon': 'Custom Icon Name',
  'QuickLaunch.assettree': 'Asset Tree',
  'js.otpSent': 'New one-time PIN sent to',
  'orgStructure.assignedUsers': 'Assigned Users',
  'SwitchingInstruction.timeStamp': 'Date/Time Instructed',
  'resource.user': 'User Account',
  'Customer.validation.circularReferencingParent':
    'Circular reference in customer parent hierarchy',
  'Project.status.description': 'Status',
  'Quotation.description': 'Description',
  'DeviceType.deleted': 'Device Type {0} Deleted',
  'FlashReport.protTeamLeaderDate': 'Signoff Date',
  'StatusReference.customNumber4': 'Custom Number 4',
  'StatusReference.customNumber3': 'Custom Number 3',
  'workimages.uploadfile': 'Upload File',
  'TitleBar.pleaseCallMe': 'Chat',
  'StatusReference.customNumber5': 'Custom Number 5',
  'AssetType.requiresAccurateGPS': 'Requires Accurate GPS?',
  'SubscriberDatabase.subscriptionExpires': 'Subscription Expires',
  'StatusReference.customNumber2': 'Custom Number 2',
  'StatusReference.customNumber1': 'Custom Number 1',
  'js.receiveSelectedBOMItems': 'Receive Selected BOM Items',
  'WorkOrder.customer.description': 'Customer Info',
  'js.viewLinkedQuotation': 'View Linked Quotation',
  'Customer.openWorkRequestCount': 'Open Work Requests',
  'assetscheduledworktemplates.heading': 'Assigned Scheduled Work Templates',
  'CustomMatrixView.dictionaryTable.className': 'Doc Type',
  'AssetScheduledWorkTemplateXref.pk.asset.description': 'Asset description',
  'js.addServiceItems': 'Add multiple Service Items',
  'Workflow.edit': 'Edit Workflow',
  'AssetTemplate.custom19': '',
  'errors.none': 'No error message was found, check your server logs.',
  'AssetTemplate.custom18': '',
  'SchematicDiagram.orgUnit.description': 'Org Unit',
  'LoadShedding.blockMVA': 'Block MVA',
  'AssetTemplate.custom17': '',
  'AssetTemplate.custom16': '',
  'menu.referenceManual': 'Reference Manual',
  'menu.viewPurchaseOrders': 'Purchase Orders',
  'StatusReference.defaultInspectionListGroup': 'Default Inspection',
  'WorkOrder.contract.description': 'Contract',
  'js.updateCode': 'Change Code',
  'UserGroup.monitorGroup': 'Monitor Group?',
  'customers.customerWorkView': 'Customer Work View',
  'SMSCampaignCategory.title': 'SMS Campaign Category',
  'Device.validation.parentRequired': 'This Device Type must have a parent',
  'ForcelinkBillingDetail.billingType.description': 'Billing Type',
  'SubscriberDatabase.accountStatus.description': 'Account Status\t',
  'resourceTrackMap.resourceBubbleText':
    "<p align='left'>Resource <a href='javascript:goToResource(${id});'>${code}</a href><br><align='left'>${description}<br/>${resourceType.description}",
  'WorkOrderRequest.signoffs': 'Signoffs',
  'BillOfMaterialsGroup.id': 'Id',
  'billOfMaterials.quantity': 'Quantity',
  'asset.title': 'Asset Detail',
  'StatusReference.active': 'Active? ',
  'AssetTemplate.custom20': '',
  'resource.deleted': 'Technician has been deleted successfully.',
  'FaultReport.failedToTrip': 'Failed To Trip',
  'js.groupSelectedAssetsToWorkOrder': 'Link Selected Assets to Work Order',
  'ScheduledScript.nextFireTime': 'Next Run',
  'SpatialLayer.url': 'URL',
  'WorkOrder.workOrderStatus.open': 'Open',
  'js.changeLabelTextFor': 'Change Label text for {0}',
  'js.changeOverallDiscount': '',
  'CallTake.customer.description.placeholder': 'Click to search',
  'flashreports.heading': 'Flash Reports',
  'menu.viewFieldAccess': 'Field Access Control',
  'SwitchingInstruction.deviceDescription': 'Bay/Circuit',
  'Assettemplate.customLookup10': '',
  'Device.assetStatus': 'Device Status',
  'ForcelinkPricing.subscriber.subscriberId': 'Subscriber',
  'WorkImage.externalURL': 'URL',
  'PlannedOutage.heading': 'Planned Outage',
  'subscriberCustomLabels.updated':
    'Custom Label has been updated successfully.',
  'js.viewResourceOnWorkMap': 'View Technician on Work Map',
  'ResourceChat.user.fullName': 'User',
  'editStatusListForm.title': 'Edit Status',
  'User.customLookup1': 'Chargeable',
  'User.customLookup2': '',
  'User.customLookup3': '',
  'User.customLookup4': '',
  'User.customLookup5': '',
  'mobile.shiftEndTime': 'Set Shift End Time',
  'InspectionListGroup.defaultAssetTypes': 'Default Asset Types',
  'js.viewInDeviceTree': 'View in Device Tree',
  'DeviceCategory.active': 'Active? ',
  'Lease.adminFee': 'Administration Fee',
  'ScheduledWorkTemplate.requiredResourceTypes': 'Required Resource Types',
  'faultReport.heading': 'Outage Details',
  'QueryMapLineLayers.title': 'Query Line Layers',
  'mobile.available': 'Available',
  'js.createPurchaseOrders': 'Create Purchase Orders',
  'StockTakeExpectedItem.material.serialised': 'Serialised?',
  'Quotation.button.addSupplierQuotation': 'Add Supplier Quotation',
  'MaterialStore.targetLevel': 'Target Quantity',
  'js.removeOrgUnitFromSupplier': 'Remove',
  'passwordReset.email.subject': 'Forcelink password reset',
  'SupplierOrgUnit.customLookup1.description': '',
  'Device.description': 'Device Description',
  'DeviceType.addressRule': 'Address Rule',
  'Asset.locationAddress.province': 'Province',
  'js.columnWidthPixels': 'Column width in pixels',
  'passwordReset.otpSentPleaseEnterBelow':
    'Once you receive the SMS, please enter the OTP below.',
  'SupplierOrgUnit.customLookup4.description': '',
  'user.linkedCustomer': 'Linked Customer',
  'Device.structureCode': 'Device Structure Code',
  'DeviceType.heading': 'Device Type',
  '*.latitude': 'Latitude',
  'PurchaseRequisition.newStatus': 'New Status',
  'WorkCalendarMatrixView.customColumnView': 'Custom Column View',
  'login.passwordHint.sent': 'The password hint for {0} has been sent to {1}.',
  'SchematicDiagram.newStatus': 'New Status',
  'Customer.postalCode': 'Postal Code',
  'menu.projects': 'Projects',
  '*.groupView': 'Group View',
  'js.setAddressFlags': 'Set Address Flags',
  'WorkOrderRequest.viewWorkOrderRequestDetails':
    'View Work Order Request Details',
  'resource.heading': 'Technician ',
  'Customer.custom40': '',
  'scheduledworktemplates.title': 'Scheduled Work Templates',
  'js.calendarMatrixEndDate': 'End Date:',
  'workimages.noImages': 'No Images',
  'CallTake.customer.description.hint':
    'Click to search by customer description',
  'js.translation': 'Translation',
  'WorkCalendarMatrixView.decimalPlaces': 'Decimal Places',
  'CustomManagerImpl_cp_mdt.twitterLoadSheddingRestored':
    'Load Shedding at ${station.description} restored at {timeStamp}. {shortURL}',
  '*.locationAddress': 'Work Location Address',
  'cistomer.view': 'View',
  'CustomMatrixView.description': 'View Name',
  'ResourceShiftRoster.resourceColumns': 'description,resourceType.description',
  'Customer.custom39': '',
  'Customer.custom38': '',
  'Customer.custom37': '',
  'Customer.custom36': '',
  'Customer.custom35': '',
  'Contract.scheduledWorkTemplates': 'Scheduled Work templates',
  'Customer.custom34': '',
  'Customer.custom33': '',
  'Customer.custom32': '',
  'Customer.custom31': '',
  'Customer.custom30': '',
  'js.suspectOutagePreviewIntro':
    'Below is a list of open Suspect Outages. If this trip is related to one of these existing suspect outages, please click on the ID.',
  'FieldAccess.required': 'Required?',
  'js.releaseNotes': 'Forcelink version {0} has been released!',
  'StockTake.store': 'Store',
  'CustomMatrixView.dictionaryTable': 'Doc Type',
  'restorationstagecustomers.heading':
    'Customers affected by Outage {0} from {1} to {2}',
  'js.viewLatestShiftOnMap': 'View Latest Shift on Map',
  'Customer.custom29': '',
  'workOrder.monitors': 'Monitors',
  'Customer.custom28': '',
  'Customer.custom27': '',
  'Customer.custom26': '',
  'Customer.custom25': '',
  'Customer.custom24': '',
  'Customer.custom23': '',
  'Customer.custom22': '',
  'WorkOrder.locationAddress.geom': '',
  'Customer.custom21': '',
  'Customer.custom20': '',
  'workrequestsubcontractorassignmentrules.title':
    'Work Request Automatic Subcontractor Assignment Rules',
  'js.clear': 'Clear',
  'js.changeSelectedAssetStatus': 'Change Selected Asset Status',
  'ReferenceList.customDate4': 'customDate4',
  'ReferenceList.customDate5': 'customDate5',
  'MaterialStore.reorderQuantity': 'Re-order Quantity',
  'js.selectSerialisedAssets': 'Select Serialised Assets',
  'js.addNote': 'Add Note',
  'WorkOrderRequest.locationAddress.geom': 'View Polygon',
  'PurchaseRequisition.billOfMaterials': 'Bill of Materials',
  'js.allocateCrew': 'Allocate Crew',
  'SLAAuditTrail.missedByDDHHMM': 'Missed By DDHHMM',
  'ReferenceList.customDate1': 'customDate1',
  'Customer.custom19': '',
  'ReferenceList.customDate2': 'customDate2',
  'Customer.custom18': '',
  'ReferenceList.customDate3': 'customDate3',
  'Customer.custom17': '',
  'Customer.custom16': '',
  'Store.customLookup10': '',
  'Customer.custom15': '',
  'Asset.orgUnit.description': 'Org. Unit',
  'SupplierQuotation.dateCreated': 'Date Captured',
  'Customer.custom14': '',
  'Customer.custom13': '',
  'Customer.custom12': '',
  'Customer.custom11': '',
  'Customer.custom10': '',
  'support.sendSms': 'Send SMS to Support',
  'menu.appearance': 'Appearance',
  'user.logout': 'Sign Out',
  'js.swapRotableAsset': 'Swap Rotable Asset',
  'QuickLaunch.scheduler': 'Scheduler',
  'ruleSelections.title': 'Rules',
  'js.createWorkOrderForOutage': 'Create Work Order for Outage',
  'PurchaseInvoice.dateCreated': 'Date Captured',
  'Resource.overtimeChargeRate': 'Overtime Charge',
  'trainingevents.heading': 'Training Events',
  'WorkOrderRequest.message.multipleWithdrawn':
    '{0} Work Requests have been withdrawn from you.',
  'StatusReference.systemStatus.label': 'System Status',
  'buildings.title': 'Buildings',
  'schematicdiagrams.standardView': 'Approved Versions',
  'FaultReport.protectionInvestigation': 'Protection Investigation',
  'menu.subcontractors': 'Subcontractors',
  'Resource.activeAllocatedResources.onDuty': 'Resource is on duty',
  'AssetType.assetCategory.description': 'Asset Category',
  'mobile.selectStandardCharge': 'Select Standard Charge',
  'smartCityPortal.webapp.slogan': 'own your city',
  'Device.serialNumber': 'Serial Number',
  'standardInspectionList.heading': 'Edit Standard Inspection Lists',
  'uploadForm.startDate': 'Document valid from',
  'PurchaseOrder.error.zeroInvoiceTotal':
    'The Purchase Invoice total must be greater than zero',
  'js.createLinearAddress': 'Create Geometry',
  'support.officeContacts': 'Office Contacts',
  'menu.runStartupTasks': 'Run startup tasks',
  'Device.warrantyEnd': 'Warranty End Date',
  'errors.password.newPasswordMustDiffer':
    'The new password must be different from the old password.',
  'Device.substationName': 'Substation',
  'FaultReport.workOrder.code': 'Work Order ID',
  'js.exactMatchNote':
    'Please note that these results are done using an exact match by default. If you require a wild-card match, please specify your search criteria with * or % as a wild-card character.',
  'js.customFieldDescription': 'Custom Field Description',
  'FailteIrelandFeeSchedule.integraProductCode': 'Product Code',
  'Asset.bookValue': 'Book Value',
  'js.setSelectedAddressFlags': 'Set Address Flags for Selected items',
  'js.imageSave': 'Save',
  'userProfile.title': 'User Settings',
  'documentsFilter.hasWorkRequest': 'Has Work Request',
  'WorkOrder.allocationHistory': 'Allocation History',
  'tax.taxNumberName': 'Vat Reg No',
  'orgStructure.heading': 'Org Structure Information',
  'js.createQuotation': 'Create Quotation',
  'Customer.city': 'City',
  'arcGISLayer.heading': 'ArcGIS Layer',
  'SMSCampaign.recipientsSummary.customersBySuburb': 'Customers by Suburb',
  'js.addWorkAddress': 'Add work address',
  'quotationscheduler.heading': 'Scheduler',
  'CustomTableColumnSelection.className': 'Table Class Name',
  'SMSCampaign.selectedRecipients': 'Selected Recipients',
  'js.matrixGroupByTitle': 'Group By (left column)',
  'orgStructure.updated': 'Org Structure has been updated successfully.',
  'call.heading': 'Rapid Call Take',
  'WorkOrder.address': 'Address',
  'failteirelandfeeschedules.heading': 'Failte Ireland Fee Schedules',
  'Asset.imageNo': 'Image',
  'StockTakeExpectedItem.message': 'Result',
  'Lease.responsibleUser': 'Responsible Person',
  'PurchaseRequisition.customerOrderNumber': 'Customer Order Number',
  'Asset.assetType.code': 'Asset Type Code',
  'SubscriberDatabase.currentHost': 'Current Host',
  'SupportSMS.delivered': 'Support SMS Delivered to {0}.',
  'mobile.submit': 'Submit',
  'notificationRule.timer': 'timer (Cron Expression)',
  'SwitchingInstruction.locationDevice': 'Station and Bay/Distributor',
  'call.addressSearchField': 'Search Address',
  'Material.unitType.code': 'Unit Type',
  'rules.heading': 'Rules',
  '*.description': 'Description',
  SMSCampaign: 'SMS Campaign',
  'CustomerPortal.webapp.name': 'Customer Portal powered by Forcelink',
  'ForcelinkSubcontractor.heading': 'Subcontractor (using Forcelink)',
  'Store.scrapStore': 'Scrap Store',
  'devices.title': 'Devices',
  'Asset.customDate9': '',
  'WorkOrder.customerOrderNumber': 'Ticket Number',
  'Asset.customDate5': '',
  'Asset.customDate6': '',
  'Asset.customDate7': '',
  'Asset.customDate8': '',
  'SuperadminUser.username': 'Username',
  'SMSCampaign.recipientsSummary': 'Recipients Summary',
  'Asset.customDate1': '',
  'Asset.customDate2': '',
  'errors.workType.requiresLinkedWorkOrderToComplete':
    'This work request type cannot be completed if not linked to a work order',
  'Asset.customDate3': '',
  'mobile.title.addMiscItem': 'Add Misc Item',
  'Asset.customDate4': '',
  'LabelValueFlag.value': 'Value',
  'js.selectSubcontractor': 'Select sub-contractor',
  'InspectionListItem.largeFormat.notes': '',
  'js.calendarBackHint': 'Click to drill out',
  'js.materialCode': 'Material Code',
  'passwordReset.otp.noMobileNumber':
    "There is no mobile number associated with your account, so password reset by OTP is not possible. Please contact your administrator or mail <a href='support@forcelink.net'>Support</a> to reset your account.",
  'Quotation.noteAddedEmailBody':
    'Dear {0},\n\nNote added at {1}:\n\n{2}\n\nRegards, Forcelink',
  'mobile.availableUntil': 'Available Until',
  'CustomerNotification.label': 'Message',
  'ForcelinkSubcontractor.username': 'Username',
  'js.viewCustomerQuotations': 'View Customer Quotations',
  'custommatrixviews.title': 'Custom Matrix Views',
  'WorkOrder.add': 'Add',
  'js.resourceShiftRosterColumnHeadings': 'Name,Type',
  'invoices.heading': 'Invoices',
  'js.quantity': 'Quantity',
  'SchematicDiagram.heading': 'Schematic Diagram',
  'js.validationRuleHint':
    'Allows a regular expression or boolean expression to be defined to specify valid strings or numbers',
  'arcGISLayer.title': 'ArcGIS Layer',
  'VersionInfo.info': 'Change Info',
  'js.workOrdersOnAncestorAsset':
    'Include Work Orders linked to ancestor assets',
  '*.province': 'Province',
  'locationAddress.addressType': 'Address Type',
  'orgStructure.parentOrgUnit.description': 'Parent Org Unit',
  'DashboardEntry.colourHint':
    "HTML color code for the KPI label's background colour",
  'LoadShedding.loadSheddingStage.description': 'Stage',
  'SLA.created': 'SLA {0} created',
  'orgStructure.customNumber5': '',
  'orgStructure.customNumber4': '',
  'orgStructure.customNumber3': '',
  'tablevalidationrules.heading': 'Table Validation Rules',
  'orgStructure.customNumber2': '',
  'orgStructure.customNumber1': '',
  'menu.viewMaterials': 'Materials',
  'StockTake.error.expectedItemNotFound': 'Not found in stock take',
  'subscriberDatabase.subscriberId': 'Subscriber Id',
  'StatusReference.workflow': 'Workflow',
  'Quotation.statusUpdateNotifyMonitorsEmailSubject':
    'Status update on Quotation [{0}]:${status.description}',
  'js.selectContractMenu.searchContract': 'Search Contract',
  'Store.assetType': 'Store Type',
  'PersonalDataExpiryRule.heading': 'Personal Data Expiry Rule',
  'SwitchingInstruction.instruction': 'Operating Instructions',
  'mainMenu.showMap': '',
  'Resource.customDate10': '',
  'PurchaseInvoice.invoiceNumber': 'Invoice Number',
  'LoadShedding.id': 'ID',
  'button.viewSchematic': 'View Schematic',
  'WorkOrderRequest.appointment': 'Appointment',
  'Warranty.active': 'Active? ',
  'WorkOrder.resolutionCode': '',
  'js.userSessionKilled': 'User session killed',
  'Asset.lifecycleFrame': 'Life Cycle/Financial',
  'mobile.quotationListTitle': 'All Quote Requests',
  'DeviceCategory.code': 'Device Type Code',
  'errors.invalidDashboardSQL': "Error: invalid SQL for dashboard entry '{0}':",
  'inspectionitems.captureOutstanding': 'Capture Outstanding',
  'WorkOrderAutoAssignmentRule.resourceFilter': 'Resource Filters',
  'Customer.officeNumber': 'Office Number',
  'FlashReport.responsibleProtUser.fullName': 'Responsible Tech',
  'AssetCategory.active': 'Active? ',
  'StockTake.error.incorrectQuantity.expected':
    'Incorrect Quantity (Expected {0})',
  'WorkOrderAutoAssignmentRule.resourceSort.hint':
    'Please supply a comma-separated list of sort criteria (e.g. description)',
  'SubscriberDatabase.billAhead': 'Bill Ahead?',
  'FaultReport.subsequentDamage': 'Subsequent Damage',
  'availabilityCalendar.resource': 'Technician',
  'SMSCampaign.otherCampaignCategory': 'Other Category Text',
  'menu.forceMobileRefresh': 'Force Mobile Refresh',
  'auditLog.title': 'Audit Log',
  'Supplier.customLookup9': '',
  'errors.login.AuthenticationCredentialsNotFoundException':
    'Invalid username/subscriber.',
  'Supplier.customLookup6': '',
  'DeviceType.inactivated': 'Device Type {0} Inactivated',
  'Supplier.customLookup5': '',
  'Note.userName': 'User',
  'Supplier.customLookup8': '',
  'Supplier.customLookup7': '',
  'Supplier.customLookup2': '',
  'WorkOrderRequest.customer': 'Customer',
  'Supplier.customLookup1': '',
  'Supplier.customLookup4': '',
  'Supplier.customLookup3': '',
  'passwordReset.submit': 'Submit',
  'WorkOrder.dispatchedBy': 'Dispatched By',
  'js.printSchematicDiagram': 'Print',
  'PurchaseOrder.outstandingTotal': 'Value Outstanding (excl. VAT)',
  'FaultReport.workOrderCode': 'Work Order Code',
  'FaultReport.feedbackFlags': 'Fault Statistics',
  'mobile.searchMode.fullCatalog': 'Full Catalog',
  'user.accountLocked': 'Locked',
  'Invoice.createdDateTime': 'Date and Time Created',
  'UserGroup.inboxEventCategories.title':
    'Comma-delimited list of inbox event categories to alert the users for',
  'button.submit': 'Submit',
  'js.validationRule': 'Validation rule',
  'mobile.selectStandardShift': 'Select standard shift',
  'WorkRequestAutoAllocationRule.activeFromHHMM': 'Active From (HH:MM)',
  'ForcelinkBilling.monthYear': 'Month/Year',
  'PurchaseOrder.orgUnit.description': 'Org Unit',
  'js.issueBOMItemDialogTitle': 'Issue BOM Item',
  'js.addAssetToList': 'Add Asset to list',
  '*.currency.code': 'Currency',
  'passwordReset.invalidOTP':
    "Sorry, the OTP you have entered is invalid or expired. Click the 'Re-send OTP' button below to re-send.",
  'InspectionListTable.heading': 'Edit Inspection List',
  'js.pleaseSelectAStore': 'You must select a store',
  'button.moveUp': 'Move Up',
  'WorkOrder.custom1': '',
  'ScheduledWorkTemplate.workOrderDescription':
    'Work Order default description',
  'LoadShedding.status': 'Status',
  'ShiftType.smsflag': 'Send SMS notifications for this shift? ',
  'WorkOrder.custom8': '',
  'WorkOrder.custom9': '',
  'WorkOrder.custom6': '',
  'menu.workOrderMatrix': 'Work Order Matrix',
  'WorkOrderRequest.billOfMaterialsDefaultColumnView': 'Order View',
  'WorkOrder.custom7': '',
  'WorkOrder.custom4': '',
  'WorkOrder.custom5': '',
  'WorkOrder.custom2': 'Additional Sign off Authority Name',
  'WorkOrder.custom3': 'Additional Sign off Authority Number',
  'Project.contract.description': 'Contract',
  'AssetStockSummary.assetStatus.description': 'Asset Status',
  'AbnormalPlant.device.deviceType.description': 'Device Type',
  'menu.memoryStatistics': 'Memory Statistics',
  'billOfMaterials.miscItemDescription': 'Description',
  'StockTake.newStatus': 'New Status',
  'PlannedOutage.reinstateAbnormalPlant.id': 'Abnormal Plant to Reinstate',
  'InternationalLabel.languageLabel': 'Label',
  'users.billingView': 'Billing View',
  'HashMap.customTransient2.format': '###,##0.00',
  'Customer.postalAddress': 'Postal Address',
  'AuditTrail.rowDescription': 'Row Description',
  'fieldAccess.showIfRule': "'Show If' rule",
  'crew.crewType': 'Crew Type',
  'ruleParameters.heading': 'Rule Parameters',
  'SupplierQuotation.status': 'Status',
  'notification.jobContinuingInBackground':
    'This operation is taking a long time, continuing in the background. You will be notified when the job has been completed.',
  'custommatrixviews.heading': 'Custom Matrix Views',
  'button.back': 'Back',
  'BillOfMaterialsItem.custom5': '',
  'SupplierOrgUnit.supplier.description': 'Supplier',
  'BillOfMaterialsItem.custom4': '',
  'BillOfMaterialsItem.custom3': '',
  'BillOfMaterialsItem.custom2': '',
  'BillOfMaterialsItem.custom1': '',
  'SLAAuditTrail.missedByMins': 'Missed By (mins)',
  'WorkOrderRequest.Asset': 'Assets',
  'WorkRequestSubcontractorAssignmentRule.workRequestFilter':
    'Work Request Filters',
  'SMSCampaign.recipientsSummary.usersByGroup': 'Users by Group',
  'AssetType.title': 'Asset Type',
  'WorkOrder.billOfMaterials': 'Bill Of Materials',
  'WorkOrderRequest.locationAddress': 'Location Address',
  'globalSettings.fax': 'Fax',
  'InspectionListGroup.defaultWorkOrderTypes': 'Default Workorder Types',
  'SupplierQuotation.quotationSupplier': 'Supplier',
  'PublicHoliday.date': 'Date',
  'WorkOrder.billOfMaterialsGroup.totalCostExcl': 'Cost Excl',
  'FlashReport.faultReport.rootCause.description': 'Root Cause',
  'passwordReset.successful': 'Password Reset Successful!',
  'AssetType.added': 'Asset Type {0} Added',
  'js.confirmDelete': 'Are you sure you want to delete this record?',
  'CustomerPortal.login.userNotLinkedToCustomer':
    'The user credentials are not correctly linked to a customer. Please contact your administrator for assistance.',
  'inboxevents.title': 'Inbox Events',
  'js.createChildWorkOrder': 'Create Child Work Order',
  'workorderautoassignmentrule.title':
    'Work Order Automatic Resource Assignment Rule',
  'js.moveProjectStartDate': 'Move Project Start Date',
  'menu.schematicDiagrams': 'Schematic Diagrams',
  'WorkOrder.resolutionCode.description': 'Resolution Code',
  'Resource.resourceType.resourceCategory.description': 'Category',
  'WorkOrderAutoAssignmentRule.workOrderFilter': 'Work Order Filters',
  'materials.heading': 'Materials',
  'viewstream.title': 'Stream Details',
  updated: '{0} {1} updated',
  'js.replaceContactDetailsMessage':
    'Do you want to replace the contact details on the form with the default contact details of the customer?',
  'menu.viewGlobalSettings': 'Global Settings',
  'errors.detail': '{0}',
  'spatialLayers.heading': 'Map Custom Layers',
  'js.addAssetNote': 'Add Note',
  'Device.assetStatus.description': 'Device Status',
  'CustomMatrixView.title': 'Custom Matrix View',
  'WorkOrderRequest.alerts': 'Alerts',
  'js.refreshLiveStatus': 'Refresh Live Statuses',
  'FaultReport.weather': 'Weather',
  'notificationRule.pushNotificationFlag': 'Push Notification?',
  'AssetScheduledWorkTemplateXref.pk.asset.assetType.assetCategory.description':
    'Asset Category',
  'call.contactNumber': 'Contact Number',
  'js.workordersAllocatedCount': 'Work Orders allocated',
  'FileUpload.heading': 'Upload File',
  'Quotation.workRequestStatus': 'New Status',
  'js.pleaseSpecifyMoveReason':
    'Please specify a reason or work order number for the stock move',
  'js.mapContextFindAddressedNearby': 'Find Addresses Nearby',
  'Resource.message.lateStart':
    '{0}, you were expected to become available at {1} but have not made yourself available yet. Time now is {2}.',
  'referenceListDetail.title': 'Reference List Detail',
  'BillOfMaterialsItem.subHeading': 'Sub Heading',
  'SLAAuditTrail.endDate': 'SLA End Date',
  'workorderrequests.OMSView': 'OMS View',
  'WorkOrder.noteAddedEmailSubject': 'Note added to Work Order {0}',
  'js.requiredIfRuleHint':
    "The item will be displayed if the condition is true either in this field or in another field in the list. Text must be formatted as follows: ${<field id value to look up>}=='<value to check for>' && ${<another field id value to look up>}=='<value to check for>' || (<more criteria>), etc",
  'mobile.enterSearchString': 'Enter Search String',
  'menu.materialStoreMatrix': 'Material Stock Matrix',
  HistoricalWorkOrder: 'Historical Work Order',
  'AbnormalPlant.department.description': 'Department',
  'menu.viewQuotations': 'Quotations',
  'js.addBomItem': '',
  'myresources.title': 'My Resources',
  'js.deleteConnection': 'Delete Connection',
  'DashboardEntry.displayGroup': 'Display Group',
  'workOrderRequestMobile.heading': 'Quotation Details',
  'Warranty.vendor': 'Vendor',
  'StockTake.prompt.serializedConfirmCaptureQuantity':
    'This is a serialised stock code. If you want to check quantities only, please continue, otherwise cancel and scan the individual items',
  'FlashReport.faultReport.startDate': 'Start Date',
  'button.sendPlannedOutageInternalNotifications': 'Internal PO Notifications ',
  'StockTake.adjustments': 'Adjustments',
  'userGroups.title': 'User Groups',
  'Call.childWorkType': 'Sub Call Type',
  'Device.liveOperationAllowed': 'Live Operation Allowed?',
  'BillOfMaterialsItem.billOfMaterialsGroup.parentDType': 'Doc Type',
  'unitType.description': 'Unit Type',
  'mobile.searchMode.byCode': 'Search by Stock Code',
  'passwordReset.email.message':
    "Dear {0},\n\nYour password has been reset to : {1}.\n\nPlease remember to change it in the 'Customer Details' section after logging in.\n\nRegards,\nForcelink admin",
  'Resource.available': 'Available',
  'workcalendarmatrixviews.heading': 'Work Calendar Matrix Views',
  'SLAAuditTrail.workOrder.code': 'Work Order',
  'Asset.customNumber19': '',
  'errors.login.AccessDeniedException': 'Access is denied.',
  'mobile.searchWorkOrders': 'Search Work Orders',
  'Resource.chats': 'Chats',
  'AbnormalPlant.switchingEvents': 'Abnormal Switching Events',
  'CustomManagerImpl_cp_mdt.LoadShedding': 'Load Shedding',
  'Asset.customNumber20': '',
  'WorkOrder.customMultipleChoice1': 'Functions to be Supplied by Customer',
  'material.custom8': '',
  'WorkOrder.customMultipleChoice2': '',
  'SLAAuditTrail.sla.description': 'SLA Name',
  'material.custom9': '',
  'WorkOrderAsset.pk.workOrder.scheduledWorkTemplate.description':
    'Sched. Work Template',
  'material.custom4': '',
  'material.custom5': '',
  'material.custom6': '',
  'material.custom7': '',
  'material.custom1': '',
  'material.custom2': '',
  'material.custom3': '',
  'js.showConnectionDirectionArrows': 'Show connection Direction Arrows',
  'Customer.accountManager': 'Account Manager',
  'availabilityCalendar.startDateTime': 'Start Time',
  'js.mapContextSelectObjectsWithPolygon': 'Select objects with polygon',
  'Asset.customNumber17': '',
  'Asset.customNumber18': '',
  'SchematicDiagram.status.description': 'Diagram Status',
  'button.moveDown': 'Move Down',
  'Asset.customNumber15': '',
  'Asset.customNumber16': '',
  'Exports.title': 'Table Exports',
  'Asset.customNumber13': '',
  'Device.warrantyStart': 'Warranty Start Date',
  'AuditTrail.auditType': 'Event Type',
  'Asset.customNumber14': '',
  'Asset.customNumber11': '',
  'Asset.customNumber12': '',
  'crew.deleted': 'Crew has been deleted successfully.',
  'material.customMultipleChoice2': '',
  'material.customMultipleChoice1': '',
  'Asset.customNumber10': '',
  'material.customMultipleChoice4': '',
  'material.customMultipleChoice3': '',
  'MaterialStore.needsReorder': 'Needs Reorder?',
  'material.customMultipleChoice5': '',
  'customerMobile.workRequestViewAll': 'View Claims',
  'customerMobile.workOrderETA': 'Estimated Time of Arrival',
  'customerMobile.workOrderETC': 'Estimated Time of Completion',
  'WorkOrderRequest.workRequestStatus': 'Status',
  '*.currency.description': 'Currency',
  'assettemplate.title': 'Asset Template',
  'Asset.costCentre': '',
  'activeUserList.heading': 'Users Currently Active',
  LoadShedding: 'Load Shedding Event',
  'Customer.emailAddress': 'Email Address',
  'user.createResource': 'Create a Resource for this user?',
  'InspectionListGroup.subGroups': 'Sub Groups',
  'Asset.warrantyStart': 'Warranty Start Date',
  'WorkOrder.customMultipleChoice5': '',
  'Resource.isOnline': 'Online?',
  'WorkOrder.customMultipleChoice3': '',
  'WorkOrder.customMultipleChoice4': '',
  'StockTakeFoundItem.barcode': 'Barcode',
  'address.deleted': 'Address has been deleted successfully.',
  Duplicate: 'Duplicate {0}',
  'LoadShedding.customerSMSMessage':
    'Dear [customer], Joburg Water is performing Load Shedding in [suburb] on {0} from {1} to {2}. Your electricity supply may be affected.',
  'js.addLease': 'Add Lease',
  'InspectionListGroup.allowChildGroups': 'Allow Child Groups?',
  'Quotation.workRequestStatus.description': 'Status',
  'TestHQL.heading': 'Test HQL statement',
  '*.SAPView': 'SAP View',
  'Device.dimensions': 'Capacity/size',
  'mobile.inspectionListItem': 'Inspection List Item',
  'WorkOrderRequest.durationHours': 'Duration (Hours)',
  'BillOfMaterialsItemAsset.pk.bomItem.material.description': 'Material Desc',
  'BillOfMaterialsItem.unexpectedQuantityReceivedMessage':
    'Expected {0}, received {1}',
  'js.setResourceAvailability': 'Set Resource Availability',
  active: 'Active?',
  'WorkDoc.status': 'Status',
  'testREST.button.getTemplate': 'Get Template',
  'locationAddress.address7': 'City',
  'locationAddress.address8': 'Postal Code',
  'locationAddress.address5': 'Street Name',
  'locationAddress.address6': 'Suburb',
  'locationAddress.address3': 'Building',
  'locationAddress.address4': 'Street Number',
  'locationAddress.address1': 'Postal Address1',
  'locationAddress.address2': 'Postal Address2',
  'CustomManagerImpl_cp_mdt.twitterLoadSheddingStarted':
    'Load Shedding at ${station.description}. Start time {startTime}. Planned Finish {plannedFinish}. {shortURL}',
  'Quotation.address': 'Address',
  'QueryMapLayer.hql': 'Layer Query Criteria',
  'TaxType.percentage': 'Precentage',
  'button.loginToPortal': 'Login to Portal',
  'CustomerPortal.registrationOTPMessage':
    'Dear {0}, please complete your registration by entering this OTP:{1}',
  'Lease.customDate3': '',
  'CustomManagerImpl_cp_mdt.ticketTooOldToEscalate':
    'Sorry, ticket {0} is too old to escalate, please log a new ticket. Escalations must occur within 4 hours of the fault being closed.',
  'Lease.customDate4': '',
  'Lease.customDate5': '',
  'Customer.customerStatus': 'Status',
  'js.customReferenceListCode': 'Code',
  'Invoice.invoiceStatus.description': 'Status',
  'menu.resolveConfigChange': 'Resolve Config Ticket',
  'Asset.weight': 'Weight',
  'BillOfMaterialsItemAsset.pk.bomItem.parentDoc': 'Linked Doc',
  'ForcelinkSubcontractor.user.passwordHint': 'Password Hint',
  'StatusReference.view': 'View',
  'js.viewLinkedDiagram': 'View Linked Diagram',
  'Contract.workorders': 'Work Orders/Deliverables',
  'Device.sameSourceAndAncestor': 'Same Source and Ancestor?',
  'LoadShedding.customLookup10': '',
  'FaultReport.error.unrestoredCustomers':
    'There are still unrestored customers, please check your interruption phases',
  'Lease.customDate1': '',
  'Lease.customDate2': '',
  'publicholidays.title': 'Public Holidays',
  'js.geometryLineColour': '#000000',
  'material.materialSubType': 'Sub Type',
  'Quotation.custom9': '',
  'workrequestsubcontractorassignmentrules.heading':
    'Work Request Automatic Subcontractor Assignment Rules',
  'Quotation.custom7': '',
  'Quotation.custom8': '',
  'Quotation.custom5': '',
  'Quotation.custom6': '',
  'Quotation.custom3': 'Work Order Reference',
  'ReferenceList.defaultItem': 'Default',
  'Quotation.custom4': '',
  'MobileManagerView.heading': 'Mobile Manager View',
  'StockTake.dateCreated': 'Date Created',
  'Customer.user.password': 'Forcelink Password',
  'menu.viewResourceShiftRoster': 'Shift Roster',
  'WorkOrder.notes.sortOrder': 'asc',
  'ScheduledScript.heading': 'Scheduled Scripts',
  'SchematicDiagram.type.description': 'Type',
  'supportSms.title': 'Support Request',
  'Quotation.custom1': '',
  'js.contractPeriodEndDate': 'Period End Date',
  'Quotation.custom2': '',
  'User.billingType.description': 'Billing Type',
  'Resource.user.passwordHint': 'Password Hint',
  'button.forceRecalculate': 'Force Recalculate',
  'errors.login.ConcurrentLoginException':
    'This user is already logged in on another session. Concurrent logins are not allowed.',
  'WorkOrderRequest.contactNumber': 'Technician Responsible Number',
  'Customer.id': 'Id',
  'PlannedOutage.actualSwitchingInstructions':
    'Actual Switching Events and Instructions',
  'QueryMapLineLayers.heading': 'Query Line Layers',
  'PurchaseOrder.custom4': '',
  'PurchaseOrder.custom5': '',
  'PurchaseOrder.custom2': 'PO Description',
  'js.shiftType': 'Shift Type',
  'js.createContractBOMPeriod': 'Create Contract BOM Period',
  'PurchaseOrder.custom3': '',
  'Supplier.type': 'Type',
  'SLA.inactivated': 'SLA {0} inactivated',
  'menu.assignedScheduledWorkTemplates': 'Assigned Scheduled Work Templates',
  'js.confirmDeleteSelectedRecords':
    'Are you sure you want to delete the selected records?',
  'support.phone': 'Tel',
  'js.selectResource': 'Select Resource',
  'Contract.extendableUntil': 'Extendable Until',
  '*.unitPrice': 'Unit Price',
  'SubscriberDatabase.supportPerson': 'Support Person',
  'CustomField.select': 'Select',
  'FaultReport.currentCustomersOffSupplyCount':
    'No. of Customers currently off',
  'js.projectTaskType': 'Type',
  'js.purchaseOrder': 'PurchaseOrder',
  'SwitchingEvent.operator.description': 'Operator 1',
  'mainMenu.activeUsers': 'Users',
  'PurchaseOrder.custom1': 'Purchase Order Number',
  'User.groups': 'Groups',
  'user.enabled': 'Enabled',
  'Quotation.monitorGroups': 'Monitor Groups',
  'Customer.updated': 'Customer {0} has been updated successfully.',
  'FaultReport.switchingEvents': 'Switching Events and Instructions',
  'label.password': 'Password',
  'SupplierOrgUnit.customLookup2.description': '',
  'StockTakeFoundItemDWR.message': 'Result',
  'menu.smsCampaigns': 'SMS Campaigns',
  'InspectionListItem.valueString': 'Value',
  'CustomerInteraction.url': 'Doc',
  'mobile.materialsOptionsTitle': 'Materials Options',
  'StatusReference.select': 'Select',
  'StockTakeExpectedItemDWR.message': 'Result',
  'js.addUsers': 'Add User(s)',
  'WorkOrder.workLog': 'Status Change Audit Trail',
  'material.miscellaneousType': 'Miscellaneous material type?',
  'uploadForm.newFileName.title':
    'File name to save the file as, without extension. If blank, the original file name of the uploaded file will be used.',
  'errors.tableValidation': '{0}',
  'customerMobile.welcome': 'Welcome to Forcelink at {0}, {1}!',
  'errors.userNotFound': 'The current user was not found!',
  'SwitchingEvent.reportTime': 'Report date/Time',
  'Device.aliasName': 'SCADA TA',
  'CustomReferenceList.description': 'Description',
  'WorkOrder.custom.address.address8': '',
  'WorkOrder.custom.address.address6': '',
  'WorkOrder.custom.address.address7': '',
  'User.employer.description': 'Employer',
  'Quotation.workRequestStatus.systemStatus.open': 'Open?',
  'WorkOrder.custom.address.address1': '',
  'orgStructures.heading': 'Organisational Units',
  'WorkOrder.custom.address.address4': '',
  'WorkOrder.custom.address.address5': '',
  'WorkOrder.custom.address.address2': '',
  'WorkOrder.custom.address.address3': '',
  'FaultReport.department.description': 'Department to Investigate',
  'js.assetNotes': 'Asset Notes',
  'assetstock.button.transfer': 'Transfer Stock between stores',
  'SchematicDiagram.orgUnit': 'Org Unit',
  'js.addStandardBillOfMaterials': '',
  'WorkOrder.billOfMaterialsDefaultColumnView': 'Order View',
  'button.sendOTP': 'Send One Time PIN',
  'BillOfMaterialsItem.totalInclVat': 'Total Incl. VAT',
  'Resource.currentPlannedShift.endDateTime': 'On Duty Until',
  'FailteIrelandFeeSchedule.perBedFee': 'Per Bed',
  'Customer.billingAddress.requiresReverseGeocoding': '',
  'workorders.invoice': 'Invoice',
  'Rule.code': 'Code',
  'js.pleaseSelectMaterialForAsset': 'Please select a material for this asset',
  'WorkOrderRequest.resourceFeedback': 'Resource Feedback',
  RestorationStage: 'Interruption Phase',
  'orgStructureList.title': 'Org Structure List',
  'ProjectGantt.task.desc': '${description}',
  'resource.resourceCategory.description': 'Resource Category',
  'Quotation.durationHours': 'Duration (Hours)',
  'js.selectOrgUnits': 'Select Org Units',
  'Call.assignToMe': 'Assign to Me?',
  'js.specifyConfirmedTrippedDevice': 'Specify Confirmed Trip Location',
  'customerMobile.workRequestViewDetails': 'View Work Request Details',
  'subscriberdatabases.title': 'Subscriber Databases',
  'PlannedOutage.department': 'Department Assigned',
  'QueryMapLineLayer.lineColour': 'Line Colour',
  'RuleSelection.rule.description': 'Description',
  'InspectionListItem.largeFormat.value': 'Value',
  'js.clearResourceMobileCache': 'Clear Resource Mobile Cache',
  'users.title': 'User List',
  'ShiftType.shiftTypeColour': 'Colour',
  'Asset.customLookup4': '',
  'Asset.customLookup3': '',
  'Asset.customTransient2': '',
  'Asset.customLookup2': '',
  'Asset.customTransient1': '',
  'Asset.customLookup1': '',
  'js.removeDevicesPlanned': 'Remove Device(s) (planned)',
  'Asset.customLookup8': '',
  '*.customer.homeNumber': 'Home Number',
  'Asset.customLookup7': '',
  'Asset.customLookup6': '',
  'Asset.customLookup5': '',
  'Asset.customTransient8': '',
  'menu.companyInfo': 'Company Info',
  'Asset.customTransient7': '',
  'UserGroup.shortcuts': 'Standard Shortcuts',
  'QueryMapLayer.deleted': 'Query Layer Deleted',
  'Asset.customTransient9': '',
  'Asset.customTransient4': '',
  'assetstocksummary.heading': 'Asset Stock Summary',
  'Asset.customTransient3': '',
  'js.editFieldAccessRules': 'Edit Field Access rules',
  'Asset.customTransient6': '',
  'Asset.customTransient5': '',
  'Resource.customNumber4': '',
  'Resource.customNumber5': '',
  'Resource.customNumber2': '',
  'Resource.customNumber3': '',
  'ResourceShiftRecord.startingMileage': 'Starting Mileage',
  'Resource.customNumber1': '',
  'DeviceType.availableEquipment': 'Related Equipment',
  'Asset.customLookup9': '',
  'CustomMatrixView.filters': 'Filters',
  '*.alert.allocatedResourceOffDuty': 'Allocated Technician is off duty',
  'Contract.slas': 'SLAs',
  'LoadShedding.customNumber2': '',
  'LoadShedding.customNumber3': '',
  'js.sendWapLink': 'Send WAP Link',
  'LoadShedding.customNumber1': '',
  'LoadShedding.customNumber6': '',
  'js.countAssetsUpdated': '{0} Assets updated',
  'LoadShedding.customNumber7': '',
  'LoadShedding.customNumber4': '',
  'material.reorderLevel': 'Reorder Level',
  'LoadShedding.customNumber5': '',
  '*.customer.contactPerson': 'Contact Person',
  'PersonalDataExpiryRule.hqlFilters': 'HQL Filters',
  'LoadShedding.customNumber8': '',
  'LoadShedding.customNumber9': '',
  'PurchaseRequisition.customLookup20': '',
  'errors.mask': '{0} is not a valid pattern.',
  'stores.title': 'Stores',
  'AssetCategories.heading': 'Asset Categories',
  'WorkOrderRequest.customer.parentCustomer.description': '',
  'js.groupSelectedWorkOrdersToParent':
    'Group Selected Work Orders to this Parent',
  'js.viewSerialisedAssetMovements': 'View Serialised Asset Movements',
  'WorkOrderRequest.assetList': 'Assets',
  'Building.ownerCustomer.description': 'Owner',
  'DashboardEntry.type.description': 'Type',
  'PurchaseRequisition.customLookup15': '',
  'PurchaseRequisition.customLookup16': '',
  'PurchaseRequisition.customLookup13': '',
  'PurchaseRequisition.customLookup14': '',
  'PurchaseRequisition.customLookup19': '',
  'ResourceChat.text': 'Text',
  'PurchaseRequisition.customLookup17': '',
  'Contract.notifyDaysBeforeExpiry': 'Notify Days before Expiry',
  'PurchaseRequisition.customLookup18': '',
  'errors.login.BadCredentialsException':
    'Invalid username/password/subscriberID combination, please try again.',
  descriptionTitle: 'View/Edit {0} ',
  'WorkOrder.workOrderType': 'Type',
  TrippedDevice: 'Tripped Device',
  'WorkOrderAutoAssignmentRule.sortOrder': 'Sort Order',
  'MaterialStore.stockOnHand': 'Stock On Hand',
  'FaultReport.error.specifyRootCause':
    'You must supply a root cause to close the Outage',
  'rules.title': 'Rules',
  saturday: 'Saturday',
  'workorderrequests.standardView': 'Standard View',
  'js.createLoadSheddingTemplate': 'Create Load Shedding Template',
  'SMSCampaign.heading': 'SMS Campaign',
  'button.emailJavaLink': 'send Java link by Email',
  'menu.smsCampaignCategories': 'SMS Campaign Categories',
  'FaultReport.protInvestigationDate': 'Prot. Investigation Date',
  'Resource.crew.description': 'Parent Crew',
  'js.deleteDocument': 'Delete Document',
  'PurchaseRequisition.customLookup11': '',
  'PurchaseRequisition.customLookup12': '',
  'PurchaseRequisition.customLookup10': '',
  'QuickLaunch.resourceworkordermap': 'Work Map',
  'notificationRuleDetail.title': 'Notification Rule Details',
  'js.createParentWorkOrderForSelectedWorkOrders':
    'Create Parent Work Order for Selected Work Orders',
  'Assettemplate.code': 'Code',
  'Customer.viewWorkOrderNotes.heading': 'Work Order Notes',
  'Resource.fetchQueueSize': 'Fetch Queue Size',
  'activeUsers.message':
    'The following is a list of users that have logged in and their sessions have not expired.',
  'userGroup.shortcuts.title':
    "Comma-separated list of URL's for the default Quick Launch shortcuts to be displayed above the menus, e.g. 'mainMenu.html,resourceworkordermap.html'",
  'mobile.130': '1hr 30min',
  'menu.deviceTypes': 'Device Types',
  'ScheduledWorkTemplate.inspectionFeedbackTriggerExprHint':
    "The template will be triggered if the condition is on the inspection list is true either in this field or in another field in the list. Text must be formatted as follows: ${value}=='<a value to check for in this field>' && ${<field id value to look up>}=='<value to check for>' && ${<another field id value to look up>}=='<value to check for>' || (<more criteria>), etc",
  'Asset.newStatus': 'New Status',
  'Material.totalStock': 'Total Stock on Hand',
  'subscriberautonumberingsettings.title': 'Auto Numbering',
  'Resource.workOrderEmailNotification':
    'Work Order {0} has been allocated to you',
  'errors.long': '{0} must be a long.',
  'Customer.createAccount': 'Create Account?',
  'AbnormalPlant.id': 'ID',
  'AbnormalPlant.returnDate': 'Return Date',
  '*.country.name': 'Country',
  'Store.description': 'Store Name',
  'AuditTrail.newValue': 'New Value',
  'js.del': 'Delete',
  'js.projectAddSubTask': 'Add sub-task',
  'quotation.updated': 'Quotation {0} has been updated successfully.',
  'Customer.customDate10': '',
  'js.reloadFromWorkOrder': 'Re-load from Work Order',
  'js.setTargetPriceIncl': 'Set Target Price (Incl VAT)',
  'subscriberCustomLabels.deleted':
    'Custom Label has been deleted successfully.',
  'js.cutDevice': 'Move (Cut) Device',
  'crew.members': 'Members',
  'smartCityPortal.title': 'Smart City',
  'workOrderMobile.title': 'Workorder',
  'errors.login.SupportPersonLoginException':
    'Invalid username/password/subscriberID combination, please try again.',
  'button.save': 'Save',
  'StatusReference.workflow.description': 'Workflow',
  'js.ifAddGroupRuleHint':
    'Adds a sub-group to the inspection list if the field result satisfies the criteria. Text must be formatted as follows: <condition to look for>:<inspection group id to add>,<alternative field value to look for>:<inspection group id to add>,etc',
  'SmsUsage.monthYear': 'Year/Month',
  'PurchaseOrder.deliveredTotal': '',
  'user.heading': 'Edit User Account',
  'FaultReport.updated': 'Outage {0} Updated',
  'CustomerPortal.login.usernameNotRegistered':
    'This username is not registered',
  'globalSettings.settingValue': 'Value',
  'js.editBomItem': 'Edit Item',
  'InspectionListGroup.title': 'Standard Inspection List',
  'ScheduledWorkTemplate.customLookup9': '',
  'ScheduledWorkTemplate.customLookup5': '',
  'ScheduledWorkTemplate.customLookup6': '',
  'ScheduledWorkTemplate.customLookup7': '',
  'PurchaseRequisition.contactNumber': 'Contact Number',
  'ScheduledWorkTemplate.customLookup8': '',
  'ScheduledWorkTemplate.customLookup1': '',
  'ScheduledWorkTemplate.customLookup2': '',
  'ScheduledWorkTemplate.customLookup3': '',
  'MobileCustomer.listText': '${description}',
  'ScheduledWorkTemplate.customLookup4': '',
  'address.country': 'Country',
  'passwordReset.confirmNewPassword': 'Confirm New Password',
  'AbnormalPlant.faultReport.rootCauseEquipment.description':
    'Damaged Equipment',
  'SuperadminUser.password': 'Password',
  'mobile.editCustomer': 'Edit Customer Details',
  'WorkCalendarMatrixView.planned': 'Planned?',
  'Device.weight': 'Weight',
  'menu.viewRuleParameters': 'Rule Parameters',
  'customerMobile.workRequestDetailDescription': 'Description',
  'SMSCampaign.smsCategory.description': 'SMS Category',
  'uploadForm.documentType': 'Document Type',
  'PurchaseRequisition.custom1': '',
  'js.createAssetGeometry': 'Create Asset Geometry',
  'referenceList.fieldName.propertyName': 'Field Name',
  'PurchaseRequisition.custom2': '',
  'mobile.100': '1hr ',
  'PurchaseRequisition.custom3': '',
  'Call.workType': 'Call Type',
  'BillOfMaterialsItem.unitType.description': 'Unit Type',
  'documentsFilter.hasWorkOrder': 'Has Work Order',
  'js.exportCimXML': 'Export CIM XML',
  'ForcelinkSubcontractor.replicateAssetTypesToSub':
    'Asset Type Codes to replicate to sub?',
  'PurchaseRequisition.custom4': '',
  'PurchaseRequisition.custom5': '',
  'PurchaseRequisition.custom6': '',
  'PurchaseRequisition.custom7': '',
  'js.confirmRemoveJob': 'Are you sure you want to remove the scheduled job?',
  'PurchaseRequisition.custom8': '',
  'PurchaseRequisition.custom9': '',
  'TableValidationRule.postLoadJavascript': 'Post Load Javascript',
  'InboxEventUser.pk.event.code': 'Code',
  'newuser.email.message':
    'Dear {0},\n\nThe administrator has created a new Forcelink account for you.  Your login information is:\n\nusername:{1}\npassword hint:{2}\nsubscriberID:{3}\n\nTo log in, go to {4}\n\nRegards,\nForcelink Admin',
  'Asset.deleted': 'Asset {0} Deleted',
  'js.deleteNote': 'Delete Note',
  'PurchaseOrder.customNumber5': '',
  'Quotation.customer.code': 'Customer Code',
  'PurchaseOrder.customNumber4': '',
  'PurchaseOrder.customNumber3': '',
  'PurchaseOrder.customNumber2': '',
  'PurchaseOrder.customNumber1': '',
  '*.ImageCount': 'ImageCount',
  'js.viewLinkedWorkOrder': 'View Linked Work Order',
  'Quotation.workLog': 'Work Log',
  'js.noteType': 'Note Type',
  'CustomManagerImpl_cp_mdt.twitterLoadSheddingUpdated':
    'Load Shedding at ${station.description} updated at {timeStamp}. {shortURL}',
  'passwordReset.otpSentTo':
    'A password reset OTP (One-time-PIN) has been sent to {0}. In order to reset your password, enter the OTP into the field below, followed by your new password.',
  'icon.email': 'E-Mail',
  'StockTake.error.serializedAssetTypeAlreadyCountedByQuantity':
    "Material '{0}' has already been counted by quantity, and should not be scanned by individual barcode.",
  'errors.tableValidationExpression': "Validation expression '{0}' is invalid",
  'WorkOrder.project.code': 'Project Code',
  'WorkOrderRequest.createdQuotation': 'Generated Quotation',
  'Store.customDate3': '',
  'Store.customDate2': '',
  'Store.customDate1': '',
  'Store.customDate9': '',
  'Store.customDate8': '',
  'js.resourceNotVisible':
    'Resource is currently Unavailable and will not be visible on the map. Continue anyway?',
  'Store.customDate7': '',
  'Store.customDate6': '',
  'Store.customDate5': '',
  'js.pleaseSelectATemplate': 'Please select a template',
  'Store.customDate4': '',
  'Workflow.active': 'Active? ',
  '*.customer.custom1': '',
  '*.customer.custom2': '',
  'Note.noteType.description': 'Note Type',
  '*.customer.custom3': '',
  '*.customer.custom4': '',
  '*.customer.custom5': '',
  '*.customer.custom6': '',
  '*.customer.custom7': '',
  'button.sendPlannedOutageCustomerNotifications': 'PO Notifications',
  '*.customer.custom8': '',
  'WorkOrderResources.assignmentSynchronisedMessage':
    'Work Order {0} has been synchronised to the server.',
  '*.customer.custom9': '',
  'js.chooseDisplayType': 'Choose Display Type',
  'editStatusListForm.active': 'Active? ',
  'assetStatus.systemStatus': 'System Status',
  'uploadForm.endDate': 'Document valid until',
  'js.clearSelections': 'Clear Selections?',
  'workOrder.customerAndAddress': 'Customer and Address',
  'WorkOrderRequest.workRequestStatus.open': 'Open?',
  'Asset.locationAddress.requiresGeocoding': '',
  'jmesa.sortingSkipped':
    'Sorting disabled for performance reasons (more than {0} records)',
  'taxType.heading': 'Tax Type',
  'AssetType.MSI': 'Maintenance Significant Item?',
  'FailteIrelandFeeSchedule.category.description': 'Category',
  'workorderautoassignmentrules.title':
    'Work Order Automatic Resource Assignment Rules',
  '*.contactPerson.emailAddress': 'Email Address',
  'date.format': 'dd-MM-yyyy',
  'SwitchingEvent.permitNo': 'Permit No',
  'mobile.addMaterialTitle': 'Add Material',
  'button.saveAndReload': 'Save And Reload',
  'SubscriberDatabase.accountStatus': 'Account Status',
  'WorkRequestAutoAllocationRule.activeToHHMM': 'Active To (HH:MM)',
  '*.exportView': 'Export View',
  'menu.failteIrelandFeeSchedule': 'Fee Schedule',
  '*.material.suppliers': 'Suppliers',
  'Lease.contactNumber': 'Contact Number',
  'menu.viewAvailabilityCalendar': 'Planned Shifts',
  'subscriberDatabase.schemaName': 'Schema Name',
  'js.addDevices': 'Add Device(s)',
  'WorkOrder.dispatchedBy.fullName': 'Dispatched By',
  'subscriberCustomLabels.title': 'Custom Labels List',
  'support.onStandby': 'On Standby',
  'Quotation.billOfMaterialsGroup.totalPriceIncl': 'Price Incl',
  'multiChoice.hardwareRequired':
    'Please select hardware that should be supplied by the installer. If you already have any of these, please do not select them below.',
  'Lease.responsibleUser.fullName': 'Responsible Person',
  'ArcGISLayer.strokeWidth': 'Stroke Width',
  'Contract.assets': 'Linked Assets',
  'notificationRule.updated': 'Notification Rule Added',
  'subscriberCustomLabels.id': 'Id',
  'ResourceChat.timeStamp': 'Date/Time',
  'js.filterForSelected': 'Filter for Selected',
  'Quotation.customer.description': 'Customer',
  'trainingevents.title': 'Training Events',
  'js.switchingEventMenu.linkAbnormalPlantToSelected':
    'Link Abnormal Plant to Selected',
  'resourceType.updated': 'Resource Sub Type Updated',
  'WorkOrderRequest.contactEmailCC': 'CC e-mail',
  'resource.manager': 'Manager',
  'FailteIrelandFeeSchedule.minimumFee': 'Minimum Fee',
  'WorkOrder.resourceChats': 'Resource Chats',
  'resource.photoTransmitMode': 'Photo Upload Mode',
  'js.selectOneBOMItem':
    'You must select at least one Bill Of Materials item to create Purchase Orders',
  'StockTake.error.materialNotSelected':
    'Material {0} does not form part of this stock take',
  'FlashReport.faultReport.endDate': 'End Date',
  'js.pleaseSelectAStartingDate': 'Please select a starting date',
  'js.editUser': 'Edit User',
  'resource.pauseRefreshMode.hint':
    'Pauses refreshes on input screens on the java mobile app - gives better user performance but may delay updates',
  'menu.viewMaterialStores': 'Material Stock',
  'material.quotable': 'Quotable?',
  'slaaudit.heading': 'SLA Event History',
  'WorkOrderRequest.workRequestStatus.systemStatus.open': 'Open?',
  'inbox.workRequests': 'Work Requests',
  'invoice.workOrder': 'Work Order',
  'AbnormalPlant.workOrder.code': 'Work Order',
  'StockTake.error.incorrectQuantity.found': 'Incorrect Quantity (Found {0})',
  'mobile.addStandardChargeTitle': '',
  'Store.usersCanTransferIn': 'Users who can transfer in',
  'damageddevices.heading': 'Damaged Devices',
  'ActiveSessionInfo.mobile': 'Mobile?',
  'js.purchaseOrdersCreated': '{0} Purchase Orders created',
  'login.passwordHint':
    '<a style="color:white;" href="?" title="Reset your password." onclick="Login.passwordReset(); return false">Reset password</a> or <a style="color:white;" href="?" title="Have your password hint sent to you." onclick="Login.passwordHint(); return false">e-mail a hint.</a>',
  'FaultReport.deleted': 'Outage {0} Deleted',
  'menu.viewAssetTemplates': 'Asset Templates',
  'customerportal.workOrderRequest.inspectionList': 'Inspection List',
  'js.addBomItems': 'Add multiple Equipment Items',
  'locationAddress.latLong': 'Lat/Long',
  'AssetCategory.equipment': 'Equipment',
  'PurchaseInvoice.invoiceDate': 'Invoice Date',
  'dashboardEntry.label': 'KPI Label',
  'WorkOrder.allocate.heading': 'Allocate Technicians',
  'mobile.pleaseEnterSearchString': 'Please Enter the Search String',
  'WorkOrder.Asset': 'Assets',
  'js.newAddress': 'New address',
  'resources.heading': 'Technicians',
  'js.contractscheduledworktemplatesMoreOptionsMenu.removeSelectedScheduledWorkTemplates':
    'Unlink selected SW Templates',
  'purchaseOrder.newStatus': 'New Status',
  'BillOfMaterialsItem.costCentre': 'Cost Centre',
  'MaterialStore.materialmovements': 'Material Movements',
  'Asset.warranty.description': 'Warranty',
  'passwordReset.passwordBlank':
    "Please enter a new password into the 'Password' and 'Confirm Password' fields.",
  'PlannedOutage.startDate': 'Actual Start',
  'menu.superAdmin': 'Super admin',
  'AssetCategory.code': 'Asset Category Code',
  'js.createPlannedOutage': 'Create Planned Outage',
  'fieldAccess.view': 'View\t',
  'SLA.code': 'Code',
  'SupplierQuotation.totalExclVAT': 'Total Excl. VAT',
  'js.lastScannedBarcode': 'Last Scanned Barcode',
  'myworkorders.heading': 'My Work',
  'editStatusListForm.heading': 'Edit Status',
  'PurchaseRequisition.workRequestType': 'Work Type',
  'Lease.id': 'Reference',
  'Store.customLookup5': '',
  'Store.customLookup4': '',
  'Store.customLookup7': '',
  'Store.customLookup6': '',
  'Store.customLookup1': '',
  'Store.customLookup3': '',
  'Store.customLookup2': '',
  'faultreports.title': 'Outages',
  'subscriberCustomFieldLabels.added':
    'Custom Field has been added successfully.',
  'js.removeDeviceFromSchematic': 'Remove Device',
  'smartCity.passwordReset.sms.message':
    'Dear {0}, please follow this link to reset your password: {1}',
  'WorkOrderRequest.resolutionCode': '',
  'BillOfMaterialsItem.subHeading.title':
    'Optionally specify a sub-heading for grouping on printable reports. If blank, it will default to the highest-level material type',
  'js.viewCustomerOnWorkMap': 'View Customer Address on Work Map',
  'AbnormalPlant.status.systemStatus.open': 'Open?',
  'AssetCategory.building': 'Building',
  'mobile.customer.description': 'Customer Description',
  '*.totalPriceExcl': 'Total Excluding VAT',
  'js.add': 'Add',
  'Assettemplate.custom15': '',
  'Assettemplate.custom14': '',
  'ForcelinkPricing.rangeEnd': 'Range End',
  'fieldAccess.group': 'Group\t',
  'Store.customLookup9': '',
  'Assettemplate.custom11': '',
  'Store.customLookup8': '',
  'Assettemplate.custom10': '',
  'Assettemplate.custom13': '',
  'Assettemplate.custom12': '',
  'mobile.addStandardCharge': '',
  'referencelistfields.heading': 'Edit Reference Lists',
  'StockTake.notes': 'Notes',
  'AbnormalPlant.dateOutOfService': 'Date Out Of Service',
  'PublicHoliday.name': 'Holiday Name',
  'menu.logout': 'Sign Out',
  'Asset.associatedMaterials': 'Associated Materials/Parts',
  'quotation.title': 'Quotation Detail',
  'user.availableRoles': 'Available Roles',
  'errors.login.OldPasswordInvalidException': 'The old password is invalid',
  'material.added': 'Material has been added successfully.',
  'AssetType.priority.code': 'Priority Code',
  'js.replaceExisting': 'Replace Existing?',
  'QuickLaunch.reportlist': 'Reports',
  'FaultReport.rootCauses': 'Root Cause (old)',
  'mobile.workOrderRequests': 'Work Requests',
  'WorkCalendarMatrixView.averageProperty': 'Average Property',
  'WorkOrder.custom.address.description': '',
  'Contract.extendable': 'Extendable?',
  'PlannedOutages.heading': 'Planned Outages',
  'Resource.custom10': '',
  'CustomerPortal.homePage.logFaultMyAddress': 'Log a Fault (My Address)',
  'leases.title': 'Leases',
  'mobile.confirmDelete':
    'Are you sure that you want to delete the selected item?',
  'SwitchingEvent.normalised': 'Normalised?',
  'mobile.supplyOtp': 'OTP:',
  'DeviceType.parentDeviceTypes': 'Allowed Parent Device Types',
  'StockTake.error.incorrectStore': 'Found in other store ({0})',
  'js.createSMSCampaignForSelectedResources':
    'Create SMS Campaign for selected resources',
  'js.setParentCustomer': 'Set Parent Customer',
  'ForcelinkBillingDetail.billingType': 'Billing Type',
  'Note.createdDate': 'Created',
  'QueryMapLayer.id': 'ID',
  'map.searchForAddress': 'Search for Address:',
  'FaultReport.MVAHours': 'MVA Hours',
  'material.materialType': 'Type',
  'js.matrixHeader': 'Matrix',
  'js.saveBeforeAddingNotes': 'Please save before adding notes',
  'QueryMapLineLayer.minZoom': 'Minimum Zoom',
  'StandardInspectionList.title': 'Standard Inspection List',
  'menu.sendSupportRequest': 'Send Support Request',
  'BillOfMaterialsItem.unexpectedItemCountMessage':
    'Expected {0}, selected {1}',
  'crew.crewType.description': 'Crew Type',
  'menu.viewCustomEntities': 'Custom Entities',
  'Customer.preferences': 'Preferences',
  'js.selectChangeAddress': 'Select/Change Address',
  'PurchaseRequisition.title': 'Purchase Requisition',
  'WorkDoc.description': 'Description',
  'supplier.heading': 'Supplier',
  'mobile.createCustomer': 'Create Customer',
  'smscampaigns.heading': 'SMS Campaigns',
  'dashboardEntry.url': 'URL',
  'billOfMaterials.totalExclVat': 'Total Excl',
  'Trainee.fullName': 'Full Name',
  'Resource.customLookup2': '',
  'Resource.customLookup1': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.country': '',
  'Resource.customLookup4': '',
  'Resource.customLookup3': '',
  'Resource.customLookup5': '',
  'PlannedOutage.permitNo': 'Permit Number',
  'material.customNumber9': '',
  'QuickLaunch.standardinspectionlists': 'Standard Inspection Lists',
  'material.customNumber6': '',
  'material.customNumber5': '',
  'material.customNumber8': '',
  'Contract.customer.description': 'Customer',
  'material.customNumber7': '',
  'material.customNumber2': '',
  'material.customNumber1': '',
  'material.customNumber4': '',
  'Quotation.contactEmail': 'Contact Email',
  'material.customNumber3': '',
  'WorkOrderRequest.custom27': '',
  'WorkOrderRequest.custom28': '',
  'PlannedOutage.status.open': 'Open?',
  'WorkOrderRequest.custom25': '',
  'WorkOrderRequest.custom26': '',
  'CustomerInteraction.user': 'User',
  'WorkOrderRequest.custom29': '',
  'Customer.user.emailAlertsEnabled': 'Enable Email alerts',
  'FaultReport.durationHHMM': 'Duration (HH:MM)',
  'js.makeFirm': 'Make Firm',
  'faultReport.workLog': 'Work Log',
  'TableValidationRule.errorMessage': 'Error Message',
  'mobile.customer.mobileNumber': 'Mobile Number',
  'js.selectStandardBillOfMaterials': 'Select Standard Bill Of Materials',
  'WorkOrderRequest.custom20': '',
  'WorkOrderRequest.custom23': '',
  'WorkOrderRequest.custom24': '',
  'QueryMapLayer.iconPath': 'Icon Path JavaScript expression',
  'WorkOrderRequest.custom21': '',
  'WorkOrderRequest.custom22': '',
  'WorkOrderRequest.custom16': '',
  'js.switchingEventMenu.updateReportDateOnSelected':
    'Update Report Date on Selected',
  'WorkOrderRequest.custom2': 'Additional Sign off Authority Name',
  'WorkOrderRequest.custom17': '',
  'Customer.billingAddress.requiresGeocoding': '',
  'WorkOrderRequest.custom1': 'Purchase Order Number',
  'WorkOrderRequest.custom14': '',
  'WorkOrderRequest.custom4': 'Quote Number',
  'WorkOrderRequest.custom15': '',
  'SubscriberDatabase.allowedTestMobileNos': 'Allowed Test Mobile Numbers',
  'menu.workRequestAutoAllocationRules': 'Work Request Auto Allocation Rules',
  'WorkOrderRequest.custom3': 'Additional Sign off Authority Number',
  'switchingevents.instructionsView': 'Instructions View',
  'WorkOrderRequest.custom18': '',
  'WorkOrderRequest.custom19': '',
  'assetStatus.systemStatus.description': 'System Status',
  'Contract.customNumber10': '',
  'WorkOrderRequest.custom9': '',
  'WorkOrderRequest.custom6': '',
  'WorkOrderRequest.custom5': 'Client Internal reference',
  'WorkOrderRequest.custom8': 'Call Logger Details',
  'WorkOrderRequest.custom7': '',
  'FaultReport.station': 'Substation',
  'user.accountactivation.emailsubject':
    'Forcelink Account Created, please activate',
  'js.updateSelectedSwitchingEvents': 'Set Reported Date on selected',
  'WorkOrderRequest.custom12': '',
  'WorkOrderRequest.custom13': '',
  'WorkOrderRequest.custom10': '',
  'CustomReferenceList.sortOrder': 'Sort Order',
  'WorkOrderRequest.custom11': '',
  'Resource.workOrders': 'Work Orders',
  'Document.customLookup1.description': '',
  'CustomManagerImpl_cp_mdt.twitterFaultReportRestored':
    'Outage at ${station.description} restored at {timeStamp}. {shortURL}',
  'smartCity.activation.email.subject': 'Activate your Smart City account',
  'js.createSubstationInternalDiagram': 'Create Substation Line Diagram',
  'ResourceType.overtimeRate': 'Overtime Cost',
  'UserGroup.shortcutIconPath': 'Icon Path',
  'billOfMaterials.pk.tableName': 'Table Name',
  'SLA.customMessageSubject':
    'An EL Expression for Custom Message Subject Notifications, Blank uses Default Message Subject.',
  'button.viewOnMap': 'View On Map',
  'AssetCategory.depreciationType': 'Depreciation Type',
  'warranties.title': 'Warranties',
  'assetStatus.created': "Asset Status '{0}' created",
  'js.createWorkOrderForPlantToIsoateFrom': 'Create Work Order',
  'js.createAbnormalPlant': 'Create Abnormal Plant record',
  'user.orgUnit': 'Organisational Unit',
  'Asset.childAssets': 'Child Assets',
  '*.customer.code': 'Customer Code',
  'billOfMaterials.itemDiscount': 'Item Discount',
  'taxTypes.heading': 'Tax Types',
  'DeviceType.code': 'Device Type Code',
  'js.setCustomer': 'Set Customer',
  'material.serialised': 'Serialised?',
  'AssetAttribute.attributeName': 'Attribute Name',
  'WorkOrderRequest.custom30': '',
  'material.code': 'Code',
  'Contract.status': 'Status',
  'errors.password.expired':
    'This password has expired. Please provide a new password.',
  'referenceList.selectField': 'Select field and table to edit',
  'billOfMaterials.materialSubType': 'Material Sub Type',
  'DashboardEntry.type': 'Type',
  'CustomManagerImpl_cp_mdt.twitterFaultReportUpdated':
    'Outage at ${station.description} updated at {timeStamp}. {shortURL}',
  'workcalendarmatrixviews.title': 'Work Calendar Matrix Views',
  'billOfMaterialsList.title': 'Bill Of Materials List',
  'js.warrantyType': 'Warranty Type',
  'js.setResourceOffDuty': 'Set Resource Off Duty',
  'userProfile.assignExplicitRoles': 'Assign Explicit (Additional) Roles',
  'billOfMaterials.materialType': 'Material Type',
  'Resource.employer.description': 'Employer',
  '*.billOfMaterialsGroup.failteIrelandOriginalAmountOwing':
    'Original Amount Owing',
  'BillOfMaterialsGroup.customTransient14': '',
  'BillOfMaterialsGroup.customTransient13': '',
  'js.deleteFile': 'Delete File',
  'BillOfMaterialsGroup.customTransient12': '',
  'BillOfMaterialsGroup.customTransient11': '',
  'BillOfMaterialsGroup.customTransient10': '',
  'js.bulkStatusChange': 'Bulk Status Change',
  description: 'Description',
  'login.heading': 'Sign In to Forcelink',
  'suspectoutages.title': 'Suspect Outages',
  'WorkOrder.custom20': '',
  'Invoice.custom1': '',
  'display.title': 'File Uploaded Successfully!',
  'Device.locationAddress.inheritedFromParent': 'Address inherited from parent',
  'Device.parentDevice.description': 'Parent Device',
  'mobile.searchMode.byDescription': 'Search By Description',
  'WorkOrderAsset.pk.workOrder.orgUnit.description': 'Org Unit',
  '*.changeDate': 'Change Date',
  'resource.groupingFilter.resourcesOnly': 'Resources Only',
  'Asset.manufacturer.description': 'Manufacturer',
  'Lease.startDate': 'Start Date',
  'Contract.custom6': '',
  'WorkOrder.custom10': '',
  'Contract.custom5': '',
  'WorkOrder.custom11': '',
  'Contract.custom8': '',
  'WorkOrder.custom12': '',
  'Contract.custom7': '',
  'WorkOrder.custom13': '',
  'WorkOrder.custom14': '',
  'Contract.custom9': '',
  'WorkOrder.custom15': '',
  'WorkOrder.custom16': '',
  'WorkOrder.custom17': '',
  'WorkOrder.custom18': '',
  'WorkOrder.custom19': '',
  'Contract.custom2': '',
  'Contract.custom1': '',
  'Contract.custom4': '',
  'Contract.custom3': '',
  'quotation.added': 'Quotation {0} has been added successfully.',
  'js.cronHint': 'second minute hour DayOfMonth Month DayOfWeek(?)',
  'SchematicDiagram.substation.description': 'Substation',
  'errors.range': '{0} is not in the range {1} through {2}.',
  'WorkOrderRequest.changeHistory': 'Change History',
  'WorkOrderRequest.contactEmail': 'Technician Responsible e-mail',
  'querymaplayers.title': 'Query Layers',
  'js.changeBomItemMaterial': 'Change Material',
  'ForcelinkBillingDetail.monthYear': 'Month/Year',
  'BillOfMaterialsItem.materialType': 'Material Type',
  'FailteIrelandFeeSchedule.id': 'ID',
  'mainMenu.filtersSavedSuccessfully': 'User Filters Saved Successfully!',
  'Device.toVoltage': 'Secondary Voltage',
  'Quotation.noteAddedEmailSubject': 'Note added to Quotation {0}',
  'Customer.province': 'Province',
  'Invoice.custom4': '',
  'passwordHint.sms.message': 'Dear {0}, your password reminder is: {1}',
  'Invoice.custom5': '',
  'Invoice.custom2': '',
  'Invoice.custom3': '',
  sunday: 'Sunday ',
  'js.customLinkedObjectText': 'Custom Linked Object Text',
  'InspectionListItem.noteType': 'Note Type',
  'customReferenceList.title': 'Edit Custom Reference Field Values',
  'BillOfMaterialsGroup.customTransient15': '',
  'Resource.visibleStores': 'Visible Stores',
  'js.done': 'Done',
  'user.loggedIn': 'Logged in?',
  'PurchaseOrder.heading': 'Purchase Order',
  'FaultReport.user.fullName': 'Created By',
  'Lease.custom5': '',
  'Warranty.vendor.description': 'Vendor',
  'inspectionitems.heading': 'Inspection List',
  '*.selectOne': 'Select',
  'resource.id': 'Id',
  'js.createWorkOrderForEachSelectedCustomer':
    'Create a Work Order for each selected Customer',
  'Asset.warranty.vendor.description': 'Warranty Supported By',
  'SLAAuditTrail.elapsedMins': 'Elapsed Minutes',
  'notificationRule.timerExpr': 'Timer Cron Expression',
  'Lease.custom3': '',
  'Lease.custom4': '',
  'Lease.custom1': '',
  'Lease.custom2': '',
  'User.customDate1': '',
  'js.addStandardInspectionList': 'Add Standard Inspection List',
  'Workflow.error.requiresResourceAllocated':
    "Resources must be allocated for the work order to reach status '{0}'",
  'AssetType.inheritOrgUnitFromParent': 'Inherit Org Unit from Parent',
  'js.linkSelectedSchematicDiagramToActivate':
    'Link selected schematic diagram to activate',
  'js.geometryFillOpacity': '0.05',
  'menu.privacyStatement': 'Privacy Statement',
  'FaultReport.status': 'Status',
  'smartCity.passwordReset.email.message':
    'Dear {0},\n\nYour password has been reset. Please follow this link to create your new password:\n {1}\n\nRegards,\nSmartCity Admin',
  'mobile.searchMode': 'Search mode',
  'RelayFailure.model': 'Model',
  'Device.validParent': 'Valid Parent Type?',
  'User.customDate2': '',
  'User.customDate3': '',
  'User.customDate4': '',
  'User.customDate5': '',
  'TableValidationRule.title': 'Table Validation Rule',
  'customerportal.mainMenu.viewWorkRequests': 'View tickets',
  'js.selectionsBoxTitle': 'Selections',
  'js.createAsset': 'Create Asset',
  'Supplier.customLookup10': '',
  'BillOfMaterialsItem.itemDiscount': 'Discount',
  'StockTake.createdBy': 'Created By',
  'testREST.option.assetTrigger': 'Asset Trigger (/rest/assettrigger)',
  'InspectionListWizard.button.downloadtemplate': 'Download XLSX Template',
  'availabilityCalendar.shiftType': 'Shift Type',
  'PurchaseRequisition.customNumber16': '',
  'locationAddress.country.code': 'Country Code',
  'PurchaseRequisition.customNumber17': '',
  'js.workOrder': 'Work Order',
  'PurchaseRequisition.customNumber14': '',
  'js.tasksHours': '{0} tasks, {1} hours',
  'PurchaseRequisition.customNumber15': '',
  'FaultReport.customLookup10': '',
  'user.passwordupdated.emailmessage':
    'Dear {0},\n\nThe administrator has updated your Forcelink password.  Your login information is:\n\nusername:{1}\npassword:{2}\nsubscriberID:{3}\n\nTo log in, go to {4}\n\nRegards,\nForcelink Admin',
  'PurchaseRequisition.customNumber18': '',
  'PurchaseRequisition.customNumber19': '',
  'material.title': 'Material Detail',
  'StandardInspectionList.created': 'Standard Inspection List Created',
  'PurchaseRequisition.customNumber12': '',
  'PurchaseRequisition.customNumber13': '',
  'button.outsource': 'Outsource',
  'PurchaseRequisition.customNumber10': '',
  'PurchaseRequisition.customNumber11': '',
  'customerMobile.workRequestNumber': 'Work Request Number',
  'button.addStationary': 'Add Stationery',
  'mobile.200': '2hr',
  'menu.smsLog': 'Messaging Log',
  'js.createPolygonAddress': 'Create Polygon',
  'roleForm.name': 'Name',
  'VersionInfo.date': 'Date',
  'BillOfMaterialsGroup.customTransient3': '',
  'QueryMapLayer.updated': 'Query Layer Updated',
  'BillOfMaterialsGroup.customTransient2': '',
  'BillOfMaterialsGroup.customTransient1': '',
  '*.contactPerson.homeNumber': 'Home Number',
  'StockTakeExpectedItemDWR.code': 'Barcode',
  'BillOfMaterialsGroup.customTransient7': '',
  'BillOfMaterialsGroup.customTransient6': '',
  'PurchaseRequisition.customNumber20': '',
  'BillOfMaterialsGroup.customTransient5': '',
  'BillOfMaterialsGroup.customTransient4': '',
  'BillOfMaterialsGroup.customTransient9': '',
  'BillOfMaterialsGroup.customTransient8': '',
  'menu.viewShiftTypes': 'Shift Types',
  'Contract.orgUnit': 'Org Unit',
  'dashboardEntry.deleted': 'Dashboard Entry {0} deleted',
  'SwitchingEvent.hasActualSwitchingEvent': 'Has Actual?',
  'js.assignScheduledWorkTemplate':
    'Assign Scheduled Work Template to Selection',
  'editInspectionListItems.customFieldType': 'Custom Field Type',
  'AbnormalPlant.custom10': '',
  'js.enterDate': 'Specify Date',
  'CustomerPortal.logFaultOtherAddress.title': 'Log a fault at Another Address',
  'js.showConnection': 'Show Connection',
  '*.changedBy': 'Changed By',
  'js.moveRight': 'Move Right',
  'smsLog.campaignview': 'Campaign View',
  'WorkOrderRequest.contactName': 'Technician Responsible Name',
  'SMSCampaign.messageText': 'Message Text',
  'globalSettings.title': 'Global Settings List',
  'FaultReport.smsGroups': 'Outage SMS Notification Groups',
  'PlannedOutage.restorationStages': 'Actual Interruption Phases',
  'AssetCategory.depreciationRate': 'Depreciation Rate (%)',
  'AssetCategory.store': 'Equipment Store',
  'Quotation.assetList': 'Assets',
  'PurchaseRequisition.customDate5': '',
  'PurchaseRequisition.customDate4': '',
  'PurchaseRequisition.customDate3': '',
  'PurchaseRequisition.customDate2': '',
  'PurchaseRequisition.customDate9': '',
  'PurchaseRequisition.customDate8': '',
  'PurchaseRequisition.customDate7': '',
  'PurchaseRequisition.customDate6': '',
  'errors.existing.user':
    'This username ({0}) or e-mail address ({1}) already exists.  Please try a different username.',
  'FlashReport.faultReport.voltage.code': 'Voltage',
  'js.codeEntryMode': 'Code entry mode',
  'ArcGISLayer.url': 'URL',
  'StockTake.customLookup4': '',
  'StockTake.customLookup5': '',
  'js.createWorkRequestForAsset': '',
  'assets.heading': 'Assets',
  'js.deleteSelected': 'Delete Selected',
  'StockTake.customLookup1': '',
  'StockTake.customLookup2': '',
  'StockTake.customLookup3': '',
  'editStatusListForm.description': 'Status Description',
  'js.changeOrgUnitForSelected': 'Change Org Unit for Selected',
  'InboxEventUser.pk.event.timeStamp': 'Time stamp',
  'signup.email.subject': 'AppFuse Account Information',
  '*.OMSView': 'OMS View',
  'matrix.docType': 'Document Type',
  'mobile.enterDescription': 'Enter description',
  'Device.substation': 'Substation',
  'material.customLookup10': '',
  'SchematicDiagram.type': 'Type',
  'Supplier.customNumber10': '',
  'PurchaseRequisition.customDate1': '',
  'User.fullName': 'Full Name',
  'Asset.assetStatus.description': 'Asset Status',
  'Quotation.customMultipleChoice1': '',
  'Quotation.customMultipleChoice2': '',
  'Quotation.customMultipleChoice3': '',
  'Resource.workTypes': 'Work Types',
  'InspectionListWizard.create': 'Create',
  'SwitchingInstruction.operator2': 'Operator 2',
  'Quotation.customMultipleChoice4': '',
  'Quotation.customMultipleChoice5': '',
  'passwordHint.email.subject': 'Forcelink password reminder',
  'WorkOrderRequest.customer.parentCustomer.mobileNumber': '',
  'customer.heading': 'Customer Information',
  'PurchaseRequisition.custom10': '',
  'errors.password.confirmPasswordDoesNotMatch':
    'The confirmation password does not match.',
  'js.includeBlanks': 'Include Blanks?',
  'PurchaseRequisition.custom11': '',
  'PurchaseRequisition.custom12': '',
  'PurchaseRequisition.custom13': '',
  'PurchaseRequisition.custom14': '',
  'PurchaseRequisition.custom15': '',
  'PurchaseRequisition.custom16': '',
  'PurchaseRequisition.custom17': '',
  'PurchaseRequisition.custom18': '',
  'PurchaseRequisition.custom19': '',
  'js.zoomFull': 'Zoom Full',
  'mobile.supplyEtaEtcTitle': 'Supply Time Estimate',
  'WorkOrder.allocate.title': 'Work Order Allocation',
  'ForcelinkPricing.subscriber': 'Subscriber Id',
  'SubscriberDatabase.pricingUseSubscriberId': 'Use Pricing From Subscriber',
  'js.createSuspectOutageForSelectedCalls':
    'Create Suspect Outage for selected calls',
  'orgStructureDetail.title': 'Org Structure Detail',
  'stores.heading': 'Stores',
  'js.renameItem': 'Rename Item',
  'PurchaseRequisition.custom20': '',
  'WorkOrderRequest.statusUpdateNotifyMonitorsEmailSubject':
    'Status update on Work Request [{0}]:${status.description}',
  'PurchaseRequisition.custom21': '',
  'call.customer.custom5': '',
  'PurchaseRequisition.custom22': '',
  'call.customer.custom4': '',
  'PurchaseRequisition.custom23': '',
  'WorkOrder.customDate10': '',
  'call.customer.custom3': '',
  'PurchaseRequisition.custom24': '',
  'call.customer.custom2': '',
  'PurchaseRequisition.custom25': '',
  'call.customer.custom1': '',
  'PurchaseRequisition.custom26': '',
  'PurchaseRequisition.custom27': '',
  'PurchaseRequisition.custom28': '',
  'PurchaseRequisition.custom29': '',
  'SubscriberDatabase.billingMaster.subscriberId': 'Billing Master Schema',
  'js.changeAssetStatus': 'Change Asset Status',
  'WorkOrder.customDate13': '',
  'WorkOrder.customDate14': '',
  'WorkOrder.customDate11': '',
  'WorkOrder.customDate12': '',
  'WorkOrder.customDate15': '',
  'mobile.listActiveWorkOrders': 'List Active Work Orders',
  'PurchaseRequisition.custom30': '',
  'smscampaigncategories.heading': 'SMS Campaign Categories',
  'documentsFilter.noWorkOrder': 'No Work Order',
  'Resource.skills': 'Skills',
  'WorkOrderRequest.customer.parentCustomer.faxNumber': '',
  Material: 'Material',
  'selectdevice.title': 'Select Device(s)',
  'passwordHint.userNotFound':
    "Sorry, this user name is not registered. Please click 'Register' to create a profile.",
  'Workflow.settings': 'Settings',
  'FailteIrelandFeeSchedule.feeType.description': 'Fee Type',
  'subscriberDatabase.title': 'Subscriber Database',
  'user.saved': 'Your profile has been updated successfully.',
  'PlannedOutage.loadSheddingStage.description': 'Stage',
  'SwitchingInstruction.heading': 'Switching Instruction',
  'FlashReport.id': 'Fault ID',
  'js.switchingEventMenu.createReverseInstruction':
    'Create Reverse Instruction',
  'StockTakeExpectedItemDWR.material': 'Material',
  'orgStructure.customLookup4': '',
  'AbnormalPlant.faultReport.custom2': '',
  'orgStructure.customLookup5': '',
  'AbnormalPlant.faultReport.custom3': '',
  'orgStructure.customLookup2': '',
  'ForcelinkSubcontractor.localUserAccount': 'Local User Account',
  'orgStructure.customLookup3': '',
  'AbnormalPlant.faultReport.custom1': '',
  'Invoice.customer.description': 'Customer',
  'js.polylineInstructions':
    'left-click to define the points on the route, press ESC to cancel, ENTER to complete.',
  'orgStructure.customLookup1': '',
  'mobile.customTime': 'Time',
  'Store.customNumber5': '',
  'AbnormalPlant.faultReport.custom6': '',
  'Store.customNumber6': '',
  'AbnormalPlant.faultReport.custom7': '',
  'Store.customNumber3': '',
  'AbnormalPlant.faultReport.custom4': '',
  'Store.customNumber4': '',
  'AbnormalPlant.faultReport.custom5': '',
  'Store.customNumber1': '',
  'Store.customNumber2': '',
  'AbnormalPlant.faultReport.custom8': '',
  'AbnormalPlant.faultReport.custom9': '',
  'Store.customNumber9': '',
  'Store.customNumber7': '',
  'WorkOrderRequest.orgUnit': 'Region/Contractor',
  'ResourceChat.recordId': 'Record ID',
  'CustomMatrixView.heading': 'Custom Matrix View',
  'Store.customNumber8': '',
  'menu.viewRules': 'Rules',
  'Invoice.invoiceStatus.open': 'Open',
  'portalMainMenu.title': 'Welcome to the Forcelink Customer Portal!',
  'js.countChildCustomersMoved': '{0} child customers moved to new parent',
  'SupportPerson.email': 'Email',
  'Asset.code': 'Asset Code',
  'crew.title': 'Crew Detail',
  'device.title': 'Device Detail',
  'menu.proposedDevices': 'Proposed Devices',
  'WorkRequestSubcontractorAssignmentRule.activeFromHHMM':
    'Active From (HH:MM)',
  'WorkOrder.id': 'Id',
  'signup.heading': 'New User Registration',
  'js.workordersDeallocatedCount': 'Work Orders de-allocated',
  'ScheduledScript.title': 'Scheduled Scripts',
  'SLA.customPause.title':
    'A comma-separated list of status codes that will cause the SLA to pause.',
  'SMSCampaign.selectedUserGroups': 'User Groups',
  'InspectionListItem.customField.customFieldType': 'Field Type',
  'Asset.edit': 'Edit Asset',
  'myresources.heading': 'My Resources',
  'js.receiveBOMItem': 'Receive/Return Item',
  'subscriberAutoNumberingSettings.updated':
    'Auto Numbering has been updated successfully.',
  'js.viewGanttChart': 'View Gantt Chart',
  'js.invoiceEmailSent': 'Invoice E-mail sent',
  'BillOfMaterialsItem.taxType': 'Tax Type',
  'callTakeForm.title': 'Rapid Call Take',
  'internationalLabels.heading': 'International Labels',
  'Quotation.customDate10': '',
  'material.customLookup6': '',
  'material.customLookup7': '',
  'material.customLookup8': '',
  'material.customLookup9': '',
  'material.customLookup2': '',
  'material.customLookup3': '',
  'material.customLookup4': '',
  'material.customLookup5': '',
  'menu.myWork': 'My Work',
  'Call.assignedToUser': 'Assign to Agent',
  'supplierorgunits.defaultColumnView': '',
  'js.translations': 'Translations',
  '*.material.description': 'Material Description',
  'errors.token':
    'Request could not be completed. Operation is not in sequence.',
  'schematicdiagrams.heading': 'Schematic Diagrams',
  'menu.createGmailResourceCalendars': 'Create Gmail Calendars',
  'js.deleteBomItem': 'Delete Item',
  'Document.dictionaryTable': 'Table',
  'InspectionListWizard.button.clear': 'Clear',
  'js.reloadExpectedItems': 'Re-load expected items',
  'Customer.autoCompleteSerachResult': '${code} ${treeDescription}',
  'QuickLaunch.materials': 'Materials',
  'BillOfMaterialsGroup.startDate': 'Start Date',
  'Asset.ownerCustomer.homeNumber': 'Home Number',
  'Crew.employer': 'Employer',
  'FaultReport.rootCauseEquipment.description': 'Root Cause Equipment',
  'PurchaseOrder.linkedObjects':
    'Linked Work Order, Quotations and Purchase Requisition',
  'Lease.monthlyRental': 'Monthly Rental',
  'js.addWorkNotes': 'Add Work Notes',
  'menu.viewWorkOrdersMaintenancePlan': 'Maintenance Plan',
  'ShiftType.code': 'Code',
  'Asset.locationAddress': 'Asset Address',
  'forcelinkbillingdetails.heading': 'Billing Details',
  'menu.viewCustomers': 'Customers',
  'button.runAllocationRulesNow': 'Run Allocation Rules now',
  'locationAddress.province.description': 'Province',
  'ResourceShiftRecord.endingMileage': 'Ending Mileage',
  'errors.login.BadSubscriberException':
    'Invalid Subscriber ID, please try again.',
  'Customer.contactPerson': 'Contact Person',
  'material.customLookup1': '',
  'MobileManagerView.title': 'Mobile Manager View',
  'js.geometryFillColour': 'lightblue',
  'LoadShedding.plannedFinish': 'Planned Finish',
  'customerPortal.passwordupdated.emailmessage':
    "Dear {0},\n\nYour password for username '{1}' has been changed to '{2}'.\n\nRegards,\nForcelink Admin",
  'editCustomFields.heading': 'Edit Custom Fields',
  'ArcGISLayer.id': 'ID',
  'js.createWorkOrderPlannedGeometry': 'Create Planned Work Geometry',
  'customerMobile.workOrderCreated': 'A Work Order has been Created',
  'Asset.material': 'Linked Material',
  'DateTime.format': 'dd-MM-yyyy HH:mm',
  'customerMobile.title': 'Customer',
  'StockTake.status': 'Status',
  'statusList.title': 'Edit Statuses',
  'WorkRequestAutoAllocationRule.workRequestFilter.hint':
    "Please supply a comma-separated list of filter criteria (e.g. workRequestType.code='XX',customer.code='YY', etc)",
  'subscriberAutoNumberingSettings.codeSuffix': 'Code Suffix',
  'workMatrix.title': 'Work Matrix',
  'WorkCalendarMatrixView.sumProperty': 'Sum Property',
  'InspectionListWizard.button.addItem': 'Add Item',
  'Customer.user.username': 'Forcelink Username',
  '*.firmCoordinate': 'Firm Coordinate?',
  'assignment.assignedToUser': 'Assigned to Agent',
  'Resource.trainingEvents': 'Training Events',
  'js.noteAdded': 'Note Added!',
  'js.portalCallRef': 'Your call reference number is:',
  'Asset.validation.moveToStore':
    'Only Asset types which are Equipment may be moved to a store',
  'StatusReference.availableOnMobile': 'Available on Mobile',
  'ForcelinkBillingDetail.subscriber.subscriberId': 'Subscriber',
  'Material.taxCode.description': 'Tax Code',
  'js.addDocument': 'Add Document',
  'availabilitycalendar.title': 'Shift Roster List',
  'errors.signatureRequired':
    'You need to sign off this {0} before updating the status',
  'addBOMItems.heading': 'Add Items to Bill Of Materials',
  'Invoice.invoiceStatus.systemStatus.open': 'Open',
  'billOfMaterials.unitCost': 'Unit Cost',
  'crew.active': 'Active? ',
  'WorkOrder.orgUnit': 'Organisational Unit',
  'subscriberdatabases.heading': 'Subscriber Databases',
  'js.switchingEventMenu.editSwitchingInstruction':
    'Edit Switching Instruction',
  'SMSMessage.charge': 'Charge (credits)',
  'mobile.mainMenu.back': 'Back To Main Menu',
  'menu.workFlow': 'Work Flow',
  'orgUnitTree.selectedCount': '{0} selected',
  'QueryMapLineLayer.layerSQL': 'SQL',
  'SpatialLayer.heading': 'Spatial Layer',
  'LoadShedding.status.description': 'Status',
  'js.replaceContactDetailsButton': 'Replace Contact Details',
  'Document.customDate3': '',
  'Document.customDate2': '',
  'Document.customDate5': '',
  'Document.customDate4': '',
  'js.quickSearchResults': 'Quick Search Results',
  'menu.viewBillOfMaterials': 'Bill Of Material',
  'SupportPerson.active': 'Active? ',
  'ArcGISLayer.transparency': 'Transparency',
  'BillOfMaterialsItem.vat': 'VAT',
  'SwitchingEvent.device.substation.description': 'Station',
  'PublicHoliday.heading': 'Public Holiday',
  'resource.cameraMode': 'Phone Camera Mode',
  'menu.viewCustomerSelfService': 'Customer Self Service',
  'subscriberAutoNumberingSettingsDetail.title': 'Auto Numbering Detail',
  'Asset.ownerCustomer.emailAddress': 'Email Address',
  'Document.customDate1': '',
  'forcelinksubcontractors.title': 'Subcontractors (using Forcelink)',
  'js.createSubGroupTitle': 'Create Sub-Group',
  'Asset.validation.parentRequired': "Asset Type '{0}' must have a parent",
  'menu.runSQLStatements': 'Run SQL statements',
  'WorkOrder.workOrderStatus.systemStatus.open': 'Open',
  'Resource.shiftTypes.hint':
    'Select which shift types are applicable to the resource. If none are selected, all shift types are available',
  'js.selectChangeSupplier': 'Select/Change Supplier',
  'menu.rapidCallTake': 'Rapid Call Logging',
  'Project.customer': 'Customer',
  'Lease.waterAndSanitationFee': 'Water and Sanitation Fee',
  'SLA.customEnd': 'End Status Code(s)',
  'customerMobile.workRequestDetailStatus': 'Work Request Status',
  'workrequestautoallocationrules.title': 'Work Request Auto Allocation Rules ',
  'notification.stockTakeExpectedItemsBackgroundJobCompleted':
    'Populating of stock take expected items in the background has completed. Please refresh with f5 to see changes.',
  'mobile.customDuration': 'Duration',
  'js.submit': 'Submit',
  'Quotation.sentToSubcontractorError':
    'Error sending Quotation request to Sub-contractor {1}',
  'resourceType.added': 'Resource Sub Type Added',
  'quotation.deleted': 'Quotation {0} has been deleted successfully.',
  'errors.phone': '{0} is an invalid phone number.',
  'AssetType.addressRule': 'Address Rule',
  'modalselectdevice.heading': 'Select Device',
  'AuditTrail.timeStamp': 'Date/Time',
  'js.switchingEventMenu.unlinkAbnormalPlant': 'Unlink Abnormal Plant',
  'resource.grouping.individual': 'Individual',
  'DashboardEntry.sortOrder': 'Sort Order',
  'mobile.title.editQuantity': 'Edit Quantity',
  'addbomitems.title': 'Add BOM Items',
  'js.imageReset': 'Reset',
  'switchingevents.standardView': 'Standard View',
  'mobile.addMaterialByAssetWorkType': 'Add Material (by Asset/Work type)',
  'CustomTableColumnSelection.columnList': 'Column List',
  'userProfile.accountSettings': 'Account Settings',
  'CustomMatrixView.userGroups': 'User Groups',
  'WorkOrder.alert.etcExceeded':
    'Estimated Time of Completion for Work Order was exceeded',
  'js.setSelectedResourcesNotAvailable':
    'Set Selected Resources as Not Available',
  'InspectionListItem.noteType.description': 'Note Type',
  'smartCityPortal.city': 'Johannesburg',
  'material.supplier': 'Supplier',
  'SwitchingEvent.abnormalPlant.id': 'Abnormal Plant',
  'Asset.parentAsset.description': 'Parent',
  'AbnormalPlant.status.description': 'Status',
  'WorkOrder.viewWorkOrderDetails': 'View Workorder Details',
  'WorkOrder.description': 'Description',
  'RestorationStage.startDate': 'Start Date',
  'js.switchingEventMenu.removeSwitchingEvent': 'Remove Switching Event',
  'crew.description': 'Description',
  'js.pasteListOfValues': 'Paste List of Values',
  'mobile.reviseETA': 'Revise ETA',
  'SMSCampaign.recipientsSummary.customersByType': 'Customers by Type',
  'mobile.reviseETC': 'Revise ETC',
  'Asset.linkedObjects': 'Linked Objects',
  'user.confirmPassword': 'Confirm Password',
  'mobile.materialsOptions.back': 'Back To Material Options',
  'SupplierQuotation.notes': 'Notes',
  'WorkOrder.noteAddedEmailBody':
    'Dear {0},\n\nNote added at {1}:\n\n{2}\n\nRegards, Forcelink',
  'CustomerPortal.login.passwordHint.error':
    'The username {0} was not found in our database.',
  'Customer.address': 'Address',
  'Invoice.billOfMaterialsGroup.totalPriceIncl': 'Price Incl',
  'menu.viewAssetCategories': 'Asset Categories',
  'menu.viewAssetStock': 'Assets in Stock',
  'button.assignResources': 'Allocate Technicians',
  'PurchaseOrder.title': 'Purchase Order',
  'Crew.defaultStore': 'Default Store',
  'Quotation.createdFromWorkRequest': 'Created From Work Request',
  'mobile.supplyResolution': 'Supply Resolution Code',
  'Asset.audittrail': 'Asset Audit Trail',
  'js.enterScanCodes': 'Enter/Scan codes',
  'menu.smsmessages': 'SMS Messages',
  'Project.orgUnit': 'Org Unit',
  'AbnormalPlant.device.circuit.description': 'Distributor',
  'DeviceCategory.heading': 'Device Category',
  'menu.viewPurchaseRequisitions': 'Purchase Requisitions',
  'pickList.selected': 'Selected',
  'menu.assetMatrix': 'Asset Matrix',
  'js.messageSent': 'Message sent to resource',
  'Device.deviceSubType.description': 'Sub Type',
  'WorkOrderRequest.changedToQuotation':
    'Work Request {0} changed to Quotation',
  'mobile.addStandardBOM': 'Add Standard BOM',
  'js.viewTask': 'View Task',
  'Device.parentDevice.SAPID': 'Parent Floc',
  'WorkOrderRequest.workRequestStatus.code': 'Status Code',
  'mobile.customer.contactPerson': 'Contact Person',
  'statuses.title': 'Statuses',
  'globalSettings.added': 'Global Setting has been added successfully.',
  'PurchaseOrder.supplier.contactPerson': 'Contact Person',
  'js.setToAvailable': 'Set to Available',
  'js.addAssetNoteToSelected': 'Add Note to selected',
  'mobile.changeSearch': 'Change Search Criteria',
  'menu.saveFilters': 'Save Filters',
  'billOfMaterials.heading': 'Bill Of Materials Information',
  'Material.mileage': 'Mileage?',
  'ForcelinkBillingDetail.hasTransactions': 'Has Transactions?',
  'workflows.title': 'Edit Workflows',
  'Note.timeStamp': 'Changed',
  'ResourceType.id': 'ID',
  'BillOfMaterialsItem.totalExclVat': 'Total Excl. VAT',
  'SchematicDiagram.devicesToCommission': 'Devices to Commission',
  'resource.defaultStore': 'Default Material Store',
  'ResourceShiftRecord.unavailableReason.description': 'Unavailable Reason',
  'User.callbackHost': 'Callback Host',
  'WorkOrder.customDate3': '',
  'WorkOrder.customDate2': 'Booked to Date',
  'WorkOrder.customDate1': 'Booked From Date',
  'WorkOrder.customDate7': '',
  'WorkOrder.customDate6': '',
  'WorkOrder.customDate5': '',
  'WorkOrder.customDate4': '',
  'WorkRequestAutoAllocationRule.userSort': 'User Sorts',
  'WorkOrder.customDate9': '',
  'WorkOrder.customDate8': '',
  'Customer.userAccount': 'User Account',
  'crew.added': 'Crew has been added successfully.',
  'standardInspectionList.title': 'Edit Standard Inspection Lists',
  'WorkOrder.contactEmailCC': 'CC e-mail',
  rightClickHint: 'Right-click for options',
  'notificationRule.active': 'Active? ',
  'PurchaseInvoice.notes': 'Notes',
  'editInspectionListValues.title': 'Edit Inspection List Values',
  'js.resheduleLoadShedding': 'Reschedule Load Shedding',
  'Quotation.eta': 'ETA',
  'Quotation.etc': 'ETC',
  'js.hideConnectionDirectionArrows': 'Hide connection Direction Arrows',
  'WorkOrder.assetVerificationSource': '',
  'menu.viewMyDetails': 'My details',
  'Building.customLookup7': '',
  'Building.customLookup8': '',
  'Contract.createdDate': 'Created Date',
  'Building.customLookup5': '',
  'Building.customLookup6': '',
  'resourceType.description': 'Description',
  'menu.recalculateBookValues': 'Recalc. Book Values',
  'Building.customLookup9': '',
  'Asset.assetType': 'Asset Type',
  'quotations.heading': 'Quotations',
  'Building.customLookup3': '',
  'Building.customLookup4': '',
  'menu.auditLog': 'Audit Log',
  'Building.customLookup1': '',
  'Building.customLookup2': '',
  'AbnormalPlant.abnormalPlantType.description': 'Abnormal Plant Type',
  'AbnormalPlant.permitNo': 'Permit No',
  'menu.viewMapLayers': 'KML Map Layers',
  'errors.short': '{0} must be a short.',
  'ForcelinkPricing.title': 'Forcelink Pricing',
  'js.projectTaskView': 'View Task',
  'StatusReference.instructions': 'Instructions',
  'reload.succeeded': 'Reloading options completed successfully.',
  'notificationRules.heading': 'Notification Rules',
  'resource.available': 'Available',
  'LoadShedding.custom10': '',
  'resources.workView': 'Work View',
  'ShiftType.days': 'Days',
  'orgStructure.costCentre': 'Cost Centre',
  'menu.customerAssets': 'Assets',
  'newuser.sms.accountactivation.message':
    "Thank you for registering on Forcelink.  Please enter activation code '{0}', or follow this link to activate your account: {1}.",
  'mobile.title.deleteMaterial': 'Delete Material',
  'WorkOrderRequest.message.allocatePush':
    'Work Request ${code} has been allocated to you.',
  'SubscriberDatabase.supportPerson.fullName': 'Support Person',
  'js.viewPeriodWeek': 'Week',
  'js.showCalendar': 'Show Calendar',
  'SMSCampaign.recipientsSummary.resourcesByEmployer': 'Resources by Employer',
  'Note.title': 'Notes',
  'export.continuingInBackground':
    'The export is continuing in the background. you will be notified when it has completed. You can check progress under Home > Table Exports.',
  'js.editListOfValues': 'Edit List of Values',
  'Asset.ownerCustomer.code': 'Owner cust. code',
  'Asset.billOfMaterials': 'Bill Of Materials',
  'js.linkUserToSelectedResource': 'Link User to selected Resource',
  'mobile.editQuantity': 'Edit Quantity',
  'CustomTableColumnSelection.userGroups': 'User Groups',
  'js.select': 'Select',
  'errors.zip': '{0} is an invalid zip code.',
  'billOfMaterials.deleted': 'Bill Of Materials has been deleted successfully.',
  'faultReport.title': 'Outage Details',
  'js.editSchematicDiagram': 'Edit Schematic Diagram',
  'Asset.assetCategory.description': 'Asset Category',
  'Lease.tenant': 'Tenant',
  'user.website': 'ID Number',
  'PlannedOutage.plannedFinish': 'Planned Finish',
  'Material.materialType.description': 'Type',
  'login.rememberMe': 'Remember Me',
  'mobile.availabilityPrompt':
    "You are currently marked as 'Not Available' - make yourself available now?",
  'BillOfMaterialsItemAsset.pk.asset.description':
    'Current Parent Asset Description',
  'smartCityPortal.homePage.emergency': 'Emergency Services',
  'Contract.custom10': '',
  'billOfMaterialsGroup.heading': 'Bill Of Materials',
  'js.linkSelectedWorkOrdersToProject': 'Link Selected Work Orders to Project',
  'resource.mileageRate': 'Unit Mileage Cost to Company',
  'Lease.contactName': 'Contact Person',
  'invoice.billOfMaterials': 'Bill Of Materials',
  'js.message': 'Message',
  'SwitchingEvent.docType': 'Type',
  'PurchaseOrder.error.invoiceRequired': 'Purchase Invoice(s) must be captured',
  'PersonalDataExpiryRule.className': 'Entity/Table',
  'js.searchForAttr2OnGoogle': 'Search for [attr2] on Google',
  'availabilityCalendar.deleted': 'Shift Roster has been deleted successfully.',
  'menu.LoadSheddingTemplates': 'Load Shedding Templates',
  'Device.explicitTrippable': 'Explicit Trippable',
  'Customer.removeParentCustomer': 'Remove Parent Customer',
  'fieldAccess.regexMessage': 'Validation failure message',
  'js.WorkOrderFormControllerTitle': 'Work Order',
  'contracts.heading': 'Contracts',
  'errors.login.LockedException': 'The account is locked',
  'assetDetail.title': 'Asset Detail',
  'assignment.outsourcedTo': 'Outsourced To',
  '*.select': 'Select',
  'WorkOrder.allocated': 'Work Order {0} has been allocated.',
  'resource.waplinksent':
    "Forcelink Mobile WAP link has been SMS'd to Technician ",
  'subscriberAutoNumberingSettings.added':
    'Auto Numbering has been added successfully.',
  'assets.defaultColumnView': '',
  'WorkOrderRequest.postalCode': 'Postal Code',
  'standardBillOfMaterials.standardCharges': 'Standard Charges',
  'PlannedOutage.trippedDevices': 'Plant to isolate from',
  'DeviceCategory.updated': 'Device Category {0} Updated',
  'Asset.floorArea': 'Floor Area (m2)',
  'PurchaseOrder.orgUnit': 'Org Unit',
  'subscriberCustomFieldLabels.deleted':
    'Custom Field has been deleted successfully.',
  'FlashReport.protTeamLeader': 'Team Leader',
  'js.countWorkOrderChangedTo': 'Work Orders changed to',
  'assetstock.title': 'Assets in Stock',
  'mobile.pleaseEnterEtc': 'Please enter Estimated Time of Completion',
  'mobile.pleaseEnterEta': 'Please enter Estimated Time of Arrival On Site',
  'mobile.settings': 'WAP Settings',
  'Resource.selected': 'Select   ',
  'billOfMaterialsMobile.heading': 'Materials Details',
  '*.workType': 'Work Type',
  'mobile.ShiftTimeUpdated': 'Shift Time has been updated successfully.',
  'SLAAuditTrail.pausedMins': 'Paused Minutes ',
  'ForcelinkSubcontractor.user.lastName': 'Last Name',
  'errors.password.mismatch':
    'Invalid username/password/subscriberID combination, please try again.',
  'PurchaseOrder.add': 'Add',
  'AssetScheduledWorkTemplateXref.pk.scheduledWorkTemplate.description':
    'Template',
  'fieldAccess.property': 'Field',
  'LoadShedding.responsibleUser': 'Responsible Person',
  'StatusReference.assignGroups': 'Assign User Groups',
  'Quotation.supplierQuotations': 'Supplier Quotations',
  'subscriberCustomLabels.description': 'Description',
  notSelected: 'Not Selected',
  'purchaseOrder.workOrder.code': 'Work Order',
  'Device.substation.description': 'Substation',
  'SupplierQuotation.supplierQuotationStatus': 'New Status',
  'js.selectChangeCustomer': 'Select/Change Customer',
  'CustomTableColumnSelection.title': 'Custom Table Column Selection',
  'user.settings': 'Settings',
  'Device.partNumber': 'Part Number',
  'ForcelinkSubcontractor.servicesDescription': 'Description of Services',
  'ForcelinkPricing.billingType.description': 'Billing Type',
  'js.changePlannedStartForSelected': 'Change Planned Start for Selected',
  'SLA.colour': 'Colour',
  'StockTake.completedBy.fullName': 'Completed By',
  WorkOrderRequests: 'Work Requests',
  'js.version': 'Version',
  'superadminusers.heading': 'Superadmin Users',
  'Contract.contactNumber': 'Contact Number',
  'button.sendOutageSMS': 'Send Internal Outage SMS',
  '*.requiresGeocoding': 'Requires Geocoding?',
  'menu.testREST': 'Test REST web services',
  'FaultReport.workOrder.eta': 'ETA',
  'js.privWarning':
    'You do not have sufficient privileges to perform this action!',
  'FaultReport.workOrder.etc': 'ETC',
  'faultreports.heading': 'Outages',
  'QuickLaunch.crews': 'Crews',
  'js.assignToAgentButton': 'Assign to Agent',
  'mobile.statusPreRequisite.note': 'A note must be added to progress this {0}',
  'js.resourceHasLateAppointments': '{0} has late appointments!',
  'fieldaccess.heading': 'Field Access',
  'button.print': 'Print',
  'SwitchingEvent.planned': 'Planned?',
  'Supplier.address': 'Address',
  'material.auditTrail': 'Material Audit Trail',
  'DeviceType.causeList': 'Related Causes',
  'userGroup.heading': 'Edit User Group',
  'LoadShedding.customLookup9': '',
  'CustomerPortal.viewNotifications.title': 'View Notifications',
  'js.editInstructions': 'Edit Instructions',
  'LoadShedding.customLookup2': '',
  'LoadShedding.customLookup1': '',
  'LoadShedding.customLookup4': '',
  'LoadShedding.customLookup3': '',
  'LoadShedding.customLookup6': '',
  'LoadShedding.customLookup5': '',
  'LoadShedding.customLookup8': '',
  'LoadShedding.customLookup7': '',
  'WorkOrderRequest.workLog': 'Status Change Audit Trail',
  'ResourceShiftRecord.mobileAppOnlinePulses': 'App Online Pulses',
  'WorkDoc.code': 'Code',
  'js.addLocalSwitchingEvent': 'Add Local Operating Event',
  'Resource.stores': 'Stores',
  'mobile.30': '30min',
  'Lease.priceEscalationPercent': 'Price escalation %',
  'js.messageSentToSelected': 'Message sent to selected resources',
  'FlashReport.protTeamLeader.fullName': 'Team Leader',
  'Invoice.customer.code': 'Customer Code',
  'resource.manager.fullName': 'Manager',
  'mobile.selectFaultCategory': 'Select Fault Category',
  'Device.voltage': 'Voltage',
  'SpatialLayer.sortOrder': 'Sort Order',
  'CustomerPortal.customerDetails.title': 'Customer Details',
  'assetstock.parentAsset.code': 'Store Code',
  'menu.contracts': 'Contracts',
  'menu.inboxEventLog': 'Inbox Event Log',
  'SwitchingInstruction.oifNumber': 'OIF Number',
  'Device.locationAddress.description': 'Device Address',
  'LoadShedding.plannedDurationMins': 'Planned Duration (Mins)',
  'damagedDevice.damageCause': 'Damage Cause',
  'orgStructure.orgUnitType.code': 'Org Unit Type',
  'QueryMapLineLayer.title': 'Query Line Layer',
  'Customer.billingAddress': 'Billing Address',
  '*.material.taxCode.description': 'Tax Type',
  'Quotation.workRequestType': 'Work Type',
  'js.invoiceNumberAndValueError': 'The invoice number and value are required',
  'FaultReport.error.specifyApparentCauseOther':
    "When choosing an apparent cause of 'Other', you must add remarks to describe the cause.",
  'billOfMaterials.taxType': 'Tax Type',
  'button.home': 'Home',
  'WorkRequestSubcontractorAssignmentRule.subcontractorSort':
    'Subcontractor Sorts',
  'WorkOrderRequest.workRequestType.code': 'Type Code',
  'js.autoRefreshMessage':
    "The page will auto-refresh in <label id='refreshTimeout'>10</label> seconds.",
  'PurchaseOrder.billOfMaterials': 'Bill Of Materials',
  'WorkRequestSubcontractorAssignmentRule.sortOrder': 'Sort Order',
  'WorkOrderRequest.linkedObjects': 'Linked Objects',
  'Asset.attributes': '',
  'Lease.electricityFee': 'Electricity Fee',
  'User.callbackSchemaPassword': 'Callback schema password',
  'documentsFilter.noWorkRequest': 'No Work Request',
  'Contract.supplier.code': 'Supplier Code',
  'WorkOrderRequest.notes.sortOrder': 'asc',
  'User.emailAlertsEnabled': 'Enable Email alerts',
  'LoadShedding.substation': 'Substation',
  'invoice.updated': 'Invoice {0} has been updated successfully.',
  'material.serviceType': 'Service Type?',
  'statusList.statusList': 'Status List',
  'Asset.installedByWorkOrder': 'Installed by Work Order',
  'WorkOrderRequest.viewInspectionListMenu': 'Attachments',
  'Customer.paymentTermsDays': 'Payment Terms',
  'WorkOrderRequest.dateCreated': 'Date Created',
  'WorkOrder.actualFinish': 'Actual Finish Date',
  'js.createWorkRequestForSelectedAssets':
    'Create Work Request for selected Assets',
  'Invoice.code': 'Invoice Code',
  'SMSMessage.message': 'Message',
  'mobile.orEnterStockCode': 'OR enter stock code',
  'menu.viewDashboardEntries': 'Dashboard Entries',
  'material.heading': 'Material Information',
  'SMSMessage.username': 'Sent By',
  'LoadShedding.loadSheddingBlock': 'Block',
  'faultReport.workOrder.notes': 'Work Notes',
  'AbnormalPlantDetails.heading': 'Abnormal Plant Detail',
  'Resource.uploadErrorsSize': 'Upload Errors',
  'ScheduledWorkTemplate.withdrawWorkIfTriggerClears':
    'Withdraw work if trigger alarm clears?',
  'PurchaseOrder.auditTrail': 'Audit Trail',
  'POBillOfMaterialsItem.warning.differentSupplier':
    'Warning, this material is associated with a different supplier from the PO. Please move it to a different PO.',
  'ResourceAttribute.attributeName': 'Attribute Name',
  'FailteIrelandFeeSchedule.customerType.description': 'Premise Type',
  'ResourceType.mileageChargeRate': 'Mileage Rate',
  'mobile.inspectionList.addStandard': 'Add Standard List',
  'login.passwordHint.error': 'The username {0} was not found in our database.',
  'PersonalDataExpiryRule.title': 'Personal Data Expiry Rule',
  'Contract.projects': 'Projects',
  'menu.deviceSubTypes': 'Device Sub Types',
  'js.setDeviceNormalState': 'Set Device Normal State',
  'js.matrixOptions': 'Matrix Options',
  'menu.smsUsage': 'SMS Usage',
  'workOrderMobile.back': 'Back to Workorder',
  'CustomReferenceList.code': 'Code',
  'invoice.title': 'Invoice',
  'hierarchyFilter.groupedAndSingle': 'Groups and single',
  'button.sendMessage': 'Send Message',
  'WorkImage.customLookup1': 'Designation',
  'WorkImage.customLookup2': '',
  'SLA.customPause': 'Pause Status Code(s)',
  'mobile.inspectionList.captureOutstanding': 'Capture outstanding items',
  'WorkImage.customLookup5': '',
  'Quotation.selectedMonitors': 'Selected Monitors',
  'WorkImage.customLookup3': '',
  'WorkImage.customLookup4': '',
  'mobile.title.resolutionTree': 'Resolution Tree',
  'Device.PONumber': 'Purchase Order Number',
  'Customer.accountInfo': 'Customer Account Details',
  'purchaseOrder.store': 'Destination Store',
  'StockTake.createdBy.fullName': 'Created By',
  'Invoice.linkedObjects': 'Linked Work Orders, Work Requests, Quotations',
  'button.sendSms': 'Send SMS',
  'WorkOrder.faultCauseCategory': 'Fault Category',
  'InternationalLabel.key': 'Key',
  'InspectionListItem.largeFormat.cost': '',
  'Assettemplate.customDate10': '',
  editListOfValues: 'Edit list of values',
  'FaultReport.protRemarks': 'General Comments / Suggestions / Recommendations',
  'js.subcontractorServices': 'Description of Services',
  'PlannedOutage.durationHHMM': 'Actual Duration (HH:MM)',
  'projectgantt.title': 'Project Gantt Chart',
  'Device.manufSerialNumber': 'Manuf. serial number',
  'editStatusListForm.code': 'Status Code',
  'FailteIrelandFeeSchedule.perSingleRoomFee': 'Per Single Room',
  'Device.deviceType.deviceCategory': 'Device Category',
  None: '[None]',
  'LoadShedding.loadSheddingStage': 'Stage',
  'WorkOrder.customer': 'Customer  ',
  'Customer.parentCustomer': 'Parent Customer',
  'globalSettings.active': 'Active? ',
  'mobile.activeQuotationListTitle': 'Active Quote Requests',
  'menu.viewWarranties': 'Warranties',
  'ruleParameters.ruleName': 'Parameters for rule',
  'button.createReinstatementPlannedOutage': 'Create Re-Instatement PO',
  'js.reloadInspFromWorkrequestQuote':
    '(Re)load from Quotation / Work Order Request',
  'js.rename': 'Rename',
  'js.editDeviceFlags': 'Edit Device Flags',
  'js.assignWarrantyToSelected': 'Assign Warranty to Selected',
  'button.searchCallHistory': 'Search Call History',
  'Asset.batchAddMode': 'Batch Asset Creation Mode',
  'button.executeSingle': 'Execute on Current Schema',
  'Device.associatedMaterials': 'Associated Materials/Parts',
  'js.editConnection': 'Edit Connection',
  'LoadShedding.station.description': 'Substation',
  'SwitchingInstruction.controller.fullName': 'Controller',
  inactivated: '{0} {1} de-activated',
  'AssetType.active': 'Active? ',
  'orgStructure.description': 'Description',
  'WorkOrderRequest.firmScheduledDate': '',
  'AssetType.error.blockWork':
    'Work may not be logged against assets of type {0}',
  'WorkOrder.project.description': 'Project Description',
  'Lease.parkingFacilities': 'Parking Facilities',
  'WorkOrder.eta': 'Expected Time of Arrival',
  'WorkOrder.etc': 'Expected Time of Completion',
  'js.createWorkRequestDefaultCustomer':
    'Create Work Request on Default Customer',
  '*.open': 'Open?',
  'js.recipient': 'Recipient',
  'FaultReport.relayFailures': 'Relay Failures',
  'PurchaseOrder.billOfMaterialsDefaultColumnView': 'Order View',
  'SubscriberDatabase.statusDetails': 'Account Status Details',
  'CustomerNotification.value': 'Date/Time',
  'menu.viewQuotaions': 'Quotations',
  'js.editDocument': 'Edit Document',
  'SLA.updated': 'SLA {0} updated',
  'js.editItemValue': 'Edit Item Value',
  'mobile.etaAtAppontmentTime': 'ETA will be set to the appointment time of',
  'Device.aquisitionDate': 'Acquisition Date',
  'js.abnormalPlant': 'Abnormal Plant',
  'InspectionListItem.images': 'Image(s)',
  'button.clear': 'Clear',
  'Asset.externalId': 'SCADA ID',
  'CustomerPortal.logFaultMyAddress.title': 'Log a fault on My Address',
  'Building.title': 'Building',
  'QueryMapLayer.iconShadowPath': 'Icon Shadow Path JavaScript expression',
  'QueryMapLayer.mapTypes': 'Maps to display in',
  'purchaseOrder.supplier.contactEmail': 'Email',
  'SLA.durationAfterHoursHint':
    "If supplied, the SLA target will be this value if the time falls outside business hours. A value of '-1' means that the SLA will not accumulate after hours.",
  'availabilityCalendarList.heading': 'Shift Rosters',
  'js.showIfRule': "'Show If' rule",
  'SLA.customStart.title':
    'A comma-separated list of status codes that will cause the SLA to finish.',
  'QueryMapLineLayer.toClassName': 'To Class',
  'WorkOrder.alert.allocatedResourceOffDuty':
    'Allocated Technician is off duty',
  'purchaseOrder.status.description': 'Status',
  'Resource.currentShift.orgUnit.description': 'Region',
  'menu.admin': 'Admin',
  'mobile.searchWorkOrderRequests': 'Search Work Orders Requests',
  'js.singleSelectMode': 'Individual Select Mode',
  'Asset.batchAddMode.codeEntryMode': 'Code Scan/Keyboard Entry',
  'forcelinkbillingdetails.title': 'Billing Details',
  'menu.viewAssetTree': 'Asset Tree',
  'QuickLaunch.quotations': 'Quotations',
  'js.ungroupChildWorkOrder': 'Ungroup Child Work Order',
  'js.projectTaskDesc': 'Desc',
  'workOrderMobile.noQuotations': 'There are no Quotations to display.',
  'ScheduledWorkTemplate.workOrderType.description': 'Work Order Type',
  'MobileManagerView.description': 'View Name',
  'WorkOrderRequest.priority.description': 'Priority',
  'BillOfMaterialsItem.serialisedAssets': 'Assets',
  '*.customer.customNumber10': '',
  'js.viewCustomerWorkRequests': 'View Customer Work Requests',
  'PurchaseRequisition.billOfMaterialsDefaultColumnView': 'Order View',
  'LoadShedding.permitNo': 'Permit Number',
  'ReferenceList.sortOrder': 'Sort Order',
  'CustomTableColumnSelection.viewName': 'Column View Name',
  'BillOfMaterialsItem.countOkMessage': 'Ok',
  'Resource.asset': 'Linked Vehicle / Machinery Asset',
  added: '{0} {1} added',
  'button.reject': 'Reject',
  'proposeddevices.heading': 'Proposed Deviced',
  'js.createWorkRequestPlannedGeometry': 'Create Planned Work Geometry',
  'WorkCalendarMatrixView.aggregateType': 'Aggregate Type',
  'WorkOrder.workPackage': 'Work Package',
  'AssetStatus.nextStatuses': 'Next Statuses',
  'StockTake.allMaterialsInStore': 'All materials in the store',
  'js.labelText': 'Label Text',
  'resource.orgUnit': 'Organisational Unit',
  'js.validateSignaturePasswordPrompt':
    'Please re-enter your password to validate your signature',
  'mobile.emptyList': 'There are no materials matching this search criteria.',
  'invoice.added': 'Invoice {0} has been added successfully.',
  'Supplier.supplierOrgUnits': 'Supplier Org Units',
  'Customer.mobileNumber': 'Mobile Number',
  'js.previewSuspectOutages': 'Preview Suspect Outages',
  'damagedDevice.deviceDescription': 'Damaged Device description',
  'FaultReport.error.interruptionPhasesNotCompleted':
    'The Fault Report cannot be restored because there are still open interruption phases.',
  'js.dialog.createReinstatementPlannedOutage':
    'Create Re-Instatement Planned Outage',
  'LoadShedding.plantToIsolateFrom': 'Plant to Isolate from',
  'menu.viewWorkOrders': 'Work Orders',
  '*.standardView': 'Standard View',
  'Customer.chooseParentCustomer': 'Choose Parent Customer',
  'SMSCampaign.recipientsSummary.resourcesByType': 'Resources by Type',
  'js.viewPeriodMonth': 'Month',
  'mobile.viewBillOfMaterials': 'View Bill Of Materials',
  'WorkOrderRequest.workRequestStatus.description': 'Status',
  'Quotation.contactName': 'Contact Person Name',
  'copyright.message': 'Copyright &copy; 2021 forcelink.net',
  'projects.heading': 'Projects',
  'button.copy': 'Copy',
  'Customer.customerType': 'CustomerType',
  'dashboardEntries.title': 'Dashboard Entries',
  'customerportal.workorderrequests.title': 'Service Tickets',
  'FaultReport.error.specifyProtectionFlags':
    'You must set the protection flags to restore the fault',
  'BillOfMaterialsItem.preferredSupplier': 'Preferred Supplier',
  'Crew.crewLeader': 'Crew Leader',
  'Customer.linkedAsset': 'Point of Supply',
  'js.makeNotFirm': 'Make Not Firm',
  'StockTake.heading': 'Stock Take',
  'js.markAllAsRead': 'Acknowledge All',
  'js.remove': 'Remove',
  'Customer.priority': 'Priority',
  'Invoice.workOrder.billOfMaterialsGroup.totalCostExcl': 'Cost Excl',
  'mainMenu.title': 'Dashboard',
  'StockTake.dateCompleted': 'Date Completed',
  'menu.myWorkRequests': 'My Work Requests',
  'userGroup.assignRoles': 'Assigned Roles',
  'material.customNumber20': '',
  'warranty.heading': 'Warranty',
  'Quotation.billOfMaterialsGroup.formattedTotalPriceExcl': 'Price Excl',
  'material.customNumber16': '',
  'material.customNumber17': '',
  'material.customNumber14': '',
  'material.customNumber15': '',
  'SupplierOrgUnit.customLookup3.description': '',
  'material.customNumber12': '',
  'material.customNumber13': '',
  'BillOfMaterialsItem.material': 'Material',
  'material.customNumber10': '',
  supplier: 'Supplier',
  'material.customNumber11': '',
  'Device.deviceType': 'Device Type',
  'material.customNumber18': '',
  'material.customNumber19': '',
  'Asset.assetStatus': 'Asset Status',
  'AssetType.deleted': 'Asset Type {0} Deleted',
  'menu.assetGantt': '',
  'MaterialStore.pk.store.code': 'Store Code',
  'js.selectedResourcesGroupedToCrew': 'Selected Resources grouped to Crew',
  'js.workRequestsDeallocatedCount': '{0} Work Requests de-allocated',
  'invoice.workRequestCode': 'Work Request/Quotation Number ',
  'SMSCampaign.newStatus': 'New Status',
  'Contract.customLookup10': '',
  'resource.groupingFilter.crewsOnly': 'Crews Only',
  'workOrderRequest.heading': 'Ticket Information',
  'Device.orgUnit.description': 'Organisational Unit',
  'fieldAccess.regexMessage.title':
    'The error text to display if the field fails validation',
  'Device.financialFrame': 'Financial Information',
  'WorkCalendarMatrixView.filters': 'Filters',
  'button.duplicate': 'Duplicate',
  'Lease.pricePerMonth': 'Price per month',
  'ScheduledWorkTemplate.intervalCounter': 'Counts',
  'WorkOrder.assetList': 'Equipment',
  'js.scheduledTemplateStartDate': 'Start Date',
  'SchematicDiagram.changeDate': 'Changed Date',
  'Device.fedFromOHL': 'Fed From Overhead Line?',
  PurchaseOrder: 'Purchase Order',
  'js.viewInSchematic': 'View in Schematic',
  'support.email': 'Email',
  'SubscriberDatabase.billingMaster': 'Billing Master Schema',
  'WorkOrder.activeAllocatedResources': 'Currently Allocated To',
  noDescription: '[No Description]',
  'SwitchingEvent.abnormalPlant': 'Abnormal Plant',
  'menu.exports': 'Table Exports',
  'Invoice.documents': 'Documents',
  'js.selectAll': 'Select All',
  'Supplier.skills': 'Skills',
  'AbnormalPlant.status': 'Status',
  'js.kickOutWarning':
    "There is another session active for this username. Click 'OK' to kill the existing session. Warning - if the existing session is in use, the user may lose some of his work. Continue anyway?",
  'FaultReport.restorationStages': 'Interruption Phases',
  'Building.customLookup10': '',
  'StockTake.materialsHint':
    'The stock take expected items are limited to these materials',
  'contracts.title': 'Contracts',
  'SubscriberAutoNumberingSettings.tableName': 'Table Name',
  'user.deleted': 'User Profile for {0} has been deleted successfully.',
  'material.deleted': 'Material has been deleted successfully.',
  'js.showSerialisedAssets': 'Show Serialised Assets',
  'WorkRequestAutoAllocationRule.workOrderSort.hint':
    'Please supply a comma-separated list of sort criteria (e.g. customer.description,dateCreated)',
  'ArcGISLayer.strokeColor': 'Stroke Colour',
  'Resource.uploadCacheSize': 'Upload Cache Size',
  'AssetStockSummary.count': 'Count',
  'js.subject': 'Subject',
  'WorkRequestSubcontractorAssignmentRule.workRequestSort':
    'Work Request Sorts',
  'BillOfMaterialsItem.preferredSupplier.description': 'Preferred Supplier',
  'js.purchaseDate': 'Asset Purchase Date',
  'WorkOrderAsset.pk.workOrder.plannedStart': 'Planned Start',
  'smscampaigns.title': 'SMS Campaigns',
  'menu.viewStandardBOMs': "Standard BOM's",
  '404.title': 'Page Not Found',
  'Resource.user.enabled': 'User Enabled',
  'PlannedOutage.custom10': '',
  'Asset.description': 'Asset Desc.',
  '403.title': 'Access Denied',
  'billOfMaterialsGroup.billOfMaterials': 'Bill of Materials',
  'material.customNumber25': '',
  'workOrderMobile.heading': 'Workorder Details',
  'material.customNumber23': '',
  'material.customNumber24': '',
  'material.customNumber21': '',
  'devices.unconnectedDeviceView': 'Unconnected Devices',
  'material.customNumber22': '',
  'js.addSwitchingInstruction': 'Add Switching Instruction',
  'ShiftType.orgUnits': 'Org Units',
  'WorkOrder.durationHours': '',
  'StatusReference.group': 'Group',
  'Contract.customLookup8': '',
  'Contract.customLookup7': '',
  'Contract.customLookup9': '',
  'SMSMessage.deliveredDate': 'Delivered Date',
  'resource.mobileLanguage': 'Language Code',
  'usergroups.billingView': 'Billing View',
  'StockTake.title': 'Stock Take',
  'js.originalWorkRequestCode': 'Original Work Request Code',
  'CustomerInteraction.date': 'Date',
  'AssetType.sapCategory': 'SAP Category',
  'button.createChildWorkOrder': 'Create Child Work Order',
  'Contract.customLookup2': '',
  'Contract.customLookup1': '',
  'mobile.inputError': 'Input Error!! Please try again.',
  'Contract.customLookup4': '',
  'Contract.customLookup3': '',
  'Contract.customLookup6': '',
  'Contract.customLookup5': '',
  'users.heading': 'Users',
  'js.addTagInstruction': 'Add Tag Instruction',
  'js.mergeAsset': 'Merge Asset',
  'js.imageLighten': 'Lighten',
  'UserGroup.inboxEventCategories': 'Inbox Categories',
  'PlannedOutage.customDate10': '',
  'WorkOrder.noteAddedNotifyMonitorsEmailBody':
    'Dear {0},\n\nNote added by {4} at {1}:\n\n{2}\n\nLink to Work Request:{3}\n\nRegards, Forcelink',
  'menu.scheduledScripts': 'Scheduled Scripts',
  'AssetType.heading': 'Asset Type',
  'menu.viewUsers': 'Users',
  'menu.customerPurchaseRequisitions': 'Purchase Requisitions',
  'resource.currentWorkCount': 'Current Work Count',
  'Invoice.workOrder.description': 'Work Order',
  'user.phoneNumber': 'Office Phone',
  'FaultReport.plannedFinish': 'Planned Finish',
  'js.createAssetInStock': 'Create Asset in Stock',
  'SchematicDiagram.diagramName.description': 'Diagram Name',
  'js.createWorkOrderForTrippedDevice': 'Create Work Order',
  'Customer.contactPerson.mobileNumber': 'Contact Number',
  'subscriberautonumberingsettings.heading': 'Auto Numbering List',
  'mobilemanagerviews.heading': 'Mobile Manager Views',
  'workOrderRequest.notes': 'Work Log',
  'uploadForm.custom1': '',
  'InspectionListTable.title': 'Edit Inspection List',
  'Customer.physicalAddress': 'Physical Address',
  'BillOfMaterialsItemAsset.pk.bomItem.createdDate': 'Movement date',
  'uploadForm.custom5': '',
  'uploadForm.custom4': '',
  'Device.constructionDate': 'Construction Date',
  'uploadForm.custom3': '',
  'uploadForm.custom2': '',
  'Project.status': 'Status',
  'mobile.supplyQuantity': 'Supply Quantity',
  'suspectoutages.heading': 'Suspect Outages',
  'CustomerPortal.login.termsAndConditionsChanged':
    'Your Terms and Conditions have changed, please accept them again',
  'js.showIfAttr3EqualY':
    'The second item in the menu. it will show if attr3=Y (and its: [attr3])',
  'js.portalCommsFailure':
    'Communication failure, please check your internet connection',
  'workOrder.monitorGroups': 'Monitor Groups',
  'customer.NotesAndInteractions': 'Notes and Interactions',
  'mobile.selectUnits': 'Select Units',
  'signature.added': 'Signature added to {0}',
  'orgStructure.workTypes': 'Allowed Work Request Types',
  'orgStructure.added': 'Org Structure has been added successfully.',
  'subscriberAutoNumberingSettings.codePrefix': 'Code Prefix',
  'arcGISLayers.title': 'Edit ArcGIS Layers',
  'js.loadingTablePleaseWait': 'Loading table, please wait...',
  'js.setSelectedResourcesAvailable': 'Set Selected Resources as Available',
  'notificationRule.description': 'Description',
  'errors.cancel': 'Operation cancelled.',
  'Building.locationAddress.geomArea': '',
  'menu.viewReferenceList': 'Reference List',
  'assets.warrantyView': 'Warranty View',
  'globalSettings.settingName': 'Key',
  'SMSMessage.campaign.description': 'Campaign Name',
  'dashboardEntry.saved': 'Dashboard Entry {0} saved',
  'inspectionitems.title': 'Inspection List',
  'address.id': 'Id',
  'menu.viewTaxType': 'Tax Types',
  'Asset.serialNumber': 'Serial Number',
  'resource.resourceName': 'Technician Name',
  'standardBillOfMaterials.heading': 'Standard Bills Of Materials',
  'login.register':
    '<a style="color:white;" href="javascript:Login.registerPortalAccount();" title="Register a customer portal account.">Register</a>',
  'ForcelinkSubcontractor.user.fullName': 'Local User Account Full name',
  'address.province': 'Province',
  'FaultReport.affectedSuburbs': 'Affected Suburbs',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.province': '',
  'js.removeItem': 'Remove Item',
  'FaultReport.error.specifyApparentCauses':
    'You must select at least one Apparent Cause to restore the fault',
  'StatusReference.verb': 'Verb',
  'statuses.heading': 'Statuses',
  outsourcedTo: 'Outsourced to',
  'FaultReport.issuedTo': 'Issued To',
  'PlannedOutage.devicesToWorkOn': 'Plant To Work On',
  'js.removeGroup': 'Remove Group',
  'material.custom10': '',
  'js.editFieldRules': 'Edit Field Rules',
  'crew.code': 'Code',
  'WorkOrderRequest.activeAllocatedResources': 'Currently Allocated To',
  'Supplier.custom1': '',
  'Supplier.custom2': '',
  'mobile.addLineItem': 'Add Misc Line Item',
  'StatusReference.code': 'Code',
  'InspectionListGroup.id': 'ID',
  'js.contractassetsMoreOptionsMenu.removeSelectedAssets':
    'Unlink selected Assets',
  'StockTakeFoundItem.message': 'Result',
  'js.invoiceNumber': 'Invoice Number',
  'WorkOrderRequest.billOfMaterials': 'Bill Of Materials',
  'Supplier.custom3': '',
  'Supplier.custom4': '',
  'Supplier.custom5': '',
  'Supplier.custom6': '',
  'resource.hourlyChargeRate': 'Hourly Charge-Out Rate',
  'SMSMessage.status': 'Delivery Status',
  'Supplier.custom7': '',
  'Supplier.custom8': '',
  'Supplier.custom9': '',
  'ScheduledScript.lastRunMillis': 'Last run milliseconds',
  'warranty.title': 'Warranty',
  'UserGroup.standardRoles': 'Standard Roles',
  'call.workRequestType': 'Call Type',
  'outageMatrix.title': 'Outage Matrix',
  'menu.versionInfo': 'Release Notes',
  'Asset.notes.sortOrder': 'asc',
  'PersonalDataExpiryRule.columnList': 'Columns to mask',
  'Customer.customerType.code': 'Customer Type Code',
  'StatusReference.systemStatus': 'System Status',
  'globalSettings.heading': 'Global Settings Information',
  'UserGroup.financialApprovalLimit': 'Financial Approval Limit',
  'POBillOfMaterialsItem.quantityDelivered': 'Quantity Delivered',
  'js.linkResourceToSelectedUser': 'Link Resource to selected User',
  'invoice.invoiceStatus': 'Change Status',
  'workorders.title': 'Work Order List',
  'taxType.title': 'Tax Type',
  FaultReport: 'Outage',
  'CustomerPortal.viewAllFaults.title': 'View All Faults',
  'js.mapContextSigleSelectMode': '{0} single select mode',
  'Asset.alarmFlags': 'Alarm Flags',
  'invoiceDetail.title': 'Invoice Detail',
  'PlannedOutage.voltage.description': 'Voltage',
  'customerPortal.passwordupdated.smsmessage':
    "{0}, your password for username '{1}' has been changed to '{2}'",
  'leases.heading': 'Leases',
  'FaultReport.notes': 'Notes',
  'globalSettings.deleted': 'Global Setting has been deleted successfully.',
  'js.searchForCustomer': 'Search for Customer ',
  'subscriberAutoNumberingSettings.deleted':
    'Auto Numbering has been deleted successfully.',
  'mobile.workOrders': 'Work Orders',
  'assets.title': 'Assets',
  'WorkOrder.postalCode': 'Postal Code',
  'Material.materialSuppliers': 'Suppliers',
  'mobile.selectResolutionCode': 'Select Resolution Code',
  'Asset.structureCode': 'Asset Hierarchy Code',
  'button.viewDiagram': 'View Diagram',
  'MaterialStore.reorderLevel': 'Re-order Level',
  'passwordRule.enforceCharactersAndNumbers':
    'The password must contain at least one character and at least one number.',
  'Quotation.auditTrail': 'Audit Trail',
  'mobile.activeWorkOrderRequestListTitle': 'Active Work Request List',
  'viewstream.heading': 'Stream Information',
  'StandardBillOfMaterials.description': 'Description',
  'mobile.searchCustomer': 'Search Customer',
  'js.setColumnWidth': 'Set Max Column Width',
  'js.close': 'Close',
  'ForcelinkSubcontractor.user.email': 'Email Address',
  'fieldAccess.requiredIfMessage':
    "Error message to display when 'Required If' condition triggered. ",
  'Customer.user.passwordHint': 'Password Hint',
  'AbnormalPlant.device.substation.description': 'Substation',
  'InspectionListItem.description': 'Inspection Item',
  'WorkImage.longitude': 'Longitude',
  'auditLog.heading': 'Audit Log',
  cronHint: 'second minute hour DayOfMonth Month DayOfWeek(?)',
  'call.notes': 'Call Information',
  DamagedDevice: 'Fault Location',
  'js.showIfRuleHint':
    "The item will be displayed if some other field condition is true in the list. Text must be formatted as follows: ${<field id value to look up>}=='<value to check for>' && ${<another field id value to look up>}=='<value to check for>' || (<more criteria>), etc",
  'linkedReferenceList.childFieldName': 'Child Field Name',
  'Resource.description': 'Name',
  'errorPage.heading': 'Yikes!',
  'Contract.supplier': 'Supplier',
  'asset.heading': 'Asset Detail',
  '*.contract.code': 'Contract Code',
  'shifttypes.title': 'Shift Types',
  'ReferenceList.custom6': 'custom6',
  'ReferenceList.custom7': 'custom7',
  'js.selectFromTree': 'Select from tree',
  'SwitchingEvent.controller.fullName': 'Controller',
  'ReferenceList.custom8': 'custom8',
  'ReferenceList.custom9': 'custom9',
  'js.createAssetInStore': 'Create Asset in Store',
  'errors.email': '{0} is an invalid e-mail address.',
  AuditTrail: 'Audit Trail',
  'supportSms.heading': 'Send Support SMS',
  'AssetCategory.depreciationRate.title': 'Percentage depreciation each year',
  'SwitchingInstruction.instructionTime': 'Instruction Time',
  'BillOfMaterialsItem.resource': 'Resource',
  'WorkOrderRequest.workRequestType.description': 'Request Type',
  'SupplierQuotation.quotationNumber': 'Quotation Number',
  'ReferenceList.custom2': 'custom2',
  'ReferenceList.custom3': 'custom3',
  'ReferenceList.custom4': 'custom4',
  'ReferenceList.custom5': 'custom5',
  'WorkOrder.createdFromScheduledWorkTemplate':
    'Created from Scheduled Work Template',
  'ScheduledWorkTemplate.customDate5': '',
  'ReferenceList.custom1': 'custom1',
  'LoadShedding.voltage.code': 'Voltage',
  'FaultReport.error.abnormalPlantForNormalisedSwitchingEvents':
    "There are normalised switching events linked to an Abnormal Plant. Please unlink event on '{0}' from the Abnormal Plant record",
  'LabelValueFlag.flag': 'Revert?',
  'WorkOrderRequest.noteAddedEmailBody':
    'Dear {0},\n\nNote added at {1}:\n\n{2}\n\nRegards, Forcelink',
  'Resource.formattedAvailableUntil': 'Shift End',
  'button.forward': 'Forward',
  'SLA.criteria': 'Measurement',
  'menu.viewAssetStatuses': 'Asset Statuses  ',
  'QuickLaunch.invoices': 'Invoices',
  'ScheduledWorkTemplate.customDate4': '',
  'ScheduledWorkTemplate.customDate3': '',
  'PlannedOutage.customLookup10': '',
  'ScheduledWorkTemplate.customDate2': '',
  'SMSCampaign.campaignCategory.description': 'Campaign Category',
  'ScheduledWorkTemplate.customDate1': '',
  'StatusReference.heading': 'Edit Status Definition',
  'Resource.mileageChargeRate': 'Mileage Charge',
  'SpatialLayer.description': 'Description',
  'WorkOrder.plannedFinish': 'Planned Finish Date/Time',
  'Quotation.customDate7': '',
  'Quotation.customDate8': '',
  'Quotation.customDate5': '',
  'Quotation.customDate6': '',
  'Quotation.customDate3': '',
  'js.viewOnSchematic': 'View on Schematic',
  'Quotation.customDate4': '',
  'Quotation.customDate1': 'Quotation Sent to Customer Date',
  'Quotation.customDate2': 'Purchase Order Received Date',
  'AssetCategory.depreciationRates': 'Depreciation Percentages',
  'Building.floorArea': 'Floor Area (m2)',
  'Resource.message.managerLateStart':
    'Resource {0} was expected to become available at {1} but has not made himself available yet. Time now is {2}.',
  'Scheduler.viewPeriod.Day': 'Day',
  'Quotation.customDate9': '',
  'Warranty.description': 'Description',
  'FaultReport.relayFlags': 'Protection Relay Flags',
  'mobile.title.confirmCompletion': 'Confirm work completion',
  'ResourceType.smsOffDutyFlag': 'SMS when off duty?',
  'customerportal.workOrderRequest.billOfMaterials': 'Bill of Materials',
  'js.apparentCause': 'Apparent Cause',
  'WorkOrder.customer.code': 'Customer Code',
  'js.disable': 'Disable',
  'PurchaseRequisition.heading': 'Purchase Requisition',
  'Assettemplate.customNumber4': '',
  'Assettemplate.customNumber5': '',
  'PlannedOutage.id': 'ID',
  'Assettemplate.customNumber2': '',
  'mobile.createWorkOrderForCustomer': 'Create W/O for Customer',
  'Assettemplate.customNumber3': '',
  'Assettemplate.customNumber1': '',
  'mycompanyworkorders.heading': 'My Company Work Orders',
  'menu.compareSchemaConfig': 'Compare schema config',
  'Assettemplate.customNumber8': '',
  'Assettemplate.customNumber9': '',
  'Assettemplate.customNumber6': '',
  'Assettemplate.customNumber7': '',
  'errorPage.title': 'An error has occurred',
  'PurchaseRequisition.customLookup7': '',
  'PurchaseRequisition.customLookup6': '',
  'PurchaseRequisition.customLookup9': '',
  'documentsFilter.hasQuotation': 'Has Quotation',
  'PurchaseRequisition.customLookup8': '',
  'Asset.error.circularReference': 'Circular reference error',
  'WorkOrder.message.multipleAllocated':
    '{0} Work Orders have been allocated to you',
  'PurchaseRequisition.customLookup1': '',
  'PurchaseRequisition.customLookup3': '',
  'Project.orgUnit.description': 'Org Unit',
  'PurchaseRequisition.customLookup2': '',
  'PurchaseRequisition.customLookup5': '',
  'PurchaseRequisition.customLookup4': '',
  'Contract.newStatus': 'New Status',
  'support.emailAddress': 'support@forcelink.net',
  'AssetType.customNumber1': '',
  'AssetCategory.heading': 'Asset Category',
  'js.WorkOrderRequestFormControllerTitle': 'Work Order Request',
  'AssetType.customNumber4': '',
  'CustomField.description': 'Description',
  'AssetType.customNumber5': '',
  'AssetType.customNumber2': '',
  'DeviceCategories.heading': 'Device Categories',
  'AssetType.customNumber3': '',
  'js.selectDevice': 'Select Device',
  'orgStructureList.heading': 'Org Structures',
  'workrequestgroupingpreview.heading':
    'Optionally group to existing Work Order',
  'DashboardEntry.displayIfZero': 'Display if Zero?',
  'LoadShedding.newStatus': 'New Status',
  'BillOfMaterialsItem.billOfMaterialsGroup.endDate': 'End Date',
  'proposeddevices.title': 'Proposed Devices',
  'assetStatus.heading': 'Asset Status',
  'js.removeScheduledJob': 'Remove Scheduled Job',
  'js.createSuspectOutageForUnknownLoadCentre':
    'Create Suspect Outage for unknown load centre',
  'js.restartStockTake': 'Re-start Stock Take',
  'DeviceType.description': 'Device Type Description',
  'js.selectStore': 'Select Store',
  'Lease.customer': 'Tenant',
  'resource.emailAddress': 'E-mail Address',
  'Device.externalId': 'External ID',
  'js.assignToMeButton': 'Assign to Myself',
  'AssetType.rootAsset': 'Can be tree root?',
  'ResourceShiftRecord.createdBy': 'Created By',
  'WorkRequestAutoAllocationRule.workRequestSort': 'Work Request Sorts',
  'workimages.instructions':
    'In order to add an image, browse to select the image file, upload the file. Repeat for any additional images, and then close the dialog with the bottom-right button. ',
  'StockTakeFoundItemDWR.material': 'Material',
  'documentsFilter.noQuotation': 'No Quotation',
  'subscriberCustomLabels.heading': 'Custom Labels',
  'WorkCalendarMatrixView.viewPeriod': 'View Period',
  'FlashReport.faultReport.rootCauseEquipment.description': 'Cause Equipment',
  'LoadShedding.customDate9': '',
  'jmesa.addRecord': 'Add new {0}',
  'LoadShedding.customDate6': '',
  'user.allowUnassignedOrgUnits': 'Allow access to Unassigned Org Units?',
  'LoadShedding.customDate5': '',
  'WorkOrderRequest.updated': 'Work Request {0} has been updated successfully.',
  'LoadShedding.customDate8': '',
  'LoadShedding.customDate7': '',
  'InspectionListItem.customField.id': 'Field ID',
  'WorkOrderRequest.id': 'Id',
  'Contract.currency': 'Currency',
  'SubscriberDatabase.dbUserPassword': 'DB user password',
  'button.checkRelatedOutages': "Related PO's",
  'Contract.docType': 'Category',
  'customerPortal.passwordReset.sms.message':
    "{0}, your password for username '{1}' has been reset to '{2}'",
  'PurchaseOrder.error.totalsDiffer':
    'Purchase Order Delivered total differs from Invoiced total',
  'SMSCampaign.selectedResources': 'Resources',
  'Customer.alertFlags': 'Alert Preferences',
  'material.assetType': 'Asset Type',
  'LoadShedding.customDate2': '',
  'LoadShedding.customDate1': '',
  'LoadShedding.customDate4': '',
  'LoadShedding.customDate3': '',
  'Asset.removedByWorkOrder': 'Removed by Work Order',
  'Quotation.appointment': 'Appointment',
  '*.QAView': 'QA View',
  'Quotation.customLookup20': '',
  '*.customer.customLookup7': '',
  '*.customer.customLookup8': '',
  '*.customer.customLookup9': '',
  'FaultReport.certainty': 'Certainty',
  'Customer.orgUnit': 'Org Unit',
  'AssetType.scheduledWorkTemplates': 'Scheduled Work Templates',
  'resourcechats.title': 'Resource Chats',
  'js.selectThisItem': 'Select this item?',
  'documentsFilter.noInvoice': 'No Invoice',
  'referenceList.deleted': 'Reference has been deleted successfully.',
  'menu.manageSubscriberDatabases': 'Manage Subscriber Databases',
  'SubscriberDatabase.allowedTestEmails': 'Allowed Test Emails',
  'js.showInfoBlock': 'Show Info Block',
  'user.status': 'Logged in as: ',
  'WorkImage.latitude': 'Latitude',
  'FaultReport.custom10': '',
  'Document.custom1': '',
  'BillOfMaterialsItem.taxType.description': 'Tax Type',
  'Document.custom2': '',
  'Document.custom3': '',
  'WorkOrderRequest.customer.parentCustomer.taxRegNumber': '',
  'FaultReport.newStatus': 'New Status',
  'js.removeUser': 'Remove User',
  'Document.custom4': '',
  'User.dateCreated': 'Date Created',
  'Document.custom5': '',
  'CustomManagerImpl_etc.nextShiftMessage':
    '{0}, your next shift is {1} starting on {2}. Here is your shift calendar for the week: {3}',
  '*.customer.customLookup1': '',
  '*.customer.customLookup2': '',
  '*.customer.customLookup3': '',
  '*.customer.customLookup4': '',
  'AbnormalPlant.newStatus': 'New Status',
  '*.customer.customLookup5': '',
  '*.customer.customLookup6': '',
  'Quotation.customLookup18': '',
  'Quotation.customLookup19': '',
  'Quotation.customLookup14': '',
  'Quotation.customLookup15': '',
  'inspectionListGroup.largeFormat.title': 'Inspection List',
  'Quotation.customLookup16': '',
  'Quotation.customLookup17': '',
  'mobile.selectSubType': 'Select Sub Type',
  'WorkOrder.cancelled': 'Work Order {0} has been cancelled.',
  'Quotation.customLookup10': '',
  'Quotation.customLookup11': '',
  'Quotation.customLookup12': '',
  'Quotation.customLookup13': '',
  'WorkOrder.signoffs': 'Signatures',
  'editStatusListForm.systemStatus': 'System Status',
  'js.countResourcesOnDuty': '{0} Resources set On Duty',
  'AbnormalPlant.faultReport.rootCause.description': 'Root Cause',
  'LoadShedding.plannedDurationHHMM': 'Planned Duration (HH:MM)',
  'Device.validation.activeChildren':
    'Cannot inactivate device with active children',
  'js.performStockTakeOnSelectedMaterials': 'Perform Stock Take on selected',
  'user.title': 'Edit User Account',
  'menu.workManager': 'Work',
  'InspectionListGroup.guid': 'GUID',
  'menu.clearAllCaches': 'Clear all caches',
  'FaultReport.orgUnit.description': 'Depot/Area',
  'WorkOrder.alert.lateFinish': 'Late Finish Alert ',
  'FaultReport.abnormalPlant': 'Abnormal Plant',
  'DictionaryField.id': 'Field Id',
  'ReferenceList.externalid': '',
  'Call.contactEmail': 'Contact Email',
  'CustomerPortal.username': 'Username',
  'passwordHint.email.message':
    'Dear {0},\n\nYour saved password reminder is: {1}.\n\nRegards,\nForcelink admin',
  'User.employer': 'Employer',
  'button.createWorkOrderRequest': 'Create Work Order Request',
  '*.faxNumber': '',
  'Contract.supplier.address.addressString': 'Supplier Address',
  'Document.documentType': 'Document Type',
  'WorkOrderRequest.city': 'City',
  'StockTake.expectedQuantities': 'Expected Quantities',
  'js.selectAssetStockWithScanner': 'Select Equipment in stock with scanner',
  '*.longitude': 'Longitude',
  'Material.description': 'Description',
  'js.createWorkPackageForSelectedAssets':
    'Create Work Package for selected Assets',
  'Asset.modelNumber': 'Model Number',
  'workorderrequestscheduler.heading': 'Scheduler',
  'mobile.yes': 'Yes',
  'ScheduledWorkTemplate.durationHours': 'Duration (Hours)',
  'QueryMapLayer.added': 'Query Layer Added',
  'tablevalidationrules.title': 'Table Validation Rules',
  'js.selectStandardCharge': 'Select Standard Charge',
  'Contract.contractprojectsDefaultColumnView': '',
  'js.QuotationFormControllerTitle': 'Quotation',
  'mobile.statusPreRequisite.closeChildren':
    'All child {0}s must be closed first',
  'js.applyPendingChanges': 'Apply the {0} pending changes?',
  'FieldAccess.editGroups': 'Edit Groups',
  'smartCityPortal.login.passwordHint':
    'Forgot your password?  Have your <a href="?" title="Have your password hint sent to you." onclick="passwordHint(); return false">password hint e-mailed to you</a> or <a href="?" title="Reset your password." onclick="passwordReset(); return false">Reset</a> it.',
  'SLAAuditTrail.startDate': 'SLA Start Date',
  'WorkOrder.createdDate': 'Created Date',
  'js.pasteScheduledQuotations': 'Paste Selected Quotations',
  'WorkOrderRequest.workOrder.faultReport.id': 'Outage',
  'Document.customLookup3.description': '',
  'js.selectConfirmation': 'Select {0} {1}?',
  'PurchaseRequisition.workRequestStatus.systemStatus.open': 'Open?',
  'js.addWithWizard': 'Add with Wizard',
  'TrainingEvent.trainer': 'Trainer',
  'FailteIrelandFeeSchedule.perRoomFee': 'Per Room',
  'customerMobile.quotationRequestDetailStatus': 'Quotation Status',
  'resource.overtimeChargeRate': 'Hourly Overtime Charge-Out Rate',
  'js.subcontractorName': 'Sub-contractor name',
  'billofmaterialsitems.POView': 'Purchase Order View',
  'restorationstagecustomers.heading2':
    'Customers affected by Outage {0} from {1}',
  'dashboardEntry.created': 'Dashboard Entry {0} created',
  'AbnormalPlant.faultReport.damagedDevices': 'Fault Location',
  'Device.select': 'Select',
  'FailteIrelandFeeSchedule.discountFlatFee': 'Discount Flat Fee',
  'mobile.selectMaterialType': 'Select Material Type',
  'customerMobile.workRequest': 'Work Request',
  'resource.duplicateUser': 'User Name already in use by another Technician ',
  'uploadForm.customDate1': '',
  'uploadForm.customDate2': '',
  'uploadForm.customDate3': '',
  'uploadForm.customDate4': '',
  'uploadForm.customDate5': '',
  'js.showAbnormalDeviceStatuses': 'Show Abnormal Device Statuses',
  'Customer.openQuotationCount': 'Open Quotations',
  'mobile.unitPrice': 'Enter Unit Price',
  'button.updateAllPowerFlows': 'Update Power Flows',
  'SwitchingInstruction.switchingInstructionClauseCable':
    '{0} {1} on {2} to {3}. {4} {5} on {6} to {7}',
  'Workflow.error.requiresResolution':
    "You must supply a resolution code to update to '{0}'",
  'Asset.ownerCustomer.description': 'Owner customer',
  'FaultReport.id': 'ID',
  'supportSmsForm.message': 'Support SMS message',
  'userProfile.orgUnits': 'Authorised Organisational Units',
  'FaultReport.voltage': 'Voltage',
  'AssetCategory.title': 'Asset Category',
  'ResourceType.mileageRate': 'Mileage cost',
  'PurchaseOrder.error.receivedSerializedAssetsNotCaptured':
    "Not all serialized assets received have been captured for material '{0}', expected {1}, found {2}",
  '*.resourceFeedback': 'Status Reason',
  'AssetCalendar.title': 'Asset Calendar for ${description}',
  'ScheduledScript.procName': 'Procedure Name',
  'js.makeDefault': 'Make this the Default item',
  'menu.testRESTfulWebService': 'Test RESTful web service',
  'CustomerPortal.viewOpenFaults.title': 'View Open Faults',
  'customerportal.workorderrequests.heading': 'Service Tickets',
  'DeviceType.deviceCategory': 'Device Category',
  'mobile.workOrderListTitle': 'Work Order List',
  'Material.taxCode.code': 'Tax Code',
  'crews.title': 'Crew List',
  'mobile.enterStockCode': 'Enter stock code',
  'signup.message': 'Please enter your user information in the form below.',
  'Asset.movementNotes': '',
  'Resource.attributes': 'Attributes \t',
  'menu.myResources': 'My Resources',
  'dashboardEntry.title': 'Dashboard Title',
  'js.addConnectedDevices': 'Add Connected Devices',
  'errors.noWorkOrderTypeLinkedToWorkRequestType':
    "There is no Work Order Type linked to work request type '{0}' - please fix the config",
  'referenceList.tableName': 'Table Name',
  'mobile.shiftEndTitle': 'Shift End',
  'FaultReport.substation': 'Substation(s)',
  'fieldaccess.title': 'Field Access',
  'ScheduledScript.type': 'Type',
  'userGroup.flags': 'Flags',
  'flashReport.title': 'Flash Report',
  'mobile.listWorkorders': 'List All Work Orders',
  'js.createWorkOrderFromInspectionGroupLink':
    'Create Work Order (Link inspection items)',
  'FaultReport.plannedStart': 'Planned Start',
  'js.createPurchaseOrder': 'Create/Update Purchase Order(s) for Selected',
  PlannedOutage: 'Planned Outage',
  'SchematicDiagram.changedBy': 'Changed By',
  'QueryMapLineLayer.description': 'Description',
  'button.assignment': 'Assignment',
  'js.createWorkOrderForSelectedAssets': '',
  'WorkRequestAutoAllocationRule.sortOrder': 'Sort Order',
  '*.country': 'Country',
  '*.billingView': 'Billing View',
  'AbnormalPlant.linkedObjects': 'Linked Objects',
  'AssetType.priority': 'Priority',
  'Customer.workAddresses': 'Work Site Addresses',
  'FaultReport.workOrder.workRequestCount': 'Call Count',
  'js.pleaseUseSubMenu': 'Please click on one of the sub-menus below',
  'WorkOrderRequest.country': 'Country',
  'Project.heading': 'Project',
  'resource.updated': 'Technician has been updated successfully.',
  'Project.responsibleUser.fullName': 'Responsible Person',
  'Device.depot.description': 'Depot/Area',
  'menu.workflowList': 'Edit Workflow',
  'js.commissionDevice': 'Commission Device',
  'js.assetCode': 'Asset Code',
  'Asset.store': 'Store',
  'assetTemplate.billOfMaterials': 'Bill of Materials',
  'InspectionListItem.cost': 'Cost',
  'Invoice.billOfMaterialsGroup.totalPriceExcl': 'Price Excl',
  'activeUsers.fullName': 'Full Name',
  'invoice.workOrderCode': 'Work Order Number ',
  'customerMobile.status': 'Status',
  'StandardInspectionList.deleted': 'Standard Inspection List Deleted',
  '*.orgUnit.description': 'Org Unit',
  'ResourceShiftRecord.id': 'ID',
  'WorkOrderRequest.address': 'Address',
  'testREST.output': 'Output',
  'PurchaseOrder.customLookup2': '',
  'PurchaseOrder.customLookup3': '',
  'Device.id': 'ID',
  'PurchaseOrder.customLookup1': '',
  'PurchaseOrder.customLookup4': '',
  'PurchaseOrder.customLookup5': '',
  'newuser.email.subject': 'A Forcelink account has been created for you',
  'FaultReport.description': 'Outage description',
  'Contract.title': 'Contracts',
  'menu.viewAssetStores': 'Stores',
  'Contract.contactName': 'Contact Person',
  'js.changeColumnLabel': 'Change Column Label',
  'workorders.workRequest': 'Work Request',
  'customerMobile.quotationRequestViewDetails': 'View Quotation Details',
  'myresources.inactiveView': 'Inactive View',
  'menu.billingadmin.billingDetails': 'Forcelink Billing Details',
  Quotation: 'Quotation',
  'SchematicDiagram.substation': 'Substation',
  'AssetType.blockWork': 'Block work from being done against asset type',
  'mobile.or': 'or',
  'menu.workOrderAutoAssignmentRules':
    'Work Order Auto Resource Assignment Rules',
  'uploadForm.customNumber3': '',
  'uploadForm.customNumber4': '',
  'uploadForm.customNumber5': '',
  'user.added': 'User information for {0} has been added successfully.',
  'WorkOrder.alert.lateStart': 'Work started late',
  'js.enable': 'Enable',
  'mobile.no': 'No',
  'uploadForm.customNumber1': '',
  'uploadForm.customNumber2': '',
  'assets.materialsView': 'Materials View',
  'ResourceType.active': 'Active? ',
  'customerMobile.quotations': 'Quotations',
  'WorkOrder.statusUpdateNotifyMonitorsEmailSubject':
    'Status update on Work Order [{0}]:${status.description}',
  'js.dialog.addDevicesToInterruptionPhase':
    'Add Devices to Interruption Phase',
  'subscriberAutoNumberingSettings.assignCodeAfterSave':
    'Assign code after save?',
  'serializedassetmovements.title': 'Serialised Asset Movements',
  'user.lastName': 'Last Name',
  'Device.validation.allowedParentTypes':
    "A device of Type '{0}' cannot be a child of type '{1}'",
  'userGroups.heading': 'User Groups',
  '*.currency': 'Currency',
  'SLAAuditTrail.violated': 'Violated?',
  'Material.validation.noMaterialFoundForCode': 'Material code not found',
  'incomingsmsmessages.heading': 'Incoming SMS messages',
  'Supplier.customDate1': '',
  'Supplier.customDate2': '',
  'Supplier.customDate3': '',
  'Supplier.customDate4': '',
  'Supplier.customDate5': '',
  'Supplier.customDate6': '',
  'Supplier.customDate7': '',
  'Supplier.customDate8': '',
  'Supplier.customDate9': '',
  DuplicateOf: 'Duplicate of {0}',
  'editStatusProgression.selectToStatus': 'Select To Status',
  'WorkOrder.faultCause.description': 'Fault',
  'DeviceTree.clipboard': 'Clipboard',
  'billofmaterialsitems.PRView': 'Purchase Requisition View',
  'address.address8': 'Postal Code',
  'address.address6': 'Suburb',
  'address.address7': 'City',
  'address.address4': 'Street Number',
  'address.address5': 'Street Name',
  'address.address2': 'Postal Address2',
  'address.address3': 'Building',
  'address.address1': 'Postal Address1',
  'Asset.ownerCustomer.contactPerson': 'Contact Person',
  'referenceList.heading': 'Reference Data',
  'notification.assetStatusBackgroundJobCompleted':
    'Updating of asset statuses in the background has completed. Please refresh to see changes.',
  'WorkOrderRequest.noteAddedEmailSubject': 'Note added to Work Request {0}',
  'menu.viewMaterialMovements': 'Material Movements',
  'button.findOnMap': 'Find on Map',
  'editStatusListForm.verb': 'Verb',
  'js.privWarnAdminCustomerAdmin':
    'You must have ROLE_ADMIN or ROLE_CUSTOMER_ADMIN privileges to use this function',
  'Quotation.billOfMaterialsGroup.totalPriceExcl': 'Price Excl',
  'errors.password.newCannotBeBlank': 'The new password cannot be blank.',
  'mobile.back': 'Back',
  'workflowSelectTypes.selectedTypes': 'Selected Types',
  'js.addStock': 'Add Stock',
  'CustomerPortal.homePage.title': 'Welcome to the Forcelink Customer Portal!',
  'Project.contract': 'Contract',
  'js.selectContractMenu.clearContract': 'Clear Contract',
  'smsLog.heading': 'SMS Log',
  'smartCityPortal.button.login': 'Login',
  'Asset.PONumber': 'Purchase Order Number',
  'BillOfMaterialsItemAsset.pk.bomItem.material.code': 'Material Code',
  deleted: '{0} {1} deleted',
  'ForcelinkPricing.heading': 'Forcelink Pricing',
  'ResourceShiftRecord.shiftStartDate': 'Start Date',
  'Asset.locationAddress.requiresReverseGeocoding': '',
  'js.viewCustomerWorkOrders': 'View Customer Work Orders',
  'FailteIrelandFeeSchedule.taxType.description': 'Tax Type',
  'Quotation.firmScheduledDate': 'Firm Appointmen',
  'js.quickAllocateWorkOrder': 'Quick Allocate Resource',
  'billOfMaterials.material': 'Material',
  'SupportPerson.mobileNumber': 'Mobile Number',
  'menu.smsMessageLog': 'SMS Message Log',
  'MaterialStore.pk.material.serialised': 'Serialised?',
  'CustomManagerImpl_cp_mdt.ticketUpdatedSMSMessage':
    'Joburg Water ticket {0} has been updated {1}',
  'FaultReport.customMultipleChoice5': '',
  'FaultReport.customMultipleChoice4': '',
  'FaultReport.customMultipleChoice3': '',
  'errors.creditcard': '{0} is not a valid credit card number.',
  'FaultReport.customMultipleChoice2': '',
  'FaultReport.customMultipleChoice1': '',
  'mobile.selectMaterial': 'Select Material',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address6': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address5': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address7': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address2': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address1': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address4': '',
  'WorkOrderRequest.customer.parentCustomer.billingAddress.address3': '',
  'button.login': 'Sign In',
  'Workflow.error.requiresEquipment':
    "You must an equipment type asset before you can update to '{0}'.",
  'upload.maxFileSize': '10485760',
  'js.dynamicDescriptionRuleHint':
    'Dynamic text for the description field. You can use properties from the work doc or asset, e.g. ${MobileWorkDoc.code}, ${MobileAsset.assetType.description}. Please note that the Mobile class structures will be used.',
  'js.addDevicesPlanned': 'Add Device(s) (planned)',
  'Customer.auditTrail': 'Audit Trail',
  'Quotation.workRequestType.description': 'Quote Type',
  'SubscriberAutoNumberingSettings.tableName.className': 'Class Name',
  'CustomManagerImpl_cp_mdt.twitterFaultReportConfirmedByOperator':
    'Technicians are onsite attending to an outage at ${station.description}. Start time {startTime}. {shortURL}',
  'crew.inactivated': 'Crew has been de-activated.',
  'js.addChild': 'Add Child',
  'js.matrixGroupBy': 'Group By',
  'BillOfMaterialsItem.miscItemDescription': 'Description',
  'invoice.orderNumber': 'Customer Order Number',
  '404.message':
    'The page you requested was not found.  You might try returning to the <a href="{0}">Main Menu</a>. While you&#39;re here, how about a pretty picture to cheer you up?',
  'js.recalculatingBookValuesInBackground':
    'The Book Values will be re-calcuated in the background',
  'html5.mobile.title': 'Forcelink Mobile',
  'InboxEventUser.removedDate': 'Removed Date',
  'AbnormalPlant.responsibleUser': 'Responsible',
  'workorders.heading': 'Work Orders',
  'TrainingEvent.trainingType': 'Training Type',
  'js.groupSelectedCallsToOutage': 'Group selected calls to outage',
  'ScheduledWorkTemplate.heading': 'Scheduled Work Template',
  'PlannedOutage.plannedRestorationStages': 'Planned Interruption Phases',
  'SubscriberDatabase.description': 'Description',
  'ForcelinkSubcontractor.user.username': 'Username',
  'PurchaseRequisition.customDate10': '',
  'ReferenceList.systemReferenceList.description': 'System Reference List',
  'Building.customNumber10': '',
  'BillOfMaterialsItem.unitType': 'Unit Type',
  'clickstreams.title': 'All Clickstreams',
  'testREST.option.assetTriggers':
    'Multiple Asset Triggers (/rest/assettriggers)',
  'MobileManagerView.filters': 'Filters',
  'material.unitType': 'Unit Type',
  'WorkRequestSubcontractorAssignmentRule.subcontractorFilter.hint':
    "Please supply a comma-separated list of filter criteria (e.g. description like '%Plumbing%')",
  'invoice.quotation': 'Quotation',
  'Asset.material.code': 'Linked Material Code',
  'workorders.quotation': 'Quotation',
  Address: 'Address',
  'PlannedOutage.durationMins': 'Duration',
  'js.cancelSuspectOutage': 'Cancel Suspect Outage',
  '*.audittrail': 'Audit Trail',
  'AuditTrail.fieldName.propertyName': 'Field',
  'js.setResourceOnDuty': 'Set Resource Planned Shift/Leave',
  'mobile.setAvailable': 'Set Availability',
  'AssetType.code': 'Asset Type Code',
  'button.saveAsNew': 'Save as New',
  'WorkOrderAutoAssignmentRule.workOrderSort.hint':
    'Please supply a comma-separated list of sort criteria (e.g. customer.description,dateCreated)',
  'SwitchingInstruction.controller': 'Controller',
  'failteirelandfeeschedules.title': 'Failte Ireland Fee Schedules',
  'FailteIrelandFeeSchedule.dueDate': 'Due Date',
  'FaultReport.error.specifyFaultLocation':
    'Please specify the fault location(s)',
  'WorkOrderRequest.customLookup1': '',
  'WorkOrderRequest.customLookup2': 'Client Technician Responsible',
  'js.quickLookupPlaceholderText': 'Quick lookup (document code)',
  'WorkOrderRequest.customLookup3': '',
  'ReferenceList.custom10': 'custom10',
  'WorkOrderRequest.customLookup4': '',
  'WorkOrderRequest.customLookup5': '',
  'WorkOrderRequest.customLookup6': '',
  'WorkOrderRequest.customLookup7': '',
  'WorkOrderRequest.customLookup8': '',
  'WorkOrderRequest.customLookup9': '',
  'Lease.building.ownerCustomer.description': 'Building Owner',
  'menu.slas': "SLA's",
  'referenceList.title': 'Reference List',
  'Quotation.signoffs': 'Signoffs',
  'smartCityPortal.password': 'Password',
  'AbnormalPlant.heading': 'Abnormal Plant',
  'RuleSelection.rule.parameters': 'Parameters',
  '*.sentToSubcontractorError': "Error sending work to Sub-Contractor '{0}'",
  'resource.grouping.crew': 'Crew',
  'TableValidationRule.dictionaryTable': 'Table',
  'schematicdiagrams.title': 'Schematic Diagrams',
  'WorkOrderRequest.workGeometry': 'Work Geometry',
  'js.assignToMeLabel': 'Assign to Myself',
  'js.clearAllNotifications': 'Clear All Notifications',
  'MobileManagerView.dictionaryTable': 'Doc Type',
  'menu.editStandardInspectionList': 'Standard Insp. Lists',
  'PlannedOutage.status': 'Status',
  'js.createQuotationForWO': 'Create Quotation for Work Order',
  'button.viewResourceEta': 'View Resource On Map',
  'js.selectAgent': 'Select Agent',
  'menu.killRunningThread': 'Kill Running Thread (use with caution!)',
  'fieldAccess.edit': 'Edit',
  'OrgStructure.locationAddress.geom': 'Show Polygon',
  'js.selectLoadCentre': 'Select Load Centre',
  'errors.conversion':
    'An error occurred while converting web values to data values.',
  'referencelist.customFieldView': 'Custom Field View',
  'SubscriberDatabase.subdomain': 'Subdomain',
  'ResourceType.hourlyChargeRate': 'Hourly Rate',
  'WorkCalendarMatrixView.heading': 'Work Calendar Matrix View',
  'js.addSelectedAssets': 'Add Selected Assets',
  '*.material.unitType.description': 'Unit Type',
  'WorkOrderRequest.noteAddedNotifyMonitorsEmailBody':
    'Dear {0},\n\nNote added at {1}:\n\n{2}\n\nLink to Work Request:{3}\n\nRegards, Forcelink',
  'PlannedOutage.substation': 'Substation',
  'js.receiveSelectedLineItemsNote':
    'Please note that the full quantity will be duplicated to the new transaction type. If partial quantities are required, please perform this operation on one BOM line item at a time.',
  'ScheduledWorkTemplate.periodExpr': 'Period Expression',
  'CustomManagerImpl_cp_mdt.twitterFaultReportConfirmedBySCADA':
    'We are aware of an outage at ${station.description}. Start time {startTime}. {shortURL}',
  code: 'Code',
  'AssetTree.title': 'Asset Tree',
  'Lease.type': 'Type',
  'js.addSwitchingEvent': 'Add SCADA Switching Event',
  'js.deleteImage': 'Delete Image',
  'stocktakeexpecteditems.captureFoundQuantitiesView':
    'Capture Found Quantities View',
  'WorkOrderAutoAssignmentRule.resourceSort': 'Resource Sorts',
  'menu.support': 'Support',
  'menu.testHQLStatement': 'Test HQL statement',
  'testREST.input': 'Input',
  'js.addPlannedLocalSwitchingEvent': 'Add Planned Local Switching Event',
  'AbnormalPlant.customLookup10': '',
  'BillOfMaterialsItem.totalPrice': 'Total Price\t',
  'faultreports.exportView': 'Unfiltered Export View',
  'mobile.inspectionListItem.cost': 'Cost',
  'purchaseOrder.status': 'Status',
  'ScheduledWorkTemplate.inspectionListGroup': 'Standard Inspection List',
  'SwitchingInstruction.abnormalPlant': 'Abnormal Plant',
  'Quotation.status.description': 'Status',
  'Customer.user.smsAlertsEnabled': 'Enable SMS alerts',
  'WorkOrderRequest.actualDurationHours': 'Actual Duration(H)',
  'js.updateDescription': 'Change Description',
  'QueryMapLayer.keyIconPath': 'Key Icon Path',
  '*.orgUnits': 'Organisational Units',
  'resource.title': 'Technician ',
  'Quotation.customerContactInfo': 'Customer Contact',
  'workorderautoassignmentrule.heading':
    'Work Order Automatic Resource Assignment Rule',
  'MaterialStore.pk.store.description': 'Store',
  'user.orgUnits': 'Organisational Units',
  'WorkRequestAutoAllocationRule.workRequestFilter': 'Work Request Filters',
  'InspectionListItem.required': 'Required?',
  'WorkOrder.faultCauseCategory.description': 'Fault Category',
  'FailteIrelandFeeSchedule.flatFee': 'Flat Fee',
  'ForcelinkSubcontractor.matchWorkExpression.hint':
    'An EL-format boolean expression that will be evaluated against work requests when outsourcing. If the expression returns true, the subcontractor will be highlighted in green.',
  'Workflow.error.requiresChildrenClosed':
    'All Child Work Orders must be closed. {0} is still open.',
  'FaultReport.customLookup6': '',
  'availabilityCalendar.added': 'Shift Roster has been added successfully.',
  'FaultReport.customLookup5': '',
  'FaultReport.customLookup4': '',
  'WorkOrderRequest.locationAddress7': 'City',
  'FaultReport.customLookup3': '',
  'WorkOrderRequest.locationAddress8': 'Postal Code',
  'WorkOrderRequest.locationAddress5': 'Street Name',
  'FaultReport.customLookup9': '',
  'WorkOrderRequest.locationAddress6': 'Suburb',
  'Workflow.table': 'Document Type',
  'FaultReport.customLookup8': '',
  'WorkOrderRequest.locationAddress3': 'Building',
  'FaultReport.customLookup7': '',
  'WorkOrderRequest.locationAddress4': 'Street Number',
  'menu.PowerLink': 'Powerlink',
  'js.reloadFromQuotation': 'Re-load from Quotation',
  'Asset.validation.address': 'Address is required for asset',
  'js.moveSelectedEquipmentToStore':
    'Transfer selected Equipment to another Store',
  'WorkOrder.updated': 'Work Order {0} has been updated.',
  'WorkOrderRequest.locationAddress1': 'Postal Address1',
  'WorkOrderRequest.locationAddress2': 'Postal Address2',
  'FaultReport.customLookup2': '',
  'FaultReport.customLookup1': '',
  'FailteIrelandFeeSchedule.customerType': 'Premise Type',
  'login.portalTitle': '',
  'DashboardEntry.groups': 'User Groups',
  'js.multipleOrgSelected': 'Work Requests with different Org Units selected',
  'js.pleaseSelectOrgUnit': 'Please select the Org Unit for the shift',
  'js.setResourceAvailable': 'Set Resource to Available',
  'js.reloadInspFromAsset': '(Re)load from Asset',
  'SLA.active': 'Active? ',
  'resource.notificationEmailSubject': 'Forcelink notification',
  'SupplierOrgUnit.supplier': 'Supplier',
  'FaultReport.rootCause.description': 'Root Cause',
  'WorkOrderAsset.pk.asset.code': 'Asset Code',
  'workflowSelectTypes.availableTypes': 'Available Types',
  'AbnormalPlant.device.substation': 'Substation',
  'material.stock': 'Stock',
  'menu.incomingSMSMessages': 'Incoming SMS Messages',
  'js.scheduledWorkTemplateName': 'Template',
  'WorkOrder.message.withdrawn': 'Work Order {0} withdrawn from you.',
  'testREST.triggerType': 'Trigger Type',
  'WorkRequestAutoAllocationRule.heading': 'Work Request Auto Allocation Rule',
  'StockTake.completedBy': 'Completed By',
  'customerMobile.heading': 'Customer Details',
  'js.zoomIn': 'Zoom In',
  'InspectionListGroup.heading': 'Standard Inspection List',
  'Invoice.customLookup1': '',
  'Invoice.customLookup2': '',
  'Invoice.customLookup3': '',
  'button.selectFields': 'Select fields',
  'Invoice.customLookup4': '',
  'Invoice.customLookup5': '',
  'WorkOrder.linkedObjects': 'Linked Work Requests, Quotations, Invoices',
  'SLA.durationAfterHours': 'Duration After Hours (Minutes)',
  'fieldAccess.requiredIfRule.title':
    'A boolean expression referencing fields EL ${field} format. These fields must be available on the page.',
  'BillOfMaterialsGroup.endDate': 'End Date',
  'js.confirmRestartStockTake':
    'Restarting the stock take will clear all the items already captured. Are you sure you want to continue? ',
  'WorkDoc.workType': 'Work Type',
  'personaldataexpiryrules.heading': 'Personal Data Expiry Rules',
  'InspectionListWizard.template': 'Template',
  'js.receiveBOMItemDialogTitle': 'Receive/Return BOM Item',
  'assetscheduledworktemplates.title': 'Assigned Scheduled Work Templates',
  'resource.mileageChargeRate': 'Unit Mileage Charge-Out Rate',
  none: 'None',
  'WorkOrderRequest.message.multipleAllocated':
    '{0} Work Requests have been allocated to you.',
  'FlashReport.faultReport.department.description': 'Department',
  'Asset.partNumber': 'Product Code',
  'WorkOrder.province': 'Province',
  'Quotation.images': 'Images',
  'globalSettings.coRegNoName': 'Co Reg No',
  'subscriberDatabase.dataSourceSchema': 'Data Source Schema',
  'AssetScheduledWorkTemplateXref.lastScheduledDate': 'Last Sched. Date',
  'errors.password.notMobileRole':
    'User does not have access to ROLE_MOBILE role. ',
  'Asset.ownerCustomer.customLookup4': '',
  'js.editValuesInTable': 'Edit values in table',
  'Asset.ownerCustomer.customLookup5': '',
  'Asset.ownerCustomer.customLookup2': '',
  'menu.admin.users': 'Users',
  'Asset.ownerCustomer.customLookup3': '',
  'referenceList.added': 'Reference has been added successfully.',
  'Asset.ownerCustomer.customLookup1': '',
  'js.selectAssetsWithScanner': 'Select assets with scanner',
  'js.removeStockTakeFoundItem': 'Remove item',
  'smartCity.passwordReset.email.subject': 'SmartCity password reset',
  'PurchaseOrder.error.quantityOrderedDiffersFromQuantityDelivered':
    "Quantity delivered differs from quantity ordered for '{0}'",
  'Project.startDate': 'Start Date',
  'Asset.ownerCustomer.customLookup8': '',
  'Asset.ownerCustomer.customLookup9': '',
  'Asset.ownerCustomer.customLookup6': '',
  'Asset.ownerCustomer.customLookup7': '',
  'SchematicDiagram.plannedOutage': 'Planned Outage to make active',
  'userProfile.showMore': 'View More Information',
  'WorkOrder.notes': 'Work Log',
  'CustomManagerImpl_etc.noMoreShiftsMessage':
    '{0}, you have no more shifts until {1}',
  'invoice.createdDateTime': 'Date and Time Created',
  'AssetType.custom2': '',
  'AssetType.custom1': '',
  'AssetType.custom4': '',
  'FaultReport.protTeamLeaderDate': 'Approved Date',
  'AssetType.custom3': '',
  'DeviceTypes.heading': 'Device types',
  'Material.unitPrice': 'Unit Price',
  'mobile.advancedSearchWorkOrderRequest': 'Advanced Search',
  'WorkImage.timeStamp': 'Date/Time',
  'js.confirmCancelSelectedWorkRequests':
    'Are you sure you want to cancel the selected Work Orders?',
  'js.pasteScheduledWorkOrderRequests': 'Paste Selected Work Requests',
  'UserGroup.billingType.description': 'Billing Type',
  'AssetType.custom5': '',
  'Device.updated': 'Device {0} Updated',
  'Contract.code': 'Contract No',
  'WorkOrder.message.allocateSMS': 'Work Order ${code} allocated to you. {0}',
  'PurchaseOrder.invoiceTotal': '',
  'ResourceChat.user': 'User',
  'DeviceType.active': 'Active? ',
  'menu.editRuleSelections': 'Rules',
  'BillOfMaterialsItemAsset.pk.bomItem.transactionType.description':
    'Transaction Type',
  'button.view': 'View',
  'ForcelinkSubcontractor.description': 'Name',
  'customer.notes': 'Notes',
  'QueryMapLayer.title': 'Query Layer Details',
  'Customer.customNumber10': '',
  'AssetCategory.deleted': 'Asset Category {0} Deleted',
  'BillOfMaterialsItemAsset.pk.bomItem.unitType.description': 'Unit Type',
  'Asset.validation.ownchild': "Asset cannot be it's own child",
  'WorkOrder.customTransient10': '',
  'js.outsourceToSubcontractorButton': 'Outsource to Subcontractor',
  '*.requiresReverseGeocoding': 'Requires Reverse Geocoding?',
  'WorkOrder.customTransient15': '',
  'WorkOrder.customTransient12': '',
  'WorkOrder.customTransient11': '',
  'resource.groupingFilter.crewsAndStandalone':
    'Crews and Standalone Resources',
  'WorkOrder.customTransient14': '',
  'WorkOrder.customTransient13': '',
  'smartCityPortal.registerHint':
    'Not yet registered? Click below to create a profile',
  'mobile.pleaseCallMe': 'Please Call Me',
  'notificationRule.triggerType': 'Trigger Type',
  'company.name': 'Tasima (Pty) Ltd',
  'WorkOrderRequest.withdrawnFromSubcontractor': 'Withdrawn From Subcontractor',
  'FlashReport.faultReport.depot.description': 'Depot/Area',
  'availabilitycalendar.heading': 'Shift Rosters',
  'resource.orgUnitsInfo':
    'The Resource is associated with these Organisational Units. An empty list here means that the resource is associated with all org units.',
  'Project.customer.description': 'Customer',
  'QueryMapLayer.className': 'Table Name',
  'js.deleteSupplierQuotation': 'Delete Supplier Quotation',
  'billOfMaterials.subTotal': 'Sub Total:',
  'passwordReset.otp': 'One-time-PIN',
  tuesday: 'Tuesday',
  'js.addSubGroup': 'Add Sub-Group',
  'js.editSupplierQuotationError':
    'The Quotation Number, Supplier, Total Excl. VAT and New Status are required',
  'ScheduledWorkTemplate.description': 'Template Name',
  'ruleParameters.title': 'Rule Parameters',
  'js.setSelectedResourcesOnDuty': 'Set Selected Resources Planned Shift/Leave',
  'errors.login.NewPasswordInvalidException':
    'The new password does not fit the requirements:{0}',
  'SupportPerson.password': 'Password',
  'resourceType.resourceCategory': 'Resource Category',
  'FaultReport.status.description': 'Status',
  'CustomerPortal.homePage.logFaultOtherAddress': 'Log a Fault (Other Address)',
  'SupplierOrgUnit.custom2': '',
  'SupplierOrgUnit.custom1': '',
  'SupplierOrgUnit.custom4': '',
  'SupplierOrgUnit.custom3': '',
  'mobile.listActiveQuotations': "List Active Quote Req's",
  'SupplierOrgUnit.custom5': '',
  'js.requiresNoteIfRuleHint':
    "The item will require a note if the condition is true either in this field or in another field in the list. Text must be formatted as follows: ${value}=='<a value to check for in this field>' && ${<field id value to look up>}=='<value to check for>' && ${<another field id value to look up>}=='<value to check for>' || (<more criteria>), etc",
  'PersonalDataExpiryRule.dateReferenceProperty': 'Date reference property',
  'Supplier.contactEmail': 'Contact Email',
  'BillOfMaterialsItem.billOfMaterialsGroup.startDate': 'Start Date',
  'Asset.custom20': '',
  'Asset.cycleCounter': 'Cycle Counter',
  'orgStructure.orgUnitType': 'Unit Type',
  'FaultReport.smsMessage.LoadShedding':
    'Load Shedding: ${code} on {0} ${status.description} {2}',
  'ArcGISLayer.sortOrder': 'Sort Order',
  'AssetCalendar.WorkOrder.backgroundColor': '#cccc00',
  'ShiftType.startTime': 'Start Time',
  'js.search': 'Search',
  'mobile.listQuotations': "List All Quote Req's",
  'Asset.custom10': '',
  'Asset.custom11': '',
  'errors.circularReference': 'There is a circular reference in the data',
  'Project.newStatus': 'New Status',
  'User.dateDisabled': 'Date Disabled',
  'Store.custom10': '',
  'Store.custom11': '',
  'Store.custom12': '',
  'menu.workRequestMatrix': 'Work Request Matrix',
  'materialstore.heading': 'Material Stock',
  'Asset.custom12': '',
  'Asset.custom13': '',
  'Asset.custom14': '',
  'Asset.custom15': '',
  'Asset.custom16': '',
  'js.email': 'Email',
  'Asset.custom17': '',
  'Asset.custom18': '',
  'Asset.custom19': '',
  'js.addTrippedDevice': 'Add Tripped Device',
  'js.partiallyRestoreDevices': 'Partially Restore Device(s)',
  'Asset.locationAddress.description': 'Description',
  'billOfMaterials.discountPercent': 'Discount(%):',
  'AbnormalPlant.customNumber6': '',
  'Store.custom17': '',
  'AbnormalPlant.customNumber5': '',
  'Store.custom18': '',
  'mobile.customer.homeNumber': 'Home Number',
  'AbnormalPlant.customNumber8': '',
  'Store.custom19': '',
  'FaultReport.damagedDevices': 'Fault Location',
  'address.latLong': 'Lat/Long',
  'AbnormalPlant.customNumber7': '',
  'Store.custom13': '',
  'AbnormalPlant.customNumber9': '',
  'Store.custom14': '',
  'Store.custom15': '',
  'Store.custom16': '',
  'Store.custom20': '',
  'assetstock.parentAsset.description': 'Store Name',
  'AbnormalPlant.customNumber2': '',
  'FailteIrelandFeeSchedule.feeType': 'Fee Type',
  '*.formattedLatLong': 'Lat/Long',
  'AbnormalPlant.customNumber1': '',
  'AbnormalPlant.customNumber4': '',
  'Quotation.priority.description': 'Priority',
  'AbnormalPlant.customNumber3': '',
  'js.resizeBlock': 'Resize Block',
  'Quotation.statusUpdateNotifyMonitorsEmailBody':
    'New Status:{1}\nQuotation Description:{0}\n\nLink to Quotation:{2}\n\nRegards, Forcelink',
  'PurchaseRequisition.contactEmail': 'Contact Email',
  'js.moreOptions': 'More Options...',
  'js.deallocateResourcesFromSelected':
    'De-Allocate all Resources from Selected',
  'Customer.enabled': 'Enabled',
  'taxTypes.title': 'Tax Types',
  'CustomTableColumnSelection.heading': 'Custom Table Column Selection',
  'TableValidationRule.dictionaryTable.className': 'Table',
  'errors.subscriberId.required': 'Subscriber ID is a required field.',
  'WorkOrderRequest.viewBillOfMaterials': 'View Bill Of Materials',
  'js.totalExclVAT': 'Total Excl. VAT',
  'Scheduler.viewPeriod': 'Period',
  '*.error.noCreatedByCustomerStatus':
    "Error: No status 'CREATED_BY_CUSTOMER' exists - please contact your administrator",
  'DeviceCategory.depreciationRate': 'Depreciation Rate',
  'QueryMapLayer.geomFillColour': 'Geometry Fill Colour',
  'js.setNameToAvailable': 'Set {0} to Available?',
  'js.rectangleInstructions':
    'left-click to define the boundaries of the rectangle, press ESC to cancel.',
  'LoadShedding.status.open': 'Open?',
  'customerMobile.workOrderStatus': 'Work Order Status',
  'SpatialLayer.title': 'Spatial Layer',
  'AssetTemplate.externalId': '',
  'ResourceType.labourServices': 'Labour / Services',
  'WorkOrderRequest.customDate6': '',
  'WorkOrderRequest.customDate5': '',
  'WorkOrderRequest.customDate4': '',
  'WorkOrderRequest.customDate3': '',
  'workOrderRequest.monitors': '',
  'WorkOrderRequest.customDate9': '',
  'ActiveSessionInfo.subscriberId': 'Subscriber Id',
  'WorkOrderRequest.customDate8': '',
  'menu.user': 'Edit Profile',
  'WorkOrderRequest.customDate7': '',
  'workOrderMobile.noActiveWorkorders': 'There are no active Work Orders.',
  'workOrderRequestMobile.title': 'Quotation',
  'js.confirmSetResourceToUnavailable': "Set Resource to 'Unavailable'?",
  'customers.title': 'Customer List',
  'WorkOrder.hierarchy': 'Hierarchy',
  'WorkOrderRequest.customDate2': 'Purchase Order Received Date',
  'WorkOrderRequest.customDate1': 'Quotation Sent to Customer Date',
  'Asset.warrantyVendor': 'Warranty Vendor',
  'login.passwordReset.sent':
    'The password reset for {0} has been sent to {1}.',
  'errors.double': '{0} must be a double (decimal number).',
  'call.customer.description': 'Customer Name',
  'scheduler.options': 'Scheduler Options',
  'IncomingSMSMessage.workRequest.status.description': 'Work Request Status',
  'PurchaseRequisition.contactName': 'Contact Person',
  'js.statusChangeSuccessMessage': 'The status has been successfully updated',
  'mobile.updateStatus': 'Update Status',
  'button.done': 'Done',
  'User.autoCompleteSearchResult': '${fullName}',
  'locationAddress.address': 'Address',
  'resource.crew': 'Crew',
  'uploadForm.file': 'File to Upload',
  'js.moveAssetToStore': 'Transfer Equipment to Store',
  'maintenanceplan.heading': 'Maintenance Plan',
  'Contract.customMultipleChoice1': '',
  'Contract.customMultipleChoice5': '',
  'Contract.customMultipleChoice4': '',
  'Contract.customMultipleChoice3': '',
  'Contract.customMultipleChoice2': '',
  'WorkOrderRequest.imageNo': 'Image',
  'WorkOrder.customNumber18': '',
  'WorkOrder.customNumber19': '',
  'WorkOrder.customNumber16': '',
  'WorkOrder.customNumber17': '',
  'FaultReport.trippedDevices': 'Tripped Device(s)',
  'SwitchingInstruction.notes': 'Notes',
  'js.showHiddenFields': 'Show Hidden Fields',
  'Quotation.status': 'Status',
  '*.contactPerson.officeNumber': 'Office Number',
  'WorkOrder.customNumber10': '',
  'WorkOrder.customNumber11': '',
  'WorkOrder.customNumber14': '',
  'restoredevices.heading': 'Restore Devices',
  'FlashReport.viewEditFaultReport': 'View/Edit Outage',
  'resource.resourceType': 'Technician Type',
  'WorkOrder.customNumber15': '',
  'WorkOrder.customNumber12': '',
  'WorkOrder.customNumber13': '',
  'button.captureItems': 'Capture Items',
  'address.added': 'Address has been added successfully.',
  'Crew.custom10': '',
  'mobile.availUntil': 'until {0}',
  'WorkOrderAsset.pk.asset.assetType.code': 'Asset Type',
  'Asset.batchAddMode.autoCodesMode': 'Auto generate codes',
  'InspectionListGroup.description': 'Group Name',
  'Quotation.billOfMaterialsGroup.formattedTotalPriceIncl': 'Price Incl',
  'menu.viewBuildings': 'Buildings',
  'Asset.purchaseCost': 'Purchase Cost',
  'js.closeWorkOrdersForRestoredOutage': 'Close work orders for this Outage?',
  'globalSettings.phone': 'Phone',
  monday: 'Monday',
  'menu.FlashReports': 'Flash Reports',
  'js.schematicDiagramSymbolLegend': 'Schematic Symbol Legend',
  'customer.interactions': 'Interactions',
  'StockTake.notApplicableCheckedByQuantity': 'N/A, checked by quantity',
  'forcelinksubcontractors.heading': 'Subcontractors (using Forcelink)',
  'Contract.status.description': 'Status',
  'material.reorderQuantity': 'Reorder Quantity',
  'js.warrantyDateRule': 'Warranty Start',
  'WorkOrder.customNumber20': '',
  'Device.circuit.description': 'Circuit',
  'editStatusProgression.selectFromStatus': 'Select From Status',
  'BillOfMaterialsItem.customLookup1': '',
  'BillOfMaterialsItem.customLookup3': '',
  'BillOfMaterialsItem.customLookup2': '',
  'BillOfMaterialsItem.customLookup5': '',
  'menu.publicHolidays': 'Public Holidays',
  'customerpurchaserequisitions.heading': 'Purchase Requisitions',
  'BillOfMaterialsItem.customLookup4': '',
  'user.username': 'Username',
  'js.loadingDashboardKPIs': "Loading Dashboard KPI's",
  'js.viewSchematicDiagram': 'View Schematic Diagram',
  'mobile.quotations': 'Quotations',
  'FaultReport.remarks': 'Remarks',
  'AuditTrail.tableKey': 'Table Row ID',
  'WorkOrderRequest.viewCustomer': 'View Customer',
  'Project.projectType': 'Project Type',
  'user.address.postalCode': 'Postal Code',
  'AssetTree.heading': 'Asset Tree',
  'Resource.IMEI': 'IMEI',
  'TaxType.country.description': 'Country',
  'AbnormalPlant.handBackBy': 'Hand Back By',
  'TaxType.description': 'Description',
  'js.quotation': 'Quotation',
  'subscriberCustomFieldLabelsDetail.title': 'Custom Field Detail',
  'subscriberDatabase.heading': 'Subscriber Database',
  'PurchaseInvoice.totalExclVAT': 'Total Excl. VAT',
  friday: 'Friday',
  'FailteIrelandFeeSchedule.invoiceNarrative': 'Invoice Narrative',
  'Document.filename': 'File Name',
  'js.confirmCancelSelectedWorkorders':
    'Are you sure you want to cancel the selected Work Orders?',
  'js.createLinkToAnotherDiagram': 'Create Link to another Diagram',
  'menu.viewAssetTypes': 'Asset Types',
  'DeviceType.updated': 'Device Type {0} Updated',
  'InboxEventUser.pk.user.fullName': 'Full Name',
  'js.notSelected': 'Not Selected',
  'VersionInfo.versionNo': 'Version No',
  'js.streetLayer': 'Detailed Street View',
  'flashReport.heading': 'Flash Report',
  'WorkOrderRequest.newStatus': 'New Status\t',
  'User.custom1': '',
  'User.custom2': '',
  'User.custom3': '',
  'menu.viewDocuments': 'Documents',
  'PlannedOutage.title': 'Planned Outage',
  'WorkOrder.country': 'Country',
  'QueryMapLayer.sqlTitle':
    'SQL must return columns id,code,description,latitude,longitude,iconUrl and must take parameters for maxLat,maxLong,minLat,minLong',
  'invoice.locationAddress': 'Work Location Address',
  'Warranty.durationDays': 'Duration (Days)',
  'subscriberAutoNumberingSettings.codeNextValue': 'Code Next Value',
  'CustomerPortal.login.passwordHint':
    'Forgot your password?  Have your <a id="passwordHint" title="Have your password hint sent to you." >password hint SMS\'d to you</a> or <a id="passwordReset" title="Reset your password.">Reset</a> it',
  'Resource.customDate8': '',
  'User.trainingEvents': 'Training Events',
  'menu.materialMatrix': 'Material Matrix',
  'Resource.customDate7': '',
  'Resource.customDate9': '',
  'configauditlog.heading': 'Configuration Audit Log',
  'billOfMaterials.added': 'Bill Of Materials has been added successfully.',
  'WorkOrder.alert.assignmentNotAcknowledged':
    'Work Assignment has not been acknowledged by Technician',
  'FlashReport.protectionSummary': 'Protection Summary',
  'Resource.customDate2': '',
  'Resource.customDate1': '',
  'Resource.customDate4': '',
  'Resource.customDate3': '',
  'Resource.customDate6': '',
  'Resource.customDate5': '',
  'purchaseOrder.dateCreated': 'Date Created',
  'User.callbackSubscriberId': 'Callback Subscriber ID',
  'js.viewSupplier': 'View Supplier',
  'js.pasteChildCustomers': 'Paste Child Customer(s)',
  'Device.audittrail': 'Device Audit Trail',
  'menu.stockTake': 'Stock Takes',
  'QuickLaunch.admin': 'User Admin',
  'Customer.workHistory': 'Work History',
  'QueryMapLayer.geomShowOnLoad': 'Show Geometry on Load',
  'billOfMaterialsList.heading': 'Bill Of Materials',
  'User.custom4': '',
  'User.custom5': '',
  'AssetAttribute.asset': 'Asset',
  'PurchaseRequisition.dateCreated': 'Date Created',
  'resource.added': 'Technician has been added successfully.',
  'CustomReferenceList.treeDescription': 'Hierarchy',
  'js.deleteLabel': 'Delete',
  'mobile.invalidOTP': 'Invalid OTP, please try again',
  'Asset.linkedCustomers': 'Linked Customers',
  'material.description': 'Description',
  'LoadShedding.customMultipleChoice5': '',
  'LoadShedding.customMultipleChoice4': '',
  'LoadShedding.customMultipleChoice3': '',
  'StandardInspectionList.updated': 'Standard Inspection List Updated',
  'LoadShedding.customMultipleChoice2': '',
  'LoadShedding.customMultipleChoice1': '',
  'smartCityPortal.username': 'Username',
  'workOrderMobile.noWorkorders': 'There are no Work Orders to display.',
  'Asset.locationAddress.country': 'Country',
  'addressList.title': 'Addresses',
  'StockTake.error.assetExistsButNotInStock':
    'Found asset ({0}:{1}), but it is a stand-alone asset not in stock',
  'js.finishedRecalculatingBookValues': 'Finished Recalculating book values',
  'workflow.updated': "Work Flow '{0}' updated",
  'SLA.customMessage':
    'An EL Expression for Custom Message Notifications, Blank uses Default Message.',
  'menu.viewAutoNumberingSettings': 'Auto Numbering',
  'label.username': 'Username',
  'js.reasonForStockChange': 'Reason for Change',
  'signup.email.message':
    'You have successfully registered for access to AppFuse.  Your username and password information is below.',
  'customer.address': 'Customer Address',
  'supportSmsForm.success': 'Support SMS sent successfully.',
  'js.createSMSCampaignForOutage': 'Create SMS Campaign for Outage',
  'InternationalLabel.language': 'Language',
  'button.createWorkOrder': 'Create Work Order',
  'QueryMapLineLayer.sqlTitle':
    'SQL must return columns fromId,toId,lineColour and must take parameters for maxLat,maxLong,minLat,minLong',
  'menu.clickstream': 'Clickstream',
  'globalSettings.id': 'Id',
  'SchematicDiagram.activeVersion': 'Active Version?',
  'Resource.shiftTypes': 'Shift Types',
  'resource.resourceCategory': 'Resource Category',
  'upload.message':
    'Note that the maximum allowed size of an uploaded file for this application is 10 MB.',
  'InspectionListItem.largeFormat.description': 'Description',
  'IncomingSMSMessage.status.description': 'Status',
  'SLA.notificationListTitle':
    'Enter a comma-separated list of e-mail addresses and mobile numbers to be notified when the SLA is violated',
  'WorkOrderRequest.statusUpdateNotifyMonitorsEmailBody':
    'New Status:{1}\nTicket Description:{0}\n\nLink to Work Request:{2}\n\nRegards, Forcelink',
  'Asset.locationAddress.geomAreaHa': '',
  'menu.workCalendarMatrix': 'Work Calendar Matrix',
  'QueryMapLineLayer.lineColourExpr': 'Line Colour Expression (EL)',
  'customerportal.mainMenu.createNewTicketOtherAddress':
    'Create a new ticket (other address)',
  'js.addDamagedDevice': 'Add Damaged Device',
  'faultreports.unfilteredView': 'Unfiltered View',
  'js.viewMaterialMovements': 'View Material Movements',
  'AbnormalPlant.editingExisting':
    'There is still an open existing Abnormal Plant record for this device, opening for edit',
  'ScheduledWorkTemplate.workOrderType': 'Work Order Type',
  'User.customNumber2': '',
  'User.customNumber1': '',
  'User.customNumber4': '',
  'User.customNumber3': '',
  'RelayFailure.make': 'Make',
  'Contract.customNumber8': '',
  'QuickLaunch.workorders': 'Work Orders',
  'Contract.customNumber9': '',
  'User.customNumber5': '',
  'Contract.customNumber6': '',
  'Contract.customNumber7': '',
  'Device.code': 'Device Code',
  'Workflow.error.invalidWorkType':
    "Work type '{0}' is not valid for this process flow.",
  'Contract.customNumber1': '',
  'button.addUsingWizard': 'Add Using Wizard',
  'Contract.customNumber4': '',
  'ResourceShiftRecord.custom4': '',
  'Contract.customNumber5': '',
  'ResourceShiftRecord.custom5': '',
  'Contract.customNumber2': '',
  'ResourceShiftRecord.custom2': '',
  'Contract.customNumber3': '',
  'ResourceShiftRecord.custom3': '',
  'SuperadminUser.enabled': 'Enabled',
  'ResourceShiftRecord.custom1': '',
  'menu.mobileManagerViews': 'Mobile Manager Views',
  'js.editDashboardEntry': 'Edit Dashboard Entry',
  'WorkOrderAsset.pk.workOrder.priority.description': 'Priority',
  '*.documents': 'Documents',
  'Customer.customLookup29': '',
  'Customer.customLookup28': '',
  'orgStructure.parentOrgId': 'Parent Org',
  'Customer.customLookup27': '',
  'Customer.customLookup33': '',
  'Customer.customLookup32': '',
  'Customer.customLookup31': '',
  'Customer.customLookup30': '',
  'Customer.customLookup37': '',
  'Customer.customLookup36': '',
  'Customer.customLookup35': '',
  'Quotation.add': 'Add',
  'Customer.customLookup34': '',
  'TrainingEvent.custom2': '',
  'WorkOrderRequest.timeSlot': '',
  'TrainingEvent.custom1': '',
  'js.imageProperties': 'Image Properties',
  'TrainingEvent.custom5': '',
  'TrainingEvent.custom4': '',
  'TrainingEvent.custom3': '',
  'billOfMaterialsMobile.title': 'Bill Of Materials',
  'js.portalUserAlreadyRegistered':
    'This user name is already registered - would you like to reset the password?',
  'js.batchAddChildAssets': 'Batch Add Child Assets',
  'Customer.customLookup39': '',
  'Customer.customLookup38': '',
  'Contract.billOfMaterials': 'Bill of Quantities',
  'LoadShedding.trippedDevices': 'Plant to isolate from',
  'PurchaseOrder.error.noStockReceived':
    "No stock has been received, please receive stock in the 'Bill Of Materials' section",
  'js.invalidNumber': 'Invalid Number',
  'mobile.statusPreRequisite.inspectionCompleted':
    "All inspections must be completed before going to status '{0}'",
  'ResourceChat.delivered': 'Delivered?',
  'js.linkChildAssetInspectionRuleHint':
    "The item will link in te inspection group from a child asset if the condition is true either in this field or in another field in the list. Text must be formatted as follows: ${value}=='<a value to check for in this field>' && ${<field id value to look up>}=='<value to check for>' && ${<another field id value to look up>}=='<value to check for>' || (<more criteria>), etc",
  'Customer.customLookup40': '',
  'Invoice.margin': 'Margin',
  'Invoice.customDate1': '',
  'workOrderRequest.monitorGroups': 'Monitor Groups',
  'Invoice.customDate3': '',
  'Invoice.customDate2': '',
  'Invoice.customDate5': '',
  'Invoice.customDate4': '',
  'Customer.customLookup11': '',
  'SwitchingInstruction.customBoolean5': '',
  'Customer.customLookup10': '',
  'StockTake.error.consumableBinCodeNotExpected':
    'Found consumable material bin code ({0}), but none of this material was expected in this store',
  'Customer.customLookup15': '',
  'SwitchingInstruction.customBoolean1': '',
  'Customer.customLookup14': '',
  'SwitchingInstruction.customBoolean2': '',
  'Customer.customLookup13': '',
  'SwitchingInstruction.customBoolean3': '',
  'Customer.customLookup12': '',
  'Contract.customer.billingAddress.addressString': 'Customer Address',
  'SwitchingInstruction.customBoolean4': '',
  'materials.title': 'Material List',
  'SwitchingEvent.deviceDescription': 'Device',
  'BillOfMaterialsItemAsset.pk.bomItem.billOfMaterialsGroup.parentDType':
    'Doc Type',
  'Customer.name': 'Name',
  'CallTake.notes': 'Notes',
  'Device.deviceType.description': 'Device Type',
  'WorkOrder.custom.address.province': '',
  'user.address.city': 'City',
  'Customer.customLookup19': '',
  'Customer.customLookup18': '',
  'Customer.customLookup17': '',
  'Customer.customLookup16': '',
  'Workflow.types': 'Work Types',
  'menu.quotationMatrix': 'Quotation Matrix',
  'Customer.customLookup22': '',
  'Customer.customLookup21': '',
  'Customer.customLookup20': '',
  'Customer.customLookup26': '',
  'Customer.customLookup25': '',
  'workOrderMobile.noCustomers': 'There are no Customers to display.',
  'Customer.customLookup24': '',
  'Customer.customLookup23': '',
  'inbox.workOrders': 'Work Orders',
  'WorkOrderAutoAssignmentRule.activeFromHHMM': 'Active From (HH:MM)',
  '*.selectedQuantity': 'Quantity',
  'AssetType.assetCategory': 'Asset Category',
  'mobile.setAvailableTitle': 'Set Availability',
  'js.viewInAssetTree': 'View in Asset Tree',
  'js.sendMessageToSelectedResource': 'Send Message to selected Technicians',
  'js.createAssetFromTemplate': 'Create Asset from Template',
  'WorkOrder.customLookup4': '',
  'WorkOrder.customLookup5': '',
  'WorkOrder.customLookup6': '',
  'RelayFailure.title': 'Relay Failure',
  'WorkOrder.customLookup7': '',
  'WorkOrder.customLookup8': '',
  'js.noAssetForWorkOrder': 'No asset associated with work order',
  'WorkOrder.customLookup9': '',
  'addasset.heading': 'Add Asset(s)',
  'FlashReport.faultReport.outageType.description': 'Outage Type',
  'Resource.currentShift.customLookup1': '',
  'Resource.currentShift.customLookup3': '',
  'Resource.currentShift.customLookup2': '',
  'Resource.currentShift.customLookup5': '',
  'js.addChildDevicesToSchematic': 'Add Child Devices to Schematic',
  'WorkOrder.customLookup1': '',
  'Resource.currentShift.customLookup4': '',
  'WorkOrder.customLookup2': '',
  'WorkOrder.customLookup3': '',
  'js.addressNotFound': "Address '{0}' not found",
  'WorkOrder.firmScheduledDate': '',
  'js.deleteSelectedBomItems': 'Delete Selected BOM Items',
  'maintenanceplan.title': 'Maintenance Plan',
  'Device.address': 'Location Address',
  'js.addressFlagType': 'Address Flag Type',
  'Asset.warrantyVendor.description': 'Warranty Vendor',
  'js.addressFlagValue': 'Address Flag Value',
  'js.validatePassword': 'Validate Password',
  'subscriberCustomLabelsDetail.title': 'Custom Label Detail',
  'WorkImage.custom4': '',
  'WorkImage.custom3': '',
  'WorkOrderAutoAssignmentRule.workOrderFilter.hint':
    "Please supply a comma-separated list of filter criteria (e.g. workRequestType.code='XX',customer.code='YY', etc)",
  'WorkImage.custom2': '',
  'WorkImage.custom1': 'Name',
  'CustomerInteraction.text': 'Notes',
  'WorkImage.custom5': '',
  'ShiftType.title': 'Shift Type',
  'call.description': 'Call Notes',
  'supportpersons.title': 'Support Persons',
  'subscriberAutoNumberingSettings.codeDigits': 'Number Of Digits in Code',
  'Supplier.requiresResource': 'Requires Resource?',
  'ForcelinkBillingDetail.fullName': 'Full Name',
  'activeUsers.heading': 'Current Users',
  'user.testAccount': 'Test Account',
  'WorkOrderAsset.pk.workOrder.code': 'Work Order Code',
  'menu.viewCustomMatrixViews': 'Custom Matrix Views',
  'Resource.orgUnit.description': 'Org Unit',
  'mobile.notAvail': 'not avail.',
  'errors.integer': '{0} must be a number.',
  'PurchaseRequisition.signoffs': 'Signoffs',
  'FaultReport.smsMessage.PlannedOutage':
    'Planned Outage ${code} on {0} ${status.description}. Reason:${plannedOutageReason} {2}',
  'PurchaseOrder.purchaseRequisitions': 'Purchase Requisitions',
  'js.requiredIfRule': "'Required If' rule",
  'ForcelinkBilling.heading': 'Forcelink Billing',
  'Supplier.customDate10': '',
  'js.viewOnMap': 'View On Map',
  'AssetCategories.title': 'Asset Categories',
  'Trainee.employer': 'Employer',
  'Building.customNumber3': '',
  'Building.customNumber2': '',
  'Building.customNumber1': '',
  'call.custom10': '',
  'Building.customNumber7': '',
  'Building.customNumber6': '',
  'Building.customNumber5': '',
  'AbnormalPlant.customDate10': '',
  'Building.customNumber4': '',
  'Building.customNumber9': '',
  'Building.customNumber8': '',
  'js.confirmCancelFileUpload':
    'You have selected a file - are you sure you want to cancel without uploading it?',
  'StockTake.store.description': 'Store',
  'menu.editNotificationRules': 'Notification Rules',
  'AuditTrail.oldValue': 'Old Value',
  'ScheduledWorkTemplate.customLookup10': '',
  'SchematicDiagram.pageSize': 'Page Size',
  'js.associateCustomer': 'Associate Customer',
  'mobile.listScheduledWorkOrderRequests': 'List Scheduled Work Order Requests',
  thursday: 'Thursday',
  'resourceChat.reply': 'Reply',
  'js.shiftComments': 'Shift Comments',
  'Contract.changeDate': 'Changed Date',
  'FaultReport.rootCauseDevice': 'Fault Cause Device',
  'ShiftType.description': 'Description',
  attachDocument: 'Attach Document',
  'menu.viewResources': 'Technicians',
  'Asset.projectCode': 'Project Code',
  'js.deSelectAsset': 'De-select Asset',
  'js.viewLinkedInvoice': 'View Linked Invoice',
  'subscriberAutoNumberingSettings.heading': 'Auto Numbering Information',
  'js.confirmReplaceMessageTextWithTemplate':
    'Are you sure you want to replace the message with the text from the template?',
  'js.cronExpression': 'Cron Expression',
  'SwitchingInstruction.operator': 'Operator 1',
  'TrainingEvent.trainees': 'Trainees',
  'Asset.notes': 'Notes',
  'FaultReport.added': 'Outage {0} Added',
  'workorders.exportView': 'Export View',
  'WorkOrderRequest.workOrder': 'Work Order',
  'Customer.joinDate': 'Join Date',
  'WorkOrder.newStatus': 'New Status',
  'js.setSelectedResourcesOffDuty': 'Set Selected Resources Off Duty',
  'Assettemplate.custom4': '',
  'smartCityPortal.homePage.inTheCity': 'In The<br>City',
  'Assettemplate.custom3': '',
  'Assettemplate.custom2': '',
  'Assettemplate.custom1': '',
  'TrainingEvent.createdDate': 'Created Date',
  'js.portalLoginUserNotRegistered':
    "This username is not registered. Please click the 'Register' button to register an account.",
  'TrainingEvent.trainingType.description': 'Training Type',
  'js.groupSelectedWorkRequestsToWorkOrder':
    'Group Selected Work Requests to Work Order',
  'Device.costCentre': 'Cost Centre',
  'WorkRequestSubcontractorAssignmentRule.activeToHHMM': 'Active To (HH:MM)',
  'BillOfMaterialsItem.purchaseOrder.status': 'PO Status',
  'PlannedOutage.customDate9': '',
  'AssetAttribute.timeStamp': 'Date/Time',
  'PlannedOutage.customDate8': '',
  'Assettemplate.custom9': '',
  'resource.user.loggedIn': 'Online?',
  'Assettemplate.custom8': '',
  'Assettemplate.custom7': '',
  'Assettemplate.custom6': '',
  'Assettemplate.custom5': '',
  'user.passwordupdated.emailsubject': 'Forcelink password updated',
  'user.registered':
    'You have successfully registered for access to this application. ',
  'button.search': 'Search',
  'LoadShedding.startDate': 'Actual Start',
  'js.clearSelection': 'Clear Selection',
  'PlannedOutage.customDate7': '',
  'PlannedOutage.customDate6': '',
  'PlannedOutage.customDate5': '',
  'PlannedOutage.customDate4': '',
  'PlannedOutage.customDate3': '',
  'PlannedOutage.customDate2': '',
  'PlannedOutage.customDate1': '',
  'button.createQuotation': 'Create Quotation',
  'invoice.workDescription': 'Work Description',
  'call.customNumber10': '',
  'button.deleteColumn': 'Delete Column',
  '*.totalPriceIncl': 'Total Including VAT',
  'assetStatuses.heading': 'Asset Statuses',
  'ScheduledWorkTemplate.title': 'Scheduled Work Template',
  'arcGISLayers.heading': 'ArcGIS Layers',
  'device.heading': 'Device Detail',
  'html.toolbar.text.saveWorksheet': 'Save Worksheet',
  'ResourceType.overtimeChargeRate': 'Overtime Rate',
  'js.createStockAdjustment': 'Create Stock Adjustment',
  'StatusReference.nextStatuses': 'Next Statuses',
  'menu.viewMyAssignedWorkRequests': 'My Assigned Work Requests',
  'Customer.heading': 'Customer Information',
  'FlashReport.status.description': 'Status',
  'Workflow.table.className': 'Document Type',
  'mobile.customItem': 'or enter Custom Item',
  'scheduler.heading': 'Scheduler',
  'resource.overtimeRate': 'Hourly Overtime Cost to Company',
  'menu.selectFile': 'Upload Data',
  'selecttrippeddevice.title': 'Select Tripped Device',
  'TrainingEvent.description': 'Training course name',
  'errors.general': 'The process did not complete. Details should follow.',
  'deviceType.trippable': 'Trippable?',
  'UserGroup.smsGroup': 'SMS Group?',
  'WorkOrder.statusUpdateNotifyMonitorsEmailBody':
    'New Status:{1}\nTicket Description:{0}\n\nLink to Work Order:{2}\n\nRegards, Forcelink',
  'js.createChildCustomer': 'Create Child Customer',
  'DashboardEntry.subColumnOrder': 'Sub Column Order',
  'invoices.title': 'Invoice List',
  'PlannedOutage.workOrderCode': 'Work Order',
  'Customer.customNumber1': '',
  'js.renderLayersInRectangle': 'Render Layers in Rectangle',
  'Quotation.allocatedResources': 'Allocated To',
  'js.addWorkNoteToSelected': 'Add Work Note to Selected',
  'LoadShedding.endDate': 'Actual Finish',
  'QuickLaunch.assets': 'Assets',
  'Customer.customNumber9': '',
  'Customer.customNumber8': '',
  'Customer.customNumber7': '',
  'Customer.customNumber6': '',
  'Customer.customNumber5': '',
  'RuleSelection.rule.code': 'Code',
  'Customer.customNumber4': '',
  'UserDWR.username': 'Username',
  'Customer.customNumber3': '',
  'js.chooseTransactionType': 'Choose Transaction Type',
  'Customer.customNumber2': '',
  'devices.heading': 'Devices',
  'Store.usersCanTransferOut': 'Users who can transfer out',
  'DeviceCategories.title': 'Device Categories',
  'Assettemplate.assetType': 'Asset Type',
  'resource.gpsMode': 'GPS Tracking Mode',
  'js.geometryLineWidth': '1',
  'customerpurchaserequisitions.title': 'Purchase Requisitions',
  selected: 'Selected',
  'js.toStore': 'To Store',
  'WorkOrderRequest.customTransient8': '',
  'LoadShedding.requirements': 'Requirements',
  'QueryMapLayer.propertyLatitude': 'Latitude property name',
  'WorkOrderRequest.customTransient7': '',
  'WorkOrderRequest.customTransient6': '',
  'WorkOrderRequest.customTransient5': '',
  'WorkOrderRequest.customTransient9': '',
  'WorkOrderRequest.customTransient4': '',
  'AssetType.scrapStore': 'Scrap Store',
  'WorkOrderRequest.customTransient3': '',
  'WorkOrderRequest.customTransient2': '',
  'WorkOrderRequest.customTransient1': '',
  'js.projectTaskCode': 'Code',
  'testREST.option.asset': 'Asset (/rest/asset)',
  'js.workRequestsAllocatedCount': 'Work Requests allocated',
  'js.restoreAllDevicesPlanned': 'Restore All Devices (planned)',
  'Device.added': 'Device {0} Added',
  'Supplier.type.description': 'Type',
  'InspectionListItem.hasRules': 'Rules?',
  'Supplier.contactPerson': 'Contact Person',
  'Material.mixedUnitCost': 'Mixed Unit Cost',
  'passwordReset.sendOTP.message':
    'To reset your Forcelink password, please use this OTP:{0}',
  'js.selectAsset': 'Select Asset',
  'assetStatus.deleted': "Asset Status '{0}' deleted",
  'workOrder.purchaseOrders': '',
  'ForcelinkBilling.billingType.description': 'Billing Type',
  'menu.customerWorkRequests': 'Service Tickets',
  'errors.password.newHintCannotBeSameAsPassword':
    'For security reasons, the new password hint may not be exactly the same as the password.',
  'WorkOrderRequest.priority': 'Priority',
  'FlashReport.protInvestigationDate': 'Investigation Date',
  'Contract.expiryDate': 'Expiry Date',
  'materialstore.title': 'Material Stock',
  'FaultReport.orgUnit': 'Depot/Area',
  'SubscriberDatabase.jdbcUrl': 'JDBC URL',
  'BillOfMaterialsItemAsset.pk.asset.code': 'Current Parent Asset Code',
  'SwitchingInstruction.flashSuit': 'Flash Suit?',
  'LoadShedding.durationMins': 'Duration',
  'FaultReport.devicesOffSupplyCount': 'No. of Devices affected',
  'menu.customerInvoices': 'Invoices',
  'orgStructure.id': 'Id',
  'mobile.performStockSearch': 'Perform Stock search',
  '*.active': 'Active? ',
  'js.updateDocType': 'Update Document Type',
  'workOrderMobile.noScheduledWorkorders': 'There are no scheduled Call Outs',
  'WorkOrder.changeHistory': 'Change History',
  'AbnormalPlant.priority.description': 'Priority',
  'InspectionListGroup.treeDescription': 'Group Tree',
  'AbnormalPlant.custom3': '',
  'AbnormalPlant.custom2': '',
  'AbnormalPlant.custom1': '',
  'AbnormalPlant.custom7': '',
  'AbnormalPlant.custom6': '',
  'AbnormalPlant.custom5': '',
  'js.changeTransactionType': 'Change Transaction type',
  'AbnormalPlant.custom4': '',
  'js.addSelectedDevicesToSchematic': 'Add selected devices',
  'js.statusDescription': 'Status',
  'AbnormalPlant.custom9': '',
  'AbnormalPlant.custom8': '',
  'referencelistfields.title': 'Edit Reference Lists',
  'PurchaseOrders.heading': 'Purchase Orders',
  'User.employeeNumber': 'Employee Number',
  'workrequestautoallocationrules.heading':
    'Work Request Auto Allocation Rules ',
  'WorkOrderRequest.deleted': 'Work Request {0} has been deleted successfully.',
  'AbnormalPlant.faultReport.id': 'Outage ID',
  'Asset.ownerCustomer.customNumber10': '',
  'ResourceChat.orgUnit.description': 'Org Unit',
  'activeUserList.title': 'Users Currently Active',
  'customerMobile.workOrderRequests': 'Claims',
  'IncomingSMSMessage.timeStamp': 'Message Time',
  'Contract.notes.sortOrder': 'asc',
  'support.preFilledEmail':
    'support@forcelink.net?subject=Support%20request%20via%20Forcelink%20Application&body=Good%20day%20Forcelink%20Support%0A%0AI%20am%20experiencing%20the%20following%20issue%20with%20Forcelink%3A%0A%0AMy%20details%20are%20as%20follows%3A%0AName%20and%20Surname%3A%0Ausername%3A%0Asubscriber%20id%20(company)%3A%0Ae-mail%3A%0Acellphone%3A%0A%0AOther%20notes%20regarding%20my%20request%20for%20support%3A%0A%0A',
  'billOfMaterials.resource': 'Technician ',
  'customerportal.mainMenu.createNewTicket':
    'Create a new ticket (your address)',
  'mobile.listActiveWorkOrderRequests': "List Active Work Req's",
  'WorkOrder.assignedToCompany': '',
  'Asset.assetType.description': 'Asset Type',
  'InboxEventUser.pk.event.tableKey': 'Record ID',
  'SwitchingInstruction.device.substationName': 'Station',
  'js.selected': 'Selected',
  'js.WorkOrdersControllerTitle': 'Work Order List',
  'Customer.hierarchy': 'Customer Hierarchy',
  'InspectionListWizard.builder': 'Builder',
  'button.viewWROnMap': '',
  'WorkOrder.contactName': 'Technician Responsible Name',
  'menu.FaultReports': 'Outages',
  'js.notes': 'Notes',
  'ForcelinkSubcontractor.user.password': 'Password',
  'menu.viewReferenceListFields': 'Reference List Fields',
  'mobile.createQuotationForCustomer': 'Create Quote for Customer',
  'PlannedOutage.endDate': 'Actual Finish',
  'map.layersToDisplay': 'Layers to display:',
  'AssetCategory.added': 'Asset Category {0} Added',
  'dashboardEntries.heading': 'Dashboard Entries',
  'Resource.availableUntil': 'Shift End',
  'Resource.activeAllocatedResources.onDutyButOffline':
    'Resource is on duty but off-line',
  'InspectionListWizard.creator': 'Creator',
  'js.ifAddGroupRule': "'If <value> then add group' rule",
  'passwordRule.minLength': 'The password minimum length is {0} characters.',
  'Resource.currentShift.shiftComments': 'Shift Comment',
  'TrippedDevice.timeStamp': 'Trip Date/Time',
  'menu.editStatusProgression': 'Status Progression',
  'PlannedOutage.newStatus': 'New Status',
  'WorkOrder.contract.code': 'Contract',
  'js.projectTaskAllocateResources': 'Allocate Resources',
  'WorkOrder.inspectionList': 'Inspection List',
  'billOfMaterialsItem.heading': 'Bill Of Materials Item',
  'dashboardEntry.gauge': 'Gauge?',
  'configauditlog.title': 'Configuration Audit Log',
  'ScheduledWorkTemplate.priority': 'Priority',
  'js.faultReport': 'Outage',
  'js.scheduledJobType': 'Job Type',
  'StockTake.quantityOKAdjusted': 'Ok (Adjusted)',
  'menu.createPOsForOpenPurchaseRequisitions': 'Create POs for new PRs',
  'Customer.viewWorkOrderNotes.title': 'Work Order Notes',
  'Lease.notifyDaysBeforeExpiry': 'Notify days before expiry',
  'smartCityPortal.login.title': 'Smart City',
  'LoadShedding.devicesToWorkOn': 'Plant To Work On',
  'button.ganttChart': 'Gantt Chart',
  'linkedReferenceList.heading': 'Linked Reference Data',
  'FaultReport.customDate10': '',
  'InboxEventUser.pk.event.description': 'Description',
  'SwitchingEvent.state': 'State',
  'mobile.viewQuotationDetails': 'View Quote Request Details',
  'Project.title': 'Project',
  'Store.assetStatus': 'Status',
  'CustomField.customFieldType.fieldType': 'Field Type',
  'Building.assetType': 'Building Type',
  'Supplier.contactNumber': 'Contact Number',
  'signup.title': 'Sign Up',
  'Customer.customerType.description': 'Customer Type',
  'StockTake.error.otherParent': 'Found under other parent asset ({0}:{1})',
  'js.addPlannedSwitchingInstruction': 'Add Planned Switching Instruction',
  'Asset.validation.noStockFoundForAssetCode':
    "No stock found for Asset Code '{0}'",
  'CustomerPortal.button.login': 'Login',
  'Lease.customLookup1': '',
  'Lease.customLookup2': '',
  'address.title': 'Address Detail',
  'Lease.customLookup3': '',
  'Lease.customLookup4': '',
  'QueryMapLayer.propertyLongitude': 'Longitude property name',
  'Lease.customLookup5': '',
  'label.confirmNewPassword': 'Confirm New Password',
  'js.editSupplierQuotation': 'Edit Supplier Quotation',
  'smartCity.activation.email.message':
    'Dear {0},\n\nPlease follow this link to activate your Smart City account:\n {1}\n\nRegards,\nSmartCity Admin',
  'js.portalLoginBlankUsername': 'Please enter your username ',
  'PurchaseOrder.message.created': 'Created new Purchase Order {0}',
  'menu.editCustomFields': 'Custom Fields',
  'js.requiresPhotoIfRule': "'Requires Photo If' rule",
  'InspectionListItem.notes': 'Notes',
  'Matrix.header': 'Matrix',
  'CustomerPortal.logFaultMyAddress.faultInfo': 'Fault Information',
  'call.customLookup5': '',
  'call.customLookup6': '',
  'call.customLookup3': '',
  'js.allocateWorkOrderDragDrop':
    'Assign {0} ({1} current jobs) to workorder {2}?',
  'call.customLookup4': '',
  'call.customLookup9': '',
  'WorkOrderRequest.code': 'Code',
  'call.customLookup7': '',
  'FaultReport.outageType': 'Outage Type',
  'call.customLookup8': '',
  'Device.SAPID': 'SAP Floc ID',
  'js.editLabel': 'Edit Label',
  'call.customLookup1': '',
  'call.customLookup2': '',
  'ForcelinkSubcontractor.customNumber5': '',
  'ForcelinkSubcontractor.customNumber4': '',
  'ForcelinkSubcontractor.customNumber1': '',
  'ForcelinkSubcontractor.customNumber3': '',
  'ForcelinkSubcontractor.customNumber2': '',
  'StockTakeExpectedItem.material.description': 'Material Description',
  'WorkCalendarMatrixView.customColumnView.description': 'Custom Column View',
  'SMSMessage.campaign.code': 'Campaign Code',
  'mobile.fontsize': 'Font size (%)',
  'billOfMaterials.unitPrice': 'Unit Price',
  'slaaudit.title': 'SLA Event History',
  'ForcelinkBilling.quantity': 'Quantity',
  'passwordReset.userNotFound':
    "Sorry, username '{0}' does not exist. Please use the 'Register' function to register a new user.",
  'ResourceChat.resource.description': 'Resource',
  'Quotation.customNumber1': '',
  StatusReference: 'Status',
  'Quotation.customNumber3': '',
  'Quotation.customNumber2': '',
  'menu.supportPersons': 'Support Persons',
  'Quotation.customNumber9': '',
  'ScheduledWorkTemplate.custom3': '',
  'js.createWorkOrder': 'Create Work Order',
  'Customer.parentCustomer.description': 'Parent Customer',
  'Quotation.customNumber8': '',
  'ScheduledWorkTemplate.custom4': '',
  'ScheduledWorkTemplate.custom5': '',
  'label.subscriberID': 'Subscriber ID',
  'Quotation.customNumber5': '',
  'Quotation.customNumber4': '',
  'Quotation.customNumber7': '',
  'ScheduledWorkTemplate.custom1': '',
  'Quotation.customNumber6': '',
  'ScheduledWorkTemplate.custom2': '',
  'PlannedOutage.plannedSwitchingEvents':
    'Planned Switching Events and Instructions',
  'js.lastScannedResult': 'Last Scanned Result',
  'PlannedOutage.status.description': 'Status',
  'WorkRequestSubcontractorAssignmentRule.heading':
    'Work Request Automatic Subcontractor Assignment Rule',
  'subscriberCustomLabels.addCustomLabel': 'Add new Custom Label',
  'WorkOrder.priority.description': 'Priority',
  'TaxType.code': 'Code',
  'PurchaseRequisition.customer.orgUnit.costCentre': 'Cost Centre',
  'WorkOrder.alerts': 'Alerts',
  'js.countResourcesMadeAvailable': '{0} resources made available',
  'SwitchingEvent.operator2.description': 'Operator 2',
  'js.createWOforWorkRequest': 'Create Work Order for Work Request',
  'WorkOrderRequest.add': 'Add',
  'QueryMapLayer.button.addStandardLayer': 'Add Standard Layer',
  'customReferenceListItems.title': 'Custom Reference List values',
  '*.customer.treeDescription': 'Customer Name',
  'js.viewAuditTrail': 'View Audit Trail',
  'js.success': 'Success',
  'AssetType.assetCategory.code': 'Asset Category Code',
  'Rule.ruleSelection.enabled': 'Enabled?',
  'notificationRule.hql': 'HQL',
  'passwordReset.newPassword': 'New Password',
  'WorkOrder.actualWorkGeometry': 'Actual Work Geometry',
  'js.connectSelectedDevices': 'Connect Selected Devices',
  'Asset.locationAddress.geom': '',
  'js.confirmSuspectOutage': 'Confirm Suspect Outage',
  'errors.login.ExpiredException': 'The password has expired.',
  'icon.warning': 'Warning',
  'errors.byte': '{0} must be a byte.',
  'Project.projectType.description': 'Project type',
  'mobile.addMaterialByCode': 'Add Material By Code',
  'Document.recordId': 'Record ID',
  'WorkOrderRequest.customer.joinDate': 'Join Date',
  '*.customer.description': 'Customer Description',
  'js.apply': 'Apply',
  '*.assetCategory.description': 'Asset Category',
  'crew.heading': 'Crew Information',
  'AuditTrail.userName': 'Username',
  'ResourceCalendar.title': 'Calendar for ${description}',
  'ScheduledWorkTemplate.sla.description': 'SLA',
  'mobile.confirmWorkCompletionMessage':
    'Please enter your One-Time PIN to confirm that work order {0} ({1}) was completed correctly. If you have any comments/concerns, please add them below.',
  'FaultReport.noLossOfSupply': 'No Loss of Supply',
  'SwitchingInstruction.switchingInstructionClause': '{0} {1} on {2}',
  'passwordRule.cannotReuseOldPassword':
    'You cannot re-use a password that you have used previously.',
  'customerMobile.createWorkRequest': 'Create Work Request',
  'deviceType.rootDevice': 'Can be tree root?',
  'WorkOrder.SLAAuditTrail': 'SLA Events',
  'WorkOrder.faultCause': 'Fault Cause',
  'button.changeToQuotation': '',
  'StockTake.signoffs': 'Signoffs',
  'ResourceAttribute.attributeValue': 'Attribute Value',
  'Workflow.defaultWorkflow': 'Default?',
  'invoice.deleted': 'Invoice {0} has been deleted successfully.',
  'assettemplate.heading': 'Asset Template',
  'PurchaseRequisition.customMultipleChoice5': '',
  'js.createInvoicesForSelectedWorkOrders':
    'Create Invoices for Selected Work Orders',
  'PurchaseRequisition.customMultipleChoice3': '',
  'PurchaseRequisition.customMultipleChoice4': '',
  'PurchaseRequisition.customMultipleChoice1': '',
  'PurchaseRequisition.customMultipleChoice2': '',
  'ScheduledWorkTemplate.triggerType': 'Trigger Type',
  'AssetStockSummary.store.description': 'Store name',
  'errors.minlength': '{0} can not be less than {1} characters.',
  'Building.heading': 'Building',
  'Assettemplate.material': '',
  'resourceType.code': 'Code',
  'availabilityCalendar.description': 'Description',
  'js.releaseNotesTitle': 'Release Notes',
  'resourceshiftroster.title': 'Resource Shift Roster',
  'myassignedworkrequests.heading': 'My Assigned Work Requests',
  'mobile.customer.address4': 'Street Number',
  'mobile.customer.address5': 'Street Name',
  'mobile.customer.address6': 'Suburb',
  'mobile.customer.address7': 'City',
  'mobile.customer.address1': 'Postal Address1',
  'mobile.customer.address2': 'Postal Address2',
  'mobile.customer.address3': 'Building',
  'mobile.customer.address8': 'Postal Code',
  'locationAddress.province.code': 'Province Code',
  'workOrder.title': 'Work Order Detail',
  'passwordReset.passwordMismatch':
    'Password and Confirmation Password are not the same, please try again ',
  'ResourceShiftRecord.unavailableReason': 'Unavailable Reason',
  'Contract.contractworkordersDefaultColumnView': '',
  'AssetType.standardInspectionList': 'Standard Inspection List',
  'js.text': 'Text',
  'js.polygonSelectMode': 'Polygon Select Mode',
  'address.heading': 'Address Information',
  'pickList.available': 'Available',
  'SMSCampaign.status': 'Status',
  'ScheduledScript.lastFireTime': 'Last Run',
  'menu.viewLeases': 'Leases',
  'errors.userHasNoResource':
    'The current user does not have an associated resource!',
  'BillOfMaterialsItemAsset.pk.bomItem.store.description': 'Store',
  'user.address.country': 'Country',
  'shifttypes.heading': 'Shift Types',
  'Resource.hourlyChargeRate': 'Hourly Charge',
  'TrainingEvent.title': 'Training Event',
  'subscriberdatabases.billingView': 'Billing View',
  'PlannedOutage.responsibleUser': 'Responsible Person',
  'mobile.createWorkRequestForCustomer': 'Create W/R for Customer',
  Asset: 'Asset',
  'workOrder.heading': 'Work Order',
  'WorkOrderRequest.selectedMonitorGroups': 'Selected Monitor Groups',
  'js.addImage': 'Add Image',
  'menu.billingadmin.billing': 'Forcelink Billing',
  'BatchAddAsset.warranty': 'Warranty',
  'QueryMapLineLayer.fromClassName': 'From Class',
  'billOfMaterials.total': 'Total',
  'workorderrequests.title': 'Work Request List',
  'menu.admin.reload': 'Reload Options',
  'customTableColumnSelection.tableIds': 'List View Ids',
  'WorkOrderRequest.selectedMonitors': 'Selected Monitors',
  'Contract.customTransient5': '',
  'login.signup': 'Not a member? <a href="{0}">Signup</a> for an account.',
  'Document.customLookup1': '',
  'Document.customLookup2': '',
  'Document.customLookup3': '',
  'Document.customLookup4': '',
  'Document.customLookup5': '',
  'dashboardEntry.heading': 'Dashboard Heading',
  'js.changeRSPlannedStart': 'Change Planned Start Date/Time',
  'Contract.customTransient4': '',
  'Contract.customTransient3': '',
  'js.workOrdersGroupedToParent': '{0} Work Orders grouped to Parent',
  'Contract.customTransient2': '',
  'Contract.customTransient1': '',
  'mobile.addNoteText': 'Note Text',
  'materialmovements.heading': 'Material Movements',
  'CustomerPortal.homePage.logOut': 'Log Out',
  'mycompanyworkorders.title': 'My Company Work Orders',
  'js.createMaintenancePlans': 'Create Maintenance Plans',
  'WorkOrderRequest.customNumber11': '',
  'WorkOrderRequest.customNumber12': '',
  WorkOrderRequest: 'Work Request',
  'WorkOrderRequest.customNumber10': '',
  'WorkOrderRequest.customNumber15': '',
  'Store.costCentre': 'Cost Centre',
  'WorkOrderRequest.customNumber16': '',
  'WorkOrderRequest.customNumber13': '',
  'WorkOrderRequest.customNumber14': '',
  'WorkOrderRequest.customNumber19': '',
  'SwitchingInstruction.normalised': 'Normalised?',
  'WorkOrderRequest.customNumber17': '',
  'WorkOrderRequest.customNumber18': '',
  'resource.grouping.crewMember': 'Crew Member',
  'AbnormalPlantDetails.title': 'Abnormal Plant Detail',
  'flashreports.title': 'Flash Reports',
  'mobile.viewWorkOrderRequestDetails': 'View Work Request Details',
  'supportpersons.heading': 'Support Persons',
  'billOfMaterials.error.quantityIssuedExceedsStockOnHand':
    "Stock on Hand for material '{0}' in store '{1}' is less than requested quantity (requested {2},available {3})",
  'Lease.title': 'Lease',
  'Customer.customerStatus.description': 'Status',
  'CustomerPortal.login.passwordHint.sent':
    'The password hint for {0} has been sent to {1}.',
  'button.addTrip': 'Add Trip',
  'js.ungroup': 'Ungroup',
  'InboxEventUser.pk.event.orgUnit.description': 'Org Unit',
  'WorkOrder.code': 'Work Order Code',
  'ArcGISLayer.authenticationToken': 'Authentication Token',
  'addressList.heading': 'Addresses',
  'SwitchingEvent.instruction': 'Instructions',
  'CustomManagerImpl_cp_mdt.twitterPlannedOutageStarted':
    'Planned Outage at ${station.description}. Start time {startTime}. Planned Finish {plannedFinish}. {shortURL}',
  'Project.description': 'Description',
  'ResourceShiftRecord.viewRouteMap': 'View Route Map',
  'Customer.customLookup9': '',
  'mobile.faultReporting': 'Fault Reporting',
  'Customer.customLookup7': '',
  'WorkOrder.allocatedResources': 'Allocated Resources',
  'Customer.customLookup8': '',
  'ActiveSessionInfo.sessionId': 'Session ID',
  'Customer.customLookup5': '',
  'activesessions.heading': 'Active User Sessions',
  'Customer.customLookup6': '',
  'Customer.customLookup3': '',
  'Customer.customLookup4': '',
  'Customer.customLookup1': '',
  'ShiftType.nonWorking': 'Non Working?',
  'Customer.customLookup2': '',
  'js.searchForOtherDevice': 'Search For Other Device',
  'Asset.custom9': '',
  'Asset.custom8': '',
  'Asset.custom7': '',
  'assetstock.heading': 'Assets in Stock',
  'PurchaseRequisition.add': 'Add',
  'mobile.statusPreRequisite.inspectionAdded':
    "An inspection list must be added before going to status '{0}'",
  'Asset.custom2': '',
  'material.unitPrice': 'Unit Price',
  'Asset.custom1': '',
  'workOrderMobile.supplyReason': 'Enter Reason',
  'Asset.custom6': '',
  'Asset.custom5': '',
  'Quotation.monitors': 'Monitors',
  'Asset.custom4': '',
  'Asset.custom3': '',
  'ScheduledWorkTemplate.code': 'Code',
  'FaultReport.certainty.description': 'Certainty',
  'jmesa.saveTable': 'Save {0} table',
  'button.kickOut': 'Kick Out',
  'WorkCalendarMatrixView.active': 'Active? ',
  'js.editProcName': 'Edit Job Name',
  'menu.viewMyCalendar': 'My Calendar',
  'ForcelinkSubcontractor.matchWorkExpression': 'Work Matching Expression',
  'errors.orgUnitAuth':
    "Sorry, you are not authorised to edit {0} {1} in Org Unit '{2}' ",
  'PurchaseOrder.warning.containsMaterialFromDifferentSupplier':
    'Warning! PO contains material from a different Supplier in the BOM',
  'workflow.inactivated': "Work Flow '{0}' de-activated",
  'Quotation.notes': 'Notes',
  'js.createLabelForConnection': 'Create label for connection ',
  'WorkOrderRequestResources.assignmentCompletedMessage':
    '{0} {1} has been completed.',
  'PurchaseRequisition.locationAddress.geom': '',
  'linkedReferenceList.title': 'Linked Reference List',
  'Call.contactName': 'Contact Person',
  'js.clearSchematic': 'Clear Schematic',
  'menu.changeUserPassword': 'Change Password',
  'js.quotationSupplier': 'Supplier',
  'purchaseOrder.notes': 'Notes',
  'SMSMessage.tries': 'Tries',
  'dashboardEntry.max': 'Gauge Maximum',
  'QuickLaunch.supportsms': 'Support SMS',
  'ScheduledWorkTemplate.billOfMaterialsGroup': 'Standard Bill Of Materials',
  'js.viewAssetOnWorkMap': 'View Asset on Work Map',
  'Lease.expiryDate': 'Expiry Date',
  'smartCityPortal.button.register': 'Register a new profile',
  'copyright.year': '2021',
  'Asset.ownerCustomer.mobileNumber': 'Mobile Number',
  'globalSettings.updated': 'Global Setting has been updated successfully.',
  'js.reasonForStockMove': 'Reason for Stock Move?',
  'js.createWorkOrderActualGeometry': 'Create Actual Work Geometry',
  'statusList.workflowName': 'Work Flow Name:',
  'CustomerPortal.login.passwordReset.sent':
    'The password reset for {0} has been sent to {1}.',
  'Quotation.code': 'Code',
  'SMSCampaign.createdDate': 'Created Date',
  'ActiveSessionInfo.superAdmin': 'Superadmin?',
  'js.stack': '[stack]',
  'mainMenu.heading': 'Welcome to Customer Services!',
  'StockTakeFoundItemDWR.code': 'Barcode',
  'js.createWorkRequest': 'Create Work Request',
  'Lease.rentalDeposit': 'Rental Deposit',
  'AbnormalPlant.priority': 'Priority',
  'WorkOrder.message.allocatePush': 'New Work Order ${code} allocated to you.',
  'FaultReport.voltage.code': 'Voltage',
  'ShiftType.endTime': 'End Time',
  'shiftSearch.title': 'Technician Shift History',
  'ResourceShiftRecord.vehicle.description': 'Vehicle',
  'billOfMaterialsDetail.title': 'Bill Of Materials Detail',
  'WorkRequestAutoAllocationRule.userFilter.hint':
    "Please supply a comma-separated list of filter criteria (e.g. description like '%Plumbing%')",
  'Device.manufacturer.description': 'Manufacturer',
  'QueryMapLayer.geomLineColour': 'Geometry Line Colour',
  'WorkOrderRequest.noteAddedNotifyMonitorsEmailSubject':
    'Note added to Work Request {0}:{1}',
  'Workflow.error.requiresAsset':
    "You must link an asset before you can update to '{0}'.",
  'label.newPasswordHint': 'New Password Hint',
  'callTakeForm.heading': 'Rapid Call Take',
  'WorkOrderRequest.added': 'Work Request {0} has been added successfully.',
  'Resource.setImage': 'Set Resource Image',
  'Material.code': 'Code',
  'resourceTypes.heading': 'Resource Types',
  'orgStructure.orgUnitType.description': 'Org Unit Type',
  'SubscriberDatabase.modulePowerLink': 'PowerLink enabled?',
  'js.warnSaveCustBeforeCreateUser':
    'Please save the customer before creating a user',
  'Quotation.dateCreated': 'Date Created',
  'menu.myWorkOrders': 'My Work Orders',
  '*.unitCost': 'Unit Cost',
  'WorkOrder.documents': 'Documents',
  'mobile.selectMaterialStore': 'Select Material Store',
  'subscriberCustomFieldLabels.label': 'Label',
  'js.userTableFiltersSaved': "Filters for table '{0}' saved to Database",
  'workOrder.selectedMonitors': 'Selected Monitors',
  'AssetAttribute.attributeValueNumber': 'Attribute Value (Numeric)',
  'invoice.id': 'Id',
  'InspectionListItem.largeFormat.images': '',
  'js.pleaseSelect': '--Please Select',
  'notificationRule.insert': 'Insert',
  'js.switchingEventMenu.createTagInstructionSameDevice':
    'Create Tag Instruction on same device',
  'workrequestgroupingpreview.button.groupSelected': 'Group to Selected',
  'InternationalLabel.englishLabel': 'English Label',
  'Resource.activeAllocatedResources.offDuty': 'Resource is off duty',
  'errors.required': '{0} is a required field.',
  'mobile.workOrderRequestListTitle': 'Work Request List',
  'ShiftType.emailflag': 'Send Email notifications for this shift?',
  'js.allowMiscMaterialType': 'Allow Miscellaneous Material Type',
  'js.projectAddTaskBelow': 'Add task below',
  'WorkOrderRequest.customNumber20': '',
  'CustomManagerImpl_cp_mdt.PlannedOutage': 'Planned Outage',
  'Contract.relatedContract': 'Related Contract',
  'js.createWorkOrderForSelectedWorkRequests':
    'Create Work Order for Selected Work Order Requests',
  'WorkImage.signaturesDefaultColumnView': '',
  'button.reset': 'Reset',
  'js.storedProcName': 'Job Name',
  'FaultReport.user': 'Created by',
  'CustomTableColumnSelection.id': 'ID',
  'FlashReport.faultReport.durationMins': 'Duration (HH:MM)',
  'flushCache.title': 'Flush Cache',
  'WorkOrderResources.assignmentCompletedMessage':
    'Work Order {0} has been completed.',
  'menu.taxTypes': 'Tax Types',
  'menu.workRequestSubcontractorAssignmentRules':
    'Work Request Auto Subcontractor Assignment Rules',
  'menu.configAuditLog': 'Config Audit Log',
  'Material.supplier.description': 'Supplier',
  'FailteIrelandFeeSchedule.rangeStart': 'Range Start',
  'js.setTargetPriceExcl': 'Set Target Price (Excl VAT)',
  'errors.expression': '{0} must match the expression {1}.',
  'SubscriberDatabase.subscriberId': 'Subscriber Id',
  'editInspectionListItems.title': 'Edit Inspection List Structure',
  'billOfMaterials.labourMaterial': 'Labour / Material',
  'WorkOrder.workOrderStatus.description': 'Status',
  'customerPortal.passwordReset.email.message':
    "Dear {0},\n\nYour password for username '{1}' has been reset to '{2}'.\n\nRegards,\nForcelink Admin",
  'js.addPeriodToContract': 'Add Period to Contract',
  'errors.duplicateAssetCode': 'Duplicate Asset Code',
  'CustomerInteraction.dateString': 'Date',
  'errors.login.DisabledException': 'The account has been disabled.',
  'AbnormalPlant.responsiblePerson': 'Responsible',
  'fieldAccess.showIfRule.title':
    'A boolean expression referencing fields EL ${field} format. These fields must be available on the page.',
  'AbnormalPlant.handOverTo': 'Hand Over To',
  'js.captureItems': 'Capture Items',
  'Project.responsibleUser': 'Responsible Person',
  'ForcelinkBillingDetail.username': 'Username',
  'SupplierOrgUnit.customLookup5.description': '',
  'DeviceCategory.depreciationType.description': 'Depreciation Type',
  'FieldAccess.regex': 'Validation Pattern',
  'referenceList.fieldName': 'Field Name',
  'js.assignToMyMobileButton': 'Allocate to My Mobile',
  'DashboardEntry.columnNumber': 'Column Number',
  'assetStatus.inactivated': "Asset Status '{0}' inactivated",
  'FaultReport.eventid': 'Event ID',
  'user.address.address': 'Address',
  'Crew.customLookup4': '',
  'Crew.customLookup5': '',
  'button.billOfMaterials.add': 'Add To Bill Of Materials',
  'Crew.customLookup2': '',
  'Crew.customLookup3': '',
  'button.edit': 'Edit',
  'Crew.customLookup1': '',
  'js.quotationDate': 'Quotation Date',
  'Quotation.custom30': '',
  'PlannedOutage.plannedDurationMins': 'Planned Duration (Mins)',
  'Asset.parentAsset.code': 'Parent Code',
  'Quotation.custom20': '',
  'invoice.workOrderRequest': 'Work Order Request',
  'WorkOrder.viewCustomer': 'View Customer',
  'Quotation.custom22': '',
  '*.code': 'Code',
  'Quotation.custom21': '',
  'Contract.customer': 'Customer Code',
  'Quotation.custom24': '',
  'Quotation.custom23': '',
  'Quotation.custom26': '',
  'Quotation.custom25': '',
  'js.viewShiftHistory': 'View Shift History',
  'Quotation.custom28': '',
  'Quotation.custom27': '',
  'Quotation.custom29': '',
  'menu.AbnormalPlantMatrix': 'Abnormal Plant Matrix',
  'js.barcode': 'Barcode',
  'menu.viewCustomFields': 'Custom Fields',
  'js.refreshMessages': 'Refresh Messages',
  'SpatialQuery.objectSizeLimit':
    '{0} in the view area exceeded limit of {1} - please zoom in closer to view.',
  'Quotation.custom11': '',
  'mobile.customer.code': 'Customer Code',
  'Quotation.custom10': '',
  'Quotation.custom13': '',
  'Quotation.custom12': '',
  'Quotation.custom15': '',
  'Quotation.custom14': '',
  'newuser.email.accountactivation.message':
    "Dear {0},\n\nThe administrator has created a new Forcelink account for you.  Please enter activation code '{1}', or click on this link to activate your account: {2}.\n\nRegards,\nForcelink Admin",
  'Quotation.custom17': '',
  'js.refresh': 'Refresh',
  'Quotation.custom16': '',
  'Customer.taxRegNumber': 'Vat Number',
  'Lease.customNumber1': '',
  'Quotation.custom19': '',
  'Quotation.custom18': '',
  'Lease.customNumber3': '',
  'FaultReport.endDate': 'End Date',
  'Lease.customNumber2': '',
  'Lease.customNumber5': '',
  'Lease.customNumber4': '',
  'workOrder.selectedMonitorGroups': 'Selected Monitor Groups',
  'user.linkedResource': 'Linked Resource',
  'js.sendHTML5link': 'Send HTML5 link',
  'js.requiresGeocoding': 'Requires Geocoding',
  'ForcelinkPricing.billingType': 'Billing Type',
  'assignment.allocatedToResource': 'Allocated to Resource',
  'DeviceTree.title': 'Device Tree',
  'button.workOrder': 'Work Order',
  'js.polygonInstructions':
    'left-click to define the boundaries of the polygon, press ESC to cancel, ENTER to complete.',
  'menu.flushCache': 'Flush Cache',
  'QueryMapLayer.propertyFeatureId': 'Shape FeatureId property',
  'Lease.status': 'Status',
  'assetStatus.updated': "Asset Status '{0}' updated",
  'mobile.selectAssetWorkType': 'Select Asset/Work Type',
  'js.WorkCalendarMatrixHeader': 'Work Calendar Matrix',
  'Assettemplate.customMultipleChoice1': '',
  'Assettemplate.customMultipleChoice3': '',
  'WorkRequestAutoAllocationRule.userSort.hint':
    'Please supply a comma-separated list of sort criteria (e.g. description)',
  'Assettemplate.customMultipleChoice2': '',
  'Assettemplate.customMultipleChoice5': '',
  'Assettemplate.customMultipleChoice4': '',
  'Quotation.documents': 'Documents',
  'DeviceType.deviceCategory.description': 'Device Category',
  'SubscriberDatabase.dateCreated': 'Date Created',
  'StockTake.materials': 'Materials',
  'SchematicDiagram.substationNames': 'Substations',
  'WorkOrderRequest.message.allocateSMS':
    'Work Request ${code} has been allocated to you. {0}',
  'SLA.notificationList': 'Notification List',
  'StockTake.quantityOK': 'Ok',
  'assettemplates.title': 'Asset Templates',
  'Customer.homeNumber': 'Home Number',
  'BillOfMaterialsItem.store.description': 'Store',
  'orgStructure.parentOrgUnit.code': 'Parent Org Code',
  'AssetCategory.virtual': 'Virtual',
  'TrainingEvent.heading': 'Training Event',
  'js.createWorkOrderForEachSelectedAsset':
    'Create a Work Order for each selected asset',
  'WorkOrder.children': 'Children',
  'TableValidationRule.javascriptValidationExpr':
    'Validation Expression (javascript)',
  'billOfMaterials.labour': 'Labour',
  'Asset.validation.serialNumber':
    'Maintenance Significant Items must have a Unique Serial Number',
  'Quotation.newStatus': 'New Status',
  'js.restoreAllDevices': 'Restore All Devices',
  'Asset.orgUnit': 'Organisational Unit',
  'quotations.title': 'Quotation List',
  'js.pleaseEnterMessage': 'Please enter the message to send',
  'User.smsAlertsEnabled': 'Enable SMS alerts',
  'SLAAuditTrail.targetDate': 'Target Date',
  'menu.scheduledWorkTemplates': 'Scheduled Work Templates',
  'StatusReference.description': 'Description',
  'ScheduledWorkTemplate.externalTriggerType': 'External Trigger Type',
  'js.privWarnAdminResourceAdmin':
    'You must have ROLE_ADMIN or ROLE_RESOURCE_ADMIN privileges to use this function',
  'AbnormalPlant.device.parentDevice.description': 'Circuit/Parent',
  'ResourceAttribute.attributeValueNumber': 'Attribute Value',
  'icon.information': 'Information',
  'LoadShedding.department': 'Department Assigned',
  'invoice.billingAddress': 'Billing Address',
  'PlannedOutage.customLookup4': '',
  'PlannedOutage.customLookup5': '',
  'StockTake.type': 'Type',
  'PlannedOutage.customLookup6': '',
  'Contract.cpiEscalation': 'CPI Escalation?',
  'PlannedOutage.customLookup7': '',
  'POBillOfMaterialsAssetItem.warning.assetTypeNotOrdered':
    'Warning, this asset type was not part of the ordered Stock',
  'PlannedOutage.customLookup8': '',
  'PlannedOutage.customLookup9': '',
  'DeviceCategory.deleted': 'Device Category {0} Deleted',
  'DeviceType.added': 'Device Type {0} Added',
  'Resource.currentShift.shiftType.description': 'Shift Type',
  'Device.manufacturerFrame': 'Manufacturer info',
  'AbnormalPlant.responsiblePerson.fullName': 'Responsible Person',
  'PlannedOutage.customLookup1': '',
  'PlannedOutage.customLookup2': '',
  'PlannedOutage.customLookup3': '',
  'SuperadminUser.heading': 'Superadmin User',
  'FaultReport.voltageLevel': 'Voltage Level',
  '*.validation.circularReferencingParent':
    'Circular Referencing in Parent {0}',
  'menu.trainingEvents': 'Training Events',
  'js.setNameToUnavailable': 'Set {0} to Unavailable?',
  'Building.costCentre': 'Cost Centre',
  'invoice.customer': 'Customer',
  'linkedReferenceList.parentFieldName': 'Parent Field Name',
  'FaultReport.attendedBy': 'Attended By',
  'DeviceType.title': 'Device Type',
  'ResourceShiftRecord.mobileAppOfflinePulses': 'App Offline Pulses',
  'user.customerPortalAccount': 'Customer Portal Account',
  'js.addSupplierQuotation': 'Add Supplier Quotation',
  'address.customer': 'Customer',
  'versioninfo.title': 'Version Info',
  'js.groupSelectedToSameWorkOrder': 'Group Selected to Same Work Order',
  'WorkOrderAutoAssignmentRule.activeToHHMM': 'Active To (HH:MM)',
  'Customer.code': 'Code',
  'CustomerPortal.homePage.viewNotifications': 'View Notifications',
  'WorkOrder.added': 'Work Order {0} has been added.',
  'standardinspectionlists.title': 'Standard Inspection Lists',
  'js.geometryLineOpacity': '0.5',
  'allocateresource.defaultColumnView': '',
  'Store.locationAddress': 'Address',
  '*.trippableDevicesView': 'Trippable Devices',
  'DictionaryField.propertyName': 'Field',
  'StockTake.type.description': 'Type',
  'FlashReport.faultReport.durationHHMM': 'Duration (HH:MM)',
  'FieldAccess.viewGroups': 'View Groups',
  'WorkOrderRequest.allocate.heading': 'Allocate Technicians',
  'WorkOrder.assignedToCompany.description': '',
  'findCustomers.heading': 'Find Customers',
  'PlannedOutage.loadSheddingStage': 'Stage',
  '*.customer.customLookup30': '',
  'AbnormalPlant.faultReport.user.fullName': 'Created By',
  'workOrderMobile.noActiveWorkOrderRequests':
    'There are no active Work Requests',
  clipboard: 'Clipboard',
  'js.hideInfoBlock': 'Hide Info Block',
  'switchingInstruction.title': 'Switching Instruction',
  'errors.currency': '{0} is an invalid currency format',
  'Customer.contactPerson.name': 'Name',
  'assetStatus.saved': "Asset Status '{0}' saved",
  'Document.customNumber5': '',
  '*.customer.customLookup21': '',
  '*.customer.customLookup22': '',
  '*.customer.customLookup20': '',
  'WorkOrderRequest.customer.parentCustomer.emailAddress': '',
  '*.customer.customLookup25': '',
  'icon.warning.img': '/images/iconWarning.gif',
  '*.customer.customLookup26': '',
  '*.customer.customLookup23': '',
  'js.changeMaterial': 'Change Material',
  '*.customer.customLookup24': '',
  '*.customer.customLookup29': '',
  'Customer.linkedAsset.code': 'Point of Supply',
  'Document.customNumber2': '',
  'upload.heading': 'Upload Data',
  'Document.customNumber1': '',
  'PlannedOutage.customerSMSMessage':
    'Dear [customer], Joburg Water is planning an outage in [suburb] on {0} from {1} to {2}. Your electricity supply may be affected.',
  '*.customer.customLookup27': '',
  'Document.customNumber4': '',
  'js.cc': 'CC',
  '*.customer.customLookup28': '',
  'Document.customNumber3': '',
  'DeviceCategory.description': 'Device Type Description',
  rightclickFieldOptions: 'Right-click for field options',
  'js.warrantyVendor': 'Vendor',
  'WorkOrderRequest.documents': 'Linked Work',
  'menu.registerConfigChange': 'Register Config Change',
  'StandardInspectionList.invalidated': 'Standard Inspection List Invalidated',
  'errors.login.CustomerPortalAdminLoginException':
    'Unable to login as a portal administrator',
  'button.confirm': 'Confirm',
  'js.save': 'Save',
  'button.addNote': 'Add Note',
  'Contract.type.description': 'Contract Type',
  'QuickLaunch.workorderrequests': 'Work Requests',
  'subscriberCustomFieldLabelsList.heading': 'Custom Fields',
  'PlannedOutage.plannedDurationHHMM': 'Planned Duration (HH:MM)',
  'SMSMessage.campaign.createdDate': 'Campaign Created',
  'SMSCampaign.title': 'SMS Campaign',
  'StockTakeExpectedItemDWR.quantity': 'Quantity Expected',
  '*.contactPerson.name': 'Contact Person',
  'js.schedulerViewPeriod': 'View Period',
  'TableValidationRule.additionalPrintReportFiles.title':
    'Comma-delimited list of .jrxml files',
  'Store.quotable': 'Quotable?',
  'Resource.explicitSkills': 'Skills',
  'user.roles': 'Current Roles',
  'jmesa.exportLimitAlert':
    'Exports are limited to {0} rows, please filter your results before exporting',
  'PlannedOutage.loadSheddingBlock': 'Block',
  'PublicHoliday.country': 'Country',
  'Resource.currentShift.custom2': '',
  'Resource.currentShift.custom3': '',
  'FaultReport.permitNo': 'Permit number',
  'Resource.currentShift.custom4': '',
  'Resource.currentShift.custom5': '',
  'js.createWorkRequestActualGeometry': 'Create Actual Work Geometry',
  'Contract.linkedObjects': 'Related Contracts',
  'Resource.currentShift.custom1': '',
  'ForcelinkSubcontractor.user.firstName': 'First Name',
  'js.language': 'Language',
  'button.allocateResources': 'Allocate Resources',
  'StockTakeExpectedItem.quantityExpected': 'Quantity Expected',
  'FailteIrelandFeeSchedule.perUnitFee': 'Per Unit',
  'Invoice.billOfMaterialsGroup.formattedTotalPriceExcl': 'Price Excl',
  'PurchaseRequisition.containsMiscennaneousMaterialType':
    "Purchase Requisition contains a Miscellaneous Material Type, please change to a real material or flag the item with 'Allow Miscellaneous Material Type'",
  'js.confirmClearSchematic':
    'Are you sure you want to delete all contents on this schematic diagram?',
  'mobile.currentSearchMode': 'Current search mode',
  'js.reshedulePlannedOutage': 'Reschedule Planned Outage',
  'Customer.user.employeeNumber': 'Employee Number',
  '*.customer.mobileNumber': 'Mobile Number',
  'FailteIrelandFeeSchedule.taxType': 'Tax Type',
  'errors.pattern': 'The value of {0} did not match the pattern {1}',
  'Asset.address.geom': '',
  'js.mapContextChangeAddress': 'Create Address',
  'availabilityCalendar.heading': 'Shift Roster Information',
  'smartCityPortal.login.heading': 'Login',
  'js.removeDevices': 'Remove Device(s)',
  'Assettemplate.locationAddress': 'Address',
  'User.signature': 'Signature',
  'BillOfMaterialsItem.customNumber1': 'Supplied By PRASA',
  'BillOfMaterialsItem.customNumber2': 'Supplied By Siemens',
  'BillOfMaterialsItem.customNumber5': '',
  'BillOfMaterialsItem.customNumber3': '',
  'js.replaceContactDetailsTitle': 'Replace Contact Details?',
  'BillOfMaterialsItem.customNumber4': '',
  'quotation.heading': 'Quotation',
  'SwitchingInstruction.reportTime': 'Report Time',
  'Device.validSource': 'Valid Source?',
  'Device.commissioningDate': 'Commissioning Date',
  'menu.aboutForcelink': 'About Forcelink',
  'SchematicDiagram.statusDate': 'Status Date',
  'Device.acquisitionFrame': 'Acquisition',
  'shiftSearch.heading': 'Technician Shift History',
  'resource.javaMobileOptions': 'Mobile App Options',
  'editStatusProgression.statusProgressionList': 'Status Progression List',
  'customtablecolumnsel.title': 'Custom Table Column Selections',
  'hierarchyFilter.groupedOnly': 'Groups only',
  'ArcGISLayer.fillColor': 'Fill Colour',
  'ForcelinkSubcontractor.server': 'Server',
  'AvailabilityCalendar.notes': 'Notes',
  'FlashReport.faultReport.relayFlags': 'Relay Flags',
  'jmesa.sortingLimitedSecondLevel':
    'Sorting limited for performance reasons (more than {0} records)',
  'Quotation.linkedObjects': 'Linked Objects',
  'Device.validation.address': 'Address is required for device',
  'mobile.listWorkOrderRequests': "List All Work Req's",
  'CustomManagerImpl_cp_mdt.twitterPlannedOutageUpdated':
    'Planned Outage at ${station.description} updated at {timeStamp}. {shortURL}',
  'Note.workOrderCode': 'Work Order',
  'Document.customLookup4.description': '',
  'SMSMessage.heading': 'Forcelink Application Support Request',
  'menu.viewPortalMap': 'View Map',
  'js.addPurchaseInvoice': 'Add Purchase Invoice',
  'customerportal.quotations.heading': 'Quotations',
  'js.viewOutageOnMap': 'View Outage on Map',
  'Asset.scheduledWorkTemplates': 'Associated Scheduled Work Templates',
  'js.invoiceDate': 'Invoice Date',
  'Material.materialType.code': 'Type Code',
  'js.createChildDevice': 'Create Child Device',
  'ScheduledScript.lastResult': 'Last result',
  'Asset.billOfMaterialsGroup.formattedTotalCostExcl': 'Cost Excl',
  'QueryMapLayer.description': 'Layer Name',
  'passwordReset.sentTo': 'Your new password has been sent to {0}',
  'js.cutCustomer': 'Cut Customer',
  'button.runAssignmentRulesNow': 'Run Assignment Rules now',
  'suppliers.title': 'Suppliers',
  'Asset.customDate10': '',
  'SMSCampaign.createdBy': 'Created By',
  'button.delete': 'Delete',
  'mobile.backToMainMenu': 'Back To Main Menu',
  'Quotation.sentToSubcontractor':
    'Quotation request sent to Sub-contractor {1}',
  'workOrderRequest.customerAndAddress': 'Customer Site',
  'SupportPerson.username': 'Username',
  'CustomerPortal.homePage.viewOpenFaults': 'View Open Faults',
  'Contract.contractbillofmaterialsDefaultColumnView': '',
  'workflow.deleted': "Work Flow '{0}' deleted",
  'ResourceType.settings': 'Settings',
  'ForcelinkSubcontractor.remoteUserAccount': 'Remote User Account',
  'user.credentialsExpired': 'Password Expired',
  'SMSCampaign.selectedCustomers': 'Customers',
  '*.add': 'Add',
  'SLAs.heading': "SLA's",
  '*.customer.customLookup10': '',
  'Customer.accountManager.fullname': 'Account Manager',
  '*.customer.customLookup11': '',
  'menu.tableValidationRules': 'Table Validation Rules',
  '*.customer.customLookup14': '',
  'QuickLaunch.resources': 'Resources',
  '*.customer.customLookup15': '',
  '*.customer.customLookup12': '',
  '*.customer.customLookup13': '',
  '*.customer.customLookup18': '',
  '*.customer.customLookup19': '',
  '*.customer.customLookup16': '',
  '*.customer.customLookup17': '',
  'UserGroup.description': 'Group Name',
  'user.passwordHint': 'Password Hint',
  'js.assign': 'Assign',
  'js.addPlannedSwitchingEvent': 'Add Planned SCADA Switching Event',
  'billOfMaterials.unitType': 'Unit Type',
  'FaultReport.damagedDevicesDescription': 'Faut Location(s)',
  'js.assignToMyMobileLabel': 'Allocate to My Mobile',
  'address.description': 'Description',
  'FaultReport.eventId': 'External event ID',
  'SubscriberCustomLabels.revert': 'Revert?',
  'orgStructures.title': 'Organisational Units',
  'AssetScheduledWorkTemplateXref.pk.asset.assetType.description': 'Asset Type',
  'LoadShedding.plannedStart': 'Planned Start',
  'menu.viewSuppliers': 'Suppliers',
  'js.transferStock': 'Transfer Stock',
  'WorkOrder.workOrderStatus': 'Status',
  'js.changesAppliedSuccessfully': 'Changes applied successfully!',
  'WorkOrder.hoursToEtc': 'Hours to ETC',
  'CustomerPortal.login.heading': 'Login',
  'Resource.user.username': 'Username',
  'WorkOrder.viewBillOfMaterials': 'View Bill Of Materials',
  'BillOfMaterialsItem.material.description': 'Material',
  'PurchaseOrder.button.addInvoice': 'Add Invoice',
  'DeviceTree.heading': 'Device Tree',
  'customers.addressView': 'Address View',
  'linkedReferenceList.selectParentValue': 'Select Parent Value',
  'user.password': 'Password',
  'js.editWorkNote': 'Edit Note',
  'Store.custom6': '',
  'Store.custom7': '',
  'Store.custom8': '',
  'ResourceChat.workOrderCode': 'Work Order',
  'Store.custom9': '',
  'InspectionListWizard.button.import': 'Import CSV',
  'AssetStockSummary.purchaseCost': 'Purchase Cost',
  'Device.active': 'Active? ',
  'orgStructure.code': 'Code',
  'Store.custom1': '',
  'Store.custom2': '',
  'Store.custom3': '',
  '*.orgUnit.code': 'Org Unit Code',
  'Store.custom4': '',
  'Store.custom5': '',
  'SupplierOrgUnit.orgUnit': 'Org unit',
  'Device.orgUnit': 'Organisational Unit',
  'user.name': 'Name',
  'button.executeMultiple': 'Execute on All Schemas',
  'menu.viewCustomTableColumnSelections': 'Custom Table Columns',
  'supportPerson.adminRightsSchemas': 'Admin rights for schemas',
  'icon.email.img': '/images/iconEmail.gif',
  'ResourceShiftRecord.shiftEndDate': 'End Date',
  'js.finishedAllocationOrgUnits': 'Finished Allocating Org Units',
  'js.fromStore': 'From Store',
  'QueryMapLayer.shapefile': 'Shape File',
  'js.viewCustomer': 'View Customer',
  'workorderrequests.exportView': 'Export View',
  'Resource.audittrail': 'Audit Trail',
  'WorkOrder.noteAddedNotifyMonitorsEmailSubject':
    'Note added to Work Order {0}:{1} (by {2})',
  'PurchaseOrder.error.quantityDeliveredExceedsQuantityOrdered':
    "Quantity delivered exceeds quantity ordered for '{0}'",
  'WorkOrderRequest.orgUnit.description': 'Organisational Unit',
  'StockTake.error.unknownBarcode.itemDesc':
    'Unknown Barcode. Captured Description:{0}',
  'js.confirmDeleteSupplierQuotation':
    'Are you sure you want to delete the Supplier Quotation?',
  'HashMap.customTransient1.format': '###,##0.00',
  'CustomerPortal.homePage.editProfile': 'Edit my Profile',
  'customtablecolumnsel.heading': 'Custom Table Column Selections',
  'AbnormalPlant.customLookup1': '',
  'AbnormalPlant.customLookup2': '',
  'AbnormalPlant.customLookup3': '',
  'AbnormalPlant.customLookup4': '',
  'AbnormalPlant.customLookup5': '',
  'material.updated': 'Material has been updated successfully.',
  'AbnormalPlant.comments': 'Comments',
  'scheduledworktemplates.heading': 'Scheduled Work Templates',
  'assetstock.button.add': 'Create Asset in Stock',
  'AssetType.geometryType': 'Geometry Type',
  'AbnormalPlant.customLookup6': '',
  'js.switchingEvent.custom1': 'OIF Number',
  'AbnormalPlant.customLookup7': '',
  'AbnormalPlant.customLookup8': '',
  'AbnormalPlant.customLookup9': '',
  'Asset.billOfMaterialsGroup.totalCostExcl': 'Cost Excl',
  'WorkOrderRequest.customTransient12': '',
  'WorkOrderRequest.customTransient13': '',
  'js.hideAbnormalDeviceStatuses': 'Hide Abnormal Device Statuses',
  'WorkOrderRequest.customTransient10': '',
  'WorkOrderRequest.customTransient11': '',
  '*.workOrder.activeAllocatedResources': 'Allocated Resources',
  'CustomerPortal.login.title': 'Forcelink Customer Portal',
  '*.auditTrail': 'Audit Trail',
  'js.sendMessageToResource': 'Send Message to Technician ',
  'ActiveSessionInfo.username': 'Username',
  'js.addItems': 'Add Items',
  'menu.internationalLabels': 'International Labels',
  'WorkOrderRequest.auditTrail': 'Audit Trail',
  'js.contractscheduledworktemplatesMoreOptionsMenu.linkSelectedScheduledWorkTemplates':
    'Link selected SW Templates',
  'FaultReport.auditTrail': 'Audit Trail',
  'BatchAddAsset.scannedCodesHint':
    'To remove incorrectly scanned items, highlight and press the Delete key',
  'WorkOrderRequest.customTransient14': '',
  'WorkOrderRequest.customTransient15': '',
  'WorkRequestAutoAllocationRule.title': 'Work Request Auto Allocation Rule',
  'QuickLaunch.slaaudit': 'SLA Events',
  'js.hideHiddenFields': 'Hide Hidden Fields',
  'Contract.responsibleUser.fullName': 'Responsible User',
  'ScheduledScript.cronExpr': 'Cron Expression',
  'menu.PlannedOutages': 'Planned Outages',
  'User.mobileNumber': 'Mobile Phone',
  'SpatialLayer.id': 'ID',
  'mobile.continue': 'Continue?',
};

const limitObj = {
  className: 'LimitImpl',
  tableModelClass: 'Asset',
  id: 'assets',
  columnView: 'Customer View',
  rowSelect: {
    rowStart: 0,
    rowEnd: 10,
    maxRows: 10,
  },
  filterSet: {
    filters: [
      { test: 123 },
    ],
  },
  sortSet: {
    sorts: [],
  },
};

export const testKPIs = [
  {
    id: 175,
    numericResult: 1,
    tableResult: null,
    label: 'Requires PO',
    colour: '',
    displayGroup: {
      label: 'Requires Approval',
      value: '29852',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '#workorderrequests.list?&workorderrequests_p_=1&workorderrequests_mr_=15&workorderrequests_cv_=Standard View&workorderrequests_f_dateCreated=after%20%2719%20Nov%202019%2009%3A47%27&workorderrequests_f_workRequestStatus.description=%5BRequires%20PO%5D&workorderrequests_tr_=true&rn=666',
    max: 1000,
    subColumnOrder: 0,
    sortOrder: 0,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 158,
    numericResult: 25,
    tableResult: null,
    label: 'Gauteng North Stations',
    colour: '',
    displayGroup: {
      label: 'Equipment Tree',
      value: '29787',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url: '/customerportal/assettree.html?id=98840',
    max: 100,
    subColumnOrder: 2,
    sortOrder: 0,
    rowNumber: 0,
    columnNumber: 1,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 154,
    numericResult: 66,
    tableResult: null,
    label: 'Gauteng South Stations',
    colour: '',
    displayGroup: {
      label: 'Equipment Tree',
      value: '29787',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url: '/customerportal/assettree.html?id=98839',
    max: 100,
    subColumnOrder: 5,
    sortOrder: 0,
    rowNumber: 0,
    columnNumber: 1,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 168,
    numericResult: 1,
    tableResult: null,
    label: 'Branch Manager',
    colour: '',
    displayGroup: {
      label: 'Requires Approval',
      value: '29852',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '/customerportal#workorderrequests.list?&rn=28&&workorderrequests_p_=1&workorderrequests_mr_=15&workorderrequests_cv_=Standard View&workorderrequests_f_dateCreated=after%20%2726%20May%202019%2013%3A09%27&workorderrequests_f_customer.description=PRASA&workorderrequests_f_workRequestStatus.description=%5BTechnician%20Signoff%5D&workorderrequests_tr_=true&rn=52',
    max: 100,
    subColumnOrder: 0,
    sortOrder: 0,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 170,
    numericResult: 1,
    tableResult: null,
    label: 'Regional Manager',
    colour: '',
    displayGroup: {
      label: 'Requires Approval',
      value: '29852',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '/customerportal#workorderrequests.list?&rn=28&&workorderrequests_p_=1&workorderrequests_mr_=15&workorderrequests_cv_=Standard View&workorderrequests_f_dateCreated=after%20%2726%20May%202019%2013%3A09%27&workorderrequests_f_customer.description=PRASA&workorderrequests_f_workRequestStatus.description=%5BBranch%20Manager%20-%20Approved%5D&workorderrequests_tr_=true&rn=52',
    max: 100,
    subColumnOrder: 0,
    sortOrder: 1,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 172,
    numericResult: 18,
    tableResult: null,
    label: 'Tickets needing to be pushed',
    colour: '',
    displayGroup: {
      label: 'Requires Approval',
      value: '29852',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '/customerportal#workorderrequests.list?&rn=28&&workorderrequests_p_=1&workorderrequests_mr_=15&workorderrequests_cv_=Standard View&workorderrequests_f_dateCreated=after%20%2726%20May%202019%2013%3A09%27&workorderrequests_f_customer.description=PRASA&workorderrequests_f_workRequestStatus.description=%5BAssessment%20Complete%5D&workorderrequests_f_documents=No%20Work%20Order&workorderrequests_tr_=true&rn=265',
    max: 100,
    subColumnOrder: 0,
    sortOrder: 2,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 152,
    numericResult: 0,
    tableResult: null,
    label: 'All Equipment on Site',
    colour: '',
    displayGroup: {
      label: 'Equipment On Site',
      value: '29790',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '#assets.list?&assets_p_=1&assets_mr_=15&assets_cv_=Custom Warranty%20View%20&customerportal&assets_tr_=true&rn=946',
    max: 100,
    subColumnOrder: 0,
    sortOrder: 2,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 174,
    numericResult: 1,
    tableResult: null,
    label: 'Quotes needing Decision',
    colour: '',
    displayGroup: {
      label: 'Requires Approval',
      value: '29852',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '/customerportal#quotations.list?&customerquotations_p_=1&customerquotations_mr_=15&customerquotations_cv_=Standard%20View&customerquotations_f_workRequestStatus.description=%5BQuotation%20Issued%5D&customerquotations_tr_=true&rn=508',
    max: 100,
    subColumnOrder: 0,
    sortOrder: 3,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
  {
    id: 159,
    numericResult: 129,
    tableResult: null,
    label: 'All Tickets',
    colour: '',
    displayGroup: {
      label: 'Tickets',
      value: '29791',
      attributes: {},
      fieldHandler: null,
    },
    type: 'KPI',
    url:
      '/customerportal#workorderrequests.list?&workorderrequests_p_=1&workorderrequests_mr_=15&workorderrequests_cv_=Standard%20View&workorderrequests_f_dateCreated=after%20%2726%20May%202019%2013%3A09%27&workorderrequests_f_customer.description=PRASA&workorderrequests_tr_=true&rn=28',
    max: 100,
    subColumnOrder: 0,
    sortOrder: 4,
    rowNumber: 0,
    columnNumber: 0,
    columnSize: 3,
    subColumnSize: 12,
    subColumnNumber: 0,
    latitude: 0.0,
    longitude: 0.0,
    layerIds: [],
    navigationFilterJson: limitObj,
  },
];
