import React, { useContext } from 'react';
import { ProfileForm } from '../../components/servest_is/profile';
import { Context } from '../../functions/servest_is/context';
import { doLogin } from '../../scripts/loginScripts';

export const Register = () => {
  document.title = 'Register | Servest IS Portal';
  const subscriberId = 'servest_is';

  const { setAuth, setUsername } = useContext(Context);
  const onLogin = ({ username, password }, setStates) => {
    doLogin(username, password, setAuth, subscriberId, setStates)
      .then((loggedIn) => loggedIn && setUsername(username));
  };

  return <ProfileForm {...{ onLogin, subscriberId }} />;
};
