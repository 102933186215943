import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Card, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../../styles/servest_is/home.scss';
import {
  PortalName,
} from '../../pages/servest_is/login/login.styled';

export const HomePage = () => (
  <Container style={{
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 124px)',
    flexDirection: 'column',
  }}
  >
    <PortalName>Servest IS Portal</PortalName>
    <Row className="iconRow">
      {[
        [
          '/ticket',
          'create_wr.jpg',
          'Create a new ticket',
          'This enables all users to create IS related calls against the Systems, Technical and Mobile IT Support divisions.',
        ],
        [
          '/tickets',
          'open_wr.jpg',
          'View Open Tickets',
          'This enables all users to view open IS related calls against the Systems, Technical and Mobile IT Support divisions.',
        ],
        [
          '/closedtickets',
          'closed_wr.jpg',
          'View Closed Tickets',
          'This enables all users to view closed IS related calls against the Systems, Technical and Mobile IT Support divisions.',
        ],
        [
          '/workorders',
          'open_wo.jpg',
          'View Open Work Orders',
          'This enables all users to view open IS related WorkOrders against the Systems, Technical and Mobile IT Support divisions.',
        ],
        [
          '/closedworkorders',
          'closed_wo.jpg',
          'View Closed Work Orders',
          'This enables all users to view closed IS related WorkOrders against the Systems, Technical and Mobile IT Support divisions.',
        ],
      ].map((val) => (
        <Link to={val[0]} key={val[2]}>
          <Card link href="">
            <Image src={`servest_is/${val[1]}`} wrapped ui={false} />
            <Card.Content>
              <Card.Header>{val[2]}</Card.Header>
              <Card.Description>{val[3]}</Card.Description>
            </Card.Content>
          </Card>
        </Link>
      ))}
    </Row>
  </Container>
);
