/* Modules */
import React from 'react';
import { Container } from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from 'react-router-dom';
/* Local */
import { Context } from './functions/botswanatourism/context';
import './styles/botswanatourism/App.scss';
import { ForcelinkFooter, PortalNav } from './components/botswanatourism/headerandfooter';
import { LoginForm } from './pages/botswanatourism/login';
import { HomePage } from './pages/botswanatourism/home';
import { FacilitiesInformation } from './pages/botswanatourism/facilitiesInformation';
import { Queries } from './pages/botswanatourism/queries';
import { QueryForm } from './pages/botswanatourism/queryForm';
import { Applications } from './pages/botswanatourism/applications';
import { ApplicationForm } from './pages/botswanatourism/applicationform';
import { Downloads } from './pages/botswanatourism/downloads';
// import { isAuthenticated } from './functions/apiCalls';

function getFaviconEl() {
  return document.getElementById('favicon');
}
export class BotswanaTourismApp extends React.Component {
  static contextType = Context;

  render() {
    const { authenticated } = this.context;
    document.title = 'Botswana Tourism Portal';
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = 'botswanatourism/botswanatourismfavicon-32x32.png';
    const headerFooterNavabarPages = [
      '/home',
      '/facilitiesinformation',
      '/downloads',
      '/queries',
      '/queryform',
      '/applications',
      '/applicationform',
    ];
    return (
      <div id="BotswanaTourismApp">
        <Switch>
          {headerFooterNavabarPages.map((path) => (
            <Route path={path} key={`${path}-element`}>
              <PortalNavWrapper>
                <PortalNavWrapper />
              </PortalNavWrapper>
            </Route>
          ))}
        </Switch>
        <div id="App">
          <Container fluid id="AppContainer">
            <Switch>
              {/* If logged in take user to home page else take the user to the login page */}
              <Route
                exact
                path="/"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
              <Route
                path="/home"
                render={({ location }) => (authenticated ? (
                  <HomePage />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                ))}
              />
              <Route
                path="/login"
                render={({ location }) => (!authenticated ? (
                  <LoginForm />
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
              <Route path="/logout">
                <LogoutWapper />
              </Route>
              <Route path="/queryform/:code">
                <QueryFormWapper />
              </Route>
              <Route path="/applicationform/:code">
                <ApplicationFormWapper />
              </Route>

              {[
                ['/facilitiesinformation', <FacilitiesInformation />],
                ['/queries', <Queries />],
                ['/queryform', <QueryForm />],
                ['/applications', <Applications />],
                ['/applicationform', <ApplicationForm />],
                ['/downloads', <Downloads />],
              ].map((route) => (
                <Route
                  path={route[0]}
                  key={`${route[0]}-element`}
                  render={({ location }) => (!authenticated ? (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  ) : (
                    route[1]
                  ))}
                />
              ))}

              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </div>
        {headerFooterNavabarPages.map((path) => (
          <Route path={path} key={`${path}-element`}>
            <ForcelinkFooter />
          </Route>
        ))}
      </div>
    );
  }
}

const NoMatch = () => {
  const location = useLocation();
  return (
    <div>
      <h3>
        404: There doesn&apos;t seem to be a page titled
        {' '}
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

const LogoutWapper = () => <LoginForm ack="logout" />;

const PortalNavWrapper = () => {
  const location = useLocation();
  return <PortalNav page={location} />;
};

const QueryFormWapper = () => {
  const { code } = useParams();
  return <QueryForm code={code} />;
};

const ApplicationFormWapper = () => {
  const { code } = useParams();
  return <ApplicationForm code={code} />;
};
