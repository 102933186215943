import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as Material from '@material-ui/core';

export const FormRow = (props) => {
  const { md = 6 } = props;
  let { children } = props;
  if (!Array.isArray(children)) children = [children, false];
  return (
    <Form.Group>
      <Row className="formInputs">
        <Col md={md}>{children[0]}</Col>
        <Col md={12 - md}>{children[1] || ''}</Col>
      </Row>
    </Form.Group>
  );
};

const validStyle = (requiredMissing, name) => (requiredMissing && requiredMissing[name] === true ? { color: 'red' } : null);

export const FormControlWithLabel = (props) => {
  const {
    requiredMissing, label, type = 'name', name, error, ...rest
  } = props;
  const required = requiredMissing != null;
  return (
    <>
      <Form.Group className="formLabels">
        <Form.Label
          className="smallLabel"
          style={validStyle(requiredMissing, name)}
        >
          {`${label}${required ? ' *' : ''}`}

        </Form.Label>
      </Form.Group>
      <Form.Control
        {...rest}
        name={name}
        type={type}
        required={required}
        isInvalid={error || (required && requiredMissing[name] === true)}
      />
      <Form.Text style={{ color: 'red' }}>
        {error}
      </Form.Text>
    </>
  );
};

export const FormSelector = (props) => {
  const {
    requiredMissing, options, name, ...rest
  } = props;
  const [list, optionValue, optionLabel] = options;
  const required = requiredMissing != null;
  return (
    <Material.TextField
      {...rest}
      name={name}
      select
      fullWidth
      margin="normal"
      variant="outlined"
      required={required}
      error={required && requiredMissing[name] === true}
    >
      <Material.MenuItem key="blank" value="" />
      {list.map((option, i) => (
        <Material.MenuItem key={i} value={option[optionValue]}>
          {option[optionLabel]}
        </Material.MenuItem>
      ))}
    </Material.TextField>
  );
};
