import React from 'react';
import {
  Button, Form, Row, Col, Card, Container,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import {
  SvgIcon,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import {
  getDivision,
  getBranch,
  register,
  updateProfile,
} from '../../scripts/servest_is/profileScripts';
import { handleLogging, handleValidation, handleOTP } from '../../functions/forms';
import { Context } from '../../functions/servest_is/context';
import { CustomerAddressForm } from '../customerAddress';
import EntriesUpdater from '../../functions/EntriesUpdater';
import { OTPModal } from '../OTPModal';
import { FormRow, FormControlWithLabel } from '../forms';
import {
  SubscriberImage,
  PortalName,
  Footer,
} from '../../pages/servest_is/login/login.styled';

export class ProfileForm extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: this.initEntries(),
      requiredMissing: this.initRequiredMissing(),
      divisionTypes: [],
      branchTypes: [],
      registrationContinued: false,
      submitting: false,
      registerDone: false,
      showModal: false,
      loadingProfile: true,
      errors: {},
    };
    const { entries, requiredMissing } = this.state;
    this.EntriesUpdaterer = new EntriesUpdater(
      entries,
      requiredMissing,
      (entriesTemp) => this.setState({ entries: entriesTemp }),
      (requiredMissingTemp) => this.setState({ requiredMissing: requiredMissingTemp }),
    );
  }

  // INIT VALUES
  initEntries = () => ({
    name: '', // description
    password: '', // user.password
    mobileNumber: '', // mobileNumber
    emailAddress: '', // emailAddress
    // registration continued:
    accountNumber: null, // code
    username: '', // user.username
    passwordHint: '', // user.passwordHint
    officeNumber: '', // officeNumber
    homeNumber: '', /// homeNumber
    division: '', // customLookup3
    branch: '', // customLookup4
    // address:
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
  });

  initRequiredMissing = () => ({
    name: false,
    username: false,
    mobileNumber: false,
    emailAddress: false,
    password: false,
  });
  // << INIT VALUES

  /* componentDidMount = async () => {
  };
  */

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (e) => this.setEntries(e.target.name, e.target.value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (e) => {
    const newVal = this.updateEntries(e);
    if (newVal !== '') {
      this.initRequiredState(e.target.name);
    }
  };

  initMultipleRequiredStates = (values) => {
    const { requiredMissing } = this.state;
    const newValues = {};
    values.forEach((name) => {
      if (requiredMissing[name] == null) {
        newValues[name] = false;
      }
    });
    if (Object.keys(newValues).length > 0) {
      const toUpdate = { ...newValues, ...requiredMissing };
      this.setState({ requiredMissing: toUpdate });
    }
  };
  // << UPDATE VALUES

  validStyle = (name) => {
    const {
      requiredMissing,
    } = this.state;
    return (requiredMissing[name] === true ? { color: 'red' } : null);
  };

  handleSubmit = async () => {
    const {
      entries,
      entries: {
        name, mobileNumber, emailAddress, password, username,
      },
      registrationContinued,
    } = this.state;
    const { onLogin } = this.props;
    let handleLoggingResponse;
    const isFormValid = handleValidation(this.state, (s) => this.setState(s));
    if (isFormValid) {
      handleLoggingResponse = handleLogging(this.state, (s) => this.setState(s));
    }

    if (handleLoggingResponse && isFormValid) {
      console.log('handling submit');
      if (!registrationContinued) {
        const registerResponse = await register(
          {
            name, mobileNumber, emailAddress, password, username,
          },
          (s) => this.setState(s),
          () => this.setState({ registrationContinued: true }),
        );
        if (registerResponse === 'SUCCESS') {
          const divisionTypes = (await getDivision()) || {};
          const branchTypes = (await getBranch()) || {};
          this.setState({ divisionTypes, branchTypes });
        }
        console.log('register response:', registerResponse);
        const { result } = registerResponse;
        const showModal = result === 'OTP_REQUIRED' || result === 'OTP_ERROR';
        this.setState({ showModal });
      } else {
        console.log('Updating profile...');
        const updateResponse = await updateProfile(entries, (s) => this.setState(s));
        console.log('update response:', updateResponse);
        if (updateResponse === true) {
          onLogin(entries, (
            submitting, registerDone,
          ) => this.setState({ submitting, registerDone }));
        }
      }
    }
  };

  continueRegistration = (registerResponse) => {
    const { code, username } = registerResponse;
    this.setEntries('accountNumber', code);
    this.setEntries('username', username);
    this.setState({ registrationContinued: true });
  };

  render() {
    const {
      updateEntries,
      updateReqEntries,
      validStyle,
      EntriesUpdaterer,
    } = this;
    const {
      entries,
      entries: {
        name,
        mobileNumber,
        emailAddress,
        password,
        // registration continued:
        username,
        passwordHint,
        officeNumber,
        homeNumber,
        division,
        branch,
      },
      requiredMissing,
      divisionTypes,
      branchTypes,
      registrationContinued,
      submitting,
      registerDone,
      showModal,
      errors,
    } = this.state;
    console.log('state:', this.state);
    const {
      // updateEntries, updateReqEntries,
      parentEntries,
      parentRequiredMissing,
      printOut,
    } = EntriesUpdaterer;
    parentEntries(entries);
    parentRequiredMissing(requiredMissing);
    printOut();

    const showFullForm = registrationContinued;

    // When the registration continues to the next screen where more info is required,
    // initialise the states of the new inputs
    if (showFullForm) {
      this.initMultipleRequiredStates([
        'username',
        'password',
        'passwordHint',
      ]);
    }

    return (
      <Container fluid id="ProfileForm">
        <div />
        <Row style={{ width: '100%' }}>
          <Col md={2} />
          <Col md={8}>
            <Card id="formCard" className="text-center" style={{ borderWidth: '1px' }}>
              <Card.Header>
                <SubscriberImage />
                <div style={{
                  width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '24px 0px',
                }}
                >
                  <div style={{
                    width: '100%', height: '0px%', margin: '0px 8px', borderBottom: '1px solid #D9D9D9',
                  }}
                  />
                  <SvgIcon>
                    <svg
                      width="58"
                      height="60"
                      viewBox="0 0 58 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M48.8059 31.5748C48.9305 30.1721 48.8381 28.7585 48.5319 27.3841C47.9703 25.2369 46.971 23.2298 45.597 21.4892C45.323 21.1307 45.2812 20.9048 45.597 20.5579C46.1868 19.9084 46.7371 19.2192 47.2688 18.5185C47.9204 17.6869 48.2957 16.6714 48.3419 15.6149C48.3881 14.5583 48.103 13.5138 47.5265 12.6282C46.8101 11.1808 45.6663 9.98994 44.2508 9.21759C42.8352 8.44525 41.2168 8.12896 39.6156 8.31178C38.832 8.4025 38.0771 8.66084 37.4017 9.06932C36.7263 9.4778 36.1462 10.0269 35.7007 10.6795C31.6396 15.7479 27.5738 20.814 23.4198 25.8126C22.6098 26.8445 22.1765 28.1234 22.1922 29.4365C22.2078 30.7496 22.6714 32.0177 23.5057 33.0299C24.3741 34.2376 25.5724 35.1675 26.9562 35.7073C27.3649 35.8657 27.3858 35.9402 27.1094 36.2894C25.6094 38.11 24.1327 39.9469 22.6582 41.7862C22.4725 42.019 22.3424 42.0982 22.0406 41.9142C17.9901 39.6009 14.9933 35.8015 13.6815 31.3164C13.0508 29.3284 12.9463 27.2101 13.3782 25.1694C13.8101 23.1286 14.7635 21.2355 16.1451 19.6756C20.3246 14.6607 24.3951 9.54105 28.5235 4.47496C30.4772 2.0808 33.2685 0.52447 36.3277 0.123607C40.3265 -0.331857 44.3673 0.478245 47.884 2.44044C51.4007 4.40264 54.2172 7.41864 55.939 11.066C56.8089 12.9298 57.2758 14.957 57.309 17.0145C57.3645 19.7124 56.4533 22.3409 54.7409 24.4227C52.8307 26.7509 50.9298 29.0946 49.0381 31.4538C48.9982 31.522 48.9685 31.5957 48.9499 31.6726L48.8083 31.5772"
                        fill="#D9D9D9"
                      />
                      <path
                        d="M9.1118 28.393C8.9004 32.0653 10.0862 35.6813 12.4299 38.5112C12.4951 38.5681 12.547 38.6386 12.5821 38.7178C12.6171 38.797 12.6343 38.8829 12.6325 38.9696C12.6308 39.0562 12.61 39.1413 12.5718 39.219C12.5336 39.2967 12.4788 39.3651 12.4113 39.4192C11.9469 39.936 11.529 40.5064 11.0646 41.028C10.2257 41.9548 9.73713 43.1469 9.68369 44.3973C9.63024 45.6477 10.0153 46.8774 10.772 47.8728C11.5225 49.1248 12.6039 50.1441 13.8964 50.818C15.189 51.4919 16.6423 51.794 18.0955 51.691C18.8744 51.6543 19.6355 51.4453 20.3244 51.079C21.0132 50.7127 21.6128 50.1982 22.08 49.5723C26.2596 44.3929 30.453 39.2236 34.6605 34.0644C35.4223 33.1284 35.8515 31.9646 35.8806 30.7568C35.9096 29.549 35.5367 28.3659 34.8207 27.3942C33.9499 25.9907 32.6536 24.9039 31.1218 24.2931C30.7549 24.1487 30.6016 24.0603 30.9221 23.6738C32.4151 21.8741 33.8872 20.0558 35.3524 18.2329C35.5591 17.9744 35.6868 17.8836 36.0165 18.0908C38.1389 19.3138 39.9892 20.9596 41.4531 22.9268C42.9171 24.894 43.9638 27.1409 44.5288 29.5291C44.9911 31.3159 45.0197 33.1878 44.612 34.988C44.2044 36.7881 43.3725 38.4641 42.186 39.8755C37.8841 45.1636 33.5815 50.4501 29.2781 55.735C27.4919 57.8989 24.9715 59.325 22.2008 59.7394C19.5392 60.1953 16.8097 60.0486 14.2119 59.3102C11.6142 58.5718 9.21393 57.2604 7.18689 55.4719C4.32599 53.2285 2.25208 50.1297 1.26586 46.6249C0.627895 44.6481 0.51684 42.5384 0.943728 40.5052C1.37062 38.4721 2.32059 36.5863 3.69929 35.0352C5.55687 32.8631 7.30995 30.6117 9.1118 28.393Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </SvgIcon>
                  <div style={{
                    width: '100%', height: '0px', margin: '0px 8px', borderBottom: '1px solid #D9D9D9',
                  }}
                  />
                </div>
                <PortalName>
                  Servest IS Portal | Account Registration
                </PortalName>
              </Card.Header>
              <Card.Body id="formBody" style={{ padding: '24px 48px' }}>
                <Form>
                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Customer Account Details
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* CUSTOMER NAME */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={validStyle('name')}
                          >
                            Customer Name *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="name"
                          type="text"
                          readOnly={showFullForm}
                          required
                          isInvalid={requiredMissing.name === true}
                          value={name}
                          onChange={updateReqEntries}
                        />
                      </Col>
                      {/* FORCELINK USERNAME */}
                      <Col md={6}>
                        <>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={validStyle('username')}
                            >
                              Forcelink Username *
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            name="username"
                            type="text"
                            required
                            isInvalid={requiredMissing.username === true}
                            value={username}
                            onChange={updateReqEntries}
                          />
                        </>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="formInputs">
                      {/* FORCELINK PASSWORD */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={validStyle('password')}
                          >
                            Forcelink Password *
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          type="password"
                          name="password"
                          required
                          isInvalid={requiredMissing.password === true}
                          value={password}
                          onChange={updateReqEntries}
                          autocomplete="new-password"
                        />
                      </Col>
                      {/* PASSWORD HINT */}
                      <Col md={6}>
                        {showFullForm && (
                          <>
                            <Form.Group className="formLabels">
                              <Form.Label
                                className="smallLabel"
                                style={validStyle('passwordHint')}
                              >
                                Password Hint *
                              </Form.Label>
                            </Form.Group>
                            <Form.Control
                              type="text"
                              name="passwordHint"
                              required
                              isInvalid={requiredMissing.passwordHint === true}
                              value={passwordHint}
                              onChange={updateReqEntries}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="formLabels">
                    <Form.Label className="largeLabel">
                      Contact Details
                    </Form.Label>
                  </Form.Group>
                  <FormRow>
                    {/* MOBILE NUMBER */}
                    <FormControlWithLabel
                      requiredMissing={requiredMissing}
                      label="Mobile Number"
                      name="mobileNumber"
                      value={mobileNumber}
                      type="tel"
                      onChange={updateReqEntries}
                      error={errors.mobileNumber}
                    />
                    {/* EMAIL ADDRESS */}
                    <FormControlWithLabel
                      requiredMissing={requiredMissing}
                      label="Email Address"
                      name="emailAddress"
                      value={emailAddress}
                      onChange={updateReqEntries}
                    />
                  </FormRow>
                  {showFullForm && (
                    <>
                      <FormRow>
                        {/* OFFICE NUMBER */}
                        <FormControlWithLabel
                          label="Office Number"
                          name="officeNumber"
                          value={officeNumber}
                          type="tel"
                          onChange={updateEntries}
                          error={errors.officeNumber}
                        />
                        {/* LANDLINE EXTENSION */}
                        <FormControlWithLabel
                          label="Landline Extension"
                          name="homeNumber"
                          value={homeNumber}
                          type="text"
                          onChange={updateEntries}
                          error={errors.homeNumber}
                        />
                      </FormRow>
                      <Form.Group className="formLabels">
                        <Form.Label className="largeLabel">
                          Branch and Division
                        </Form.Label>
                      </Form.Group>
                      <Form.Group>
                        <Row className="formInputs">
                          {/* DIVISION */}
                          <Col md={6}>
                            <Material.TextField
                              name="division"
                              select
                              fullWidth
                              margin="normal"
                              label="Division"
                              variant="outlined"
                              required
                              error={requiredMissing.division === true}
                              value={division}
                              onChange={updateReqEntries}
                            >
                              <Material.MenuItem
                                key="blank"
                                value=""
                              />
                              {divisionTypes.map((divisioner) => (
                                <Material.MenuItem
                                  key={divisioner.value}
                                  value={divisioner.value}
                                >
                                  {divisioner.label}
                                </Material.MenuItem>
                              ))}
                            </Material.TextField>
                          </Col>
                          {/* BRANCH */}
                          <Col md={6}>
                            <Material.TextField
                              name="branch"
                              select
                              fullWidth
                              margin="normal"
                              label="Branch"
                              variant="outlined"
                              required
                              error={requiredMissing.branch === true}
                              value={branch}
                              onChange={updateReqEntries}
                            >
                              <Material.MenuItem
                                key="blank"
                                value=""
                              />
                              {branchTypes.map((newBranch) => (
                                <Material.MenuItem
                                  key={newBranch.value}
                                  value={newBranch.value}
                                >
                                  {newBranch.label}
                                </Material.MenuItem>
                              ))}
                            </Material.TextField>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="formLabels">
                        <Form.Label className="largeLabel">
                          Customer Address
                        </Form.Label>
                      </Form.Group>
                      {/* CUSTOMER ADDRESS inputs */}
                      <CustomerAddressForm {...{ entries, updateEntries }} />
                    </>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer>
                <Form.Group id="finalButtons">
                  <Link to="/home" style={{ width: '100%' }}>
                    <Button className="secondary-button" variant="secondary" type="submit">
                      Back
                    </Button>
                  </Link>
                  {!submitting && (
                    <Button
                      className="primary-button"
                      variant={registerDone ? 'secondary' : 'primary'}
                      disabled={registerDone}
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                      {(registrationContinued ? 'Save and login' : 'Register')}
                    </Button>
                  )}
                  {submitting && <Spinner style={{ width: '100%' }} />}
                  {
                     window.clientInformation
                      && window.clientInformation.appVersion
                        === '5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.150 Safari/537.36' && (
                        <Button
                          onClick={() => {
                            const toUpdate = {
                              ...entries,
                              name: 'a',
                              password: 'b',
                              mobileNumber: '0821112233',
                              emailAddress: 'cjmeyer96+pqr@gmail.com',
                              // registration continued:
                              username: 'cjmeyer96+pqr@gmail.com',
                              passwordHint: 'd',
                              officeNumber: '0219998877',
                              homeNumber: '32',
                              division: '1',
                              branch: '1',
                              // address:
                              building: 'e',
                              streetNumber: '32',
                              streetName: 'f',
                              suburb: 'g',
                              city: 'h',
                              postalCode: '9876',
                            };
                            this.setState({
                              registrationContinued: true,
                              entries: toUpdate,
                            });
                          }}
                        >
                          reg cont
                        </Button>
                     )
                    /* DEV TODO: delete */
                  }
                </Form.Group>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={2} />
        </Row>
        <OTPModal
          onChangeOTP={(OTP) => this.setEntries('otp', OTP)}
          showModal={showModal}
          setShowModal={(showingModal) => this.setState({ showingModal })}
          setSubmitting={(submit) => this.setState({ submit })}
          mobileNumber={mobileNumber}
          handleOTP={(setOtp, otp) => handleOTP(
            register,
            this.continueRegistration,
            entries,
            (s) => this.setState(s),
            setOtp,
            otp,
          )}
        />
        <Footer>
          <div
            style={{ paddingRight: '5px' }}
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net');
            }}
          >
            Powered by Forcelink&trade; 2024 |
          </div>
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net/privacy-policy');
            }}
          >
            Privacy Policy
          </div>
        </Footer>
      </Container>
    );
  }
}
