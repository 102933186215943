import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Context } from '../../functions/enpower/context';
// import 'sb-admin-2/dist/css/sb-admin-2.css'
// import 'sb-admin-2/vendor/bootstrap/css/bootstrap.css'
import '../../styles/enpower/home.scss';

export class HomePage extends React.Component {
  static contextType = Context;

  iconRow = (val, i) => (
    <div key={`icon-${i}`} className="iconGroup">
      <Link to={val[0]}>
        <img
          src={`enpower/homeIcons/${val[1]}.svg`}
          alt="icon"
          className="icons"
        />
      </Link>
      <p className="iconCaptions">{val[2]()}</p>
    </div>
  );

  render() {
    return (
      <Container>
        <Row className="iconRow">
          {[
            ['/profile', 'Profile', () => 'Edit Profile'],
            [
              '/logFaultMyAddress',
              'Log at Home',
              () => (
                <span>
                  Log a fault
                  <br />
                  (My address)
                </span>
              ),
            ],
            [
              '/logFaultOtherAddress',
              'Log Away',
              () => (
                <span>
                  Log a fault
                  <br />
                  (Other address)
                </span>
              ),
            ],
          ].map(this.iconRow)}
        </Row>
        <Row className="iconRow">
          {[
            ['/openFaults', 'Open Faults', () => 'Open faults'],
            ['/allFaults', 'All Faults', () => 'All faults'],
            ['/notifications', 'Notifications', () => 'Notifications'],
            ['/logout', 'Log Out', () => 'Log out'],
          ].map(this.iconRow)}
        </Row>
      </Container>
    );
  }
}
