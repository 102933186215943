import React, { useEffect } from 'react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/siemens/context';

export const AssetsPage = () => {
  const title = 'Assets';
  const tableModelClass = 'Asset';
  const id = 'assets';
  const columnView = 'Customer View';
  const rowToolbarLinks = [
    {
      url: '/asset/view/',
      text: 'View',
      toState: false,
    },
    {
      url: '/asset/tree/',
      text: 'Asset Tree',
      toState: false,
    },
    {
      url: '/ticket',
      text: 'Create Ticket',
      toState: true,
    },
  ];

  useEffect(() => {
    document.title = 'Assets | Siemens Portal';
  }, []);

  return (
    <Table
      Context={Context}
      title={title}
      rowToolbarLinks={rowToolbarLinks}
      tableModelClass={tableModelClass}
      id={id}
      columnView={columnView}
    />
  );
};
