import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  postWorkRequest,
} from '../../scripts/siemens/createTicketScripts';
import { addressSchema, AddressForm } from './AddressForm';
import { Input, Datetime } from './Form';

// yup validation schema
const assetSchema = yup.object().shape({
  description: yup.string().nullable().required(),
  typeCode: yup.string().nullable().required(),
  childWorkType: yup.string().nullable(),
  statusCode: yup.string().nullable(),
  statusDescription: yup.string().nullable(),
  appointment: yup.string().nullable(),
  priorityCode: yup.string().nullable().required(),
  orgUnitCode: yup.string().nullable(),
  customerCode: yup.string().nullable(),
  customerDescription: yup.string().nullable(),
  custom1: yup.string().nullable(),
  custom2: yup.string().nullable(),
  custom3: yup.string().nullable(),
  custom4: yup.string().nullable(),
  custom5: yup.string().nullable(),
  custom6: yup.string().nullable(),
  custom7: yup.string().nullable(),
  custom8: yup.string().nullable(),
  custom9: yup.string().nullable(),
  custom10: yup.string().nullable(),
  custom11: yup.string().nullable(),
  custom12: yup.string().nullable(),
  custom13: yup.string().nullable(),
  custom14: yup.string().nullable(),
  custom15: yup.string().nullable(),
  customNumber1: yup.string().nullable(),
  customNumber2: yup.string().nullable(),
  customNumber3: yup.string().nullable(),
  customNumber4: yup.string().nullable(),
  customNumber5: yup.string().nullable(),
  customLookupCode1: yup.string().nullable(),
  customLookupCode2: yup.string().nullable(),
  customLookupCode3: yup.string().nullable(),
  customLookupCode4: yup.string().nullable(),
  customLookupCode5: yup.string().nullable(),
  ...addressSchema,
  createdDate: yup.string().nullable(),
  customDate1: yup.string().nullable(),
  customDate2: yup.string().nullable(),
  customDate3: yup.string().nullable(),
  customDate4: yup.string().nullable(),
  customDate5: yup.string().nullable(),
  customDate6: yup.string().nullable(),
  customDate7: yup.string().nullable(),
  customDate8: yup.string().nullable(),
  customDate9: yup.string().nullable(),
  customDate10: yup.string().nullable(),
  plannedStartDate: yup.string().nullable(),
  durationHours: yup.string().nullable(),
  systemStatusId: yup.string().nullable(),
  assetCodes: yup.string().nullable(),
  assetCodesToRemove: yup.string().nullable(),
  resolutionCode: yup.string().nullable(),
  contactName: yup.string().nullable(),
  contactNumber: yup.string().nullable(),
  contactEmail: yup.string().nullable(),
  parentWorkOrderCode: yup.string().nullable(),
  resourceFeedback: yup.string().nullable(),
  documents: yup.string().nullable(),
  images: yup.string().nullable(),
  notes: yup.string().nullable(),
  docType: yup.string().nullable(),
  billOfMaterialsGroup: yup.string().nullable(),
  workRequestCodes: yup.string().nullable(),
  allocatedToText: yup.string().nullable(),
  inspectionListGroup: yup.string().nullable(),
  workType: yup.string().nullable(),
  systemStatus: yup.string().nullable(),
});

/**
 * Asset Form Component
 */
export const AssetForm = ({ defaults }) => {
  const methods = useForm({
    resolver: yupResolver(assetSchema),
    defaultValues: defaults,
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = (data) => {
    postWorkRequest(data);
    alert(JSON.stringify(data));
  };

  console.log('defaults: ', defaults);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/* Convert Region/Contractor to select */}
            <Input label="WorkOrderRequest.orgUnit" name="orgUnitCode" disabled />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkDoc.createdDate" name="createdDate" now disabled />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkOrderRequest.appointment" name="appointment" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactName" name="contactName" disabled />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactNumber" name="contactNumber" disabled />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactEmail" name="contactEmail" disabled />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.contactEmailCC" name="contactEmailCC" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom1" name="custom1" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom3" name="custom3" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom4" name="custom4" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom5" name="custom5" />
          </Grid>
          <Grid item xs={6}>
            <Input label="WorkOrderRequest.custom8" name="custom8" />
          </Grid>
          <Grid item xs={12}>
            <Input label="WorkOrderRequest.description" name="description" />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkOrderRequest.customDate1" name="customDate1" disabled />
          </Grid>
          <Grid item xs={6}>
            <Datetime label="WorkOrderRequest.customDate2" name="customDate2" disabled />
          </Grid>
        </Grid>
        <AddressForm title="Client site" />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: '1rem' }}
        >
          <Button variant="contained" component={Link} to="/home">Cancel</Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Submit</Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
