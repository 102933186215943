import React, { useEffect } from 'react';
import {
  Row, Col, Container, Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/botswanatourism/home.scss';

export const HomePage = () => {
  useEffect(() => {
    async function fetchData() {
      // Auth routing
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Row>
        <Link to="/facilitiesinformation" style={{ color: 'black' }}>
          <Col md={3}>
            <div className="homeCard">
              <img className="homeImage" src="botswanatourism/Asset5@50x.png" alt="Home" />
              <Card.Body>
                <Card.Title>Edit Facility Details</Card.Title>
                <Card.Text>
                  &#8226; Facility information
                  {' '}
                  <br />
                  &#8226; Contact information
                  {' '}
                  <br />
                  &#8226; Address information
                  {' '}
                  <br />
                  &#8226; Facility status
                  {' '}
                  <br />
                  &#8226; Facility services
                </Card.Text>
              </Card.Body>
            </div>
          </Col>
        </Link>

        <Link to="/queries" style={{ color: 'black' }}>
          <Col md={3}>
            <div className="homeCard">
              <img className="homeImage" src="botswanatourism/Asset6@50x.png" alt="Home" />
              <Card.Body>
                <Card.Title>Logging of Queries</Card.Title>
                <Card.Text>
                  &#8226; Outstanding assessments
                  <br />
                  &#8226; Outstanding certificates/plaques
                  <br />
                  &#8226; Updating facilities information
                  <br />
                </Card.Text>
              </Card.Body>
            </div>
          </Col>
        </Link>

        <Link to="/applications" style={{ color: 'black' }}>
          <Col md={3}>
            <div className="homeCard">
              <img className="homeImage" src="botswanatourism/Asset3@50x.png" alt="Home" />
              <Card.Body>
                <Card.Title>Grading Applications</Card.Title>
                <Card.Text>
                  &#8226; First Time Grading
                  <br />
                  &#8226; Grading Renewal / Amendment
                  <br />
                  &#8226; Advisory Visit Request
                  <br />
                  &#8226; Eco-Certification
                </Card.Text>
              </Card.Body>
            </div>
          </Col>
        </Link>

        <Link to="/downloads" style={{ color: 'black' }}>
          <Col md={3}>
            <div className="homeCard">
              <img className="homeImage" src="botswanatourism/Asset4@50x.png" alt="Home" />
              <Card.Body>
                <Card.Title>Downloads​</Card.Title>
                <Card.Text>
                  Supporting material for:
                  <br />
                  &#8226; Grading system reference documents
                  <br />
                  &#8226; Self-assessment forms
                  {' '}
                  <br />
                  <br />
                </Card.Text>
              </Card.Body>
            </div>
          </Col>
        </Link>

      </Row>
    </Container>
  );
};
