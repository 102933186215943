import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Home, SupervisorAccountOutlined, Help } from '@material-ui/icons';
import { Context } from '../../functions/syntell/context';
import { LogoHeaderWithNav, SupportModal } from '../header';

export const SyntellHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const logoSrc = 'syntell/mtm-home.png';
  const { username, labels } = useContext(Context);
  const version = (labels && labels['js.versionNo']) || '';

  const dropdowns = [
    [
      'home',
      Home,
      'Home',
      [['/home', 'Home']],
      () => history.push('/home'),
    ],
    [
      'customer',
      SupervisorAccountOutlined,
      'Customer Self Service',
      [
        ['/myDetails', 'My Details'],
        ['/tickets', 'View Work Requests'],
        ['/ticket', 'Create Work Request'],
      ],
    ],
    [
      'support',
      Help,
      'Support',
      [[() => setShowModal(true), 'About Forcelink']],
      () => setShowModal(true),
    ],
  ];
  return (
    <>
      <LogoHeaderWithNav {...{
        history, logoSrc, username, dropdowns,
      }}
      />
      <SupportModal
        showModal={showModal}
        setShowModal={setShowModal}
        version={version}
      />
    </>
  );
};
