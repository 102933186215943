import React from 'react';
import { Cookies } from 'react-cookie';
import { checkCookies } from '../context';
import * as context from '../context';

export const Context = React.createContext();

export class ContextManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.zeroState();
    this.cookies = new Cookies();
  }

  zeroState = () => ({
    authenticated: null,
    callTypes: [],
    customerTypes: [],
  });

  componentDidMount = async () => {
    checkCookies(this.cookies, (s) => this.setState(s));
  };

  setAuth = async (...args) => context.setAuth(...args, this.cookies, (s) => this.setState(s));

  logout = () => context.logout((s) => this.setState(s), this.cookies, this.zeroState);

  // SETTERS
  setCallTypes = (callTypes) => this.setState({ callTypes });

  setCustomerTypes = (customerTypes) => this.setState({ customerTypes });

  // RENDER CONTEXT
  render() {
    const {
      props: { children },
      state: { authenticated, callTypes, customerTypes },
      cookies,
      setAuth,
      logout,
      setCallTypes,
      setCustomerTypes,
    } = this;

    if (authenticated === true) {
      cookies.set('subscriberId', 'enpower_gsdm', { path: '/enpower_gsdm' });
    }
    console.log('context state:', this.state, 'cookies:', cookies.cookies);

    return (
      <Context.Provider
        value={{
          setAuth,
          logout,
          setCallTypes,
          setCustomerTypes,
          cookies,
          authenticated,
          callTypes,
          customerTypes,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
