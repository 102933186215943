import React, { useEffect, useState, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  deleteDocument,
  getDocumentsDWRForClassAndId,
} from '../../functions/apiCalls';
import FormTable from './document_table';
import DocumentDialog from './document_dialog';
import { Context } from '../../functions/servest_is/context';
import { DocumentsStyled } from './documents.styled';
import DocumentButton from './document_button';

const Documents = ({
  recordId,
  className,
  editable,
}) => {
  const { labels } = useContext(Context);
  const [openDocumentPopup, setOpenDocumentPopup] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState(
    undefined,
  );
  const [documentId, setDocumentId] = useState(undefined);

  const fetchData = async () => {
    const apiCallOptions = await getDocumentsDWRForClassAndId(className, recordId)
      .then((res) => res.json());
    setDocuments(apiCallOptions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id) => {
    if (id !== undefined) {
      const editedDoc = documents.find((doc) => doc.id === id);
      if (editedDoc !== undefined) {
        setDocument(editedDoc);
        setOpenDocumentPopup(true);
      }
    }
  };

  const handleDelete = (id) => {
    id !== undefined && setDocumentId(id);
  };

  const handleCloseDelete = () => {
    setDocumentId(undefined);
  };

  const handleDeleteConfirmation = async () => {
    await deleteDocument(`${documentId}`, recordId);
    fetchData();
    handleCloseDelete();
  };

  return (
    <DocumentsStyled>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '650px',
            borderRadius: '0px',
            padding: '24px',
          },
          '& .MuiDialogTitle-root': {
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '27px',
            color: '#000000',
            fontFamily: '"Poppins", sans-serif',
            padding: '0px 0px 24px 0px',
          },
          '& .MuiDialogContentText-root': {
            fontFamily: '"Poppins", sans-serif',
            fontWeight: '300',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#000000',
          },
          '& .MuiDialogContent-root': {
            padding: '0px 0px 24px 0px',
          },
          '& .MuiDialogActions-root': {
            padding: '0px',
          },
          '& .MuiButtonBase-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 12px',
            background: '#102b7c',
            color: '#fff',
            fontFamily: '"Poppins", sans-serif',
          },
          '& .MuiButtonBase-root:hover': {
            backgroundColor: '#102b7c',
          },
        }}
        open={documentId !== undefined}
        onClose={handleCloseDelete}
      >
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`This will permanently remove this ${labels.Document}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DocumentButton
            type="primary"
            onClick={handleDeleteConfirmation}
            tooltip="Confirm Delete Action"
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              Delete
            </div>
          </DocumentButton>
          <DocumentButton
            type="secondary"
            onClick={handleCloseDelete}
            tooltip="Cancel"
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              Cancel
            </div>
          </DocumentButton>
        </DialogActions>
      </Dialog>
      <DocumentDialog
        open={openDocumentPopup}
        setOpen={setOpenDocumentPopup}
        className={className}
        recordId={recordId}
        refresh={fetchData}
        document={document}
        setDocument={setDocument}
      />
      {documents.length > 0
        ? (
          <FormTable
            columns={[
              {
                minWidth: '25%',
                id: 'dateCreated',
                label: labels['*.dateCreated'],
              },
              {
                minWidth: '25%',
                id: 'changeDate',
                label: labels['*.changeDate'],
              },
              {
                minWidth: '25%',
                id: 'documentType',
                label: labels['Document.documentType'],
              },
              {
                minWidth: '25%',
                id: 'filename',
                label: labels['Document.filename'],
                link: true,
              },
            ]}
            rows={documents.map((doc) => ({
              id: doc.id,
              dateCreated: doc.startDate,
              changeDate: doc.changeDate,
              documentType: doc.documentType,
              filename: { label: doc.filename, value: doc.url },
            }))}
            contextMenuOptions={editable ? [
              { label: 'Edit', action: handleEdit },
              { label: 'Delete', action: handleDelete },
            ] : undefined}
          />
        ) : <p>No documents</p>}

      {editable
      && (
      <div
        className="document-footer"
      >
        <DocumentButton
          type="secondary"
          onClick={() => {
            setOpenDocumentPopup(true);
          }}
          tooltip="Add Document"
        >
          <div
            style={{
              color: '#595959', fontSize: '12px', fontWeight: '500', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px',
            }}
          >
            <AddIcon
              style={{ color: '#102b7c' }}
            />
            Add Document
          </div>
        </DocumentButton>
      </div>
      )}
    </DocumentsStyled>
  );
};

export default Documents;
