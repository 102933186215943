import React, { useState, useEffect } from 'react';
import {
  Paper,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import { getPortalCustomerDetails } from '../../functions/apiCalls';
import { CustomerForm } from '../../components/siemens/CustomerForm';

/**
 * recursively delete all null keys in obj
 */
const deleteNull = (obj) => {
  const objecter = obj;
  Object.keys(obj).forEach((k) => {
    if (objecter[k] === null) { delete objecter[k]; } else if (typeof (objecter[k]) === 'object') { deleteNull(objecter[k]); }
  });
  return objecter;
};

export const CustomerFormPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaults, setDefaults] = useState();

  useEffect(() => {
    document.title = 'Customer Details | Siemens Portal';
    async function getData() {
      setIsLoading(true);

      // fetch customer data
      // TODO: unnecessary request if you get profile data from context
      const customerData = await getPortalCustomerDetails().then((res) => res.json());
      // delete keys that are null, because react-hook-form doesn't like null inputs
      const data = deleteNull(customerData);

      setDefaults(data);
      setIsLoading(false);
    }
    getData();
  }, []);

  return (
    <Container maxWidth="md" disableGutters>
      <Paper
        style={{ padding: '1rem' }}
        elevation={2}
      >
        <Typography variant="h4" style={{ textAlign: 'center', paddingBottom: '1rem' }}>
          Customer Information
        </Typography>
        {isLoading
          && (
          <Backdrop style={{ zIndex: 10, color: '#fff' }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
          )}
        {defaults !== undefined
          ? <CustomerForm defaults={defaults} />
          : null}
      </Paper>
    </Container>
  );
};
